
export interface DbZone {
    zoneTitles?: Array<string>;
    list?: Array<Zone>;
}

export interface Zone {
    id?: string;
    name?: string;
    cols?: number;
    rows?: number;
    zones?: Array<Zone>;
    placements?: number;
    //sensorIds?: Array<string>;
    externalId?: string
}

// export interface ZonePhase {
//     phaseName?: string;
//     zoneIds?: Array<string>;
// }

// export interface HarvestZonePhases extends Zone {
//     zones: Array<ZonePhase>;
// }

// export interface ZoneSetter {
//     id?: string;
//     count?: number;
// }