import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from '../../environments/environment';

declare var gtag;
declare var ga;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private router: Router) { }

  setupGoogleAnalytics() {

    if(environment.production){
      //gtag('config', 'UA-177735591-1');
  
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // ga('set', 'page', event.urlAfterRedirects);
          // ga('send', 'pageview');
          gtag('config', 'UA-177735591-1', 
                {
                  'page_path': event.urlAfterRedirects
                }
               );
        }
      });
    }
  }

  public eventEmitter( 
    eventName: string, 
    eventCategory: string, 
    eventAction: string, 
    eventLabel: string = null,  
    eventValue: number = null ){ 
      if(environment.production){
        gtag('event', eventName, { 
                eventCategory: eventCategory, 
                eventLabel: eventLabel, 
                eventAction: eventAction, 
                eventValue: eventValue
              })
      }
    }
}
