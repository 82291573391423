import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewModels from 'app/viewmodels';

import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit,  OnDestroy {

  @Input() disabled = false;
  @Input() displayList = false;
  @Input() list: viewModels.ViewModel[] = [];
  
  get title(): string {
    if(this.ids == null || this.list == null){
      return '';
    }
    if(this.ids.length == this.list.length){
      return 'ALL';
    }
    if(this.ids.length >= 1){
      let title = this.list[0].name;
      if(this.ids.length >= 2){
        title += ` (+${this.ids.length - 1} ${this.ids?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }

  idsValue: Array<string>;
  @Input()
  get ids() {
    return this.idsValue;
  }
  @Output() idsChange = new EventEmitter();
  set ids(val) {
    if(val == null){
      val = [];
    }
    this.idsValue = val;
    //this.setForm();
    this.idsChange.emit(this.idsValue);
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  change(newValue) {
    this.ids = newValue;
  }

  selectAll(select: NgModel, values: Array<viewModels.ViewModel>) {
    select.update.emit(values); 
    this.change(this.list.map(i => i.id));
  }

  deselectAll(select: NgModel) {
    select.update.emit([]); 
    this.change([]);
  }
}

