import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
//import { LoadingInterceptor } from './interceptors/httpInterceptor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill'

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ButtonsModule } from './buttons/buttons.module';
import { MaterialModule } from './app.material.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularWebStorageModule } from 'angular-web-storage';
import { DeviceDetectorModule } from 'ngx-device-detector';
//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { NgxIziToastModule } from 'ngx-izitoast';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

// firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireFunctionsModule } from '@angular/fire/functions';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import * as firebase from 'firebase';
// import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';

import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaterialPopoverModule } from './modules/ngx-material-popover/ngx-material-popover.module';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { PagesModule } from './pages/pages.module';
//import { MarketingLayoutModule } from './layouts/marketing-layout/marketing-layout.module';
//import { MarketingLayoutComponent } from './layouts/marketing-layout/marketing-layout.component';
// import { CreateCompanyComponent } from './dialogs/create-company/create-company.component'

import { ContextMenuModule } from 'ngx-contextmenu';
// import { GoogleChartComponent } from 'angular-google-charts';
import { TimelineHelper } from './components/timeline/timeline-helper';

import { ServiceModule } from './app.service.module';
import { GridModule } from './grid.module';

//dialogs
import {
  UserAddComponent,
  ForgotPasswordComponent,
  UpdateRoleComponent,
  HarvestAddComponent,
  EventUpdateComponent,
  EventRepeatComponent,
  HarvestEditComponent,
  TemplateAddComponent,
  EventAddComponent,
  UserSelectComponent,
  UserSelectSingleComponent,
  UserEditComponent,
  HarvestGroupDeleteComponent,
  HarvestSelectDialogComponent,
  HarvestSelectSingleDialogComponent,
  HarvestShiftComponent,
  UsersAssignComponent,
  RestrictionNotificationComponent,
  BlockNotificationComponent,
  DemoRequestComponent,
  ImageUploadComponent,
  CreateCompanyComponent,
  HelpComponent,
  NotesComponent,
  UrlUploadDialogComponent,
  NotificationTokenComponent,
  TemplateUploadComponent,
  ZoneUpdateComponent,
  ZoneAssignComponent,
  ZoneExplanationDialogComponent,
  FilterCalendarDialogComponent,
  PostHarvestEditComponent,
  SensorEditComponent,
  SensorUploadComponent,
  EditRecurringEventsDialogComponent,
  DatePickerDialogComponent,
  SensorZoneTimelineDialogComponent,
  AttachmentUploadDialogComponent,
  AttachmentsSelectDialogComponent,
  DocumentationRedirectDialogComponent,
  InviteToOtherCompanyDialogComponent,
  LeaflogixHarvestMatchDialogComponent,
  FilterSetSaveDialogComponent,
  HarvestDocumentsDialogComponent,
  DocumentUploadDialogComponent,
  HarvestsReviewDialogComponent,
  ApplyWorkflowComponent,
  StrainUploadDialogComponent,
  CreateTeamsAlertDialogComponent,
  RoleAddDialogComponent,
  CompanySelectMultipleDialogComponent,
  CompanySelectDialogComponent,
  TemplateWorkflowDeleteComponent,
  EmbededVideoDialogComponent,
  ChangeColorDialogComponent,
  HarvestEditDialogComponent,
  DutchieUnmatchedBatchSelectDialogComponent,
  SubtasksDialogComponent,
  DescriptionDialogComponent,
  TemplatedTaskCreateFromStepDialogComponent,
  StepAddFromLibraryDialogComponent,
  EventAddFromLibraryDialogComponent,
} from './dialogs';
import { SafePipe } from './services/pipes/safe.pipe';


//firebase.initializeApp(environment.firebase);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ButtonsModule,
    RouterModule,
    AppRoutingModule,
    TextMaskModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    // }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    // AngularFireAuthModule,
    // AngularFireFunctionsModule,
    // AngularFireStorageModule,
    // AngularFireAuthGuardModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    MaterialModule,
    ColorPickerModule,
    AngularWebStorageModule,
    DeviceDetectorModule.forRoot(),
    //NgxMatSelectSearchModule,
    PagesModule,
    //MarketingLayoutModule,
    ServiceModule,
    GridModule,
    GuidedTourModule,
    ContextMenuModule.forRoot(),
    NgxIziToastModule,
    DragDropModule,
    NgxCsvParserModule,
    MatSelectFilterModule,
    QuillModule.forRoot(),
    NgxTippyModule
    // GoogleChartComponent,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    UserAddComponent,
    ForgotPasswordComponent,
    UpdateRoleComponent,
    HarvestAddComponent,
    EventUpdateComponent,
    EventRepeatComponent,
    HarvestEditComponent,
    TemplateAddComponent,
    EventAddComponent,
    UserSelectComponent,
    UserSelectSingleComponent,
    UserEditComponent,
    HarvestGroupDeleteComponent,
    HarvestSelectDialogComponent,
    HarvestSelectSingleDialogComponent,
    HarvestShiftComponent,
    UsersAssignComponent,
    RestrictionNotificationComponent,
    BlockNotificationComponent,
    DemoRequestComponent,
    ImageUploadComponent,
    CreateCompanyComponent,
    HelpComponent,
    NotesComponent,
    UrlUploadDialogComponent,
    NotificationTokenComponent,
    TemplateUploadComponent,
    ZoneUpdateComponent,
    ZoneAssignComponent,
    ZoneExplanationDialogComponent,
    FilterCalendarDialogComponent,
    PostHarvestEditComponent,
    SensorEditComponent,
    SensorUploadComponent,
    EditRecurringEventsDialogComponent,
    DatePickerDialogComponent,
    SensorZoneTimelineDialogComponent,
    AttachmentUploadDialogComponent,
    AttachmentsSelectDialogComponent,
    DocumentationRedirectDialogComponent,
    InviteToOtherCompanyDialogComponent,
    LeaflogixHarvestMatchDialogComponent,
    FilterSetSaveDialogComponent,
    HarvestDocumentsDialogComponent,
    DocumentUploadDialogComponent,
    HarvestsReviewDialogComponent,
    ApplyWorkflowComponent,
    StrainUploadDialogComponent,
    CreateTeamsAlertDialogComponent,
    RoleAddDialogComponent,
    CompanySelectMultipleDialogComponent,
    CompanySelectDialogComponent,
    TemplateWorkflowDeleteComponent,
    EmbededVideoDialogComponent,
    ChangeColorDialogComponent,
    HarvestEditDialogComponent,
    DutchieUnmatchedBatchSelectDialogComponent,
    SubtasksDialogComponent,
    DescriptionDialogComponent,
    TemplatedTaskCreateFromStepDialogComponent,
    StepAddFromLibraryDialogComponent,
    EventAddFromLibraryDialogComponent,
    SafePipe
  ],
  providers: [
    GuidedTourService,
    TimelineHelper,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserAddComponent,
    ForgotPasswordComponent,
    UpdateRoleComponent,
    HarvestAddComponent,
    EventUpdateComponent,
    EventRepeatComponent,
    HarvestEditComponent,
    TemplateAddComponent,
    EventAddComponent,
    UserSelectComponent,
    UserSelectSingleComponent,
    UserEditComponent,
    HarvestGroupDeleteComponent,
    HarvestSelectDialogComponent,
    HarvestSelectSingleDialogComponent,
    HarvestShiftComponent,
    UsersAssignComponent,
    RestrictionNotificationComponent,
    BlockNotificationComponent,
    DemoRequestComponent,
    ImageUploadComponent,
    CreateCompanyComponent,
    HelpComponent,
    NotesComponent,
    UrlUploadDialogComponent,
    NotificationTokenComponent,
    TemplateUploadComponent,
    ZoneUpdateComponent,
    ZoneAssignComponent,
    ZoneExplanationDialogComponent,
    FilterCalendarDialogComponent,
    PostHarvestEditComponent,
    SensorEditComponent,
    SensorUploadComponent,
    EditRecurringEventsDialogComponent,
    DatePickerDialogComponent,
    SensorZoneTimelineDialogComponent,
    AttachmentUploadDialogComponent,
    AttachmentsSelectDialogComponent,
    DocumentationRedirectDialogComponent,
    InviteToOtherCompanyDialogComponent,
    LeaflogixHarvestMatchDialogComponent,
    FilterSetSaveDialogComponent,
    HarvestDocumentsDialogComponent,
    DocumentUploadDialogComponent,
    StrainUploadDialogComponent,
    RoleAddDialogComponent,
    CompanySelectMultipleDialogComponent,
    CompanySelectDialogComponent,
    TemplateWorkflowDeleteComponent,
    EmbededVideoDialogComponent,
    DutchieUnmatchedBatchSelectDialogComponent,
    SubtasksDialogComponent,
    DescriptionDialogComponent,
    TemplatedTaskCreateFromStepDialogComponent,
    StepAddFromLibraryDialogComponent,
    EventAddFromLibraryDialogComponent,
  ],
})
export class AppModule { }
