import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import * as models from '../../../models';
import * as services from '../../../services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-template-select-multiple',
  templateUrl: './template-select-multiple.component.html',
  styleUrls: ['./template-select-multiple.component.scss']
})
export class TemplateSelectMultipleComponent implements OnInit {

  @Input() displayFor: string = 'templates';

  @Input() primaryTemplates: Array<models.Template> = [];
  @Input() secondaryTemplates: Array<models.Template> = [];

  public primaryTemplateCtrl: FormControl = new FormControl();
  public secondaryTemplateCtrl: FormControl = new FormControl();

  public primaryTemplateFilterCtrl: FormControl = new FormControl();
  public secondaryTemplateFilterCtrl: FormControl = new FormControl();

  public filteredPrimaryTemplates: ReplaySubject<models.Template[]> = new ReplaySubject<models.Template[]>(1);
  public filteredSecondaryTemplates: ReplaySubject<models.Template[]> = new ReplaySubject<models.Template[]>(1);

  protected _onDestroy = new Subject<void>();

  protected filterPrimaryTemplates() {
    if (!this.primaryTemplates) {
      return;
    }
    // get the search keyword
    let search = this.primaryTemplateFilterCtrl.value;
    if (!search) {
      this.filteredPrimaryTemplates.next(this.primaryTemplates.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the harvests
    let filteredTemplates = this.primaryTemplates.filter(template => {
      return (template.name != null && template.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredPrimaryTemplates.next(filteredTemplates);
  }

  protected filterSecondaryTemplates() {
    if (!this.secondaryTemplates) {
      return;
    }
    // get the search keyword
    let search = this.secondaryTemplateFilterCtrl.value;
    if (!search) {
      this.filteredSecondaryTemplates.next(this.secondaryTemplates.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the harvests
    let filteredTemplates = this.secondaryTemplates.filter(template => {
      return (template.name != null && template.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredSecondaryTemplates.next(filteredTemplates);
  }

  @Input() displayList: boolean = false;

  @Input() primaryTemplateIDsValue: Array<string>;
  @Input() secondaryTemplateIDsValue: Array<string>;
  
  @Input()
  get primaryTemplateIds() {
    return this.primaryTemplateIDsValue;
  }
  
  @Output() primaryTemplateIdsChange = new EventEmitter();
  set primaryTemplateIds(val) {
    if(val == null){
      val = [];
    }
    this.primaryTemplateIDsValue = val;
    this.setPrimaryForm();
    this.primaryTemplateIdsChange.emit(this.primaryTemplateIDsValue);
    this.selectedPrimaryTemplatesChange.emit(this.selectedPrimaryTemplates);
  }

  @Input()
  get secondaryTemplateIds() {
    return this.secondaryTemplateIDsValue;
  }
  
  @Output() secondaryTemplateIdsChange = new EventEmitter();
  set secondaryTemplateIds(val) {
    if(val == null){
      val = [];
    }
    this.secondaryTemplateIDsValue = val;
    this.setSecondaryForm();
    this.secondaryTemplateIdsChange.emit(this.secondaryTemplateIDsValue);
    this.selectedSecondaryTemplatesChange.emit(this.selectedSecondaryTemplates);
  }

  @Input()
  get selectedPrimaryTemplates() {
    return this.primaryTemplates.filter(i => this.primaryTemplateIds.includes(i.uid));
  }
  
  @Output() selectedPrimaryTemplatesChange = new EventEmitter();
  set selectedPrimaryTemplates(val) {
    if(val == null){
      val = [];
    }
    this.primaryTemplateIds = val.map(i => i.uid);
    this.selectedPrimaryTemplatesChange.emit(val);
  }

  @Input()
  get selectedSecondaryTemplates() {
    return this.secondaryTemplates.filter(i => this.secondaryTemplateIds.includes(i.uid));
  }
  
  @Output() selectedSecondaryTemplatesChange = new EventEmitter();
  set selectedSecondaryTemplates(val) {
    if(val == null){
      val = [];
    }
    this.secondaryTemplateIds = val.map(i => i.uid);
    this.selectedSecondaryTemplatesChange.emit(val);
  }

  constructor(
    private templateService: services.TemplateService,
    private helperService: services.HelperService,
  ) { }

  ngOnInit(): void {
    if((this.primaryTemplateIds && this.primaryTemplateIds.length === 0) || (this.secondaryTemplates && this.secondaryTemplates.length === 0)){
      this.getTemplates();
    } else {
      this.filteredPrimaryTemplates.next(this.primaryTemplates.slice());
      this.filteredSecondaryTemplates.next(this.secondaryTemplates.slice());
    }

    // listen for search field value changes on primary templates
    this.primaryTemplateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPrimaryTemplates();
      });

    // listen for search field value changes on secondary templates or workflows
    this.secondaryTemplateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSecondaryTemplates();
      });
  }

  async getTemplates() {

    let _primaryTemplates = [];
    let _secondaryTemplates = [];

    let templateData = await this.templateService.getAll();

    let templates: models.Template[] = this.helperService.sortArrayByStringField(templateData, 'name');

    // separate Primary and Secondary Templates
    templates.forEach(template => {
      if (template.type === models.TemplateType.Secondary) {
        _secondaryTemplates.push(template);
      } else {
        _primaryTemplates.push(template);
      }
    })

    this.primaryTemplates = _primaryTemplates;
    this.secondaryTemplates = _secondaryTemplates;

    this.filteredPrimaryTemplates.next(this.primaryTemplates.slice());
    this.setPrimaryForm();

    this.filteredSecondaryTemplates.next(this.secondaryTemplates.slice());
    this.setSecondaryForm();
  }

  templateChange(newValue: models.Template[], templateType: string) {
    if (templateType === models.TemplateType.Primary) {
      this.selectedPrimaryTemplates = newValue;
    }

    if (templateType === models.TemplateType.Secondary) {
      this.selectedSecondaryTemplates = newValue;
    }
  }

  selectFilteredPrimaryTemplate() {
    let sub = this.filteredPrimaryTemplates.subscribe(data => {
      this.templateChange(data, 'primary');
    })

    if (sub !== null) {
      sub.unsubscribe();
    }
  }

  selectFilteredSecondaryTemplate() {
    let sub = this.filteredSecondaryTemplates.subscribe(data => {
      this.templateChange(data, 'secondary');
    })

    if (sub !== null) {
      sub.unsubscribe();
    }
  }

  setPrimaryForm() {
    if (this.displayFor === 'templates') {
      this.primaryTemplateCtrl.setValue(this.primaryTemplates.filter(i => this.primaryTemplateIds.includes(i.uid)));
    }
  }

  setSecondaryForm() {
    if (this.displayFor === 'workflows') {
      this.secondaryTemplateCtrl.setValue(this.secondaryTemplates.filter(i => this.secondaryTemplateIds.includes(i.uid)));
    }
  }

  async setPrimaryTemplates() {
    this.primaryTemplateIds = this.primaryTemplateCtrl.value.map(i => i.uid);
  }

  async setSecondaryTemplates() {
    this.secondaryTemplateIds = this.secondaryTemplateCtrl.value.map(i => i.uid);
  }

}
