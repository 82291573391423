import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import * as models from '../models';
import * as viewmodels from '../viewmodels';
import { HelperService } from './helper.service';

import { EventService } from './events.service';
import { FileService } from './file.service';

@Injectable()
export class HarvestDocumentService {

    companyRef: AngularFirestoreCollection;

    parentSlug: string = 'harvests';
    slug: string = 'docs';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
        private fns: AngularFireFunctions,
        private storage: SessionStorageService,
        private eventService: EventService,
        private fileService: FileService
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    /**
     * 
     * @param harvestId 
     * @returns 
     */
    async getListByHarvest(harvestId): Promise<Array<models.Document>> {
        let companyId: string = this.helperService.currentCompanyId;

        let response = await this.companyRef.doc(companyId).collection(this.parentSlug).doc(harvestId).collection(this.slug).get().toPromise();
        
        let docs: Array<models.Document> = [];
        response.forEach(d => {
            let doc: models.Document = d.data();
            doc.id = d.id;
            docs.push(doc);
        });
        return docs;
    }

    async save(harvestId: string, document: models.Document) {
        let companyId = this.helperService.currentCompanyId;

        let id: string;
        if(document.id){
            id = document.id;
        } else {
            id = this.firestore.createId();
        }

        let docRef: AngularFirestoreDocument = this.companyRef.doc(companyId).collection(this.parentSlug).doc(harvestId).collection(this.slug).doc(id);

        let fullPath: string = `docs/{companyId}/harvest/${harvestId}/doc/${docRef.ref.id}`;
    
        let response = await this.fileService.upload(fullPath, document.file);

        delete document.file
        delete document.id;
        document.fullPath = response.metadata.fullPath;
    
        await docRef.set(document);

        return docRef.ref.id;
    }

    async delete(harvestId: string, document: models.Document) {
        let companyId = this.helperService.currentCompanyId;
        await this.fileService.delete(document.fullPath).toPromise();
        let docRef: AngularFirestoreDocument = this.companyRef.doc(companyId).collection(this.parentSlug).doc(harvestId).collection(this.slug).doc(document.id);
        await docRef.delete();
    }
}
