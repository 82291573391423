import { Injectable } from '@angular/core';
import * as models from '../models';
import { SessionStorageService } from 'angular-web-storage';
import { HelperService } from './helper.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class NotificationService {

  constructor(
    private functions: Functions,
    private storage: SessionStorageService,
    private helperService: HelperService) {
  }

  async processNotifications() {
    
    let companyId = this.helperService.currentCompanyId;

    let processCompanyNotificationsMethod = httpsCallable(this.functions, 'processCompanyNotifications');
    var processCompanyNotificationsResponse = await processCompanyNotificationsMethod({ companyId: companyId, overrideTimeCheck: true });

    return processCompanyNotificationsResponse;
  }

  /**
   * process managment level notifications for the company
   */
  async processManagementNotifications() {
    
    let companyId = this.helperService.currentCompanyId;

    let processCompanyManagementNotificationsMethod = httpsCallable(this.functions, 'processCompanyManagementNotifications');
    var processCompanyManagementNotificationsResponse = await processCompanyManagementNotificationsMethod({ companyId: companyId, overrideTimeCheck: true });

    return processCompanyManagementNotificationsResponse;
  }

  /**
   * process owner level notifications for the company
   */
  async processOwnerNotifications() {
    
    let companyId = this.helperService.currentCompanyId;

    let processCompanyOwnerNotificationsMethod = httpsCallable(this.functions, 'processCompanyOwnerNotifications');
    var processCompanyOwnerNotificationsResponse = await processCompanyOwnerNotificationsMethod({ companyId: companyId, overrideTimeCheck: true });

    return processCompanyOwnerNotificationsResponse;
  }

  async processAllNotifications() {
    
    let processAllNotificationsMethod = httpsCallable(this.functions, 'processAllNotifications');
    var processAllNotificationsResponse = await processAllNotificationsMethod({ overrideTimeCheck: true });

    return processAllNotificationsResponse;
  }

  async blastNotification(subject: string, message: string){
    
    let companyId = this.helperService.currentCompanyId;

    let processBlastNotificationsMethod = httpsCallable(this.functions, 'processBlastNotifications');
    var processBlastNotificationsResponse = await processBlastNotificationsMethod({ companyId: companyId, subject: subject, message: message });
  
    return processBlastNotificationsResponse;
  }
}
