<h2 mat-dialog-title>{{header}}</h2>
<mat-dialog-content>

  <form id="harvestSelectDialog" class="harvest-select-form" (ngSubmit)="submit()">

    <div class="row">
      <div class="col-sm-12">
        <h4 mat-dialog-title>Harvests</h4>

        <app-harvest-select 
            [includeCompleted]="true" 
            [includeConnectedLeaflogix]="false"
            [expanded]="true"
            [(harvest)]="selectedHarvest">
        </app-harvest-select>

      </div>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{negativeBtn}}</button>
  <button mat-button type="submit" form="harvestSelectDialog" [disabled]="selectedHarvest == null">
    {{affirmativeBtn}}
  </button>
</mat-dialog-actions>
