import { Component, OnInit, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as models from '../../../models';
import * as services from '../../../services';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-chart-daterange-filter',
  templateUrl: './chart-daterange-filter.component.html',
  styleUrls: ['./chart-daterange-filter.component.scss']
})
export class ChartDaterangeFilterComponent implements OnInit {

  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;

  selectedChartRange: models.DateRanger;
  showCustomRangeInput: boolean = false;
  companySub: Subscription;

  @Input() filterForEventVolumeChart: boolean;
  @Input() showYearly: boolean = true;
  @Input() showAllTime: boolean = true;
  @Input() showCustom: boolean = true;
  @Output() selectedChartDateRangeChange = new EventEmitter();
  set selectedChartDateRange(val: models.DateRanger) {
    if(val && val != null){
      this.selectedChartRange = val;  
      this.selectedChartDateRangeChange.emit(this.selectedChartRange);
    }
  }

  selectedOption: string;

  constructor(
    private helperService: services.HelperService
  ) { }

  isDropdownOpen: boolean = false;

  toggleDropdown() {
    this.showCustomRangeInput = false;
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  setDefaultDateRange() { // default set to last month
    if (this.filterForEventVolumeChart) {
      this.selectedOption = 'week';
      this.selectedChartDateRange = {
        rangeType: 'week',
        datesInRange: this.helperService.computeDatesRange("week"),
        defaultViewSetTo: this.helperService.getDefaultInterval("week")
      }
    } else {
      this.selectedOption = 'last_month';
      this.selectedChartDateRange = {
        rangeType: 'last_month',
        datesInRange: this.helperService.computeDatesRange("last_month"),
        defaultViewSetTo: this.helperService.getDefaultInterval("last_month")
      }
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let companyId = this.helperService.currentCompanyId;
    if (companyId) {
      this.setDefaultDateRange();
    }
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.setDefaultDateRange();
    })
  }

  onCustomDateRangeChange(startDate: string, endDate: string): void {
    this.setCustomDateRange(startDate, endDate);
  }

  setCustomDateRange(startDate: string, endDate: string) {
    const dates = this.helperService.computeDatesRange("custom", startDate, endDate);
    const defaultView = this.helperService.getDefaultInterval("custom");
    this.selectedChartDateRange = {
      rangeType: "custom",
      datesInRange: dates,
      defaultViewSetTo: defaultView
    }
    this.isDropdownOpen = false;
  }

  optionChange(option: any) {
    //this.selectedOption = this.helperService.getDateRangeTitle(option.value);
    if (option.value !== 'custom') {
      this.isDropdownOpen = false;
      this.selectedChartDateRange = {
        rangeType: option.value,
        datesInRange: this.helperService.computeDatesRange(option.value),
        defaultViewSetTo: this.helperService.getDefaultInterval(option.value)
      };
      this.showCustomRangeInput = false;
      this.isDropdownOpen = false;
    } else {
      this.showCustomRangeInput = true;
    }
  }

  ngOnDestroy() {
    if (this.companySub) {
      this.companySub.unsubscribe();
    }
  }

}
