import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as services from 'app/services';
import * as models from 'app/models';
var fileDownload = require('js-file-download');

@Component({
  selector: 'app-attachment-upload-dialog',
  templateUrl: './attachment-upload-dialog.component.html',
  styleUrls: ['./attachment-upload-dialog.component.scss']
})
export class AttachmentUploadDialogComponent implements OnInit {

  saveClicked: boolean = false;
  fileToUpload: File | null = null;

  attachment: models.Attachment;

  constructor(
    private dialogRef: MatDialogRef<AttachmentUploadDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private notifierService: services.NotifierService
    , private fileService: services.FileService
    , private attachmentService: services.AttachmentService
  ) {
    this.attachment = data?.attachment;
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  async upload() {
    if (this.fileToUpload == null) {
      this.notifierService.error('Must first select a file to upload')
      return;
    }

    this.saveClicked = true;

    if (this.attachment == null) {
      this.attachment = {
        fileName: this.fileToUpload.name,
        fullPath: '',
        file: this.fileToUpload
      }
    } else {
      this.attachment.fileName = this.fileToUpload.name;
      this.attachment.file = this.fileToUpload
    }

    let response = await this.attachmentService.save(this.attachment);

    this.dialogRef.close(response)
    this.saveClicked = false;
  }

  download() {
    this.fileService.get(this.attachment.fullPath).subscribe(async url => {
      let blob = await fetch(url).then(r => r.blob());
      fileDownload(blob, this.attachment.fileName);
    });
  }
}
