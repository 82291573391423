<div class="loading-container" *ngIf="!retrievedUsers || gettingEvents">
    <div class="content">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</div>

<div class="row" style="padding-bottom: 10px;">
    <div class="col-sm-10">         
        <app-filter-section forAnalytics="false" [events]="events" (onFilter)="onFilter($event)"></app-filter-section>
    </div>
    <div class="col-sm-2">
        <div class="right">            
            <mat-slide-toggle *ngIf="canSeeWeeklyListSlider" [(ngModel)]="weeklyListView" class="gridbtn" (change)="toggleListViewChange($event)">List View</mat-slide-toggle>

            <button mat-raised-button class="gridbtn" *ngIf="canSeeHarvestAdd || canSeeGroupAdd || canSeeAddEventBtn" 
                [matMenuTriggerFor]="addmenu" aria-label="Example icon-button with a menu">
                <mat-icon>add</mat-icon>
            </button>
            <mat-menu #addmenu="matMenu">
                <button mat-menu-item *ngIf="canSeeHarvestAdd" (click)="addHarvest()">
                    <mat-icon fontSet="material-symbols-outlined">potted_plant</mat-icon>
                    <span>Harvest</span>
                </button>
                <button mat-menu-item *ngIf="canSeeGroupAdd" (click)="addGroup()">
                    <mat-icon>splitscreen</mat-icon>
                    <span>Task Group</span>
                </button>                
                <button mat-menu-item *ngIf="canSeeAddEventBtn" (click)="addEvent()">
                    <mat-icon>event</mat-icon>
                    <span>Event</span>
                </button>
            </mat-menu>

            <button mat-raised-button class="gridbtn" *ngIf="canSeeMassUserAssign || canSeeHarvestGroupDelete" 
                [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="canSeeMassUserAssign" (click)="usersMassAssign()">
                    <mat-icon>group_add</mat-icon>
                    <span>Mass assign Users</span>
                </button>
                <app-harvest-shift-btn [dropDownMenu]="true"></app-harvest-shift-btn>
                <button mat-menu-item *ngIf="canSeeHarvestGroupDelete" (click)="harvestGroupDelete()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Harvests and Task Groups</span>
                </button>
                <app-leaflogix-harvest-sync-btn></app-leaflogix-harvest-sync-btn>
            </mat-menu>
        </div>
    </div>
</div>
<!-- <div class="row" *ngIf="canSeeAddEventBtn || canSeeWeeklyListSlider">
    <div class="col-12" style="padding-bottom: 10px;">
        <div style="float: right">
            <mat-slide-toggle *ngIf="canSeeWeeklyListSlider" [(ngModel)]="weeklyListView" class="gridbtn" (change)="toggleListViewChange($event)">List View</mat-slide-toggle>
            <button *ngIf="canSeeAddEventBtn" class="gridbtn" (click)="addEvent()" mat-raised-button title="Add Event">
                <mat-icon aria-hidden="false" aria-label="Add Event">add</mat-icon>
            </button>
        </div>
    </div>
</div> -->
<div class="row">
    <div class="col-12">
        <full-calendar #calendar [options]="calendarOptions"></full-calendar>
    </div>
</div>