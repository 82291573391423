<div *ngIf="!isMobile" mat-dialog-title>
  <div class="flex-grid-thirds">
    <div class="col">
      <img src="/assets/img/Logo1.png" style="max-width: 50px; max-height: 50px">
    </div>
    <div class="col">
      <h3>Request a Demo</h3>
    </div>
    <div class="col"></div>
  </div>
</div>

<div *ngIf="isMobile" mat-dialog-title>
  <div class="mobile-header">
    <div class="img-container">
      <img src="/assets/img/Logo1.png">
    </div>
    <span class="spacer"></span>
    <h3>Request a Demo</h3>
    <span class="spacer"></span>
  </div>
</div>

<div class="progress-bar-container">
  <mat-progress-bar [value]=this.progressBarValue></mat-progress-bar>
</div>
<mat-dialog-content *ngIf="!isMobile">
  <form id="demoRequest" class="dr-form" (ngSubmit)="sendEmail()" (ngClose)="sendEmail()">
    <mat-tab-group class="invisible-tabs">
      <mat-tab *ngIf="this.tab == 1">
          <p dr-full-width>Thank you for submitting your email, someone will be in touch shortly!</p><br>
          <p dr-full-width>If you don't mind, please take a few minutes to answer some additional questions about your grow so we may provide a more personalized demo.</p>
      </mat-tab>
        <mat-tab *ngIf="this.tab == 2">
          <mat-form-field class="dr-full-width">
            <input matInput placeholder="How big is your grow?" style="width: 100%" type="text" name="growSize" [(ngModel)]="growSize">
          </mat-form-field>
        </mat-tab>
        <mat-tab *ngIf="this.tab == 3">
          <p dr-full-width>Approximately how many people do you currently employ?</p>
          <mat-button-toggle-group class="desktop-toggle-group" name="employees" [(ngModel)]="employees">
            <mat-button-toggle value="1-9">1 - 9</mat-button-toggle>
            <mat-button-toggle value="10-25">10 - 25</mat-button-toggle>
            <mat-button-toggle value="26-50">26 - 50</mat-button-toggle>
            <mat-button-toggle value="51-99">51 - 99</mat-button-toggle>
            <mat-button-toggle value="100+">100+</mat-button-toggle>
          </mat-button-toggle-group>
        </mat-tab>
        <mat-tab *ngIf="this.tab == 4">
          <mat-form-field class="dr-full-width">
            <input matInput placeholder="What is your current scheduling process?" style="width: 100%" type="text" name="schedulingProcess" [(ngModel)]="schedulingProcess">
          </mat-form-field>
        </mat-tab>
        <mat-tab *ngIf="this.tab == 5">
          <mat-form-field class="dr-full-width">
            <input matInput placeholder="What do you feel is your biggest issue with your current scheduling process?" style="width: 100%, inline" name="issue" [(ngModel)]="issue">
          </mat-form-field>
        </mat-tab>
        <mat-tab *ngIf="this.tab == 6">
          <mat-form-field class="dr-full-width">
            <input matInput placeholder="How much time do you spend weekly making sure your grow schedule stays on track?" style="width: 100%" type="text" name="timeSpent" [(ngModel)]="timeSpent">
          </mat-form-field>
        </mat-tab>
    </mat-tab-group>
  </form>
</mat-dialog-content>

<mat-dialog-content *ngIf="isMobile">
  <form id="demoRequest" class="dr-form" (ngSubmit)="sendEmail()" (ngClose)="sendEmail()">
    <mat-tab-group class="invisible-tabs">
      <mat-tab *ngIf="this.tab == 1">
          <p dr-full-width>Thank you for submitting your email, someone will be in touch shortly!</p><br>
          <p dr-full-width>If you don't mind, please take a few minutes to answer some additional questions about your grow so we may provide a more personalized demo.</p>
      </mat-tab>
      <mat-tab *ngIf="this.tab == 2">
        <mat-form-field class="dr-full-width">
          <p>How big is your grow?</p>
          <input matInput style="width: 100%" type="text" name="growSize" [(ngModel)]="growSize">
        </mat-form-field>
      </mat-tab>
      <mat-tab *ngIf="this.tab == 3">
        <p dr-full-width>Approximately how many people do you currently employ?</p>
        <mat-button-toggle-group class="mobile-button-toggle-group" vertical="true" name="employees" [(ngModel)]="employees">
          <mat-button-toggle value="1-9">1 - 9</mat-button-toggle>
          <mat-button-toggle value="10-25">10 - 25</mat-button-toggle>
          <mat-button-toggle value="26-50">26 - 50</mat-button-toggle>
          <mat-button-toggle value="51-99">51 - 99</mat-button-toggle>
          <mat-button-toggle value="100+">100+</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-tab>
      <mat-tab *ngIf="this.tab == 4">
        <mat-form-field class="dr-full-width">
          <p dr-full-width>What is your current scheduling process?</p>
          <input matInput style="width: 100%" type="text" name="schedulingProcess" [(ngModel)]="schedulingProcess">
        </mat-form-field>
      </mat-tab>
      <mat-tab *ngIf="this.tab == 5">
        <mat-form-field class="dr-full-width">
          <p dr-full-width>What do you feel is your biggest issue with your current scheduling process?</p>
          <input matInput style="width: 100%, inline" name="issue" [(ngModel)]="issue">
        </mat-form-field>
      </mat-tab>
      <mat-tab *ngIf="this.tab == 6">
        <mat-form-field class="dr-full-width">
          <p dr-full-width>How much time do you spend weekly making sure your grow schedule stays on track?</p>
          <input matInput style="width: 100%" type="text" name="timeSpent" [(ngModel)]="timeSpent">
        </mat-form-field>
      </mat-tab>
    </mat-tab-group>
  </form>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isMobile">
  <div class="flex-grid-thirds">
    <div class="col">
      <button mat-button mat-dialog-close class="left-btn">Cancel</button>
    </div>
  <!-- <span class="spacer"></span> -->
    <div class="col">
      <div id="tab-counter">
        {{tab - 1}}/5
      </div>
    </div>
  <!-- <span class="spacer"></span> -->
    <div class="col">
      <div class="float-right">
        <button mat-raised-button (click)="prevTab()" *ngIf="this.tab > 1">Previous</button>
        <button mat-raised-button (click)="nextTab()" *ngIf="this.tab < this.totalTabs">Next</button>
        <button mat-button type="submit" form="demoRequest" *ngIf="this.tab == this.totalTabs">Submit</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="isMobile" class=mobile-actions>
  <div class="flex-grid-thirds">
    <div class="col">
      <button mat-button mat-dialog-close class="left-btn">Cancel</button>
    </div>
  <!-- <span class="spacer"></span> -->
    <div class="col">
      <div id="tab-counter">
        {{tab - 1}}/5
      </div>
    </div>
  <!-- <span class="spacer"></span> -->
    <div class="col">
      <div class="float-right">
        <button mat-icon-button (click)="prevTab()" *ngIf="this.tab > 1">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <button mat-icon-button (click)="nextTab()" *ngIf="this.tab < this.totalTabs">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
        <button mat-mini-fab color="primary" type="submit" form="demoRequest" *ngIf="this.tab == this.totalTabs">
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
