<div class="row" style="padding-bottom: 15px;" *ngIf="harvest.phases != null && harvest.phases.length > 0">
    <div class="col-12">
        <h3>Phases</h3>
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel *ngFor="let phase of harvest.phases" [disabled]="phase.strains == null || phase.strains.length == 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{phase.phaseName}}
                    </mat-panel-title>
                    <mat-panel-description *ngIf="phase.strains != null && phase.strains.length > 0">
                        <span>
                            Plant Count: <span style="color: black">{{totalPlantCount(phase)}}</span>
                            <span *ngIf="totalZoneCount(phase) != null"> | Zone Count: 
                                <span style="color: #43a047; font-weight: bold;">[{{totalZoneCount(phase)}}]</span>
                            </span>
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row" *ngIf="zones.length > 0">
                    <div class="col-12">
                        <button *ngIf="canEditHarvest" style="float:right;" type="button" mat-raised-button
                            (click)="assignZone(phase)">
                            Assign Zones
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" *ngIf="phase.strains != null && phase.strains.length > 0">
                        <h5>Strains</h5>
                        <ul>
                            <li style="padding-top: 15px;" *ngFor="let strain of phase.strains">
                                <div class="row" style="padding-bottom: 10px;">
                                    <div class="col-3">
                                        {{strain.strainName}}
                                    </div>
                                    <div class="col-3">
                                        <mat-form-field>
                                            <input [disabled]="!canEditHarvest" matInput type="number"
                                                [(ngModel)]="strain.count" placeholder="{{phase.phaseName + ' Count'}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <app-zone-identifiers [zones]="zones" [selectedZones]="strain.zones">
                                        </app-zone-identifiers>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>