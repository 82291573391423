<div class="row" style="padding-bottom: 15px">
  <div class="col-sm-12 right">
    <button mat-raised-button *ngIf="canEdit()" class="pad-btn" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="addTaskGroup()" title="Add Task Group">
        <mat-icon aria-hidden="false" aria-label="Add Task Group">add</mat-icon>
        <span>Add Task Group</span>
      </button>
      <button mat-menu-item (click)="deleteTaskGroup()" title="Delete Task Groups">
        <mat-icon aria-hidden="false" aria-label="Delete Task Groups">delete</mat-icon>
        <span>Delete Task Groups</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="row" data-step="1" data-intro="Double click on a row to see the details of a Harvest."
  data-position='right'>
  <ag-grid-angular style="width: 100%; min-height:400px; height: calc(100vh - 300px);" class="ag-theme-balham"
    [gridOptions]="gridOptions" (cellDoubleClicked)="selectedCell($event)">
  </ag-grid-angular>
</div>
<div class="row" style="padding: 15px 0px">
  <div class="col-sm-12 right">
    <button class="gridbtn" mat-raised-button (click)="exportAsCSV()">Export as CSV</button>
  </div>
</div>