import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-post-harvest-data',
  templateUrl: './post-harvest-data.component.html',
  styleUrls: ['./post-harvest-data.component.scss']
})
export class PostHarvestDataComponent implements OnInit {

  postHarvestDataPoints: Array<models.PostHarvest> = [];

  @Input() canEditHarvest: boolean = false;

  postHarvestDataValue: any = {};
  @Input()
  get postHarvestData() {
    return this.postHarvestDataValue;
  }
  @Output() postHarvestDataChange = new EventEmitter();
  set postHarvestData(val) {
    if(val == null){
      val = {};
    }
    this.postHarvestDataValue = val;
    this.postHarvestDataChange.emit(this.postHarvestDataValue);
  }
  
  constructor(
    private postHarvestService: services.PostHarvestService
  ) { }

  ngOnInit(): void {
    this.getPostHarvestDataPoints();
  }

  async getPostHarvestDataPoints() {
    this.postHarvestDataPoints = await this.postHarvestService.getAll();
  }

}
