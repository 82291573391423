import { Injectable } from '@angular/core';
import { EventTableViewModel } from 'app/viewmodels';
import { Subject } from 'rxjs';
import { HelperService } from '../helper.service';

@Injectable()
export class GridHeaderService {

    // private _anyChecked: boolean = false;
    // public get anyChecked(): boolean {
    //     return this.events.filter(i => i.event.completed).length > 0;
    // };
    // public set anyChecked(val: boolean) {
    //     this._anyChecked = val;
    // }
    public anyChecked: boolean = false;


    public get totalRows(): number {
        return this.events.length;
    };
    //public totalRows: number = 0;
    //public rowsPerPage: number = 100;
    //public rowsPerPageChanged = new Subject<any>();
    public notAllChecked: boolean = false;
    //public events: EventTableViewModel[] = [];

    private _events: EventTableViewModel[] = [];
    public get events(): EventTableViewModel[] {
        return this._events;
    };
    public set events(val: EventTableViewModel[]) {
        this._events = val;
        this.anyChecked = this.events.filter(i => i.event.completed).length > 0;
    }

    constructor(
        private helperService: HelperService
    ) { }

    setEvents(events: EventTableViewModel[]) {
        this.events = events;
        this.reCalcAnyChecked();
    }

    reCalcAnyChecked() {
        this.anyChecked = this.events.filter(i => i.event.completed).length > 0
    }

    // async changeTotalPerPage(number: number){
    //     this.rowsPerPage = number;
    //     await this.helperService.wait(50);
    //     this.rowsPerPageChanged.next(number);
    // }
}
