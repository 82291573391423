<h2 mat-dialog-title style="margin-bottom: 5px;">Link batches with Harvest schedules</h2>
<h5 style="font-size: 14px;">Choose unlinked Dutchie batches below and link them to existing or new Harvest schedules</h5>
<mat-dialog-content style="min-width: 75vw;">
    <div class="row">
        <div class="col-12">
            <ag-grid-angular
                style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
                class="ag-theme-balham"
                [rowData]="batches"
                [gridOptions]="gridOptions">
            </ag-grid-angular>
            <!-- <table class="table table-bordered table-hover">
                <tr>
                    <th>Batch Name</th>
                    <th>Match</th>
                    <th>Add</th>
                </tr>
                <tr *ngFor="let i of batches">
                    <td>{{i.batchName}}</td>
                    <td style="text-align: center">
                        <mat-icon *ngIf="i.linkedExisting">check</mat-icon>
                        <button mat-raised-button *ngIf="!i.linkedExisting && !i.addedNew" (click)="matchCurrent(i)">
                            <mat-icon>sync_alt</mat-icon>
                            <span>Match with current PlanaCan Harvest</span>
                        </button>
                    </td>
                    <td style="text-align: center">
                        <mat-icon *ngIf="i.addedNew">check</mat-icon>
                        <button mat-raised-button *ngIf="!i.linkedExisting && !i.addedNew" (click)="addHarvest(i)">
                            <mat-icon>add</mat-icon>
                            <span>Create Harvest</span>
                        </button>
                    </td>
                </tr>
            </table> -->
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">OK</button>
    <!-- <button mat-button form="imageUpload" [disabled]="submitted" (click)="submit()">
    <mat-spinner *ngIf="submitted" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!submitted">Done</span>
  </button> -->
</mat-dialog-actions>