import { Time } from "@angular/common";
import { CompanyNotification } from "./companyNotification";
import { LeafLogix } from "./leaflogix";
import { Strain } from "./strain";

export interface Company {
    uid?: string;
    name?: string;
    selected?: boolean;
    timezone?: string;
    // sendNotifications?: boolean;
    // checkTime?: number;
    // message?: string;

    // sendManagementNotifications?: boolean;
    // managementCheckTime?: number;
    // managementMessage?: string;
    
    // sendOwnerNotifications?: boolean;
    // ownerCheckTime?: number;
    // ownerMessage?: string;

    freeVersion?: boolean;
    stripeCustomerId?: string;
    //stripeSubscriptionCancelled?: boolean;
    paymentProviderCustomerId?: string;

    notifications?: Array<CompanyNotification>;
    strains?: Array<Strain>;

    enableSensors?: boolean;

    leaflogix?: LeafLogix;
    eventsSplit?: boolean;
    eventsTooltips?: boolean;
}
