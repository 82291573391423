
  <!-- ***** Header Area Start ***** -->
  <header class="header_area animated" [ngClass]="perminantBackgroundClass">
    <div class="container-fluid">
        <div class="row align-items-center">
            <!-- Menu Area Start  col-lg-10 -->
            <div class="col-12">
                <div class="menu_area2">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <!-- Logo -->
                        <!-- <a class="navbar-brand" href="/">Ca.</a> -->
                        <img class="navbar-brand" href="/" src="assets/marketing/img/WhitelogoFinal.png"/>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ca-navbar" aria-controls="ca-navbar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                        <!-- Menu Area -->
                        <div class="collapse navbar-collapse" id="ca-navbar" *ngIf="displayLogin">
                            <ul class="navbar-nav ml-auto" id="nav">
                                <!-- <li class="nav-item active"><a class="nav-link" href="#home">Home</a></li> -->
                                <!-- <li class="nav-item"><a class="nav-link" href="#about">About</a></li> -->
                                <!-- <li class="nav-item"><a class="nav-link" href="#features">Features</a></li>
                                <li class="nav-item"><a class="nav-link" href="#screenshot">Screenshot</a></li> -->
                                <!-- <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li> -->
                                <!-- <li class="nav-item"><a class="nav-link" href="#testimonials">Testimonials</a></li> -->
                                <!-- <li class="nav-item"><a class="nav-link" href="#team">Team</a></li> -->
                                <li class="nav-item" *ngIf="userLoggedIn == true && displayGoToConsole">
                                    <a class="nav-link" href="/console">
                                        Go to Console
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="userLoggedIn != true">
                                    <a class="nav-link" href="/login">
                                        Login
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="userLoggedIn == true">
                                    <a class="nav-link" style="cursor: pointer;" (click)="logout()">
                                        Logout
                                    </a>
                                </li>
                            </ul>
                            <!-- <div class="sing-up-button d-lg-none">
                                <a href="#">Sign Up Free</a>
                            </div> -->
                        </div>
                    </nav>
                </div>
            </div>
            <!-- Signup btn -->
            <!-- <div class="col-12 col-lg-2">
                <div class="sing-up-button d-none d-lg-block">
                    <a href="#">Sign Up Free</a>
                </div>
            </div> -->
        </div>
    </div>
</header>
<!-- ***** Header Area End ***** -->