import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-harvest-undo-btn',
  templateUrl: './harvest-undo-btn.component.html',
  styleUrls: ['./harvest-undo-btn.component.scss']
})
export class HarvestUndoBtnComponent implements OnInit {
  @Input() disabled: boolean
  
  constructor() { }

  ngOnInit(): void {
  }

}
