import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridOptions, ColDef, GridReadyEvent } from "ag-grid-community";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TimeStampComponent } from '../../components/cell-components';
import * as services from '../../services';

@Component({
  selector: 'app-harvests-review-dialog',
  templateUrl: './harvests-review-dialog.component.html',
  styleUrls: ['./harvests-review-dialog.component.scss']
})
export class HarvestsReviewDialogComponent implements OnInit {

  harvestFormDataArray = [];
  saveClick: boolean = false;
  harvestSaveError: boolean = false;
  successHarvests = [];
  failedHarvests = [];
  gridOptions: GridOptions;
  columnDefs: ColDef[] = [
    { field: 'name', headerName: 'Name', suppressSizeToFit: true, minWidth: 80, flex: 1},
    { field: 'selectedTemplate.name', headerName: 'Template Used', suppressSizeToFit: true, minWidth: 80, flex: 1},
    { field: 'startDateTimestamp', headerName: 'Start Date', cellRenderer: "timestampRenderer", minWidth: 50, suppressSizeToFit: true, flex: 1},
    { field: 'harvestDateTimestamp', headerName: 'Harvest Date', cellRenderer: "timestampRenderer", minWidth: 50, suppressSizeToFit: true, flex: 1},
    { field: 'endDateTimestamp', headerName: 'End Date', cellRenderer: "timestampRenderer", suppressSizeToFit: true,  minWidth: 50, flex: 1},
    { field: 'harvestDuration', headerName: 'Duration', suppressSizeToFit: true, flex: 1, minWidth: 30},
  ];

  constructor(
    private dialogRef: MatDialogRef<HarvestsReviewDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) private data: any,
    private deviceService: DeviceDetectorService,
    private notifierService: services.NotifierService,
    private harvestService: services.HarvestService
    ) { 
    this.harvestFormDataArray = this.data;
    this.gridOptions = <GridOptions> {
      context: {
        componentParent: this
    },
    //rowData: [],
    columnDefs: this.columnDefs,
    rowHeight: 35,
    headerHeight: 40,
    onGridReady: () => {
      if(!this.deviceService.isMobile()){
        this.gridOptions.api.sizeColumnsToFit();
      }
    },
    rowSelection: 'single',
    frameworkComponents: {
      timestampRenderer: TimeStampComponent,
    },
    }
  }

  getRowStyle(params) {
    if (params.data.addSuccess === undefined) {
      return null; // normally
    } else if(params.data.addSuccess === true) {
      return {background: '#90EE90'}; // after save if particular harvest add is a success
    } else if(params.data.addSuccess === false) {
      return {background: '#FF726F'}; // // after save if particular harvest add is a failure
    } else {
      return null;
    }
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.dialogRef.close({
      added: false,
      ids: this.harvestFormDataArray
    });
  }

  onFirstDataRendered(): void {
    this.gridOptions.api.sizeColumnsToFit();
  }

  goBackAndModify(): void {
    this.dialogRef.close({
      added: false,
      ids: this.failedHarvests
    })
  }

  async processHarvestsAdd() {
    this.saveClick = true;
    const harvestAddPromises = this.harvestFormDataArray.map(_harvestData => {
      let startDate: Date = new Date(_harvestData.startDate);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      return this.harvestService.add(_harvestData.selectedTemplate.uid, _harvestData.name, _harvestData.strainIds, _harvestData.harvestPhases, _harvestData.color, startDate, _harvestData.leaflogixId, _harvestData.leaflogixName)
    })

    return Promise.all(harvestAddPromises)
                  .then(response => {
                    response.forEach((promiseResponse, index) => {
                      this.harvestFormDataArray[index]['addSuccess'] = promiseResponse.data.success;
                    });
                    this.columnDefs = [...this.columnDefs , {headerName: 'Saved ?', field: 'addSuccess', suppressSizeToFit: true, flex: 1, minWidth: 50 ,valueFormatter: params =>  params.value ? 'Yes' : 'No'}]
                    if (this.harvestFormDataArray.every(formData => formData.addSuccess)) { // all save success
                      this.notifierService.success(`Successfully Saved ${response.length} New Harvests.`)
                      this.harvestSaveError = false;
                      this.saveClick = false;
                      this.dialogRef.close({
                        added: true,
                        ids: response.map(val => val.data.value) // ids passed to other components for push in array
                      })
                    } else if (this.harvestFormDataArray.every(formData => !formData.addSuccess)) { // all save fail
                      this.notifierService.error(`Error Saving ${response.length} New Harvests.`)
                      this.harvestSaveError = true;
                      this.saveClick = false;
                      this.dialogRef.close({
                        added: false,
                        ids: this.harvestFormDataArray
                      })
                    } else { // some pass some fail
                      this.harvestSaveError = true;
                      this.successHarvests = this.harvestFormDataArray.filter(val => val.addSuccess);
                      this.failedHarvests = this.harvestFormDataArray.filter(val => !val.addSuccess);
                      this.notifierService.success(`Successfully Saved ${this.successHarvests.length} New Harvests.`)
                      this.notifierService.error(`Error Saving ${this.failedHarvests.length} New Harvests.`)
                      this.gridOptions.getRowStyle = this.getRowStyle;
                      this.gridOptions.api.setRowData(this.harvestFormDataArray);
                      this.saveClick = false;
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  })
  }

  async saveAllHarvests() {
    await this.processHarvestsAdd()
  }

}
