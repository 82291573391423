import { Timestamp } from "@angular/fire/firestore";
import * as models from '../models';

export interface HarvestTableViewModel {
    harvestId?: string;
    name?: string;
    templateId?: string;
    //phases?: Array<HarvestTableViewPhaseModel>;
    startDate?: Timestamp;
    harvestDate?: Timestamp;
    endDate?: Timestamp;
    completed?: boolean;
    completedTasks?: number;
    totalTasks?: number;
    percentComplete?: number;
    color?: string;
    leaflogixName?: string;

    strains?: string;
    plantCount?: number;
    zone?: string;

    harvest?: models.Harvest;
}

export interface HarvestTableViewPhaseModel {
    name?: string;
    startDate?: Timestamp;
    endDate?: Timestamp;
}