<div class="row" style="width: 100%; margin: 0px;">
  <div class="col-sm-12" style="padding: 0;">
    <div class="chartWithOverlay" *ngIf="this.hasData">
      <google-chart
        #chart
        id="timeline-chart"
        [title]="harvestTimeline.title"
        [type]="harvestTimeline.type"
        [data]="harvestTimeline.data"
        [options]="harvestTimeline.options"
        [columns]="harvestTimeline.columns"
        [dynamicResize]="harvestTimeline.dynamicResize"
        style="width: 100%"
        (ready)="onReady()"
        [height]="myHeight">
      </google-chart>

      <div class="overlay" id="line-overlay" *ngIf="displayPlacemarker">
        <div class="vl" id="current-line"></div>
        <span class="tooltiptext">{{this.timeNow}}</span>
      </div>
    </div>

    <div *ngIf="!this.hasData">{{this.errorMessage}}</div>
  </div>
</div>