import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "list-cell",
    template: `
        <ul>
            <li class="bullet" *ngFor="let i of list" [innerHtml]="i | safeHtml"></li>
        </ul>
    `,
    styles: [
        `
        .bullet {
            line-height: 25px;
        }
        `
    ]
})
export class ListComponent implements ICellRendererAngularComp {
    private params: any;

    list: Array<string> = [];

    constructor(){}

    async agInit(params: any): Promise<void> {
        if(params.value == null){
            return;
        }
        this.params = params;

        this.list = this.params.value;

    }

    refresh(params: any): boolean {
        this.params = params;
        
        this.list = this.params.value;

        return false;
    }
}