import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-event-repeat',
  templateUrl: './event-repeat.component.html',
  styleUrls: ['./event-repeat.component.scss']
})
export class EventRepeatComponent implements OnInit {

  number: number = 1;
  interval: string = "weekly";
  endDate: FormControl;

  numbers: Array<number> = [];
  
  minDate: Date = moment.utc().add(1, 'day').toDate();
  maxDate: Date = moment.utc().add(5, 'year').toDate();

  daysOfWeek: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<EventRepeatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) { 
    if(data.repeatEvent){
      this.interval = data.interval;
      this.endDate = new FormControl(data.repeatEndDate);
      this.daysOfWeek = data.daysOfWeek;
    } else {
      this.daysOfWeek = [data.dayOfWeek]
      this.endDate = new FormControl(this.minDate);
    }
  }

  ngOnInit() {
    //this.fillNumbersArray(30);
    this.number = 1;
  }

  intervalChange(newVal: string){
    switch(newVal) { 
      case "daily": { 
        //this.fillNumbersArray(30);

        if(this.number > 30){
          this.number = 30;
        }

        break; 
      } 
      case "weekly": { 
        //this.fillNumbersArray(52);

        if(this.number > 52){
          this.number = 52;
        }

        break; 
      } 
      case "monthly": {
        //this.fillNumbersArray(12);

        if(this.number > 12){
          this.number = 12;
        }

        break;
      }
      default: { 
         //statements; 
         break; 
      } 
   } 
  }

  submit() {

    let repeat = false;
    if(this.interval != null && this.endDate != null){
      repeat = true;

      if(!this.endDate.valid){
        this.snackBar.open('End Date not valid', null, {
          duration: 3000,
          verticalPosition: 'top'
        })
        return;
      }
    }

    let dialogResult: any = {
      submitted: true,
      repeat: repeat,
      number: this.number,
      interval: this.interval,
      endDate: this.endDate.value,
      daysOfWeek: this.daysOfWeek,
    }
    //alert(JSON.stringify(dialogResult));
    this.dialogRef.close(dialogResult)
  }

  // fillNumbersArray(number: number) {
  //   this.numbers = [];
  //   for (let i = 1; i <= number; i++) {
  //     this.numbers.push(i);
  //   }
  // }

}
