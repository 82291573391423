import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EventEmitter } from 'stream';

@Injectable()
export class CalendarService {

    public sub = new Subject<boolean>();

    constructor() { }

    fireNext() {
        this.sub.next(true);
    }
}
