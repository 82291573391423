<h2 mat-dialog-title>Forgot Password</h2>
<mat-dialog-content>
  <form id="forgotPassword" class="fp-form" (ngSubmit)="sendEmail()">

    <mat-form-field class="fp-full-width">
      <input matInput placeholder="Email" name="emailAddress" required [(ngModel)]="emailAddress">
    </mat-form-field>
  
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="working">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="forgotPassword" [disabled]="working">
    <mat-spinner *ngIf="working" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!working">Send Email</span>
  </button>
</mat-dialog-actions>