import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-role-add-dialog',
  templateUrl: './role-add-dialog.component.html',
  styleUrls: ['./role-add-dialog.component.scss']
})
export class RoleAddDialogComponent implements OnInit {

  isCopy: boolean = false;
  copyRole: models.Role;
  saveClicked: boolean = false;
  name: string;
  createdRole: models.Role;

  displayCompanySelect: boolean = false;
  companies: models.Company[] = [];
  migrateClicked: boolean = false;
  selectedCompanies = new FormControl('');

  constructor(
    private dialogRef: MatDialogRef<RoleAddDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private notifierService: services.NotifierService
    , private roleService: services.RoleService
    , private companyService: services.CompanyService
    , private helperService: services.HelperService
  ) { 
    dialogRef.disableClose = true;

    if(data) {
      this.isCopy = data.isCopy || false;
      if(this.isCopy) {
        this.copyRole = data.copyRole;
      }
    }
  }

  ngOnInit(): void {
  }

  companyChange(newValue: models.Company[]) {
    this.selectedCompanies.setValue(newValue);
  }

  selectAll() {
    this.selectedCompanies.setValue(this.companies);
  }

  async saveClick() {
    if(this.name == null || this.name === ''){
      this.notifierService.error('Must enter in name for the new Role.')
      return;
    }

    this.saveClicked = true;

    let role: models.Role = {
      name: this.name,
      permissions: []
    }

    if(this.isCopy) {
      role.permissions = this.copyRole.permissions;
    }

    let companyId = this.helperService.currentCompanyId;
  
    let roleAddResponse = await this.roleService.save(role, companyId);

    if(roleAddResponse.success){
      this.notifierService.success('Successfully created Role');

      role.id = roleAddResponse.value;
      this.createdRole = role;
      this.checkOtherCompanies();
      
    } else {
      this.notifierService.brokenRules(roleAddResponse.brokenRules)
      //this.notifierService.error('There was an error creating the Role. ' + roleAddResponse.message);
      console.log(JSON.stringify(roleAddResponse.message))
      this.saveClicked = false;
    }
  }

  async checkOtherCompanies() {
    let companyResponse = await this.companyService.getAllCompaniesForUser();

    if(companyResponse.success) {
      let otherCompanies = companyResponse.value.filter(i => i.uid != this.helperService.currentCompanyId)

      if(otherCompanies.length == 0) {
        this.dialogRef.close(this.createdRole);
      } else {
        this.companies = otherCompanies;
        this.selectedCompanies.setValue(this.companies);
        this.displayCompanySelect = true;
        this.saveClicked = false;
      }
    } else {
      this.notifierService.brokenRules(companyResponse.brokenRules)
      console.log(companyResponse)
      this.saveClicked = false;
    }
  }

  close() {
    this.dialogRef.close(this.createdRole);
  }

  async migrateClick() {
    this.migrateClicked = true;

    let selectedCompanies: models.Company[] = this.selectedCompanies.value;
    for (let i = 0; i < selectedCompanies.length; i++) {
      const selectedCompany = selectedCompanies[i];
    
      let savableRole = this.helperService.deepCopy(this.createdRole);
      let roleAddResponse = await this.roleService.save(savableRole, selectedCompany.uid);
    }

    this.dialogRef.close(this.createdRole);
  }

}
