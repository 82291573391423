<app-header [perminantBackground]="true" [displayLogin]="false"></app-header>


<section class="clearfix" style="padding-top: 50px;">

    <div class="loader" *ngIf="loading">
        <!-- <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg> -->
        <img src="assets/img/Loading_Logo.gif" class="loadingImg" />
    </div>

    <ng-container *ngIf="!loading">
        <div class="container h-100" *ngIf="!loading && link == null && errorCode != 'link_expired' && errorCode != 'resent_link'">
            <h2 style="text-align: center; color: green">Link Invalid</h2>
            <br />
            <h4 style="text-align: center; color: green">
                This link is invalid.  Proceed to the <a href="/login">Login</a> screen to access the application or to reset your password.
            </h4>
        </div>
        <div class="container h-100" *ngIf="errorCode == 'link_expired'">
            <h2 style="text-align: center; color: green">Link Expired</h2>
            <br />
            <h4 style="text-align: center; color: green">
                The Welcome link expires after 3 hours for security reasons.  
                Click <a style="cursor: pointer; color: blue; text-decoration: underline;" (click)="resend()">here</a> to request another link to be sent to your email.
            </h4>
        </div>
        <div class="container h-100" *ngIf="!loading && errorCode == 'resent_link'">
            <h2 style="text-align: center; color: green">Resend Successful</h2>
            <br />
            <h4 style="text-align: center; color: green">
                Check your email to finish the registration process.
            </h4>
        </div>
        <div class="container h-100" *ngIf="!loading && link != null">
            <h2 style="text-align: center; color: green">Thank you for verifying your email</h2>
            <h2 class="email" style="text-align: center; color: green;">{{invitedEmail}}</h2>
            <br />
            <h3 style="text-align: center; color: green">Please login via a trusted authentication partner, and get growing!</h3>
            <br />

            <div class="sso-button-list-container">
                <div class="container-sso-button">
                    <div class="wrap-sso-button google">
                        <div class="sso-bgbutton"></div>
                        <button id="google_btn" class="sso-button" (click)="googleClick()">
                            <svg class="sso-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                              <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                            Google
                        </button>
                    </div>
                </div>
                
                <div class="container-sso-button">
                    <div class="wrap-sso-button microsoft">
                        <div class="sso-bgbutton"></div>
                        <button id="microsoft_btn" class="sso-button" (click)="microsoftClick()">
                            <img src="assets/img/microsoft_logo.png" alt="Logo" class="sso-logo">
                            Microsoft
                        </button>
                    </div>
                </div>
            </div>

            <div class="signup-or-line"><span>OR</span></div>

            <h3 style="text-align: center; color: green">Create a password for your account</h3>
            <br />
            <div class="welcome-panel">
                <form id="welcome" (ngSubmit)="setPassword()">
                    <mat-form-field class="full-width">
                      <input matInput placeholder="Password" type="password" name="password" (keyup)="onKey($event)"> <!-- [(ngModel)]="password" -->
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <input matInput placeholder="Confirm Password" type="password" name="confirm" [(ngModel)]="confirmPassword">
                    </mat-form-field>
                    <p [ngClass]="{'valid': lowercaseValid, 'invalid': !lowercaseValid }">A <b>lowercase</b> letter</p>
                    <p [ngClass]="{'valid': uppercaseValid, 'invalid': !uppercaseValid }">A <b>capital (uppercase)</b> letter</p>
                    <p [ngClass]="{'valid': numberValid, 'invalid': !numberValid }">A <b>number</b></p>
                    <p [ngClass]="{'valid': lengthValid, 'invalid': !lengthValid }">Minimum <b>8 characters</b></p>
                    <p [ngClass]="{'valid': specialCharValid, 'invalid': !specialCharValid }">A <b>special</b> character</p>
                    <p [ngClass]="{'valid': confirmCorrect, 'invalid': !confirmCorrect }">Password must <b>match</b></p>
                    <div class="center">
                        <button type="submit" class="submitBtn" mat-button title="Submit" [disabled]="disabledSubmit">
                            <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
                            <span style="font-weight: bold;" *ngIf="!saving">Submit</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>

</section>

<app-footer></app-footer>