
<mat-form-field>
    <mat-select [disabled]="disabled" [formControl]="teamCtrl" placeholder="Select Teams" multiple (closed)="setTeams()">
      <mat-option>
        <ngx-mat-select-search [formControl]="teamFilterCtrl"
                                placeholderLabel="Find team..."
                                noEntriesFoundLabel="no matching team found"></ngx-mat-select-search>
      </mat-option>
      <mat-select-trigger>
        {{teamCtrl.value && teamCtrl.value.length >= 1 ? teamCtrl.value[0].name : 'None Selected'}}
        <span *ngIf="teamCtrl.value?.length > 1" class="example-additional-selection">
          (+{{teamCtrl.value.length - 1}} {{teamCtrl.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option 
       *ngFor="let team of filteredTeams | async" 
       [value]="team"
       [matTooltip]="team.allTeamUsersTooltip"
       matTooltipPosition="left"
       >
        {{team.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  <div class="row" *ngIf="displayTeamList">
    <div class="col-12">
      <h5>Selected Teams:</h5>
      <ul class="list">
        <li *ngFor="let team of teamCtrl.value">
          {{team.name}}
        </li>
      </ul>
    </div>
  </div>
  