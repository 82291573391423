<div mat-dialog-title>
  <div class="flex-grid-thirds">
    <div class="col"></div>
    <div class="col">
      <h3>Create Demo Company</h3>
    </div>
    <div class="col"></div>
  </div>
</div>

<mat-dialog-content>
  <form id="createCompany" (ngSubmit)="createCompany()">
    <mat-form-field>
      <input matInput required name=company [(ngModel)]="companyName" placeholder="Company Name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Time Zone</mat-label>
      <mat-select required name="timezone" [(ngModel)]="timezone">
        <mat-option [(value)]="t.key" *ngFor="let t of timezones">{{t.display}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="addUserToCompany" name="addUser">Add User To Company</mat-checkbox>
    <mat-form-field *ngIf="addUserToCompany">
      <input matInput name="adminName" [(ngModel)]="adminName" placeholder="Admin Name">
    </mat-form-field>
    <mat-form-field *ngIf="addUserToCompany">
      <input matInput name="email" [(ngModel)]="adminEmail" placeholder="Admin email">
    </mat-form-field>
    <br>
    <mat-checkbox [(ngModel)]="freeVersion" name="freeVersion">Free Version</mat-checkbox>
    <!-- <br>
    <mat-checkbox [(ngModel)]="eventsSplit" name="eventsSplit">Split Events</mat-checkbox> -->
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="creatingCompany">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button id="createCompany-submit" mat-button type="submit" form="createCompany" [disabled]="creatingCompany">
    <mat-spinner *ngIf="creatingCompany" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!creatingCompany">Submit</span>
  </button>
</mat-dialog-actions>
