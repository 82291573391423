<h2 mat-dialog-title>Add Event from Library</h2>
<mat-dialog-content>
    <!-- <div class="row" *ngIf="needToPick">
      <div class="col-12">
        <div class="assignContainer">
          <ng-template matStepLabel>Harvest/Task Group</ng-template>
          <mat-button-toggle-group class="center" [(ngModel)]="assignToGroup">
            <mat-button-toggle [value]="false">Harvest</mat-button-toggle>
            <mat-button-toggle [value]="true">Task Group</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <input matInput [matDatepicker]="datePicker" placeholder="Event Date" [(ngModel)]="date"
                disabled="true">
                <mat-datepicker-toggle [disabled]="!needToPickDate" matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <ng-container *ngIf="!assignToGroup">
                <app-harvest-select 
                [includeCompleted]="false" 
                [(harvestId)]="selectedHarvestId">
                </app-harvest-select>
            </ng-container>

            <ng-container *ngIf="assignToGroup">
                <app-group-select
                [(groupId)]="selectedTaskGroupId">
                </app-group-select>
            </ng-container>
        </div>
    </div>

    <div class="row" *ngIf="selectedHarvestId != null && !assignToGroup">
        <div class="col-12">
            <app-phase-select [harvestId]="selectedHarvestId" [date]="date" [(phase)]="phase"></app-phase-select>
        </div>
    </div> -->

    <div class="row">
        <div class="col-12">
            <app-templated-task-select
                [(selectedTask)]="selectedTemplate">
            </app-templated-task-select>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saving">Cancel</button>
  <button id="template_add_save" mat-button [disabled]="saving" (click)="continue()">
    <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saving">Continue</span>
  </button>
</mat-dialog-actions>
