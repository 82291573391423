
<form class="form-container" id="createCompany" (ngSubmit)="createCompany()">
    <input class="form-input" type="text" name="name" [(ngModel)]="adminName" placeholder="Name" [disabled]="loading || complete">
    <input class="form-input" type="text" name="email" [(ngModel)]="adminEmail" placeholder="Email" [disabled]="loading || complete">
    <input class="form-input" type="text" name="company" [(ngModel)]="companyName" placeholder="Company Name" [disabled]="loading || complete">
    <input class="form-input" type="text" name="phone" [(ngModel)]="phone" [textMask]="{mask: mask, guide: true}" placeholder="Phone" [disabled]="loading || complete">

    <select class="form-input form-select" id="timezone" name="timezone" [(ngModel)]="timezone" [disabled]="loading || complete" required>
        <option value="" disabled selected hidden>Select Time Zone</option>
        <option [(value)]="t.key" *ngFor="let t of timezones">{{t.display}}</option>
    </select>

    <span class="message">{{message}}</span>

    <button type="submit" class="submitBtn" mat-button title="Submit" *ngIf="!complete" [disabled]="loading">
        <!-- <mat-spinner *ngIf="!deleteHarvestClick" diameter=25 strokeWidth=4></mat-spinner> -->
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        <span style="font-weight: bold;" *ngIf="!loading">{{btnText}}</span>
        <!-- <mat-icon *ngIf="!loading">backup</mat-icon> -->
    </button>
</form>