import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-templated-task-create-from-step-dialog',
  templateUrl: './templated-task-create-from-step-dialog.component.html',
  styleUrls: ['./templated-task-create-from-step-dialog.component.scss']
})
export class TemplatedTaskCreateFromStepDialogComponent implements OnInit {

  step: models.Step;
  name: string = '';
  saving: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<TemplatedTaskCreateFromStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private templatedTasksService: services.TemplatedTasksService,
    private notifierService: services.NotifierService,
    private router: Router
  ) { 
    if(data) {
      this.step = data.step;
    }
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.saving = true;
    let templatedTask: models.TemplatedTask = new models.TemplatedTask(this.name, this.step);

    this.templatedTasksService.save(templatedTask).then((id: string) => {
      this.notifierService.successWithBtn('Templated Task created from step', 'Go to Templated Task', () => {
        this.router.navigate(['/console', 'templates', 'task-library'], { queryParams: { tt: id } });
      });
      this.dialogRef.close();
    });
    this.saving = false;
  }

}
