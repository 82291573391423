
<mat-form-field>
    <mat-label>Select Phase(s)</mat-label>
    <mat-select [(ngModel)]="phaseIds" (selectionChange)="phaseChange($event.value)" multiple #phaseSelect="ngModel">
        <mat-select-trigger>
            {{title}}
        </mat-select-trigger>
        <mat-option disabled="disabled" class="filter-option">
            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllPhases(phaseSelect, phases)">
                Select All
            </button>
            <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAllPhases(phaseSelect)">
                Deselect All
            </button>
        </mat-option>
        <mat-option [value]="h" *ngFor="let h of phases">{{h}}</mat-option>
    </mat-select>
</mat-form-field>
