<app-loading-panel [show]="loading"></app-loading-panel>

<ng-container *ngIf="!loading">
    <div class="row" style="padding-bottom: 15px">
        <div class="col-sm-6">
            <h3 style="float: left;">Security Roles</h3>
            <div style="float: right;">
            </div>
        </div>
        <div class="col-sm-6" style="text-align: right">
            <button mat-raised-button class="pad-btn" [disabled]="!dirty()" title="Save Role" (click)="saveClick()">
                <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
                <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Role">save</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" [disabled]="!dirty()" title="Discard Changes" (click)="load()">
                <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
            </button>

            <button mat-raised-button class="gridbtn" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [disabled]="dirty()" (click)="add()" title="Add Role">
                    <mat-icon>add</mat-icon>
                    <span>Add Role</span>
                </button>
                <button mat-menu-item [disabled]="dirty()" (click)="copy()" title="Copy Role">
                    <mat-icon>file_copy</mat-icon>
                    <span>Copy Role</span>
                </button>
                <button mat-menu-item [disabled]="dirty() || deleting" (click)="delete()" title="Delete Role">
                    <i class="fa fa-spinner fa-spin" *ngIf="deleting"></i>
                    <ng-container *ngIf="!deleting">
                        <mat-icon>delete</mat-icon>
                        <span>Delete Role</span>
                    </ng-container>
                </button>
                <button mat-menu-item *ngIf="hasMultipleCompanies" [disabled]="dirty()" (click)="migrateClick()" title="Migrate Roles">
                    <mat-icon>arrow_forward</mat-icon>
                    <span>Migrate Roles from Other Company</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="row">
    </div>

    <div class="row">
        <div class="col-sm-4">
            <mat-form-field>
                <mat-label>Roles</mat-label>
                <mat-select [disabled]="dirty() || saving" [(ngModel)]="selectedRoleId" (selectionChange)="roleChange()">
                    <mat-option [(value)]="role.id" *ngFor="let role of roles">{{role.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12">

            <mat-card style="box-shadow: none; margin-bottom: 10px;">
                <mat-card-content>
                    <mat-checkbox class="permissions-select checklist-leaf-node" style="margin-left: 23px;" 
                        [(ngModel)]="selectedRole.powerUser" 
                        (change)="powerUserChecked($event)">
                        Power User
                    </mat-checkbox>
                </mat-card-content>
            </mat-card>

            <mat-tree class="permissions-select" [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                    <button mat-icon-button disabled></button>
                    <mat-checkbox class="checklist-leaf-node" 
                        [checked]="checklistSelection.isSelected(node)"
                        [disabled]="selectedRole?.powerUser"
                        (change)="leafItemSelectionToggle(node)">{{node.item}}
                    </mat-checkbox>
                </mat-tree-node>

                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <mat-checkbox [checked]="descendantsAllSelected(node)"
                        [indeterminate]="descendantsPartiallySelected(node)"
                        [disabled]="selectedRole?.powerUser"
                        (change)="itemSelectionToggle(node)">{{node.item}}</mat-checkbox>
                    <!-- <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button> -->
                </mat-tree-node>
            </mat-tree>
        </div>
    </div>
</ng-container>