import { Component, OnInit, ViewChild, Input, ElementRef, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleChartComponent, ChartType } from 'angular-google-charts';
import { HelperService } from 'app/services';
import { TimelineHelper } from './timeline-helper';


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {
  @Input() dataList: Array<any>;
  @Input() dateFormat: string;
  @Input() colors: Array<string>;
  // events: Array<Event>;
  timeNow: string;
  hasData: boolean;
  currentRoute: string;
  errorMessage: string = 'There are no events for this harvest.';
  defaultColors = [
    '#009933', //green
    '#0066ff', //lite-blue
    '#8114ee', //lite-purple
    '#168D8D', //turqouis
    '#0000cc', //blue
    '#C62424', //red
    '#51168D', //purple
    '#C77A2D', //orange
    '#009933', //green
    '#0066ff', //lite-blue
    '#8114ee', //lite-purple
    '#168D8D', //turqouis
    '#0000cc', //blue
    '#C62424', //red
    '#51168D', //purple
    '#C77A2D', //orange
  ];

  myHeight: number = undefined;
  public harvestTimeline = {
    title: 'Harvest Timeline',
    type: ChartType.Timeline,
    data: [],
    columns: [
      {type: 'string', id: 'row'},
      {type: 'string', id: 'dummy bar label'},
      {type: 'string', role: 'tooltip', 'p': {'html': true}},
      {type: 'string', id: 'style', role: 'style'},
      {type: 'date', id: 'Start'},
      {type: 'date', id: 'End'},
    ],
    options: {
      animation: {
        duration: 250,
        easing: 'linear',
        startup: true
      },
      avoidOverlappingGridlines: false,
      timeline: { 
        showRowLabels: false,
      },
      //height: 200,
      tooltip: { isHtml: true },
      // height: '100%',
      hAxis: {
        // viewWindow: {

        // },
        format: "",
        gridlines: {count: 100}
      },
      //colors: [],
      // colors: [
      //   '#009933', //green
      //   '#0066ff', //lite-blue
      //   '#8114ee', //lite-purple
      //   '#168D8D', //turqouis
      //   '#0000cc', //blue
      //   '#C62424', //red
      //   '#51168D', //purple
      //   '#C77A2D', //orange
      //   '#009933', //green
      //   '#0066ff', //lite-blue
      //   '#8114ee', //lite-purple
      //   '#168D8D', //turqouis
      //   '#0000cc', //blue
      //   '#C62424', //red
      //   '#51168D', //purple
      //   '#C77A2D', //orange
      // ],
    },
    dynamicResize: true,
  };

  @ViewChild('chart', { static: false })
  public chart!: GoogleChartComponent;

  displayPlacemarker: boolean = true;

  constructor(
    private timelineHelper: TimelineHelper,
    private router: Router,
    private helperService: HelperService
  ) { 
    this.currentRoute = this.router.url

    if(this.currentRoute == '/console/harvests') {
      this.errorMessage = 'There are no current or future harvests to display.'
    }
  }

  createHtmlTooltip() {
    return '<div><b>Phase Name:</b></div>' +
    `<div>${new Date(2022, 3, 10).toLocaleString().split(',')[0]} - ${new Date(2022, 5, 1).toLocaleString().split(',')[0]}</div>`
  }

  ngOnInit(): void { }

  async ngOnChanges(changes) {
    if (changes.dataList.currentValue && this.dataList.length > 0) {
      this.hasData = true;
      this.harvestTimeline.data = this.dataList
      this.harvestTimeline.options.hAxis.format = this.dateFormat || "M/d"
      //this.harvestTimeline.options.colors = this.colors || this.defaultColors
      // await this.helperService.wait(50);
      // this.hasData = true;
    } else {
      this.hasData = false;
    }
  }

  onReady() {
    this.placeMarker()
    this.timeNow = new Date().toLocaleDateString('en-us', { weekday:"short", month: "short", day: "numeric"})
    this.calcHeight();
  }

  placeMarker() {
    let percent = this.timelineHelper.calculateCurrentTimeMarker(this.chart.data);

    if(percent > 1){
      this.displayPlacemarker = false;
      return;
    }
    const chartHeight = document.querySelector('rect').height.baseVal.value
    document.getElementById('current-line').style.height = `${chartHeight}` + 'px'
    document.getElementById('line-overlay').style.left = `${percent * 100}` + '%'
  }

  calcHeight() {
    let element = (<HTMLInputElement>document.getElementById("timeline-chart"))

    let calcHeight = element?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.clientHeight
    if(calcHeight != null){
      this.myHeight = calcHeight + 70;
    } else {
      //this.myHeight = 200;
    }
  }
}
