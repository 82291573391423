
<button mat-raised-button class="gridbtn dutchie-btn" *ngIf="canSeeDutchie" 
[matMenuTriggerFor]="dutchieMenu" [matBadge]="unlinkedDutchieBadge" [matBadgeHidden]="unlinkedDutchieBadge == null">
    <img style="height: 24px; width: 24px;" *ngIf="!dutchieProcessing" src="assets/img/Dutchie_Mark_Red_32x32.png">
    <div *ngIf="dutchieProcessing" style="margin-top: 5px; margin-bottom: 5px; display: inline-block">
        <mat-spinner diameter=25 strokeWidth=4></mat-spinner>
    </div>
</button>

<mat-menu #dutchieMenu="matMenu">
    <app-leaflogix-harvest-sync-btn (addedHarvests)="addedHarvests.emit($event)" [(processing)]="dutchieProcessing"></app-leaflogix-harvest-sync-btn>
    <app-leaflogix-sync-btn [(processing)]="dutchieProcessing"></app-leaflogix-sync-btn>
</mat-menu>
