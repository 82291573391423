<h2 id="sensor_add_title" mat-dialog-title>
  <span style="display: inline-block">Subtasks</span>
  <button style="float: right" mat-icon-button (click)="add()">
    <mat-icon>add</mat-icon>
  </button>
</h2>
<mat-dialog-content style="min-width:50vw">
  <div *ngIf="subtasks.length == 0" class="none-set">No subtasks set</div>
  <div *ngIf="subtasks.length != 0" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let subtask of subtasks; let i = index" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <div class="row">
        <div class="col-12">
            <mat-form-field class="subtask_input" appearance="outline">
              <div matPrefix class="example-handle" cdkDragHandle>
                <mat-icon>drag_handle</mat-icon>
              </div>
              <!-- <mat-label>Subtask</mat-label> -->
              <input matInput placeholder="New Subtask" type="text" [(ngModel)]="subtask.subtask.title">
              <button mat-icon-button class="badge-empty" matSuffix [matBadge]="0" [matBadgeHidden]="subtask.subtask.description == null || subtask.subtask.description == ''" (click)="more(subtask)">
                <mat-icon>read_more</mat-icon>
              </button>
              <button mat-icon-button matSuffix (click)="remove(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-form-field>
        </div>
      </div>
      <div class="row" style="padding-left: 15px;" *ngIf="subtask.displayDescription">
        <div class="col-12">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput [(ngModel)]="subtask.subtask.description"></textarea>
            </mat-form-field>
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button (click)="save()">Ok</button>
</mat-dialog-actions>
