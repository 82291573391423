import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-harvest-select-dialog',
  templateUrl: './harvest-select-dialog.component.html',
  styleUrls: ['./harvest-select-dialog.component.scss']
})
export class HarvestSelectDialogComponent implements OnInit {

  header: string = 'Select Harvests';
  negativeBtn: string = 'Cancel';
  affirmativeBtn: string = 'OK';
  harvests: Array<models.Harvest> = [];
  selectedHarvests: Array<models.Harvest> = [];

  constructor(
    private dialogRef: MatDialogRef<HarvestSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.header = data.header;
    this.negativeBtn = data.negativeBtn;
    this.affirmativeBtn = data.affirmativeBtn;
    this.harvests = data.harvests;
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(this.selectedHarvests);
  }

}
