import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-step-add-from-library-dialog',
  templateUrl: './step-add-from-library-dialog.component.html',
  styleUrls: ['./step-add-from-library-dialog.component.scss']
})
export class StepAddFromLibraryDialogComponent implements OnInit {

  selectedTemplate: models.TemplatedTask;

  type: string = 'single';

  single: number = 0;

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  list: Array<number> = [0];

  rangeStart: number = 0;
  rangeEnd: number = 1;

  constructor(
    private dialogRef: MatDialogRef<StepAddFromLibraryDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private notifierService: services.NotifierService
  ) { }

  ngOnInit(): void {
  }

  getValue(): any {
      switch (this.type) {
          case 'single': {
              return this.single.toString();
          }
          case 'list': {
              if (this.list.length == 0) {
                  return ['0'];
              }
              return this.list.map(i => +i).sort((a, b) => {return a-b}).join(',');
          }
          case 'range': {
              return this.rangeStart + '-' + this.rangeEnd;
          }
          default: {
              return '0';
          }
      }
  }

  add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      if (this.list == null) {
          this.list = [];
      }

      // Add our fruit
      if ((value || 0)) {
          this.list.push(+value);
      }

      // Reset the input value
      if (input) {
          input.value = '';
      }
  }

  remove(fruit: string): void {
      const index = this.list.indexOf(+fruit);

      if (index >= 0) {
          this.list.splice(index, 1);
      }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if(this.selectedTemplate == null) {
      this.notifierService.error('Must select a Templated Task from the Task Library');
      return;
    }

    let step: models.Step = new models.Step(this.selectedTemplate);
    step.day = this.getValue();
    this.dialogRef.close(step);
  }
}
