<div mat-dialog-title>
    <div class="flex-grid-thirds">
        <div class="col"></div>
        <div class="col">
            <h3>Filter Calendar</h3>
        </div>
        <div class="col"></div>
    </div>
</div>

<mat-dialog-content style="min-height: 40vh; min-width: 50vw;">
    <div class="row">
        <div class="col-12">
            <app-harvest-select-multiple 
                [selectByDefault]="harvestSelectByDefault" 
                [(selectedHarvests)]="harvests"
                [initialSelectedIds]="initialHarvestIds">
            </app-harvest-select-multiple>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-group-select-multiple 
                [selectByDefault]="groupSelectByDefault" 
                [(selectedGroups)]="groups"
                [initialSelectedIds]="initialGroupIds">
            </app-group-select-multiple>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Schedule For User:
        </div>
        <div class="col-sm-8">
            <mat-form-field>
                <mat-label>Select a User</mat-label>
                <mat-select [(value)]="selectedUserId">
                    <mat-option [value]="u.id" *ngFor="let u of users">{{u.displayName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Schedule For Team:
        </div>
        <div class="col-sm-8">
            <mat-form-field>
                <mat-label>Select a Team</mat-label>
                <mat-select [(value)]="selectedTeamId">
                    <mat-option [value]="t.uid" *ngFor="let t of teams">{{t.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Complete Status:
        </div>
        <div class="col-sm-8">
            <mat-radio-group [(ngModel)]="completeStatus">
                <mat-radio-button value="incomplete">Incomplete</mat-radio-button>
                <mat-radio-button value="complete">Complete</mat-radio-button>
                <mat-radio-button value="both">Both</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Only my tasks:
        </div>
        <div class="col-sm-8">
            <mat-slide-toggle [(ngModel)]="onlyMyTasks" class="gridbtn"></mat-slide-toggle>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Phases:
        </div>
        <div class="col-sm-8">
            <app-phase-select-multiple [getFromTemplate]="true" [(phaseIds)]="phases"></app-phase-select-multiple>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Zones:
        </div>
        <div class="col-sm-8">
            <mat-form-field>
                <mat-label>Select Zones</mat-label>
                <mat-select multiple [(ngModel)]="zoneIds">
                    <mat-option [value]="zone.id" *ngFor="let zone of allZones">{{zone.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            Tags:
        </div>
        <div class="col-sm-8">
            <mat-form-field>
                <mat-label>Select Tags</mat-label>
                <mat-select multiple [(ngModel)]="tags">
                    <mat-option [value]="tag" *ngFor="let tag of allTags">{{tag}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="save()">Save Filters</button>
    <button mat-button (click)="default()">Clear Filters</button>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="filter()">
        <span>Filter</span>
    </button>
</mat-dialog-actions>