import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';

@Component({
  selector: 'app-post-harvest-edit',
  templateUrl: './post-harvest-edit.component.html',
  styleUrls: ['./post-harvest-edit.component.scss']
})
export class PostHarvestEditComponent implements OnInit {
  isAdd: boolean = false;
  postHarvest: models.PostHarvest = {};

  saveClicked: boolean = false;

  dataTypes: Array<string> = [];

  constructor(
    private dialogRef: MatDialogRef<PostHarvestEditComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data){
      this.isAdd = data.isAdd;
      this.postHarvest = data.postHarvest;
    }
    this.dataTypes = Object.values(models.DataTypes);
    if(this.postHarvest.dataType == null){
      this.postHarvest.dataType = 'text';
    }
  }

  ngOnInit(): void {
  }

  saveClick() {
    this.dialogRef.close(this.postHarvest);
  }

}
