import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import * as models from '../../../models';
import * as services from '../../../services';
import { MatChipInputEvent } from '@angular/material/chips';
import { GridApi, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { CheckboxLabelComponent, MatCheckboxComponent, MatInputComponent, MomentComponent, ValueEditButtonComponent } from 'app/components/cell-components';
import { DeviceDetectorService } from 'ngx-device-detector';

import { MatDialog } from '@angular/material/dialog';
import { NotesComponent, ZoneExplanationDialogComponent, ZoneUpdateComponent } from 'app/dialogs';
import { isObservable, Subscription, SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-zone-setup',
  templateUrl: './zone-setup.component.html',
  styleUrls: ['./zone-setup.component.scss']
})
export class ZoneSetupComponent implements OnInit {

  @Input() edit: boolean = true;

  private strainsValue: Array<models.HarvestPhaseStrain> = [];
  @Input()
  get strains() {
    return this.strainsValue;
  }
  @Output() strainsChange = new EventEmitter();
  set strains(val) {
    if(val == null){
      val = [];
    }
    this.strainsValue = val;
    this.strainsChange.emit(this.strainsValue);
    //this.selectedIds = this.setSelectedIds(val);
  }
  //selectedIds: Array<string> = [];

  // public gridOptions: GridOptions;
  // private gridApi: GridApi;

  saving: boolean = false;
  
  zoneObject: models.DbZone;
  initialZoneObject: models.DbZone;

  zoneDisplays: Array<any> = [];

  columns: Array<any> = [
    {
        headerName: "Zone Name",
        field: "name"
    }
  ]

  sensors: models.Sensor[];

  // setSelectedIds(strains: Array<models.HarvestPhaseStrain>) {
  //   let retVal = []
  //   strains.forEach(strain => {
  //     if(strain.zones != null){
  //       strain.zones.forEach(zone => {
  //         retVal.push(...zone.zoneId)
  //       });
  //     }
  //   });
  //   return retVal;
  // }

  getSelectedZones() {
    let zoneDictionary = new Map<string, Array<models.HarvestPhaseStrainZone>>();
    for (let _ = 0; _ < this.zoneDisplays.length; _++) {
      const zone = this.zoneDisplays[_];
      let keys = Object.keys(zone);
      keys.forEach(key => {
        if(zone[key].checked) {
          if(zone[key].strains == null || zone[key].strains.length == 0) {

            let childIds = this.zoneService.getListOfChildIds(this.zoneObject.list, zone[key].id);
            let childZoneDisplays = this.findSelectedDisplaysByIds(childIds);

            let childZoneDisplaysWithStrains = childZoneDisplays.filter(i => i.strains != null && i.strains.length > 0);

            let strainIds: Array<string> = [];

            childZoneDisplaysWithStrains.forEach(item => {
              strainIds.push(...item.strains.map(i => i.id));
            })

            strainIds = [...new Set(strainIds)];

            strainIds.forEach(strainId => {
              let z = zoneDictionary[strainId];
              if (z == null){
                z = [];
              }
              let newZoneSetter: models.HarvestPhaseStrainZone = {
                zoneId: zone[key].id,
                //count: strain.count
              }
              z.push(newZoneSetter)
              zoneDictionary[strainId] = z;
            });
          } else {
            zone[key].strains.forEach(strain => {
              let z = zoneDictionary[strain.id];
              if (z == null){
                z = [];
              }
              let newZoneSetter: models.HarvestPhaseStrainZone = {
                zoneId: zone[key].id,
                count: strain.count
              }
              z.push(newZoneSetter)
              zoneDictionary[strain.id] = z;
            });
          }
        }
      });
    }
    return zoneDictionary;
  }

  findSelectedDisplaysByIds(selectedIds: Array<string>){
    let retVal = []
    for (let _ = 0; _ < this.zoneDisplays.length; _++) {
      const zone = this.zoneDisplays[_];
      let keys = Object.keys(zone);
      keys.forEach(key => {
        if(selectedIds.includes(zone[key].id)){
          retVal.push(zone[key]);
        }
      });
    }
    return retVal;
  }

  getSelectedIds() {
    let ids: Array<string> = [];
    let zoneSetters = this.getSelectedZones();
    let keys = Object.keys(zoneSetters);
    keys.forEach(key => {
      zoneSetters[key].forEach((zoneSetter: models.HarvestPhaseStrainZone) => {
        ids.push(zoneSetter.zoneId)
      });
    });
    return ids;
  }

  get dirty(): boolean {
    let x = JSON.stringify(this.initialZoneObject);
    let y = JSON.stringify(this.zoneObject);
    return x !== y;
  }

  borderTopStyle(row: any){
    if(row['value0'] != null){
      return '2px solid grey'
    }
  }

  // cursorStyle(zone: any){
  //   if(this.edit && zone != null){
  //     return 'pointer'
  //   }
  // }

  class(zone: any){
    if(this.edit && zone != null){
      return 'pointer'
    }
  }

  getPlacementCountForStrain(strain: models.HarvestPhaseStrain): number {
    let placements: number = 0;
    this.zoneDisplays.forEach(zoneDisplay => {
      let keys = Object.keys(zoneDisplay);
      keys.forEach(key => {
        let zone = zoneDisplay[key];
        let strains = zone.strains || [];
        let applicableStrains = strains.filter(i => i.id == strain.strainId);
        placements += applicableStrains.map(i => i.count).reduce((partial_sum, a) => partial_sum + a, 0);
      });
    })
    // if(strain.zones == null) {
    //   return placements;
    // }
    // strain.zones.forEach(zone => {
    //   if(zone.count != null){
    //     placements += zone.count;
    //   }
    // });
    return placements;
  }

  constructor(
    private zoneService: services.ZoneService
    , private deviceService: DeviceDetectorService
    , private helperService: services.HelperService
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private sensorService: services.SensorService
    , private leaflogixService: services.LeaflogixService
  ) {
    // this.gridOptions = <GridOptions>{
    //   context: {
    //       componentParent: this
    //   },
    //   //rowData: [],
    //   //columnDefs: this.columns,
    //   onGridReady: () => {
    //     this.gridApi = this.gridOptions.api;
    //     this.resizeCols();
    //   },
    //   //rowHeight: 48, // recommended row height for material design data grids,
    //   frameworkComponents: {
    //       checkboxLabelComponent: CheckboxLabelComponent,
    //       valueEditButtonComponent: ValueEditButtonComponent
    //   },
    //   //rowSelection: 'none',
    //   //selectionChanged: this.onSelectionChanged,
    //   defaultColDef: {
    //     resizable: false,
    //     sortable: false,
    //     suppressSizeToFit: false
    //   },
    //   // onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
    //   //   this.onDoubleClicked(event);
    //   // },
    //   getRowStyle: params => {
    //     if (params.node.data.value0 != null) {
    //       return { 'border-top': '1px solid grey' };
    //     } 
    //     // else {
    //     //   return { 'border-top': 'none' };
    //     // }
    //   },
    // };
  }

  ngOnInit(): void {
    this.getLeaflogix();
    this.load();
  }

  load() {
    this.get();
    if(this.edit){
      this.getSensors();
    }
  }

  // async resizeCols() {
  //   if(!this.deviceService.isMobile() && this.gridApi != null){
  //     await this.helperService.wait(50);
  //     this.gridApi.sizeColumnsToFit();

  //     // const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
  //     // this.gridOptions.columnApi.autoSizeColumns(colIds)
  //   }
  // }

  async get(){
    this.zoneObject = await this.zoneService.getZonesObject();
    if(this.zoneObject == null){
      this.zoneObject = {
        zoneTitles: [],
        list: []
      };
    }
    this.initialZoneObject = this.helperService.deepCopy(this.zoneObject);
    this.setHeaders(this.zoneObject.zoneTitles);
    this.zoneObject.list = this.helperService.sortArrayByStringField(this.zoneObject.list, 'name');
    this.setData();
  }

  setHeaders(titles: Array<string>) {
    //set columns
    let columns = [];
    for (let i = 0; i < titles.length; i++) {
      const title = titles[i];
      let column: any = {
        headerName: title,
        field: 'value' + i, // + '.name',
      };
      columns.push(column)
    }
    this.columns = columns;

  }

  removeZonesBelowLevel(level: number){
    let i = 1;
    this.loopZonesToRemoveSubZones(this.zoneObject.list, i, level);

    this.setData();
  }

  loopZonesToRemoveSubZones(list: Array<models.Zone>, iterator: number, level: number){
    for (let i = 0; i < list.length; i++) {
      const zone = list[i];
      
      if(iterator >= level){
        zone.zones = [];
      } else {
        zone.zones = this.loopZonesToRemoveSubZones(zone.zones, iterator+1, level);
      }
    }

    return list;
  }

  setData() {
    this.setZonePlacements(this.zoneObject.list);
    let list = [];
    this.zoneObject.list.forEach(zone => {
      list = this.addZoneToDisplay(list, zone, 0, null);
      //list.push(...item);
    });
    this.zoneDisplays = list;
    //this.resizeCols()
  }

  addZoneToDisplay(list: Array<any>, zone: models.Zone, level: number, parentId: string) : Array<any> {
    let zoneDisplay = {}

    let strains = this.strains.filter(i => i.zones != null && i.zones.find(i => i.zoneId == zone.id));
    //let checked = this.selectedIds != null && this.selectedIds.find(i => i == zone.id) != null;
    let checked = strains.length > 0;
    let zoneDisplayStrains = [];
    strains.forEach(strain => {
      let count = strain.zones.find(i => i.zoneId == zone.id).count;
      if(count != null){
        zoneDisplayStrains.push({
          id: strain.strainId,
          count: count,
        })
      }
    });
    zoneDisplay['value' + level] = {
      id: zone.id,
      name: zone.name,
      checked: checked,
      placements: zone.placements,
      strains: zoneDisplayStrains
    };
    let parentLevel = level-1;

    //find parent node that doesn't already have a value for this level
    let foundParentZone = list.find(
      i => i['value' + parentLevel] != null
      && i['value' + parentLevel].id == parentId
      && i['value' + level] == null);
    if(foundParentZone != null){
      foundParentZone['value' + level] = zoneDisplay['value' + level];
    } else {
      list.push(zoneDisplay);
    }
    zone.zones.forEach(subZone => {
      let subLevel = level+1;
      list = this.addZoneToDisplay(list, subZone, subLevel, zone.id);
      // subZoneDisplays.forEach(subZoneDisplay => {
      // });
    });
    return list;
  }

  setGroups() {
    let notesDialog = this.dialog.open(NotesComponent, {
      width: '50%',
      data: {
        list: this.zoneObject.zoneTitles,
        header: 'Zone Groups',
        placeholder: 'Zone Group'
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if(data != null){
        this.zoneObject.zoneTitles = data;
        this.setHeaders(this.zoneObject.zoneTitles);
        this.removeZonesBelowLevel(this.zoneObject.zoneTitles.length);
      }
    })
  }

  // onSelectionChanged() {
  //   const selectedRows = this.gridApi.getSelectedRows();
  //   //this.zone = selectedRows[0];
  // }

  findInList(list: Array<models.Zone>, id: string) : models.Zone {
    let found = list.find(i => i.id == id);

    if(found){
      return found;
    }

    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      let foundInList = this.findInList(element.zones, id);
      if(foundInList){
        return foundInList;
      }
    }

    return null;
  }

  zoneClick(zone: any){
    if(zone != null){
      this.editItem(zone.id);
    }
  }

  editItem(id: string){
    //popup to edit zone
    //let zone = this.zoneObject.list.find(i => i.id == event.data.id);
    let zone = this.findInList(this.zoneObject.list, id);

    let inObj = {
      zone: zone,
      zoneTitles: this.zoneObject.zoneTitles,
      allZones: this.zoneObject.list,
      sensors: this.sensors
    }
    let dialogRef = this.dialog.open(ZoneUpdateComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        let zone: models.Zone = result.zone;
        let parentIds: Array<string> = result.parentIds;
        let del: boolean = result.delete || false;

        let currentParentIds = this.zoneService.getListOfParents(this.zoneObject.list, zone.id);

        //this means we are to delete the zone or the position has changed
        if(del || JSON.stringify(parentIds) != JSON.stringify(currentParentIds)){
          let oldList = this.zoneObject.list;
          if(currentParentIds.length != 0){
            currentParentIds.forEach(parentId => {
              oldList = oldList.find(i => i.id == parentId).zones;
            });
          }
          const index = oldList.indexOf(zone);
          if (index > -1) {
            oldList.splice(index, 1);
          }
          //oldList = oldList.filter(i => i.id != zone.id);
        }

        if(del){
          this.setData();
          return;
        }

        //add/update in list of zones
        let list = this.zoneObject.list;
        if(parentIds.length != 0){
          parentIds.forEach(parentId => {
            list = list.find(i => i.id == parentId).zones;
          });
        }
        let found = list.find(i => i.id == zone.id);
        if(found == null){
          list.push(zone);
        } else {
          found = zone;
        }
        list = this.helperService.sortArrayByStringField(list, 'name');

        //make sure it is populated on grid
        this.setData();
      }
    })
  }

  addZone(){
    //popup to add zone
    let inObj = {
      zone: {},
      zoneTitles: this.zoneObject.zoneTitles,
      allZones: this.zoneObject.list,
      sensors: this.sensors
    }
    let dialogRef = this.dialog.open(ZoneUpdateComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        let zone: models.Zone = result.zone;
        let parentIds: Array<string> = result.parentIds;
        let del: boolean = result.delete || false;

        if(del){
          return;
        }

        //add/update in list of zones
        let list = this.zoneObject.list;
        if(parentIds.length != 0){
          parentIds.forEach(parentId => {
            list = list.find(i => i.id == parentId).zones;
          });
        }
        let found = list.find(i => i.id == zone.id);
        if(found == null){
          list.push(zone);
        } else {
          found = zone;
        }
        list = this.helperService.sortArrayByStringField(list, 'name');

        //make sure it is populated on grid
        this.setData();
      }
    })
  }

  checkChange(zone: any, i: number, checked: boolean) {
    zone.checked = checked;
    this.check(zone.id, checked);

    let foundZone = this.zoneService.getZone(this.zoneObject.list, zone.id);

    if((foundZone.zones == null || foundZone.zones.length == 0) 
        && zone.strains.length == 0 && this.strains[0] != null) {
      this.add(zone.strains, zone.placements)
      // let newStrain = {
      //   id: this.strains[0].strainId,
      //   placements: zone.placements
      // }
      // zone.strains.push(newStrain);
    }
  }

  check(id: string, checked: boolean){
    let list = this.zoneObject.list;

    let checkedIds: Array<string> = [];
    let uncheckIds: Array<string> = [];

    //if checked - check all parents - check all children
    if(checked){ 
      //get list of parent ids
      let parentIds = this.zoneService.getListOfParents(list, id);
      checkedIds.push(...parentIds);

      // parentIds.forEach(parentId => {
      //   list = list.find(i => i.id == parentId).zones;
      // });
      let zone = this.zoneService.getZone(list, id);
      checkedIds.push(...this.getIdsForChildren(zone.zones));
    }

    //if unchecked - uncheck parents that don't have siblings checked - 
    if(!checked){
      uncheckIds.push(...this.getParentsToUncheck(list, [], id));

      let zone = this.zoneService.getZone(list, id);
      uncheckIds.push(...this.getIdsForChildren(zone.zones));
    }

    //set display values on grid
    this.zoneDisplays.forEach(zoneDisplay => {
      let keys = Object.keys(zoneDisplay);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if(checkedIds.includes(zoneDisplay[key].id)){
          zoneDisplay[key].checked = true;

          if(i == keys.length-1 && zoneDisplay[key].strains.length == 0 && this.strains[0] != null) {
            this.add(zoneDisplay[key].strains, zoneDisplay[key].placements);
            // let newStrain = {
            //   id: this.strains[0].strainId,
            //   placements: zoneDisplay[key].placements
            // }
            // zoneDisplay[key].strains.push(newStrain);
          }
        }
        if(uncheckIds.includes(zoneDisplay[key].id)){
          zoneDisplay[key].checked = false;
        }
      }
    })

    // //refresh grid
    // var params = {
    //   force: true,
    //   suppressFlash: true,
    // };
    // this.gridApi.refreshCells(params);
  }

  getIdsForChildren(list: Array<models.Zone>) {
    let ids: Array<string> = [];
    list.forEach(item => {
      ids.push(item.id);
      ids.push(...this.getIdsForChildren(item.zones));
    });
    return ids;
  }

  getParentsToUncheck(zones: Array<models.Zone>, list: Array<string>, id): Array<string> {
    let parent = this.zoneService.getParent({zones: zones}, id);

    if(parent == null || parent.zones == null){
      return list;
    }
    
    let siblingIds = parent.zones.filter(i => i.id != id).map(i => i.id);
    let selectedIds = this.getSelectedIds();
    let intersected = siblingIds.filter(value => selectedIds.includes(value));

    if(intersected.length == 0 && parent.id != null){
      list.push(parent.id);
      list.push(...this.getParentsToUncheck(zones, list, parent.id));
    }

    return list;
  }

  async save() {
    this.saving = true;
    this.setZonePlacements(this.zoneObject.list);
    await this.zoneService.update(this.zoneObject);
    this.notifierService.success('Successfully Saved Zones');
    this.saving = false;
    this.get();
  }

  setZonePlacements(list: Array<models.Zone>){
    list.forEach(zone => {
      let placements: number = 0;

      if(zone.zones == null || zone.zones.length == 0){
        placements = zone.placements;
      } else {
        zone.zones.forEach(subZone => {
          placements += this.findPlacements(subZone);
        });
      }
      zone.placements = placements;
    });
  }

  findPlacements(zone: models.Zone) {
    if(zone.zones != null && zone.zones.length > 0){
      let placements: number = 0;
      zone.zones.forEach(subZone => {
        placements += this.findPlacements(subZone);
      });
      zone.placements = placements;
    }
    return zone.placements;
  }

  add(strains: any[], placements: number){
    let currentStrainIds = strains.map(i => i.id);
    let availableStrains = this.strains.filter(i => !currentStrainIds.includes(i.strainId));
    
    let counts = strains.map(i => i.count);
    let total = counts.reduce((partial_sum, a) => partial_sum + a, 0);

    let newStrain = {
      id: availableStrains[0].strainId,
      count: placements - total
    }
    strains.push(newStrain);
  }
  
  remove(strains: any[], strain: any){
    const index = strains.indexOf(strain);
    if (index > -1) {
      strains.splice(index, 1);
    }
  }
  
  countValueChange(strains: any[], strain: any, newValue, placements: number) {
    if(newValue > placements){
      newValue = placements;
    }
    strain.count = newValue;

    let remainder = placements - newValue;

    let otherStrains = strains.filter(i => i.id != strain.id);
    let counts = otherStrains.map(i => i.count);
    let total = counts.reduce((partial_sum, a) => partial_sum + a, 0);

    if(total > remainder){
      strains.forEach(s => {
        if(s.id == strain.id){
          return;
        }
        let diff = s.count;
        if(s.count > remainder){
          s.count = remainder;
        }
        remainder = remainder - diff;
        if(remainder < 0){
          remainder = 0;
        }
      })
    }
  }

  info() {
    this.dialog.open(ZoneExplanationDialogComponent);
  }

  async getSensors() {
    this.sensors = await this.sensorService.getAll()
  }

  canSeeLeaflogix: boolean = false;
  getLeaflogix() {
    let company = this.helperService.currentCompany;
    this.canSeeLeaflogix = company?.leaflogix?.active;
  }

  refreshingZones: boolean = false;
  async refreshZonesClick() {
    this.notifierService.confirm('Refresh Zones?', 'Are you sure you want to sync Zones?  This will overwrite any existing information you’ve already saved.',
    () => {this.refreshZones()}, () => {})
  }
  async refreshZones() {

    this.refreshingZones = true;
    let retVal = await this.leaflogixService.integrateZones();
    let response: models.Result<boolean> = retVal.data;

    if(response.success){
      this.notifierService.success('Successfully integrated Zones')
      this.get()
    } else {
      this.notifierService.error('There was an error syncing with Dutchie.  Please check API key.')
    }
    this.refreshingZones = false;
  }
}
