import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { SessionStorageService } from 'angular-web-storage';

import * as enums from '../../models/enumerations';
import * as models from '../../models';
import * as services from '../../services';

@Component({
    selector: "tag-cell",
    template: `
        <div class="cont">
            <mat-chip-list class="tag-chip-list" style="vertical-align: middle">
                <mat-chip *ngFor="let tag of tags" style="vertical-align: middle">{{tag}}</mat-chip>
            </mat-chip-list>
        </div>
    `,
    styles: [
        `
        .cont {
            margin-top:1px
        }

        ::ng-deep .tag-chip-list .mat-chip-list-wrapper {
            flex-wrap: unset !important;
            //width: 250px;
            overflow-x: scroll;
        }
  
        `
    ]
})
export class TagComponent implements ICellRendererAngularComp {
    private params: any;

    tags: Array<string> = [];

    constructor(){}

    async agInit(params: any): Promise<void> {
        if(params.value == null){
            return;
        }
        this.params = params;

        //let roles: Array<models.Role> = this.storage.get('roles');
        let tags: Array<string> = this.params.value;
        this.tags = tags;

        // if(tags != null){
        //     this.tags = tags.split('|');
        // }
    }

    refresh(params: any): boolean {
        return false;
    }
}