import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import * as models from '../../models';
import * as services from '../../services';
import * as viewmodels from 'app/viewmodels';

import {
  UserSelectComponent
} from '../../dialogs/user-select/user-select.component';
import { DiscussionType } from '../../models';
import { UrlUploadDialogComponent } from '../url-upload-dialog/url-upload-dialog.component';
import { HttpHandler } from '@angular/common/http';
import { EditRecurringEventsDialogComponent } from '../edit-recurring-events-dialog/edit-recurring-events-dialog.component';
import { MessagingComponent } from 'app/components/messaging/messaging.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Timestamp, getDocs, query, where } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { SubtasksDialogComponent } from '../subtasks-dialog/subtasks-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-event-update',
  templateUrl: './event-update.component.html',
  styleUrls: ['./event-update.component.scss']
})
export class EventUpdateComponent implements OnInit, OnDestroy {
  @ViewChild(MessagingComponent) messagingComponent: MessagingComponent
  stopwatchSub: Subscription;

  saving: boolean = false;
  deleting: boolean = false;
  saveInDialog: boolean = false;

  event: models.Event = {};
  eventDate: Date;
  eventTime: string = '';
  users: Array<models.User> = [];
  notes: string[] = [];
  sendingNote: boolean = false;
  subtasks: models.SubtaskDisplay[] = [];
  automaticallyCompletedEvent: boolean = false;

  initialEvent: models.Event;

  assignToGroup: boolean = false;
  harvestId: string;
  groupId: string;
  get parentId() {
    return this.assignToGroup ? this.groupId : this.harvestId
  }
  eventList: Array<models.Event> = []

  fileToUpload: File = null;

  get detailTableItems(): DetailTableItem[] {
    let retVal: DetailTableItem[] = []
    
    // <h5 *ngIf="event.harvestName != null && event.harvestName != ''">Harvest: {{event.harvestName}}</h5>
    if(this.event.harvestName != null && this.event.harvestName != '') {
      retVal.push({
        label: 'Harvests:',
        value: this.event.harvestName,
        color: '#858bff',
        link: null // '/console/harvest/' + this.event.parentId
      })
    }

    // <h5 *ngIf="harvest?.leaflogixName != null && harvest.leaflogixName != ''">Dutchie Batch: {{harvest.leaflogixName}}</h5>
    if(this.harvest?.leaflogixName != null && this.harvest.leaflogixName != '') {
      retVal.push({
        label: 'Dutchie Batch:',
        value: this.harvest.leaflogixName,
        color: '#87ff66',
        link: null
      })
    }

    // <h5 *ngIf="event.groupName != null && event.groupName != ''">Task Group: {{event.groupName}}</h5>
    if(this.event.groupName != null && this.event.groupName != '') {
      retVal.push({
        label: 'Task Group:',
        value: this.event.groupName,
        color: '#ff7066',
        link: null // '/console/group/' + this.event.parentId
      })
    }

    // <h5 *ngIf="harvestId != null && zones != null && zones.length > 0">{{zoneFirstTitle}}: {{zoneIdentifer}}</h5>
    if(this.harvestId != null && this.zones != null && this.zones.length > 0) {
      retVal.push({
        label: this.zoneFirstTitle + ':',
        value: this.zoneIdentifer,
        color: '#ababab',
        link: null
      })
    }

    //get strains
    let currentPhase = this.harvest?.phases?.find(i => i.phaseName == this.event.phase);
    if(this.harvestId != null && currentPhase?.strains != null && currentPhase?.strains.length > 0) {
      currentPhase?.strains.forEach(strain => {
        retVal.push({
          label: strain.strainName + ':',
          value: strain.count + ' plants',
          color: '#ababab',
          link: null
        })
      });
    }

    return retVal;
  };

  get assignedTeamsUsers(): number {
    if (this.event == null) {
      return null;
    }
    let assignedUsers = this.event?.assignedUserIds?.length ?? 0;
    let assignedTeams = this.event?.assignedTeamIds?.length ?? 0;
    let badge = assignedUsers + assignedTeams;
    if(badge == 0){
      return null;
    } else {
      return badge;
    }
  }

  get allTags() {
    let list = [];
    this.eventList.forEach(event => {
      if (event.tags != null) {
        list.push(...event.tags);
      }
    });

    return [...new Set(list)].sort();
  }

  get assignUserAbility(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.assignUsers);
  }

  get editEventAbility(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.editEvent);
  }

  get qualityTrackerAbility(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.qualityTracker);
  }

  get deleteEventAbility(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteEvent);
  }

  get completeEvent(): boolean { 
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.completeEvent);
  }

  get mustCompleteSubtasks(): boolean {
    if(this.event.subtasks == null || this.event.subtasks.length == 0){
      return false;
    }

    let incomplete = this.event.subtasks.filter(i => i.completed == false).length;
    return incomplete > 0;
  }

  get subtasksEmpty() {
    return this.event.subtasks == null || this.event.subtasks.length == 0;
  }

  formatLabel(value: number) {
    return value + '%';
  }

  descriptionHasError: boolean = false;
  timeSpentHasError: boolean = false;

  zoneFirstTitle: string = 'Zones';
  zones: models.Zone[];
  get zoneIds(): string[] {
    let zoneIds: string[] = [];
    if(this.harvest == null){
      return zoneIds;
    }
    let currentPhase = this.harvest.phases?.find(i => i.phaseName == this.event.phase);
    if(currentPhase?.strains == null){
      return zoneIds;
    }
    currentPhase.strains.forEach(strain => {
      if(strain.zones == null){
        return;
      }
      strain.zones.forEach(zone => {
        if(!zoneIds.includes(zone.zoneId)){
          zoneIds.push(zone.zoneId);
        }
      });
    });
    return zoneIds;
  }
  get zoneIdentifer(): string {
    let zones = this.zoneService.getAllTopLevel(this.zones, this.zoneIds);
    return zones.map(i => i.name).join(', ');
  }
  harvest: models.Harvest;

  startDate: FormControl;

  constructor(
    private dialogRef: MatDialogRef<EventUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private claimsService: services.ClaimsService,
    private fileService: services.FileService,
    private helperService: services.HelperService,
    private userService: services.UserService,
    public dialog: MatDialog,
    private notifierService: services.NotifierService,
    private zoneService: services.ZoneService,
    private harvestService: services.HarvestService,
    private attachmentService: services.AttachmentService,
    public stopwatchHelperService: services.StopwatchHelperService,
    private eventService: services.EventService
  ) {
    this.event = this.helperService.deepCopy(data.event);
    this.eventDate = new Date(data.event.startDateTime.seconds * 1000);
    this.startDate = new FormControl(this.eventDate);
    this.eventTime = this.eventDate.toTimeString().substring(0, 5);
    this.organizeSubtaskDisplays();

    this.assignToGroup = data.assignToGroup;
    this.harvestId = data.harvestId;
    this.groupId = data.groupId;
    this.eventList = data.eventList;
    this.saveInDialog = data.saveInDialog;

    this.initialEvent = this.helperService.deepCopy(this.event);
  }

  ngOnInit() {
    //this.tourService.listenForQueryString(['event']);
    this.getZones();
    this.getHarvest();
    this.listenForStopwatchSubmit();
  }

  ngOnDestroy(): void {
    this.stopwatchSub.unsubscribe();
  }

  async getZones() {
    let dbZone = await this.zoneService.getDbZone();
    if(dbZone == null){
      return;
    }
    this.zones = dbZone.list;
    this.zoneFirstTitle = dbZone.zoneTitles[0];
  }

  getHarvest() {
    if(this.harvestId == null) {
      return;
    }

    this.harvestService.get(this.harvestId).snapshotChanges().subscribe(harvestResponse => {
      if(harvestResponse.payload.exists){
        this.harvest = harvestResponse.payload.data();
      }
    });
  }

  organizeSubtaskDisplays() {
    this.subtasks = this.event.subtasks?.map(i => {return {displayDescription: false, subtask: i}}) ?? [];
  }

  listenForStopwatchSubmit() {
    this.stopwatchSub = this.stopwatchHelperService.submitSub.subscribe(data => {
      if(data.eventName == this.getFullEventName()){
        this.event.timeSpent = data.timeSpent;
        this.stopwatchHelperService.clearSub.next()
        this.stopwatchHelperService.display = false;
      } else {
        this.notifierService.error('Stopwatch not for this event.  Please select correct event.');
      }
    })
  }

  selectTeamsUsers() {
    let dialogRef = this.dialog.open(UserSelectComponent, {
      //panelClass: 'med-width-dialog',
      id: 'user-select-dialog',
      data: {
        userIds: this.event.assignedUserIds,
        teamIds: this.event.assignedTeamIds
      },
      //autoFocus: true
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.submitted) {
        this.event.assignedUserIds = result.userIds;
        this.event.assignedTeamIds = result.teamIds;
      }
      // console.log(JSON.stringify(result));
    });
  }

  async saveEvent() {
    this.descriptionHasError = false;
    if(this.event.description == null || this.event.description == '') {
      this.descriptionHasError = true;
      this.notifierService.error('Cannot save an Event with an empty Event Description field');
      return;
    }

    this.timeSpentHasError = false;
    if(this.event.completed && this.event.timeSpentRequired && this.event.timeSpent == null){
      this.timeSpentHasError = true;
      this.notifierService.error('This Event must have a Time Spent logged against it to be completed based on configuration');
      return;
    }

    this.saving = true;
    this.eventDate = this.startDate.value;

    let split = this.eventTime.split(':');
    let hour = +split[0];
    let min = +split[1];
    this.eventDate.setHours(hour);
    this.eventDate.setMinutes(min);
    this.event.startDateTime = Timestamp.fromDate(this.eventDate);
    //alert(JSON.stringify(this.event))

    if(this.subtasksEmpty == false){
      if(this.event.subtasks.filter(i => i.completed == false).length == 0) {
        this.event.completed = true;
        this.automaticallyCompletedEvent = true;
      } else {
        this.event.completed = false;
      }
    }

    this.messagingComponent.submit();

    if (this.event.repeatedEventId != null && this.event.repeatedEventId != '' && this.repeatableFieldChanged()) {

      let dialogRef = this.dialog.open(EditRecurringEventsDialogComponent, {
        autoFocus: true,
        disableClose: false
      });

      let collectionType = this.assignToGroup ? 'groups' : 'harvests';

      dialogRef.afterClosed().subscribe(async result => {
        //reload list
        if (result) {
          let eventsToEdit: Array<models.Event> = [this.event];

          switch (result) {
            case 'one':
              await this.updateDiscussion(this.event, true);
              await this.closeDialog([this.event]);
              break;
            case 'following':
              if(this.saveInDialog) {

                //get events in harvest/group that are part of the same repeat group that are AFTER the selected event
                var eventColRef = this.eventService.getSplit(this.event.parentId, collectionType);
                let eventQuery = query(eventColRef, where('repeatedEventId', '==', this.event.repeatedEventId), where('startDateTime', '>=', this.event.startDateTime))
        
                //copy fields and add to eventsToEdit list
                let qSS = await getDocs(eventQuery);
                for (let i = 0; i < qSS.docs.length; i++) {
                  const doc = qSS.docs[i];
                  let event: models.Event = doc.data();
                  event.uid = doc.id;
                  await this.copyFields(event);
                  eventsToEdit.push(event)
                }

              } else {
                let applicableFollowingEvents = this.eventList.filter(i => i.repeatedEventId == this.event.repeatedEventId &&
                  i.startDateTime >= this.event.startDateTime);
                for (let i = 0; i < applicableFollowingEvents.length; i++) {
                  const event = applicableFollowingEvents[i];
                  await this.copyFields(event);
                }
              }
              await this.closeDialog(eventsToEdit);
              break;
            case 'all':
              if(this.saveInDialog) {

                //get events in harvest/group that are part of the same repeat group
                var eventColRef = this.eventService.getSplit(this.event.parentId, collectionType);
                let eventQuery = query(eventColRef, where('repeatedEventId', '==', this.event.repeatedEventId))
        
                //copy fields and add to eventsToEdit list
                let qSS = await getDocs(eventQuery);
                for (let i = 0; i < qSS.docs.length; i++) {
                  const doc = qSS.docs[i];
                  let event: models.Event = doc.data();
                  event.uid = doc.id;
                  await this.copyFields(event);
                  eventsToEdit.push(event)
                }
              } else {
                let applicableEvents = this.eventList.filter(i => i.repeatedEventId == this.event.repeatedEventId);
                for (let i = 0; i < applicableEvents.length; i++) {
                  const event = applicableEvents[i];
                  //if(event.repeatedEventId == this.event.repeatedEventId){
                  await this.copyFields(event);
                  //}
                }
              }
              await this.closeDialog(eventsToEdit);
              break;
            default:
              break;
          }
          //alert('save all now - ' + result);
        } else {
          this.saving = false;
        }
        console.log(result);
      })
    } else {
      await this.updateDiscussion(this.event, true);
      await this.closeDialog([this.event]);
    }
  }

  async closeDialog(eventsToSave: Array<models.Event>) {
    let dialogResult: viewmodels.EventUpdateDialogResult = {
      save: true,
      event: this.event,
      eventList: this.eventList
    }

    if(this.saveInDialog) {
      dialogResult.save = false;

      await this.eventService.saveListSplit(this.assignToGroup, eventsToSave);
      this.saving = false;
      this.notifierService.success(`Successfully updated ${eventsToSave.length} events`)
      if(this.automaticallyCompletedEvent) {
        this.notifierService.success('All subtasks are complete, event has been automatically marked as completed');
        this.automaticallyCompletedEvent = false;
      }
    } else {
      eventsToSave.forEach(event => {
        let found = this.eventList.findIndex(i => i.id == event.id);
        if(found >= 0) {
          this.eventList[found] = event;
        }
      });
      dialogResult.eventList = this.eventList;
      dialogResult.automaticallyCompletedEvent = this.automaticallyCompletedEvent;
      this.automaticallyCompletedEvent = false;
    }

    this.dialogRef.close(dialogResult)
  }

  repeatableFieldChanged(): boolean {
    let initialEventDate = new Date(this.initialEvent.startDateTime.seconds * 1000);
    let eventDate = new Date(this.event.startDateTime.seconds * 1000);

    if (this.initialEvent.description != this.event.description ||
      this.initialEvent.highPriority != this.event.highPriority ||
      this.initialEvent.anyTime != this.event.anyTime ||
      (this.notes != null && this.notes.length > 0) ||
      JSON.stringify(this.initialEvent.tags) != JSON.stringify(this.event.tags) ||
      JSON.stringify(this.initialEvent.assignedUserIds) != JSON.stringify(this.event.assignedUserIds) ||
      JSON.stringify(this.initialEvent.cloudAttachments) != JSON.stringify(this.event.cloudAttachments) ||
      initialEventDate.getHours() != eventDate.getHours() ||
      initialEventDate.getMinutes() != eventDate.getMinutes()) {
      return true;
    }

    return false;
  }

  async copyFields(event: models.Event) {
    if (this.initialEvent.description != this.event.description &&
      this.initialEvent.description == event.description) {
      event.description = this.event.description;
    }
    if (this.initialEvent.highPriority != this.event.highPriority &&
      this.initialEvent.highPriority == event.highPriority) {
      event.highPriority = this.event.highPriority;
    }
    if (this.initialEvent.anyTime != this.event.anyTime &&
      this.initialEvent.anyTime == event.anyTime) {
      event.anyTime = this.event.anyTime;
    }
    if (JSON.stringify(this.initialEvent.tags) != JSON.stringify(this.event.tags) &&
      JSON.stringify(this.initialEvent.tags) == JSON.stringify(event.tags)) {
      event.tags = this.event.tags;
    }
    if (JSON.stringify(this.initialEvent.assignedUserIds) != JSON.stringify(this.event.assignedUserIds) &&
      JSON.stringify(this.initialEvent.assignedUserIds) == JSON.stringify(event.assignedUserIds)) {
      event.assignedUserIds = this.event.assignedUserIds;
    }
    if (JSON.stringify(this.initialEvent.cloudAttachments) != JSON.stringify(this.event.cloudAttachments) &&
      JSON.stringify(this.initialEvent.cloudAttachments) == JSON.stringify(event.cloudAttachments)) {
      event.cloudAttachments = this.event.cloudAttachments;
    }

    let initialEventDate = new Date(this.initialEvent.startDateTime.seconds * 1000);
    let eventDate = new Date(this.event.startDateTime.seconds * 1000);

    if (initialEventDate.getHours() != eventDate.getHours() ||
      initialEventDate.getMinutes() != eventDate.getMinutes()) {
      let split = this.eventTime.split(':');
      let hour = +split[0];
      let min = +split[1];
      let date = new Date(event.startDateTime.seconds * 1000);
      date.setHours(hour);
      date.setMinutes(min);
      event.startDateTime = Timestamp.fromDate(date);
    }

    await this.updateDiscussion(event, this.event == event);
  }

  async updateDiscussion(event: models.Event, currentEvent: boolean) {
    //create list if it is null
    if (this.event.discussions == null) {
      this.event.discussions = [];
    }

    //adding notes to discussion
    if (this.notes != null) {
      this.notes.forEach(note => {
        let newDis: models.Discussion = {
          discussionType: DiscussionType.Note,
          userId: this.claimsService.currentUserId(),
          timestamp: Timestamp.now(),
          content: note,
        }
  
        event.discussions.push(newDis);
      });
    }

    if (!currentEvent) {
      return;
    }

    //check if user completed any of the subtasks
    let initialSubtasks = this.initialEvent.subtasks ?? [];
    let eventSubtasks = this.event.subtasks ?? [];
    eventSubtasks.forEach(subtask => {
      let initialSubtask = initialSubtasks.find(i => i.id == subtask.id);
      if(initialSubtask == null){
        return;
      }
      if(initialSubtask.completed != subtask.completed){
        subtask.completedDate = Timestamp.now();
        subtask.completedUserId = this.claimsService.currentUserId();
        let newDis: models.Discussion = {
          discussionType: DiscussionType.Subtask,
          userId: this.claimsService.currentUserId(),
          timestamp: Timestamp.now(),
          content: subtask.title + ' as ' + (subtask.completed ? 'Completed' : 'Incomplete'),
        }
  
        event.discussions.push(newDis);
      }
    });

    //check if user added any new subtasks
    let newSubtasks = eventSubtasks.filter(i => initialSubtasks.find(j => j.id == i.id) == null);
    newSubtasks.forEach(subtask => {
      let newDis: models.Discussion = {
        discussionType: DiscussionType.SubtaskAdded,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: subtask.title,
      }

      event.discussions.push(newDis);
    });

    //check if user removed any subtasks
    let removedSubtasks = initialSubtasks.filter(i => eventSubtasks.find(j => j.id == i.id) == null);
    removedSubtasks.forEach(subtask => {
      let newDis: models.Discussion = {
        discussionType: DiscussionType.SubtaskRemoved,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: subtask.title,
      }

      event.discussions.push(newDis);
    });

    //add completed status
    if (this.initialEvent.completed != this.event.completed) {
      let newDis: models.Discussion = {
        discussionType: DiscussionType.CompletedStatus,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: this.event.completed ? 'true' : 'false',
      }

      event.discussions.push(newDis);
    }

    //add time spent
    if (this.initialEvent.timeSpent != this.event.timeSpent) {
      let newDis: models.Discussion = {
        discussionType: DiscussionType.TimeSpent,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: this.event.timeSpent != null ? this.event.timeSpent.toString() : "0",
      }

      event.discussions.push(newDis);
    }

    //add efficacy
    if (this.initialEvent.efficacy != this.event.efficacy) {
      let newDis: models.Discussion = {
        discussionType: DiscussionType.Efficacy,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: this.event.efficacy != null ? this.event.efficacy.toString() : "0",
      }

      event.discussions.push(newDis);
    }

    //add attachment
    if (this.fileToUpload != null) {
      let dateString = Date.now();
      let ind = this.fileToUpload.name.lastIndexOf('.');
      let newFileName: string = this.fileToUpload.name.substring(0, ind) + "_" + dateString.toString() + this.fileToUpload.name.substring(ind);
      // let attachment: models.Attachment = {
      //   fileName: this.fileToUpload.name,
      //   fullPath: 'docs/{companyId}/event/' + newFileName
      // }
      let fullPath: string = 'docs/{companyId}/event/' + newFileName;

      let response = await this.fileService.upload(fullPath, this.fileToUpload);

      fullPath = response.metadata.fullPath;

      let newDis: models.Discussion = {
        discussionType: DiscussionType.AttachedFile,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: fullPath
      }

      event.discussions.push(newDis);
    }
  }

  deleteClick() {
    this.deleting = true;
    this.notifierService.confirm('Delete Event', 'Are you sure you want to delete this event?', () => {
      this.deleteEvent();
    }, () => {
      this.deleting = false;
    })
  }

  deleteEvent() {
    if (this.event.repeatedEventId != null && this.event.repeatedEventId != '') {

      let dialogRef = this.dialog.open(EditRecurringEventsDialogComponent, {
        autoFocus: true,
        disableClose: false,
        data: {
          type: 'Delete'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //reload list
        if (result) {
          this.handleEditRecurringEventResponse(result);
        } else {
          this.deleting = false;
        }
      })
    } else {
      this.closeDialogOnDelete([this.event.id]);
    }
  }

  handleEditRecurringEventResponse(recurringKey: string = null) {
    switch (recurringKey) {
      case 'one':
        this.closeDialogOnDelete([this.event.id], recurringKey);
        break;
      case 'following':
        let eventIdsToDelete = this.eventList.filter(i => i.repeatedEventId == this.event.repeatedEventId && i.startDateTime?.seconds >= this.event.startDateTime?.seconds).map(i => i.id)
        this.eventList = this.eventList.filter(i => i.repeatedEventId != this.event.repeatedEventId ||
          i.startDateTime < this.event.startDateTime);

        this.closeDialogOnDelete(eventIdsToDelete, recurringKey);
        break;
      case 'all':
        let eventIdsToDeleteAll = this.eventList.filter(i => i.repeatedEventId == this.event.repeatedEventId).map(i => i.id)
        this.eventList = this.eventList.filter(i => i.repeatedEventId != this.event.repeatedEventId);

        this.closeDialogOnDelete(eventIdsToDeleteAll, recurringKey);
        break;
      default:
        break;
    }
  }

  async closeDialogOnDelete(idsToDelete: string[], recurringKey: string = null) {
    let dialogResult: viewmodels.EventUpdateDialogResult = {
      save: false,
      delete: true,
      event: this.event,
      eventList: this.eventList,
      eventIdsToDelete: idsToDelete
    }

    let collectionType: string;
    if (this.assignToGroup) {
      dialogResult.groupId = this.groupId;
      collectionType = 'groups';
    } else {
      dialogResult.harvestId = this.harvestId;
      collectionType = 'harvests';
    }

    if(this.saveInDialog){
      dialogResult.delete = false;
      let eventsToDelete: Array<string> = [];

      if(recurringKey == 'following' || recurringKey == 'all') {
        var eventColRef = this.eventService.getSplit(this.event.parentId, collectionType);
        let queryContraints = [where('repeatedEventId', '==', this.event.repeatedEventId)];

        if(recurringKey == 'following'){
          queryContraints.push(where('startDateTime', '>=', this.event.startDateTime))
        }

        let eventQuery = query(eventColRef, ...queryContraints)

        let qSS = await getDocs(eventQuery);
        qSS.forEach(doc => {
          eventsToDelete.push(doc.id)
        })
      }

      await this.eventService.deleteSplit(this.event, collectionType, eventsToDelete)
      this.notifierService.success('Successfully deleted event')
    }

    this.dialogRef.close(dialogResult);
  }

  fileChange(files: FileList) {
    if (files.length === 0)
      return;

    var reader = new FileReader();
    this.fileToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
  }

  browseForFile() {
    document.getElementById('selectedFile').click();
  }

  cloudStorageClick() {
    let dialogRef = this.dialog.open(UrlUploadDialogComponent, {
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (this.event.cloudAttachments == null) { this.event.cloudAttachments = [] };

      this.event.cloudAttachments.push(result);
    })
  }

  getClass(d: string) {
    if (d.includes('google')) {
      return 'fab fa-google-drive';
    } else if (d.includes('dropbox')) {
      return 'fab fa-dropbox';
    } else if (d.includes('icloud')) {
      return 'fab fa-apple';
    } else {
      return 'fas fa-cloud';
    }
  }

  removeCloudAttachment(a) {
    this.event.cloudAttachments = this.event.cloudAttachments.filter(obj => obj !== a)
  }

  removeAttachment(a) {
    this.event.attachments = this.event.attachments.filter(obj => obj !== a)
  }

  deleteMessage(message: models.Discussion) {
    this.event.discussions = this.event.discussions.filter(i => !(i.timestamp == message.timestamp && i.content == i.content))
  }

  async sendMessage(e: any) {

    await this.saveEvent();
  }

  stopwatchClick() {
    if(!this.stopwatchHelperService.display) {
      this.startStopwatch();
    } else {
      this.notifierService.confirm(
        'New Stopwatch?', 
        'Starting a new stopwatch timer will remove your current one.  Would you like to continue?', 
        () => {this.startStopwatch()}, () => {});
    }
  }

  startStopwatch(){
    this.stopwatchHelperService.startStopwatch(this.getFullEventName(), this.event.timeSpent ?? 0);
  }

  getFullEventName() {
    let parent = this.event.harvestName;
    if(!parent){
      parent = this.event.groupName;
    }
    return parent + ': ' + this.event.description;
  }

  editSubtasks(event) {
    event.stopPropagation();
    event.preventDefault();
    const doc = document.documentElement;
    const top = (window.scrollX || doc.scrollTop) - (doc.clientTop || 0);
    const left = (window.scrollY || doc.scrollLeft) - (doc.clientLeft || 0);
    if(top != 0 || left != 0){
      window.scrollTo(0, 0);
    }
    let notesDialog = this.dialog.open(SubtasksDialogComponent, {
      data: {
        subtasks: this.event.subtasks || []
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data != null && data != '') {
        this.event.subtasks = data;
        this.organizeSubtaskDisplays();
      }
      if(top != 0 || left != 0){
        window.scrollTo({ top: top, left: left, behavior: 'auto' });
      }
    })
  }

  subtaskDescToggle(event, subtask: models.SubtaskDisplay) {
    event.stopPropagation();
    event.preventDefault();
    subtask.displayDescription = !subtask.displayDescription;
  }

  @ViewChild('tooltip') tooltip: MatTooltip;

  showTooltip(event: Event): void {
    if (this.mustCompleteSubtasks) {
      this.tooltip.show();
      event.stopPropagation();
    }
  }

}

export interface DetailTableItem {
  label: string;
  value: string;
  color: string;
  link: string;
}
