<app-loading-panel [show]="company == null"></app-loading-panel>

<ng-container *ngIf="company != null">
    <div class="row" style="padding-bottom: 15px">
        <div class="col-sm-12" style="text-align: right">
            <app-manage-billing-btn [customerId]="company.paymentProviderCustomerId"></app-manage-billing-btn>
            <!-- <app-stripe-portal-btn [customerId]="company.stripeCustomerId"></app-stripe-portal-btn> -->
            <button mat-raised-button class="pad-btn" [disabled]="!dataUpdated()" title="Save Company" (click)="save()">
                <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
                <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Template">save</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" [disabled]="!dataUpdated()" title="Discard Changes"
                (click)="get()">
                <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-2" style="cursor: pointer;">
            <i class="fa fa-minus-circle" *ngIf="customCompanyImage" (click)="minusClick()"
                style="font-size:24px;color:red;position: absolute;right:5px;"></i>
            <img [src]="companyImageUrl" height="100" width="100" (click)="imageClick()"
                style="object-fit: cover; margin: auto; display: block;">
        </div>
        <div class="col-10">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="full-width">
                        <input type="text" #input matInput [(ngModel)]="company.name" placeholder="Company Name">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <mat-select [(ngModel)]="company.timezone">
                            <mat-option [(value)]="t.key" *ngFor="let t of timezones">{{t.display}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="padding: 20px;">
        <mat-slide-toggle [(ngModel)]="company.eventsTooltips">
            <span style="color: #000000; font-size: 16px;">Event Tooltips</span>
        </mat-slide-toggle>
    </div>
</ng-container>

<!-- <div class="row" *ngIf="company.leaflogix != null">
    <div class="col-12">
        <mat-accordion class="headers-align" multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Dutchie
                    </mat-panel-title>
                    <mat-panel-description>
                        Dutchie Configurations
                        <mat-icon *ngIf="company.leaflogix.active">done</mat-icon>
                        <mat-icon *ngIf="!company.leaflogix.active">close</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-sm-6">
                        <mat-slide-toggle name="leaflogixactive" [(ngModel)]="company.leaflogix.active">Use Dutchie</mat-slide-toggle>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <mat-form-field>
                            <input autocomplete="off" matInput placeholder="ApiKey" [type]="apiKeyHide ? 'password' : 'text'" [(ngModel)]="company.leaflogix.apiKey">
                            <mat-icon matSuffix (click)="apiKeyHide = !apiKeyHide">{{apiKeyHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <app-user-select-multiple [placeholder]="'Dutchie Admins'" [displayUserList]="false" [(userIds)]="company.leaflogix.adminUserIds"></app-user-select-multiple>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div> -->