import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestrictionNotificationComponent } from 'app/dialogs';

import * as services from '../../../services';

@Component({
  selector: 'app-upgrade-fab',
  templateUrl: './upgrade-fab.component.html',
  styleUrls: ['./upgrade-fab.component.scss']
})
export class UpgradeFabComponent implements OnInit {

  visible: boolean = false;

  constructor(
    private snackBar: MatSnackBar
    , private helperService: services.HelperService
    , private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.visible = this.helperService.company_freeVersion;
    this.helperService.getcompany_freeVersion().subscribe(data => {
      this.visible = data;
    })
  }

  upgrade() {
    let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
      data: 'upgrade'
    });
  }

}
