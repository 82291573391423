
<mat-form-field>
    <mat-label>Select Item(s)</mat-label>
    <mat-select [(ngModel)]="ids" (selectionChange)="change($event.value)" multiple #select="ngModel">
        <mat-select-trigger>
            {{title}}
        </mat-select-trigger>
        <mat-option disabled="disabled" class="filter-option">
            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll(select, list)">
                Select All
            </button>
            <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAll(select)">
                Deselect All
            </button>
        </mat-option>
        <mat-option [value]="h.id" *ngFor="let h of list">{{h.name}}</mat-option>
    </mat-select>
</mat-form-field>

<div class="row" *ngIf="displayList">
    <div class="col-12">
        <h5>Selected Items:</h5>
        <ul class="list">
            <li *ngFor="let item of ids">
                {{item}}
            </li>
        </ul>
    </div>
</div>