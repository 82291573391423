import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttachmentUploadDialogComponent } from '../attachment-upload-dialog/attachment-upload-dialog.component';

import * as services from 'app/services';
import * as models from 'app/models';
import { ColDef, FirstDataRenderedEvent, GridOptions } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IconComponent } from 'app/components/cell-components';

@Component({
  selector: 'app-attachments-select-dialog',
  templateUrl: './attachments-select-dialog.component.html',
  styleUrls: ['./attachments-select-dialog.component.scss']
})
export class AttachmentsSelectDialogComponent implements OnInit {

  gridList: models.Attachment[];
  gridOptions: GridOptions;

  colDefs: ColDef[] = [
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 30
    },
    {
      headerName: 'Name',
      field: 'fileName'
    },
    {
      headerName: "Edit",
      field: "description",
      width: 30,
      cellRendererFramework: IconComponent,
      cellRendererParams: (params) => {
        params.icon = 'edit';
        params.badge = 0;
        params.func = this.editClick.bind(this, params.data);
        return params;
      },
    },
    {
      headerName: "Delete",
      field: "description",
      width: 30,
      cellRendererFramework: IconComponent,
      cellRendererParams: (params) => {
        params.icon = 'delete';
        params.badge = 0;
        params.func = this.deleteClick.bind(this, params.data);
        return params;
      },
    },
  ];

  selected: string[];

  constructor(
    private dialogRef: MatDialogRef<AttachmentsSelectDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private dialog: MatDialog
    , private attachmentService: services.AttachmentService
    , private deviceService: DeviceDetectorService
    , private helperService: services.HelperService
    , private notifierService: services.NotifierService
  ) { 
    this.selected = data.selected ?? [];

    this.gridOptions = <GridOptions>{
      context: {
          componentParent: this
      },
      columnDefs: this.colDefs,
      onGridReady: () => {
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
        this.setSelected();
      },
      rowSelection: 'multiple',
      suppressRowClickSelection: true
      //selectionChanged: this.onSelectionChanged
    };
  }

  ngOnInit(): void {
    this.load();
  }

  upload() {
    let dialogRef = this.dialog.open(AttachmentUploadDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.selected.push(result);
        this.load();
      }
    })
  }

  async load() {
    let attachements = await this.attachmentService.getAll();
    this.gridList = this.helperService.sortArrayByStringField(attachements, 'fileName')

    await this.helperService.wait(10);
    this.setSelected();
  }

  setSelected() {
    this.gridOptions.api.forEachNode((node) =>
      node.setSelected(node.data != null && this.selected.includes(node.data.id))
    );
  }

  ok() {
    let selected: models.Attachment[] = this.gridOptions.api.getSelectedRows()
    this.dialogRef.close(selected.map(i => i.id));
  }

  editClick(attachment: models.Attachment) {
    this.notifierService.confirm('Edit?', 'Editing or overwriting this attachment will update it for all Templates, and active Harvest Events it is currently associated with. Are you sure you want to proceed?', 
      () => {this.edit(attachment)}, 
      () => {}
    );
  }

  edit(attachement: models.Attachment) {
    let dialogRef = this.dialog.open(AttachmentUploadDialogComponent, {data: {attachment: attachement}});

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.selected.push(result);
        this.load();
      }
    })
  }

  deleteClick(attachment: models.Attachment) {
    this.notifierService.confirm('Delete?', 'Deleting this attachment will remove it from all Templates, and active Harvest Events it is currently associated with. Are you sure you want to proceed?', 
      () => {this.delete(attachment)}, 
      () => {}
    );
  }

  async delete(attachment: models.Attachment) {
    await this.attachmentService.delete(attachment);
    this.load();
  }
}
