import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridOptions, CellDoubleClickedEvent, RowNode, RefreshModelParams } from "ag-grid-community";
import { DeviceDetectorService } from 'ngx-device-detector';
import { HelperService, GroupService, ClaimsService, EventService } from 'app/services';
import { Router } from '@angular/router';
import { TaskGroupTableViewModel } from 'app/viewmodels';
import { Subscription } from 'rxjs';
import { HarvestAddComponent, HarvestGroupDeleteComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';
import { TimeStampComponent } from '../../../components/cell-components';
import { Timestamp, getDocs } from '@angular/fire/firestore';

import * as models from 'app/models';
import * as cells from 'app/components/cell-components';

@Component({
  selector: 'app-task-group-list',
  templateUrl: './task-group-list.component.html',
  styleUrls: ['./task-group-list.component.scss']
})
export class TaskGroupListComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  rowData: Array<any>;
  companySub: Subscription;

  private columnDefs = [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      resizable: true,
      // flex: 1,
      cellRendererFramework: cells.NameColorCellComponent,
      cellRendererParams: (params) => {
        params.color = params.data.color;
        params.isHarvest = false;
        return params;
      },
    },
    {
      headerName: "Start Date",
      field: "startDate",
      cellRenderer: "timestampRenderer",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "End Date",
      field: "endDate",
      cellRenderer: "timestampRenderer",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "# of Tasks",
      field: "totalTasks",
      sortable: true,
      resizable: true,
    },
  ]

  constructor(
    private claimsService: ClaimsService,
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    private eventService: EventService,
    private helperService: HelperService,
    private groupService: GroupService,
    private router: Router,
  ) { 
    this.gridOptions = <GridOptions> {
      context: {
        componentParent: this,
      },
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      rowHeight: 35,
      onGridReady: () => {
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      frameworkComponents: {
          timestampRenderer: TimeStampComponent,
      },
      rowSelection: 'muliple',
    }
  }

  ngOnInit(): void {

    let companyId = this.helperService.currentCompanyId;

    if(companyId != null) {
      this.getTaskGroups();
    };

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.getTaskGroups();
    })
  };

  ngOnDestroy(): void {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  async getTaskGroups() {
    if(this.gridOptions.api) {
      this.gridOptions.api.setRowData([]);
    };

    let taskGroupsList = await (this.groupService.getAll()).get().toPromise();

    await taskGroupsList.docs.forEach(async groupData => {
      let data = groupData.data()
      let taskGroupVM: TaskGroupTableViewModel = {
        id: groupData.id,
        name: data.name,
        startDate: data.startDate,
        endDate: data.endDate,
        completedTasks: data.completedTasks,
        totalTasks: data.totalTasks,
        color: data.color
      }

      if(taskGroupVM.startDate == null || taskGroupVM.endDate == null) {
        let eventDataList = await getDocs(this.eventService.get(groupData.id, 'groups'));
        eventDataList.forEach(eventData => {
          let dbEvent = eventData.data();

          let sorted = dbEvent.list.sort((a, b) => (a.startDateTime > b.startDateTime) ? 1 : -1)
          taskGroupVM.startDate = sorted[0].startDateTime
          taskGroupVM.endDate = sorted[sorted.length - 1].startDateTime
          taskGroupVM.totalTasks = (dbEvent.list.length || 0)
        });
      }

      this.gridOptions.api.applyTransaction({add: [taskGroupVM]})
    });

    
    
    // this.gridOptions.api.setRowData(taskGroups);
    this.gridOptions.columnApi.getColumn('startDate').setSort('desc');

    this.gridOptions.api.hideOverlay();
  }

  selectedCell(event) {
    let id: string = event.data.id

    this.router.navigate(['console', 'task-groups', id])
  }

  canEdit() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.editGroup);
  }

  addTaskGroup() {
    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result.added){
        this.getTaskGroups();
      }
      console.log(JSON.stringify(result.id));
    })
  }

  deleteTaskGroup() {
    let dialogRef = this.dialog.open(HarvestGroupDeleteComponent, {
      panelClass: 'full-width-dialog',
      data: { hideHarvests: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTaskGroups();
    })
  }

  exportAsCSV() {
    let params: any = {};
    params.processCellCallback = (params) => {
      if (params.value instanceof Timestamp) {
        return this.helperService.timestampToDateString(params.value);
      } else {
        return params.value;
      }
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }
}
