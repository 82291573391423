<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" (onClose)="popoverClosed($event)" (onOpen)="popoverOpened($event)" #popover="ngxMaterialPopover"
    mode="toggle">
    <button mat-raised-button matTooltip="Saved Views" matTooltipPosition="above" aria-label="Bookmarked Filters." matTooltipClass="savedViewsBtn">
        <mat-icon>bookmark_add</mat-icon><span *ngIf="selectedFilterSet != null"> {{truncate(selectedFilterSet.name)}}</span>
    </button>
</ngx-material-popover>

<ng-template #popoverContent>
    <!-- <mat-form-field appearance="outline" style="margin-bottom: -1em; width: 300px;">
        <mat-label>Search...</mat-label>
        <input matInput [(ngModel)]="searchVal">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->
    <ul class="list" *ngIf="filterSets.length != 0">
        <mat-selection-list #filterSetList name="fieldName" ngDefaultControl [multiple]="false" [(ngModel)]="selectedFilterSets" (ngModelChange)="filterSetChange($event)">
            <mat-list-option *ngFor="let filterSet of filterSets" [value]="filterSet">
                {{filterSet.name}}
            </mat-list-option>
        </mat-selection-list>
    </ul>
    <div class="no-filter-sets-container" *ngIf="filterSets.length == 0">
        <span class="no-filter-sets-text">No Views Available</span>
    </div>
    <div class="footer">
        <button mat-button *ngIf="canManipulateFilterSets" color="primary" (click)="addFilterSet()">Save</button>
        <button mat-button *ngIf="canManipulateFilterSets" [disabled]="selectedFilterSet == null" (click)="removeFilterSet()">Remove</button>
        <button mat-button style="float: right" [disabled]="selectedFilterSet == null" (click)="default()">Default</button>
        <mat-icon 
            matTooltipPosition="right" 
            aria-label="Complete instructions to use Bookmarks" 
            matTooltip="To save a custom view, make a selection from your set of filters, then save and name your selection. Once saved, you'll be able to refer back to the saved view at any time."
            matTooltipClass="savedViewsBtn"
            style="margin-left: auto;place-self: center;cursor: pointer;">help_outline
        </mat-icon>
    </div>
</ng-template>