import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, Subscription } from 'rxjs';
import { EventEmitter } from 'stream';

@Injectable()
export class StopwatchHelperService {

    display: boolean = false;
    public sub = new Subject<boolean>();
    public submitSub = new Subject<any>();
    public clearSub = new Subject<number>();

    constructor(private sessionStorage: SessionStorageService) { }

    fireNext() {
        this.sub.next(true);
    }

    /**
     * stopwatch - Event Name
     */
    get eventName() {
        let val = this.sessionStorage.get('stopwatch_eventName');
        return val;
    }
    set eventName(val: string) {
        if (val === null)
            this.sessionStorage.remove('stopwatch_eventName');
        else
            this.sessionStorage.set('stopwatch_eventName', val);
    }

    /**
     * stopwatch - stopwatch start
     */
    get start() {
        let val = this.sessionStorage.get('stopwatch_start');
        return val;
    }
    set start(val: number) {
        if (val === null)
            this.sessionStorage.remove('stopwatch_start');
        else
            this.sessionStorage.set('stopwatch_start', val);
    }

    startStopwatch(name: string, minutes: number) {
        this.eventName = name;
        this.start = Date.now() - minutes*60000;
        this.display = true;
        this.fireNext();
    }
}
