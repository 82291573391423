import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-harvest-phase-timeline',
  templateUrl: './harvest-phase-timeline.component.html',
  styleUrls: ['./harvest-phase-timeline.component.scss']
})
export class HarvestPhaseTimelineComponent implements OnInit, OnChanges {

  @Input() startDate: Date;
  @Input() template: models.Template;
  @Output() timelineList: EventEmitter<any> = new EventEmitter();

  listDisplay: Array<viewmodels.TimelineViewModel> = []

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.compileTimeline();
  }

  compileTimeline() {
    if(this.startDate == null || this.template == null){
      return;
    }
    this.listDisplay = [];
    var temp = [];

    let item: viewmodels.TimelineViewModel = {
      date: this.startDate,
      description: `Start Harvest`,
      harvestAtEnd: false
    }
    temp.push(item);

    var date = new Date(this.startDate.getTime());

    for (let i = 0; i < this.template.phases.length; i++) {
      const phase = this.template.phases[i];
      let nextPhase: models.Phase = null;
      if(i < this.template.phases.length-1){
        nextPhase = this.template.phases[i+1];
      }

      let days = phase.steps.map(i => i.day);
      let splitDays: number[] = [];
      days.forEach(item => {
        if(item.includes('-')){
          splitDays.push(...item.split('-').map(i => +i))
        } else if(item.includes(',')){
          splitDays.push(...item.split(',').map(i => +i))
        } else {
          splitDays.push(+item);
        }
      });
      let max = Math.max(...splitDays)
      date.setDate(date.getDate() + max+phase.daysSinceLastPhase);
      
      let description = '';
      if(nextPhase != null){
        description = `${phase.name} → ${nextPhase.name}`;
        //date.setDate(date.getDate());
      } else {
        description = `Finished Harvest`
      }
      let item: viewmodels.TimelineViewModel = {
        date: new Date(date.getTime()),
        description: description,
        harvestAtEnd: phase.harvestAtEnd
      }
      temp.push(item);

      // if(i != 0){
      //   date.setDate(date.getDate()-1);
      // }
    }
    this.listDisplay = temp;
    this.timelineList.emit(this.listDisplay);
  }

}
