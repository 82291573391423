<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" #popover="ngxMaterialPopover" (onClose)="closedEvent($event)"
    mode="toggle">
    <button mat-raised-button>
        {{ intervalTitle }}
        <mat-icon class="expand">expand_more</mat-icon>
    </button>
</ngx-material-popover>


<ng-template #popoverContent>
    <mat-form-field>
        <mat-label>Select Interval</mat-label>
        <mat-select  
        [(ngModel)]="selectedInterval" 
        (ngModelChange)="onIntervalOptionChange($event)" 
        >
        <mat-option *ngFor="let option of dateIntervalOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>
    
</ng-template>