import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

import * as models from '../../models';

@Component({
    selector: "time-spent-cell",
    template: `
        <mat-icon *ngIf="required" style="font-size: 12px; width: 15px;">emergency</mat-icon>
        <span *ngIf="value != null">Est Time: {{value}} min</span>
    `,
    styles: [
        `
        `
    ]
})
export class TimeSpentCellComponent implements ICellRendererAngularComp {
    private params: any;

    value: string;
    required: boolean = false;

    constructor() { }

    agInit(params: any): void {
        if (!params.value) {
            return;
        }

        this.params = params;

        this.value = this.params.value;
        this.required = this.params.data.timeSpentRequired;
    }

    refresh(params: any): boolean {
        return false;
    }
}