
<mat-form-field>
  <mat-select [disabled]="disabled" [formControl]="userCtrl" [placeholder]="placeholder" multiple (closed)="setUsers()">
    <mat-option>
      <ngx-mat-select-search [formControl]="userFilterCtrl"
                              placeholderLabel="Find user..."
                              noEntriesFoundLabel="no matching user found"></ngx-mat-select-search>
    </mat-option>
    <mat-select-trigger>
      {{userCtrl.value && userCtrl.value.length >= 1 ? userCtrl.value[0].displayName : 'None Selected'}}
      <span *ngIf="userCtrl.value?.length > 1" class="example-additional-selection">
        (+{{userCtrl.value.length - 1}} {{userCtrl.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      {{user.displayName}}
    </mat-option>
  </mat-select>
  <mat-spinner matSuffix *ngIf="loading" diameter=25 strokeWidth=4></mat-spinner>
</mat-form-field>

<div class="row" *ngIf="displayUserList">
  <div class="col-12">
    <h5>Selected Users:</h5>
    <ul class="list">
      <li *ngFor="let user of userCtrl.value">
        {{user.displayName}}
      </li>
    </ul>
  </div>
</div>

<!-- <mat-form-field appearance="fill" style="width: 100%">
  <mat-label>Users</mat-label>
  <mat-select name="users" [formControl]="selectedUsersFC" multiple (closed)="setUsers()">
    <mat-select-trigger>
      {{selectedUsersFC.value && selectedUsersFC.value.length >= 1 ? selectedUsersFC.value[0].displayValue : 'None Selected'}}
      <span *ngIf="selectedUsersFC.value?.length > 1" class="example-additional-selection">
        (+{{selectedUsersFC.value.length - 1}} {{selectedUsersFC.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let user of users" [value]="user">{{user.displayValue}}</mat-option>
  </mat-select>
</mat-form-field> -->
