import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "link-cell",
    template: `
        <a href="javascript:void(0)" (click)="btnClick()">{{value}}</a>
    `,
    styles: []
})
export class LinkCellComponent implements ICellRendererAngularComp {
    private params: any;

    value: string;
    func: any;

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        this.func = this.params.func;
    }

    refresh(params: any): boolean {
        return false;
    }

    btnClick() {
        this.func(this.params.data);
    }
}
