import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components';
import * as models from '../../models';
import * as services from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-interval-select-btn',
  templateUrl: './interval-select-btn.component.html',
  styleUrls: ['./interval-select-btn.component.scss']
})
export class IntervalSelectBtnComponent implements OnInit {

  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';


  @Input() dateRange: models.DateRanger;

  selectedIntervalValue: string;

  @Input() 
  get selectedInterval(): string {
    return this.selectedIntervalValue;
  };

  @Output() selectedIntervalChange = new EventEmitter();
  set selectedInterval(val: string) {
    if(val && val != null){
      this.selectedIntervalValue = val;  
      this.selectedIntervalChange.emit(this.selectedIntervalValue);
    }
  }

  @Output() onClosed = new EventEmitter();
  @Output() loaded = new EventEmitter();

  constructor(
    private helperService: services.HelperService
  ) { }

  isSelectOpen: boolean = false;

  dateIntervalOptions: string[] = [];
  rangeType: string = 'last_month';

  companySub: Subscription;

  intervalTitle: string;

  ngOnInit(): void {
    let companyId = this.helperService.currentCompanyId;
    if (companyId != null) {
      this.intervalTitle = 'Interval';
      this.makeChartIntervals(this.rangeType);
      this.loaded.emit();
    }
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.intervalTitle = 'Interval';
      this.makeChartIntervals(this.rangeType);
      this.loaded.emit();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRange && changes.dateRange.currentValue !== changes.dateRange.previousValue) {
      setTimeout(() => {
        this.intervalTitle = "Interval";
        this.selectedInterval = changes.dateRange.currentValue.defaultViewSetTo;
        this.makeChartIntervals(changes.dateRange.currentValue.rangeType);
      }, 0)
    }
  }

  makeChartIntervals(rangeType: string): void {
    switch(rangeType) {
      case 'last_week':
        this.dateIntervalOptions = ['Day', 'Week'];
        break;
      case 'last_month':
        this.dateIntervalOptions = ['Day', 'Week', 'Month'];
        break;
      case 'last_year':
        this.dateIntervalOptions = ['Day', 'Week', 'Month', 'Quarter']; 
        break;
      case 'all_time':
        this.dateIntervalOptions = ['Day', 'Week', 'Month', 'Quarter', 'Year'];
        break;
      case 'custom': 
        this.dateIntervalOptions = ['Day', 'Week', 'Month'];
        break;
    }
  }

  onIntervalOptionChange(option: string) {
    this.selectedInterval = option;
  }

  closedEvent(event: any) {
    if (this.selectedInterval) {
      this.intervalTitle = this.selectedInterval;
    }
    this.onClosed.emit(event);
  }

  ngOnDestroy() {
    if(this.companySub){
      this.companySub.unsubscribe();
    }
  }
}
