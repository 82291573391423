import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CreateCompanyComponent } from 'app/dialogs';

import * as services from '../../services';
import * as models from '../../models';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-insert',
  templateUrl: './form-insert.component.html',
  styleUrls: ['./form-insert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormInsertComponent implements OnInit {

  loading: boolean = false;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

  btnText: string = 'CREATE ACCOUNT';

  form: FormGroup;

  timezones: any;
  companyName: string;
  timezone: string = '';
  adminName: string;
  adminEmail: string;
  phone: string;
  //addUserToCompany: boolean = false;

  message: string;
  complete: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private helperService: services.HelperService,
    private companyService: services.CompanyService,
    private authService: services.AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.timezones = this.helperService.getTimeZones();

    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { order: "popular" }

        if(params.btnText){
          this.btnText = params.btnText;
        }
      }
    );
  }

  valid(): boolean {
    if(this.adminEmail == null){
      this.snackBar.open('Must enter in email address');
      return false;
    }

    if(!this.helperService.emailIsValid(this.adminEmail)){
      this.snackBar.open('Must enter a valid email address');
      return false;
    }

    if(this.adminName == '' || this.adminName == null){
      this.snackBar.open('Must enter an admin name');
      return false;
    }

    if(this.companyName == '' || this.companyName == null) {
      this.snackBar.open('Must enter a company name', null, {duration: 3000, verticalPosition: 'top'});
      return false;
    }
    
    if(this.timezone == '' || this.timezone == null) {
      this.snackBar.open('Please select a timezone', null, {duration: 3000, verticalPosition: 'top'});
      return false;
    }

    return true;
  }

  createCompany() {
    let valid = this.valid();

    if(!valid)
      return;

    let companyCreate: models.CompanyCreate = {
      companyName: this.companyName,
      timezone: this.timezone,
      addUserToCompany: true,
      adminName: this.adminName,
      adminEmail: this.adminEmail,
      phone: this.phone,
      freeVersion: true,
      domain: this.helperService.getCurrentDomain(),
      eventsSplit: false
    };
    this.createNewCompany(companyCreate);
  }

  async createNewCompany(companyCreate: models.CompanyCreate) {

    this.loading = true;
    this.message = null;

    this.companyService.createDemoCompany(companyCreate).then(data => {

      if(!data.data.success){
        this.message = data.data.message
        this.loading = false;
        return;
      }

      if(data.data.sendEmail){
        this.authService.sendWelcomeEmail(companyCreate.adminEmail);
        this.message = 'Check your email to finish the registration process.';
      } else {
        this.message = data.data.message;
      }

      this.loading = false;
      this.complete = true;
    }).catch(error => {
      this.snackBar.open(error.error);
      console.log(JSON.stringify(error))
      this.loading = false;
    })
  };
}
