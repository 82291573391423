import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-template-select-multi-btn',
  templateUrl: './template-select-multi-btn.component.html',
  styleUrls: ['./template-select-multi-btn.component.scss']
})
export class TemplateSelectMultiBtnComponent implements OnInit {

  templates: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);
  }

  get allSelected() {
    return this.selected.length == this.templates.length;
  }

  @Output() onClosed = new EventEmitter();

  firstLoad: boolean = true;

  constructor(
    private templateService: services.TemplateService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getAll();
  }

  async getAll() {
    let templates = await this.templateService.getAll()
    let list = templates.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i.uid,
        displayValue: i.name,
        selected: true // this.selected.includes(i.uid)
      }
      return h;
    })
    this.templates = this.helperService.sortArrayByStringField(list, 'displayValue');
    this.selected = this.templates.map(i => i.id);

    if(this.firstLoad){
      await this.helperService.wait(10);
      this.onClosed.emit();
      this.firstLoad = false;
    }
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
