<h2 mat-dialog-title class="dialog-title">
    {{type}} recurring event
</h2>
<mat-dialog-content>
    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
        [(ngModel)]="choice">
        <mat-radio-button class="radio-button" value="one">
            This event
        </mat-radio-button>
        <mat-radio-button class="radio-button" value="following">
            This and following events
        </mat-radio-button>
        <mat-radio-button class="radio-button" value="all">
            All events
        </mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" (click)="save()">Save</button>
</mat-dialog-actions>