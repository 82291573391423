import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { Observable, Subject } from 'rxjs';
import { HelperService } from './helper.service';

import * as viewmodels from 'app/viewmodels';

@Injectable()
export class AppStorage {

  constructor(
    private localStorage: LocalStorageService
    , private helperService: HelperService
    ) {
  }

  public releaseDate: Date = new Date(2024, 2 - 1, 25)
  public releaseVersion: string = 'V.24.02.01';
  public releaseVideoUrl: string = null; // 'https://www.youtube.com/embed/EwTZ2xpQwpA?si=FmVHBkdpWt_0UTbv'; //can be null
  
  public freeHarvests: number = 5;
  public freeTemplates: number = 3;
  public freeUsers: number = 1;

  private _skippedTour = new Subject<any>();
  getSkippedTour(): Observable<any> {
    return this._skippedTour.asObservable();
  }
  get skippedTour() {
    let val = this.localStorage.get('skippedTour');
    return JSON.parse(val);
  }
  set skippedTour(val: boolean) {
    if (val === null)
        this.localStorage.remove('skippedTour');
    else
        this.localStorage.set('skippedTour', JSON.stringify(val));
    this._skippedTour.next(val);
  }

  get weeklyListView() {
    let val = this.localStorage.get('weeklyListView');
    return JSON.parse(val);
  }
  set weeklyListView(val: boolean) {
    if (val === null)
        this.localStorage.remove('weeklyListView');
    else
        this.localStorage.set('weeklyListView', JSON.stringify(val));
  }

  get harvestListColGroups() {
    let str: string = this.localStorage.get("harvestListColGroups")
    let ret = JSON.parse(str);
    return ret;
  }
  set harvestListColGroups(val: Array<any>) {
    if (val === null || val.length == 0)
        this.localStorage.remove('harvestListColGroups');
    else{
      let str: string = JSON.stringify(val);
      this.localStorage.set("harvestListColGroups", str);
    }
  }
  
  /*
   * event list columns
   */
  set eventListColumns(eventListColumns: Array<string>) {
    if (eventListColumns === null || eventListColumns.length == 0) {
      this.localStorage.remove("eventListColumns");
    }
    else {
      let eventListColumnsString: string = JSON.stringify(eventListColumns);
      this.localStorage.set("eventListColumns", eventListColumnsString);
    }
  }
  get eventListColumns(): Array<any> {
    let str: string = this.localStorage.get("eventListColumns")
    let ret = JSON.parse(str);
    return ret;
  }
  
  /*
   * task group event list columns
   */
  set taskGroupEventListColumns(taskGroupEventListColumns: Array<string>) {
    if (taskGroupEventListColumns === null || taskGroupEventListColumns.length == 0) {
      this.localStorage.remove("taskGroupEventListColumns");
    }
    else {
      let eventListColumnsString: string = JSON.stringify(taskGroupEventListColumns);
      this.localStorage.set("taskGroupEventListColumns", eventListColumnsString);
    }
  }
  get taskGroupEventListColumns(): Array<any> {
    let str: string = this.localStorage.get("taskGroupEventListColumns")
    let ret = JSON.parse(str);
    return ret;
  }
  
  /*
   * leaflogix check time
   */
  set leaflogixCheckTime(leaflogixCheckTime: Date) {
    if (leaflogixCheckTime === null) {
      this.localStorage.remove("leaflogixCheckTime");
    }
    else {
      let leaflogixCheckTimeString: string = leaflogixCheckTime.toISOString();
      this.localStorage.set("leaflogixCheckTime", leaflogixCheckTimeString);
    }
  }
  get leaflogixCheckTime(): Date {
    let str: string = this.localStorage.get("leaflogixCheckTime")
    if(str == null){
      return null;
    }
    let ret = new Date(str);
    return ret;
  }

  /**
   * harvest list - show finished harvests
   */
  get showFinishedHarvests() {
    let val = this.localStorage.get('showFinishedHarvests');
    if(!val){
      return false;
    }
    return JSON.parse(val);
  }
  set showFinishedHarvests(val: boolean) {
    if (val === null)
        this.localStorage.remove('showFinishedHarvests');
    else
        this.localStorage.set('showFinishedHarvests', JSON.stringify(val));
  }

  get showFinishedHarvestsInFilters() {
    let val = this.localStorage.get('showFinishedHarvestsInFilters');
    if(!val){
      return false;
    }
    return JSON.parse(val);
  }
  set showFinishedHarvestsInFilters(val: boolean) {
    if (val === null)
        this.localStorage.remove('showFinishedHarvestsInFilters');
    else
        this.localStorage.set('showFinishedHarvestsInFilters', JSON.stringify(val));
  }

  get companyFiltersObject(): viewmodels.Filter {
    let companyId: string = this.helperService.currentCompanyId;

    let filterString = this.localStorage.get(`filter_${companyId}`)
    let filters = JSON.parse(filterString);
    return filters;
  }
  
  set companyFiltersObject(val: viewmodels.Filter) {
    if(val != null){
      let companyId: string = this.helperService.currentCompanyId;
      this.localStorage.set(`filter_${companyId}`, JSON.stringify(val));
    }

    // if (val["companyId"] === null)
    //     this.localStorage.remove('companyFilters');
    // else {
    //     let _cfd = JSON.parse(this.localStorage.get('companyFilters')) || [];
    //     let foundCompanyIndex = _cfd.findIndex(cfd => cfd["companyId"] === val.companyId);
    //     if (foundCompanyIndex !== -1) {
    //       // update company filters if currentCompanyId found in lS
    //       Object.keys(val['companyFilters']).map(_fCKey => {
    //         // console.log(_fCKey, val['companyFilters'][_fCKey])
    //         _cfd[foundCompanyIndex]['companyFilters'][_fCKey] = val['companyFilters'][_fCKey]
    //         // _cfd[foundCompanyIndex]['companyFilters'][_fCKey] = val['companyFilters'][_fCKey]
    //       })
    //     } else {
    //       // push company filters data to localStorage if currentCompanyId not found in lS
    //       _cfd.push(val);
    //     }
    // }
  }

  get harvestListSortDesc() {
    let val = this.localStorage.get('harvestListSortDesc');
    if(val == null){
      return true;
    }
    return JSON.parse(val);
  }
  set harvestListSortDesc(val: boolean) {
    if (val === null)
        this.localStorage.remove('harvestListSortDesc');
    else
        this.localStorage.set('harvestListSortDesc', JSON.stringify(val));
  }

  get unlinkedDutchieBatches() {
    let val = this.localStorage.get('unlinkedDutchieBatches');
    return +val;
  }
  set unlinkedDutchieBatches(val: number) {
    if (val === null)
        this.localStorage.remove('unlinkedDutchieBatches');
    else
        this.localStorage.set('unlinkedDutchieBatches', val);
  }
}
