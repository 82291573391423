<h2 mat-dialog-title>Shift Harvest</h2>
<mat-dialog-content>
  <form id="shiftHarvest" class="harvest-form" (ngSubmit)="submit()">

    <mat-form-field class="user-full-width" *ngIf="!needToPickHarvest && selectedHarvests[0]">
      <input matInput placeholder="Harvest" type="text" name="harvest" disabled [(ngModel)]="selectedHarvests[0].name">
    </mat-form-field>

    <mat-form-field class="user-full-width" *ngIf="needToPickHarvest">
      <mat-label>Select Harvest(s)</mat-label>
      <mat-select [(ngModel)]="selectedHarvests" (selectionChange)="harvestChange($event.value)" multiple name="harvest" #harvestSelect="ngModel">
          <mat-select-trigger>
            {{selectedHarvests.length == harvests.length ? 'ALL' : ''}}
            {{selectedHarvests.length != harvests.length && selectedHarvests.length > 0 ? selectedHarvests[0].name : ''}}
            <span *ngIf="selectedHarvests.length != harvests.length && selectedHarvests?.length > 1" class="example-additional-selection">
              (+{{selectedHarvests.length - 1}} {{selectedHarvests?.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option disabled="disabled" class="filter-option">
              <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllHarvests(harvestSelect, harvests)">
                  Select All
              </button>
              <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAllHarvests(harvestSelect)">
                  Deselect All
              </button>
          </mat-option>
          <mat-option [value]="h" *ngFor="let h of harvests">{{h.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field style="max-width: 600px" *ngIf="needToPickHarvest">
      <mat-label>Select a Harvest</mat-label>
      <mat-select [(value)]="selectedHarvest" (selectionChange)="harvestChange($event.value)">
      <mat-option [value]="h" *ngFor="let h of harvests">{{h.name }}{{ h.completed ? '(Completed)' : ''}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field class="user-full-width">
      <input matInput placeholder="Number of Days" type="number" required name="numberofdays" [(ngModel)]="numberOfDays">
    </mat-form-field>

    <mat-checkbox name="partialShift" [(ngModel)]="partialShift">Partial Shift</mat-checkbox>

    <mat-form-field *ngIf="partialShift">
      <input matInput [matDatepicker]="startDatePicker" placeholder="Choose a start date" name="startDate" [formControl]="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="formSubmitted">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="shiftHarvest" [disabled]="formSubmitted">
    <mat-spinner *ngIf="formSubmitted" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!formSubmitted">Shift</span>
  </button>
</mat-dialog-actions>
