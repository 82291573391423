import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as models from '../../models';
import * as services from '../../services';
import { Timestamp } from 'firebase/firestore';
import * as viewmodels from 'app/viewmodels';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { HarvestsReviewDialogComponent } from '../harvests-review-dialog/harvests-review-dialog.component';
import { DiscussionType } from '../../models';

@Component({
  selector: 'app-harvest-add',
  templateUrl: './harvest-add.component.html',
  styleUrls: ['./harvest-add.component.scss']
})
export class HarvestAddComponent implements OnInit {

  saveClick: boolean = false;
  saveAsGenericGroup: boolean = false;
  leaflogixId: string;
  leaflogixName: string;

  get mainPanelClass() {
    if(this.saveAsGenericGroup) {
      return 'col-sm-12';
    }
    if(this.allStrains.length == 0 || this.leaflogixId != null) { // && this.zones.length == 0)){      
      return 'col-sm-6';
    }

    // if(this.allStrains.length > 0 && this.strains.length > 0 && this.zones.length > 0){
    //   return 'col-sm-4'
    // }

    return 'col-sm-4';
  }

  get strainPanelClass() {
    // if(this.zones.length > 0){
    //   return 'col-sm-4'
    // } else {
    //   return 'col-sm-6';
    // }
    return 'col-sm-4';
  }

  get timelinePanelClass() {
    // if(this.zones.length > 0){
    //   return 'col-sm-4'
    // } else {
    //   return 'col-sm-6';
    // }

    return 'col-sm-4';
  }

  // get zonePanelClass() {
  //   if(this.allStrains.length > 0 && this.strains.length > 0){
  //     return 'col-sm-4'
  //   } else {
  //     return 'col-sm-6';
  //   }
  // }

  get dialogTitle(): string {
    if(this.saveAsGenericGroup){
      return 'Add Task Group';
    } else {
      return 'Add Harvest';
    }
  }

  get namePlaceHolder(): string {
    if(this.saveAsGenericGroup){
      return 'Group Name';
    } else {
      return 'Harvest Name';
    }
  }

  //zones: Array<models.Zone> = [];

  templates: Array<models.Template> = [];
  selectedTemplate: models.Template;
  name: string;
  cloneCount: number;
  color: string;
  startDate:Date = new Date();


  allStrains: Array<models.Strain> = [];
  strainIds: Array<string> = [];
  //strains: Array<models.Strain> = [];
  harvestPhases?: Array<models.HarvestPhase> = [];

  secondaryTemplates: Array<any> = [];
  selectedSecondaryTemplate: models.Template | any;

  showTimeline: boolean = false;
  
  harvestForm = new models.HarvestTabForm();
  harvestFormDataArray: Array<any> = [];
  selectedHarvestTab: number = 0; // keep track of current harvest tab

  constructor(
    private dialogRef: MatDialogRef<HarvestAddComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private templateService: services.TemplateService
    , private harvestService: services.HarvestService
    , private groupService: services.GroupService
    , public snackBar: MatSnackBar
    , private route: ActivatedRoute
    , private zoneService: services.ZoneService
    , private helperService: services.HelperService
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private router: Router
    , private eventService: services.EventService
    , private claimsService: services.ClaimsService
    ) {
    dialogRef.disableClose = true;

    if(data.addGroup){
      this.saveAsGenericGroup = true;
    }
    if(data.leaflogixId){
      this.leaflogixId = data.leaflogixId;
      this.harvestForm.leaflogixId = this.leaflogixId;
    }
    if(data.leaflogixName){
      this.leaflogixName = data.leaflogixName;
      this.harvestForm.name = this.leaflogixName;
    }
    if(data.startDate) {
      this.harvestForm.startDate = new Date(data.startDate);
    }
    if(data.name){
      this.name = data.name;
    }
  }

  ngOnInit() {
    // let tourstep: string = this.route.snapshot.queryParamMap.get('tourstep');
    // if(tourstep != null){
    //   this.name = 'Tour Harvest';
    //   this.color = '#C62424'
    // }
    this.harvestForm.formId = uuidv4();
    this.harvestFormDataArray.push(this.harvestForm);
    this.getTemplates();
    // if(!this.saveAsGenericGroup){
    //   this.getZones();
    // }
  }

  navigateToTemplates(event: any) {
    if(event.value.name === "You don't have any Secondary Templates saved. Go to Templates to build one.") {
      this.dialogRef.close({
        added: false,
        id: null,
      })
      this.router.navigate(['/console', 'templates']);
    }
    
  }
  
  async getTemplates() {
    let data = await this.templateService.getAll();
    let sortedData = this.helperService.sortArrayByStringField(data, 'name');
    //alert(JSON.stringify(data));
    if(this.saveAsGenericGroup) {
      let secondaryOnlyTemplates = sortedData.filter(sd => sd.type && sd.type === models.TemplateType.Secondary);
      let extraOptions = [];
      if(secondaryOnlyTemplates.length === 0) {
          extraOptions.push( {name: "You don't have any Secondary Templates saved. Go to Templates to build one.", uid: null});
      }
      // extraOptions.push({name: "Don't Apply Template", uid: null});
      this.secondaryTemplates = [...extraOptions, ...secondaryOnlyTemplates];
    } else {
      this.harvestForm.templates = sortedData;
      if(this.harvestForm.templates.length !== 0){
        this.templateChange(this.harvestForm.templates[0], this.harvestForm.formId);
      }
    }
  }

  // async getZones() {
  //   let zones = await this.zoneService.getAll();
  //   this.zones = this.helperService.sortArrayByStringField(zones, 'name');
  // }

  templateChange(newVal, tabId: string){
    // this.selectedTemplate = newVal;
    // console.log(this.harvestFormDataArray);
    // this.harvestForm.selectedTemplate = newVal;
    let currentForm = this.harvestFormDataArray.find(_hf => _hf.formId === tabId);
    currentForm.selectedTemplate = newVal;
    // this.harvestForm.selectedTemplate = newVal;
    let strains = currentForm.harvestPhases[0] != null ? currentForm.harvestPhases[0].strains : [];
    if(strains == null){
      strains = [];
    }

    currentForm.harvestPhases = [];
    let phases = currentForm.selectedTemplate.phases.map(i => i.name);

    //set up harvest phases
    phases.forEach(phase => {
      let harvestPhase: models.HarvestPhase = {
        phaseName: phase
      }
      if(strains.length > 0){
        harvestPhase.strains = [];
        strains.forEach(strain => {
          let newStrain: models.HarvestPhaseStrain = {
            strainId: strain.strainId,
            strainName: strain.strainName,
            count: 0
          };
          harvestPhase.strains.push(newStrain);
        });
      }
      currentForm.harvestPhases.push(harvestPhase);
    });
    // //set up zones
    // phaseZones.forEach(phase => {
    //   let phaseZone: models.ZonePhase = {
    //     phaseName: phase
    //   }
    //   this.phaseZones.push(phaseZone);
    // });

    // //set up strains
    // this.harvestStrains.forEach(harvestStrain => {

    //   let i = 0;
    //   for (i; i < phaseZones.length; i++) {
    //     const phase = phaseZones[i];
  
    //     if(harvestStrain.phases[i] != null){
    //       harvestStrain.phases[i].phaseName = phase;
    //     } else {
    //       harvestStrain.phases.push({
    //         phaseName: phase,
    //         count: 0
    //       })
    //     }
    //   }
    //   if(phaseZones.length < harvestStrain.phases.length){
    //     harvestStrain.phases.length = phaseZones.length;
    //   }
    // });
  }

  addHarvestTab(): void {
    this.harvestForm = new models.HarvestTabForm();
    this.harvestForm.formId = uuidv4();
    this.harvestFormDataArray.push(this.harvestForm);
    this.selectedHarvestTab = this.harvestFormDataArray.length - 1;
    this.getTemplates();
  }

  deleteHarvestClick(tab: number) {
    this.notifierService.confirm('Delete Harvest?', 'Are you sure you want to delete this Harvest?',
    () => {this.deleteHarvestTab(tab)}, () => {})
  }

  deleteHarvestTab(tab: number) {
    this.harvestFormDataArray.splice(tab, 1);
    this.selectedHarvestTab = this.harvestFormDataArray.length - 1;
  }

  /**
   * will make sure the data being submitted is valid and will display errors if it is not valid
   */
  valid(): boolean {
    if (this.saveAsGenericGroup) {
      if(this.name == null || this.name == ''){
        this.notifierService.error('Must provide a name for the Task Group.')
        return false;
      }
      return true;
    } else {
      if(this.harvestFormDataArray.filter(val => !val.hasOwnProperty("name")).length > 0){
        this.notifierService.error('Must provide a name for the Harvest.')
        return false;
      }
      return true;
    } 
    
  }

  save(): void {
    if(!this.valid()){
      return;
    }
    if(this.harvestFormDataArray.some(_hf => _hf.hasOwnProperty('leaflogixId')) && this.harvestFormDataArray.some(_hf => _hf.selectedTemplate.phases.some(i => i.compliancePhase == null))){
      this.notifierService.errorWithBtn('You do not have a Dutchie compliance stage associated with each phase in the Template you are using to create this Harvest. Need help?', 
          'Click here', 
          () => {this.dialogRef.close({
            added: false,
            templateId: this.harvestForm.selectedTemplate?.uid
          });
        }, false);
      return;
    }

    this.saveClick = true;
    if(this.saveAsGenericGroup){
      this.saveGroup();
    } else {
      this.saveHarvest();
    }
  }

  saveGroup(): void {
    let group: models.Group;
    if(this.selectedSecondaryTemplate && this.selectedSecondaryTemplate.uid !== null) {
      group = {
        color: this.color,
        name: this.name,
        templateId: this.selectedSecondaryTemplate.uid,
        startDate: this.helperService.dateStringToTimestamp(this.startDate.toString()),
      }
    } else {
      group = {
        color: this.color,
        name: this.name,
      }
    }
    if(group.color == null){
      this.notifierService.error('Must select a color to add a Task Group')
      this.saveClick = false;
      return;
    }
    this.groupService.add(group).then((data: any) => {
      let response: models.Result<any> = data.data;

      if(response.success) {
        this.notifierService.success(`Successfully created Task Group - ${this.name}`);
        this.dialogRef.close({
          added: true,
          id: response.value
        });
      } else {
        this.notifierService.brokenRules(response.brokenRules);
      }
      this.saveClick = false;
    })
  }

  saveHarvest() {
    let _harvestFormDataArray = this.harvestFormDataArray.filter(data => !data.addSuccess)
    const reviewHarvestsDialogRef = this.dialog.open(HarvestsReviewDialogComponent, {
      disableClose: true,
      data: _harvestFormDataArray,
    });
    reviewHarvestsDialogRef.afterClosed().subscribe(async (reviewedData) => {
      if(reviewedData.added) {
        this.saveClick = false;
        // await this.processHarvestsAdd()
        this.dialogRef.close({
          added: true,
          id: reviewedData.ids,
          harvestNames: this.harvestFormDataArray.map(i => i.name)
        });
      } else {
        this.saveClick = false;
        this.harvestFormDataArray = reviewedData.ids;
        this.selectedHarvestTab = 0;
      }
    });
    this.saveClick = false;
  }

  timelineListHandler(data: viewmodels.TimelineViewModel[], harvestTabId: string) {
    let _currentForm = this.harvestFormDataArray.find(formData => formData.formId === harvestTabId);
    let _startDateTimestamp = this.helperService.dateStringToTimestamp(_currentForm.startDate); 
    _currentForm["endDate"] = data[data.length - 1].date;

    let harvestPhase = data.find(i => i.harvestAtEnd == true);
    let harvestDate: any = harvestPhase != null ? harvestPhase.date : _currentForm.endDate;

    let _harvestDateTimestamp = this.helperService.dateStringToTimestamp(harvestDate); 
    let _endDateTimestamp = this.helperService.dateStringToTimestamp(_currentForm.endDate);
    _currentForm["harvestDuration"] = this.helperService.calculateHarvestDurationInDays(_startDateTimestamp, _endDateTimestamp)    
    _currentForm["startDateTimestamp"] = _startDateTimestamp;
    _currentForm["harvestDateTimestamp"] = _harvestDateTimestamp;
    _currentForm["endDateTimestamp"] = _endDateTimestamp;
  }

  // async navigateToTemplate(templateId: string) {
  //   let extras: NavigationExtras = {
  //     queryParams: {
  //       'tourstep': 'stages'
  //     }
  //   }
  //   this.router.navigate(['console', 'template', templateId], extras)
  // }
}
