import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-phase-select-multiple',
  templateUrl: './phase-select-multiple.component.html',
  styleUrls: ['./phase-select-multiple.component.scss']
})
export class PhaseSelectMultipleComponent implements OnInit {

  @Input() getFromTemplate: boolean = false;

  phases: Array<string> = [];
  loadingPhases: boolean = true;

  harvestsValue: Array<models.Harvest>;
  @Input()
  get harvests() {
    return this.harvestsValue;
  }
  set harvests(val) {
    this.harvestsValue = val;
    this.getPhases();
  }
  
  phaseIDsValue: Array<string>;
  @Input()
  get phaseIds() {
    return this.phaseIDsValue;
  }
  @Output() phaseIdsChange = new EventEmitter();
  set phaseIds(val) {
    if(val == null){
      val = [];
    }
    this.phaseIDsValue = val;
    //this.setForm();
    this.phaseIdsChange.emit(this.phaseIDsValue);
  }

  get title(): string {
    if(this.phaseIds == null || this.phases == null){
      return '';
    }
    if(this.phaseIds.length == this.phases.length){
      return 'ALL';
    }
    if(this.phaseIds.length >= 1){
      let title = this.phaseIds[0];
      if(this.phaseIds.length >= 2){
        title += ` (+${this.phaseIds.length - 1} ${this.phaseIds?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }

  constructor(
    private eventService: services.EventService
    , private helperService: services.HelperService
    , private templateService: services.TemplateService
  ) { }

  ngOnInit(): void {
    if(this.getFromTemplate){
      this.getPhases();
    }
  }

  phaseChange(newValue) {
    this.phaseIds = newValue;
  }

  selectAllPhases(select: NgModel, values) {
    select.update.emit(values); 
    this.phaseChange(this.phases);
  }

  deselectAllPhases(select: NgModel) {
    select.update.emit([]); 
    this.phaseChange([]);
  }

  async getPhases() {
    this.loadingPhases = true;

    if(this.getFromTemplate){
      let bothPhaseLists = await this.templateService.getPhasesForAllTemplates();
      this.phases = [...bothPhaseLists.preCutPhases, ...bothPhaseLists.postCutPhases];
    } else {
      let eventResponse = await this.eventService.getByHarvestIdList(this.harvests.map(i => i.uid));
  
      let phases = eventResponse.map(i => { return i.phase});
      this.phases =  [...new Set(phases)];
    }
  }
}
