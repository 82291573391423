<div class="row">
    <div id="calendar-filters" class="col-6">
        <div class="row" style="max-width: 800px;">
            <div class="col-8" style="padding-right: 0">
                <mat-form-field>
                    <mat-label>Select Harvest(s)</mat-label>
                    <mat-select [(ngModel)]="selectedHarvests" (selectionChange)="harvestChange($event.value)" multiple #harvestSelect="ngModel">
                        <mat-select-trigger>
                          {{selectedHarvests.length == harvests.length ? 'ALL' : ''}}
                          {{selectedHarvests.length != harvests.length && selectedHarvests.length > 0 ? selectedHarvests[0].name : ''}}
                          <span *ngIf="selectedHarvests.length != harvests.length && selectedHarvests?.length > 1" class="example-additional-selection">
                            (+{{selectedHarvests.length - 1}} {{selectedHarvests?.length === 2 ? 'other' : 'others'}})
                          </span>
                        </mat-select-trigger>
                        <mat-option disabled="disabled" class="filter-option">
                            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllHarvests(harvestSelect, harvests)">
                                Select All
                            </button>
                            <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAllHarvests(harvestSelect)">
                                Deselect All
                            </button>
                        </mat-option>
                        <mat-option [value]="h" *ngFor="let h of harvests">{{h.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field>
                    <mat-label>Select a Harvest</mat-label>
                    <mat-select [(value)]="selectedHarvest" (selectionChange)="harvestChange($event.value)">
                        <mat-option [value]="h" *ngFor="let h of harvests">{{h.name }}{{ h.completed ? '(Completed)' : ''}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <div class="col-4" style="padding-left: 0">
                <div style="padding-top: 10%" *ngIf="loadingHarvests">
                    <i class="fa fa-spinner fa-spin"></i>
                </div>
                <button id="calendar-harvestAdd" class="gridbtn" mat-raised-button *ngIf="canSeeHarvestAdd() && !loadingHarvests" (click)="addHarvest()" title="Add Harvest">
                    <mat-icon aria-hidden="false" aria-label="Add Harvest">add</mat-icon>
                </button>
            </div>
        </div>
        <div class="row" style="max-width: 800px;">
            <div class="col-8" style="padding-right: 0">
                <mat-form-field>
                    <mat-label>Select a Group</mat-label>
                    <mat-select [(ngModel)]="selectedGroups" (selectionChange)="groupChange($event.value)" multiple #groupSelect="ngModel">
                        <mat-select-trigger>
                          {{selectedGroups.length == groups.length ? 'ALL' : ''}}
                          {{selectedGroups.length != groups.length && selectedGroups.length > 0 ? selectedGroups[0].name : ''}}
                          <span *ngIf="selectedGroups.length != groups.length && selectedGroups?.length > 1" class="example-additional-selection">
                            (+{{selectedGroups.length - 1}} {{selectedGroups?.length === 2 ? 'other' : 'others'}})
                          </span>
                        </mat-select-trigger>
                        <mat-option disabled="disabled" class="filter-option">
                            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllGroups(groupSelect, groups)">
                                Select All
                            </button>
                            <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAllGroups(groupSelect)">
                                Deselect All
                            </button>
                        </mat-option>
                        <mat-option [value]="g" *ngFor="let g of groups">{{g.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" style="padding-left: 0">
                <div style="padding-top: 10%" *ngIf="loadingHarvests">
                    <i class="fa fa-spinner fa-spin"></i>
                </div>
                <button id="calendar-groupAdd" class="gridbtn" mat-raised-button *ngIf="canSeeGroupAdd() && !loadingHarvests" (click)="addGroup()" title="Add Task Group">
                    <mat-icon aria-hidden="false" aria-label="Add Group">add</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="col-3">
        <mat-form-field style="max-width: 600px">
            <mat-label>Select a Harvest</mat-label>
            <mat-select [(value)]="selectedHarvest" (selectionChange)="harvestChange($event.value)">
            <mat-option [value]="h" *ngFor="let h of harvests">{{h.name }}{{ h.completed ? '(Completed)' : ''}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
    <div class="col-6">
        <div class="right">
            <mat-slide-toggle [(ngModel)]="onlyMyTasks" class="gridbtn" (change)="toggleChange($event)">Only my Tasks</mat-slide-toggle>
        </div>
        <div class="right" style="padding-bottom: 10px">
            <button class="gridbtn" *ngIf="canSeeMassUserAssign()" (click)="usersMassAssign()" mat-raised-button title="Mass assign Users">
                <mat-icon aria-hidden="false" aria-label="Mass assign Users">group_add</mat-icon>
            </button>

            <button class="gridbtn" *ngIf="canSeeUserFilter()" (click)="filterUserSchedule()" mat-raised-button title="Users to view Schedule for">
                <mat-icon aria-hidden="false" aria-label="Users to view Schedule for">assignment_ind</mat-icon>
            </button>

            <!--shift harvest button-->
            <app-harvest-shift-btn></app-harvest-shift-btn>

            <!--delete harvest button-->
            <!-- <app-harvest-delete-btn [harvest]="selectedHarvest" (doneDeleting)="getHarvests()"></app-harvest-delete-btn> -->
            <button class="gridbtn" *ngIf="canSeeHarvestGroupDelete()" (click)="harvestGroupDelete()" mat-raised-button title="Delete Harvests and Groups">
                <mat-icon aria-hidden="false" aria-label="Delete Harvests and Groups">delete</mat-icon>
            </button>

            <!--edit harvest button - no more selected Harvest so we don't need this here-->
            <!-- <app-harvest-edit-btn [harvest]="selectedHarvest" (done)="getHarvests()"></app-harvest-edit-btn> -->
            
            <!-- <button id="calendar-harvestAdd" class="gridbtn" mat-raised-button *ngIf="canSeeHarvestAdd()" (click)="addHarvest()" title="Add Harvest">
                <mat-icon aria-hidden="false" aria-label="Add Harvest">add</mat-icon>
            </button> -->
            <!-- <button type="button" class="fc-button fc-button-primary" (click)="addHarvest()">
                <mat-icon aria-hidden="false" aria-label="Add Harvest" title="Add Harvest">add</mat-icon>
            </button> -->
        </div>
    </div>
</div>
<div class="row" *ngIf="canSeeAddEventBtn || canSeeWeeklyListSlider">
    <div class="col-12" style="padding-bottom: 10px;">
        <div style="float: right">
            <mat-slide-toggle *ngIf="canSeeWeeklyListSlider" [(ngModel)]="weeklyListView" class="gridbtn" (change)="toggleListViewChange($event)">List View</mat-slide-toggle>
            <button *ngIf="canSeeAddEventBtn" class="gridbtn" (click)="addEvent()" mat-raised-button title="Add Event">
                <mat-icon aria-hidden="false" aria-label="Add Event">add</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <!-- <full-calendar #calendar 
            defaultView="dayGridMonth"
            [plugins]="calendarPlugins"
            [events]="calendarEvents"
            (dateClick)="handleDateClick($event)"
            eventLimit=true
            [views]="calendarViews"
            [header]="calendarHeader"
            (eventClick)="eventClick($event)"
            [eventOrder]="eventOrder"
            height="auto"
            [eventStartEditable]="canDragEvents"
            (eventDrop)="eventDrop($event)"
            (eventRender)="eventRenderer($event)"
            (datesRender)="viewRender($event)"
        ></full-calendar> -->
    </div>
</div>