import { Injectable } from '@angular/core';
import * as models from '../models';
import { SessionStorageService } from 'angular-web-storage';
import { HelperService } from './helper.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class MigrationService {

  constructor(
    private functions: Functions,
    private storage: SessionStorageService,
    private helperService: HelperService) {
  }

  async migrateCompany(companyId: string) {

    let request: models.MigrateCompanyRequest = {
        companyId: companyId
    }

    const method = httpsCallable<any, models.Result<string>>(this.functions, 'migrateCompany');
    var response = await method(request);

    let retVal: models.Result<string> = response.data;

    return retVal;
  }
}
