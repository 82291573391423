<ng-container *ngIf="templatedTask != null">
    <div class="row">
        <div class="col-12">
            <div style="float: right;">
                <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Save" (click)="save()">
                    <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
                    <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save">save</mat-icon>
                </button>
                <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Discard Changes" (click)="undo()">
                    <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
                </button>
                <button *ngIf="id != 'add'" mat-raised-button class="pad-btn" title="Delete" (click)="delete()">
                    <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
          <mat-checkbox name="highPriority" [(ngModel)]="templatedTask.highPriority">High Priority</mat-checkbox>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Templated Task Name</mat-label>
                <input matInput [(ngModel)]="templatedTask.name">
                <mat-hint>Name of Templated Task - will not show up on Calendar Event</mat-hint>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Task Description</mat-label>
                <input matInput [(ngModel)]="templatedTask.description">
                <mat-hint>Description of Event - this will show up on Calendar</mat-hint>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row" style="padding-top: 10px">
      <div class="col-5">
        <mat-checkbox name="anyTime" [(ngModel)]="templatedTask.anyTime">Anytime</mat-checkbox>
      </div>
      <div class="col-7">
        <mat-form-field class="user-full-width" *ngIf="!templatedTask.anyTime">
          <input matInput name="startTime" type="time" [(ngModel)]="templatedTask.startTime" placeholder="Start Time">
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <app-chip-list-edit [label]="'Tags'" [allChips]="allTags" [(chips)]="templatedTask.tags"></app-chip-list-edit>
        </div>
    </div>
    
    <div class="row">
        <div class="col-5">
            <mat-checkbox name="anyTime" [(ngModel)]="templatedTask.timeSpentRequired">Time spent required</mat-checkbox>
        </div>
        <div class="col-7">
            <mat-form-field class="user-full-width">
                <mat-label>Estimated Time</mat-label>
                <input matInput name="estimatedTime" type="number" [(ngModel)]="templatedTask.estimatedTime">
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <app-team-select-multiple
                [(teamIds)]="templatedTask.assignedTeamIds">
            </app-team-select-multiple>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <button style="margin-right: 12px" mat-raised-button (click)="cloudClick()" [matBadge]="templatedTask.cloudAttachments?.length" [matBadgeHidden]="templatedTask.cloudAttachments?.length===0">
                <mat-icon>cloud</mat-icon>
            </button>
            <button style="margin-right: 12px" mat-raised-button (click)="attachmentsClick()" [matBadge]="templatedTask.attachments?.length" [matBadgeHidden]="templatedTask.attachments?.length===0">
                <mat-icon>attachment</mat-icon>
            </button>
            <button style="margin-right: 12px" mat-raised-button (click)="notesClick()" [matBadge]="templatedTask.notes?.length" [matBadgeHidden]="templatedTask.notes?.length===0">
                <mat-icon>sticky_note_2</mat-icon>
            </button>
            <button style="margin-right: 12px" mat-raised-button (click)="subtasksClick()" [matBadge]="templatedTask.subtasks?.length" [matBadgeHidden]="templatedTask.subtasks?.length===0">
                <mat-icon>event_list</mat-icon>
            </button>
        </div>
    </div>
    
</ng-container>
