import * as viewModels from '../viewmodels';

export enum MapDataType {
    UserRefresh = 'user_refresh',
    UserRedirect = 'user_redirect',
}

export enum MapDisplayType {
    UserHeat = 'user_heat',
    UserPin = 'user_pin',
}

// export enum Claim {
//     admin = "System Administrator",
//     manager = "Manager",
//     standardUser = "Standard User"
// }

export enum SelectDefault {
    None = 'none',
    All = 'all',
    NotCompleted = 'not_completed'
}


export enum DiscussionType {
    Created = 'created',
    InitialNote = 'initial_note',
    Note = 'note',
    CompletedStatus = 'completed_status',
    Subtask = 'subtask_completed',
    SubtaskAdded = 'subtask_added',
    SubtaskRemoved = 'subtask_removed',
    TimeSpent = 'time_spent',
    Efficacy = 'efficacy',
    AttachedFile = 'attached_file',
}

export enum SquareSubscriptionStatus {
    Active = 'ACTIVE',
    Pending = 'PENDING',
    Cancelled = 'CANCELED',
    Deactivated = 'DEACTIVATED'
}

export enum ProcessType {
    TemplateToHarvestSync = 'template_to_harvest_sync',
    MigrateCompany = 'migrate_company',
}

export enum ProcessStatus {
    Requested = 'requested',
    InProgress = 'in_progress',
    Completed = 'completed',
    Error = 'error',
    RevertingInProgress = 'reverting_in_progress',
    RevertCompleted = 'revert_completed'
}

export enum DataTypes {
    text = 'text',
    number = 'number'
}
// export class dataTypes {
//     public types = [
//         {
//           display: 'Text',
//           value: 'text'
//         },
//         {
//           display: 'Number',
//           value: 'number'
//         }
//       ];
// }

export class Contstants {
    public static daysOfWeek: Array<string> = [
        'Sun',
        'Mon',
        'Tues',
        'Wed',
        'Thur',
        'Fri',
        'Sat'
      ];
}

export enum CompleteStatus {
    incomplete = 'incomplete',
    complete = 'complete',
    both = 'both'
}

export enum TemplateType {
    Primary = 'primary',
    Secondary = 'secondary'
}

export class TemplateTypeList {
    list: viewModels.ViewModel[] = [
      {
        id: TemplateType.Primary,
        name: 'Primary'
      },
      {
        id: TemplateType.Secondary,
        name: 'Workflow'
      }
    ];
}

export enum SavedViewType {
    Calendar = 'calendar',
    Dashboard = 'dashboard'
}

export enum AlertRedirectType {
    dutchie = 'dutchie'
}

export enum queryStringKeys {
    Templatedtask = 'tt',
}