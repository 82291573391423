import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from 'app/services';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  //forAttachments: boolean = false;
  header: string;
  placeholder: string;
  notes: Array<string> = [];

  constructor(
    private dialogRef: MatDialogRef<NotesComponent>
    , @Inject(MAT_DIALOG_DATA) data
    , private helperService: HelperService
  ) { 
    if(data?.list){
      this.notes = this.helperService.deepCopy(data.list);
    } else {
      this.notes = [''];
    }
    
    this.header = data.header;
    this.placeholder = data.placeholder;
  }

  ngOnInit(): void {
  }
  
  async add(){
    this.notes.push('');
    await this.helperService.wait(100);
    document.getElementById("note_" + (this.notes.length-1)).focus();
  }

  onValueUpdate(e, i){
    this.notes[i] = e.target.value;
  }

  remove(n: number){
    //this.notes.splice(this.notes.findIndex(i => i == n), 1);
    this.notes.splice(n, 1);
  }

  save() {
    this.dialogRef.close(this.notes);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

}
