import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import * as services from 'app/services';
import { Subscription } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { RestrictionNotificationComponent, TemplateAddComponent, TemplateUploadComponent, TemplateWorkflowDeleteComponent } from 'app/dialogs';
import { Router } from '@angular/router';
import { TemplateGridComponent } from '../template-grid/template-grid.component';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit, OnDestroy {

  @ViewChild("primaryList") primaryListComponent: TemplateGridComponent;
  @ViewChild("secondaryList") secondaryListComponent: TemplateGridComponent;

  companySub: Subscription;
  userRole: models.Role;

  harvests: models.Harvest[];
  primaryTemplates: viewmodels.TemplateListViewModel[];
  secondaryTemplates: viewmodels.TemplateListViewModel[];

  get canAdd() : boolean {
    if(this.helperService.company_freeVersion && this.primaryTemplates.length + this.secondaryTemplates.length >= this.appStorage.freeTemplates) {
      return false;
    }
    
    return this.userRole?.permissions?.includes(models.Permissions.addTemplate);
  }

  get canDelete(): boolean {
    return this.userRole?.permissions?.includes(models.Permissions.deleteTemplate);
  }

  constructor(
    private helperService: services.HelperService
    , private templateService: services.TemplateService
    , private harvestService: services.HarvestService
    , private dialog: MatDialog
    , private appStorage: services.AppStorage
    , private router: Router
    , private claimsService: services.ClaimsService
  ) { }

  async ngOnInit(): Promise<void> {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      //this.getStrains();
      this.load();
    }

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load();
    })

    this.userRole = await this.claimsService.getRoleAwait();
  }

 

  ngOnDestroy() {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  async load() {
    await this.getHarvests();
    this.getTemplates();
  }

  async getHarvests() {
    let harvests: models.Harvest[] = [];
    let ss = await this.harvestService.getAll().get().toPromise();
    ss.docs.forEach(doc => {
      let harvest: models.Harvest = doc.data()
      harvest.uid = doc.id;
      harvests.push(harvest);
    });
    this.harvests = harvests;
  }

  async getTemplates(id: string = null) {
    //remove all data
    // if(this.gridOptions.api) {
    //   this.gridOptions.api.setRowData([]);
    // }
    this.primaryTemplates = [];
    this.secondaryTemplates = [];

    let data = await this.templateService.getAll();
    //alert(JSON.stringify(data));
    let templates: models.Template[] = this.helperService.sortArrayByStringField(data, 'name');
    let _pvms: viewmodels.TemplateListViewModel[] = [];
    let _svms: viewmodels.TemplateListViewModel[] = [];

    templates.forEach(template => {
      let totalDays: number = 1;
      let events: number = 0;
      template.phases.forEach(phase => {
        phase.steps.forEach(s => {
          let day: string = '';
          if(s.day != null){
            day = s.day?.toString();
          }
          
          if(day.includes(',')){
            events += day.split(',').length;
          } else if(day.includes('-')){
            let t = day.split('-');
            events += (+t[1] - +t[0]) + 1;
          } else {
            events++;
          }
        })

        totalDays += phase.daysSinceLastPhase;
        let days = phase.steps.flatMap(i => {
          if(!i.day){
            return 0
          }
          let day: string = '';
          if(i.day != null){
            day = i.day?.toString();
          }
          if(day.includes(',')){
            return day.split(',').map(j => +j);
          } else if(day.includes('-')){
            return day.split('-').map(j => +j);
          } else {
            return [+day]
          }
        }).sort((a, b) => {return b-a});

        if(days.length > 0){
          totalDays += days[0];
        }

      });
      let vm: viewmodels.TemplateListViewModel = {
        id: template.uid,
        name: template.name,
        phases: template.phases.length,
        events: events,
        harvestsCreated: this.harvests.filter(i => i.templateId == template.uid).length,
        duration: totalDays
      }
      if(template.type == models.TemplateType.Secondary) {
        _svms.push(vm);
      } else {
        _pvms.push(vm);
      }
    });
    this.primaryTemplates = _pvms;
    this.secondaryTemplates = _svms;
    
    // if(this.templates.length !== 0){
    //   let foundTemplate = this.templates.find(i => i.uid == id);
    //   if(foundTemplate == null){
    //     this.templateChange(this.templates[0]);
    //   } else {
    //     this.templateChange(foundTemplate, false);
    //   }
    // }
  }


  add() {
    if(this.canAdd){
      this.openTemplateAddComp(false, undefined);
    } else {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'templateAdd'
      });
    }
  }

  openTemplateAddComp(isCopy: boolean, templateToCopy: models.Template){
    let dialogRef = this.dialog.open(TemplateAddComponent, {
      panelClass: 'med-width-dialog',
      data: {isCopy: isCopy, templateToCopy: templateToCopy} //, selectedTemplate: this.selectedTemplate
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.router.navigate(['console', 'template', result]);
        //this.load();
      }
      console.log(JSON.stringify(result));
    })
  }

  import() {
    if(!this.canAdd){
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'templateAdd'
      });
      return;
    }

    let dialogRef = this.dialog.open(TemplateUploadComponent, {
      data: {} //selectedTemplate: this.selectedTemplate
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.router.navigate(['/console', 'template', result]);
      }
      console.log(JSON.stringify(result));
    })
  }

  exportAsCSV(type: string) {
    switch(type){
      case(models.TemplateType.Primary): {
        this.primaryListComponent.exportAsCSV();
      }
      case(models.TemplateType.Secondary): {
        this.secondaryListComponent.exportAsCSV();
      }
    }
  }

  templateWorkflowDelete() {
    let dialogRef = this.dialog.open(TemplateWorkflowDeleteComponent, {
      panelClass: 'full-width-dialog',
      data: { hideGroups: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.getHarvests();
    })
  }
}
