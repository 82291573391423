import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as services from '../../services'

@Component({
  selector: 'app-demo-request',
  templateUrl: './demo-request.component.html',
  styleUrls: ['./demo-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DemoRequestComponent implements OnInit {

  growSize: string;
  employees: string;
  schedulingProcess: string;
  issue: string;
  timeSpent: string;
  tab: number;
  totalTabs: number;
  progressBarValue: number;
  isMobile: boolean;

  constructor(public dialog: MatDialog,
  private dialogRef: MatDialogRef<DemoRequestComponent>,
  private snackBar: MatSnackBar,
  private demoRequestService: services.DemoRequestService) { }

  ngOnInit(): void {
    this.tab = 1;
    this.totalTabs = 6;
    this.progressBarValue = 0;
    if (window.innerWidth < 992) {
      this.isMobile = true;
    };
  }

  @HostListener("window:resize", [])
    onResize() {
      var width = window.innerWidth;
      if (width < 992) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }


  sendEmail() {
    if(this.tab < this.totalTabs){
      this.nextTab()
      return;
    }

    console.log('submitted dialog form')

    let dialogResult: any = {
      size: this.growSize,
      employees: this.employees,
      schedule: this.schedulingProcess,
      issue: this.issue,
      timeSpent: this.timeSpent,
    }
    // await this.demoRequestService.requestDemo("test@test.com");

    this.dialogRef.close(dialogResult);
  }

  nextTab() {
    this.tab ++;
    this.progressBarValue = this.progressBarValue + 20;
  }

  prevTab() {
    this.tab --;
    this.progressBarValue = this.progressBarValue - 20;
  }
}
