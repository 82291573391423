import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import * as models from 'app/models';

@Component({
  selector: 'app-zone-identifiers',
  templateUrl: './zone-identifiers.component.html',
  styleUrls: ['./zone-identifiers.component.scss']
})
export class ZoneIdentifiersComponent implements OnInit, OnChanges {

  @Input() zones: Array<models.Zone> = [];
  @Input() selectedZones: Array<models.HarvestPhaseStrainZone> = [];

  zoneIdentifiers: Array<models.Zone> = [];

  get totalZoneCountForStrain(): number {
    let placements: number = 0;
    if(this.selectedZones == null){
      return placements;
    }
    this.selectedZones.forEach(selectedZone => {
      placements += selectedZone?.count || 0;
    });
    return placements;
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.zones != null || changes.selectedZones != null){
      this.zoneIdentifiers = this.organizeZones(this.zones)
    }
  }

  organizeZones(zones: Array<models.Zone>) {
    let retZones = [];
    if(this.selectedZones == null){
      return;
    }
    zones.forEach(zone => {
      let selectedZone = this.selectedZones.find(i => i.zoneId == zone.id);
      if(selectedZone != null){
        let z: models.Zone = {
          id: zone.id,
          name: zone.name,
          zones: [],
          placements: selectedZone.count
        }
        let subZones = this.organizeZones(zone.zones);
        z.zones = subZones;
        retZones.push(z);
      }
    });
    return retZones;
  }

  // organize(zone: models.Zone): models.Zone {
  //   let z: models.Zone = {
  //     name: zone.name,
  //     zones: []
  //   }
  //   zone.zones.forEach(z => {
  //     if(this.selectedIds.includes(z.id)){
  //       chip.children.push(this.organize(z));
  //     }
  //   });
  //   return chip;
  // }

}