<div class="row">
    <div class="col-12">
        <h3 style="float: left;">Sensors</h3>
        <div style="float: right;">
            <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item id="sensor_add_btn" title="Add Sensor" (click)="addSensor()">
                  <mat-icon aria-hidden="false" aria-label="Add Sensor">add</mat-icon>
                  <span>Add Sensor</span>
              </button>
              <button mat-menu-item id="sensor_export_btn" title="Export Sensor" (click)="export()">
                  <mat-icon aria-hidden="false" aria-label="Export Sensor">file_download</mat-icon>
                  <span>Export Sensors</span>
              </button>
              <button mat-menu-item id="sensor_import_btn" title="Export Sensor" (click)="import()">
                  <mat-icon aria-hidden="false" aria-label="Upload Sensor">file_upload</mat-icon>
                  <span>Upload Sensors</span>
              </button>
            </mat-menu>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <ag-grid-angular 
            style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
            class="ag-theme-balham"
            [rowData]="sensors"
            [gridOptions]="gridOptions">
        </ag-grid-angular>
    </div>
</div>