<div class="loading-container" *ngIf="!retrievedUsers || gettingEvents">
    <div class="content">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</div>

<div class="row">
    <div class="col-md-12 col-sm-12">
        <app-performance-and-laborLoad-analytics [allEvents]="allEvents"></app-performance-and-laborLoad-analytics>
    </div>
</div>

<div class="row mt-n2">
    <div class="col-12">
        <app-filter-section 
            [forAnalytics]="true"
            (onFilter)="onFilter($event)" 
            (initialLoad)="initialLoadFromFilter($event)"
            (resetChartView)="resetChartView($event)"
            [displaySummary]="true"
            [displayHelp]="true"
        ></app-filter-section>
    </div>
</div>

<div class="row" id="chartSection">
    <div [class]="eventsChartClasses">
        <app-events-completed-stacked-chart 
            [filteredEvents]="filteredEvents" 
            (chartViewChange)="onChartViewChange($event)">
        </app-events-completed-stacked-chart>
    </div>
    
    <div [class]="usersAndTeamsChartClasses">
        <app-users-teams-performance-chart
            [removeEmpty]="filterUserIds?.length > 0"
            [filteredEvents]="filteredEvents" 
            (chartViewChange)="onChartViewChange($event)">
        </app-users-teams-performance-chart>
    </div>

    <div [class]="timeTakenChartClasses">
        <app-users-actual-and-estimated-time-chart
            [removeEmpty]="filterUserIds?.length > 0"
            [filteredEvents]="filteredEvents" 
            (chartViewChange)="onChartViewChange($event)">
        </app-users-actual-and-estimated-time-chart>
    </div>

    <div [class]="laborLoadChartClasses">
        <app-event-volume-combo-chart
            [filteredEvents]="filteredEvents"
            (chartViewChange)="onChartViewChange($event)">
        </app-event-volume-combo-chart>
    </div>
</div>