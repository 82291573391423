import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';

import * as models from '../../models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-zone-update',
  templateUrl: './zone-update.component.html',
  styleUrls: ['./zone-update.component.scss']
})
export class ZoneUpdateComponent implements OnInit {

  zone: models.Zone;
  zoneTitles: Array<string> = [];
  allZones: Array<models.Zone> = [];
  sensors: Array<models.Sensor> = [];
  selectedSensors: Array<models.Sensor> = [];

  zoneSelections: Array<viewmodels.ZoneSelection> = [];

  mustAddNewTitle: boolean = false;
  newTitle: string;

  get canEditPlacements() {
    return this.zone.zones == null || this.zone.zones.length == 0;
    //return this.zoneSelections.length == this.zoneTitles.length;
  }

  get nameClass() {
    return this.canEditPlacements ? 'col-8': 'col-12';
  }

  constructor(
    private dialogRef: MatDialogRef<ZoneUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: services.HelperService,
    private zoneService: services.ZoneService,
    private notifierService: services.NotifierService
  ) {
    //dialogRef.disableClose = true;
    this.zone = data.zone;
    if(this.zone.id == null){
      this.zone.id = uuidv4();
    }
    if(this.zone.zones == null){
      this.zone.zones = [];
    }
    if(this.zone.placements == null){
      this.zone.placements = 1;
    }

    this.zoneTitles = this.helperService.deepCopy(data.zoneTitles);
    this.allZones = this.helperService.deepCopy(data.allZones);

    this.sensors = data.sensors;
  }

  ngOnInit(): void {
    this.setZoneSelections();
    this.findSensors();
  }

  getDefaultZoneSelection(zones: Array<models.Zone>) {
    let nullZone = zones.find(i => i.id == null);
    if(nullZone == null){
      nullZone = {
        id: null,
        name: '<At This Level>'
      }
      zones.unshift(nullZone)
    }

    let newZoneSelection: viewmodels.ZoneSelection = {
      zones: zones,
      selectedZone: nullZone
    }

    return newZoneSelection;
  }

  disabled(i: number){
    if(i == this.zoneTitles.length-1){
      return true;
    }
    return false;
  }

  setZoneSelections() {
    let zones = this.allZones;

    let zoneSelection = this.getDefaultZoneSelection(zones);
    this.zoneSelections.push(zoneSelection);

    //compile parent ids
    //let parentIds = this.findInList(this.allZones, this.zone.id);
    let parentIds = this.zoneService.getListOfParents(this.allZones, this.zone.id)
    for (let i = 0; i < parentIds.length; i++) {
      const parentId = parentIds[i];
      
      if(this.zoneSelections[i] != null) {
        let selectedZone = this.zoneSelections[i].zones.find(i => i.id == parentId);
        if(selectedZone != null){
          this.zoneSelections[i].selectedZone = selectedZone;
          let newZoneSelection = this.getDefaultZoneSelection(selectedZone.zones);
          this.zoneSelections.push(newZoneSelection);
        }
      } else {
        let previousZone = this.zoneSelections[i-1].selectedZone;
        let newZoneSelection = this.getDefaultZoneSelection(previousZone.zones);
        this.zoneSelections.push(newZoneSelection);
      }
    }
  }


  // findInList(list: Array<models.Zone>, id: string) : Array<string> {
  //   let retVal = [];
  //   let found = list.find(i => i.id == id);

  //   if(found){
  //     return [];
  //   }

  //   for (let i = 0; i < list.length; i++) {
  //     const element = list[i];
  //     let foundInList = this.findInList(element.zones || [], id);
  //     if(foundInList.length > 0){
  //       retVal.push(...foundInList);
  //       return retVal;
  //     }
  //   }

  //   return [];
  // }

  async change(zoneSelection: viewmodels.ZoneSelection, index: number, event: any) {
    //let zone = zoneSelection.zones.find(i => i.id == event.value);
    let zone = event.value

    //if(zone == null || zone.id == null){
    //  this.zoneSelections.length = index+1;
    //}

    if(index+1 < this.zoneSelections.length){
      this.zoneSelections = this.zoneSelections.splice(0, index+1);
    }

    
    //await this.helperService.wait(1000);
    this.addZoneSelection(zone);
    
    // await this.helperService.wait(10);
    // zoneSelection.selectedZone = null;
    // await this.helperService.wait(10);
    // zoneSelection.selectedZone = zone;

    
    // this.displayToggle = false;
    // await this.helperService.wait(50);
    // this.displayToggle = true;
  }

  addZoneSelection(zone: models.Zone){
    let zones = zone.zones;

    let zoneSelection = this.getDefaultZoneSelection(zones);

    this.zoneSelections.push(zoneSelection);
  }

  saveZone() {
    let parentIds = [];
    let selectedZones = this.zoneSelections.filter(i => i.selectedZone.id != null);

    selectedZones.forEach(selectedZone => {
      if(selectedZone.selectedZone != null && selectedZone.selectedZone.id != null){
        parentIds.push(selectedZone.selectedZone.id)
      }
    });

    let zoneResponse = {
      //level: this.zoneSelections.length - 1,
      zone: this.zone,
      parentIds: parentIds
    }
    this.dialogRef.close(zoneResponse);
  }

  copyClick() {
    let zone: models.Zone = this.helperService.deepCopy(this.zone);
    zone.name += ' Copy';
    this.updateAllIds([zone]);
    this.zone = zone;
  }

  updateAllIds(list: Array<models.Zone>) {
    list.forEach(zone => {
      zone.id = uuidv4();
      this.updateAllIds(zone.zones);
    });
  }

  deleteClick() {
    this.notifierService.confirm(
      'Delete Zone', 
      'Are you sure you want to delete this Zone?  Doing so will delete this zone as well as any subzones, and break the link between any Harvests that are assigned to them.',
      () => {this.delete()},
      () => {}
    )
  }

  delete() {

    let zoneResponse = {
      zone: this.zone,
      delete: true
    }
    this.dialogRef.close(zoneResponse);
  }

  findSensors() {
    this.selectedSensors = this.sensors.filter(i => i.zoneId == this.zone.id);
  }

}
