import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HarvestAddComponent, RestrictionNotificationComponent } from 'app/dialogs';
import { AppStorage } from 'app/services';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-add-menu-btn',
  templateUrl: './add-menu-btn.component.html',
  styleUrls: ['./add-menu-btn.component.scss']
})
export class AddMenuBtnComponent implements OnInit {

  @Output() addedHarvest = new EventEmitter();
  @Output() addedGroup = new EventEmitter();
  @Output() addEventClick = new EventEmitter();

  get canSeeHarvestAdd(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.addHarvest)) {
      return true;
    } else {
      return false;
    }
  }

  get canSeeGroupAdd(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.addGroup)) {
      return true;
    } else {
      return false;
    }
  }

  get canSeeAddEventBtn(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent)) {
      return true;
    }
    return false;
  }

  constructor(
    private claimsService: services.ClaimsService
    , private helperService: services.HelperService
    , private harvestService: services.HarvestService
    , private appStorage: AppStorage
    , private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  async canAdd() {
    let harvestResponse = await this.harvestService.getAll(false).get().toPromise();
    if (this.helperService.company_freeVersion && harvestResponse.size >= this.appStorage.freeHarvests) {
      return false;
    }
  }

  addHarvest() {
    if (!this.canAdd()) {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        data: 'harvestAdd'
      });
      return;
    }

    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if (result.added) {
        this.addedHarvest.emit(result.id);
      }
      console.log(JSON.stringify(result));
    })
  }

  addGroup() {
    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if (result.added) {
        this.addedGroup.emit(result.id);
      }
      console.log(JSON.stringify(result));
    })
  }

  addEvent() {
    this.addEventClick.emit(null);
  }
}
