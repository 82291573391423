import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import * as models from '../../../models';
import * as services from '../../../services';
import * as viewmodels from 'app/viewmodels';
import { MatChipInputEvent } from '@angular/material/chips';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { ExportToCsv, Options } from 'export-to-csv';
import { MatDialog } from '@angular/material/dialog';
import { StrainUploadDialogComponent } from 'app/dialogs';

@Component({
  selector: 'app-strain-setup',
  templateUrl: './strain-setup.component.html',
  styleUrls: ['./strain-setup.component.scss']
})
export class StrainSetupComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  saving: boolean = false;
  
  // strainsValue: Array<models.Strain>;
  // @Input()
  // get strains() {
  //   return this.strainsValue;
  // }
  // @Output() strainsChange = new EventEmitter();
  // set strains(val) {
  //   if(val == null){
  //     val = [];
  //   }
  //   this.strainsValue = val;
  //   this.strainsChange.emit(this.strainsValue);
  // }
  strains: Array<models.Strain>;
  initialStrains: Array<models.Strain>;
  
  csvExporter: ExportToCsv;

  get dirty() {
    let jStrains = JSON.stringify(this.strains);
    let iStrains = JSON.stringify(this.initialStrains);

    return jStrains != iStrains;
  }

  constructor(
    private strainService: services.StrainService
    , private helperService: services.HelperService
    , private notifierService: services.NotifierService
    , private leaflogixService: services.LeaflogixService
    , private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getLeaflogix();
    this.getStrains();
  }

  async getStrains() {
    this.strains = await this.strainService.getAll();
    this.initialStrains = this.helperService.deepCopy(this.strains);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      let name = value.trim();

      if(this.strains.find(i => i.name == name) != null){
        this.notifierService.error('Cannot create a Strain with the same name');
        return;
      }
      let id = uuidv4();
      this.strains.push({id: id, name: name});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: models.Strain): void {
    const index = this.strains.indexOf(fruit);

    if (index >= 0) {
      this.strains.splice(index, 1);
    }
  }

  async save() {
    this.saving = true;
    await this.strainService.update(this.strains);
    this.saving = false;
    this.getStrains();
  }

  canSeeLeaflogix: boolean = false;
  getLeaflogix() {
    let company = this.helperService.currentCompany;
    this.canSeeLeaflogix = company?.leaflogix?.active;
  }

  refreshingStrains: boolean = false;
  async refreshStrainsClick() {
    this.notifierService.confirm('Refresh Strains?', 'Are you sure you want to sync Strains?  This will overwrite any existing information you’ve already saved.',
    () => {this.refreshStrains()}, () => {})
  }
  async refreshStrains() {

    this.refreshingStrains = true;
    let retVal = await this.leaflogixService.integrateStrains();
    let response: models.Result<boolean> = retVal.data;

    if(response.success){
      this.notifierService.success('Successfully integrated Strains')
      this.getStrains();
    } else {
      this.notifierService.error('There was an error syncing with Dutchie.  Please check API key.')
    }
    this.refreshingStrains = false;
  }

  export() {
    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: 'Strains Export',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Strains Export',
      //headers: ['Phase', 'Day in Phase', 'Task Description', 'High Priority', "Anytime", "Start Time", "Cloud Attachments", "Notes"]
    };
  
    this.csvExporter = new ExportToCsv(options);

    let strainsToExport: viewmodels.StrainExport[] = []
    this.strains.forEach(strain => {
      let strainExport: viewmodels.StrainExport = new viewmodels.StrainExport({strains: strain.name})
      strainsToExport.push(strainExport);
    });

    this.csvExporter.generateCsv(strainsToExport);
  }

  import() {
    let dialogRef = this.dialog.open(StrainUploadDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.getStrains();
      }
      console.log(JSON.stringify(result));
    })
  }
}
