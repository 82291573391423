import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';import { NgModel } from '@angular/forms';
import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-sensors-select-multiple',
  templateUrl: './sensors-select-multiple.component.html',
  styleUrls: ['./sensors-select-multiple.component.scss']
})
export class SensorsSelectMultipleComponent implements OnInit {

  @Input() displayList: boolean = false;
  @Input() selectByDefault: string = models.SelectDefault.None;
  //@Input() initialSelectedIds: string[];

  sensors: Array<models.Sensor> = [];
  //selectedSensors: Array<models.Sensor> = [];
  loading: boolean = true;

  // selectedSensorsValue: Array<models.Sensor>;
  // @Input()
  // get selectedSensors() {
  //   return this.selectedSensorsValue;
  // }
  // @Output() selectedSensorsChange = new EventEmitter();
  // set selectedSensors(val) {
  //   if (val == null) {
  //     val = [];
  //   }
  //   this.selectedSensorsValue = val;
  //   //this.setForm();
  //   this.selectedSensorsChange.emit(this.selectedSensorsValue);
  // }

  // //selectedSensorsValue: Array<models.Sensor>;
  // @Input()
  // get selectedSensors() {
  //   return this.sensors.filter(i => this.sensorIds.includes(i.id));
  //   //return this.selectedSensorsValue;
  // }
  // @Output() selectedSensorsChange = new EventEmitter();
  // set selectedSensors(val) {
  //   if(val == null){
  //     val = [];
  //   }
  //   this.sensorIds = val.map(i => i.id);
  //   this.selectedSensorsChange.emit(val);
  // }

  get selectedSensors() {
    return this.sensors.filter(i => this.sensorIds.includes(i.id));
  }

  sensorIDsValue: Array<string>;
  @Input()
  get sensorIds() {
    return this.sensorIDsValue;
  }
  @Output() sensorIdsChange = new EventEmitter();
  set sensorIds(val) {
    if(val == null){
      val = [];
    }
    this.sensorIDsValue = val;
    this.sensorIdsChange.emit(this.sensorIDsValue);
    //this.selectedSensorsChange.emit(this.selectedSensors);
  }

  get title(): string {
    if (this.sensorIds == null || this.sensors == null) {
      return '';
    }
    if (this.sensorIds.length == this.sensors.length) {
      return 'ALL';
    }
    if (this.sensorIds.length >= 1) {
      let title = this.selectedSensors[0].name;
      if (this.selectedSensors.length >= 2) {
        title += ` (+${this.selectedSensors.length - 1} ${this.selectedSensors?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }
  constructor(
    private sensorService: services.SensorService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getSensors();
  }

  // sensorChange(newValue) {
  //   this.selectedSensors = newValue;
  // }

  // selectAllSensors(select: NgModel, values) {
  //   select.update.emit(values);
  //   this.selectAll();
  // }

  // selectAll() {
  //   this.sensorChange(this.sensors);
  // }

  // deselectAllSensors(select: NgModel) {
  //   select.update.emit([]);
  //   this.sensorChange([]);
  // }

  async getSensors() {
    this.loading = true;
    let sensors = await this.sensorService.getAll();

    this.sensors = this.helperService.sortArrayByStringField(sensors, 'name');
    //this.initialSelect();
    this.loading = false;
  }

  // initialSelect() {
  //   if (this.initialSelectedIds != null) {
  //     let selected = this.sensors.filter(i => this.initialSelectedIds.includes(i.id));
  //     this.sensorChange(selected);
  //   } else {
  //     switch (this.selectByDefault) {
  //       case models.SelectDefault.All: {
  //         this.sensorChange(this.sensors);
  //         break;
  //       }
  //       case models.SelectDefault.NotCompleted:
  //       case models.SelectDefault.None:
  //       default: {
  //         break;
  //       }
  //     }
  //   }
  // }

}
