import { Timestamp } from "firebase/firestore";

export interface Group {
    uid?: string;
    name?: string;
    color?: string;

    startDate?: Timestamp;
    endDate?: Timestamp;
    completedTasks?: number;
    totalTasks?: number;
    templateId?: string;
}