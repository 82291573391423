import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import * as equal from 'fast-deep-equal';

import * as models from 'app/models';
import * as services from 'app/services';
import { PostHarvestEditComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-post-harvest-data-points',
  templateUrl: './post-harvest-data-points.component.html',
  styleUrls: ['./post-harvest-data-points.component.scss']
})
export class PostHarvestDataPointsComponent implements OnInit {

  postHarvests: models.PostHarvest[] = [];
  initialPostHarvests: models.PostHarvest[] = [];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.postHarvests, event.previousIndex, event.currentIndex);
  }

  saving: boolean = false;
  get dirty() {
    return !equal(this.postHarvests, this.initialPostHarvests)
  }

  constructor(
    private postHarvestService: services.PostHarvestService
    , private helperService: services.HelperService
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
  ) { }

  ngOnInit(): void {
    this.getPostHarvestDataPoints();
  }

  async getPostHarvestDataPoints() {
    this.postHarvests = await this.postHarvestService.getAll();
    this.initialPostHarvests = this.helperService.deepCopy(this.postHarvests);
  }

  async save() {
    this.saving = true;
    await this.postHarvestService.update(this.postHarvests);
    this.saving = false;
    this.notifierService.success('Successfully save Post Harvest Data Points')
    this.getPostHarvestDataPoints();
  }

  add() {
    let inObj = {
      isAdd: true,
      postHarvest: {}
    }
    let dialogRef = this.dialog.open(PostHarvestEditComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postHarvests.push(result);
      }
    })
  }

  edit(postHarvest: models.PostHarvest) {
    let inObj = {
      isAdd: false,
      postHarvest: postHarvest
    }
    let dialogRef = this.dialog.open(PostHarvestEditComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.getPostHarvestDataPoints();
      }
    })
  }

  delete(index: number) {
    this.postHarvests.splice(index, 1);
  }
}
