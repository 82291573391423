import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent implements OnInit {

  @Input() height: number = 100;
  @Input() show: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
