import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import { HelperService } from './helper.service';

@Injectable()
export class FilterSetService {

    companyRef: AngularFirestoreCollection;

    constructor(
        private firestore: AngularFirestore
        , private helperService: HelperService
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    async getAll(): Promise<models.FilterSet[]> {
        const companyId = this.helperService.currentCompanyId;

        const snap = await this.companyRef.doc(companyId).collection('filter-set').get().toPromise();
        let filterSets: models.FilterSet[] = [];
        snap.docs.forEach(doc => {
            let filterSet: models.FilterSet = doc.data();
            filterSet.id = doc.id;
            filterSets.push(filterSet);
        })
        return this.helperService.sortArrayByStringField(filterSets, 'name');
    }

    async save(filterSet: models.FilterSet): Promise<string> {
        const companyId = this.helperService.currentCompanyId;

        let id: string = filterSet.id;
        delete filterSet.id;

        if(!filterSet.userIds){
            delete filterSet.userIds;
        }
        if(!filterSet.teamIds){
            delete filterSet.teamIds;
        }
        if(!filterSet.completeStatus){
            delete filterSet.completeStatus;
        }
        if(!filterSet.onlyMyTasks){
            delete filterSet.onlyMyTasks;
        }
        if(!filterSet.phases){
            delete filterSet.phases;
        }
        if(!filterSet.tags){
            delete filterSet.tags;
        }
        if(!filterSet.zoneIds){
            delete filterSet.zoneIds;
        }

        if(id){
            await this.companyRef.doc(companyId).collection('filter-set').doc(id).set(filterSet, {merge: true})
            return id;
        } else {
            let addRef = await this.companyRef.doc(companyId).collection('filter-set').add(filterSet);
            return addRef.id;
        }
    }

    async delete(id: string) {
        const companyId = this.helperService.currentCompanyId;
        await this.companyRef.doc(companyId).collection('filter-set').doc(id).delete();
    }
}
