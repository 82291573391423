<h2 style="width: 80%" mat-dialog-title>Assign Teams/Users to Tasks</h2>
    <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)='stepChange($event)'>
        <mat-step [stepControl]="assigningGroup">
            <mat-dialog-content>
                <form class="form" [formGroup]="assigningGroup">
                    <ng-template matStepLabel>Assign/Unassign</ng-template>
                    <mat-button-toggle-group class="center" name="fontStyle" aria-label="Font Style" formControlName="assigning" (change)="assignChange($event.value)">
                        <mat-button-toggle value=true>Assign</mat-button-toggle>
                        <mat-button-toggle value=false>Unassign</mat-button-toggle>
                    </mat-button-toggle-group>
                </form>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <button *ngIf="assigningGroup.get('assigning').value != null" mat-button matStepperNext>Next</button>
            </mat-dialog-actions>
        </mat-step>
        <mat-step [stepControl]="selectUsersFormGroup" label="Select Users/Teams">
            <mat-dialog-content>
                <form [formGroup]="selectUsersFormGroup">
                    <div class="row">
                        <div class="col-sm-6">
                            <app-user-select-multiple [displayUserList]="true" [(selectedUsers)]="users"></app-user-select-multiple>
                        </div>
                        <div class="col-sm-6">
                            <app-team-select-multiple [displayTeamList]="true" [(selectedTeams)]="teams"></app-team-select-multiple>
                        </div>
                    </div>
                </form>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
              <button mat-button mat-dialog-close>Cancel</button>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </mat-dialog-actions>
        </mat-step>
        <mat-step [stepControl]="selectHarvestsFormGroup">
          <ng-template matStepLabel>Select Harvests/Groups</ng-template>
          <mat-dialog-content>
              <form [formGroup]="selectHarvestsFormGroup">
                  <div class="row">
                    <div class="col-sm-6">
                        <app-harvest-select-multiple [(selectedHarvests)]="harvests"></app-harvest-select-multiple>
                    </div>
                    <div class="col-sm-6">
                        <app-group-select-multiple [(selectedGroups)]="groups" [selectByDefault]="'none'"></app-group-select-multiple>
                    </div>
                  </div>
              </form>
          </mat-dialog-content>
          <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </mat-dialog-actions>
        </mat-step>
        <mat-step [stepControl]="selectTasksFormGroup">
          <ng-template matStepLabel>Select Phases/Date Range</ng-template>
          <mat-dialog-content>
              <form class="form" [formGroup]="selectTasksFormGroup">
                <h3 style="text-align: center;">Select One:</h3>
                <div class="row">
                    <div class="col-sm-12" style="min-height: 75px;">
                        <mat-button-toggle-group class="center" name="fontStyle" aria-label="Font Style" formControlName="taskType" (change)="taskTypeChange($event.value)">
                            <mat-button-toggle value="phase">Phase</mat-button-toggle>
                            <mat-button-toggle value="dateRange">Date Range</mat-button-toggle>
                            <mat-button-toggle value="tags">Tags</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row" *ngIf="selectTasksFormGroup.get('taskType').value == 'phase'">
                    <div class="col-sm-12">
                        <app-phase-select-multiple [harvests]="harvests" [(phaseIds)]="phases"></app-phase-select-multiple>
                    </div>
                </div>
                <div class="row" *ngIf="selectTasksFormGroup.get('taskType').value == 'dateRange'">
                    <div class="col-sm-6">
                        <mat-form-field>
                          <mat-icon style="cursor: pointer;" [inline]="true" matPrefix matTooltip="Leave blank to assign all tasks from the beginning of the Harvest/Group">info</mat-icon>
                          <input matInput [matDatepicker]="startDatePicker" placeholder="Choose a start date" name="startDate" [formControl]="startDate">
                          <mat-datepicker-toggle matSuffix [for]="startDatePicker" name='startDatePicker'></mat-datepicker-toggle>
                          <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field>
                          <mat-icon style="cursor: pointer;" [inline]="true" matPrefix matTooltip="Leave blank to assign all tasks through the end of the Harvest/Group">info</mat-icon>
                          <input matInput [matDatepicker]="endDatePicker" placeholder="Choose an end date" name="endDate" [formControl]="endDate">
                          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                          <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="selectTasksFormGroup.get('taskType').value == 'tags'">
                    <div class="col-sm-12">
                        <app-multiple-select [displayList]="true" [list]="allTags" [(ids)]="tags"></app-multiple-select>
                    </div>
                </div>
              </form>
          </mat-dialog-content>
          <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </mat-dialog-actions>
        </mat-step>
        <mat-step [stepControl]="confirmFormGroup">
          <ng-template matStepLabel>Confirm</ng-template>
          <mat-dialog-content>
              <form class="form" [formGroup]="confirmFormGroup">
                <div class="row">
                    <div class="col-12">
                        <p style="text-align: center;">
                            <span *ngIf="assigningGroup.get('assigning').value == 'true'">Assigning</span>
                            <span *ngIf="assigningGroup.get('assigning').value == 'false'">Unassigning</span>
                            <b> {{userNames}} </b> 
                            <span *ngIf="users.length > 0 && teams.length > 0">and</span>
                            <b> {{teamNames}} </b> 
                            <span *ngIf="assigningGroup.get('assigning').value == 'true'">to</span>
                            <span *ngIf="assigningGroup.get('assigning').value == 'false'"> from</span> the 
                            <span *ngIf="harvests.length > 0"><b>{{harvestNames}}</b> Harvest<span *ngIf="harvests.length > 1">s</span> </span>
                            <span *ngIf="harvests.length > 0 && groups.length > 0"> and </span>
                            <span *ngIf="groups.length > 0">{{groupNames}} Group<span *ngIf="groups.length > 1">s</span> </span>
                            <span *ngIf="selectTasksFormGroup.get('taskType').value == 'phase'"> for Phases <b>{{phaseNames}}</b></span>
                            <span *ngIf="selectTasksFormGroup.get('taskType').value == 'dateRange'"> between <b>{{startDate.value | date: 'MM/dd/yyyy'}}</b> and <b>{{endDate.value | date: 'MM/dd/yyyy'}}</b></span>
                            <span *ngIf="selectTasksFormGroup.get('taskType').value == 'tags'"> for Tags <b>{{tagSentence}}</b></span>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <table>
                          <tr>
                            <th>Assign/Unassign</th>
                            <th *ngIf="displayUsers">Users</th>
                            <th *ngIf="displayTeams">Teams</th>
                            <th *ngIf="displayHarvests">Harvests</th>
                            <th *ngIf="displayGroups">Groups</th>
                            <th *ngIf="displayPhases">Phases</th>
                            <th *ngIf="displayDates">Date Range</th>
                            <th *ngIf="displayTags">Tags</th>
                          </tr>
                          <tr *ngFor="let i of descriptionItems">
                            <td>{{i.assign}}</td>
                            <td *ngIf="displayUsers">{{i.user}}</td>
                            <td *ngIf="displayTeams">{{i.team}}</td>
                            <td *ngIf="displayHarvests">{{i.harvest}}</td>
                            <td *ngIf="displayGroups">{{i.group}}</td>
                            <td *ngIf="displayPhases">{{i.phase}}</td>
                            <td *ngIf="displayDates">{{i.date}}</td>
                            <td *ngIf="displayTags">{{i.tag}}</td>
                          </tr>
                        </table>
                    </div>
                </div>

                <!-- <p class="center">{{description}}</p> -->
              </form>
          </mat-dialog-content>
          <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close [disabled]="assigning">Cancel</button>
            <button mat-button matStepperPrevious>Back</button>
            <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
            <button mat-button type="submit" [disabled]="assigning" (click)="process()">
              <mat-spinner *ngIf="assigning" diameter=25 strokeWidth=4></mat-spinner>
              <span *ngIf="!assigning">Assign</span>
            </button>
          </mat-dialog-actions>
        </mat-step>
    </mat-horizontal-stepper>

  <!-- <form id="massAssignUsersForm" class="mass-assign-form" (ngSubmit)="process()"> -->

    <!-- <div class="row">
        <mat-slide-toggle [(ngModel)]="assign" class="gridbtn" (change)="toggleChange($event)">
            <span *ngIf="assign">Assign Users</span>
            <span *ngIf="!assign">Remove Users</span>
        </mat-slide-toggle>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <mat-form-field>
                <mat-label>Select User(s)</mat-label>
                <mat-select [(ngModel)]="selectedUsers" (selectionChange)="userChange($event.value)" multiple #userSelect="ngModel">
                    <mat-select-trigger>
                      {{selectedUsers.length == users.length ? 'ALL' : ''}}
                      {{selectedUsers.length != users.length && selectedUsers.length > 0 ? selectedUsers[0].displayName : ''}}
                      <span *ngIf="selectedUsers.length != users.length && selectedUsers?.length > 1" class="example-additional-selection">
                        (+{{selectedUsers.length - 1}} {{selectedUsers?.length === 2 ? 'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option disabled="disabled" class="filter-option">
                        <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllUsers(userSelect, users)">
                            Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAllUsers(userSelect)">
                            Deselect All
                        </button>
                    </mat-option>
                    <mat-option [value]="h" *ngFor="let h of users">{{h.displayName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
    
    <div class="row">
        <div class="col-sm-6">
            <ul>
                <li *ngFor="let h of selectedUsers">{{h.displayName}}</li>
            </ul>
        </div>
        <div class="col-sm-6">
            <ul>
                <li *ngFor="let h of selectedHarvests">{{h.name}}</li>
                <li *ngFor="let g of selectedGroups">{{g.name}}</li>
            </ul>
        </div>
    </div>
 -->

  <!-- </form> -->
