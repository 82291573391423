import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentData, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import * as models from '../models';
import * as viewmodels from '../viewmodels';
import { HelperService } from './helper.service';

import { EventService } from './events.service';
import { Timestamp, getDocs } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class HarvestService {

    companyRef: AngularFirestoreCollection;

    slug: string = 'harvests';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
        private storage: SessionStorageService,
        private eventService: EventService,
        private functions: Functions
    ) {
        console.log('Hello HarvestService Provider');

        this.companyRef = this.firestore.collection('companies');
    }

    /**
     * 
     * @param onlyIncomplete only get harvests where complete flag is false
     * @param onlyActive only get harvests where the endDate is in the future
     * @returns 
     */
    getAll(onlyIncomplete: boolean = false, onlyActive: boolean = false): AngularFirestoreCollection {
        let companyId: string = this.helperService.currentCompanyId;

        let harvestResponse = this.companyRef.doc(companyId).collection(this.slug, ref => {
            let retRef: Query<DocumentData> = ref;
            if (onlyIncomplete) {
                retRef = ref.where('completed', '==', false)
            } else {
                retRef = ref;
            }

            if (onlyActive) {
                retRef = ref.where('endDate', '>=', Timestamp.now())
            } else {
                retRef = ref;
            }

            return retRef;
        });

        return harvestResponse;
    }

    async getAllModels(onlyIncomplete: boolean = false, showUpcomingHarvests: boolean = true): Promise<models.Harvest[]> {
        let companyId: string = this.helperService.currentCompanyId;
        
        let harvestResponse = await this.companyRef.doc(companyId).collection(this.slug, ref => {
            if (onlyIncomplete) {
                return ref.where('completed', '==', false)
            } else if (!showUpcomingHarvests) {
                return ref.where('startDate', '<=' , new Date())
            } else {
                return ref;
            }
        }).get().toPromise();

        let harvests: models.Harvest[] = [];
        harvestResponse.forEach(harvestData => {
            let harvest: models.Harvest = harvestData.data();
            harvest.uid = harvestData.id;
            harvests.push(harvest);
          })

        return harvests;
    }

    async getAllForCompany(companyId: string): Promise<AngularFirestoreCollection> {
        let harvestResponse = this.companyRef.doc(companyId).collection(this.slug);
        return harvestResponse;
    }

    getAllForTemplate(templateId: string): AngularFirestoreCollection {
        let companyId: string = this.helperService.currentCompanyId;

        let harvestResponse = this.companyRef.doc(companyId).collection(this.slug, ref => {
            return ref.where('completed', '==', false)
                      .where('templateId', '==', templateId)
                      .where('endDate', '>=', Timestamp.now())
        });

        return harvestResponse;
    }

    /**
     * 
     * @param onlyIncomplete only get harvests where complete flag is false
     * @param onlyActive only get harvests where the endDate is in the future
     * @returns 
     */
     getAllForTemplates(templateIds: Array<string> = null, onlyIncomplete: boolean = false, onlyActive: boolean = false): AngularFirestoreCollection {
        let companyId: string = this.helperService.currentCompanyId;

        let harvestResponse = this.companyRef.doc(companyId).collection(this.slug, ref => {
            let retRef: Query<DocumentData> = ref;

            if(templateIds != null) {
                retRef = retRef.where('templateId', 'in', templateIds)
            }

            if (onlyIncomplete) {
                retRef = retRef.where('completed', '==', false)
            }

            if (onlyActive) {
                retRef = retRef.where('endDate', '>=', Timestamp.now())
            }
            
            return retRef;
        });

        return harvestResponse;
    }


    get(harvestId: string): AngularFirestoreDocument {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = this.companyRef.doc(companyId).collection(this.slug)
            .doc(harvestId);

        return harvestResponse;
    }

    async delete(harvestId: string) {
        let companyId = this.helperService.currentCompanyId;

        let deletHarvestMethod = httpsCallable<any, models.Result<any>>(this.functions, 'deleteHarvest');
        var deletHarvestResponse = await deletHarvestMethod({ companyId: companyId, harvestId: harvestId });

        return deletHarvestResponse;
    }

    async deleteList(harvestIds: Array<string>) {
        let companyId = this.helperService.currentCompanyId;

        let deletHarvestListMethod = httpsCallable<any, models.Result<any>>(this.functions, 'deleteHarvestList');
        var deletHarvestListResponse = await deletHarvestListMethod({ companyId: companyId, harvestIds: harvestIds });

        return deletHarvestListResponse;
    }

    async add(templateId: string, name: string, strainIds: Array<string>, phases: Array<models.HarvestPhase>, color: string, startDate: Date, leaflogixId: string, leaflogixName: string) {

        let companyId = this.helperService.currentCompanyId;
        let sdTimeStamp: Timestamp = Timestamp.fromDate(startDate)
        let inObj = {
            companyId: companyId,
            templateId: templateId,
            name: name,
            phases: phases,
            strainIds: strainIds,
            color: color,
            startDate: sdTimeStamp,
            leaflogixId: leaflogixId,
            leaflogixName: leaflogixName
        }

        let addHarvestMethod = httpsCallable<any, models.Result<any>>(this.functions, 'addHarvest');
        let addHarvestResponse = await addHarvestMethod(inObj);
        return addHarvestResponse
    }

    async update(harvest: models.Harvest): Promise<void> {
        let uid = harvest.uid;
        delete harvest.uid;

        let companyId = this.helperService.currentCompanyId;

        await this.companyRef.doc(companyId).collection(this.slug).doc(uid).update(harvest);

        let eventDataList = await getDocs(this.eventService.get(uid));
        for (let i = 0; i < eventDataList.docs.length; i++) {
            const eventData = eventDataList.docs[i];
            let dbEvent: models.DbEvent = eventData.data();
            dbEvent.uid = eventData.id;

            dbEvent.list.forEach(event => {
                event.harvestName = harvest.name;
            });

            await this.eventService.saveToDb(this.slug, uid, dbEvent);
        };
    }

    async partialUpdate(obj: any, id: string) {
        let companyId = this.helperService.currentCompanyId;

        await this.companyRef.doc(companyId).collection(this.slug).doc(id).update(obj);
    }

    async shift(harvestId: string, numberOfDays: number, startDate: Date, partialShift: boolean) {

        let companyId = this.helperService.currentCompanyId;

        let sdTimeStamp: Timestamp = Timestamp.fromDate(startDate)
        let inObj = {
            companyId: companyId,
            harvestId: harvestId,
            numberOfDays: numberOfDays,
            startDate: sdTimeStamp,
            partialShift: partialShift
        }

        let shiftHarvestMethod = httpsCallable(this.functions, 'shiftHarvest');
        let shiftHarvestResponse = shiftHarvestMethod(inObj);

        return shiftHarvestResponse;
    }

    async createDemoHarvest(companyId: string) {

        let generateDemoHarvestMethod = httpsCallable(this.functions, 'generateDemoHarvest')
        let harvestResponse = await generateDemoHarvestMethod(companyId);

        return harvestResponse;
    }

    async updateHarvestsFromTemplate(templateId: string, harvestIds: Array<string>) {

        const inObj = {
            companyId: this.helperService.currentCompanyId,
            templateId: templateId,
            harvestIds: harvestIds
        }

        let method = httpsCallable(this.functions, 'applyTemplateChangesToHarvestList')
        let response = await method(inObj);

        return response;
    }

    async getActiveAndFutureHarvestsForTemplate(templateId: string) {
        let companyId: string = this.helperService.currentCompanyId

        let harvestsSnap = this.companyRef.doc(companyId).collection(this.slug, ref => {
            return ref.where('templateId', '==', templateId)
        })

        console.log(harvestsSnap);
    }

    async getHarvestsAndPhasesByZoneIds(zoneIds: string[]): Promise<viewmodels.HarvestPhases[]>{
        let companyId: string = this.helperService.currentCompanyId

        let harvestsResponse = this.companyRef.doc(companyId).collection(this.slug, ref => {
            return ref.where('completed', '==', false)
        })
        let harvestsSS = await harvestsResponse.get().toPromise();
        let harvests: models.Harvest[] = []
        harvestsSS.docs.forEach(data => {
            let harvest: models.Harvest = data.data() || {};
            harvest.uid = data.id;
            harvests.push(harvest);
        })

        let retVal: viewmodels.HarvestPhases[] = []
        harvests.forEach(harvest => {
            let harvestPhase: viewmodels.HarvestPhases = {
                harvestId: harvest.uid,
                harvestName: harvest.name,
                phases: [],// phases.map(i => i.phaseName)
            }

            harvest.phases?.forEach(phase => {
                phase.strains?.forEach(strain => {
                    let z = strain.zones?.filter(i => zoneIds.includes(i.zoneId)) || [];
                    if(z.length > 0 && !harvestPhase.phases.includes(phase.phaseName)){
                        harvestPhase.phases.push(phase.phaseName);
                    }
                });
            });
            if(harvestPhase.phases.length > 0){
                retVal.push(harvestPhase);
            }
        });

        return retVal;
    }

    async updateColor(harvestId: string, groupId: string, color: string, name: string) {

        const inObj = {
            companyId: this.helperService.currentCompanyId,
            harvestId: harvestId,
            groupId: groupId,
            name: name,
            color: color
        }

        let method = httpsCallable(this.functions, 'updateColor')
        let response = await method(inObj);

        return response;
    }

    async unlinkDutchieBatch(harvestId: string) {
        let harvestPartial: models.Harvest = {
            leaflogixName: null,
            leaflogixId: null
        }
        await this.partialUpdate(harvestPartial, harvestId);
    }
}
