import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import * as models from 'app/models';

@Component({
    selector: "button-cell",
    template: `
    <button mat-icon-button [matBadge]="badge" [matBadgeHidden]="badge===0" (click)="btnClick()" class="badge-btn" style="width: 25px; height: 25px; line-height: 15px; margin-top: 8px;">
        <mat-icon aria-hidden="false" style="vertical-align: middle">{{icon}}</mat-icon>
    </button>
    `,
    styles: [
        `
        ::ng-deep .mat-menu-item.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: 0px;
            right: 0px;
        }
        ::ng-deep .badge-btn.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: -7px;
        }
        `
    ]
})
export class ButtonCellComponent implements ICellRendererAngularComp {
    private params: any;

    badge: number = 0;
    icon: string = '';
    clickFunc: any;

    agInit(params: any): void {
        this.setData(params);
    }

    refresh(params: any): boolean {
        this.setData(params);
        return true;
    }

    setData(params: any){
        this.params = params;

        this.badge = this.params.badge;
        this.icon = this.params.icon;
        this.clickFunc = this.params.clickFunc;
    }

    btnClick() {
        this.clickFunc();
    }
}
