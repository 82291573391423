import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppStorage } from 'app/services';

@Component({
  selector: 'app-restriction-notification',
  templateUrl: './restriction-notification.component.html',
  styleUrls: ['./restriction-notification.component.scss']
})
export class RestrictionNotificationComponent implements OnInit {

  attemptedAction: string;

  freeHarvests: number;
  freeTemplates: number;
  freeUsers: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    , public appStorage: AppStorage
    , private dialogRef: MatDialogRef<RestrictionNotificationComponent>
    , private router: Router
  ) { }

  ngOnInit(): void {
    this.attemptedAction = this.data
    console.log(this.attemptedAction);
  }

  successfulUpgrade() {
    //let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigateByUrl('/console/company-settings');
    });
    this.dialogRef.close();
  }
}
