import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportToCsv, Options } from 'export-to-csv';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';

import * as services from '../../services';
import * as viewModels from '../../viewmodels';
import * as models from 'app/models'

@Component({
  selector: 'app-sensor-upload',
  templateUrl: './sensor-upload.component.html',
  styleUrls: ['./sensor-upload.component.scss']
})
export class SensorUploadComponent implements OnInit {

  saveClicked: boolean = false;
  fileToUpload: File | null = null;
  //sensorName: string;

  constructor(
    private dialogRef: MatDialogRef<SensorUploadComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private ngxCsvParser: NgxCsvParser
    , private notifierService: services.NotifierService
    , private sensorService: services.SensorService
  ) { }

  ngOnInit(): void {
  }

  downloadExample() {
    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false, 
      showTitle: false,
      title: 'Example Sensor Import',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: 'Example Sensor Import',
      //headers: ['Phase', 'Day in Phase', 'Task Description', 'High Priority', "Anytime", "Start Time", "Cloud Attachments", "Notes"]
    };
  
    let csvExporter = new ExportToCsv(options);

    let sensorSteps: Array<models.Sensor> = [
      {
        name: 'name',
        sensorNumber: 'sensorNumber',
        type: 'type',
      }
    ];

    csvExporter.generateCsv(sensorSteps);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  } 

  upload() {
    // if(this.sensorName == null || this.sensorName == ''){
    //   this.notifierService.error('Must specify a name for new Sensor');
    //   return;
    // }

    if(this.fileToUpload == null){
      this.notifierService.error('Must select a file to upload');
      return;
    }

    this.saveClicked = true;

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(this.fileToUpload, { header: true, delimiter: ',' })
      .pipe().subscribe((result: Array<viewModels.SensorExport>) => {

        this.validate(result);
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
        this.notifierService.error('There was an error uploading file');
        this.saveClicked = false;
      });
  }

  async validate(results: Array<viewModels.SensorExport>) {
    let errors: Array<boolean> = [];
    let validatedRows: Array<viewModels.SensorExport> = [];

    for (let i = 0; i < results.length; i++) {
      const result = new viewModels.SensorExport(results[i]);

      if(!result.validate()){
        errors.push(true);
      }

      validatedRows.push(result);
    }

    if(errors.length > 0){
      this.notifierService.error(`There were ${errors.length} row${errors.length > 1 ? 's' : ''} with errors in the uploaded file`);
      this.saveClicked = false;
      return;
    }

    this.import(validatedRows);
  }

  async import(result: Array<viewModels.SensorExport>) {
    await this.sensorService.import(result);
    this.dialogRef.close(true)
    this.saveClicked = false;
  }

}
