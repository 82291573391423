import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-teams-alert-dialog',
  templateUrl: './create-teams-alert-dialog.component.html',
  styleUrls: ['./create-teams-alert-dialog.component.scss']
})
export class CreateTeamsAlertDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CreateTeamsAlertDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public dialog: MatDialog
    , private router: Router
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.router.navigate(['/console', 'user', 'teams'])
    this.dialogRef.close();
  }

}
