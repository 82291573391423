import { Component, OnInit, Input } from '@angular/core';
import * as services from 'app/services';
import * as models from 'app/models';
import { MatDialog } from '@angular/material/dialog';
import { ApplyWorkflowComponent } from 'app/dialogs';
@Component({
  selector: 'app-apply-workflow-btn',
  templateUrl: './apply-workflow-btn.component.html',
  styleUrls: ['./apply-workflow-btn.component.scss']
})
export class ApplyWorkflowBtnComponent implements OnInit {
  
  @Input() showApplyToGroupButton: boolean;
  @Input() harvestId: string;

  constructor(
    private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private claimsService: services.ClaimsService
  ) { }

  ngOnInit(): void {
  }

  get canSeeApplyWorkflowBtn(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.applyWorkflow)) {
      return true;
    } else {
      return false;
    }
  }

  applyWorkflow() {
    let dialogRef = this.dialog.open(ApplyWorkflowComponent, {
      data: { showGroupTab: this.showApplyToGroupButton, harvestId: this.harvestId },
      panelClass: 'med-width-dialog',
      autoFocus: true,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.added) {
        this.notifierService.success(result.message);
      } else {
        this.notifierService.error(result.message);
      }
    })
  }

}
