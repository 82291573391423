<!-- <div class="speech-wrapper">
    <div class="bubble" [class.alt]="d.currentUser" *ngFor="let d of discussionViewModels">
        <div class="txt">
            <p class="name">{{d.userName}}</p>
            <p class="message">{{d.description}}</p>
            <span class="timestamp" *ngIf="d.timestamp != null">{{d.timestamp?.seconds * 1000 | timeAgo}}</span>
        </div>
        <div class="bubble-arrow" [class.alt]="d.currentUser"></div>
    </div>
    <div class="bubble alt">
        <div class="txt">
            <p class="name alt">+353 87 1234 567<span> ~ John</span></p>
            <p class="message">Nice... this will work great for my new project.</p>
            <span class="timestamp">10:22 pm</span>
        </div>
        <div class="bubble-arrow alt"></div>
    </div>

    <div class="message-input">
        <mat-form-field appearance="outline" style="margin-bottom: -1.25em">
            <input matInput name=company [(ngModel)]="newMessage" placeholder="Leave a message...">
            <button type="button" mat-icon-button matSuffix (click)="submit">
                <mat-icon>send</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div> -->


<div class="chat-area">
    <!-- <div class="title"><b>Messages</b></div> -->
    <div class="chat-list">
        <ul class="chat-list-item" *ngFor="let d of discussionViewModels; let i = index">
            <li ><!-- [class.me]="!(i % 2)" -->
                <div class="name">
                    <span class="">{{d.userName}}</span><br/>
                    <span class="msg-time" *ngIf="d.timestamp != null" [matTooltip]="d.formattedDate">{{d.timestamp?.seconds * 1000 | timeAgo}}</span>
                </div>
                <div class="message">
                    <span [innerHtml]="d.description | safeHtml"></span>
                    <button class="msg-delete" type="button" mat-icon-button [disabled]="disabled" *ngIf="d.currentUser" (click)="deleteMessage.emit(d)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </li>
            <!-- <li class="">
                <div class="name">
                    <span class="">Christian Smith</span>
                </div>
                <div class="message">
                    <p><span class="blue-label">Cucu Ionel</span> I see what you did there.</p>
                    <span class="msg-time">5:01 pm</span>
                </div>
            </li> -->
        </ul>
    </div>
    <div class="message-input" *ngIf="!disabled">
        <form (ngSubmit)="submit()">
            <div class="message-container">
                <div style="flex: 1;">
                    <app-mention-textbox [(content)]="newMessage" [placeholder]="'Leave a note...'"></app-mention-textbox>
                </div>
                <div class="send_button">
                    <button mat-icon-button [disabled]="sending" (click)="send()" style="width: 25px; height: 25px; line-height: 15px; margin-bottom: 2px; margin-left: 5px;">
                        <mat-spinner *ngIf="sending" diameter=25 strokeWidth=4></mat-spinner>
                        <mat-icon *ngIf="!sending">send</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>