

<div mat-dialog-title>
    <div class="flex-grid-thirds">
        <div class="col"></div>
        <div class="col">
            <h3>Sensor/Zone History</h3>
        </div>
        <div class="col"></div>
    </div>
</div>

<mat-dialog-content>
    <ul class="sessions">
        <li *ngFor="let h of listDisplay; let i = index">
            <div class="flex">
                <div>
                    <div class="time">{{h.dateMovedToZone.seconds * 1000 | date:'M/d/yyyy h:mm a'}}</div>
                    <p>Moved to {{h.zoneHierarchy}}</p>
                </div>
                <div>
                    <button mat-icon-button color="warn">
                        <mat-icon style="margin-right: 8px" (click)="deleteClick(h)">delete</mat-icon>
                    </button>
                </div>
            </div>
        </li>
    </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button type="submit" (click)="submit()">
        OK
    </button>
</mat-dialog-actions>