<h2 mat-dialog-title>Update User Role</h2>
<mat-dialog-content>
  <form id="updateUserRole" class="user-form" (ngSubmit)="save()">
    <mat-form-field>
      <mat-label>Role</mat-label>
      <mat-select [(value)]="selectedRole">
        <mat-option *ngFor="let role of roles" [value]="role.id">
          {{role.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClick">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="updateUserRole" [disabled]="saveClick">
    <i class="fa fa-spinner fa-spin" *ngIf="saveClick"></i>
    <span *ngIf="!saveClick">Save</span>
  </button>
</mat-dialog-actions>
