import {Step} from './step';

export interface Phase {
    uid?: string;
    daysSinceLastPhase?: number;
    harvestAtEnd?: boolean;
    name?: string;
    sortOrder?: number;
    compliancePhase?: string;

    steps?: Array<Step>;
}