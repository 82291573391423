import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ColDef, GridOptions } from 'ag-grid-community';
import { ExportToCsv } from 'export-to-csv';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as services from 'app/services';
import * as models from 'app/models';
import * as cells from 'app/components/cell-components';
import * as leaflogix from 'app/models/leaflogix/index';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-leaflogix-harvest-match-dialog',
  templateUrl: './leaflogix-harvest-match-dialog.component.html',
  styleUrls: ['./leaflogix-harvest-match-dialog.component.scss']
})
export class LeaflogixHarvestMatchDialogComponent implements OnInit {
  //grid with LeafLogix Harvest data
  //option to connect it to current Harvest or to create a harvest from scratch
  public gridOptions: GridOptions;
  
  csvExporter: ExportToCsv;

  columnDefs: Array<ColDef> = [
    {
      headerName: "Planted On",
      field: "plantedOn",
      cellRendererFramework: cells.DateCellComponent
    },
    {
      headerName: "Batch Name",
      field: "batchName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Strain",
      field: "strain",
    },
    {
      headerName: "Plant Count",
      field: "plantCount",
    },
    {
      headerName: "Room",
      field: "room",
    },
    {
      headerName: "Compliance Phase",
      field: "phase",
    },
    {
      headerName: "Link",
      field: "batchId",
      cellRendererFramework: cells.BatchLinkCellComponent,
      cellRendererParams: (params) => {
        params.navigateToTemplateFunc = this.navigateToTemplate.bind(this);
        params.harvestAddedFunc = this.harvestAdded.bind(this);
        return params;
      },
    },
  ];

  batches: leaflogix.Batch[] = [];
  addedHarvestIds: string[] = [];
  templates: models.Template[] = [];

  constructor(
    private dialogRef: MatDialogRef<LeaflogixHarvestMatchDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private deviceService: DeviceDetectorService
    , private helperService: services.HelperService
    , private templateService: services.TemplateService
    , private router: Router
  ) { 
    if(data){
      this.batches = this.helperService.sortArrayByStringFieldDesc(data, 'plantedOn');
    }

    this.gridOptions = <GridOptions>{
      context: {
          componentParent: this
      },
      //rowData: this.rowData,
      columnDefs: this.columnDefs,
      rowHeight: 35,
      onGridReady: () => {
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      // frameworkComponents: {
      // },
      // rowSelection: 'none',
      // enableCellTextSelection: true,
      defaultColDef: {
        sortable: true,
        resizable: true
      }
    };
  }

  ngOnInit(): void {
  }
  
  async navigateToTemplate(templateId: string) {
    if(templateId == null){
      let template = await this.templateService.getFirst();
      templateId = template.uid;
    }

    this.dialogRef.close(
      {
        harvestIds: [],
        linked: false
      }
    );

    let extras: NavigationExtras = {
      queryParams: {
        'tourstep': 'stages'
      }
    }
    this.router.navigate(['console', 'template', templateId], extras)
  }

  harvestAdded(harvestId: string) {
    this.addedHarvestIds.push(harvestId);
  }

  close() {
    this.dialogRef.close(
      {
        harvestIds: this.addedHarvestIds,
        linked: this.batches.filter(i => i.linkedExisting).length + this.batches.filter(i => i.addedNew).length > 0
      }
    );
    // if(this.addedHarvestIds.length > 0){
    //   this.dialogRef.close(
    //     {
    //       harvestIds: this.addedHarvestIds,
    //       linked: this.batches.filter(i => i.matched).length > 0
    //     }
    //   );
    // } else {
    //   this.dialogRef.close(
    //     {
    //       harvestIds: [],
    //       linked: this.batches.filter(i => i.matched).length > 0
    //     }
    //   );
    // }
  }
}
