
<button mat-raised-button class="addCardBtn" (click)="addCard()" [disabled]="disabled">
    Add another card
</button>

<ngx-aside #NgxAsidePanelRight
           
    (cancel)="onCancel()"
    (submit)="onGetCardNonce($event)"

    [title]="'Add Card'"
    [cancelButtonTitle]="'Cancel'"
    [submitButtonTitle]="'Submit Payment'"

    [closeOnEscape]="false"
    [showOverlay]="true"

    [showDefaultFooter]="false"
    [showDefaultHeader]="true">

    <h3>Add a different card</h3>

    <div id="form-container">
        <div id="sq-card"></div>
    </div>

    <footer class="footer" style="display: block;">
        <button mat-raised-button class="cancelBtn" (click)="NgxAsidePanelRight.hide()">
            Close
        </button>
        <button id="sq-creditcard" mat-raised-button color="accent" align="end" class="submitBtn" (click)="onGetCardNonce($event)" [disabled]="upgrading">
            <mat-spinner *ngIf="upgrading" diameter=25 strokeWidth=4></mat-spinner>
            <span *ngIf="!upgrading">Add Card</span>
        </button>
    </footer>
</ngx-aside>