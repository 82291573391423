import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as services from './services';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  constructor(
    private gaService: services.GoogleAnalyticsService
    , private matIconReg: MatIconRegistry
  ) {}

  async ngOnInit() {
    this.gaService.setupGoogleAnalytics();
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }

}
