<!-- <div class="bottom_drawer">
    <div class="header_bar">
        <h2>4 videos selected</h2>
        <button class="create">Create Task</button>
    </div>
    <div class="body_area">
        <p>
            This is where the form goes
        </p>
    </div>
</div> -->

<div class="footerSlideContainer" *ngIf="stopwatchHelperService.display">
    <button class="footerSlideButton" (click)="click()">Stopwatch</button>
	<div class="footerSlideContent">
		<div class="footerSlideText">
			<h3 class="timer-counter-name">{{stopwatchHelperService.eventName}}</h3>
            <div class="timer-counter-label" *ngIf="counter"> {{timerDisplay.hours.digit1}}{{timerDisplay.hours.digit2}}:{{timerDisplay.minutes.digit1}}{{timerDisplay.minutes.digit2}}:{{timerDisplay.seconds.digit1}}{{timerDisplay.seconds.digit2}} </div>
            <div class="timer-counter-label" *ngIf="!counter"> Press Start </div>
            <section class="timer-button-container">
                <button class="timer-button" (click)="startTimer()">
                    {{ startText }}
                </button>
                <button class="timer-button" (click)="clearTimer()">Clear</button>
                <button class="timer-button" style="background: #43a047; color: white" (click)="submit()">Submit</button>
            </section>
		</div>
	</div>
</div>