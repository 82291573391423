<div class="row">
    <div class="col-12">
        <h3 style="float: left;">Strains</h3>
        <div style="float: right;">
            <button *ngIf="canSeeLeaflogix" mat-raised-button class="pad-btn" [disabled]="refreshingStrains" title="Refresh Strains" (click)="refreshStrainsClick()">
                <i class="fa fa-spinner fa-spin" *ngIf="refreshingStrains"></i>
                <span *ngIf="!refreshingStrains">Sync with Dutchie</span>
            </button>
            <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Save Strains" (click)="save()">
              <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Strains">save</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Discard Changes" (click)="getStrains()">
                <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item id="sensor_export_btn" title="Export Sensor" (click)="export()">
                  <mat-icon aria-hidden="false" aria-label="Export Sensor">file_download</mat-icon>
                  <span>Export Strains</span>
              </button>
              <button mat-menu-item id="sensor_import_btn" title="Export Sensor" (click)="import()">
                  <mat-icon aria-hidden="false" aria-label="Upload Sensor">file_upload</mat-icon>
                  <span>Upload Strains</span>
              </button>
            </mat-menu>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <mat-form-field class="example-chip-list">
            <mat-label>Strains</mat-label>
            <mat-chip-list #chipList aria-label="Strains">
                <mat-chip *ngFor="let strain of strains" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(strain)">
                    {{strain.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="New strain..." [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
    </div>
</div>

<!-- <div class="row">
    <div class="col-12">
        <div style="float: right">
            <button *ngIf="canSeeLeaflogix" mat-raised-button class="pad-btn" [disabled]="refreshingStrains" title="Refresh Strains" (click)="refreshStrainsClick()">
                <i class="fa fa-spinner fa-spin" *ngIf="refreshingStrains"></i>
                <span *ngIf="!refreshingStrains">Sync with Dutchie</span>
            </button>
        </div>
    </div>
</div> -->
