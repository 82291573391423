<div mat-dialog-title>
    <div class="flex-grid-thirds">
        <div class="col"></div>
        <div class="col">
            <h3>{{title}}</h3>
        </div>
        <div class="col"></div>
    </div>
</div>

<mat-dialog-content>

    <mat-form-field>
      <input matInput [matDatepicker]="datePicker" placeholder="Date" [min]="minDate" [(ngModel)]="date">
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <input matInput name="time" type="time" [(ngModel)]="time" placeholder="Time">
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button type="submit" (click)="submit()">
        Submit
    </button>
</mat-dialog-actions>