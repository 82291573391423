<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" (onClose)="popoverClosed($event)" (onOpen)="popoverOpened($event)" #popover="ngxMaterialPopover"
    mode="toggle">
    <button mat-raised-button type="button">
        {{label}}<span *ngIf="selected.length > 0"> ({{selected.length}})</span>
        <mat-icon class="expand">expand_more</mat-icon>
    </button>
</ngx-material-popover>

<ng-template #popoverContent>
    <mat-form-field appearance="outline" [ngClass]="{'harvests-search-box': label === 'Harvests' , 'other-search-boxes': label !== 'Harvests'}">
        <mat-label>Search...</mat-label>
        <input matInput [(ngModel)]="searchVal">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <ul class="harvest-list" *ngIf="label === 'Harvests'; else restFilters">
        <table class="harvests-table">
            <tr>
                <th></th>
                <th style="color:rgba(0,0,0,.87); font-weight: 500; font-size: 14px; padding: 5px; text-align: center;">Start Date</th>
                <th style="color:rgba(0,0,0,.87); font-weight: 500; font-size: 14px; padding: 5px; text-align: center;">Harvest Date</th>
                <th style="color:rgba(0,0,0,.87); font-weight: 500; font-size: 14px; padding: 5px; text-align: center;">End Date</th>
                <th style="color:rgba(0,0,0,.87); font-weight: 500; font-size: 14px; padding: 5px; text-align: left;">Day</th>
            </tr>
                
            <tr *ngFor="let item of filteredList" class="harvest-data-row">
                <td [ngStyle]="{'display': 'flex', 'align-items': 'center'}">
                    <mat-checkbox 
                        [(ngModel)]="item.selected" 
                        class="px-1 mt-2 harvest-select-chbx"
                    >
                    </mat-checkbox>
                    
                    <span [ngStyle]="{'display': 'inline-block', 'background-color': item.color ? item.color : '#0066FF', 'width.px': '15', 'height.px': '15', 'border-radius': '50%', 'margin-top.px': '2'}"></span>
                    
                    <span 
                        class="harvest-name-display px-1"
                        (click)="gotoHarvestDetails(item.id)"
                    >
                        {{item.displayValue}}
                    </span>
                </td>
                <td>
                    <div style="color: #AAAAAA;">
                        {{ item.formattedStartDate }}
                    </div>
                </td>
                <td>
                    <div style="color: #AAAAAA;">
                        {{ item.formattedHarvestDate }}
                    </div>
                </td>
                <td>
                    <div style="color: #AAAAAA;">
                        {{ item.formattedEndDate }}
                    </div>
                </td>
                <td>
                    <div [class]="item.currentDay == 'PAST DUE' ? 'textred' : 'textgray'">
                        {{ item.currentDay }}
                    </div>
                </td>
            </tr>
        </table>
    </ul>
    <ng-template #restFilters>
        <ul class="list">
            <li *ngFor="let item of filteredList">
                <mat-checkbox [(ngModel)]="item.selected" *ngIf="!item.harvestAtEnd; else postHarvestPhase;">
                    {{item.displayValue}}
                </mat-checkbox>
                <ng-template #postHarvestPhase>
                    <mat-checkbox [(ngModel)]="item.selected">
                        {{item.displayValue}}
                    </mat-checkbox>
                    <span style="width: 100%;display: flex; align-items: center; margin-bottom: 4px;">
                        <mat-divider style="width: inherit;border-color: #EEF0F1; border-style: dashed;"></mat-divider>
                        <mat-icon style="font-size: 18px; margin-left: auto; margin-right: 0; margin-top: -5px; color: grey; transform: rotate(180deg);">content_cut</mat-icon>
                    </span>
                </ng-template>
            </li>
        </ul>
    </ng-template>
    
    <div [ngClass]="{'harvests-footer': label === 'Harvests', 'footer': label !== 'Harvests'}">
        <div>
            <button mat-button color="primary" (click)="selectAll()">Select All</button>
            <button mat-button (click)="selectNone()">Select None</button>
        </div>
        <div *ngIf="label === 'Harvests'" class="footer-filter">
            <mat-icon (click)="sortByFirstDate(!sortDateOrder)" mat-ripple [matTooltip]="sortDateOrder ? 'Sort Harvests By Descending Start Date' : 'Sort Harvests By Ascending Start Date'" matTooltipPosition="above" class="sortBtn">sort</mat-icon>
            <mat-icon (click)="sortByName(!sortNameOrder)" mat-ripple [matTooltip]="sortNameOrder ? 'Sort Harvests By Descending Names' : 'Sort Harvests By Ascending Names'" matTooltipPosition="above" class="sortBtn">sort_by_alpha</mat-icon>
            <mat-slide-toggle [matTooltip]="appStorageService.showFinishedHarvests ? 'Exclude Finished Harvests' : 'Include Finished Harvests'" [(ngModel)]="appStorageService.showFinishedHarvests" matTooltipPosition="above"></mat-slide-toggle>
        </div>
    </div>
</ng-template>