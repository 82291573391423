<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" (onOpen)="openedEvent($event)" (onClose)="closedEvent($event)" #popover="ngxMaterialPopover"
    mode="toggle">
    <button mat-raised-button>
        Other Filters
        <mat-icon class="expand">expand_more</mat-icon>
    </button>
</ngx-material-popover>

<ng-template #popoverContent>
    <div class="row" style="min-width: 300px">
        <div class="col-sm-4">
            Complete Status:
        </div>
        <div class="col-sm-8">
            <mat-radio-group class="example-radio-group" [(ngModel)]="completeStatus">
                <mat-radio-button *ngFor="let opt of completeStatusOptions" [value]="opt.value">{{opt.display}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row" style="min-width: 300px">
        <div class="col-sm-4">
            Only my tasks:
        </div>
        <div class="col-sm-8">
            <mat-slide-toggle [(ngModel)]="onlyMyTasks" class="gridbtn"></mat-slide-toggle>
        </div>
    </div>
    <!-- <div class="footer">
        <button mat-button color="primary" (click)="selectAll()">Select All</button>
        <button mat-button (click)="selectNone()">Select None</button>
    </div> -->
</ng-template>