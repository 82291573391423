import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class DemoRequestService {

  constructor(
    private fns: AngularFireFunctions) {
    console.log('Hello DemoRequestService Provider');
  }

  async requestDemo(email: string, dialogResponse): Promise<any> {
    var demoRequestMethod = this.fns.httpsCallable('demoRequest');
    var result = await demoRequestMethod({
      email: email,
      growSize: dialogResponse.size,
      employees: dialogResponse.employees,
      schedulingProcess: dialogResponse.schedule,
      issue: dialogResponse.issue,
      timeSpent: dialogResponse.timeSpent
    }).toPromise();

    return result;
  }

}
