import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

import * as models from '../../models';
import { Timestamp } from "@angular/fire/firestore";

@Component({
    selector: "timestamp-cell",
    template: `
    <ng-container *ngIf="timestamp != null">
        <span *ngIf="!showTime">{{ timestamp.seconds * 1000 | date:'MM/dd/yyyy' }}</span>
        <span *ngIf="showTime">{{ timestamp.seconds * 1000 | date:'MM/dd/yyyy h:mm a' }}</span>
        <span *ngIf="showDayOfWeek"> ({{ timestamp.seconds * 1000 | date:'EEE' }})</span>
    </ng-container>
    `,
    styles: [
    ]
})
export class TimeStampComponent implements ICellRendererAngularComp {
    private params: any;

    timestamp: Timestamp;
    showTime: boolean = false;
    empty: boolean = false;
    showDayOfWeek: boolean = false;

    constructor() { }

    agInit(params: any): void {
        if (!params.value) {
            return;
        }

        this.params = params;

        this.timestamp = this.params.value;
        this.showTime = this.params.showTime || false;
        this.showDayOfWeek = this.params.showDayOfWeek || false;
    }

    refresh(params: any): boolean {
        return false;
    }
}