<mat-dialog-content>

  <form id="templateWorkflowDelete" class="user-form" (ngSubmit)="deleteClick()">

    <div class="row">
      <div class="col-12">
        <h4 mat-dialog-title class="mb-n1">Template(s) Delete</h4>
        <app-template-select-multiple 
          [displayList]="true" 
          [displayFor]="'templates'"
          [(selectedPrimaryTemplates)]="primaryTemplates"
          >
        </app-template-select-multiple>

      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <h4 mat-dialog-title class="mb-n1">Workflow(s) Delete</h4>
        <app-template-select-multiple 
          [displayList]="true" 
          [displayFor]="'workflows'"
          [(selectedSecondaryTemplates)]="secondaryTemplates">
        </app-template-select-multiple>

      </div>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="deleting">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="templateWorkflowDelete" [disabled]="deleting">
    <mat-spinner *ngIf="deleting" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!deleting">Delete</span>
  </button>
</mat-dialog-actions>
