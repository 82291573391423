import { AfterViewInit, Component, } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IRichCellEditorParams } from 'ag-grid-community';

import * as models from 'app/models';

@Component({
    selector: 'team-select-multiple-cell',
    template: `
    <div class="cont">
        <mat-form-field>
            <mat-label>Select Teams</mat-label>
            <mat-select [(ngModel)]="selectedTeams" multiple #teamSelect="ngModel">
                <mat-select-trigger>
                    {{title}}
                </mat-select-trigger>
                <mat-option [value]="t" *ngFor="let t of teams">{{t.name}}</mat-option>
            </mat-select>
            <button type="button" matSuffix mat-icon-button (click)="go()"><mat-icon>keyboard_return</mat-icon></button>
        </mat-form-field>
    </div>
    `,
    styles: [
        `
        .cont{
          padding:20px;
        }
      `
    ],
})
export class TeamSelectMultipleEditorCell implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    teams: Array<models.Team> = [];
    selectedTeams: Array<models.Team>;

    get title(): string {
        if (this.selectedTeams == null || this.teams == null) {
            return '';
        }
        if (this.selectedTeams.length == this.teams.length) {
            return 'ALL';
        }
        if (this.selectedTeams.length >= 1) {
            let title = this.selectedTeams[0].name;
            if (this.selectedTeams.length >= 2) {
                title += ` (+${this.selectedTeams.length - 1} ${this.selectedTeams?.length === 2 ? 'other' : 'others'})`
            }
            return title;
        }
    }

    teamChange(newValue: models.Team[]) {
      this.selectedTeams = newValue.filter(i => i != null)
    }

    isPopup(): boolean {
        return true;
    }

    ngAfterViewInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.teams = this.params.teams;
        let assignedTeamIds: string[] = this.params.value;

        if(assignedTeamIds == null){
            this.selectedTeams = [];
        } else {
            this.selectedTeams = this.teams.filter(i => assignedTeamIds.includes(i.uid));
        }
    }

    getValue(): any {
        if(!this.selectedTeams) {
            this.params.data.assignedTeamIds = [];
        } else {
            this.params.data.assignedTeamIds = this.selectedTeams.map(i => i.uid);
        }

        return this.params.data.assignedTeamIds;
    }

    go() {
        this.params.api.stopEditing()

        //this.gridOptions.api.stopEditing()
    }
}
