import { Subtask } from "./subtask";
import { TemplatedTask } from "./templatedTask";

export interface Step {
    uid?: string;
    day?: string;
    description?: string;
    precedence?: number;
    highPriority?: boolean;
    anyTime?: boolean;
    startTime?: string;
    cloudAttachments?: Array<string>;
    attachments?: Array<string>;
    notes?: Array<string>;
    tags?: Array<string>;
    estimatedTime?: string;
    timeSpentRequired?: boolean;
    assignedTeamIds?: Array<string>;
    subtasks?: Array<Subtask>;
    templatedTaskId?: string;

    dayTotal?: string;
    dayOfWeek?: string;
}

export class Step {
    constructor(task: TemplatedTask){
        this.templatedTaskId = task.id;
        this.description = task.description || '';
        if (task.precedence) {
            this.precedence = task.precedence;
        }
        if (task.highPriority) {
            this.highPriority = task.highPriority;
        }
        if (task.anyTime) {
            this.anyTime = task.anyTime;
        }
        if (task.startTime) {
            this.startTime = task.startTime;
        }
        if (task.cloudAttachments) {
            this.cloudAttachments = task.cloudAttachments;
        }
        if (task.attachments) {
            this.attachments = task.attachments;
        }
        if (task.estimatedTime) {
            this.estimatedTime = task.estimatedTime;
        }
        if (task.timeSpentRequired) {
            this.timeSpentRequired = task.timeSpentRequired;
        }

        this.tags = task.tags || [];
        this.notes = task.notes || [];
        this.assignedTeamIds = task.assignedTeamIds || [];
        this.subtasks = task.subtasks || [];
    }
}