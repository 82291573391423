import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-invite-to-other-company-dialog',
  templateUrl: './invite-to-other-company-dialog.component.html',
  styleUrls: ['./invite-to-other-company-dialog.component.scss']
})
export class InviteToOtherCompanyDialogComponent implements OnInit {

  submitted: boolean = false;
  gettingCompanies: boolean = false;

  currentCompanyId: string;
  user: models.User;

  companies: models.Company[];
  selectedCompanyId: string;

  constructor(
    private dialogRef: MatDialogRef<InviteToOtherCompanyDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private companyService: services.CompanyService
    , private userService: services.UserService
    , private helperService: services.HelperService
    , private notifierService: services.NotifierService
  ) { 
    if(data){
      this.currentCompanyId = data.companyId;
      this.user = data.user;
      this.companies = data.companies
    }
  }

  ngOnInit(): void {
    //this.getCompanies();
  }

  // async getCompanies() {
  //   let companyResult = await this.companyService.getAllCompaniesForUser();
  //   if(companyResult.success){
  //     this.companies = companyResult.value.filter(i => i.uid != this.currentCompanyId);
  //   }
  //   this.gettingCompanies = false;
  // }

  invite() {
    this.submitted = true;

    let userRequest: models.User = {
      companyIds: [this.selectedCompanyId],
      emailAddress: this.user.emailAddress
    }

    let request: viewmodels.AddUserRequest = {
      domain: this.helperService.getCurrentDomain(),
      user: userRequest
    }
    this.userService.addUser(request).then(data => {

      if(data.data.success){
        this.notifierService.success(data.data.message);

        this.dialogRef.close();
        this.submitted = false;

      }else{
        this.notifierService.error(data.data.message);

        this.submitted = false;
      }
      
    }).catch(error => {
      this.notifierService.error(error.message);
      console.log(JSON.stringify(error))

      this.submitted = false;
    });
  }
}
