import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class StripeService {

  constructor(
    private firestore: AngularFirestore,
    private functions: Functions,
    private helperService: HelperService) {
  }

  /// { data } = { domain }
  async createStripeSession(inObj: any): Promise<any> {
    var full = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    inObj.domain = full;
    var createStripeSessionMethod = httpsCallable(this.functions, 'createStripeSession');
    var createStripeSessionResponse = await createStripeSessionMethod(inObj);

    return createStripeSessionResponse;
  }

  async retrieveCheckoutSession(sessionId: string): Promise<any> {
    let inObj = {
        sessionId: sessionId
    }
    var retrieveCheckoutSessionMethod = httpsCallable(this.functions, 'retrieveCheckoutSession');
    var retrieveCheckoutSessionResponse = await retrieveCheckoutSessionMethod(inObj);
    return retrieveCheckoutSessionResponse;
  }

  async retrieveCustomerPortalSession(customerId: string): Promise<any> {
    var full = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    let inObj = {
        customerId: customerId,
        domain: full
    }
    var retrieveCustomerPortalSessionMethod = httpsCallable(this.functions, 'retrieveCustomerPortalSession');
    var retrieveCustomerPortalSessionResponse = await retrieveCustomerPortalSessionMethod(inObj);
    return retrieveCustomerPortalSessionResponse;
  }

  async getSubscriptionsByStripeCustomerId(customerId: string): Promise<any> {
    var getSubscriptionsByStripeCustomerIdMethod = httpsCallable(this.functions, 'getSubscriptionsByStripeCustomerId');
    var getSubscriptionsByStripeCustomerIdResponse = await getSubscriptionsByStripeCustomerIdMethod({customerId: customerId});
    return getSubscriptionsByStripeCustomerIdResponse;
    
  }
}
