import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { ClaimsService } from '../claims.service';
import { CompanyService } from "../company.service";

@Injectable()
export class CanActivateGuard implements CanActivate {

  constructor(
    private claimsService: ClaimsService
    , private router: Router
  ) {}

  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //must have one of these roles
    let permissions = route.data.permissions as Array<string>;

    //if there are no roles passed in, then we don't need to go check the roles
    if (permissions == null || permissions.length == 0) {
      return true;
    }

    let role = await this.claimsService.getRoleAwait();

    for (var i = 0; i < permissions.length; i++) {
      let r: string = permissions[i];
      if (role.permissions.includes(r)) {
        return true;
      }
    }

    this.router.navigate(['console', 'no-permissions']);
    return false;

    // if(role.precedence != 0){
    //   this.router.navigateByUrl('/no-permissions');
    //   return false;
    // }

    //return true;
  }
}
