<mat-card class="col-sm-12 mt-4 chart-container">
    <mat-card-header class="chart-title">
        <mat-card-title>
            {{ chartTitle }}
        </mat-card-title>
        <mat-card-subtitle>
            {{ chartSubTitle }}
        </mat-card-subtitle>
    </mat-card-header>
    <span class="material-symbols-outlined expand-icon" 	
        (click)="changeChartView()">
        expand_content
    </span>
    <mat-card-content class="mb-0">
        <div class="chart-view-tabs">
            <button 
                class="tab-button" 
                (click)="switchChartView('Users')"
                [class.active]="selectedChartView === 'Users'">Users
            </button>
            <button 
                class="tab-button" 
                (click)="switchChartView('Teams')"
                [class.active]="selectedChartView === 'Teams'">Teams
            </button>
        </div>
        <div style="height: 375px;">
            <canvas #chartCanvas></canvas>
        </div>
    </mat-card-content>
    <mat-card-actions class="card-action-btn">
            <app-chart-daterange-filter 
                (selectedChartDateRangeChange)="handleChartDateRangeChange($event)"
            ></app-chart-daterange-filter>
    </mat-card-actions>
</mat-card>

