import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as services from '../../services';
import * as models from '../../models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {

  loading: boolean = true;
  saving: boolean = false;
  errorCode: string;

  linkId: string;
  link: models.UserLink;

  invitedEmail: string;

  password: string;
  confirmPassword: string;

  lowercaseValid: boolean = false;
  uppercaseValid: boolean = false;
  numberValid: boolean = false;
  lengthValid: boolean = false;
  specialCharValid: boolean = false;

  get confirmCorrect() {
    if(this.password == null || this.password == ''){
      return false;
    }

    return this.password == this.confirmPassword;
  }

  get disabledSubmit() {
    return !this.lowercaseValid 
            || !this.uppercaseValid 
            || !this.numberValid 
            || !this.lengthValid 
            || !this.specialCharValid 
            || !this.confirmCorrect;
  }

  constructor(
    private route: ActivatedRoute
    , private router: Router
    , private userService: services.UserService
    , private snackBar: MatSnackBar
    , private authService: services.AuthService
    , private notifierService: services.NotifierService
  ) { 
    this.route.queryParams.subscribe(params => {
        this.linkId = params['l'];
        this.verifyLink();
    });
  }

  ngOnInit(): void {
  }

  async verifyLink() {
    if(this.linkId == null){
      this.router.navigate(['login']);
      return;
    }

    let linkResponse = await this.userService.getUserLink(this.linkId);
    let link: models.Result<models.UserLink> = linkResponse.data;

    if(!link.success) {
      this.errorCode = link.message;
      this.loading = false;
      return;
    }

    this.link = link.value;
    this.invitedEmail = this.link.user.emailAddress;
    this.loading = false;
  }

  async resend() {
    this.loading = true;
    let linkResponse = await this.userService.requestReplacementLink(this.linkId);
    let link: models.Result<models.UserLink> = linkResponse.data;

    if(link.success){
      this.errorCode = 'resent_link';
    } else {
      this.snackBar.open(link.message)
    }

    this.loading = false;
  }

  onKey(event: any) { // without type info
    this.password = event.target.value;

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    this.lowercaseValid = event.target.value.match(lowerCaseLetters);
    
    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    this.uppercaseValid = event.target.value.match(upperCaseLetters)
  
    // Validate numbers
    var numbers = /[0-9]/g;
    this.numberValid = event.target.value.match(numbers)
    
    // Validate length
    this.lengthValid = event.target.value.length >= 8

    //validate special character
    var special = /[!@#$%^&*]/g;
    this.specialCharValid = event.target.value.match(special)
  }

  async setPassword() {
    if(this.password == null || this.password == ''){
      this.snackBar.open('Must enter Password to continue');
      return;
    }

    if(this.password != this.confirmPassword){
      this.snackBar.open('Must correctly confirm your password to continue');
      return;
    }

    //set password for userlink
    this.saving = true;
    let linkResponse = await this.userService.verifyUserLink(this.linkId, this.password);
    let linkResult: models.Result<models.UserLink> = linkResponse.data;

    //log user in
    if(linkResult.success){
      await this.authService.signIn(linkResult.value.user.emailAddress, this.password);
      this.redirectToApp(linkResult.value.user.id);
    }

    this.saving = false;
  }

  async googleClick() {
    try {
      let userCred = await this.authService.signInViaGoogle();

      //account used matches invited email
      if(userCred.user.email == this.invitedEmail){
        await this.redirectToApp(userCred.user.uid)
      } else {
        this.notifierService.error(
          'Account selected via authentication partner does not match the invited email address.',
          false
        );
        await this.authService.signOut()
      }
    }
    catch(ex){
      if(ex.code == 'auth/popup-closed-by-user') {
        return;
      }
      this.snackBar.open('Could not sign in.', null, {
        duration: 3000,
        verticalPosition: 'top'
      });
    }
  }

  async microsoftClick () {
    try {
      let userCred = await this.authService.signInViaMicrosoft();

      //account used matches invited email
      if(userCred.user.email == this.invitedEmail){
        await this.redirectToApp(userCred.user.uid)
      } else {
        this.notifierService.error(
          'Account selected via authentication partner does not match the invited email address.',
          false
        );
        await this.authService.signOut()
      }
    }
    catch(ex){
      if(ex.code == 'auth/popup-closed-by-user') {
        return;
      }
      this.snackBar.open('Could not sign in.', null, {
        duration: 3000,
        verticalPosition: 'top'
      });
    }
  }

  async redirectToApp(userId: string) {
    this.snackBar.open('Signed in Successfully.', null, {
      duration: 3000,
      verticalPosition: 'top'
    });

    await this.userService.expireUserLink(userId, this.link.id);

    window.location.href = '/console';
  }

}
