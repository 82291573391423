import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import * as models from '../../models';
import * as viewModels from '../../viewmodels';
import * as services from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DiscussionType } from '../../models';
import { DomSanitizer } from '@angular/platform-browser';

var linkify = require('linkifyjs');
var linkifyHtml = require('linkifyjs/html');

@Component({
  selector: 'app-discussion-panel',
  templateUrl: './discussion-panel.component.html',
  styleUrls: ['./discussion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DiscussionPanelComponent implements OnInit {

  @Input() discussions: Array<models.Discussion>;

  discussionViewModels: Array<viewModels.DiscussionViewModel> = [];
  users: Array<models.User> = [];

  loading: boolean = true;

  constructor(
    private userService: services.UserService,
    private storage: SessionStorageService,
    private helperService: services.HelperService,
    private snackBar: MatSnackBar,
    private sanitized: DomSanitizer,
    private fileService: services.FileService
    ) { 
      //Window["DiscussionPanelComponent"] = this;
    }

  async ngOnInit(): Promise<void> {
    await this.getUsers();

    this.organizeDiscussionHistory();
  }

  /**
   * get users for the company from the database
   */
  async getUsers() {
    let companyId = this.helperService.currentCompanyId;
    let userResponse = await this.userService.getUsersFoCompany(companyId, false)

    if(!userResponse.data.success){
      this.snackBar.open('Error getting Users', null, {duration: 3000, verticalPosition: 'top'});
      return;
    }

    userResponse.data.value.forEach(element => {
      let user: models.User = element;
      user.id = element.id;
      this.users.push(user);
    });
  }

  /**
   * take in discussion list from event and organize it into viewmodels
   */
  async organizeDiscussionHistory() {

    if(this.discussions == null){
      this.loading = false;
      return;
    }

    let docs: Array<any> = [];

    this.discussions = this.helperService.sortByDateField('timestamp', this.discussions);

    //include all discussions except for the notes
    let discussions = this.discussions.filter(i => i.discussionType != models.DiscussionType.Note && i.discussionType != models.DiscussionType.InitialNote);
    for (let i = 0; i < discussions.length; i++) {
      const dis = discussions[i];
      
      let disVM: viewModels.DiscussionViewModel = {
        timestamp: dis.timestamp
      };

      if(dis.timestamp != null){
        disVM.formattedDate = this.helperService.timestampToDateTimeString(dis.timestamp)
      }

      let user = this.users.find(i => i.id == dis.userId);

      disVM.userName = user != null ? user.displayName : 'Unknown';

      switch(dis.discussionType){
        case DiscussionType.Created : {
          disVM.icon = 'created';
          disVM.description = 'Created task';
          break;
        }
        case DiscussionType.InitialNote : {
          disVM.icon = 'note';
          let linkifyString = linkifyHtml(dis.content, {
            defaultProtocol: 'https'
          });
          disVM.userName = '';
          disVM.description = `Template Note: <b><i>'${linkifyString}'</i></b>`;
          disVM.timestamp = null;
          break;
        }
        case DiscussionType.Note : {
          disVM.icon = 'note';
          let linkifyString = linkifyHtml(dis.content, {
            defaultProtocol: 'https'
          });
          disVM.description = `left message: <b><i>'${linkifyString}'</i></b>`;
          break;
        }
        case DiscussionType.CompletedStatus : {
          if(dis.content == 'true'){
            disVM.icon = 'complete';
          } else {
            disVM.icon = 'incomplete';
          }
          disVM.description = `marked as ${dis.content == 'true' ? '<span class="green">Complete</span>' : '<span class="red">Incomplete</span>'}`;
          break;
        }
        case DiscussionType.Subtask : {
          disVM.icon = 'subtask';
          disVM.description = `marked ${dis.content}`;
          break;
        }
        case DiscussionType.SubtaskAdded : {
          disVM.icon = 'material subtask_add';
          disVM.description = `added subtask '${dis.content}'`;
          break;
        }
        case DiscussionType.SubtaskRemoved : {
          disVM.icon = 'material subtask_remove';
          disVM.description = `removed subtask '${dis.content}'`;
          break;
        }
        case DiscussionType.TimeSpent : {
          disVM.icon = 'time';
          disVM.description = `updated Time Spent to: ${dis.content} minutes`;
          break;
        }
        case DiscussionType.Efficacy : {
          disVM.icon = 'efficacy';
          disVM.description = `updated Quality Tracker to: ${dis.content}%`;
          break;
        }
        case DiscussionType.AttachedFile : {
          disVM.icon = 'file';
          let fullPath: string = dis.content;
          //let id: string = fullPath.substring(fullPath.lastIndexOf('/')+1);

          let name =  fullPath.split('\\').pop().split('/').pop();
          let url = await this.fileService.get(fullPath).toPromise()
          //disVM.description = this.sanitized.bypassSecurityTrustHtml(`attached a file: <a id="${id}" href="javascript:;">${dis.content.fileName}</a>`) ;
          disVM.description = this.sanitized.bypassSecurityTrustHtml(`attached a file: <a target="_blank" href="${url}">${name}</a>`) ;

          // docs.push({
          //   id: id,
          //   content: dis.content
          // });
          break;
        }
        default : {
          return;
        }
      }

      this.discussionViewModels.push(disVM);
    };

    this.loading = false;

    // this.helperService.wait(150).then(d => {
    //   docs.forEach(doc => {
    //     document.querySelector("#" + doc.id).addEventListener('click', () => {
    //       this.onClick(doc.content);
    //     });
    //   });
    // });

  }

  onClick(content: string) {
    this.fileService.get(content).subscribe(url => {
      var link = document.createElement("a");
      if (link.download !== undefined) {
          link.setAttribute("href", url);
          link.setAttribute("download", content);
          link.setAttribute("target", "_blank");
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    })
  }

}
