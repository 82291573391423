<ng-container *ngIf="collectionId != null">
    <div class="row" data-step="1" data-intro="Double click on a row to edit the Event." data-position='right'>
        <div class="col-12">
            <!-- <div class="row" style="padding-bottom: 15px">
                <div class="col-sm-8" style="text-align: left">
                    <h3>Events</h3>
                </div>
                <div class="col-sm-4 right">
                    <app-harvest-save-btn [disabled]="!this.hasPendingChanges" (click)="saveChanges()">
                    </app-harvest-save-btn>
                    <app-harvest-undo-btn [disabled]="!this.hasPendingChanges" (click)="undoChanges()">
                    </app-harvest-undo-btn>
                    <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item data-step="2" data-into="Use this to add an Event." data-position='left' (click)="addEvent()" [disabled]="this.hasPendingChanges" title="Add Event">
                        <mat-icon aria-hidden="false" aria-label="Add Event">add</mat-icon>
                        <span>Add Event</span>
                      </button>
                    </mat-menu>
                </div>
            </div> -->
            <ag-grid-angular 
                style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
                class="ag-theme-balham"
                [gridOptions]="eventGridOptions"
                [rowData]="gridList"
                (cellDoubleClicked)="eventClick($event)"
                (rowSelected)="handleRowClick($event)"
                [columnDefs]="columnDefs" >
            </ag-grid-angular>
        </div>
    </div>
    
    <div class="row" style="padding: 15px 0px">
        <div class="col-sm-12 right">
            <button class="gridbtn" mat-raised-button (click)="exportAsCSV()">Export as CSV</button>
        </div>
    </div>
</ng-container>