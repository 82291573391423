import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import * as models from '../models';

@Injectable()
export class SystemSettingsService {

    constructor(
        private firestore: AngularFirestore,
    ) {
    }

    async get(): Promise<models.SystemSettings> {
        let response = this.firestore.collection('system').doc('system');
        let systemSettings: models.SystemSettings = (await response.get().toPromise()).data();
        return systemSettings;
    }

    async save(systemSettings: models.SystemSettings) {
        return this.firestore.collection('system').doc('system').set(systemSettings);
    }
}