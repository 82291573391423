import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HelperService } from './helper.service';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';
import * as models from 'app/models';

@Injectable()
export class SquareService {

  constructor(
    private firestore: AngularFirestore,
    private functions: Functions,
    private helperService: HelperService) {
  }

  async getCustomer(paymentProviderCustomerId: string): Promise<any> {
    let inObj = {
        paymentProviderCustomerId: paymentProviderCustomerId
    }
    var method = httpsCallable(this.functions, 'getSquareCustomerById');
    var response = await method(inObj);
    return response;
  }

  async getSubscriptonsBySquareCustomerId(paymentProviderCustomerId: string): Promise<any> {
    let inObj = {
        paymentProviderCustomerId: paymentProviderCustomerId
    }
    var method = httpsCallable(this.functions, 'getSubscriptonsBySquareCustomerId');
    var response = await method(inObj);
    return response;
  }

  async getSquareCustomerInvoices(paymentProviderCustomerId: string): Promise<any> {
    let inObj = {
        paymentProviderCustomerId: paymentProviderCustomerId
    }
    var method = httpsCallable(this.functions, 'getSquareCustomerInvoices');
    var response = await method(inObj);
    return response;
  }

  async updateCardOnSubscription(subscriptionId: string, cardId: string): Promise<any> {
    let inObj = {
        subscriptionId: subscriptionId,
        cardId: cardId
    }
    var method = httpsCallable(this.functions, 'updateCardOnSquareSubscription');
    var response = await method(inObj);
    return response;
  }

  async cancelSubscription(subscriptionId: string): Promise<any> {
    let inObj = {
        subscriptionId: subscriptionId
    }
    var method = httpsCallable(this.functions, 'cancelSquareSubscription');
    var response = await method(inObj);
    return response;
  }

  async resumeSubscription(subscriptionId: string): Promise<any> {
    let inObj = {
        subscriptionId: subscriptionId
    }
    var method = httpsCallable(this.functions, 'resumeSquareSubscription');
    var response = await method(inObj);
    return response;
  }

  async addCardToCustomer(customerId: string, cardNonce: string): Promise<HttpsCallableResult<models.Result<any>>> {
    let inObj = {
        customerId: customerId,
        cardNonce: cardNonce
    }
    var method = httpsCallable<any, models.Result<any>>(this.functions, 'addCardToSquareCustomer');
    var response = await method(inObj);
    return response;
  }

  async upgradeAccount(
      givenName: string
      , familyName: string
      , companyName: string
      , companyId: string
      , emailAddress: string
      , cardNonce: string
      , cardholderName: string
      , startDate: string
      , paymentProviderCustomerId: string
      , cardId: string): Promise<any> {
    let inObj = {
        paymentProviderCustomerId: paymentProviderCustomerId,
        givenName: givenName,
        familyName: familyName,
        companyName: companyName,
        companyId: companyId,
        emailAddress: emailAddress,
        cardNonce: cardNonce,
        cardholderName: cardholderName,
        startDate: startDate,
        cardId: cardId
    }
    if(paymentProviderCustomerId != null){
        inObj.paymentProviderCustomerId = paymentProviderCustomerId;
    }
    var method = httpsCallable(this.functions, 'upgradeAccount');
    var response = await method(inObj);
    return response;
  }
}
