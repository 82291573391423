<div class="row">
    <div class="col-12">
        <h3 style="float: left;">Post Harvest Data</h3>
        <div style="float: right;">
            <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Save" (click)="save()">
              <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save">save</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Discard Changes" (click)="getPostHarvestDataPoints()">
                <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" title="Add" (click)="add()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="nothing-container" *ngIf="postHarvests.length == 0">
    <div class="nothing-content">
        No Post Harvest Data Points Set Up
    </div>
</div>
<div *ngIf="postHarvests.length > 0" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let postHarvest of postHarvests; let i = index" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      {{postHarvest.name}} - {{postHarvest.dataType}}
      <div>
        <!-- <button mat-raised-button class="pad-btn" title="Edit" (click)="edit(postHarvest)">
            <mat-icon>edit</mat-icon>
        </button> -->
        <button mat-icon-button class="pad-btn" title="Delete" (click)="delete(i)">
            <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
</div>
