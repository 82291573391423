<h2 mat-dialog-title class="dialog-title">
  Add Event
  <span *ngIf="startDate?.value != null"
    style="float: right; font-size: 15px; color: rgb(96, 96, 96);">{{startDate.value | date:'EEEE, MMMM d, y'}}</span>
  <!-- <span *ngIf="event.startDateTime != null"
    style="float: right; font-size: 15px; color: rgb(96, 96, 96);">{{event.startDateTime.seconds * 1000 | date:'EEEE, MMMM d, y'}}</span> -->

  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>

  <div class="row" *ngIf="needToPickHarvest">
    <div class="col-12">
      <div class="assignContainer">
        <ng-template matStepLabel>Harvest/Task Group</ng-template>
        <mat-button-toggle-group class="center" [(ngModel)]="assignToGroup">
          <mat-button-toggle [value]="false">Harvest</mat-button-toggle>
          <mat-button-toggle [value]="true">Task Group</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>

  <ng-container *ngIf="assignToGroup != null">
    <div class="row" style="padding-bottom: 10px">
      <div class="col-sm-6">
        <button style="margin-bottom: 5px; z-index: 10000" mat-raised-button (click)="repeatBtn()">
          Repeat
          <mat-icon *ngIf="repeatEvent" aria-hidden="false">done</mat-icon>
        </button>
      </div>
      <div class="col-sm-6 right">
        <button style="z-index: 10000" mat-raised-button (click)="selectUsers()">Assign Users</button>
      </div>
    </div>

    <form id="updateEvent" class="user-form" (ngSubmit)="saveEvent()">

      <!-- <mat-slide-toggle *ngIf="needToPickHarvest && needToPickGroup" name="assignToGroup" [(ngModel)]="assignToGroup" class="gridbtn" (change)="toggleChange($event)">Assign to Group</mat-slide-toggle>-->

      <mat-form-field *ngIf="needToPickDate">
        <input matInput [matDatepicker]="startDatePicker" placeholder="Event Date" [formControl]="startDate"
          disabled="true">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>

      <ng-container *ngIf="!assignToGroup">
        <app-harvest-select 
          [disabled]="!needToPickHarvest" 
          [includeCompleted]="!needToPickHarvest" 
          [(harvestId)]="harvestId" 
          [(harvest)]="harvest">
        </app-harvest-select>
      </ng-container>

      <ng-container *ngIf="assignToGroup">
        <app-group-select 
          [disabled]="!needToPickGroup" 
          [(groupId)]="groupId" 
          [(group)]="group">
        </app-group-select>
      </ng-container>

      <!-- <mat-form-field class="user-full-width" *ngIf="!assignToGroup">
        <input matInput placeholder="Phase" name="phase" [(ngModel)]="event.phase">
      </mat-form-field> -->

      <ng-container *ngIf="harvest != null && !assignToGroup">
        <app-phase-select [harvestId]="harvest.uid" [date]="startDate.value" [(phase)]="event.phase"></app-phase-select>
      </ng-container>

      <mat-form-field class="user-full-width" [class.mat-form-field-invalid]="descriptionHasError">
        <input matInput placeholder="Event Description" type="text" name="description" [(ngModel)]="event.description">
        <mat-hint class="mat-error" *ngIf="descriptionHasError">
          Event Description cannot be empty
        </mat-hint>
      </mat-form-field>

      <mat-checkbox name="highPriority" [(ngModel)]="event.highPriority">High Priority</mat-checkbox>

      <div class="row">
        <div class="col-5">
          <mat-checkbox name="anyTime" [(ngModel)]="event.anyTime">Anytime</mat-checkbox>
        </div>
        <div class="col-7">
          <mat-form-field class="user-full-width" *ngIf="!event.anyTime">
            <input matInput name="startTime" type="time" [(ngModel)]="eventTime" placeholder="Start Time">
          </mat-form-field>
        </div>
      </div>

      <!-- <mat-form-field>
        <mat-label>Assigned User</mat-label>
        <mat-select [(ngModel)]="event.assignedUserId" name="user" (ngModelChange)="userChange($event)">
          <mat-option value="">Unassigned</mat-option>
          <mat-option [value]="u.uid" *ngFor="let u of users">{{u.displayName}}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-checkbox name="completed" [(ngModel)]="event.completed">Completed</mat-checkbox> -->

      <mat-form-field>
        <textarea matInput placeholder="Leave a note..." name="notes" [(ngModel)]="notes"></textarea>
      </mat-form-field>

    </form>
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saving">Cancel</button>
  <button mat-button type="submit" form="updateEvent" [disabled]="saving">
    <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saving">Save</span>
  </button>
</mat-dialog-actions>