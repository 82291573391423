
<app-zone-setup></app-zone-setup>

<!-- <div class="row">
    <div class="col-12">
        <button (click)="addCol()">Add Col</button>
        <button (click)="addRow()">Add Row</button>
    </div>
</div> -->

<!-- <div class="row">
    <div class="col-12">
        <div class="grid-container" [style.width]="width" [style.grid-template-columns]="gridTemplateColumns">
            <div class="grid-item" *ngFor="let b of availableBoxes">{{b.name}}</div>
        <button (click)="addCol()">Add Column</button>
        <div class="grid-container">
            <div *ngFor="let z of zones" class="grid-item">{{z.name}}</div>
        </div>
        <button (click)="addRow()">Add Row</button>
    </div>
</div> -->
