import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-harvest-select-multi-btn',
  templateUrl: './harvest-select-multi-btn.component.html',
  styleUrls: ['./harvest-select-multi-btn.component.scss']
})
export class HarvestSelectMultiBtnComponent implements OnInit, OnDestroy {


  @Input() showUpcomingHarvests: boolean;
  @Input() appStorageNeeded: boolean = true;
  @Input() startWithNoneSelected: boolean = false;

  harvests: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if (val == null) {
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);

    this.selectedHarvests = this.harvests.filter(i => this.selected.includes(i.id));
  }
  @Output() onClosed = new EventEmitter();

  initialLoad: boolean = false;
  @Output() loaded = new EventEmitter();

  companySub: Subscription;

  selectedHarvestsValue: Array<viewmodels.MultiSelect>;
  @Input()
  get selectedHarvests() {
    return this.selectedHarvestsValue;
  }
  @Output() selectedHarvestsChange = new EventEmitter();
  set selectedHarvests(val) {
    if (val == null) {
      val = [];
    }
    this.selectedHarvestsValue = val;
    this.selectedHarvestsChange.emit(this.selectedHarvestsValue);
  }

  constructor(
    private harvestService: services.HarvestService
    , private helperService: services.HelperService
    , private appStorageService: services.AppStorage
  ) { }

  ngOnInit(): void {
    if (this.helperService.currentCompanyId != null) {
      this.getHarvests();
    }

    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.initialLoad = false;
      this.getHarvests();
    })
  }

  ngOnDestroy(): void {
    if (this.companySub) {
      this.companySub.unsubscribe();
    }
  }

  loading: boolean = false;
  async getHarvests(selectAll: boolean = false) {
    if (this.loading) {
      return;
    }
    this.loading = true;

    let harvests = this.showUpcomingHarvests ? await this.harvestService.getAllModels(false, false) : await this.harvestService.getAllModels();
    if (!this.initialLoad) { //set selected from saved data only if it is first time loading

      if (this.appStorageNeeded && selectAll == false) { //set selected from saved data only if app storage is required - not in case of harvest list or analytics - only for calendar

        let filterData = this.appStorageService.companyFiltersObject;
        if (filterData != null) {
          this.selected = filterData.harvestIds;// filterData ? filterData['companyFilters']['harvestIds'] : [];
        }
      } else {
        this.selected = [];
      }
      let list = harvests.map(i => {
        let name: string = i.name;
        if (i.leaflogixName != null && name != i.leaflogixName) {
          name += ' (' + i.leaflogixName + ')'
        }
        let h: viewmodels.MultiSelect = {
          id: i.uid,
          color: i.color,
          displayValue: name,
          selected: this.setSelected(i),
          completed: i.completed,
          startDate: i.startDate,
          harvestDate: i.harvestDate,
          endDate: i.endDate,
          formattedStartDate: this.helperService.timestampToFormattedDateString(i.startDate),
          formattedHarvestDate: this.helperService.timestampToFormattedDateString(i.harvestDate),
          formattedEndDate: this.helperService.timestampToFormattedDateString(i.endDate),
          currentDay: this.helperService.calculateCurrentDayForHarvest(i.startDate, i.endDate),
        }
        return h;
      })
      this.harvests = this.helperService.sortArrayByStringField(list, 'displayValue');
      // if(this.selected.length == 0){
      //   this.selected = this.harvests.filter(i => i.).map(i => i.id);
      // }
      this.selected = this.harvests.filter(i => i.selected).map(i => i.id)

      // this.harvests.forEach(harvest => {
      //   if(this.selected.includes(harvest.id)){
      //     harvest.selected = true;
      //   }
      // })

      //if(!this.initialLoad) {
      this.loaded.emit();
      this.initialLoad = true;
      //}
      this.loading = false;
    }
    let list = harvests.map(i => {
      let name: string = i.name;
      if(i.leaflogixName != null && name != i.leaflogixName){
        name += ' (' + i.leaflogixName + ')'
      }
      let h: viewmodels.MultiSelect = {
        id: i.uid,
        color: i.color,
        displayValue: name,
        selected: this.setSelected(i),
        completed: i.completed,
        startDate: i.startDate,
        harvestDate: i.harvestDate,
        endDate: i.endDate,
        formattedStartDate: this.helperService.timestampToFormattedDateString(i.startDate),
        formattedHarvestDate: this.helperService.timestampToFormattedDateString(i.harvestDate),
        formattedEndDate: this.helperService.timestampToFormattedDateString(i.endDate),
        currentDay: this.helperService.calculateCurrentDayForHarvest(i.startDate, i.endDate),
      }
      return h;
    })

    this.harvests = this.helperService.sortArrayByStringField(list, 'displayValue');
    // if(this.selected.length == 0){
    //   this.selected = this.harvests.filter(i => i.).map(i => i.id);
    // }
    this.selected = this.harvests.filter(i => i.selected).map(i => i.id)

    // this.harvests.forEach(harvest => {
    //   if(this.selected.includes(harvest.id)){
    //     harvest.selected = true;
    //   }
    // })

    //if(!this.initialLoad) {
    this.loaded.emit();
    this.initialLoad = true;
    //}
    this.loading = false;
  }

  closedEvent(event: any) {
    if (JSON.stringify(this.selected) != JSON.stringify(event)) {
      this.selected = event;
      this.onClosed.emit(event);
    }
  }

  setSelected(i: models.Harvest) {
    if(this.startWithNoneSelected) {
      return false;
    }
    
    return this.selected.length == 0 ? !i.completed : this.selected.includes(i.uid)
  }
}
