import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import * as models from '../models';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { FileService } from './file.service';
import { AttachmentsSelectDialogComponent } from 'app/dialogs';
import { TemplateService } from './template.service';
import { EventService } from './events.service';
import { CollectionReference, DocumentReference, Firestore, collection, doc, getDoc, getDocs, setDoc } from '@angular/fire/firestore';
import { deleteDoc } from 'firebase/firestore';

@Injectable()
export class AttachmentService {

    companiesRef: CollectionReference;
    slug: string = 'attachments';

    constructor(
        private firestore: Firestore,
        private sessionStorage: SessionStorageService,
        private helperService: HelperService,
        private fileService: FileService,
        private templateService: TemplateService,
        private eventService: EventService
    ) { 
        this.companiesRef = collection(this.firestore, 'companies');
    }

    private getCollectionRef() {
        let companyId = this.helperService.currentCompanyId;
        let companyRef = doc(this.companiesRef, companyId);
        let colRef = collection(companyRef, this.slug);
        return colRef;
    }

    async get(id: string) {
        let attachmentResponse = await getDoc(doc(this.getCollectionRef(), id));
        if(!attachmentResponse.exists()){
            return null;
        }
        let attachment: models.Attachment = attachmentResponse.data();
        attachment.id = attachmentResponse.id;

        return attachment;
    }

    async getAll() {
        let attachments = await getDocs(this.getCollectionRef());
        let retVal: models.Attachment[] = [];
        attachments.docs.forEach(doc => {
            let attachment: models.Attachment = doc.data();
            attachment.id = doc.id;
            retVal.push(attachment);
        })

        return retVal;
    }

    async save(attachment: models.Attachment) {
        let colRef = this.getCollectionRef();

        let docRef: DocumentReference;
        if(attachment.id){
            docRef = doc(colRef, attachment.id);
        } else {
            docRef = doc(colRef);
        }

        let fullPath: string = 'docs/{companyId}/attachment/' + docRef.id;
    
        let response = await this.fileService.upload(fullPath, attachment.file);

        delete attachment.file
        delete attachment.id;
        attachment.fullPath = response.metadata.fullPath;
    
        await setDoc(docRef, attachment);

        return docRef.id;
    }

    async delete(attachment: models.Attachment) {
        let colRef = this.getCollectionRef();

        await this.fileService.delete(attachment.fullPath).toPromise();
        let docRef = doc(colRef, attachment.id);
        await deleteDoc(docRef);

        await this.templateService.removeReferenceToAttachment(attachment.id);
        await this.eventService.removeReferenceToAttachment(attachment.id);
    }
}
