import { Timestamp } from "firebase/firestore";

export interface Alert {
    id?: string;
    companyId?: string;
    userId?: string;
    subject?: string;
    message?: string;
    dateAdded?: Timestamp;
    read?: boolean;
    readDate?: Timestamp;
    deleted?: boolean;

    harvestId?: string;
    groupId?: string;
    eventId?: string;
    redirectType?: string;
}
