<h2 mat-dialog-title class="dialog-title">
    {{title}}
</h2>
<mat-dialog-content>
    <iframe 
        width="560" 
        height="315"
        [src]="url | safe" 
        [title]="title" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
    </iframe>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
