import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateCompanyComponent } from 'app/dialogs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, AfterViewInit, OnDestroy {

  companySelectForm: FormGroup;
  retrievedCompanies: boolean = false;

  selectedCompany: models.Company;
  companies: Array<models.Company> = [];

  protected allUsers: models.User[] = [];
  protected users: models.User[] = [];
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<models.User[]> = new ReplaySubject<models.User[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  selectedUser: models.User;
  initialUser: models.User;
  savingUser: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: services.CompanyService,
    private userService: services.UserService,
    private helperService: services.HelperService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.companySelectForm = this.formBuilder.group({
      companies: new FormArray([])
    });
  }

  ngOnInit() {
    this.getCompanies();
    this.getUsers();

    // listen for search field value changes
    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  companyChange(value: models.Company){
    this.selectedCompany = value;

    this.filterUsersForCompany();
  }

  userChange(value: models.User){
    this.selectedUser = value;
    this.initialUser = this.helperService.deepCopy(value);

    this.checkCompanies();
  }

  filterUsersForCompany(){
    if(this.selectedCompany == null){
      this.users = this.allUsers;
    } else {
      let users: models.User[] = this.allUsers.filter((user) => {
        return user.companyIds != null && user.companyIds.includes(this.selectedCompany.uid);
      })
      this.users = users;
    }

    this.filterUsers();
  }

  getCompanies() {
    this.companyService.get().get().subscribe((companyDocList) => {
      companyDocList.docs.forEach(companyDoc => {
        let company: models.Company = companyDoc.data();
        company.uid = companyDoc.id;
        this.companies.push(company);

        let assigned: boolean = false;
        const control = new FormControl(assigned);
        //const control = new FormControl(harvest);
        (this.companySelectForm.controls.companies as FormArray).push(control);
      });

      this.retrievedCompanies = true;
    })
  }

  checkCompanies() {
    if(this.selectedUser == null || this.selectedUser.companyIds == null){
      (this.companySelectForm.controls.companies as FormArray).controls.forEach(c => {
        c.setValue(false);
      });
    } else {

      for (let i = 0; i < this.companies.length; i++) {
        const company = this.companies[i];

        if(this.selectedUser.companyIds.includes(company.uid)){
          (this.companySelectForm.controls.companies as FormArray).controls[i].setValue(true);
        } else {
          (this.companySelectForm.controls.companies as FormArray).controls[i].setValue(false);
        }
      }
    }
  }

  get checkedCompanyIds() {
    const selectedCompanyIds = this.companySelectForm.value.companies
      .map((v, i) => v ? this.companies[i].uid : null)
      .filter(v => v !== null);

      return selectedCompanyIds;
  }

  companiesUpdated(): boolean {

    if(this.selectedUser == null){
      return false;
    }

    let x = JSON.stringify(this.checkedCompanyIds);
    let y = JSON.stringify(this.selectedUser.companyIds || []);
    let companiesUpdated: boolean = x !== y;

    return companiesUpdated;
  }

  userUpdated(): boolean {

    if(this.selectedUser == null){
      return false;
    }
    
    let finish = JSON.stringify(this.selectedUser);
    let start = JSON.stringify(this.initialUser);
    let userUpdated = finish !== start;

    return userUpdated;
  }

  async getUsers() {
    let userResponse = await this.userService.getAllUsers();

    userResponse.docs.forEach((userDoc) => {
      let user: models.User = userDoc.data();
      user.id = userDoc.id;
      this.users.push(user);
      this.allUsers.push(user);
    })

    // set initial selection
    //this.bankCtrl.setValue(this.banks[10]);

    // load the initial bank list
    this.filteredUsers.next(this.users.slice());
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: models.User, b: models.User) => a && b && a.id === b.id;
      });
  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.users.filter(user => {
        return (user.displayName != null && user.displayName.toLowerCase().indexOf(search) > -1) || (user.emailAddress != null && user.emailAddress.toLowerCase().indexOf(search) > -1)
      })
    );
  }

  async saveUserCompanies(unassignEvents: boolean = false) {
    let inObj: any = {
      userId: this.selectedUser.id,
      companyIds: this.checkedCompanyIds,
      unassignEvents: unassignEvents
    }

    this.savingUser = true;

    let response: any = await this.userService.updateUserCompanies(inObj);

    if(response.data.success){
      this.snackBar.open(response.data.message);
      this.savingUser = false;
      this.selectedUser.companyIds = this.checkedCompanyIds;
      return;
    }

    if(response.data.needConfirmation){
      let c = confirm(response.data.message);
      if(c){
        await this.saveUserCompanies(true);
      }
    } else {
      this.snackBar.open(response.data.message);
    }

    this.savingUser = false;
  }

  async saveUser(){
    this.savingUser = true;

    await this.userService.update(this.selectedUser)

    this.initialUser = this.helperService.deepCopy(this.selectedUser);

    this.savingUser = false;
  }
}
