import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-zone-select-multi-btn',
  templateUrl: './zone-select-multi-btn.component.html',
  styleUrls: ['./zone-select-multi-btn.component.scss']
})
export class ZoneSelectMultiBtnComponent implements OnInit {

  zones: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);
  }
  @Output() onClosed = new EventEmitter();

  constructor(
    private zoneService: services.ZoneService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getZones();
  }

  async getZones() {
    let zones = await this.zoneService.getAll()
    let list = zones.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i.id,
        displayValue: i.name ?? '',
        selected: this.selected.includes(i.id)
      }
      return h;
    })
    this.zones = zones;

    this.zones = this.helperService.sortArrayByStringField(list, 'displayValue');
    //this.selected = this.zones.map(i => i.id);
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
