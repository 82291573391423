
export class SensorExport {
  sensorNumber?: string;
  name?: string;
  type?: string;

  constructor(model: SensorExport){
    this.sensorNumber = model.sensorNumber;
    this.name = model.name;
    this.type = model.type;
  }

  public validate?() : boolean {
    if (typeof this.sensorNumber !== 'string' || this.sensorNumber == null) {
      return false;
    }
    if (typeof this.name !== 'string' || this.name == null) {
      return false;
    }
    if (typeof this.type !== 'string' || this.type == null) {
      return false;
    }

    return true;
  }
}