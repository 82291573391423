import { Timestamp } from "@angular/fire/firestore";

export interface DbSensor {
    list?: Array<Sensor>;
}

export interface Sensor {
    id?: string;
    sensorNumber?: string;
    name?: string;
    type?: string;
    zoneId?: string;
    zoneHistory?: Array<SensorZoneHistory>;
}

export interface SensorZoneHistory {
    zoneId?: string;
    dateMovedToZone?: Timestamp
}