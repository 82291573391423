<button mat-raised-button class="gridbtn" *ngIf="canSeeHarvestAdd || canSeeGroupAdd || canSeeAddEventBtn"
    [matMenuTriggerFor]="addmenu" aria-label="Example icon-button with a menu">
    <mat-icon>add</mat-icon>
</button>
<mat-menu #addmenu="matMenu">
    <button mat-menu-item *ngIf="canSeeHarvestAdd" (click)="addHarvest()">
        <mat-icon fontSet="material-symbols-outlined">potted_plant</mat-icon>
        <span>Harvest</span>
    </button>
    <button mat-menu-item *ngIf="canSeeGroupAdd" (click)="addGroup()">
        <mat-icon>splitscreen</mat-icon>
        <span>Task Group</span>
    </button>
    <button mat-menu-item *ngIf="canSeeAddEventBtn" (click)="addEvent()">
        <mat-icon>event</mat-icon>
        <span>Event</span>
    </button>
    <!-- Apply Workflow Button  -->
    <app-apply-workflow-btn [showApplyToGroupButton]="true"></app-apply-workflow-btn>
</mat-menu>