<mat-form-field appearance="outline">
  <mat-label>Range</mat-label>
  <mat-select #selector (selectionChange)="optionChange($event)" [(ngModel)]="selectedOption">
    <mat-option [value]="filterForEventVolumeChart ? 'week' : 'last_week'">{{ filterForEventVolumeChart ? 'Week' : 'Last Week' }}</mat-option>
    <mat-option [value]="filterForEventVolumeChart ? 'month' : 'last_month'">{{ filterForEventVolumeChart ? 'Month' : 'Last Month' }}</mat-option>
    <mat-option *ngIf="showYearly" [value]="filterForEventVolumeChart ? 'year' : 'last_year'">{{ filterForEventVolumeChart ? 'Year' : 'Last Year' }}</mat-option>
    <mat-option *ngIf="!filterForEventVolumeChart && showAllTime" value="all_time">All Time</mat-option>
    <mat-option *ngIf="showCustom" value="custom" (click)="selector.open()">Custom Range</mat-option>
    <div class="custom-date-picker" *ngIf="showCustomRangeInput">
      <mat-date-range-input [rangePicker]="picker" name="dateRange">
          <input matStartDate placeholder="Start" #dateRangeStart readonly>
          <input matEndDate placeholder="End" #dateRangeEnd readonly (dateChange)="onCustomDateRangeChange(dateRangeStart.value, dateRangeEnd.value)">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
  </mat-select>
</mat-form-field>