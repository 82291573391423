import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import * as services from 'app/services';
import * as models from 'app/models';

import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-select-multiple',
  templateUrl: './user-select-multiple.component.html',
  styleUrls: ['./user-select-multiple.component.scss']
})
export class UserSelectMultipleComponent implements OnInit, OnDestroy {

  @Input() disabled = false;
  @Input() displayUserList = false;
  @Input() placeholder: string = 'Select Users';

  loading: boolean = true;
  protected users: models.User[] = [];
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<models.User[]> = new ReplaySubject<models.User[]>(1);
  protected _onDestroy = new Subject<void>();

  // get selectedUsers(): Array<models.User> {
  //   if(this.userIds == null){
  //     return [];
  //   }

  //   return this.users.filter(i => this.userIds.includes(i.id))
  // }
  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    let filteredUsers = this.users.filter(user => {
      return (user.displayName != null && user.displayName.toLowerCase().indexOf(search) > -1) ||
        (user.emailAddress != null && user.emailAddress.toLowerCase().indexOf(search) > -1)
    })
    this.filteredUsers.next(filteredUsers);
  }

  //selectedUsersValue: Array<models.User>;
  @Input()
  get selectedUsers() {
    return this.users.filter(i => this.userIds.includes(i.id));
    //return this.selectedUsersValue;
  }
  @Output() selectedUsersChange = new EventEmitter();
  set selectedUsers(val) {
    if(val == null){
      val = [];
    }
    this.userIds = val.map(i => i.id);
    //this.selectedUsersValue = val;
    //this.setForm();
    this.selectedUsersChange.emit(val);
  }

  userIDsValue: Array<string>;
  @Input()
  get userIds() {
    return this.userIDsValue;
  }
  @Output() userIdsChange = new EventEmitter();
  set userIds(val) {
    if(val == null){
      val = [];
    }
    this.userIDsValue = val;
    this.setForm();
    this.userIdsChange.emit(this.userIDsValue);
    this.selectedUsersChange.emit(this.selectedUsers);
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  companySub: Subscription;

  constructor(
    private userService: services.UserService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    //get companyId
    let companyId = this.helperService.currentCompanyId;

    //if we have it then load
    if(companyId != null){
      this.getUsers(companyId);
    }

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.getUsers(data);
    })

    // listen for search field value changes
    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });
  }

  ngOnDestroy(): void {
    this.companySub.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async getUsers(companyId: string) {
    //go get all users for the company
    let userResponse = await this.userService.getUsersFoCompany(companyId, true);
    if(userResponse.data.success){
      userResponse.data.value.forEach(element => {
        let user: models.User = element;
        user.id = element.id;
        this.users.push(user);
      });

      this.filteredUsers.next(this.users.slice());
      this.setForm();
      this.loading = false;
    }
  }

  setForm() {
    this.userCtrl.setValue(this.users.filter(i => this.userIds.includes(i.id)));
  }

  async setUsers() {
    this.userIds = this.userCtrl.value.map(i => i.id);
  }

  // setForm() {
  //   this.userCtrl.setValue(this.selectedUsers);
  //   //this.userCtrl.setValue(this.users.filter(i => this.userIds.includes(i.id)));
  // }

  // async setUsers() {
  //   this.selectedUsers = this.userCtrl.value;
  // }
}
