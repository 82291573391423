import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZoneAssignComponent } from 'app/dialogs/zone-assign/zone-assign.component';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-phase-accordion',
  templateUrl: './phase-accordion.component.html',
  styleUrls: ['./phase-accordion.component.scss']
})
export class PhaseAccordionComponent implements OnInit {
  
  //Harvest
  harvestValue: models.Harvest;
  @Input()
  get harvest() {
    return this.harvestValue;
  }
  @Output() harvestChange = new EventEmitter();
  set harvest(val) {
    this.harvestValue = val;
    this.harvestChange.emit(this.harvestValue);
  }

  @Input() allStrains: Array<models.Strain> = []
  @Input() canEditHarvest: boolean = false;

  //zones
  zones: Array<models.Zone> = [];
  
  totalPlantCount(phase: models.HarvestPhase): number {
    return phase.strains.map(i => i.count).reduce((p, c) => p + c);
  }

  totalZoneCount(phase: models.HarvestPhase): number {
    let placements: number = 0;

    let applicableStrains = phase.strains.filter(i => i.zones != null && i.zones.length > 0);

    if(applicableStrains.length == 0){
      return null;
    }

    applicableStrains.forEach(strain => {
      strain.zones.forEach(zone => {
        if(zone.count != null){
          placements += zone.count;
        }
      });
    });

    return placements;
  }

  constructor(
    private zoneService: services.ZoneService
    , private helperService: services.HelperService
    , private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getZones();
  }

  async getZones() {
    let zones = await this.zoneService.getAll();
    this.zones = this.helperService.sortArrayByStringField(zones, 'name');
  }

  assignZone(phase: models.HarvestPhase) {
    let inObj = {
      phase: phase
    }
    let dialogRef = this.dialog.open(ZoneAssignComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        phase = result;
      }
    })
  }

}
