import { Timestamp } from '@angular/fire/firestore';
// import { ZonePhase } from '.';
// import { HarvestStrainPhases } from './strain';
import { Template } from './template';

export interface Harvest {
    uid?: string;
    name?: string;
    color?: string;
    dateAdded?: Timestamp;
    completed?: boolean;
    templateId?: string;
    leaflogixName?: string;
    leaflogixId?: string;

    startDate?: Timestamp;
    harvestDate?: Timestamp;
    endDate?: Timestamp;
    completedTasks?: number;
    totalTasks?: number;

    strainIds?: Array<string>;
    phases?: Array<HarvestPhase>;

    // strains?: Array<HarvestStrainPhases>;
    // zones?: Array<ZonePhase>;

    postHarvestData?: any;
}

export interface HarvestPhase {
    phaseName?: string;
    start?: Timestamp;
    end?: Timestamp;

    strains?: Array<HarvestPhaseStrain>;
}

export interface HarvestPhaseStrain {
    strainId?: string;
    strainName?: string;
    count?: number;

    zones?: Array<HarvestPhaseStrainZone>;
}

export interface HarvestPhaseStrainZone {
    zoneId?: string,
    count?: number
}

export class HarvestTabForm {
    formId: string;
    leaflogixId: string;
    leaflogixName: string;
    selectedTemplate: Template;
    name: string;
    color: string;
    cloneCount: number;
    startDate:Date = new Date();
    endDate: Date = new Date();
    startDateTimestamp: Timestamp;
    endDateTimestamp: Timestamp;
    harvestDuration: string;
    strainIds: Array<string> = [];
    harvestPhases: Array<HarvestPhase> = [];
    templates: Array<Template> = [];
    addSuccess: boolean;
    colors: Array<any> = [
      {class: 'red', value: '#C62424'},
      {class: 'green', value: '#009933'},
      {class: 'army-green', value: '#6f7302'},
      {class: 'lite-blue', value: '#0066ff'},
      {class: 'blue', value: '#0000cc'},
      {class: 'mustard', value: '#B9B91C'},
      {class: 'tuqouis', value: '#168D8D'},
      {class: 'lite-purple', value: '#8114ee'},
      {class: 'purple', value: '#51168D'},
      {class: 'orange', value: '#C77A2D'},
    ];
  }