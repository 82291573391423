import { Component, Inject, OnInit } from '@angular/core';

import * as services from '../../services'
import * as models from '../../models'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  working: boolean = false;
  question: string = '';

  constructor(
    private dialogRef: MatDialogRef<HelpComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private questionService: services.QuestionService
    , private claimsService: services.ClaimsService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
  }

  async save() {
    this.working = true;

    let question: models.Question = {
      companyId: this.helperService.currentCompanyId,
      userId: this.claimsService.currentUserId(),
      question: this.question
    }

    await this.questionService.add(question);

    this.working = false;
    this.dialogRef.close(true);
  }

}
