import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';
import { DatePickerDialogComponent } from '../date-picker-dialog/date-picker-dialog.component';
import { SensorZoneTimelineDialogComponent } from '../sensor-zone-timeline-dialog/sensor-zone-timeline-dialog.component';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-sensor-edit',
  templateUrl: './sensor-edit.component.html',
  styleUrls: ['./sensor-edit.component.scss']
})
export class SensorEditComponent implements OnInit {
  isAdd: boolean = false;

  sensor: models.Sensor = {};
  allSensors: Array<models.Sensor> = []
  options: string[];
  initialZoneId: string;

  saveClicked: boolean = false;

  //dataTypes: Array<string> = [];

  constructor(
    private dialogRef: MatDialogRef<SensorEditComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private sensorService: services.SensorService
    , private notifierService: services.NotifierService
    , private dialog: MatDialog
    , private helperService: services.HelperService
  ) {
    if (data) {
      this.isAdd = data.isAdd;
      this.sensor = data.sensor;
      this.allSensors = data.allSensors;
      this.options = data.options;
      this.initialZoneId = this.sensor.zoneId;
    }
  }

  ngOnInit(): void {
  }

  async saveClick() {
    if (this.sensor.name == null || this.sensor.name == '') {
      this.notifierService.error('Sensor Name cannot be empty')
      return;
    }
    if (this.sensor.sensorNumber == null || this.sensor.sensorNumber == '') {
      this.notifierService.error('Sensor Number cannot be empty')
      return;
    }
    if (this.sensor.type == null || this.sensor.type == '') {
      this.notifierService.error('Sensor Type cannot be empty')
      return;
    }

    if (this.initialZoneId != this.sensor.zoneId) {
      //popup to ask about when it was added to zone
      let minDate: Date = null;
      let sortedDates = this.sensor.zoneHistory?.map(i => i.dateMovedToZone).sort() ?? [];
      if (sortedDates.length > 0) {
        minDate = this.helperService.timestampToDate(sortedDates[sortedDates.length - 1])
      }
      let inObj = {
        title: 'When did you move this sensor to the selected Zone?',
        minDate: minDate
      }
      let dialogRef = this.dialog.open(DatePickerDialogComponent, {
        data: inObj
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.sensor.zoneHistory == null) {
            this.sensor.zoneHistory = [];
          }
          let newZoneHistory: models.SensorZoneHistory = {
            zoneId: this.sensor.zoneId,
            dateMovedToZone: Timestamp.fromDate(result)
          }
          this.sensor.zoneHistory.push(newZoneHistory);
          this.save();
        }
      })
    } else {
      this.save();
    }
  }

  async save() {
    this.saveClicked = true;
    if (this.isAdd) {
      this.allSensors.push(this.sensor);
    }

    await this.sensorService.update(this.allSensors);
    this.notifierService.success('Successfully saved Sensor')
    this.dialogRef.close(this.sensor);
  }

  async deleteClick() {
    this.notifierService.confirm('Delete Sensor?', 'Are you sure you want to delete this sensor?  This action will not be able to be undone and will remove all data related to the sensor.',
      () => { this.delete() }, () => { })
  }

  async delete() {
    let sensorsToSave = this.allSensors.filter(i =>
      i.name != this.sensor.name ||
      i.sensorNumber != this.sensor.sensorNumber ||
      i.type != this.sensor.type);

    await this.sensorService.update(sensorsToSave);
    this.notifierService.success('Successfully deleted Sensor')
    this.dialogRef.close(this.sensor);
  }

  timeline() {
    let inObj = {
      history: this.sensor.zoneHistory,
    }
    let dialogRef = this.dialog.open(SensorZoneTimelineDialogComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sensor.zoneHistory = result;
      }
    })
  }

}
