import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "icon-cell",
    template: `
    <button mat-icon-button class="badge-btn" (click)="click()" [matBadge]="badge" [matBadgeHidden]="badge===0" style="width: 25px; height: 25px; line-height: 15px;">
        <mat-icon aria-hidden="false" style="vertical-align: middle">{{icon}}</mat-icon>
    </button>
    `,
    styles: [
        `
        ::ng-deep .badge-btn.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: -6px;
        }
        `
    ]
})
export class IconComponent implements ICellRendererAngularComp {
    private params: any;

    icon: string;
    badge: number = 0;
    func: any;

    agInit(params: any): void {
        this.setData(params);
    }

    refresh(params: any): boolean {
        this.setData(params);
        return true;
    }

    setData(params: any){
        this.params = params;
        this.icon = this.params.icon;
        this.badge = this.params.badge;
        this.func = this.params.func;
    }

    click() {
        this.func(this.params.data);
        //this.refresh(this.params);
    }
}
