<h2 mat-dialog-title *ngIf="saveAsGenericGroup">{{dialogTitle}}</h2>
<mat-tab-group class="harvest-add-dialog" [(selectedIndex)]="selectedHarvestTab" *ngIf="!saveAsGenericGroup">
  <mat-tab *ngFor="let harvestForm of harvestFormDataArray; let i = index" [label]="harvestForm.name" [disabled]="harvestForm.addSuccess">
    <ng-template mat-dialog-title mat-tab-label>
      <div style="display: flex; align-items: center;">
        <span class="harvest-label-name">{{harvestForm.name && harvestForm.name.length > 0 ? harvestForm.name : 'Harvest Name'}}</span>
        <button mat-icon-button class="small-icon-button" *ngIf="leaflogixId == null" (click)="deleteHarvestClick(i)" style="margin-left: 10px;">
          <mat-icon aria-hidden="false" aria-label="Remove Harvest">delete</mat-icon>
        </button>
      </div>
    </ng-template>
    <mat-dialog-content class="mt-2" style="width: 100%; overflow: initial;">
      <form id="addHarvest" class="harvest-form" (ngSubmit)="save()">
        <!-- <mat-checkbox [(ngModel)]="saveAsGenericGroup" name="saveAsGroup">Create Generic Group</mat-checkbox> -->
    
        <div class="row">
          <div [class]="mainPanelClass">
    
            <!-- Select a Template -->
            <mat-form-field style="max-width: 600px" *ngIf="!saveAsGenericGroup">
              <mat-label>Select a Template</mat-label>
              <mat-select [(ngModel)]="harvestForm.selectedTemplate" name="selectedTemplate" (ngModelChange)="templateChange($event, harvestForm.formId)">
                <mat-option [value]="t" *ngFor="let t of harvestForm.templates">{{t.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Name of Harvest or Group -->
            <mat-form-field class="full-width">
              <input id="harvestAdd-name" matInput [placeholder]="namePlaceHolder" type="text" required name="name" [(ngModel)]="harvestForm.name">
            </mat-form-field>
            
            <!-- Date the Harvest should start on -->
            <mat-form-field *ngIf="!saveAsGenericGroup">
              <input matInput [matDatepicker]="startDatePicker" placeholder="Choose a start date" name="startDate" [(ngModel)]="harvestForm.startDate">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
            
            <!-- Color the Harvest or Group will be -->
            <app-color-picker [saveAsGenericGroup]="saveAsGenericGroup" [(color)]="harvestForm.color"></app-color-picker>
    
          </div>
          <div [class]="strainPanelClass" *ngIf="!saveAsGenericGroup" [hidden]="allStrains.length == 0 || leaflogixId != null">
            <app-strain-select-multiple-for-harvest 
              [(allStrains)]="allStrains"
              [(strainIds)]="harvestForm.strainIds"
              [(harvestPhases)]="harvestForm.harvestPhases">
            </app-strain-select-multiple-for-harvest>
            <!-- <app-strain-select-multiple [(strains)]="allStrains" [(selected)]="strains"></app-strain-select-multiple> -->
    
            <ul *ngIf="harvestForm.harvestPhases[0] != null">
              <li *ngFor="let strain of harvestForm.harvestPhases[0].strains">
                <div class="flex-container">
                  <div class="flex-child-one">
                    {{strain.strainName}}
                  </div>
                  <div class="flex-child-two">
                    <mat-form-field class="user-full-width">
                      <input matInput placeholder="Count" type="number" [(ngModel)]="strain.count" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                  </div>
                </div>
                
              </li>
            </ul>
          
          </div>      
          <div [class]="mainPanelClass" *ngIf="!saveAsGenericGroup">
            <app-harvest-phase-timeline [startDate]="harvestForm.startDate" [template]="harvestForm.selectedTemplate" (timelineList)="timelineListHandler($event, harvestForm.formId)"></app-harvest-phase-timeline>
          </div>
          <!-- <div [class]="zonePanelClass" *ngIf="!saveAsGenericGroup && zones.length > 0">
            <ng-container *ngFor="let pz of phaseZones">
              <div style="margin-bottom: 10px;" class="row">
                <div class="col-12">
                  <span style="float:left;">{{pz.phaseName}}:</span>
      
                  <button style="float:right;" type="button" mat-raised-button (click)="assignZone(pz)">
                    Assign Zones
                  </button>
                </div>
              </div>
              <div style="margin-bottom: 10px;" class="row" *ngIf="pz?.zoneIds != null">
                <div class="col-12">
                  <app-zone-identifiers [zones]="zones" [selectedIds]="pz.zoneIds"></app-zone-identifiers>
                </div>
              </div>
            </ng-container>
          </div> -->
        </div>
    
        <!-- This has been removed for simplicity of use.  Put it back in if we want them to choose colors more freely. -->
        <!-- <mat-form-field class="user-full-width">
          <input matInput placeholder="Harvest Color" name="color" [value]="color" [(colorPicker)]="color" [style.background]="color"/>
        </mat-form-field> -->
    
      </form>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab disabled title="Add Harvest" *ngIf="leaflogixId == null">
    <ng-template mat-tab-label>
        <button id="harvest_tabAdd" mat-icon-button (click)="addHarvestTab()">
            <mat-icon>add_circle</mat-icon>
        </button>
    </ng-template>
    <mat-dialog-content style="width: 100%; height: 100%;">

    </mat-dialog-content>
  </mat-tab>
</mat-tab-group>
<mat-dialog-content *ngIf="saveAsGenericGroup">
  <form id="addHarvest" class="harvest-form" (ngSubmit)="save()">
    <!-- <mat-checkbox [(ngModel)]="saveAsGenericGroup" name="saveAsGroup">Create Generic Group</mat-checkbox> -->

    <div class="row">
      <div [class]="mainPanelClass">

        <!-- Select a Template -->
        <!-- <mat-form-field style="max-width: 600px" *ngIf="!saveAsGenericGroup">
          <mat-label>Select a Template</mat-label>
          <mat-select [ngModel]="selectedTemplate" name="template" (ngModelChange)="templateChange($event)">
            <mat-option [value]="t" *ngFor="let t of templates">{{t.name}}</mat-option>
          </mat-select>
        </mat-form-field> -->
      
        <!-- Name of Harvest or Group -->
        <mat-form-field class="user-full-width">
          <input id="harvestAdd-name" matInput [placeholder]="namePlaceHolder" type="text" required name="name" [(ngModel)]="name">
        </mat-form-field>
        
        <!-- Date the Harvest should start on -->
        <!-- <mat-form-field *ngIf="!saveAsGenericGroup">
          <input matInput [matDatepicker]="startDatePicker" placeholder="Choose a start date" name="startDate" [(ngModel)]="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field> -->
        
        <!-- Color the Harvest or Group will be -->
        <!-- <mat-form-field> -->
          <app-color-picker [saveAsGenericGroup]="saveAsGenericGroup" [(color)]="color"></app-color-picker>
  
          <!-- <mat-radio-group aria-label="Select an option" [class.group]="saveAsGenericGroup" [(ngModel)]="color" name="color-radio">
            <mat-radio-button *ngFor='let c of colors' [className]="c.class" style="width: unset" [value]="c.value"></mat-radio-button>
          </mat-radio-group> -->
        <!-- </mat-form-field> -->
        <div class="row">
          <mat-form-field class="col-12 mt-2">
            <mat-label>Apply a Workflow</mat-label>
            <mat-select [(ngModel)]="selectedSecondaryTemplate" name="secondaryTemplate-select" (selectionChange)="navigateToTemplates($event)">
              <mat-option [ngClass]="{'full-option-text': secondaryTemplate.name.length > 20}" [value]="secondaryTemplate" *ngFor="let secondaryTemplate of secondaryTemplates">
                  {{secondaryTemplate.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>  
        
        <div class="row" *ngIf="selectedSecondaryTemplate && selectedSecondaryTemplate.name !== 'Don\'t Apply Template'">
          <mat-form-field class="col-12 mt-2">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Choose a start date" name="startDate" [(ngModel)]="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>     
      <!-- <div [class]="mainPanelClass" *ngIf="!saveAsGenericGroup">
        <app-harvest-phase-timeline [startDate]="startDate" [template]="selectedTemplate"></app-harvest-phase-timeline>
      </div> -->
      <!-- <div [class]="zonePanelClass" *ngIf="!saveAsGenericGroup && zones.length > 0">
        <ng-container *ngFor="let pz of phaseZones">
          <div style="margin-bottom: 10px;" class="row">
            <div class="col-12">
              <span style="float:left;">{{pz.phaseName}}:</span>
  
              <button style="float:right;" type="button" mat-raised-button (click)="assignZone(pz)">
                Assign Zones
              </button>
            </div>
          </div>
          <div style="margin-bottom: 10px;" class="row" *ngIf="pz?.zoneIds != null">
            <div class="col-12">
              <app-zone-identifiers [zones]="zones" [selectedIds]="pz.zoneIds"></app-zone-identifiers>
            </div>
          </div>
        </ng-container>
      </div> -->
    </div>

    <!-- This has been removed for simplicity of use.  Put it back in if we want them to choose colors more freely. -->
    <!-- <mat-form-field class="user-full-width">
      <input matInput placeholder="Harvest Color" name="color" [value]="color" [(colorPicker)]="color" [style.background]="color"/>
    </mat-form-field> -->

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClick">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button id="harvestAdd-save" mat-button type="submit" form="addHarvest" [disabled]="saveClick">
    <mat-spinner *ngIf="saveClick" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClick && !saveAsGenericGroup">Review and Save</span>
    <span *ngIf="!saveClick && saveAsGenericGroup">Save</span>
  </button>
</mat-dialog-actions>
