import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { HelperService } from './helper.service';
import * as models from '../models';
import * as viewModels from '../viewmodels';
import { SessionStorageService } from 'angular-web-storage';

@Injectable()
export class CompanyService {

    constructor(
        private firestore: AngularFirestore,
        private functions: Functions,
        private helperService: HelperService,
        private storage: SessionStorageService) {
    }

    get(): AngularFirestoreCollection {
        let companiesResponse = this.firestore.collection('companies', ref => ref.orderBy('name'));

        return companiesResponse;
    }

    async getCurrent() {
        let companyId = this.helperService.currentCompanyId;
        return this.getById(companyId);
    }

    async getById(companyId: string, hydrated: boolean = false): Promise<models.Company> {
        let document = this.firestore.collection('companies').doc(companyId);
        let companyResponse = await document.get().toPromise();
        let retVal: models.Company = companyResponse.data();
        retVal.uid = companyResponse.id;
        if(hydrated){
            retVal.notifications = [];
            let notificationsSS = await document.collection('notifications').get().toPromise();

            notificationsSS.forEach(i => {
                let notification: models.CompanyNotification = i.data();
                notification.uid = i.id;
                retVal.notifications.push(notification);
            })
        }
        return retVal;
    }

    async getLastActive(companyId: string) {
        let inObj = {
            companyId: companyId
        }

        let method = httpsCallable(this.functions, 'getCompanyLastActive');
        let response = await method(inObj);

        let retVal: models.Result<any> = response.data;

        return retVal;
    }

    async upgrade(id: string, stripeCustomerId: string){
        let companyUpdate: models.Company = {
            stripeCustomerId: stripeCustomerId,
            freeVersion: false
        }
        await this.firestore.collection('companies').doc(id).update(companyUpdate);
        this.helperService.company_freeVersion = false;
    }

    // async cancelSubscription(id: string){
    //     let companyUpdate: models.Company = {
    //         stripeSubscriptionCancelled: true
    //     }
    //     await this.firestore.collection('companies').doc(id).update(companyUpdate);
    //     this.helperService.company_subscriptionCancelled = true;
    // }

    async savePartial(id: string, partial: models.Company) {
        await this.firestore.collection('companies').doc(id).update(partial);
    }

    async save(company: models.Company) {

        //set up object to save
        let companyId: string = company.uid;
        let notifications = company.notifications;
        delete company.uid;
        delete company.notifications;

        //save company
        let document = this.firestore.collection('companies').doc(companyId);
        await document.update(company);

        let notificationsCol = document.collection('notifications');
        let notificationsSS = await notificationsCol.get().toPromise();
        let currentIds = notificationsSS.docs.map(i => i.id);

        for (let i = 0; i < notifications.length; i++) {
            const notification = notifications[i];
            if(notification.uid != null){
                await notificationsCol.doc(notification.uid).update(notification);
                const index = currentIds.indexOf(notification.uid);
                if (index > -1) {
                    currentIds.splice(index, 1);
                }
            } else {
                await notificationsCol.add(notification);
            }
        }

        for (let i = 0; i < currentIds.length; i++) {
            const id = currentIds[i];
            await notificationsCol.doc(id).delete();
        }
    }

    async getAllCompaniesForUser() : Promise<models.Result<Array<models.Company>>> {

        let getListOfCompaniesForCurrentUserMethod = httpsCallable(this.functions, 'getListOfCompaniesForCurrentUser');
        let getListOfCompaniesForCurrentUserResponse = await getListOfCompaniesForCurrentUserMethod({});

        let retVal: models.Result<Array<models.Company>> = getListOfCompaniesForCurrentUserResponse.data;

        return retVal;
    }

    async createDemoCompany(data: models.CompanyCreate) {
      let createCompanyMethod = httpsCallable(this.functions, 'createCompany')

      let createCompanyResponse = await createCompanyMethod(data);

      let retVal: any = createCompanyResponse.data;

      return retVal;
    }

    async delete(companyId: string) {
        let inObj = {
            companyId: companyId
        }

        let method = httpsCallable(this.functions, 'deleteCompany');
        let response = await method(inObj);

        let retVal: models.Result<string> = response.data;

        return retVal;
    }

    async getCompaniesWithDetails() : Promise<models.Result<Array<viewModels.CompanyViewModel>>> {

        let method = httpsCallable(this.functions, 'getCompaniesWithDetails');
        let response = await method({});

        let retVal: models.Result<Array<viewModels.CompanyViewModel>> = response.data;

        return retVal;
    }
}
