import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CellDoubleClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { TemplateUploadComponent } from 'app/dialogs';
import { RestrictionNotificationComponent } from 'app/dialogs/restriction-notification/restriction-notification.component';
import { TemplateAddComponent } from 'app/dialogs/template-add/template-add.component';

import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import * as services from 'app/services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-template-grid',
  templateUrl: './template-grid.component.html',
  styleUrls: ['./template-grid.component.scss']
})
export class TemplateGridComponent implements OnInit {

  @ViewChild('templateList') templateList: ElementRef;

  @Input() templates: viewmodels.TemplateListViewModel[];
  @Input() gridTitle: string;
  @Input() selectedTemplate: string;
  @Input() tooltipContent: string;
  @Input() displayHarvestsCreated: boolean = true;

  public gridOptions: GridOptions;

  private allPossibleColumns : Array<ColDef> = [
    {
        headerName: "Name",
        field: "name"
        //pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    {
        headerName: "Phases",
        field: "phases"
        //pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    {
        headerName: "Events",
        field: "events"
        //pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    {
        headerName: "Harvests Created",
        field: "harvestsCreated"
        //pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    {
        headerName: "Duration (Days)",
        field: "duration"
        //pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    // {
    //     headerName: "Clone Date",
    //     field: "startDate", 
    //     cellRenderer: "timestampRenderer", 
    //     sortable: true, resizable: true,
    //     filter: 'agDateColumnFilter',
    // },
    // {
    //     headerName: "Harvest Date",
    //     field: "endDate",
    //     cellRenderer: "timestampRenderer",
    //     sortable: true, resizable: true,
    //     filter: 'agDateColumnFilter',
    // }
  ];

  constructor(
    private helperService: services.HelperService
    , private deviceService: DeviceDetectorService
    , private router: Router
    , private harvestService: services.HarvestService
  ) {
    this.gridOptions = <GridOptions>{
      context: {
          componentParent: this
      },
      //rowData: this.templates,
      //columnDefs: this.createColumnDefs(),
      rowHeight: 35,
      onGridReady: () => {
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      // frameworkComponents: {
      //     timestampRenderer: TimeStampComponent,
      // },
      rowSelection: 'multiple',
      onCellDoubleClicked: this.selectedCell.bind(this),
      defaultColDef: {
        sortable: true, resizable: true,
      },
    };
   }

  ngOnInit(): void {
    if(!this.displayHarvestsCreated) {
      this.allPossibleColumns = this.allPossibleColumns.filter(i => i.field != 'harvestsCreated');
    }
  }

  ngAfterViewInit(): void {
    this.setUpColumns();
  }

  setUpColumns() {
    let currentCols = this.allPossibleColumns;

    if(this.gridOptions != null && this.gridOptions.api != null){
      this.gridOptions.api.setColumnDefs(currentCols);
      this.gridOptions.columnApi.autoSizeAllColumns();
    }

    this.resizeCols();
  }

  async resizeCols() {
    if(!this.deviceService.isMobile() && this.gridOptions != null){
      await this.helperService.wait(10);
      const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
      this.gridOptions.columnApi.autoSizeColumns(colIds);
      await this.helperService.wait(10);

      let columns = this.gridOptions.columnApi.getAllDisplayedColumns()
      let widthOfCols = 0;
      columns.forEach(col => {
        widthOfCols += col.getActualWidth();
      });
      if(this.templateList.nativeElement.clientWidth < widthOfCols) {
        const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
        this.gridOptions.columnApi.autoSizeColumns(colIds)
      } else {
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  }
  
  selectedCell(event: CellDoubleClickedEvent) {
    let templateId: string = event.data.id;

    this.router.navigate(['console', 'template', templateId]);
  }

  exportAsCSV() {
    let params: any = {};
    params.processCellCallback = (params) => {
      if (params.value instanceof Timestamp) {
        return this.helperService.timestampToDateString(params.value);
      } else {
        return params.value;
      }
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }
}
