import { Injectable } from '@angular/core';
import * as models from '../models';
import { CollectionReference, Firestore, collection, doc, getDoc, getDocs } from '@angular/fire/firestore';
import { SessionStorageService } from 'angular-web-storage';
import { HelperService } from './helper.service';
import { deleteDoc, setDoc } from 'firebase/firestore';
import { UserService } from './user.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class RoleService {

  companiesRef: CollectionReference;
  
  constructor(
    private firestore: Firestore
    , private helperService: HelperService
    , private storage: SessionStorageService
    , private userService: UserService
    , private functions: Functions
  ) {
    this.companiesRef = collection(this.firestore, 'companies');
  }

  async getRoles() : Promise<models.Role[]> {
    let companyId = this.helperService.currentCompanyId;
    let cacheKey = 'comp-roles-' + companyId;

    let roles: Array<models.Role> = this.storage.get(cacheKey);
    if(roles === null || roles.length == 0){
      roles = await this.get(companyId);
      this.storage.set(cacheKey, roles, 12, 'h') //expire after 12 hours
    }
    return roles;
  }

  async getById(roleId: string): Promise<models.Role> {
    let companyId = this.helperService.currentCompanyId;
    let rolesDocRef = doc(this.companiesRef, companyId, 'roles', roleId);
    let snapShot = await getDoc(rolesDocRef);
    let role: models.Role = snapShot.data();
    role.id = snapShot.id;
    return role;
  }

  async get(companyId: string): Promise<Array<models.Role>> {
    let rolesColRef = collection(this.companiesRef, companyId, 'roles');
    let snapShot = await getDocs(rolesColRef);
    let roles: Array<models.Role> = [];

    snapShot.docs.forEach(doc => {
        let role: models.Role = doc.data();
        role.id = doc.id;
        roles.push(role);
    });

    return roles;
  }

  async getByIdAndCompany(roleId: string, companyId: string): Promise<models.Role> {
    let rolesDocRef = doc(this.companiesRef, companyId, 'roles', roleId);
    let snapShot = await getDoc(rolesDocRef);

    if(!snapShot.exists()){
      return null;
    }

    let role: models.Role = snapShot.data();
    role.id = snapShot.id;
    return role;
  }

  async save(role: models.Role, companyId: string): Promise<models.Result<string>> {
    let retVal: models.Result<string> = new models.Result<string>();

    try {
      let id: string = role.id;
      if(id == null) {
        id = this.helperService.removeSpacesAndLowercaseFirstLetter(role.name);
  
        let existingRef = doc(this.companiesRef, companyId, 'roles', id);
        let snapShot = await getDoc(existingRef);
        if(snapShot.exists()){
          retVal.success = false;
          retVal.addBrokenRule('Duplicate Role', 'Cannot save a Role with the same name as another.  Please specify another name.', true);
          return retVal;
        }
      }
  
      let roleRef = doc(this.companiesRef, companyId, 'roles', id);
      await setDoc(roleRef, role);

      retVal.value = id;
      this.storage.remove('comp-roles-' + companyId) //remove cache value after save
  
    } catch(ex) {
      let error = (ex as Error);
      retVal.success = false;
      retVal.error = JSON.stringify(error);
      retVal.message = error.message;
      retVal.addBrokenRule('Duplicate Role', error.message, true);
    }

    return retVal;
  }

  async delete(roleId: string): Promise<models.Result<string>> {
    let retVal: models.Result<string> = new models.Result<string>();

    try {
      let companyId: string = this.helperService.currentCompanyId;
      let userResponse = await this.userService.getUsersForCompanyWithCaching(companyId, false, false)
  
      let usersWithRole = userResponse.filter(i => i.roleId == roleId);
      if(usersWithRole.length > 0) {
        retVal.success = false;
        retVal.addBrokenRule('ActiveRole', 'Cannot delete active Roles. Please reassign all users before attempting to delete Role.', true);
        return retVal;
      }

      let roleRef = doc(this.companiesRef, companyId, 'roles', roleId);
      await deleteDoc(roleRef)

      this.storage.remove('comp-roles-' + companyId) //remove cache value after save

    } catch(ex) {
      let error = (ex as Error);
      retVal.success = false;
      retVal.error = JSON.stringify(error);
      retVal.message = error.message;
      retVal.addBrokenRule('Delete Role', error.message, true);
    }

    return retVal;

  }

  async getRoleCompaniesByUser(roleId: string): Promise<models.Result<models.Company[]>> {
    let method = httpsCallable<any, models.Result<models.Company[]>>(this.functions, 'getRoleCompaniesByUser');
    var response = await method(roleId);

    return response.data;
  }

}
