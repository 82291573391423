import { Component } from "@angular/core";
import { Timestamp } from "@angular/fire/firestore";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "phase-date-range-cell",
    template: `
    <div *ngIf="showCell">
        <span>{{ date.seconds * 1000 | date:'MM/dd/yyyy' }}</span>
    </div>
    `,
    styles: [
    ]
})
export class PhaseDateRangeCellComponent implements ICellRendererAngularComp {
    private params: any;

    date: Timestamp;
    showCell: boolean = false;

    constructor() { }

    agInit(params: any): void {
        if (!params.value) {
            return;
        }

        this.params = params;

        this.date = this.params.value.startDate;

        if (this.date) {
            this.showCell = true;
        }
    }

    refresh(params: any): boolean {
        return false;
    }
}