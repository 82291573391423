<div class="row" style="padding-bottom: 10px;">
    <div class="col-sm-12">
        <div style="display: flex; align-items: center;">
            <h4 style="margin-top: 7px;">{{ gridTitle }}</h4>
            <mat-icon 
                matTooltipPosition="right" 
                aria-label="Templates Help" 
                [matTooltip]="tooltipContent"
                matTooltipClass="templates_help"
                class="helperContent">help_outline
            </mat-icon>
        </div>
    </div>
</div>
<div class="row" #templateList style="padding-bottom: 20px;">
    <ag-grid-angular style="width: 100%; min-height:200px; height: calc(30vh);" class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="templates">
    </ag-grid-angular>
</div>
