import { Injectable } from '@angular/core';
import { 
  GoogleAuthProvider,
  signInWithPopup, 
  Auth, 
  UserCredential, 
  signInWithEmailAndPassword, 
  Persistence, 
  setPersistence, 
  browserLocalPersistence, 
  signOut, 
  sendPasswordResetEmail, 
  OAuthProvider} from '@angular/fire/auth';
import { SessionStorageService } from 'angular-web-storage';

import { Observable } from 'rxjs';

import * as models from '../models';
import * as enums from '../models/enumerations';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { HelperService } from './helper.service';

@Injectable()
export class AuthService {

  constructor(
    private auth: Auth, 
    private storage: SessionStorageService,
    private functions: Functions,
    private helperService: HelperService
  ) {
    console.log('Hello AuthService Provider');
  }

  async signIn(email: string, password: string): Promise<UserCredential> {
    //await setPersistence(this.auth, browserLocalPersistence)

    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async signInViaGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  async signInViaMicrosoft() {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({'prompt': 'select_account'});
    return signInWithPopup(this.auth, provider);
  }

  async signOut() : Promise<void>{
    return signOut(this.auth);
  }

  async sendPasswordResetLink(email: string) : Promise<void> {
    
    let domain = document.location.origin;
    let emaiResponse = sendPasswordResetEmail(this.auth, email, {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: domain,
      handleCodeInApp: false
    });
    // let emaiResponse = await firebase.auth().sendSignInLinkToEmail(email, {
    //   // After password reset, the user will be give the ability to go back
    //   // to this page.
    //   url: domain,
    //   handleCodeInApp: true
    // });

    return emaiResponse;
  }

  async sendWelcomeEmail(email: string) {
    let domain = document.location.origin;
    let lowerCase = email.toLowerCase();
    let inObj = {
      email: lowerCase,
      domain: domain
    }
    const sendWelcomeEmailMethod = httpsCallable(this.functions, 'sendWelcomeEmail');
    var sendWelcomeEmailResponse = await sendWelcomeEmailMethod(inObj);

    return sendWelcomeEmailResponse;
  }

  async enableUsers(users: Array<models.User>){
    const enableUsersMethod = httpsCallable<any, models.Result<any>>(this.functions, 'enableUsers');
    var enableUsersResponse = await enableUsersMethod(users);

    return enableUsersResponse;
  }

  async disableUsers(users: Array<models.User>){
    var disableUsersMethod = httpsCallable<any, models.Result<any>>(this.functions, 'disableUsers');
    var disableUsersResponse = await disableUsersMethod(users);

    return disableUsersResponse;
  }

  async deleteUsers(request: any){
    var method = httpsCallable<any, models.Result<any>>(this.functions, 'deleteUsers');
    var response = await method(request);

    return response;
  }

  async setUserRole(role: string, email: string){
    let inObj = {
      email: email,
      role: role,
      companyId: this.helperService.currentCompanyId
    }

    let methodToCall = httpsCallable(this.functions, 'updateRole');
    var response = await methodToCall(inObj);
  
    return response;
  }
}
