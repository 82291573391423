import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as models from '../../models';
import * as services from '../../services';
import * as viewmodels from '../../viewmodels';
import { Subscription } from 'rxjs';
import { HttpsCallableResult } from '@angular/fire/functions';

@Component({
  selector: 'app-harvest-group-delete',
  templateUrl: './harvest-group-delete.component.html',
  styleUrls: ['./harvest-group-delete.component.scss']
})
export class HarvestGroupDeleteComponent implements OnInit, OnDestroy {

  // Form Groups
  //userIdsForm: FormGroup;
  //harvestGroupForm: FormGroup;
  harvests: Array<viewmodels.MultiSelect> = [];
  hideHarvests: boolean = false;
  groups: Array<viewmodels.MultiSelect> = [];
  hideGroups: boolean = false;

  deleting: boolean = false;

  // //subscriptions
  // harvestSub: Subscription;
  // groupSub: Subscription;

  // retrievedHarvests: boolean = false;
  // retrievedGroups: boolean = false;

  get harvestCol() {
    if(this.hideGroups) {
      return 'col-sm-12';
    }
    return 'col-sm-6';
  }

  get groupCol() {
    if(this.hideHarvests) {
      return 'col-sm-12';
    }
    return 'col-sm-6';
  }
  
  constructor(
    private dialogRef: MatDialogRef<HarvestGroupDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    //private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private harvestService: services.HarvestService,
    private groupService: services.GroupService,
    private notiferService: services.NotifierService
  ) { 
    // this.harvestGroupForm = this.formBuilder.group({
    //   harvests: new FormArray([]),
    //   groups: new FormArray([])
    // });
    if(data) {
      this.hideHarvests = data.hideHarvests;
      this.hideGroups = data.hideGroups;
    }
  }

  ngOnInit() {
    // this.getHarvests();
    // this.getGroups();
  }

  ngOnDestroy() {
    // this.harvestSub.unsubscribe();
    // this.groupSub.unsubscribe();
  }

  // async getHarvests() {
  //   this.harvestSub = (await this.harvestService.getAll()).get().subscribe(harvestResponse => {
  //     harvestResponse.forEach(doc => {
  //       let harvest: models.Harvest = doc.data();
  //       harvest.uid = doc.id;
  //       this.harvests.push(harvest);
  
  //       let assigned: boolean = false;
  //       const control = new FormControl(assigned);
  //       //const control = new FormControl(harvest);
  //       (this.harvestGroupForm.controls.harvests as FormArray).push(control);
  //     });

  //     this.retrievedHarvests = true;
  //   });
  // }

  // getGroups() {
  //   this.groupSub =  this.groupService.getAll().get().subscribe(groupResponse => {
  //     groupResponse.forEach(doc => {
  //       let group: models.Group = doc.data();
  //       group.uid = doc.id;
  //       this.groups.push(group);
  
  //       let assigned: boolean = false;
  //       const control = new FormControl(assigned);
  //       //const control = new FormControl(harvest);
  //       (this.harvestGroupForm.controls.groups as FormArray).push(control);
  //     });

  //     this.retrievedGroups = true;
  //   });
  // }

  /**
   * method that is called to submit form
   */
  deleteClick() {

    // const harvestIds: Array<string> = this.harvestGroupForm.value.harvests
    //   .map((v, i) => v ? this.harvests[i].uid : null)
    //   .filter(v => v !== null);
    const harvestIds: Array<string> = this.harvests.map(i => i.id);

    // const groupIds: Array<string> = this.harvestGroupForm.value.groups
    //   .map((v, i) => v ? this.groups[i].uid : null)
    //   .filter(v => v !== null);
    const groupIds: Array<string> = this.groups.map(i => i.id);

    if(harvestIds.length === 0 && groupIds.length === 0){
      this.snackBar.open('Must select at least one Harvest or Group to continue.');
      return;
    }

    let confirmationText = `Are you sure you want to delete`;
    if(!this.hideHarvests){
      confirmationText += ` ${harvestIds.length} Harvest(s)`;
    }
    if(!this.hideHarvests && !this.hideGroups) {
      confirmationText += ` and`;
    }
    if(!this.hideGroups){
      confirmationText += ` ${groupIds.length} Group(s)`;
    }
    confirmationText += `?  This will delete them permanently.`;

    this.notiferService.confirm('Delete?', confirmationText, 
      () => {this.delete(harvestIds, groupIds)},
      () => {});
  }

  delete(harvestIds: string[], groupIds: string[]) {
    this.deleting = true;

    let methods: Promise<HttpsCallableResult<any>>[] = [];
    
    if(harvestIds.length != 0){
      let harvestPromise = this.harvestService.deleteList(harvestIds);
      methods.push(harvestPromise)
    }

    if(groupIds.length != 0){
      let groupPromise = this.groupService.deleteList(groupIds);
      methods.push(groupPromise)
    }
    
    Promise.all(methods).then((values) => {
      let message: string = '';
      if(harvestIds.length != 0){
        let harvestResponse = values[0];
        message = harvestResponse.data.message;
      }

      let groupResponse;
      if(groupIds.length != 0){
        if(harvestIds.length != 0){
          message += '\n';
          groupResponse = values[1];
        } else {
          groupResponse = values[0];
        }
      }

      if(groupResponse != null)
        message += groupResponse.data.message

      this.snackBar.open(message);

      this.deleting = false;
      this.dialogRef.close({
        harvestIds: harvestIds,
        groupIds: groupIds
      });
    }).catch(error => {
        this.snackBar.open('An Error as occured.');
        console.log(JSON.stringify(error))
  
        this.deleting = false;
    });
  }
}
