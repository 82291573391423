<div class="loading-container" *ngIf="!retrievedInitial || gettingEvents">
    <div class="content">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</div>

<div class="row" style="padding-bottom: 10px;">
    <div class="col-sm-10">         
        <app-filter-section forAnalytics="false" [events]="events" (onFilter)="onFilter($event)" (initialLoad)="initialLoadFromFilter($event)"></app-filter-section>
    </div>
    <div class="col-sm-2">
        <div class="right">            
            <mat-slide-toggle 
                *ngIf="canSeeWeeklyListSlider" 
                [(ngModel)]="weeklyListView" 
                class="gridbtn" 
                (change)="toggleListViewChange($event)">
                    List View
            </mat-slide-toggle>

            <app-add-menu-btn 
                (addedHarvest)="addedHarvest($event)" 
                (addedGroup)="addedGroup($event)" 
                (addEventClick)="addEvent($event)">
            </app-add-menu-btn>

            <app-calendar-more-menu-btn 
                (addedHarvests)="addedHarvests($event)"
                (deletedHarvestsAndGroups)="deletedHarvestsAndGroups($event)">
            </app-calendar-more-menu-btn>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <full-calendar #calendar [options]="calendarOptions"></full-calendar>
    </div>
</div>