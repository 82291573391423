import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
//import * as equal from 'deep-equal';
//const equal = require('deep-equal');
//import deepEqual from 'deep-equal';
import * as equal from 'fast-deep-equal';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
import { ColDef, ColumnApi, CsvExportParams, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewModels from 'app/viewmodels';
import * as cells from 'app/components/cell-components';

import {
  TimeStampComponent,
  ListComponent,
  HighPriortyIconComponent,
  TagComponent,
  UnitCellComponent,
  MatCheckboxComponent
} from '../../cell-components';

import {
  EventUpdateComponent,
  EventAddComponent,
} from '../../../dialogs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from 'angular-web-storage';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxHeaderComponent } from 'app/components/grid/header-checkbox.component';
import { Timestamp } from '@angular/fire/firestore';
import { Unsubscribe, onSnapshot } from 'firebase/firestore';
import { Query, DocumentData } from '@angular/fire/firestore';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  //Input/Output
  @Input() collectionType: string; //db collection target in firebase (either 'harvests' or 'groups')
  @Input() collectionName: string;
  @Input() collectionId: string;
  @Input() company: models.Company;

  dbEvent: models.DbEvent;
  @Output() eventListChange = new EventEmitter();
  events: models.Event[] 

  gridList: viewModels.EventTableViewModel[] = [];
  initialGridList: viewModels.EventTableViewModel[] = [];

  //variables
  loading: boolean = false;
  users: Array<models.User>;
  teams: Array<models.Team>;
  pageLoaded: boolean = false;
  eventId: string;

  //subscriptions
  eventSub: Unsubscribe;
  querySub: Subscription;

  //grid settings
  public eventGridOptions: GridOptions;
  private gridApi: GridApi;
  private columnApi: ColumnApi;
  rowData: Array<any>;

  //harvest
  harvest: models.Harvest;
  template: models.Template;
  zones: models.Zone[];

  get canCompleteEvent(): boolean { 
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.completeEvent);
  }

  public columnDefs = [];
  private possibleColumns: ColDef[] = [
    // {
    //   headerName: "",
    //   field: "event.completed",
    //   sortable: false, resizable: false,
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   cellRenderer: (params) => {
    //     params.node.setSelected(params.node.data.event.completed)
    //     //params.node.selectable = !params.node.data.event.completed
    //   },
    //   // cellStyle: (params) => {
    //   //  return params.node.data.event.completed ? {'pointer-events': 'none', 'opacity': '0.4'} : ''
    //   // },
    //   width: 25,
    //   // cellRendererFramework: MatCheckboxComponent,
    //   // cellRendererParams: (params) => {
    //   //   //params.unit = ' minutes';
    //   //   return params;
    //   // },
    // },
    {
      headerName: "",
      field: "event.completed",
      cellRendererFramework: MatCheckboxComponent,
      sortable: false, resizable: false,
      width: 50,
      headerComponentFramework: CheckboxHeaderComponent
    },
    {
      headerName: "Priority",
      field: "event.highPriority",
      cellRenderer: "highPriortyIcon",
      sortable: false, resizable: false,
      width: 50,
      suppressSizeToFit: true
    },
    {
      headerName: "Day of Week",
      field: "event.startDateTime",
      cellRendererFramework: cells.DayOfWeekCellComponent,
      sortable: true, resizable: true,
      width: 70
    },
    {
      colId: 'dateColumn',
      headerName: "Date",
      field: "event.startDateTime",
      cellRenderer: "timestampRenderer",
      sortable: true, resizable: true,
      width: 125
    },
    {
      headerName: "Phase",
      field: "event.phase", sortable: true, resizable: true,
      width: 150
    },
    {
      headerName: "Event Description",
      field: "event.description",
      sortable: true, resizable: true,
      width: 250
    },
    {
      headerName: "Notes",
      field: "messages",
      cellRenderer: "listRenderer",
      sortable: false, resizable: true,
      autoHeight: true,
      width: 300
    },
    {
      headerName: "Assigned to",
      cellRenderer: (params) => {
        const event: models.Event = params.node.data.event;
        const assignedUserId = event.assignedUserIds || [];
        const assignedTeamId = event.assignedTeamIds || [];

        const users = this.users.filter(i => assignedUserId.includes(i.id)).map(i => i.displayName);
        const teams = this.teams.filter(i => assignedTeamId.includes(i.uid)).map(i => i.name);
        return this.helperService.concatToSentence(teams.concat(users));
      },
      sortable: false,
      resizable: true,
      width: 150
    },
    {
      headerName: "Completed By",
      cellRenderer: (params) => {
        if (params.node.data.event.completed) {
          const discussion = params.node.data.event.discussions?.reverse().find(d => d.discussionType === models.DiscussionType.CompletedStatus)
          if (discussion) {
            const user = this.users.find(u => u.id === discussion.userId)
            return user ? user.displayName : null
          }
        }
      },
      sortable: false,
      resizable: true,
      width: 150
    },
    {
      headerName: "Est. Time (Min)",
      field: "event.estimatedTime",
      cellRendererFramework: UnitCellComponent,
      cellRendererParams: (params) => {
        params.unit = ' minutes';
        return params;
      },
      sortable: true, resizable: true,
      width: 150,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: "Time Spent",
      field: "event.timeSpent",
      cellRendererFramework: UnitCellComponent,
      cellRendererParams: (params) => {
        params.unit = ' minutes';
        return params;
      },
      sortable: true, resizable: true,
      width: 150,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: "Quality Tracker",
      field: "event.efficacy",
      cellRendererFramework: UnitCellComponent,
      cellRendererParams: (params) => {
        params.unit = '%';
        return params;
      },
      sortable: true, resizable: true,
      width: 150,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: "Compliance Stage",
      field: "complianceStage",
      sortable: true, resizable: true,
      width: 250
    },
    {
      headerName: "Zone",
      field: "zones",
      sortable: true, resizable: true,
      width: 250
    },
    {
      headerName: "Tags",
      field: "event.tags",
      cellRenderer: "tagRenderer",
      sortable: false, resizable: true,
      autoHeight: true,
      width: 250
    }
  ];

  get hasPendingChanges(): boolean {
    let newComp = this.gridList.map(i => i.event.completed);
    let initialComp = this.initialGridList.map(i => i.event.completed);
    //let etestgs = diff(this.gridList, this.initialGridList);
    let test = equal(newComp, initialComp);
    return !test;
  }

  get eventList() : Array<string> {
    let el: Array<string> = null;
    if(this.collectionType == 'harvests'){
      el = this.appStorage.eventListColumns;
    } else if(this.collectionType == 'groups') {
      el = this.appStorage.taskGroupEventListColumns;
    }
    return el;
  }
  set eventList(val: Array<string>) {
    if(this.collectionType == 'harvests'){
      this.appStorage.eventListColumns = val;
    } else if(this.collectionType == 'groups') {
      this.appStorage.taskGroupEventListColumns = val;
    }
  }

  constructor(
    private userService: services.UserService
    , private eventService: services.EventService
    , private helperService: services.HelperService
    , private dialog: MatDialog
    , private claimsService: services.ClaimsService
    , private notifierService: services.NotifierService
    , private appStorage: services.AppStorage
    , public gridHeaderService: services.GridHeaderService
    , private teamService: services.TeamService
    , private harvestService: services.HarvestService
    , private templateService: services.TemplateService
    , private zoneService: services.ZoneService
    , private activatedRoute: ActivatedRoute
    , private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.listenForQuery();

    this.setUpEventGrid();
    if(this.collectionType == 'groups') {
      this.possibleColumns = this.possibleColumns.filter(i => i.headerName != 'Phase' && i.headerName != 'Compliance Stage' && i.headerName != 'Zone');
    }
    if(!this.canCompleteEvent) {
      this.possibleColumns = this.possibleColumns.filter(i => i.headerName != '');
    }

    //get companyId
    let companyId = this.helperService.currentCompanyId;
    this.users = await this.userService.getUsersForCompanyWithCaching(companyId, true, true);
    this.teams = await this.getTeams();

    if(this.collectionType == 'harvests'){
      let harvestResponse = await this.harvestService.get(this.collectionId).get().toPromise();
      this.harvest = harvestResponse.data();
      this.harvest.uid = harvestResponse.id;

      if(this.harvest.templateId){
        this.template = await this.templateService.get(this.harvest.templateId);
      }

      await this.getDbZones();
    }

    this.getEvents();
  }

  ngAfterViewInit(): void {
    this.setColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.collectionId) {
      this.getEvents();
    }
  }

  ngOnDestroy(): void {
    if (this.eventSub != null) {
      this.eventSub();
    }
    if(this.querySub != null) {
      this.querySub.unsubscribe();
    }
  }

  listenForQuery() {
    this.querySub = this.activatedRoute.queryParams.subscribe( params => {
      this.eventId = params.evt;

      if(this.eventId != null) {
        this.autoOpenEventPopup(this.eventId);
      }
    });
  }

  async getDbZones() {
    let dbZone = await this.zoneService.getDbZone();
    if(dbZone == null){
      return;
    }
    this.zones = dbZone.list;
  }

  async getTeams(): Promise<models.Team[]> {
    let teams: models.Team[] = [];
    let teamResponse = await (await this.teamService.getAll().get()).toPromise()
    teamResponse.docs.forEach(doc => {
        let team: models.Team = doc.data();
        team.uid = doc.id;
        teams.push(team);
      })
    return teams;
  }

  async setColumns() {
    let stored = this.eventList;
    if (stored != null) {
      let columns = [];

      if(!stored.includes('Day of Week')) { //adding 'Day of Week' right before 'Date' if it's not saved in browser cache
        stored.splice(stored.indexOf('Date'), 0, 'Day of Week')
      }

      if (this.possibleColumns.filter(i => i != null).find(i => i.headerName == '') != null) {
        let foundCol = this.possibleColumns.filter(i => i != null).find(i => i.headerName == '');
        if (foundCol != null) {
          columns.push(foundCol);
        }
      }

      let excluded = this.possibleColumns.filter(i => !stored.includes(i.headerName)).map(i => i.headerName);
      
      stored.forEach(col => {
        if (col == '') {
          return;
        }
        if(col == 'Messages'){
          col = 'Notes'
        }
        let foundCol = this.possibleColumns.filter(i => i != null).find(i => i.headerName == col);
        if (foundCol != null) {
          columns.push(foundCol);
        }
      });

      excluded.forEach(col => {
        if (col == '') {
          return;
        }
        let foundCol = this.possibleColumns.filter(i => i != null).find(i => i.headerName == col);
        if (foundCol != null) {
          columns.push(foundCol);
        }
      })
      
      this.columnDefs = [];
      await this.helperService.wait(10);
      this.columnDefs = columns;
    } else {
      this.columnDefs = this.possibleColumns;
    }
    this.resizeColumns();
  }

  columnMoved(t) {
    // if (t.type != "dragStopped") {
    //   return;
    // }
    let cols = this.columnApi.getAllGridColumns().map(i => i.getUserProvidedColDef().headerName); //.filter(i => i.isVisible())
    this.eventList = cols;
  }

  setUpEventGrid() {
    this.eventGridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      rowData: this.rowData,
      //columnDefs: this.eventColumnDefs(),
      rowHeight: 35,
      onGridReady: (event: GridReadyEvent) => {
        // if(!this.deviceService.isMobile()){
        //   this.eventGridOptions.api.sizeColumnsToFit();
        // }
        this.gridApi = event.api;
        this.columnApi = event.columnApi;
        this.gridApi.showLoadingOverlay();
      },
      frameworkComponents: {
        timestampRenderer: TimeStampComponent,
        listRenderer: ListComponent,
        highPriortyIcon: HighPriortyIconComponent,
        tagRenderer: TagComponent
      },
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      //onCellDoubleClicked: this.selectedCell
      // rowClassRules: {
      //   // accent high priority tasks
      //   'high-priority-row': function(params) {
      //     return params.data.event.highPriority === true; }
      // }
      onSelectionChanged: (event: SelectionChangedEvent) => {
        if (this.loading) {
          return;
        }
        this.eventGridOptions.api.forEachNode(node => {
          node.data.event.completed = node.isSelected()
        });
        //this.eventGridOptions.api.row
      },
      onDragStopped: this.columnMoved.bind(this)
    };
  }

  resizeColumns() {
    this.eventGridOptions.columnApi.autoSizeColumns(["event.startDateTime", "event.phase", "event.description", "messages"])
  }

  handleRowClick(event) {
    //this.pendingChanges()
    // if(event.node.selected) {
    //   event.node.data.event.completed = true
    // } else {
    //   event.node.data.event.completed = false
    // }

  }

  selectedNodes() {
    return this.eventGridOptions.api.getSelectedNodes().filter(node => node.selectable)
  }

  async getUsers(companyId: string) {
    let userResponse = await this.userService.getUsersFoCompany(companyId, true);
    if (userResponse.data.success) {
      userResponse.data.value.forEach(element => {
        let user: models.User = element;
        user.id = element.id;
        this.users.push(user);
      });
    }
  }

  getEvents() {
    if(this.company.eventsSplit){
      this.getEventsSplit();
    } else {
      this.getEventsOld();
    }
  }

  getEventsSplit() {
    this.eventSub = onSnapshot(this.eventService.getSplit(this.collectionId, this.collectionType), async (eventResponse) => {
      this.loading = true;

      this.gridList = [];
      let gridList: viewModels.EventTableViewModel[] = [];
      this.events = [];

      eventResponse.forEach(eventData => {
        let event: models.Event = eventData.data();
        event.uid = eventData.id;
        this.events.push(event);
      });
      let sorted: models.Event[] = this.helperService.sortArrayByNumberField(this.events, 'startDateTime');

      for (let i = 0; i < sorted.length; i++) {
        const event = sorted[i];

        let eventVM: viewModels.EventTableViewModel = {
          event: event,
          messages: []
        }
        eventVM.complianceStage = this.getComplianceStage(event.phase);
        eventVM.zones = this.getZones(event.phase);

        let notes = event.discussions != null ? event.discussions.filter((v) => {
          return v.discussionType == models.DiscussionType.Note;
        }) : [];
        notes.forEach(note => {
          eventVM.messages.push(note.content);
        });

        gridList.push(eventVM);
      };

      this.gridList = gridList;
      await this.helperService.wait(50);
      this.initialGridList = this.helperService.deepCopy(gridList);
      this.gridHeaderService.events = this.gridList;

      this.loading = false;

      this.pageLoaded = true;
      this.defaultSort();

      this.resizeColumns();

      this.eventListChange.next(this.events);
      this.gridApi.hideOverlay();
    });

  }

  getEventsOld() {
    this.eventSub = onSnapshot(this.eventService.get(this.collectionId, this.collectionType), async eventResponse => {
      // if(this.eventGridOptions.api) {
      //   this.eventGridOptions.api.setRowData([]);
      // }
      this.loading = true;

      this.gridList = [];
      let gridList = [];

      // this.startDate = firebase.default.firestore.Timestamp.fromDate(this.helperService.addYearsToToday(10))
      // this.endDate = firebase.default.firestore.Timestamp.fromDate(this.helperService.addYearsToToday(-10))
      for (let i = 0; i < eventResponse.size; i++) {
        const eventData = eventResponse.docs[i];
        let dbEvent: models.DbEvent = eventData.data();
        dbEvent.uid = eventData.id;
        this.dbEvent = dbEvent;

        let sorted: models.Event[] = this.helperService.sortArrayByNumberField(this.dbEvent.list, 'startDateTime');
        sorted.forEach((event, index) => {
          // if(event.startDateTime < this.startDate){
          //   this.startDate = event.startDateTime
          // }
          // if(event.startDateTime > this.endDate){
          //   this.endDate = event.startDateTime;
          // }

          let uid = `${this.dbEvent.uid}::${index}`;
          event.uid = uid;

          let eventVM: viewModels.EventTableViewModel = {
            event: event,
            messages: []
          }
          eventVM.complianceStage = this.getComplianceStage(event.phase);
          eventVM.zones = this.getZones(event.phase);

          let notes = event.discussions != null ? event.discussions.filter((v) => {
            return v.discussionType == models.DiscussionType.Note;
          }) : [];
          notes.forEach(note => {
            eventVM.messages.push(note.content);
          });

          //put all users returned from the server into the list
          gridList.push(eventVM);
          //this.eventGridOptions.api.applyTransaction({ add: [eventVM] });
        });

        //this.autoSizeAll(false);
        this.gridList = gridList;
        await this.helperService.wait(50);
        this.initialGridList = this.helperService.deepCopy(gridList);
        // this.gridHeaderService.checkAll = this.gridList.filter(i => i.event.completed).length > 0
        // this.gridHeaderService.totalRows = this.gridList.length;
        this.gridHeaderService.events = this.gridList;
      };

      this.gridList = gridList;
      await this.helperService.wait(50);
      this.initialGridList = this.helperService.deepCopy(gridList);

      this.loading = false;

      this.pageLoaded = true;
      this.defaultSort();

      this.resizeColumns();

      this.eventListChange.next(this.dbEvent.list);

      if(this.eventId != null) {
        this.autoOpenEventPopup(this.eventId);
      }
    });
  }

  async defaultSort() {
    await this.helperService.wait(1000);
    this.eventGridOptions.columnApi.getColumn('dateColumn').setSort("asc")
  }

  getComplianceStage(phase: string) {
    if(phase == null || this.template == null){
      return null;
    }

    return this.template.phases?.find(i => i.name == phase)?.compliancePhase;
  }

  getZones(phase: string): string {
    if(phase == null || this.zones == null){
      return null;
    }
    
    let harvestPhase = this.harvest.phases?.find(i => i.phaseName == phase);
    let zoneIds = harvestPhase?.strains?.flatMap(i => i.zones).filter(i => i != null).map(i => i.zoneId);
    if(zoneIds == null){
      return '';
    }
    let zones = this.zoneService.getAllTopLevel(this.zones, zoneIds);
    return zones.map(i => i.name).join(', ');
  }

  eventClick(info: any) {
    let event: models.Event = info.data.event;
    let start = this.helperService.timestampToDate(event.startDateTime);
    let harvestId = this.collectionId;
    const isTaskGroupEvent = this.collectionType == 'groups';

    let data: any = {
      event: event,
      start: start,
      assignToGroup: isTaskGroupEvent,
      harvestId: harvestId,
      eventList: this.dbEvent != null ? this.dbEvent.list : this.events,
      saveInDialog: this.helperService.currentCompany?.eventsSplit || false
    }

    let dialogRef = this.dialog.open(EventUpdateComponent, {
      width: '800px',
      data: data
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      if (result.save) {
        //perform save
        let dbEvent: models.DbEvent = {
          uid: this.dbEvent.uid,
          list: result.eventList
        }
        await this.eventService.save(isTaskGroupEvent, this.collectionId, dbEvent);
        if(result.automaticallyCompletedEvent) {
          this.notifierService.success('All subtasks are complete, event has been automatically marked as completed');
        }
      }
      else if (result.delete) {
        let list: models.Event[] = result.eventList;
        let index = list.indexOf(result.event);

        if (index > -1) {
          result.eventList.splice(index, 1);
        }

        let dbEvent: models.DbEvent = {
          uid: this.dbEvent.uid,
          list: result.eventList
        }
        await this.eventService.save(isTaskGroupEvent, this.collectionId, dbEvent);
      }
    })
  };

  addEvent() {
    let data: any = {};
    if (this.collectionType == 'groups') {
        data = { 
          groupId: this.collectionId,
          addFromGroupDetail: true,
        };
    } else {
      data = { harvestId: this.collectionId };
    }
    data.saveInDialog = this.helperService.currentCompany?.eventsSplit || false;

    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: data,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.save) {
        //perform save
        let assignToGroup: boolean = result.assignToGroup;
        let harvest: models.Harvest = result.harvest;
        let group: models.Group = result.group;

        let events: Array<models.Event> = result.events;
        let dbEvents: Array<models.Event> = [];

        events.forEach(event => {
          let dbEvent: models.Event = event;

          dbEvent.color = assignToGroup ? group.color : harvest.color;

          if (assignToGroup) {
            delete dbEvent.harvestName;
            dbEvent.groupName = group.name;
          } else {
            delete dbEvent.groupName;
            dbEvent.harvestName = harvest.name;
          }

          dbEvents.push(dbEvent);
        });

        await this.eventService.add(assignToGroup, assignToGroup ? group.uid : harvest.uid, dbEvents);
      }
    });
  }

  async saveChanges() {
    const isTaskGroup = this.collectionType == 'groups'

    const successText = 'Successfully saved Events in ' + (isTaskGroup ? 'Group ' : 'Harvest ')

    let eventsToSave: models.Event[] = [];

    for (let i = 0; i < this.gridList.length; i++) {
      const eventVM = this.gridList[i];

      if (eventVM.event.completed != this.initialGridList[i].event.completed) {
        if (eventVM.event.discussions == null) {
          eventVM.event.discussions = []
        }

        let newDis: models.Discussion = {
          discussionType: models.DiscussionType.CompletedStatus,
          userId: this.claimsService.currentUserId(),
          timestamp: Timestamp.now(),
          content: eventVM.event.completed ? 'true' : 'false',
        }

        if(this.company.eventsSplit) {
          eventVM.event.discussions.push(newDis);
          eventsToSave.push(eventVM.event);
        } else {
          this.dbEvent.list[i].completed = eventVM.event.completed;
          this.dbEvent.list[i].discussions.push(newDis);
        }
      }
    }

    if(this.company.eventsSplit) {
      await this.eventService.saveListSplit(isTaskGroup, eventsToSave)
    } else {
      let resp = await this.eventService.save(isTaskGroup, this.collectionId, this.dbEvent)
    }

    this.notifierService.success(successText + `'` + this.collectionName + `'`);
  }

  undoChanges() {
    this.eventSub();
    this.getEvents();
  }

  exportAsCSV() {
    let params: CsvExportParams = {
      fileName: `${this.collectionName}_TaskExport.csv`
    };
    params.processCellCallback = (params) => {
      if (params.value instanceof Timestamp) {
        return this.helperService.timestampToDateString(params.value);
      } else if(this.helperService.isHTMLString(params.value)){
        return this.helperService.extractVisibleTextFromHTML(params.value)
      } else {
        if(params.value != null) {
          return params.value;
        } else {
          var cellVal = params.value;
          if (typeof (<any>params.column.getColDef()).cellRenderer === "function") {
            cellVal = (<any>params.column.getColDef()).cellRenderer(params);
          }
          return cellVal;
        }
      }
    };
    this.eventGridOptions.api.exportDataAsCsv(params);
  }

  autoOpenEventPopup(eventId: string) {
    let foundEvent = this.gridList.find(i => i.event.id == eventId);

    if(foundEvent == null) {
      return;
    }

    this.eventClick(
      {
        data: foundEvent
      })

    const queryParams: Params = { evt: null };

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

}
