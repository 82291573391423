import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "date-cell",
    template: `
        {{ dateTime | date:'M/d/yyyy' }}
    `,
    styles: [
    ]
})
export class DateCellComponent implements ICellRendererAngularComp {
    private params: any;

    dateTime: string;

    constructor() { }

    agInit(params: any): void {
        if (!params.value) {
            return;
        }

        this.params = params;

        this.dateTime = this.params.value;
    }

    refresh(params: any): boolean {
        return false;
    }
}