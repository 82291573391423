
<div class="row" style="padding-bottom: 15px">
<!-- <div class="col-sm-6">
    <button class="gridbtn" mat-raised-button (click)="addRow()">Add row</button>
    <button class="gridbtn" mat-raised-button (click)="setToDefaultData()">Set default data</button>
</div> -->
    <div class="col-sm-12" style="text-align: right">
        <button mat-raised-button class="gridbtn" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button *ngIf="canAddUsers && canSeeInviteToOtherCompanies" mat-menu-item (click)="inviteToOtherCompany()" title="Invite" [disabled]="selectedRows.length == 0">
                <ng-container>
                    <mat-icon>outgoing_mail</mat-icon>
                    <span>Invite to other Company</span>
                </ng-container>
            </button>
            <button *ngIf="canEditUsers" mat-menu-item (click)="editUser()" title="Edit" [disabled]="selectedRows.length == 0 || selectedRows.length > 1">
                <mat-icon>edit</mat-icon>
                <span>Edit User</span>
            </button>
            <button *ngIf="canEnableDisableUsers" mat-menu-item (click)="disableSelectedRows()" title="Disable Selected Users" [disabled]="selectedRows.length == 0 || disableUsersClick">
                <i class="fa fa-spinner fa-spin" *ngIf="disableUsersClick"></i>
                <ng-container *ngIf="!disableUsersClick">
                    <mat-icon>person_off</mat-icon>
                    <span>Disable</span>
                </ng-container>
            </button>
            <button *ngIf="canEnableDisableUsers" mat-menu-item (click)="enableSelectedRows()" title="Enable Selected Users" [disabled]="selectedRows.length == 0 || enableUsersClick">
                <i class="fa fa-spinner fa-spin" *ngIf="enableUsersClick"></i>
                <ng-container *ngIf="!enableUsersClick">
                    <mat-icon>person_outline</mat-icon>
                    <span>Enable</span>
                </ng-container>
            </button>
            <button *ngIf="canDeleteUsers" mat-menu-item (click)="deleteSelectedRowsClick()" title="Delete Selected Users" [disabled]="selectedRows.length == 0 || deleteUsersClick">
                <i class="fa fa-spinner fa-spin" *ngIf="deleteUsersClick"></i>
                <ng-container *ngIf="!deleteUsersClick">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </ng-container>
            </button>
            <button mat-menu-item (click)="sendResetPasswordLink()" title="Send Reset Password Link" [disabled]="selectedRows.length == 0 || sendResetPasswordLinkClick">
                <i class="fa fa-spinner fa-spin" *ngIf="sendResetPasswordLinkClick"></i>
                <ng-container *ngIf="!sendResetPasswordLinkClick">
                    <mat-icon>send</mat-icon>
                    <span>Send Reset Password Link</span>
                </ng-container>
            </button>
            <button *ngIf="canAddUsers" mat-menu-item (click)="addUser()">
                <mat-icon>add</mat-icon>
                <span>Invite User</span>
            </button>
            <button mat-menu-item (click)="export()">
                <mat-icon>file_download</mat-icon>
                <span>Export</span>
            </button>
        </mat-menu>
    </div>
</div>
<div class="row">
    <ag-grid-angular style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
        class="ag-theme-balham"
        [rowData]="listUsers"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        (selectionChanged)="onSelectionChanged()">
    </ag-grid-angular>
</div>