import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "value-edit-button-cell",
    template: `
        {{label}}
        <button mat-icon-button *ngIf="buttonVisible" [disabled]="processing" (click)="buttonClicked()" style="width: 25px; height: 25px; line-height: 15px">
            <mat-spinner *ngIf="processing" diameter=25 strokeWidth=4></mat-spinner>
            <mat-icon *ngIf="!processing">{{icon}}</mat-icon>
        </button>
    `,
    styles: [
        `
            ::ng-deep
            .mat-checkbox-layout {
                /* horizontally align the checkbox */
                width: 100%;
                display: inline-block !important;
                text-align: center;
                margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

                /* vertically align the checkbox when not using the ag-material theme - should be the same as the 
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
            }
        `
    ]
})
export class ActionButtonCellComponent implements ICellRendererAngularComp {
    private params: any;

    label: string = '';
    buttonVisible: boolean = true;
    id: string;
    icon: string;
    
    processing: boolean = false;

    agInit(params: any): void {
        this.params = params;

        if(this.params.data != null){
            this.label = this.params.data?.name;
            this.id = this.params.data?.id;
            if(this.params.buttonVisible != null){
                this.buttonVisible = this.params.buttonVisible || false;
            }
        }
        this.icon = this.params.icon;
        //this.checked = this.params.value === "On";
    }

    buttonClicked() {
        this.processing = true
        this.params.action.call(this.params.that, this.id, this.completedProcessing);
    }

    completedProcessing() {
        this.processing = false;
    }

    refresh(params: any): boolean {
        return false;
    }
}