import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { ComponentsModule } from '../components/components.module';
//import { MarketingLayoutModule } from '../layouts/marketing-layout/marketing-layout.module';

import { CompanySelectComponent } from './company-select/company-select.component';
import { LoginComponent } from './login/login.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FormInsertComponent } from './form-insert/form-insert.component';
import { PaymentComponent } from './payment/payment.component';
import { SignupComponent } from './signup/signup.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NoCompaniesComponent } from './no-companies/no-companies.component';

const decs: Array<any> = [
    CompanySelectComponent,
    LoginComponent,
    NoPermissionsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    FormInsertComponent,
    PaymentComponent,
    SignupComponent,
    ConfirmComponent,
    NoCompaniesComponent,
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TextMaskModule,
    //MarketingLayoutModule
  ],
  declarations: [
    ... decs,
  ],
  exports: [
    ... decs
  ]
})
export class PagesModule { }
