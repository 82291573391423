import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AttachmentsSelectDialogComponent, HarvestSelectDialogComponent, NotesComponent, SubtasksDialogComponent } from 'app/dialogs';

import * as models from 'app/models';
import * as services from 'app/services';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';

@Component({
  selector: 'app-task-library-editor',
  templateUrl: './task-library-editor.component.html',
  styleUrls: ['./task-library-editor.component.scss']
})
export class TaskLibraryEditorComponent implements OnInit, OnChanges {

  @Input() id: string;
  @Input() allTags: string[];
  @Output() saved: EventEmitter<models.TemplatedTask> = new EventEmitter();
  userRole: models.Role;

  templatedTask: models.TemplatedTask;
  initialTemplatedTask: models.TemplatedTask;

  saving: boolean = false;

  get dirty() {
    let jTask = JSON.stringify(this.templatedTask);
    let iTask = JSON.stringify(this.initialTemplatedTask);

    return jTask != iTask;
  }

  constructor(
    private templatedTaskService: services.TemplatedTasksService
    , private templateService: services.TemplateService
    , private harvestService: services.HarvestService
    , private helperService: services.HelperService
    , private claimsService: services.ClaimsService
    , private dialog: MatDialog
    , private router: Router
    , private notifierService: services.NotifierService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userRole = await this.claimsService.getRoleAwait();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.id != null && changes.id.currentValue != changes.id.previousValue) {
      let id = changes.id.currentValue;
      if(id == 'add') {
        this.createAddedTask();
      } else {
        this.getTemplatedTask(changes.id.currentValue);
      }
    }
  }

  createAddedTask() {
    this.templatedTask = {
      anyTime: true,
      startTime: '12:00',
    }
  }

  getTemplatedTask(id: string) {
    if(id == null) {
      this.templatedTask = null;
      this.initialTemplatedTask = null;
      return;
    }
    this.templatedTaskService.get(id).then(templatedTask => {
      this.templatedTask = templatedTask;
      this.initialTemplatedTask = this.helperService.deepCopy(templatedTask);
    });
  }

  async save() {
    this.saving = true;
    let id = await this.templatedTaskService.save(this.templatedTask);
    if(this.id == 'add') {
      this.templatedTask.id = id;
      this.helperService.addQueryStringParam(models.queryStringKeys.Templatedtask, id);
    } else {
      this.id = id;
    }
    this.initialTemplatedTask = this.helperService.deepCopy(this.templatedTask);

    this.notifierService.success('Successfully saved Templated Task');

    //check templates the task is used in and update them
    await this.updateTemplates();

    this.saving = false;
    this.saved.emit(this.templatedTask);
  }

  undo() {
    if(this.id == 'add') {
      this.router.navigate(['/console', 'templates', 'task-library']);
    } else {
      this.templatedTask = this.helperService.deepCopy(this.initialTemplatedTask);
    }
  }

  delete() {
    this.templatedTaskService.delete(this.id).then(() => {
      this.router.navigate(['/console', 'templates', 'task-library']);
      this.saved.emit(null);
    });
  }

  cloudClick() {
    let notesDialog = this.dialog.open(NotesComponent, {
      width: '50%',
      data: {
        list: this.templatedTask.cloudAttachments,
        header: 'Event Cloud Attachments',
        placeholder: 'URL'
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data != null) {
        this.templatedTask.cloudAttachments = data;
      }
    })
  }

  attachmentsClick() {
    let notesDialog = this.dialog.open(AttachmentsSelectDialogComponent, { data: { selected: this.templatedTask.attachments } });

    notesDialog.afterClosed().subscribe(data => {
      if (data) {
        this.templatedTask.attachments = data;
      }
    })
  }

  notesClick() {
    let notesDialog = this.dialog.open(NotesComponent, {
      width: '50%',
      data: {
        list: this.templatedTask.notes,
        header: 'Event Notes',
        placeholder: 'Note'
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data != null) {
        this.templatedTask.notes = data;
      }
    })
  }

  subtasksClick() {
    const doc = document.documentElement;
    const top = (window.scrollX || doc.scrollTop) - (doc.clientTop || 0);
    const left = (window.scrollY || doc.scrollLeft) - (doc.clientLeft || 0);
    if(top != 0 || left != 0){
      window.scrollTo(0, 0);
    }
    let notesDialog = this.dialog.open(SubtasksDialogComponent, {
      data: {
        subtasks: this.templatedTask.subtasks || []
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data != null) {
        this.templatedTask.subtasks = data;
      }
      if(top != 0 || left != 0){
        window.scrollTo({ top: top, left: left, behavior: 'auto' });
      }
    })
  };

  async updateTemplates() {
    let request: models.UpdateStepsFromTemplatedTaskRequest = {
      companyId: this.helperService.currentCompanyId,
      templatedTaskId: this.templatedTask.id
    }
    let response = await this.templateService.updateStepsFromTemplatedTask(request);

    if(response.data.success){
      if(response.data.value.length > 0){
        this.notifierService.success(`Successfully updated ${response.data.value.length} Templates - ${response.data.value.map(t => t.name).join(', ')}`);

        await this.checkConnectedHarvests(response.data.value);
      }
    }
  }

  async checkConnectedHarvests(templates: models.Template[]) {
    let requests = [];
    let templateIds = templates.map(t => t.uid)
    templateIds.forEach(templateId => {
      requests.push(this.harvestService.getAllForTemplate(templateId).get().toPromise());
    })
    let responses: QuerySnapshot<DocumentData>[] = await Promise.all(requests);

    let harvests: Array<models.Harvest> = [];
    
    responses.forEach(response => {
      if(response.size == 0){
        return;
      }
      response.forEach(doc => {
        let harvest: models.Harvest = doc.data()
        harvest.uid = doc.id;
        harvests.push(harvest);
      })
    });
    
    harvests = this.helperService.sortArrayByStringField(harvests, 'name');

    let inModel = {
      header: 'Templates Saved.  Would you like to update Harvests?',
      negativeBtn: 'No',
      affirmativeBtn: 'Update',
      //templateId: templateId,
      harvests: harvests
    }

    let dialogRef = this.dialog.open(HarvestSelectDialogComponent, {
      data: inModel
    });
  
    dialogRef.afterClosed().subscribe(async (dialogResult: models.Harvest[]) => {
      if(dialogResult){

        templates.forEach(async template => {
          let templateHarvestsSelected = dialogResult.filter(h => h.templateId == template.uid);
          if(templateHarvestsSelected.length == 0){
            return;
          }
          this.kickOffHarvestUpdate(template, templateHarvestsSelected);
        });
      }
    })
  }

  async kickOffHarvestUpdate(template: models.Template, harvests: models.Harvest[]) {
    this.notifierService.success(`Harvest Update kicked off for Template - ${template.name}.  Go to processes screen to view.`);
    let response = await this.harvestService.updateHarvestsFromTemplate(template.uid, harvests.map(h => h.uid));
    let result: models.Result<number> = response.data;

    if(result.success){
      this.notifierService.success(`Successfully updated ${result.value} Harvests for Template - ${template.name}`);
    }
  }
}
