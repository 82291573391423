<div class="wrapper">
  <app-navbar></app-navbar>
  <div class="main-content">
    <div class="container-fluid">
      <div class="no-permissions-container">
        Oops!  You do not have permission to access this functionality.
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>