import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
var fileDownload = require('js-file-download');

import * as services from 'app/services';
import * as models from 'app/models';
import { GridOptions } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TimeStampComponent } from 'app/components/cell-components';
import { DocumentUploadDialogComponent } from '../document-upload-dialog/document-upload-dialog.component';

@Component({
  selector: 'app-harvest-documents-dialog',
  templateUrl: './harvest-documents-dialog.component.html',
  styleUrls: ['./harvest-documents-dialog.component.scss']
})
export class HarvestDocumentsDialogComponent implements OnInit {

  harvestId: string;
  documents: models.Document[] = [];
  selectedRows: models.Document[] = [];

  public gridOptions: GridOptions;

  private columns: Array<any> = [
    {
      headerName: "Document Name",
      field: "name",
      sortable: true, resizable: true,
    },
    {
      headerName: "Date Uploaded",
      field: "dateUploaded",
      cellRenderer: "timestampRenderer",
      sortable: true, resizable: true,
      filter: 'agDateColumnFilter',
    },
    // {
    //   headerName: "Actions",
    //   field: "id",
    //   sortable: false, resizable: false,
    // }
  ];

  constructor(
    private dialogRef: MatDialogRef<HarvestDocumentsDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private harvestDocumentService: services.HarvestDocumentService
    , private deviceService: DeviceDetectorService
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private fileService: services.FileService
  ) { 
    this.harvestId = data?.harvestId;

    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      columnDefs: this.columns,
      rowData: this.documents,
      rowHeight: 35,
      onGridReady: () => {
        if (!this.deviceService.isMobile()) {
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      frameworkComponents: {
        timestampRenderer: TimeStampComponent,
      },
      rowSelection: 'multiple',
    };
  }

  ngOnInit(): void {
    this.getHarvestDocuments();
  }

  onSelectionChanged() {
    this.selectedRows = this.gridOptions.api.getSelectedRows();
  }

  async getHarvestDocuments() {
    this.documents = await this.harvestDocumentService.getListByHarvest(this.harvestId);
  }

  addDocument() {
    let dialogRef = this.dialog.open(DocumentUploadDialogComponent, {
      data: { 
        harvestId: this.harvestId
      }
    });
    
    dialogRef.afterClosed().subscribe(data => {
      if(data){
        this.getHarvestDocuments();
      }
    })
  }

  download() {
    this.selectedRows.forEach(selectedRow => {
      this.fileService.get(selectedRow.fullPath).subscribe(async url => {
        let blob = await fetch(url).then(r => r.blob());
        fileDownload(blob, selectedRow.name);
      });
    });
  }

  async delete() {
    this.selectedRows.forEach(async selectedRow => {
      await this.harvestDocumentService.delete(this.harvestId, selectedRow);
      this.notifierService.success('Successfully deleted document from Harvest')
      this.documents = this.documents.filter(i => i.id != selectedRow.id)
    });
  }
}
