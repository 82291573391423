
export interface CompanyCreate {
    companyName: string;
    timezone: string;
    addUserToCompany: boolean;
    adminName: string;
    adminEmail: string;
    phone?: string;
    freeVersion: boolean;
    domain: string;
    eventsSplit: boolean;
}