import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import {MatDialog} from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage';

import { UpdateRoleComponent } from '../../dialogs/update-role/update-role.component';

import * as models from '../../models';
import * as services from '../../services';

@Component({
    selector: "updateRoleBtn-cell",
    template: `
        <button *ngIf="visible" (click)="buttonClicked()" mat-icon-button>
            <mat-icon>lock</mat-icon>
        </button>

    `,
    styles: [
    ]
})
export class UpdateRoleBtnComponent implements ICellRendererAngularComp {
    private params: any;

    email: string = '';
    userId: string;
    //private trueClaims: Array<models.Role> = [];
    hasPermission: boolean = false;
    invited: boolean = false;
    roleId: string;

    get visible() {
        return this.hasPermission && !this.invited;
    }

    constructor(
        public dialog: MatDialog
        , private storage: SessionStorageService
        , private claimsService: services.ClaimsService
        , private roleService: services.RoleService
    ){}

    async agInit(params: any): Promise<void> {
        if(params.value == null){
            return;
        }
        this.params = params;

        let role = await this.claimsService.userRole
        //this.trueClaims.push(role);
        this.email = this.params.value;
        this.userId = this.params.data.user.id;
        this.invited = this.params.data.invited;
        this.roleId = this.params.data.user.roleId;

        this.hasPermission = role.permissions?.includes(models.Permissions.editUserRoles);

        // let currentUserRole = await this.claimsService.getRoleAwait();
        // let higherRoleThanCurrentUser = this.trueClaims.find(i => i.precedence <= currentUserRole.precedence);

        // if(this.userId != this.claimsService.currentUserId() && (higherRoleThanCurrentUser == null || currentUserRole.precedence <= 1)){
        //     this.hasPermission = true;
        // }
    }

    buttonClicked(): void {
        let data = {
            email: this.email,
            roleId: this.roleId
        }
        let dialogRef = this.dialog.open(UpdateRoleComponent, {
          panelClass: 'med-width-dialog',
          data: data
        });
    
        dialogRef.afterClosed().subscribe(result => {
          //reload list
          if(result){
            this.params.context.componentParent.getFromServer();
          }
          console.log(JSON.stringify(result));
        })
    }

    refresh(params: any): boolean {
        return false;
    }
}