import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Timestamp } from "@angular/fire/firestore";

@Component({
    selector: "dayOfWeek-cell",
    template: `
    <ng-container *ngIf="timestamp != null">
        {{ timestamp.seconds * 1000 | date:'EEE' }}
    </ng-container>
    `,
    styles: [
    ]
})
export class DayOfWeekCellComponent implements ICellRendererAngularComp {
    private params: any;

    timestamp: Timestamp;

    constructor() { }

    agInit(params: any): void {
        if (!params.value) {
            return;
        }

        this.params = params;

        this.timestamp = this.params.value;
    }

    refresh(params: any): boolean {
        return false;
    }
}