import { Injectable } from '@angular/core';
import { CollectionReference, DocumentReference, Firestore, collection, doc, getDoc, getDocs, query, setDoc, where, onSnapshot } from '@angular/fire/firestore';

import * as services from 'app/services';
import * as models from 'app/models';
import { orderBy, writeBatch } from 'firebase/firestore';

@Injectable()
export class AlertService {

    slug: string = 'alerts';

    constructor(
        private firestore: Firestore,
        private helperService: services.HelperService,
        private claimsService: services.ClaimsService
    ) { 
    }

    getForCurrentUser(onlyUnread: boolean = false) {
        let userId = this.claimsService.currentUserId();
        let alertListRef = collection(this.firestore, 'users', ...[userId, this.slug])
        
        const constraints = []
        constraints.push(where('deleted', '==', false))

        if(onlyUnread) {
            constraints.push(where('read', '==', false))
        }

        constraints.push(orderBy('dateAdded', 'desc'))
        
        let alertListQuery = query(alertListRef, ...constraints);

        return alertListQuery;

        // let snapShot = await getDocs(alertListQuery);

        // let events: models.Alert[] = [];
        // snapShot.docs.forEach(doc => {
        //     let event: models.Alert = doc.data();
        //     event.id = doc.id
        //     events.push(event);
        // })
    }

    async update(alert: models.Alert) {
        let userId = this.claimsService.currentUserId();
        let alertRef = doc(this.firestore, 'users', ...[userId, this.slug, alert.id])

        await setDoc(alertRef, alert);
    }

    async updateList(alerts: models.Alert[]) {
        let userId = this.claimsService.currentUserId();

        // Get a new write batch
        const batch = writeBatch(this.firestore);

        //make the updates
        alerts.forEach(alert => {
            let alertRef = doc(this.firestore, 'users', ...[userId, this.slug, alert.id])
            batch.set(alertRef, alert);
        });

        // Commit the batch
        await batch.commit();
    }
}
