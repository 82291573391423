import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components/ngx-material-popover/ngx-material-popover.component';
import { Router } from '@angular/router';
import * as viewmodels from 'app/viewmodels';
import * as services from '../../services';

@Component({
  selector: 'app-multi-select-btn',
  templateUrl: './multi-select-btn.component.html',
  styleUrls: ['./multi-select-btn.component.scss']
})
export class MultiSelectBtnComponent<T> implements OnInit {
  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';

  @Input() list: Array<viewmodels.MultiSelect> = []
  @Input() label: string = 'Object'

  // selectedValue: Array<string>;
  // @Input()
  // get selected() {
  //   return this.selectedValue;
  // }
  // @Output() selectedChange = new EventEmitter();
  // set selected(val) {
  //   if(val == null){
  //     val = [];
  //   }
  //   this.selectedValue = val;
  //   this.selectedChange.emit(this.selectedValue);
  // }
  @Input() selected: string[] = [];

  @Output() onOpened = new EventEmitter();
  @Output() onClosed = new EventEmitter();

  searchVal: string = '';
  sortNameOrder: boolean = true; 
  sortDateOrder: boolean = true;
  get filteredList() {
    if(this.appStorageService.showFinishedHarvests) { // show finished harvests
      return this.list.filter(i => i.displayValue.includes(this.searchVal))
    }
    return this.list.filter(i => i.displayValue.includes(this.searchVal) && !i.completed) // hide finished harvests
  }

  constructor(
    private appStorageService: services.AppStorage
    , private helperService: services.HelperService
    , private router: Router) {
  }

  ngOnInit(): void {
  }

  openPopover(): void {
    this.popover.open();
    this.onOpened.emit();
  }

  onClose(): void {
    this.popover.close();
  }

  popoverOpened(event: any) {
    //this.onClosed.emit(this.selected);
    this.list = this.list.map(i => {
      i.selected = this.selected.includes(i.id)
      return i;
    })
    this.sortByFirstDate(false);
  }

  popoverClosed(event: any) {
    this.selected = this.list.filter(i => i.selected).map(i => i.id);
    this.onClosed.emit(this.selected);
  }

  selectAll() {
    this.filteredList.forEach(item => {
      item.selected = true;
    });
  }

  selectNone() {
    this.filteredList.forEach(item => {
      item.selected = false;
    });
  }

  sortByName(ascendingOrder: boolean) {
    this.sortNameOrder = ascendingOrder;
    if(this.sortNameOrder) {
      this.helperService.sortArrayByStringField(this.list, 'displayValue');
    } else {
      this.helperService.sortArrayByStringField(this.list, 'displayValue').reverse();
    }
  }

  sortByFirstDate(ascendingOrder: boolean) {
    this.sortDateOrder = ascendingOrder;
    if(this.sortDateOrder) {
      this.helperService.sortByDateField('startDate', this.list);
    } else { // 
      this.helperService.sortByDateFieldDesc('startDate', this.list);
    }
  }

  gotoHarvestDetails(harvestId: string) {
    this.router.navigate(['console', 'harvest', harvestId]);
  }
}
