import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as models from 'app/models';
import * as services from 'app/services';
import * as leaflogix from 'app/models/leaflogix/index';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LeaflogixHarvestMatchDialogComponent } from 'app/dialogs';

@Component({
  selector: 'app-leaflogix-sync-btn',
  templateUrl: './leaflogix-sync-btn.component.html',
  styleUrls: ['./leaflogix-sync-btn.component.scss']
})
export class LeaflogixSyncBtnComponent implements OnInit, OnDestroy {

  disabled: boolean = false;
  companyIdSub: Subscription;

  processingValue: boolean;
  @Input()
  get processing() {
    return this.processingValue;
  }
  @Output() processingChange = new EventEmitter();
  set processing(val) {
    this.processingValue = val;
    this.processingChange.emit(this.processingValue);
  }
  constructor(
    private helperService: services.HelperService,
    private claimsService: services.ClaimsService,
    private leafLogixService: services.LeaflogixService,
    private dialog: MatDialog,
    private appStorage: services.AppStorage,
    private notifierService: services.NotifierService
  ) { }

  ngOnInit(): void {
    this.companyIdSub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load();
    });
    this.load();
  }

  ngOnDestroy(): void {
    this.companyIdSub.unsubscribe();
  }

  load() {
    this.getLeaflogix();
  }

  canSeeLeaflogix: boolean = false;
  getLeaflogix() {
    let company = this.helperService.currentCompany;
    let userId = this.claimsService.currentUserId();
    this.canSeeLeaflogix = company?.leaflogix?.active && company.leaflogix?.adminUserIds?.includes(userId);
  }

  async sync() {
    this.processing = true;
    this.notifierService.success('Kicking off process to sync Harvests');
    let response = await this.leafLogixService.syncLeaflogixCompany();
    let syncResponse: models.Result<boolean> = response.data;

    if (syncResponse.success) {
      this.notifierService.success('Successfully synced all Harvests that are linked to LeafLogix Batches');
    } else {
      this.notifierService.error('There was an error syncing with Dutchie')
    }
    this.processing = false;
  }
}
