import { Injectable } from '@angular/core';
import { HelperService } from 'app/services';
import { HarvestTableViewModel } from 'app/viewmodels';

@Injectable()
export class TimelineHelper {

  defaultColors = [
    '#009933', //green
    '#0066ff', //lite-blue
    '#8114ee', //lite-purple
    '#168D8D', //turqouis
    '#0000cc', //blue
    '#C62424', //red
    '#51168D', //purple
    '#C77A2D', //orange
    '#009933', //green
    '#0066ff', //lite-blue
    '#8114ee', //lite-purple
    '#168D8D', //turqouis
    '#0000cc', //blue
    '#C62424', //red
    '#51168D', //purple
    '#C77A2D', //orange
  ];

  constructor ( 
    private helperService: HelperService
  ) { }

  createTimelineDataFromEventList(eventList: Array<any>) {
    const phases = [...new Set(eventList.map(event => event.phase))]

    let dataArray = []

    for (let i = 0; i < phases.length; i++) {
      const phase = phases[i];
      let remainder =  i % this.defaultColors.length
      const events = eventList.filter(event => event.phase == phase)
      let startAndEndDate = this.getStartAndEndDateForPhase(events)
      dataArray.push(['', phase, null, this.defaultColors[remainder] || '#0066ff', ...startAndEndDate])
    }

    return dataArray;
  }

  createTimelineDataFromHarvestViewModelList(harvestViewModels: Array<HarvestTableViewModel>, onlyActive: boolean = true) {
    let dataArray = [];
    let colorsArray: Array<string> = [];

    let sorted = this.helperService.sortByDateField('startDate', harvestViewModels)
    sorted.forEach(harvest => {
      let start: Date = new Date(this.helperService.timestampToDate(harvest.startDate).setHours(0, 0, 0))
      let end: Date = new Date(this.helperService.timestampToDate(harvest.endDate).setHours(0, 0, 0))
      
      let row = ['', harvest.name, null, harvest.color || '#0066ff', start, end];

      if (!onlyActive) {
        colorsArray.push(harvest.color || '0066ff')
        return dataArray.push(row)
      }

      let today: Date = new Date()
      today = new Date(today.setHours(0,0,0))

      if (end >= today) {
        colorsArray.push(harvest.color || '0066ff');
        return dataArray.push(row)
      }
    });

    return { dataList: dataArray, colors: colorsArray }
  }

  getStartAndEndDateForPhase(events: Array<any>)  {
    let sorted = this.helperService.sortByDateField('startDateTime', events)
    const startDate: Date = new Date(this.helperService.timestampToDate(sorted[0].startDateTime).setHours(0, 0 ,0));
    const endDate: Date = new Date(this.helperService.timestampToDate(sorted[sorted.length - 1].startDateTime).setHours(0, 0 ,0));

    return [startDate, endDate]
  }

  calculateCurrentTimeMarker(chartData) {
    const startDateTimeStamp = chartData[0][4].getTime()
    const endDateSort = this.sortChartDataByEndDate(chartData)
    const endDateTimeStamp = endDateSort[0][5].getTime()
    const currentTime = new Date().getTime();

    return (currentTime - startDateTimeStamp) / (endDateTimeStamp - startDateTimeStamp)
  }

  sortChartDataByEndDate(chartData) {
    let sorted = [...chartData].sort((a, b) => {
      return b[5] - a[5];
    });

    return sorted;
  }
}