import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-company-select-dialog',
  templateUrl: './company-select-dialog.component.html',
  styleUrls: ['./company-select-dialog.component.scss']
})
export class CompanySelectDialogComponent implements OnInit {

  title: string = 'Select Company'

  companies: models.Company[] = [];
  selectedCompany: models.Company;
  saveClicked: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CompanySelectDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data) {
      this.companies = data.companies;
      this.title = data.title;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({ confirm: false })
  }

  async yesClick() {
    this.dialogRef.close({ confirm: true, selectedCompany: this.selectedCompany })
  }

}
