import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../../environments/environment';

import * as services from '../../services';
import { HttpsCallableResult } from '@angular/fire/functions';

declare var SqPaymentForm: any;

@Component({
  selector: 'app-add-card-btn',
  templateUrl: './add-card-btn.component.html',
  styleUrls: ['./add-card-btn.component.scss']
})
export class AddCardBtnComponent implements OnInit, AfterViewInit {
  
  @ViewChild('NgxAsidePanelRight') NgxAsidePanelRight;

  @Input() disabled: boolean;

  paymentForm: any;

  upgrading: boolean = false;
  @Input() trialDays: number;

  @Output() successfulCardAdd: EventEmitter<any> = new EventEmitter();

  constructor(
    private snackBar: MatSnackBar,
    // private stripeService: services.StripeService,
    private claimsService: services.ClaimsService,
    private userService: services.UserService,
    private companyService: services.CompanyService,
    private helperService: services.HelperService,
    // private router: Router,
    private dsls: services.DynamicScriptLoaderService,
    private squareService: services.SquareService
  ) { }

  addCard() {
    this.NgxAsidePanelRight.show()
    this.initPayment();
  }
  
  onCancel() {
    this.NgxAsidePanelRight.hide()
  }

  onSave() {
    alert('Save')
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  async initPayment() {
    await this.dsls.loadScript(environment.square.script);

    this.paymentForm = new SqPaymentForm({ 
      applicationId: environment.square.appId,
      autoBuild: false,
      card: {
        elementId: 'sq-card',
      },
      // SqPaymentForm callback functions
      callbacks: {
        /*
        * callback function: cardNonceResponseReceived
        * Triggered when: SqPaymentForm completes a card nonce request
        */
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          if (errors) {
              // Log errors from nonce generation to the browser developer console.
              console.error('Encountered errors:');
              errors.forEach(function (error) {
                  console.error('  ' + error.message);
              });
              //alert('Encountered errors, check browser developer console for more details');
              this.snackBar.open('There was an error entering the Card information')
              this.upgrading = false;
              return;
          }
          
          this.addCardToSystem(nonce);
        }
      }
    });
    this.paymentForm.build();
  }

  onGetCardNonce(event) {
    this.upgrading = true;

    // Don't submit the form until SqPaymentForm returns with a nonce
    event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    this.paymentForm.requestCardNonce();
  }

  async addCardToSystem(nonce: string) {
    let company = await this.companyService.getCurrent();

    let upgadeResponse = await this.squareService.addCardToCustomer(company.paymentProviderCustomerId, nonce);

    if(upgadeResponse.data.success){
      this.helperService.company_freeVersion = false;
      this.NgxAsidePanelRight.hide();
      this.successfulCardAdd.emit();
    } else {
      this.snackBar.open(upgadeResponse.data.message);
    }

    this.upgrading = false;
  }
}
