import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "checkbox-cell",
    template: `
        <button (click)="buttonClicked()" mat-button>
            <mat-icon>delete</mat-icon>
        </button>
    `,
    styles: [
    ]
})
export class MatButtonComponent implements ICellRendererAngularComp {
    private params: any;

    agInit(params: any): void {
        this.params = params;
    }

    // demonstrates how you can do "inline" editing of a cell
    buttonClicked() {
        var res = this.params.node.updateRowData({ remove: this.params.node });
    }

    refresh(params: any): boolean {
        return false;
    }
}