import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-description-dialog',
  templateUrl: './description-dialog.component.html',
  styleUrls: ['./description-dialog.component.scss']
})
export class DescriptionDialogComponent implements OnInit {

  description: string = '';

  constructor(
    private dialogRef: MatDialogRef<DescriptionDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data) {
      this.description = data.description;
    }
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.description);
  }

}
