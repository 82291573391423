import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as models from '../../models';
import * as services from '../../services';
import { Subscription, Observable, Subject } from 'rxjs';
import * as Chart from 'chart.js/auto';
//import tippy, { Instance, Props, Tippy } from 'tippy.js';


@Component({
  selector: 'app-performance-and-laborLoad-analytics',
  templateUrl: './performance-and-laborLoad-analytics.component.html',
  styleUrls: ['./performance-and-laborLoad-analytics.component.scss']
})
export class PerformanceAndLaborLoadAnalyticsComponent implements OnInit, OnDestroy {

  @ViewChild('chartCanvas', { static: false }) chartCanvas!: ElementRef<HTMLCanvasElement>;

  @Input() allEvents: models.ChartEventInput[];

  private laborLoadDoughnutChart;

  //topPerformersTooltip: Instance<Props>[];

  constructor(
    private helperService: services.HelperService
    , private userService: services.UserService
    , private teamService: services.TeamService
  ) { }

  users: models.User[];
  teams: models.Team[];

  eventsSub: Subscription;

  // stores for Performance Analytics
  eventsInLastMonth: models.ChartEventInput[];
  events3MonthsPriorToLastMonth: models.ChartEventInput[];

  // events stores for Labor Load Analytics
  eventsInLast4Weeks: models.ChartEventInput[];
  eventsInNext7Days: models.ChartEventInput[];

  top3Performers: Array<{ name: string, count: number, percentIncrease: number }>;
  bottom3Performers: Array<{ name: string, count: number, percentIncrease: number }>;
  allPerformers: Array<{ name: string, count: number, percentIncrease: number }>;
  allPerformersDesc: Array<{ name: string, count: number, percentIncrease: number }>;

  percentChangeInLaborLoad: number = 0;

  laborLoadOnUsers: Array<{ name: string, count: number, percentChange: number }>;
  laborLoadOnTeams: Array<{ name: string, count: number, percentChange: number }>;

  laborLoadInNext7Days: Array<{ name: string, count: number, percentChange: number }>;
  laborLoadOnUsersAndTeams: Array<{ name: string, count: number, percentChange: number }>;

  showAllLaborLoads: boolean = false;

  private _events = new Subject<models.ChartEventInput[]>();
  getEvents(): Observable<any> {
    return this._events.asObservable();
  }

  set events(val: models.ChartEventInput[]) {
    if (val) {
      this._events.next(val);
    }
  }

  performanceLabelName(user: { name: string, count: number, percentIncrease: number }, i: number): string {
    let value: string = `${i + 1}. ${user.name} `;
    return value;
  }

  performanceLabelValue(user: { name: string, count: number, percentIncrease: number }, i: number): string {
    let value: string = `: ${user.count !== null ? user.count : 0} (${user.percentIncrease > 0 ? '+' : ''}${user.percentIncrease !== null ? user.percentIncrease : '0'}%)`;
    return value;
  }

  ngOnInit(): void {
    //this.createTooltips();
  }

  ngOnChanges() {
    this.events = this.allEvents;
  }

  ngAfterViewInit(): void {
    if (this.eventsSub) {
      this.fetchEventsForAnalytics(this.events);
    }
    this.eventsSub = this.getEvents().subscribe(data => {
      this.fetchEventsForAnalytics(data);
    })
  }

  ngOnDestroy(): void {
    // if(this.topPerformersTooltip){
    //   this.topPerformersTooltip[0].destroy();
    //   this.topPerformersTooltip = null;
    // }
  }

  // createTooltips() {
  //   this.topPerformersTooltip = tippy('#top-performers-help', {
  //     content: `<b>Top Performers tracks two types of data: </b> <br/><br/>
  //     1. Number of Events completed by Users over the last 30 days. <br/>
  //     2. The % change in the number of Events completed over the last thirty days compared to the last 90 days previous.`,
  //     allowHTML: true,
  //     arrow: true,
  //     onShow(instance) {
  //       instance.popper.style.backgroundColor = 'white';
  //       instance.popper.style.color = '#494949';
  //       instance.popper.style.borderRadius = '5px';
  //     },
  //     onHide(instance) {
  //       instance.popper.style.display = 'none';
  //     },
  //   });
  // }

  async fetchEventsForAnalytics(listOfEvents: models.ChartEventInput[]) {

    // fetch all users for a company 
    this.users = await this.userService.getUsersForCompanyWithCaching(this.helperService.currentCompanyId, true, true);

    // fetch all teams
    this.teams = await this.teamService.getAllModels();

    // gather events for Performance Analytics
    const [_eventsLastMonth, _events3MonthsPrior] = this.fetchEventsForPerformanceAnalytics(listOfEvents);
    this.eventsInLastMonth = _eventsLastMonth;
    this.events3MonthsPriorToLastMonth = _events3MonthsPrior;
    // find performers based on events completed by them
    const [_topPerformers, _bottomPerformers, _allPerformers] = await this.findTopAndBottomPerformersData();

    this.top3Performers = _topPerformers;
    this.bottom3Performers = _bottomPerformers;
    this.allPerformers = _allPerformers.reverse();
    this.allPerformersDesc = _allPerformers;
    // gather events for Labor Load Analytics - Percent Change in Labor Load
    const [_eventsLast4Weeks, _eventsNext7Days] = this.fetchEventsForLaborLoadAnalytics(listOfEvents);

    const tasksAverageForLast4Weeks = _eventsLast4Weeks.length / 4;

    // find and set overall percentage change
    this.percentChangeInLaborLoad = await this.getOverAllPercentChangeInLaborLoad(tasksAverageForLast4Weeks, _eventsNext7Days.length);

    this.eventsInNext7Days = _eventsNext7Days;
    this.eventsInLast4Weeks = _eventsLast4Weeks;

    // using events in last 4 weeks and in next 7 days for Labor Load on Users & Teams
    const [loadOnUsersInLast4Weeks, loadOnTeamsInLast4Weeks] = await this.fetchLaborLoadInLast4Weeks(_eventsLast4Weeks);
    const [loadOnUsersInNext7Days, loadOnTeamsInNext7Days] = await this.fetchLaborLoadInNext7Days(_eventsNext7Days);
    const loadOnUsersWithPercentChange = this.getPercentChangeForUsers(loadOnUsersInLast4Weeks, loadOnUsersInNext7Days);
    const loadOnTeamsWithPercentChange = this.getPercentChangeForTeams(loadOnTeamsInLast4Weeks, loadOnTeamsInNext7Days);
    
    // sort users and teams according to their load or percent change - alphabetically if load and percent change are similar
    this.laborLoadOnUsers = this.helperService.sortByLaborLoadAndPercentChange(loadOnUsersWithPercentChange);

    this.laborLoadOnTeams = this.helperService.sortByLaborLoadAndPercentChange(loadOnTeamsWithPercentChange);

    this.laborLoadOnUsersAndTeams = [...this.laborLoadOnUsers, ...this.laborLoadOnTeams];
    this.makeLaborLoadChart();
  }

  fetchEventsForPerformanceAnalytics(listOfEvents: models.ChartEventInput[]) {
    // find events for last month
    const currentDate = new Date();
    const dateForLastMonth = this.helperService.getDateForLastMonth();

    const eventsLastMonth = listOfEvents.filter(_event => {
      const eventDate = new Date(_event.date);
      return eventDate >= dateForLastMonth && eventDate <= currentDate;
    })

    // find events for 3 Months prior to last month
    const date3MonthsPrior = this.helperService.getDateFor3MonthsPriorLastMonth();

    const events3MonthsPrior = listOfEvents.filter(_event => {
      const eventDate = new Date(_event.date);
      return eventDate >= date3MonthsPrior && eventDate < dateForLastMonth;
    });

    return [eventsLastMonth, events3MonthsPrior];
  }

  fetchEventsForLaborLoadAnalytics(listOfEvents: models.ChartEventInput[]) {
    // finding events for last 4 weeks
    const currentDate = new Date();
    const dateBefore4Weeks = this.helperService.getDateForLastMonth();

    const _eventsInLast4Weeks = listOfEvents.filter(_event => {
      const eventDate = new Date(_event.date);
      return eventDate >= dateBefore4Weeks && eventDate <= currentDate;
    })

    // finding events for next 7 days
    const dateAfter1Week = new Date();
    dateAfter1Week.setDate(currentDate.getDate() + 7);

    const _eventsInNext7Days = listOfEvents.filter(_event => {
      const eventDate = new Date(_event.date);
      return eventDate > currentDate && eventDate <= dateAfter1Week;
    })

    return [_eventsInLast4Weeks, _eventsInNext7Days];
  }

  async getOverAllPercentChangeInLaborLoad(tasksAverageForLast4Weeks: number, tasksAmountInNextWeek: number) {
    const _percentChangeInLaborLoad = Math.floor(((tasksAmountInNextWeek - tasksAverageForLast4Weeks) / tasksAverageForLast4Weeks) * 100);
    return _percentChangeInLaborLoad;
  }

  getPercentChangeForUsers(loadInLast4Weeks: any, loadInNext7Days: any) {
    const userWiseCountMapForLast4Weeks = this.helperService.computeUsersToEventsMap(loadInLast4Weeks, this.users);

    const userWiseCountArrayForLast4Weeks = Array.from(userWiseCountMapForLast4Weeks, ([name, count]) => ({ name, count }));

    const userWiseCountMapForNext7Days = this.helperService.computeUsersToEventsMap(loadInNext7Days, this.users);

    const userWiseCountArrayForNext7Days = Array.from(userWiseCountMapForNext7Days, ([name, count]) => ({ name, count }));

    // compute percent change for each user's load in last 4 weeks and next 7 days
    const userWisePercentChangeArray = userWiseCountArrayForLast4Weeks.map((user, index) => {
      // finding percent change by comparing last 4 weeks weekly task average with next 7 days total task amount
      let percentChange = Math.floor(((userWiseCountArrayForNext7Days[index].count - (user.count / 4)) / (user.count / 4)) * 100);
      return { ...user, percentChange };
    })

    return userWisePercentChangeArray;
  }

  getPercentChangeForTeams(loadInLast4Weeks: any, loadInNext7Days: any) {
    const teamWiseCountMapForLast4Weeks = this.helperService.computeTeamsToEventsMap(loadInLast4Weeks, this.teams);

    const teamWiseCountArrayForLast4Weeks = Array.from(teamWiseCountMapForLast4Weeks, ([name, count]) => ({ name, count }));

    const teamWiseCountMapForNext7Days = this.helperService.computeTeamsToEventsMap(loadInNext7Days, this.teams);

    const teamWiseCountArrayForNext7Days = Array.from(teamWiseCountMapForNext7Days, ([name, count]) => ({ name, count }));

    // compute percent change for each team's load in last 4 weeks and next 7 days
    const teamWisePercentChangeArray = teamWiseCountArrayForLast4Weeks.map((team, index) => {
      // finding percent change by comparing last 4 weeks weekly task average with next 7 days total task amount
      let percentChange = Math.floor(((teamWiseCountArrayForNext7Days[index].count - (team.count / 4)) / (team.count / 4)) * 100);
      return { ...team, percentChange };
    })

    return teamWisePercentChangeArray;
  }

  async fetchLaborLoadInLast4Weeks(eventsLast4Weeks: models.ChartEventInput[]) {
    // store for labor load on users in last 4 weeks
    const usersLaborLoad = {};
    const teamsLaborLoad = {};

    // find events assigned to a Team or a User in last 4 weeks
    for (const event of eventsLast4Weeks) {
      if (event.assignedTeamIds && event.assignedTeamIds.length > 0) {
        event.assignedTeamIds.forEach(teamId => {
          if (!teamsLaborLoad[teamId]) {
            teamsLaborLoad[teamId] = 1;
          } else {
            teamsLaborLoad[teamId]++;
          }
        })
      }

      if (event.assignedUserIds && event.assignedUserIds.length > 0) {
        event.assignedUserIds.forEach(userId => {
          if (!usersLaborLoad[userId]) {
            usersLaborLoad[userId] = 1;
          } else {
            usersLaborLoad[userId]++;
          }
        })
      }
    }

    return [usersLaborLoad, teamsLaborLoad];
  }

  async fetchLaborLoadInNext7Days(eventsNext7Days: models.ChartEventInput[]) {
    // store for labor load on users in last 4 weeks
    const usersLaborLoad = {};
    const teamsLaborLoad = {};

    // find events assigned to a Team or a User in last 4 weeks
    for (const event of eventsNext7Days) {
      if (event.assignedTeamIds && event.assignedTeamIds.length > 0) {
        event.assignedTeamIds.forEach(teamId => {
          if (!teamsLaborLoad[teamId]) {
            teamsLaborLoad[teamId] = 1;
          } else {
            teamsLaborLoad[teamId]++;
          }
        })
      }

      if (event.assignedUserIds && event.assignedUserIds.length > 0) {
        event.assignedUserIds.forEach(userId => {
          if (!usersLaborLoad[userId]) {
            usersLaborLoad[userId] = 1;
          } else {
            usersLaborLoad[userId]++;
          }
        })
      }
    }

    return [usersLaborLoad, teamsLaborLoad];
  }

  async findTopAndBottomPerformersData() {

    // find completed events count User wise for last 30 days;
    const userWiseCountForLastMonth = this.findUserWiseCompletedEventsCount(this.eventsInLastMonth);

    // find completed events count User wise for 90 days prior to last 30 days;
    const userWiseCountFor3MonthsPrior = this.findUserWiseCompletedEventsCount(this.events3MonthsPriorToLastMonth);

    // compute the average for completed tasks 90 days prior to 30 days
    const userWiseCountMapFor3MonthsPrior = this.helperService.computeUsersToEventsUserMap(userWiseCountFor3MonthsPrior, this.users);
    //const allCountsFor3MonthsPrior = Array.from(userWiseCountMapFor3MonthsPrior.values())
    // const sumOfCompletedEvents3MonthsPrior = allCountsFor3MonthsPrior.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // const averageForEvents3MonthsPrior = sumOfCompletedEvents3MonthsPrior / 3;
    const userWiseCountMap = this.helperService.computeUsersToEventsUserMap(userWiseCountForLastMonth, this.users);

    const userWiseCountArray = Array.from(userWiseCountMap, ([userId, obj]) => ({ userId, obj }));

    // compute percent increase for each user's completed events
    const userWisePercentIncreaseArray = userWiseCountArray.map((user) => {
      let total3MonthsPrior = userWiseCountMapFor3MonthsPrior.get(user.userId)
      let averageForEvents3MonthsPrior: number = (total3MonthsPrior.number ?? 0) / 3;
      let percentIncrease: number;
      if(averageForEvents3MonthsPrior == 0) { //default it to 1 if none
        if(user.obj.number > 0){
          averageForEvents3MonthsPrior = 1;
        } else{
          percentIncrease = 0;
        }
      } else {
        percentIncrease = user.obj !== null ? (Math.floor(((user.obj.number ?? 0) / averageForEvents3MonthsPrior) * 100)) - 100 : null;
      }
      return { name: user.obj.user.displayName, count: user.obj.number, percentIncrease };
    })
    // filter user wise count for top 3 performers from last month data
    // if more than 1 user has same count then sort alphabetically
    userWisePercentIncreaseArray.sort((a, b) => {
      // Compare percentIncrease values
      if (b.percentIncrease !== a.percentIncrease) {
        return b.percentIncrease - a.percentIncrease;
      }

      // If percentIncrease are the same, then compare counts
      if (b.count !== a.count) {
        return b.count - a.count;
      }


      // If counts are the same, compare usernames alphabetically
      return a.name.localeCompare(b.name);
    });
    // pick only top 3 performers
    return [userWisePercentIncreaseArray.slice(0, 3), userWisePercentIncreaseArray.reverse().slice(0,3), userWisePercentIncreaseArray];
  }

  findUserWiseCompletedEventsCount(listOfEvents: models.ChartEventInput[]) {
    let userWiseCompletedEventsCount = {};
    for (const _event of listOfEvents) {
      if (_event.completed) {
        // check which user has completed the event
        let discussions = _event.eventObj.discussions.slice().reverse();
        let userIdWhoCompletedThisEvent = discussions.find(item => item.discussionType === 'completed_status' && item.content === "true")?.userId;

        // check if user is not a Power User
        let completeUser = this.users.find(_user => _user.id === userIdWhoCompletedThisEvent);
        let userRole = completeUser ? completeUser.roleId : undefined;

        // add to the count of this user if not a Power User, 
        if (!userWiseCompletedEventsCount[userIdWhoCompletedThisEvent] && (userRole && userRole !== 'powerUser')) {
          userWiseCompletedEventsCount[userIdWhoCompletedThisEvent] = 1;
        } else {
          userWiseCompletedEventsCount[userIdWhoCompletedThisEvent]++;
        }
      }
    }

    return userWiseCompletedEventsCount;
  }

  getColorClass(percentChange: number) {
    let color: string;
    if (percentChange >= -10 && percentChange <= 10) {
      color = '#3AAA44';
    } else if (percentChange > 10 && percentChange < 25) {
      color = '#FF914D';
    } else if (percentChange >= 25) {
      color = '#FF3131';
    } else if (percentChange < -10 && percentChange > -25) {
      color = '#90B85A';
    } else if (percentChange <= -25) {
      color = '#C3DBA2';
    } else {
      color = '#43A047';
    }
    return color;
  }

  percentageChange: number;
  makeLaborLoadChart() {

    // destroy chart if already there
    if (this.laborLoadDoughnutChart) {
      this.laborLoadDoughnutChart.destroy();
    }

    let eventsThisWeek = this.eventsInNext7Days?.length;
    let averageOverLastMonth = this.eventsInLast4Weeks?.length / 4;
    this.percentageChange = eventsThisWeek / averageOverLastMonth;
    //this.percentageChange = 1.15

    let percentUnder = this.percentageChange > 1 ? 100 : this.percentageChange*100;

    const dataSet =  [
      {
        //label: 'Outer',
        data: [{
          x: percentUnder,
          y: 'Labor Load'
        }],
        backgroundColor: this.getColorClass(100-percentUnder),
      }
    ]

    if(this.percentageChange > 1) {
      let percentOver = (this.percentageChange-1)*100;
      dataSet.push(
        {
          //label: 'Over',
          data: [{
            x: percentOver,
            y: 'Labor Load'
          }],
          backgroundColor: this.getColorClass(percentOver),
        }
      )
    }

    const chartOptions: Chart.ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          offset: false,
          ticks: {
            //stepSize: 200, /* total/4 shows 0, 25%, 50%, 75%, 100% */             
            callback: function(value: number, index, values) {
                return value + '%';
            }
              // format: {
              //     style: 'percent'
              // }
          }
        },
        y: {
          stacked: true,
          offset: true,
          ticks: {
            display: false //this will remove only the label
          }
        }
      },
      //cutout: '30%',
      layout: {
        padding: {
          bottom: 0, // Adjust as needed
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: this.externalTooltipHandler
        }
      }
    };

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.laborLoadDoughnutChart = new Chart.Chart(ctx, {
      type: 'bar',
      labels: ['Test'],
      data: {
        datasets: dataSet,
      },
      options: chartOptions
    } as any);
  }

  getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'white';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.border = '1px solid lightgray';
      tooltipEl.style.color = 'black';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
  
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };
  
  externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = this.getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);
  
      const tableHead = document.createElement('thead');

      const thr = document.createElement('tr');
      thr.style.borderWidth = '0';

      const th = document.createElement('th');
      th.style.borderWidth = '0';

      const span = document.createElement('span');
      // span.style.background = colors.backgroundColor;
      // span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '150px';
      span.style.display = 'inline-block';

      let message: string = '';
      let percentChange = this.percentageChange*100;
      if(percentChange > 100) {
        let over = Math.floor(percentChange - 100);
        message = `Labor load is ${over}% above average capacity over the next 7 days`;
      } else {
        message = `Labor load is ${Math.floor(percentChange)}% of average capacity over the next 7 days`;
      }

      const textMessage = document.createTextNode(message);

      span.appendChild(textMessage);
      th.appendChild(span);
      thr.appendChild(th);
      tableHead.appendChild(thr);
  
      // titleLines.forEach(title => {
      //   const tr = document.createElement('tr');
      //   tr.style.borderWidth = '0';
  
      //   const th = document.createElement('th');
      //   th.style.borderWidth = '0';
      //   const text = document.createTextNode(title);
  
      //   th.appendChild(text);
      //   tr.appendChild(th);
      //   tableHead.appendChild(tr);
      // });
  
      // const tableBody = document.createElement('tbody');
      // const tr = document.createElement('tr');
      // tr.style.backgroundColor = 'inherit';
      // tr.style.borderWidth = '0';

      // const td = document.createElement('td');
      // td.style.borderWidth = '0';

      // const span = document.createElement('span');
      // // span.style.background = colors.backgroundColor;
      // // span.style.borderColor = colors.borderColor;
      // span.style.borderWidth = '2px';
      // span.style.marginRight = '10px';
      // span.style.height = '10px';
      // span.style.width = '150px';
      // span.style.display = 'inline-block';

      // td.appendChild(span);
      // const text = document.createTextNode('');
      // td.appendChild(text);
      // tr.appendChild(td);
      // tableBody.appendChild(tr);

      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      //tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = (positionX + tooltip.caretX - 100) + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };
}
