import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-harvest-select',
  templateUrl: './harvest-select.component.html',
  styleUrls: ['./harvest-select.component.scss']
})
export class HarvestSelectComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() includeCompleted: boolean = false;
  @Input() includeConnectedLeaflogix: boolean = true;
  @Input() expanded: boolean = false;

  harvests: Array<models.Harvest> = [];
  public filteredHarvests = this.harvests.slice();
  
  @Input()
  get harvest() {
    return this.harvests.find(i => i.uid == this.harvestId);
  }
  @Output() harvestChange = new EventEmitter();
  set harvest(val) {
    if(val == null){
      return;
    }
    this.harvestId = val.uid;
    this.harvestChange.emit(val);
  }

  harvestIDValue: string;
  @Input()
  get harvestId() {
    return this.harvestIDValue;
  }
  @Output() harvestIdChange = new EventEmitter();
  set harvestId(val) {
    this.harvestIDValue = val;
    //this.setForm();
    this.harvestIdChange.emit(this.harvestIDValue);
    this.harvestChange.emit(this.harvest);
  }

  getDates(harvest: models.Harvest): string {
    if(!this.expanded || harvest.startDate == null || harvest.endDate == null){
      return '';
    }

    return ` - ${harvest.startDate.toDate().toLocaleDateString()} - ${harvest.endDate.toDate().toLocaleDateString()}`
  }

  constructor(
    private harvestService: services.HarvestService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getHarvests();
  }

  // harvestChange(newValue) {
  //   this.selectedHarvest = newValue;
  // }

  async getHarvests() {
    (await this.harvestService.getAll(!this.includeCompleted, false)).snapshotChanges().subscribe(snapshot => {
      let harvests = [];
      let data = snapshot
      data.forEach(element => {
        let harvest: models.Harvest = element.payload.doc.data();
        harvest.uid = element.payload.doc.id;

        if(this.includeConnectedLeaflogix || harvest.leaflogixId == null){
          harvests.push(harvest);
        }
      });
      
      this.harvests = this.helperService.sortByDateFieldDesc('name', harvests);
      this.filteredHarvests = this.harvests.slice();

      if(this.harvests.length != 0 && this.harvestId == null) {
        //this.harvestChange(this.harvests[0]);
        this.harvestId = this.harvests[0].uid;
      }

      this.harvestChange.emit(this.harvest);

      // if(!this.needToPickHarvest){
      //   this.selectedHarvest = this.harvests.find(i => i.uid == this.initialHarvestId);
      // }
    })
  }

}
