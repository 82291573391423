import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from '../../models';

@Component({
  selector: 'app-notification-token',
  templateUrl: './notification-token.component.html',
  styleUrls: ['./notification-token.component.scss']
})
export class NotificationTokenComponent implements OnInit {

  timeframes: Array<any> = [
    { key: 'allTime', display: 'Today and Earlier'},
    { key: 'thisMonth', display: 'This Month'},
    { key: 'thisWeek', display: 'This Week'},
    { key: 'today', display: 'Today'}
  ]
  timeframe: any;

  assigned: boolean = false;
  incomplete: boolean = false;

  tokens = new models.Tokens().tokens;

  get token() : models.Token {
    let token = this.tokens.find(i => 
      i.assigned == this.assigned && 
      i.incomplete == this.incomplete &&
      i.timeframe == this.timeframe)

    return token;
  }

  constructor(
    private dialogRef: MatDialogRef<NotificationTokenComponent>
    , @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(this.token.token);
  }

}