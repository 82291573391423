import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileService } from 'app/services';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  imageFor: string;
  formSubmitted: boolean = false;
  public imagePath;
  imgURL: any;
  fileToUpload: File = null;
  public message: string;

  constructor(
    private dialogRef: MatDialogRef<ImageUploadComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private snackBar: MatSnackBar
    , private fileService: FileService) { 
      if (data.imageFor) {
        this.imageFor = data.imageFor
      }
    }

  ngOnInit(): void {
  }

  preview(files: FileList) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
 
    var reader = new FileReader();
    this.imagePath = files;
    this.fileToUpload = files.item(0);
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

  browse() {
    document.getElementById('selectedFile').click();
  }

  submit() {
    //must pick a file
    if(this.fileToUpload == null){
      this.snackBar.open('Must pick a file to upload.');
      return;
    }

    //file must not be over a certain side (100kb)
    if(this.fileToUpload.size > 100000){
      this.snackBar.open('The file selected is too big.  Please select a file that is 100KB or less.');
      return;
    }

    this.formSubmitted = true;

    this.fileService.upload(this.imageFor === 'company' ? `companyImage/{companyId}` : `userImage/{userId}`, this.fileToUpload, this.imageFor).then(data => {
      this.formSubmitted = false;
  
      this.dialogRef.close(true);
    }, error => {
      this.snackBar.open('There was an issue uploading your file.')
    });
  }
}
