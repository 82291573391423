<ul class="sessions">
    <li *ngFor="let h of listDisplay; let i = index">
        <div class="flex">
            <div>
                <div class="time">{{h.date | date:'M/d/yyyy'}}</div>
                <p>{{h.description}}</p>
            </div>
        </div>
    </li>
</ul>

<!-- <app-timeline [dataList]="timelineData"></app-timeline> -->
