import { Timestamp } from "firebase/firestore";

export interface Subtask {
    id?: string;
    title?: string;
    description?: string;
    completed?: boolean;
    completedUserId?: string;
    completedDate?: Timestamp;
}


export interface SubtaskDisplay {
    displayDescription: boolean;
    subtask: Subtask;
}