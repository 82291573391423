import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-group-select-multi-btn',
  templateUrl: './task-group-select-multi-btn.component.html',
  styleUrls: ['./task-group-select-multi-btn.component.scss']
})
export class TaskGroupSelectMultiBtnComponent implements OnInit, OnDestroy {

  groups: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;

  @Input() showGroupsForAnalytics: boolean = false;
  @Input() startWithNoneSelected: boolean = false;

  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);

    this.selectedGroups = this.groups.filter(i => this.selected.includes(i.id));
  }
  @Output() onClosed = new EventEmitter();

  initialLoad: boolean = false;
  @Output() loaded = new EventEmitter();

  companySub: Subscription;

  selectedGroupsValue: Array<viewmodels.MultiSelect>;
  @Input()
  get selectedGroups() {
    return this.selectedGroupsValue;
  }
  @Output() selectedGroupsChange = new EventEmitter();
  set selectedGroups(val) {
    if (val == null) {
      val = [];
    }
    this.selectedGroupsValue = val;
    this.selectedGroupsChange.emit(this.selectedGroupsValue);
  }

  constructor(
    private groupService: services.GroupService
    , private helperService: services.HelperService
    , private appStorageService: services.AppStorage
  ) { }

  ngOnInit(): void {
    if(this.helperService.currentCompanyId != null){
      this.getGroups();
    }

    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.initialLoad = false;
      this.selected = [];
      this.getGroups();
    })
  }

  ngOnDestroy(): void {
    if(this.companySub){
      this.companySub.unsubscribe();
    }
  }

  loading: boolean = false;
  async getGroups() {
    if(this.loading){
      return;
    }
    this.loading = true;
    let groups = await this.groupService.getAllModels()
    if(!this.initialLoad && !this.showGroupsForAnalytics) { //set selected from saved data only if it is first time loading or not showing groups for Analytics
      let filterData = this.appStorageService.companyFiltersObject;
      if(filterData != null){
        this.selected = filterData.groupIds;// filterData ? filterData['companyFilters']['groupIds'] : [];
      }
    } else {
      this.selected = [];
    }
    let list = groups.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i.uid,
        displayValue: i.name,
        selected: this.setSelected(i),
      }
      return h;
    })
    this.groups = this.helperService.sortArrayByStringField(list, 'displayValue');

    // if(this.selected.length == 0){
    //   this.selected = this.groups.map(i => i.id);
    // }
    this.selected = this.groups.filter(i => i.selected).map(i => i.id)

    // this.groups.forEach(group => {
    //   if(this.selected.includes(group.id)){
    //     group.selected = true;
    //   }
    // })

    //if(!this.initialLoad) {
      this.loaded.emit();
      this.initialLoad = true;
    //}
    this.loading = false;
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }

  setSelected(i: models.Harvest) {
    if(this.startWithNoneSelected) {
      return false;
    }
    
    return this.selected.length == 0 ? true : this.selected.includes(i.uid)
  }
}
