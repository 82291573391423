<h2 mat-dialog-title>Cloud Storage Url</h2>
<mat-dialog-content>
  <form id="url" class="user-form" (ngSubmit)="submit()">
    <mat-form-field>
      <input matInput placeholder="URL" name="url" required [(ngModel)]="url">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="url">
    Upload
  </button>
</mat-dialog-actions>
