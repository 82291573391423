import { Event } from "app/models";

export interface EventUpdateDialogResult {
    save?: boolean;
    delete?: boolean;
    eventIdsToDelete?: string[];
    harvestId?: string;
    groupId?: string;
    event?: Event;
    eventList?: Event[];
    automaticallyCompletedEvent?: boolean;
}
