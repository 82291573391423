import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as models from '../../models';
import * as services from '../../services';

@Component({
  selector: 'app-harvest-edit',
  templateUrl: './harvest-edit.component.html',
  styleUrls: ['./harvest-edit.component.scss']
})
export class HarvestEditComponent implements OnInit {
  saveClick: boolean = false;
  harvest: models.Harvest = {};

  constructor(
    private dialogRef: MatDialogRef<HarvestEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private harvestService: services.HarvestService,
    private snackBar: MatSnackBar
  ) {
    this.harvest.uid = data.uid;
    this.harvest.name = data.name;
    this.harvest.completed = data.completed;
  }

  ngOnInit() {
  }

  saveHarvest() {
    this.saveClick = true;
    this.harvestService.update(this.harvest).then(data => {
      this.snackBar.open('Successfully Saved Harvest', null, {
        duration: 3000,
        verticalPosition: 'top'
      })
      this.dialogRef.close(true);
    }).catch(error => {
      this.snackBar.open('Unable to save Harvest', null, {
        duration: 3000,
        verticalPosition: 'top'
      })
      this.saveClick = false;
    })
  }

}
