<!-- <mat-chip-list aria-label="Fish selection">
    <mat-chip *ngFor="let chip of chips; let i = index">
        <app-zone-identifiers-detail [chip]="chip" [lastInList]="chips.length == i+1"></app-zone-identifiers-detail>
    </mat-chip>
</mat-chip-list> -->

<div style="float: left; margin-right: 20px" *ngIf="totalZoneCountForStrain != 0">
    <span style="font-weight: bold; color: #43a047;">[{{totalZoneCountForStrain}}]</span>
</div>
<ul>
    <li *ngFor="let zone of zoneIdentifiers">
        {{zone.name}} <span *ngIf="zone.placements != null" style="font-size: 14px; color: #43a047">({{zone.placements}})</span>
        <ng-container *ngIf="zone.zones != null && zone.zones.length > 0">
            <app-zone-identifiers-detail [zones]="zone.zones"></app-zone-identifiers-detail>
        </ng-container>
    </li>
</ul>
