import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    Component,
    ElementRef,
    ViewChild,
    ViewContainerRef,
  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
  import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
  
  @Component({
    selector: 'editor-cell',
    template: `
    <div class="chip-edit">
      <div style="padding-bottom: 5px">
          <div style="color: lightgrey; text-align: right">
              <mat-icon style="vertical-align: middle;">keyboard_return</mat-icon> <span>to close</span>
          </div>
      </div>
      <mat-form-field>
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipList aria-label="Chips">
          <mat-chip
              *ngFor="let chip of chips"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(chip)">
              {{chip}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
              placeholder="New..."
              #chipInput
              [formControl]="chipCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
              {{chip}}
          </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
    `,
    styles: [
      `
      .chip-edit{
        padding:20px;
      }
      `
    ],
  })
  export class TagEditorCell implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
    chipCtrl = new FormControl();
    filteredChips: Observable<string[]>;

    isPopup(): boolean {
      return true;
    }

    allChips: Array<string> = [];
    chips: Array<string> = [];
    
    @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
    //@ViewChild('container', { read: ViewContainerRef })
    //public container!: ViewContainerRef;
  
    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
    //   window.setTimeout(() => {
    //     this.container.element.nativeElement.focus();
    //   });
    this.filteredChips = this.chipCtrl.valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filter(fruit) : this.allChips.slice()));
    }
  
    agInit(params: any): void {
      this.params = params;
      this.allChips = this.params.allTags;
      this.chips = this.params.value;
    }
  
    getValue(): any {
      return this.chips;
    }

    add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;
  
      if(this.chips == null){
        this.chips = [];
      }
  
      // Add our fruit
      if ((value || '').trim()) {
        this.chips.push(value.trim());
      }
  
      // Reset the input value
      if (input) {
        input.value = '';
      }
  
      this.chipCtrl.setValue(null);
    }
  
    remove(fruit: string): void {
      const index = this.chips.indexOf(fruit);
  
      if (index >= 0) {
        this.chips.splice(index, 1);
      }
    }
  
    selected(event: MatAutocompleteSelectedEvent): void {
      if(this.chips == null){
        this.chips = [];
      }
      this.chips.push(event.option.viewValue);
      this.chipInput.nativeElement.value = '';
      this.chipCtrl.setValue(null);
    }
  
    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
  
      return this.allChips.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
    }
  }