import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as services from '../../services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  emailAddress: string;
  working: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ForgotPasswordComponent>
    , private snackBar: MatSnackBar
    , private authService: services.AuthService) { }

  ngOnInit() {
  }

  sendEmail() {

    if(this.emailAddress == null || this.emailAddress === ''){
      this.snackBar.open('Must enter in an email to request a new password.')
      return;
    }

    this.working = true;
    this.authService.sendPasswordResetLink(this.emailAddress).then(data => {
      this.snackBar.open('Email has been sent.  Check your email inbox.', null, {
        duration: 3000,
        verticalPosition: 'top'
      });
  
      this.dialogRef.close();

    }).catch(error => {
      this.snackBar.open(error, null, {
        duration: 3000,
        verticalPosition: 'top'
      });
      this.working = false;
    });

  }

}
