import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import * as models from '../../../models';
import { Subscription } from 'rxjs';
import * as services from 'app/services';
import * as viewmodels from '../../../viewmodels';

@Component({
  selector: 'app-workflow-select',
  templateUrl: './workflow-select.component.html',
  styleUrls: ['./workflow-select.component.scss']
})
export class WorkflowSelectComponent implements OnInit {


  companySub: Subscription;
  workflows: viewmodels.TemplateListViewModel[] = [];
  public filteredWorkflows = this.workflows.slice();

  @Input()
  get workflow() {
    return this.workflows.find(i => i.id == this.workflowId);
  }

  @Output() workflowChange = new EventEmitter();
  set workflow(val) {
    if(val == null){
      return;
    }
    this.workflowId = val.id;
    this.workflowChange.emit(val);
  }

  workflowIDValue: string;
  @Input()
  get workflowId() {
    return this.workflowIDValue;
  }
  @Output() workflowIdChange = new EventEmitter();
  set workflowId(val) {
    this.workflowIDValue = val;
    //this.setForm();
    this.workflowIdChange.emit(this.workflowIDValue);
    this.workflowChange.emit(this.workflow);
  }

  constructor(
    private helperService: services.HelperService
    , private templateService: services.TemplateService
  ) { }

  ngOnInit(): void {
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.getWorkflows();
    }

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.getWorkflows();
    })
  }

  ngOnDestory() {
    if(this.companySub != null) {
      this.companySub.unsubscribe();
    }
  }

  async getWorkflows() {
    let templates = await this.templateService.getAll(true);
    let _workflows: Array<viewmodels.TemplateListViewModel> = [];
    templates.forEach(template => {      
        let vm: viewmodels.TemplateListViewModel = {
          id: template.uid,
          name: template.name
      }
      _workflows.push(vm);
    })
    this.workflows = this.helperService.sortArrayByStringField(_workflows,'name');
    this.filteredWorkflows = this.workflows.slice();
    if(this.workflows.length != 0 && this.workflowId == null) {
      this.workflowId = this.workflows[0].id;
    }
    this.workflowChange.emit(this.workflows[0]);
  }
}
