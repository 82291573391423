import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';

import * as services from 'app/services';
import * as viewModels from 'app/viewmodels';
import * as models from 'app/models'
import { ExportToCsv, Options } from 'export-to-csv';

@Component({
  selector: 'app-strain-upload-dialog',
  templateUrl: './strain-upload-dialog.component.html',
  styleUrls: ['./strain-upload-dialog.component.scss']
})
export class StrainUploadDialogComponent implements OnInit {

  saveClicked: boolean = false;
  fileToUpload: File | null = null;

  constructor(
    private dialogRef: MatDialogRef<StrainUploadDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private ngxCsvParser: NgxCsvParser
    , private notifierService: services.NotifierService
    , private strainService: services.StrainService
  ) { }

  ngOnInit(): void {
  }

  downloadExample() {
    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false, 
      showTitle: false,
      title: 'Example Strain Import',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: 'Example Strain Import',
      //headers: ['Phase', 'Day in Phase', 'Task Description', 'High Priority', "Anytime", "Start Time", "Cloud Attachments", "Notes"]
    };

    let csvExporter = new ExportToCsv(options);

    let strainSteps: Array<models.Strain> = [
      {
        name: 'strains',
      }
    ];

    csvExporter.generateCsv(strainSteps);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  } 

  upload() {
    // if(this.strainName == null || this.strainName == ''){
    //   this.notifierService.error('Must specify a name for new Strain');
    //   return;
    // }

    if(this.fileToUpload == null){
      this.notifierService.error('Must select a file to upload');
      return;
    }

    this.saveClicked = true;

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(this.fileToUpload, { header: true, delimiter: ',' })
      .pipe().subscribe((result: Array<viewModels.StrainExport>) => {

        this.validate(result);
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
        this.notifierService.error('There was an error uploading file');
        this.saveClicked = false;
      });
  }

  async validate(results: Array<viewModels.StrainExport>) {
    let errors: Array<boolean> = [];
    let validatedRows: Array<viewModels.StrainExport> = [];

    for (let i = 0; i < results.length; i++) {
      const result = new viewModels.StrainExport(results[i]);

      if(!result.validate()){
        errors.push(true);
      }

      validatedRows.push(result);
    }

    if(errors.length == results.length) {
      this.notifierService.error(`Upload file must have a header row with 'strains' in it.  Please verify that is in place and attempt upload again.`);
      this.saveClicked = false;
      return;
    } else if(errors.length > 0){
      this.notifierService.error(`There ${errors.length > 1 ? 'were' : 'was'} ${errors.length} row${errors.length > 1 ? 's' : ''} with errors in the uploaded file`);
      this.saveClicked = false;
      return;
    }

    this.import(validatedRows);
  }

  async import(result: Array<viewModels.StrainExport>) {
    await this.strainService.import(result);
    this.dialogRef.close(true)
    this.saveClicked = false;
  }

}
