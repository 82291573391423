<div class="wrapper">
  <app-navbar></app-navbar>
  <div class="main-content">
      <div class="row">

        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Select Company</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="loading-container" *ngIf="loading">
              <mat-spinner style="margin: auto" diameter=25 strokeWidth=4></mat-spinner>
            </div>
            <div class="list-group" id="list-tab" role="tablist" *ngIf="!loading">
              <a class="list-group-item list-group-item-action" (click)="companyClick(c)" [class.active]="c.selected" *ngFor="let c of companies">{{c.name}}</a>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button (click)="okClick()" mat-button>OK</button>
          </mat-card-actions>
        </mat-card>

      </div>
      <!-- <div class="center-container">
        <div class="center-div"> -->
        <!-- </div>
      </div> -->
  </div>
  <app-footer></app-footer>
</div>