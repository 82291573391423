import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "icon-cell",
    template: `
        <mat-icon aria-hidden="false" style="vertical-align: middle" *ngIf="!disabled" aria-label="Example home icon">done</mat-icon>
        <mat-icon aria-hidden="false" style="vertical-align: middle" *ngIf="disabled" aria-label="Example home icon">cancel</mat-icon>

    `,
    styles: [
    ]
})
export class MatIconComponent implements ICellRendererAngularComp {
    private params: any;

    disabled: boolean = false;

    agInit(params: any): void {
        this.params = params;
        this.disabled = this.params.value;
    }

    refresh(params: any): boolean {
        return false;
    }
}