import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import { HelperService } from './helper.service';

@Injectable()
export class QuestionService {

    companyRef: AngularFirestoreCollection;

    constructor(
        private firestore: AngularFirestore
        , private helperService: HelperService
    ) {
        this.companyRef = this.firestore.collection('companies');
    }


    async add(question: models.Question){

        // let uid = question.uid;
        // delete question.uid;

        await this.firestore.collection('questions').add(question);
    }
}
