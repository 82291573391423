import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpsCallableResult } from 'firebase/functions';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-change-color-dialog',
  templateUrl: './change-color-dialog.component.html',
  styleUrls: ['./change-color-dialog.component.scss']
})
export class ChangeColorDialogComponent implements OnInit {

  saving: boolean = false;

  type: string = 'harvest';
  id: string;
  name: string;
  color: string;

  get saveAsGenericGroup() {
    return this.type != 'harvest';
  }

  constructor(
    private dialogRef: MatDialogRef<ChangeColorDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private harvestService: services.HarvestService
    , private notifierService: services.NotifierService
  ) { 
    if(data) {
      this.id = data.id;
      this.color = data.color;
    }
  }

  ngOnInit(): void {
  }

  async save() {
    this.saving = true;

    let response: HttpsCallableResult<models.Result<any>> = await this.harvestService.updateColor(this.id, null, this.color, this.name);

    if(response.data.success) {
      this.notifierService.success('Successfully updated Color');
      this.dialogRef.close({
        color: this.color
      })
    } else {
      this.notifierService.brokenRules(response.data.brokenRules);
    }
    this.saving = false;
  }
}
