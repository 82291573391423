import {
    AfterViewInit,
    Component,
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'number-editor-cell',
    template: `
    <mat-form-field>
        <input matInput type="number" [(ngModel)]="value">
    </mat-form-field>
    `,
    styles: [
        `
      .cont{
        padding:20px;
      }
      `
    ],
})
export class NumberEditorCell implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    value: number;

    isPopup(): boolean {
        return false;
    }

    ngAfterViewInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value || 0;
    }

    getValue(): any {
        return this.value;
    }
}
