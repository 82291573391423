<mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{notification.name}}
      </mat-panel-title>
      <mat-panel-description>
        {{getTitle(notification)}}
        <!-- <mat-icon>notifications</mat-icon> -->
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="row">
      <div class="col-sm-4">
        <mat-form-field>
          <mat-label>Notification Name</mat-label>
          <input matInput [(ngModel)]="notification.name">
        </mat-form-field>
      </div>
      <div class="col-sm-8">
        <div style="float: right;">
          <mat-checkbox style="padding-right: 15px;" [(ngModel)]="notification.enabled">Enabled</mat-checkbox>
          <button mat-raised-button class="pad-btn" title="Remove Notification" (click)="delete()">
            <mat-icon aria-hidden="false" aria-label="Remove Notification">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <mat-form-field>
          <mat-label>Frequency</mat-label>
          <mat-select [(ngModel)]="notification.frequency" (selectionChange)="frequencyChange($event, notification)">
            <mat-option [(value)]="f.key" *ngFor="let f of frequencies">{{f.display}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-2" *ngIf="notification.frequency == 'weekly'">
        <mat-form-field>
          <mat-label>Day of Week</mat-label>
          <mat-select [(ngModel)]="notification.dayOfWeek">
            <mat-option [(value)]="d.key" *ngFor="let d of daysOfWeek">{{d.display}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-2" *ngIf="notification.frequency == 'monthly'">
        <mat-form-field>
          <mat-label>Day of Month</mat-label>
          <mat-select [(ngModel)]="notification.dayOfMonth">
            <mat-option [(value)]="d.key" *ngFor="let d of daysOfMonth">{{d.display}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-2">
        <!-- <mat-form-field class="full-width">
            <input min="1" max="12" type="number" #input matInput [(ngModel)]="notification.hour" placeholder="Time">
        </mat-form-field> -->
        <mat-form-field>
          <mat-label>Time</mat-label>
          <mat-select [(ngModel)]="notification.hour">
            <mat-option [(value)]="h.key" *ngFor="let h of hours">{{h.display}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-2">
        <mat-form-field>
          <mat-label>AM/PM</mat-label>
          <mat-select [(ngModel)]="notification.ampm">
            <mat-option value="am">am</mat-option>
            <mat-option value="pm">pm</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-2" *ngIf="notification.frequency == 'daily'">
      </div>
      <div class="col-sm-4">
        <mat-form-field>
            <mat-label>Role(s)</mat-label>
            <mat-select [(ngModel)]="selectedRoles" multiple>
                <mat-select-trigger>
                  {{selectedRoles.length == roles.length ? 'ALL' : ''}}
                  {{selectedRoles.length != roles.length && selectedRoles.length > 0 ? selectedRoles[0].name : ''}}
                  <span *ngIf="selectedRoles.length != roles.length && selectedRoles?.length > 1" class="example-additional-selection">
                    (+{{selectedRoles.length - 1}} {{selectedRoles?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option [value]="role" *ngFor="let role of roles">{{role.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field>
          <mat-label>Role</mat-label>
          <mat-select [(value)]="notification.role">
            <mat-option *ngFor="let role of roles" [value]="role.precedence">
              {{role.name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-checkbox [(ngModel)]="assigned">Only Tasks Assigned to User</mat-checkbox> <br />
        <mat-checkbox [(ngModel)]="incomplete">Exclude Complete Tasks</mat-checkbox>
      </div>
      <div class="col-sm-8">
        <p>Example: <b>{{token.example}}</b></p>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-12">
        <button style="float: right;" (click)="addTokenClick(n)" mat-button>Add Token</button>
      </div>
    </div> -->

</mat-expansion-panel>