<h2 mat-dialog-title>Edit Harvest</h2>
<mat-dialog-content>
  <form id="editHarvest" class="harvest-form" (ngSubmit)="saveHarvest()">

    <mat-form-field class="user-full-width">
      <input matInput placeholder="Harvest Name" type="text" required name="name" [(ngModel)]="harvest.name">
    </mat-form-field>

    <mat-checkbox name="completed" [(ngModel)]="harvest.completed">Completed</mat-checkbox>
  
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClick">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="editHarvest" [disabled]="saveClick">
    <mat-spinner *ngIf="saveClick" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClick">Save</span>
  </button>
</mat-dialog-actions>
