import { Injectable } from '@angular/core';
import { EventApi, EventInput } from '@fullcalendar/core';
import { HelperService } from '../helper.service';

import * as models from 'app/models';
import { ZoneService } from '../zone.service';

@Injectable()
export class CalendarEventService {

    constructor(
        private helperService: HelperService
        , private zoneService: ZoneService
    ) { }

    getClassName(event: models.Event, type: string) {

        let className: string = '';
        if (event.completed) {
            className = 'completed'
        }
        if (event.highPriority && !event.completed) {
            className += ' high-priority';
        }
        if (type == 'groups') {
            className += ' task-group-event';
        }

        return className;
    }

    getTitle(event: models.Event, type: string, zoneIdentifier: string, users: models.User[], teams: models.Team[]) {
        //get the title of the event
        let title: string = `${type == 'harvests' ? event.harvestName : event.groupName}${zoneIdentifier != null && zoneIdentifier != '' ? ' (' + zoneIdentifier + ') ' : ''}: ${event.description}`;
        //if the assigned team ids is not empty or null
        if (event.assignedTeamIds !== undefined && event.assignedTeamIds.length !== 0) {
            title = `${title} - Teams: `;

            const filteredTeams = teams.filter(i => (event.assignedTeamIds ?? []).includes(i.uid)).map(i => i.name);

            title += this.helperService.concatToSentence(filteredTeams);
        }
        //if the assigned user ids is not empty or null
        if (event.assignedUserIds !== undefined && event.assignedUserIds.length !== 0) {
            title = `${title} - Users: `;

            const filteredUsers = users.filter(i => (event.assignedUserIds ?? []).includes(i.id)).map(i => i.displayName);

            title += this.helperService.concatToSentence(filteredUsers);
        }

        return title;
    }

    getTooltip(event: models.Event, type: string) {
      return `${type == 'harvests' ? event.harvestName : event.groupName}<br>` + `${event.description}<br>`
    }

    getZoneIdentifier(event: models.Event, allZones: models.Zone[], harvests: models.Harvest[]) {
        //get zone identifier
        let zoneIdentifier = null;
        if (event.harvestName != null && allZones.length > 0) {
          let zoneIds = this.getZoneIds(event.parentId, event.phase, harvests);
          let zones = this.zoneService.getAllTopLevel(allZones, zoneIds);
          zoneIdentifier = zones.map(i => i.name).join(', ');
        }

        return zoneIdentifier;
    }

    getZoneIds(id: string, phase: string, harvests: models.Harvest[]) {
      let zoneIds: string[] = [];
      let harvest = harvests.find(i => i.uid == id);
      if (harvest == null) {
        return zoneIds;
      }
      let currentPhase = harvest.phases?.find(i => i.phaseName == phase);
      if (currentPhase?.strains == null) {
        return zoneIds;
      }
      currentPhase.strains.forEach(strain => {
        if (strain.zones == null) {
          return;
        }
        strain.zones.forEach(zone => {
          if (!zoneIds.includes(zone.zoneId)) {
            zoneIds.push(zone.zoneId);
          }
        });
      });
  
      return zoneIds;
    }


    convertEventToInput(event: models.Event, users: models.User[], teams: models.Team[], zones: models.Zone[], harvests: models.Harvest[]): EventInput {
        //set the color and completed value of the event
        let color: string = event.color;

        let type: string = event.harvestName != null && event.harvestName != '' ? 'harvests' : 'groups';

        let className: string = this.getClassName(event, type);

        let zoneIdentifier: string = this.getZoneIdentifier(event, zones, harvests);
        let title = this.getTitle(event, type, zoneIdentifier, users, teams);
        let tooltip = this.getTooltip(event, type);

        let input: EventInput = {
            id: event.id,
            title: title,
            date: event.startDateTime.toDate(),
            allDay: event.anyTime !== false,
            color: color,
            className: className,
            eventObj: event,
            type: type,
            parentId: event.parentId,
            tooltip: tooltip
        }

        return input;
    }

    updateEvent(eventApi: EventApi, event: models.Event, users: models.User[], teams: models.Team[], zones: models.Zone[], harvests: models.Harvest[]) {

        let type: string = event.harvestName != null && event.harvestName != '' ? 'harvests' : 'groups';

        let className: string = this.getClassName(event, type);
        let classNames = className.split(' ');

        let zoneIdentifier: string = this.getZoneIdentifier(event, zones, harvests);
        let title = this.getTitle(event, type, zoneIdentifier, users, teams);
        let tooltip = this.getTooltip(event, type);

        // if the event exists in both lists, update its properties
        eventApi.setProp('title', title);
        eventApi.setStart(new Date(event.startDateTime.seconds * 1000));
        eventApi.setAllDay(event.anyTime !== false);
        eventApi.setProp('color', event.color);
        eventApi.setProp('classNames', classNames);
        eventApi.setExtendedProp('eventObj', event);
        eventApi.setExtendedProp('type', type);
        eventApi.setExtendedProp('parentId', event.parentId);
        eventApi.setExtendedProp('tooltip', tooltip);
    }
}
