import { Component, OnInit, Input, NgZone, Optional } from '@angular/core';
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() perminantBackground: boolean = false;
  @Input() displayGoToConsole: boolean = true;
  @Input() displayLogin: boolean = true;

  get perminantBackgroundClass(): string {
    return this.perminantBackground == true ? 'perm_background' : '';
  };

  private userDisposable: Subscription|undefined;
  public user: User;
  name: string = '';
  // get userLoggedIn(): boolean  {
  //   return this.user != null;
  // };
  userLoggedIn: boolean = false;

  constructor(private ngZone: NgZone, @Optional() private auth: Auth) { }

  async ngOnInit() {
    //this.user = await firebase.auth().currentUser;
    this.monitorAuthStatus();
  }

  monitorAuthStatus(): void {
    if (this.auth) {
      this.userDisposable = authState(this.auth).subscribe(user => {
        if(user) {
          this.setUser(user);
        }
      });
    }
  }

  setUser(user: User) {
    this.ngZone.run( () => {
      this.user = user;
      this.name = user.displayName != null ? user.displayName : user.email;
      this.userLoggedIn = true;
    });
  }

  async logout() {
    await this.auth.signOut();
    window.location.href = '/';
  }

}
function traceUntilFirst(arg0: string): import("rxjs").OperatorFunction<User, unknown> {
  throw new Error('Function not implemented.');
}

