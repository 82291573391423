
export interface DbStrain {
    list?: Array<Strain>;
}

export interface Strain {
    id?: string;
    name?: string;
    //cloneCount?: number;
    externalId?: string
}

// export interface StrainPhase {
//     phaseName?: string;
//     count?: number;
// }

// export interface HarvestStrainPhases extends Strain {
//     phases: Array<StrainPhase>;
// }