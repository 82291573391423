import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss']
})
export class GroupSelectComponent implements OnInit {

  @Input() disabled: boolean = false;

  groups: Array<models.Group> = [];
  public filteredGroups = this.groups.slice();
  
  @Input()
  get group() {
    return this.groups.find(i => i.uid == this.groupId);
  }
  @Output() groupChange = new EventEmitter();
  set group(val) {
    if(val == null){
      return;
    }
    this.groupId = val.uid;
    this.groupChange.emit(val);
  }

  groupIDValue: string;
  @Input()
  get groupId() {
    return this.groupIDValue;
  }
  @Output() groupIdChange = new EventEmitter();
  set groupId(val) {
    this.groupIDValue = val;
    //this.setForm();
    this.groupIdChange.emit(this.groupIDValue);
    this.groupChange.emit(this.group);
  }

  constructor(
    private groupService: services.GroupService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getGroups();
  }

  // groupChange(newValue) {
  //   this.selectedGroup = newValue;
  // }

  async getGroups() {
    (await this.groupService.getAll()).snapshotChanges().subscribe(snapshot => {
      let groups = [];
      let data = snapshot
      data.forEach(element => {
        let group: models.Group = element.payload.doc.data();
        group.uid = element.payload.doc.id;
        groups.push(group);
      });
      
      this.groups = this.helperService.sortArrayByStringField(groups, 'name');
      this.filteredGroups = this.groups.slice();

      if(this.groups.length != 0 && this.groupId == null) {
        //this.groupChange(this.groups[0]);
        this.groupId = this.groups[0].uid;
      }

      this.groupChange.emit(this.group);

      // if(!this.needToPickGroup){
      //   this.selectedGroup = this.groups.find(i => i.uid == this.initialGroupId);
      // }
    })
  }

}
