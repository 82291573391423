import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment-timezone';
import { LocalStorageService, LocalStorage } from 'angular-web-storage'

import * as models from '../../models';
import * as services from '../../services';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  HarvestAddComponent,
  EventUpdateComponent,
  HarvestEditComponent,
  EventAddComponent,
  UserSelectSingleComponent,
  HarvestGroupDeleteComponent,
  UsersAssignComponent,
  RestrictionNotificationComponent
} from '../../dialogs';
import { NgModel } from '@angular/forms';
import { AppStorage } from '../../services';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Unsubscribe, onSnapshot } from '@angular/fire/firestore';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy {

  @ViewChild("calendar") calendarComponent: FullCalendarComponent; // the #calendar in the template

  onlyMyTasks: boolean = false;
  filterUserId: string = undefined;

  company: models.Company;
  
  calendarPlugins = [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ]; // important!
  calendarEvents = [];
  calendarViews =  {
    dayGridMonth: { buttonText: this.deviceService.isMobile() ? 'M' : 'Month' },
    dayGridWeek: { 
      buttonText: this.deviceService.isMobile() ? 'W' : 'Week',
      eventLimit: 20
    },
    listDay: { buttonText: this.deviceService.isMobile() ? 'D' : 'Day' },
    //timeGridWeek: { buttonText: this.deviceService.isMobile() ? 'W3' : 'Week3' },
    listWeek: { buttonText: this.deviceService.isMobile() ? 'W' : 'Week' }
  };
  calendarHeader =  {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,listWeek,listDay'
  }
  viewType: string = 'dayGridMonth';
  viewStart: Date;
  viewEnd: Date;

  eventRenderer(info) {
    //debugger;
    let title = info.event.title;
    if(info.event.extendedProps.highPriority){
      title = '<i style="font-weight: bold;" class="fa fa-exclamation"></i> ' + info.event.title;
    }
    
    let element = info.el.querySelector('span.fc-title');
    if(element == null){
      element = info.el.querySelector('a')
    }
    element.innerHTML = title;//.append("<br/>" + );
    //element.find('.fc-event-title').html(info.event.title);
  }

  viewRender(i) {
    this.viewType = i?.view?.type;
    this.viewStart = i?.view?.activeStart;
    this.viewEnd = i?.view?.activeEnd;

    if(this.viewType == 'listWeek' && !this.weeklyListView) {
      this.viewType = 'dayGridWeek';
      this.calendarComponent.getApi().changeView('dayGridWeek');
    }
  }

  users: Array<models.User> = [];

  harvests: Array<models.Harvest> = [];
  selectedHarvests: Array<models.Harvest> = [];
  loadingHarvests: boolean = true;

  groups: Array<models.Group> = [];
  selectedGroups: Array<models.Group> = [];
  loadingGroups: boolean = true;

  dbEvents: Array<models.DbEvent> = [];
  eventSubs: Array<Unsubscribe> = [];

  companySub: Subscription;

  eventOrder (eventA, eventB){
    // let completedA: boolean = eventA.completed ? true : false;
    // let completedB: boolean = eventB.completed ? true : false;
    // if(completedA && !completedB){
    //   return 1;
    // }
    // if(!completedA && completedB){
    //   return -1;
    // }

    if(eventA.highPriority && !eventB.highPriority){
      return -1;
    }
    if(!eventA.highPriority && eventB.highPriority){
      return 1;
    }

    if(eventA.allDay && !eventB.allDay){
      return 1;
    }
    if(!eventA.allDay && eventB.allDay){
      return -1;
    }

    if(eventA.start < eventB.start){
      return -1;
    } else if (eventA.start > eventB.start){
      return 1;
    }

    let precA: number = eventA.precedence ? eventA.precedence : 0;
    let precB: number = eventB.precedence ? eventB.precedence : 0;
    return (precA > precB) ? -1 : 1;
  }

  get canDragEvents() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.editEvent);
  }

  get numberOfHarvests() : number {
    return this.harvests.length
  }

  get canAdd() : boolean {
    if(this.helperService.company_freeVersion && this.numberOfHarvests >= this.appStorage.freeHarvests) {
      return false;
    }

    return true;
  }

  get canSeeAddEventBtn() : boolean {
    let editEvent = this.claimsService.userRole?.permissions?.includes(models.Permissions.editEvent);
    if(editEvent && (this.viewType == 'listDay' || this.viewType == 'listWeek')){
      return true;
    }
    return false;
  }

  get canSeeWeeklyListSlider() : boolean {
    if(this.viewType == 'listWeek' || this.viewType == 'dayGridWeek'){
      return true;
    }
    return false;
  }

  constructor(
    private claimsService: services.ClaimsService
    , private harvestService: services.HarvestService
    , private groupService: services.GroupService
    , private eventService: services.EventService
    , private snackBar: MatSnackBar
    , public dialog: MatDialog
    , private deviceService: DeviceDetectorService
    , private userService: services.UserService
    , private helperService: services.HelperService
    , private companyService: services.CompanyService
    , private appStorage: AppStorage
    , private localStorage: LocalStorageService
  ) {

    let mobile: boolean = this.deviceService.isMobile();
    // this.calendarViews.dayGridMonth.buttonText = mobile ? 'M' : 'Month';
    // this.dayBtnTxt = mobile ? 'D' : 'D';
    // this.weekBtnTxt = mobile ? 'W' : 'W';
   }
  
  async ngOnInit() {
    //get companyId
    let companyId = this.helperService.currentCompanyId;

    //if we have it then load
    if(companyId != null){
      this.load(companyId);
    }

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load(data);
    })

    //this.getBtnText();

    this.weeklyListView = this.appStorage.weeklyListView;
  }

  ngOnDestroy() {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  async load(companyId: string) {
    //go get all users for the company
    this.users = await this.userService.getUsersForCompanyWithCaching(companyId, true, false);
    
    this.getHarvests();
    this.getGroups();
  }

  async getHarvests() {
    this.loadingHarvests = true;
    var start = Date.now();
    console.log('Before Get Harvest');
    this.harvestService.getAll(true).snapshotChanges().subscribe(snapshot => {
      var afterreturn = Date.now();
      console.log(`Returned from GetHarvest (seconds): ${(afterreturn - start)/1000}`);
      let harvests = [];
      let data = snapshot
      data.forEach(element => {
        let harvest: models.Harvest = element.payload.doc.data();
        harvest.uid = element.payload.doc.id;
        harvests.push(harvest);
      });

      // if(harvests.length > 1){
      //   let allHarvest: models.Harvest = {
      //     name: 'ALL',
      //     uid: ''
      //   }
      //   harvests.unshift(allHarvest);
      // }
      var afterCalcs = Date.now();
      console.log(`afterCalcs (seconds): ${(afterCalcs - start)/1000}`);
      
      this.harvests = harvests;

      if(this.harvests.length != 0) { // && this.selectedHarvest == null){
        this.harvestChange(this.harvests);
      }
      // let sdata = JSON.stringify(harvests);
      // alert(sdata);
      this.loadingHarvests = false;
    })
  }

  getGroups() {
    this.loadingGroups = true;
    this.groupService.getAll().snapshotChanges().subscribe(snapshot => {
      let groups = [];
      let data = snapshot
      data.forEach(element => {
        let group: models.Group = element.payload.doc.data();
        group.uid = element.payload.doc.id;
        groups.push(group);
      });

      this.groups = groups;

      if(this.groups.length != 0) { // && this.selectedHarvest == null){
        this.groupChange(this.groups);
      }
      this.loadingGroups = false;
    })
  }

  selectAllHarvests(select: NgModel, values) {
    select.update.emit(values); 
    this.harvestChange(this.harvests);
  }

  deselectAllHarvests(select: NgModel) {
    select.update.emit([]); 
    this.harvestChange([]);
  }

  selectAllGroups(select: NgModel, values) {
    select.update.emit(values); 
    this.groupChange(this.groups);
  }

  deselectAllGroups(select: NgModel) {
    select.update.emit([]); 
    this.groupChange([]);
  }

  harvestChange(newValue) {
    this.selectedHarvests = newValue;

    //this.calendarEvents = [];

    this.getEvents();
  }

  groupChange(newValue) {
    this.selectedGroups = newValue;

    //this.calendarEvents = [];

    this.getEvents();
  }

  addHarvest() {
    if(!this.canAdd){
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        data: 'harvestAdd'
      });
      return;
    }
    
    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.getHarvests();
      }
      console.log(JSON.stringify(result));
    })
  }

  addGroup() {
    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.getHarvests();
      }
      console.log(JSON.stringify(result));
    })
  }

  canSeeMassUserAssign() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.assignUsers);
  }

  canSeeUserFilter() : boolean {
    return false;
    // if(this.claimsService.userRole.precedence <= 2){
    //   return true;
    // } else {
    //   return false;
    // }
  }

  canSeeHarvestGroupDelete() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addHarvest) ||
           this.claimsService.userRole?.permissions?.includes(models.Permissions.addGroup);
  }

  canSeeHarvestAdd() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addHarvest);
  }

  canSeeGroupAdd() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addGroup);
  }

  filterUserSchedule() {
    let dialogRef = this.dialog.open(UserSelectSingleComponent, {
      panelClass: 'med-width-dialog',
      data: this.filterUserId || ''
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result && result.submitted){
        this.filterUserId = result.data;
        if(this.filterUserId !== undefined){
          this.onlyMyTasks = false;
        }
        this.getEvents();
      }
    })
  }

  harvestGroupDelete() {
    let dialogRef = this.dialog.open(HarvestGroupDeleteComponent, {
      panelClass: 'full-width-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      // //reload list
      // if(result && result.submitted){
      //   this.getEvents();
      // }
    })
  }

  usersMassAssign() {
    let dialogRef = this.dialog.open(UsersAssignComponent, {
      //panelClass: 'full-width-dialog'
    });
  }

  removeAllSubscriptions(){
    this.eventSubs.forEach(sub => {
      sub()
    });
    
    this.eventSubs = [];
  }

  getEvents(){

    //remove all events from calendar
    this.calendarEvents = [];

    //remove all subscriptions
    this.removeAllSubscriptions();

    this.selectedHarvests.forEach(harvest => {
      this.eventSubs.push(this.getEventsForHarvest(harvest.uid));
    });

    this.selectedGroups.forEach(group => {
      this.eventSubs.push(this.getEventsForGroup(group.uid));
    });

  }

  //THIS IS USED IF WE ARE DOING DOCUMENT FOR EACH EVENT
  // getEventsForHarvest(harvestId: string): Subscription {
  //   return this.eventService.get(harvestId).snapshotChanges().subscribe(snapshot => {
  //     let events = [];
  //     snapshot.forEach(element => {
  //       let dbEvent: models.Event = element.payload.doc.data();
  //       let uid = element.payload.doc.id;

  //       let foundEvent = this.calendarEvents.find(i => i.id == uid);

  //       if(foundEvent != null){
  //         foundEvent.title = `${dbEvent.phase}: ${dbEvent.description}`;
  //         foundEvent.date = dbEvent.startDateTime.toDate();
  //         foundEvent.color = dbEvent.color;
  //       }else{
  //         let event = {
  //           id: uid,
  //           title: `${dbEvent.phase}: ${dbEvent.description}`,
  //           phase: dbEvent.phase,
  //           description: dbEvent.description,
  //           completed: dbEvent.completed,
  //           date: dbEvent.startDateTime.toDate(),
  //           allDay: true,
  //           color: dbEvent.color
  //         }
  //         this.calendarEvents.push(event);
  //       }
        
  //     });
  //   });
  // }

  getEventsForHarvest(harvestId: string): Unsubscribe {
    return onSnapshot(this.eventService.get(harvestId), snapshot => {
      let events = [];
      snapshot.forEach(element => {
        let dbEventObj: any = element.data();
        let list: Array<models.Event> = dbEventObj.list;

        let dbEvent: models.DbEvent = {
          uid: element.id,
          id: harvestId,
          list: list
        }

        let index = this.dbEvents.map(function(e) { return e.uid; }).indexOf(dbEvent.uid)
        if(index >= 0){
          this.dbEvents.splice(index, 1, dbEvent)
        }else{
          this.dbEvents.push(dbEvent);
        }

        list.forEach((dbevent, index) => {
          let uid = `${element.id}::${index}`;
          let foundEvent = this.calendarEvents.find(i => i.id == uid);

          //if filterUserId is definied then we need to only display the events that pertain to that id
          if(this.filterUserId !== undefined){
            if(dbevent.assignedUserIds == null || !dbevent.assignedUserIds.includes(this.filterUserId)){
              if(foundEvent != null){
                let index = this.calendarEvents.findIndex(i => i.id == foundEvent.id);
                this.calendarEvents.splice(index, 1);
              }
              return;
            }
          }

          let color: string = dbevent.color;
          let className : string = '';
          if(dbevent.completed){
            className = 'completed'
            //color = this.colorLuminance(dbevent.color, .75);
          }
          if(dbevent.highPriority && !dbevent.completed){
            className += ' high-priority';
          }

          //get the title of the event
          let title: string = `${dbevent.harvestName}: ${dbevent.description}`;
          //if the assigned user ids is not empty or null
          if(dbevent.assignedUserIds !== undefined && dbevent.assignedUserIds.length !== 0){
            title = `${title} - `;

            dbevent.assignedUserIds.forEach(assignedUserId => {
              //find user from list
              let user: models.User = this.users.find(i => i.id == assignedUserId);
  
              if(user != null){
                title += `${user.displayName}, `;
              }
            });

            title = title.slice(0, -2);
          }
  
          if(foundEvent != null){
            foundEvent.title = title;
            foundEvent.phase = dbevent.phase,
            foundEvent.description = dbevent.description,
            foundEvent.completed = dbevent.completed,
            foundEvent.timeSpent = dbevent.timeSpent || 0,
            foundEvent.estimatedTime = dbevent.estimatedTime,
            foundEvent.efficacy = dbevent.efficacy || 0,
            foundEvent.date = dbevent.startDateTime.toDate();
            foundEvent.precedence = dbevent.precedence;
            foundEvent.highPriority = dbevent.highPriority;
            //foundEvent.notes = dbevent.notes;
            foundEvent.allDay = dbevent.anyTime !== false;
            foundEvent.color = color;
            foundEvent.className = className;
            foundEvent.assignedUserIds = dbevent.assignedUserIds;
            foundEvent.harvestId = harvestId;
            foundEvent.discussions = dbevent.discussions;
            foundEvent.cloudAttachments = dbevent.cloudAttachments;
            foundEvent.tags = dbevent.tags;
          }else{
            let event = {
              id: uid,
              title: title,
              phase: dbevent.phase,
              description: dbevent.description,
              completed: dbevent.completed,
              timeSpent: dbevent.timeSpent || 0,
              estimatedTime: dbevent.estimatedTime || 0,
              efficacy: dbevent.efficacy || 0,
              date: dbevent.startDateTime.toDate(),
              precedence: dbevent.precedence,
              highPriority: dbevent.highPriority,
              //notes: dbevent.notes,
              allDay: dbevent.anyTime !== false,
              color: color,
              className: className,
              assignedUserIds: dbevent.assignedUserIds,
              harvestId: harvestId,
              discussions: dbevent.discussions,
              cloudAttachments: dbevent.cloudAttachments,
              tags: dbevent.tags
            }
            this.calendarEvents.push(event);
          }
        });
        
      });
    });
  }

  /**
   * will take in a groupid and will go get the events for that group
   * @param groupId 
   */
  getEventsForGroup(groupId: string): Unsubscribe {
    return onSnapshot(this.eventService.getFromGroup(groupId), snapshot => {
      let events = [];
      snapshot.forEach(element => {
        let dbEventObj: any = element.data();
        let list: Array<models.Event> = dbEventObj.list;

        let dbEvent: models.DbEvent = {
          uid: element.id,
          id: groupId,
          list: list
        }

        let index = this.dbEvents.map(function(e) { return e.uid; }).indexOf(dbEvent.uid)
        if(index >= 0){
          this.dbEvents.splice(index, 1, dbEvent)
        }else{
          this.dbEvents.push(dbEvent);
        }

        list.forEach((dbevent, index) => {
          let uid = `${element.id}::${index}`;
          let foundEvent = this.calendarEvents.find(i => i.id == uid);

          //if filterUserId is definied then we need to only display the events that pertain to that id
          if(this.filterUserId !== undefined){
            if(dbevent.assignedUserIds == null || !dbevent.assignedUserIds.includes(this.filterUserId)){
              if(foundEvent != null){
                let index = this.calendarEvents.findIndex(i => i.id == foundEvent.id);
                this.calendarEvents.splice(index, 1);
              }
              return;
            }
          }

          //set the color and completed value of the event
          let color: string = dbevent.color;
          let className : string = '';
          if(dbevent.completed){
            className = 'completed'
          }
          if(dbevent.highPriority && !dbevent.completed){
            className += ' high-priority';
          }

          //get the title of the event
          let title: string = `${dbevent.groupName}: ${dbevent.description}`;
          //if the assigned user ids is not empty or null
          if(dbevent.assignedUserIds !== undefined && dbevent.assignedUserIds.length !== 0){
            title = `${title} - `;

            dbevent.assignedUserIds.forEach(assignedUserId => {
              //find user from list
              let user: models.User = this.users.find(i => i.id == assignedUserId);
  
              if(user != null){
                title += `${user.displayName}, `;
              }
            });

            title = title.slice(0, -2);
          }
  
          if(foundEvent != null){
            foundEvent.title = title;
            foundEvent.phase = dbevent.phase,
            foundEvent.description = dbevent.description,
            foundEvent.completed = dbevent.completed,
            foundEvent.timeSpent = dbevent.timeSpent || 0,
            foundEvent.efficacy = dbevent.efficacy || 0,
            foundEvent.date = dbevent.startDateTime.toDate();
            foundEvent.precedence = dbevent.precedence;
            foundEvent.highPriority = dbevent.highPriority;
            //foundEvent.notes = dbevent.notes;
            foundEvent.allDay = dbevent.anyTime !== false;
            foundEvent.color = color;
            foundEvent.className = className;
            foundEvent.assignedUserIds = dbevent.assignedUserIds;
            foundEvent.groupId = groupId;
            foundEvent.discussions = dbevent.discussions;
            foundEvent.cloudAttachments = dbevent.cloudAttachments;
            foundEvent.tags = dbevent.tags;
          }else{
            let event = {
              id: uid,
              title: title,
              phase: dbevent.phase,
              description: dbevent.description,
              completed: dbevent.completed,
              timeSpent: dbevent.timeSpent || 0,
              efficacy: dbevent.efficacy || 0,
              date: dbevent.startDateTime.toDate(),
              precedence: dbevent.precedence,
              highPriority: dbevent.highPriority,
              //notes: dbevent.notes,
              allDay: dbevent.anyTime !== false,
              color: color,
              className: className,
              assignedUserIds: dbevent.assignedUserIds,
              groupId: groupId,
              discussions: dbevent.discussions,
              cloudAttachments: dbevent.cloudAttachments,
              tags: dbevent.tags
            }
            this.calendarEvents.push(event);
          }
        });
        
      });
    });
  }

  compilePlanaCanEventFromFullCalendarEvent(inEvent: any): models.Event {
    let event: models.Event = {};
    event.uid = inEvent.event.id;
    event.completed = inEvent.event._def.extendedProps.completed;
    event.timeSpent = inEvent.event._def.extendedProps.timeSpent;
    event.efficacy = inEvent.event._def.extendedProps.efficacy;
    event.phase = inEvent.event._def.extendedProps.phase;
    event.description = inEvent.event._def.extendedProps.description;
    event.assignedUserIds = inEvent.event._def.extendedProps.assignedUserIds || [];
    event.precedence = inEvent.event._def.extendedProps.precedence || 0;
    event.highPriority = inEvent.event._def.extendedProps.highPriority || false;
    // = inEvent.event._def.extendedProps.notes || '';
    event.anyTime = inEvent.event.allDay;
    event.discussions = inEvent.event._def.extendedProps.discussions || [];
    event.cloudAttachments = inEvent.event._def.extendedProps.cloudAttachments || [];
    event.tags = inEvent.event._def.extendedProps.tags || [];

    return event;
  }

  updateEventInList(inEvent: any): models.DbEvent {

    let event: models.Event = inEvent;
    let realUid = event.uid.split("::")[0];
    let index = event.uid.split("::")[1];

    let dbEvent: models.DbEvent = this.dbEvents.find(i => i.uid == realUid);
    dbEvent.list[index].completed = event.completed;
    dbEvent.list[index].timeSpent = event.timeSpent;
    dbEvent.list[index].efficacy = event.efficacy;
    dbEvent.list[index].phase = event.phase;
    dbEvent.list[index].description = event.description;
    dbEvent.list[index].assignedUserIds = event.assignedUserIds;
    dbEvent.list[index].precedence = event.precedence;
    dbEvent.list[index].highPriority = event.highPriority;
    //dbEvent.list[index].notes = event.notes;
    dbEvent.list[index].anyTime = event.anyTime;
    dbEvent.list[index].startDateTime = event.startDateTime;
    dbEvent.list[index].discussions = event.discussions;
    dbEvent.list[index].cloudAttachments = event.cloudAttachments;
    dbEvent.list[index].tags = event.tags;

    return dbEvent;
  }

  async eventDrop(eventToMove: any){
    let event = this.compilePlanaCanEventFromFullCalendarEvent(eventToMove);

    //if(event.anyTime){
      // if(this.company == null){
      //   this.company = await this.companyService.getCurrent();
      // }

      // event.startDateTime = moment(eventToMove.event.start).tz(this.company.timezone).toDate();
    //} else {

    //}
    event.startDateTime = eventToMove.event.start;

    let harvestId = eventToMove.event._def.extendedProps.harvestId;
    let groupId = eventToMove.event.groupId;
    let assignToGroup: boolean = false;
    if(harvestId == null && groupId != null){
      assignToGroup = true;
    }

    let dbEvent = this.updateEventInList(event);

    this.eventService.save(assignToGroup, dbEvent.id, dbEvent);

    this.snackBar.open('Successfully saved new task date', null, {duration: 1500, horizontalPosition: 'right', verticalPosition: 'bottom'})
  }

  eventClick(info: any){
    let event = this.compilePlanaCanEventFromFullCalendarEvent(info);
    //event.startDateTime = info.event.start;
    //debugger;
    let start = info.event.start;
    let harvestId = info.event._def.extendedProps.harvestId;
    let groupId = info.event.groupId;
    let assignToGroup: boolean = false;
    if(harvestId == null && groupId != null){
      assignToGroup = true;
    }

    let data: any = {
      event: event, 
      start: start, 
      assignToGroup: assignToGroup,
      dbEvents: this.dbEvents
    }

    if(assignToGroup){
      data.groupId = groupId
    } else{
      data.harvestId = harvestId
    }

    let dialogRef = this.dialog.open(EventUpdateComponent, {
      width: '800px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result){
        return;
      }
      if(result.save){
        //perform save
        let dbEvent = this.updateEventInList(result.event);

        this.eventService.save(assignToGroup, dbEvent.id, dbEvent);
      }
      else if(result.delete){
        let event: models.Event = result.event;
        let realUid = event.uid.split("::")[0];
        let index = event.uid.split("::")[1];

        let cetr = this.calendarEvents.filter(i => {
          return i.id.startsWith(realUid)
        });
        cetr.forEach(element => {
          let index = this.calendarEvents.indexOf(element);
          this.calendarEvents.splice(index, 1)
        });

        this.eventService.delete(assignToGroup, assignToGroup ? result.groupId : result.harvestId, event);
    
        //this.getEventsForHarvest(result.harvestId);
      }
      console.log(JSON.stringify(result));
    })
  };
  
  /**
   * method that will be called when a user clicks on a date
   * @param arg argument coming from fullcalendar
   */
  handleDateClick(arg) { // handler method
    if(!this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent)){
      return;
    }

    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: arg,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result && result.save){
        //perform save
        let assignToGroup: boolean = result.assignToGroup;
        let harvest: models.Harvest = result.harvest;
        let group: models.Group = result.group;

        let events: Array<models.Event> = result.events;
        // let realUid = event.uid.split("::")[0];
        // let index = event.uid.split("::")[1];
        let dbEvents: Array<models.Event> = [];

        events.forEach(event => {
          let dbEvent: models.Event = {
            completed: event.completed,
            phase: event.phase,
            timeSpent: event.timeSpent || 0,
            efficacy: event.efficacy || 0,
            description: event.description,
            assignedUserIds: event.assignedUserIds,
            startDateTime: event.startDateTime,
            color: assignToGroup ? group.color : harvest.color,
            //notes: event.notes,
            anyTime: event.anyTime,
            discussions: event.discussions,
            highPriority: event.highPriority,
            cloudAttachments: event.cloudAttachments,
            tags: event.tags
          };

          if(assignToGroup){
            delete dbEvent.harvestName;
            dbEvent.groupName = group.name;
          } else {
            delete dbEvent.groupName;
            dbEvent.harvestName = harvest.name;
          }

          dbEvents.push(dbEvent);
        });

        //alert(JSON.stringify(dbEvent))
        //alert(JSON.stringify(harvest))
        await this.eventService.add(assignToGroup, assignToGroup ? group.uid : harvest.uid, dbEvents);
      }
      console.log(JSON.stringify(result));
    })
  }

  addEvent() {
    let data: any = {}
    if(this.viewType == 'listDay'){
      data.date = this.viewStart;
    }
    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: data,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result && result.save){
        //perform save
        let assignToGroup: boolean = result.assignToGroup;
        let harvest: models.Harvest = result.harvest;
        let group: models.Group = result.group;

        let events: Array<models.Event> = result.events;
        // let realUid = event.uid.split("::")[0];
        // let index = event.uid.split("::")[1];
        let dbEvents: Array<models.Event> = [];

        events.forEach(event => {
          let dbEvent: models.Event = {
            completed: event.completed,
            phase: event.phase,
            timeSpent: event.timeSpent || 0,
            efficacy: event.efficacy || 0,
            description: event.description,
            assignedUserIds: event.assignedUserIds,
            startDateTime: event.startDateTime,
            color: assignToGroup ? group.color : harvest.color,
            //notes: event.notes,
            anyTime: event.anyTime,
            discussions: event.discussions,
            highPriority: event.highPriority,
            cloudAttachments: event.cloudAttachments,
            tags: event.tags
          };

          if(assignToGroup){
            delete dbEvent.harvestName;
            dbEvent.groupName = group.name;
          } else {
            delete dbEvent.groupName;
            dbEvent.harvestName = harvest.name;
          }

          dbEvents.push(dbEvent);
        });

        //alert(JSON.stringify(dbEvent))
        //alert(JSON.stringify(harvest))
        await this.eventService.add(assignToGroup, assignToGroup ? group.uid : harvest.uid, dbEvents);
      }
      console.log(JSON.stringify(result));
    })
  }

  toggleChange(newVal) {
    if(newVal && newVal.checked){
      this.filterUserId = this.claimsService.currentUserId();
    } else {
      this.filterUserId = undefined;
    }

    console.log('toggle change - ' + this.filterUserId)
    this.getEvents();
  }

  weeklyListView: boolean = true;
  toggleListViewChange(newVal) {
    if(this.weeklyListView) {
      this.viewType = 'listWeek';
      this.calendarComponent.getApi().changeView('listWeek');
    } else {
      this.viewType = 'dayGridWeek';
      this.calendarComponent.getApi().changeView('dayGridWeek');
    }
    
    this.appStorage.weeklyListView = this.weeklyListView;
  }
}
