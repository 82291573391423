import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import { HelperService } from './helper.service';

@Injectable()
export class PostHarvestService {

    companyRef: AngularFirestoreCollection;
    collectionId: string = 'grow-settings';
    itemId: string = 'post-harvest';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    async getAll(): Promise<Array<models.PostHarvest>> {
        let companyId = this.helperService.currentCompanyId;
        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbPostHarvest: models.DbPostHarvest = await (await response.get().toPromise()).data();

        return dbPostHarvest?.list || [];
    }

    update(postHarvests: Array<models.PostHarvest>) {
        postHarvests.forEach(postHarvest => {
            postHarvest.systemValue = postHarvest.name.toLowerCase().replace(/ /g, '_');
        });
        let dbPostHarvest: models.DbPostHarvest = {
            list: postHarvests
        }

        let companyId = this.helperService.currentCompanyId;
        return this.firestore.collection('companies').doc(companyId)
                .collection(this.collectionId).doc(this.itemId).set(dbPostHarvest);
    }
}