import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-user-select-multi-btn',
  templateUrl: './user-select-multi-btn.component.html',
  styleUrls: ['./user-select-multi-btn.component.scss']
})
export class UserSelectMultiBtnComponent implements OnInit {

  users: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);

    this.selectedUsers = this.users.filter(i => this.selected.includes(i.id));
  }
  @Output() onClosed = new EventEmitter();

  selectedUsersValue: Array<viewmodels.MultiSelect>;
  @Input()
  get selectedUsers() {
    return this.selectedUsersValue;
  }
  @Output() selectedUsersChange = new EventEmitter();
  set selectedUsers(val) {
    if (val == null) {
      val = [];
    }
    this.selectedUsersValue = val;
    this.selectedUsersChange.emit(this.selectedUsersValue);
  }

  constructor(
    private userService: services.UserService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  async getUsers() {
    const companyId: string = this.helperService.currentCompanyId;
    let users = await this.userService.getUsersForCompanyWithCaching(companyId, true, true)
    let list = users.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i.id,
        displayValue: i.displayName,
        selected: false // this.selected.includes(i.uid)
      }
      return h;
    })

    this.users = this.helperService.sortArrayByStringField(list, 'displayValue');
    //this.selected = this.zones.map(i => i.id);
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
