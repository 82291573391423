import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-phase-select-multi-btn',
  templateUrl: './phase-select-multi-btn.component.html',
  styleUrls: ['./phase-select-multi-btn.component.scss']
})
export class PhaseSelectMultiBtnComponent implements OnInit {

  phases: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);
  }
  @Output() onClosed = new EventEmitter();

  constructor(
    private templateService: services.TemplateService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getPhases();
  }

  async getPhases() {
    let phases = await this.templateService.getPhasesForAllTemplates()
    let list = phases.preCutPhases.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i,
        displayValue: i,
        harvestAtEnd: i == phases.preCutPhases[phases.preCutPhases.length - 1] && phases.postCutPhases?.length > 0,
        selected: false // this.selected.includes(i.uid)
      }
      return h;
    })
    list.push(...phases.postCutPhases.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i,
        displayValue: i,
        harvestAtEnd: false,
        selected: false // this.selected.includes(i.uid)
      }
      return h;
    }))
    this.phases = list;
    //this.selected = this.phases.map(i => i.id);
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
