import { Component, Input, OnInit } from '@angular/core';

import * as services from '../../services';

@Component({
  selector: 'app-stripe-portal-btn',
  templateUrl: './stripe-portal-btn.component.html',
  styleUrls: ['./stripe-portal-btn.component.scss']
})
export class StripePortalBtnComponent implements OnInit {

  @Input() customerId: string;
  loading: boolean = false;

  constructor(
    private stripeService: services.StripeService
  ) { }

  ngOnInit(): void {
  }

  click() {
    this.loading = true;
    this.stripeService.retrieveCustomerPortalSession(this.customerId).then(data => {
      window.location.href = data.data.url;
      this.loading = false;
    })
  }

}
