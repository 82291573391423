import { Component, OnDestroy } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { DutchieUnmatchedBatchSelectDialogComponent } from "app/dialogs/dutchie-unmatched-batch-select-dialog/dutchie-unmatched-batch-select-dialog.component";

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import * as leaflogix from "app/models/leaflogix/index";
import { Subscription } from "rxjs";

@Component({
    selector: "dutchie-link-cell",
    template: `
        <ng-container *ngIf="batchName != null">
            <span>{{batchName}}</span>
            <button mat-icon-button class="link-delete-btn" *ngIf="dutchieAdmin" [disabled]="loading" (click)="unlinkClick()">
                <mat-spinner *ngIf="loading" diameter=25 strokeWidth=4></mat-spinner>
                <mat-icon *ngIf="!loading">close</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="batchName == null">
            <button mat-icon-button *ngIf="dutchieAdmin" [disabled]="loading" (click)="link()">
                <mat-spinner *ngIf="loading" diameter=25 strokeWidth=4></mat-spinner>
                <mat-icon *ngIf="!loading">link</mat-icon>
            </button>
        </ng-container>
    `,
    styles: [
        `
            .link-delete-btn {
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }

            .link-delete-btn:hover {
                opacity: 1;
            }
        `
    ]
})
export class DutchieLinkComponent implements ICellRendererAngularComp, OnDestroy {
    private params: any;

    companySub: Subscription;

    batchName: string;
    harvestViewModel: viewmodels.HarvestTableViewModel;
    loading: boolean = false;

    dutchieAdmin: boolean = false;

    constructor(
        private dialog: MatDialog
        , private templateService: services.TemplateService
        , private notifierService: services.NotifierService
        , private router: Router
        , private harvestService: services.HarvestService
        , private appStorage: services.AppStorage
        , private claimsService: services.ClaimsService
        , private helperService: services.HelperService
    ) {}

    agInit(params: any): void {
        this.params = params;
        this.harvestViewModel = this.params.data;
        this.batchName = this.params.value;

        this.setCanSeeDutchie();
    }

    refresh(params: any): boolean {
        return false;
    }

    ngOnDestroy(): void {
        if(this.companySub != null) {
            this.companySub.unsubscribe();
        }
    }

    setCanSeeDutchie() {
      let userId = this.claimsService.currentUserId();
  
      this.dutchieAdmin = this.helperService.currentCompany.leaflogix?.active && this.helperService.currentCompany.leaflogix?.adminUserIds?.includes(userId);
  
      this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
        this.dutchieAdmin = data.leaflogix?.active && data.leaflogix?.adminUserIds?.includes(userId);
      })
    }

    async link() {
        this.loading = true;

        //check harvest template
        let currentTemplate = await this.templateService.get(this.harvestViewModel.templateId);

        if (
            currentTemplate == null ||
            currentTemplate.phases.filter((i) => i.compliancePhase == null)
                .length > 0
        ) {
            this.notifierService.errorWithBtn(
                "You do not have a Dutchie compliance stage associated with each phase in the Template used to create this Harvest. Need help?",
                "Click here",
                () => {
                this.navigateToTemplate(currentTemplate?.uid);
                },
                false
            );
            this.loading = false;
            return;
        }

        //open dutchie link select dialog
        let dialogRef = this.dialog.open(DutchieUnmatchedBatchSelectDialogComponent);

        dialogRef.afterClosed().subscribe(async result => {
            if (result?.submitted) {
                let batch: leaflogix.Batch = result.data;

                let harvestPartial: models.Harvest = {
                    leaflogixName: batch.batchName.toString(),
                    leaflogixId: batch.batchId.toString()
                }
                await this.harvestService.partialUpdate(harvestPartial, this.harvestViewModel.harvestId);

                this.appStorage.unlinkedDutchieBatches = this.appStorage.unlinkedDutchieBatches - 1;

                this.notifierService.success(
                `You've synced PlanaCan Harvest ${this.harvestViewModel.name} with Dutchie Batch ${batch.batchName}. All plant counts, plant attrition, and room movements will now be displayed and updated in PlanaCan.`
                );
            }
            this.loading = false;
        })
    }

    navigateToTemplate(templateId: string) {
        let extras: NavigationExtras = {
          queryParams: {
            'tourstep': 'stages'
          }
        }
        this.router.navigate(['console', 'template', templateId], extras)
    }

    unlinkClick() {
        this.notifierService.confirm('Unlink Batch to Harvest', 'Are you sure you want to unlink this Batch from this Harvest?',
        () => {this.unlink()},
        () => {})
    }

    async unlink() {
        this.loading = true;
        await this.harvestService.unlinkDutchieBatch(this.harvestViewModel.harvestId);
        this.loading = false;
        this.notifierService.success('Successfully unlinked Dutchie Batch from the PlanaCan Harvest')
    }
}
