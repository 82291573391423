<h2 id="template_add_title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-select placeholder="Select Company" [(ngModel)]="selectedCompany">
                    <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">
        No
    </button>
    <button mat-button (click)="yesClick()">
        Yes
    </button>
</mat-dialog-actions>