import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import * as models from 'app/models';
import { HelperService } from './helper.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class GroupService {

    companyRef: AngularFirestoreCollection;

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
        private functions: Functions
    ) {
        console.log('Hello GroupService Provider');

        this.companyRef = this.firestore.collection('companies');
    }

    getAll(): AngularFirestoreCollection {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = this.companyRef.doc(companyId).collection('groups');

        return harvestResponse;
    }

    async getAllModels(onlyIncomplete: boolean = false): Promise<models.Group[]> {
        let companyId: string = this.helperService.currentCompanyId;

        let response = await this.companyRef.doc(companyId).collection('groups').get().toPromise();

        let groups: models.Group[] = [];
        response.forEach(data => {
            let group: models.Group = data.data();
            group.uid = data.id;
            groups.push(group);
          })

        return groups;
    }

    get(groupId: string): AngularFirestoreDocument {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = this.companyRef.doc(companyId).collection('groups')
                                .doc(groupId);

        return harvestResponse;
    }

    async add(group: models.Group) {
        let companyId = this.helperService.currentCompanyId;
        let inObj = {
            companyId: companyId,
            name: group.name,
            color: group.color,
            templateId: group.templateId ? group.templateId : null,
            startDate: group.startDate ? group.startDate : null,
        }
        let addGroupMethod = httpsCallable<any, models.Result<any>>(this.functions, 'addGroup');
        let addGroupResponse = await addGroupMethod(inObj);
        return addGroupResponse;
    }

    update(group: models.Group) {
        let uid = group.uid;
        delete group.uid;

        let companyId = this.helperService.currentCompanyId;
        return this.firestore.collection('companies').doc(companyId)
                .collection('groups').doc(uid).set(group);
    }

    async deleteList(groupIds: Array<string>){
        let companyId = this.helperService.currentCompanyId;

        let deleteGroupListMethod = httpsCallable(this.functions, 'deleteGroupList');
        var deleteGroupListResponse = await deleteGroupListMethod({ companyId: companyId, groupIds: groupIds });
    
        return deleteGroupListResponse;
    }
}