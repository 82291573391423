import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import * as models from 'app/models';

@Component({
    selector: "icon-cell",
    template: `
    <button mat-icon-button [matMenuTriggerFor]="menu" [matBadge]="badge" [matBadgeHidden]="badge===0" class="badge-btn" style="width: 25px; height: 25px; line-height: 15px; margin-top: 8px;">
        <mat-icon aria-hidden="false" style="vertical-align: middle">menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="cloudClick()" [matBadge]="cloudbadge" [matBadgeHidden]="cloudbadge===0">
            <mat-icon>cloud</mat-icon>
            <span>Cloud Attachments</span>
        </button>
        <button mat-menu-item (click)="attachmentsClick()" [matBadge]="attachmentsbadge" [matBadgeHidden]="attachmentsbadge===0">
            <mat-icon>attachment</mat-icon>
            <span>Attachments</span>
        </button>
        <button mat-menu-item (click)="notesClick()" [matBadge]="notesbadge" [matBadgeHidden]="notesbadge===0">
            <mat-icon>sticky_note_2</mat-icon>
            <span>Notes</span>
        </button>
        <!--<mat-divider></mat-divider>
        <button mat-menu-item (click)="deleteClick()">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>-->
    </mat-menu>
    `,
    styles: [
        `
        ::ng-deep .mat-menu-item.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: 0px;
            right: 0px;
        }
        ::ng-deep .badge-btn.mat-badge-medium.mat-badge-above .mat-badge-content {
            top: -7px;
        }
        `
    ]
})
export class TemplateStepMoreComponent implements ICellRendererAngularComp {
    private params: any;

    notesbadge: number = 0;
    attachmentsbadge: number = 0;
    cloudbadge: number = 0;
    get badge() {
        return this.notesbadge + this.cloudbadge + this.attachmentsbadge;
    }
    
    cloudFunc: any;
    attachmentsFunc: any;
    notesFunc: any;
    deleteFunc: any;

    agInit(params: any): void {
        this.setData(params);
    }

    refresh(params: any): boolean {
        this.setData(params);
        return true;
    }

    setData(params: any){
        this.params = params;

        let step: models.Step = this.params.data;
        this.notesbadge = step.notes?.length ?? 0;
        this.cloudbadge = step.cloudAttachments?.length ?? 0;
        this.attachmentsbadge = step.attachments?.length ?? 0;

        this.cloudFunc = this.params.cloudFunc;
        this.attachmentsFunc = this.params.attachmentsFunc;
        this.notesFunc = this.params.notesFunc;
        this.deleteFunc = this.params.deleteFunc;
    }

    cloudClick() {
        this.cloudFunc(this.params.data);
        //this.refresh(this.params);
    }

    attachmentsClick() {
        this.attachmentsFunc(this.params.data);
    }

    notesClick() {
        this.notesFunc(this.params.data);
        //this.refresh(this.params);
    }

    deleteClick() {
        this.deleteFunc(this.params.data);
        //this.refresh(this.params);
    }
}
