import { BrokenRule } from "./brokenRule";

export interface Result<T> {
    success?: boolean;
    message?: string;
    value?: T;
    brokenRules?: Array<BrokenRule>;
}


export class Result<T> {
    success?: boolean;
    message?: string;
    value?: T;
    error?: string;
    exMessages?: string[];
    brokenRules?: Array<BrokenRule>;

    constructor() {
        this.success = true;
        this.exMessages = [];
        this.brokenRules = [];
    }

    mapResult?<Tin>(inResult: Result<Tin>) {
        this.success = inResult.success && this.success;

        inResult.brokenRules?.forEach(brokenRule => {
            this.brokenRules?.push(brokenRule);
        });
    }

    addBrokenRule?(name: string, message: string, displayToClient: boolean){
        let brokenRule: BrokenRule = {
            name: name,
            message: message,
            displayToClient: displayToClient,
        }
        this.brokenRules?.push(brokenRule);
    }
}