import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import { NavigationExtras, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';

@Component({
  selector: 'app-company-settings-dutchie',
  templateUrl: './company-settings-dutchie.component.html',
  styleUrls: ['./company-settings-dutchie.component.scss']
})
export class CompanySettingsDutchieComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  companySub: Subscription;

  saving: boolean = false;

  company: models.Company = null;
  initialCompany: models.Company = {};

  apiKeyHide: boolean = true;

  constructor(
    private helperService: services.HelperService
    , private companyService: services.CompanyService
    , private notifierService: services.NotifierService
    , private leaflogixService: services.LeaflogixService
    , private templateService: services.TemplateService
    , private router: Router
  ) {
    super()
   }

  canDeactivate():boolean{
    return !this.dataUpdated();
  }

  dataUpdatedBool: boolean = false;
  dataUpdated(): boolean {
    let x = JSON.stringify(this.initialCompany);
    let y = JSON.stringify(this.company);

    this.dataUpdatedBool = x !== y;
    return this.dataUpdatedBool;
  }

  ngOnInit(): void {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.load(companyId);
    }
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load(data);
    })
  }

  ngOnDestroy(): void {
    if(this.companySub != null) {
      this.companySub.unsubscribe();
    }
  }

  load(companyId: string) {
    this.get();
  }

  async get() {
    let companyId = this.helperService.currentCompanyId;
    let company = await this.companyService.getById(companyId, true);
    if(company.leaflogix == null){
      company.leaflogix = {adminUserIds: []};
    }
    this.company = company;
    //this.eventTooltipDisplay = company.eventsTooltips ? company.eventsTooltips : false;
    this.initialCompany = this.helperService.deepCopy(company);
  }

  async save() {
    let dutchieChanged: boolean = false;
    if(this.initialCompany.leaflogix?.active != this.company.leaflogix?.active || this.initialCompany.leaflogix?.apiKey != this.company.leaflogix?.apiKey){
      dutchieChanged = true;
    }
    this.saving = true;
    let companyToSave = this.helperService.deepCopy(this.company)
    await this.companyService.save(companyToSave);
    this.helperService.currentCompany = companyToSave;
    this.get();
    this.saving = false;

    this.notifierService.success('Successfully saved Dutchie integration settings');

    if(this.company.leaflogix?.active && dutchieChanged){
      this.notifierService.confirm('Initial Sync', 'Would you like to run an initial sync from Dutchie?  This will update all Strains and Rooms.  Anything currently saved will be overwritten.',
      () => {this.syncLeaflogix()},
      () => {})
    }
  }

  syncLeaflogix() {
    let pro = Promise.all([
      this.leaflogixService.integrateStrains(),
      this.leaflogixService.integrateZones()
    ]);

    pro.then(data => {
      let strainResponse: models.Result<boolean> = data[0].data;
      let zoneResponse: models.Result<boolean> = data[1].data;
      if(strainResponse.success && zoneResponse.success){
        this.notifierService.successWithBtn(
          'Successfully integrated Strains and Zones from Dutchie.  Next step is to set up your Templates.  Would you like to go there?',
          'Go!',
          () => {this.navigateToTemplate()}
        )
        // this.notifierService.confirm('Successfully integrated Strains and Zones',
        // 'Successfully integrated Strains and Zones from Dutchie.  Next step is to set up your Templates.  Would you like to go there?',
        // () => {this.navigateToTemplate()},
        // () => {})
      } else {
        this.notifierService.error('There was an error syncing with Dutchie.  Please check API key.', false)
      }
    })
  }

  async navigateToTemplate() {
    let template = await this.templateService.getFirst();
    let extras: NavigationExtras = {
      queryParams: {
        'tourstep': 'stages'
      }
    }
    this.router.navigate(['console', 'template', template.uid], extras)
  }
}
