<!-- <mat-form-field>
    <mat-label>Select a Zone</mat-label>
    <mat-select [disabled]="disabled" [(value)]="zoneId">
        <mat-select-filter [array]="zones" [displayMember]="'name'" (filteredReturn)="filteredZones = $event">
        </mat-select-filter>
        <mat-option>None</mat-option>
        <mat-option [attr.style]="'padding-left: ' + ((h.indent*20)+16) + 'px'" [value]="h.id"
            *ngFor="let h of filteredZones">{{h.name}}</mat-option>
    </mat-select>
</mat-form-field> -->


<div class="row">
    <div class="col-4" *ngFor="let zs of zoneSelections; let i = index">
        <mat-form-field>
            <mat-label>{{zoneTitles[i]}}</mat-label>
            <mat-select [(ngModel)]="zs.selectedZone" (selectionChange)="change(zs, i, $event)"
                [ngModelOptions]="{standalone: true}">
                <mat-option [value]="z" *ngFor="let z of zs.zones">{{z.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>