import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupSelectMultipleComponent } from 'app/components/group/group-select-multiple/group-select-multiple.component';
import { HarvestSelectMultipleComponent } from 'app/components/harvest/harvest-select-multiple/harvest-select-multiple.component';
import { FilterSetSaveDialogComponent } from '../filter-set-save-dialog/filter-set-save-dialog.component';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-filter-calendar-dialog',
  templateUrl: './filter-calendar-dialog.component.html',
  styleUrls: ['./filter-calendar-dialog.component.scss']
})
export class FilterCalendarDialogComponent implements OnInit {
  
  @ViewChild(HarvestSelectMultipleComponent) harvestSelectMultipleComponent: HarvestSelectMultipleComponent;
  @ViewChild(GroupSelectMultipleComponent) groupSelectMultipleComponent: GroupSelectMultipleComponent;

  harvestSelectByDefault: string = models.SelectDefault.NotCompleted;
  groupSelectByDefault: string = models.SelectDefault.All;

  initialHarvestIds: Array<string> = [];
  initialGroupIds: Array<string> = [];
  harvests: Array<models.Harvest> = [];
  groups: Array<models.Group> = [];
  
  users: Array<models.User> = [];
  selectedUserId: string;

  teams: Array<models.Team> = [];
  selectedTeamId: string;
  
  completeStatus: string;
  onlyMyTasks: boolean = false;

  phases: Array<string> = [];

  allZones: Array<models.Zone> = [];
  zoneIds: Array<string> = [];

  allTags: Array<string> = [];
  tags: Array<string> = [];

  constructor(
    private dialogRef: MatDialogRef<FilterCalendarDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private helperService: services.HelperService
    , private userService: services.UserService
    , private notifierService: services.NotifierService
    , private teamService: services.TeamService
    , private claimsService: services.ClaimsService
    , private zoneService: services.ZoneService
    , private dialog: MatDialog
  ) { 
    if(data != null){
      this.initialHarvestIds = data.initialHarvestIds;
      this.initialGroupIds = data.initialGroupIds;
      this.selectedUserId = data.selectedUserId;
      this.selectedTeamId = data.selectedTeamId;
      this.completeStatus = data.completeStatus;
      this.onlyMyTasks = data.onlyMyTasks;
      this.phases = data.phases;
      this.tags = data.tags;
      this.zoneIds = data.zoneIds

      let events: models.Event[] = data.events;
      if(events != null){
        let allTags = [...new Set(events.map(i => i.tags).flat())];
        this.allTags = allTags.filter(i => i != null && i != '');
      }
    }
  }

  ngOnInit(): void {
    this.getUsers();
    this.getTeams();
    this.getZones();
  }

  async getUsers() {
    let companyId = this.helperService.currentCompanyId;
    let userResponse = await this.userService.getUsersForCompanyWithCaching(companyId, true)

    let nullUser: models.User = {
      id: undefined,
      displayName: 'No User'
    }
    this.users.push(nullUser);

    userResponse.forEach(element => {
      let user: models.User = element;
      user.id = element.id;
      this.users.push(user);
    });
  }

  async getTeams() {
    let teamResponse = await this.teamService.getAll();
    let docs = (await teamResponse.get().toPromise()).docs;

    let nullTeam: models.Team = {
      uid: undefined,
      name: 'No Team'
    }
    this.teams.push(nullTeam);

    docs.forEach(element => {
      let team: models.Team = element.data();
      team.uid = element.id;
      this.teams.push(team);
    });
  }

  async getZones() {
    let zones = await this.zoneService.getAll()
    this.allZones = zones;
  }

  save() {
    let newFilterSet: models.FilterSet = {
      name: '',
      userIds: [this.selectedUserId],
      teamIds: [this.selectedTeamId],
      completeStatus: this.completeStatus,
      onlyMyTasks: this.onlyMyTasks,
      phases: this.phases,
      tags: this.tags,
      zoneIds: this.zoneIds
    }
    this.dialog.open(FilterSetSaveDialogComponent, {data: newFilterSet}).afterClosed().subscribe(id => {
      if(id){
        this.filter(id)
      }
    })
  }

  async default() {
    this.harvestSelectMultipleComponent.selectAll();
    this.groupSelectMultipleComponent.selectAll();
    this.selectedUserId = undefined;
    this.selectedTeamId = undefined;
    this.completeStatus = 'both';
    this.onlyMyTasks = false;
    this.phases = null;
    this.tags = null;
    this.zoneIds = null;
    
    await this.helperService.wait(10);
    this.filter();
  }

  filter(savedFilterSetId: string = null) {
    let teamIds: Array<string> = [];
    //let userIds: Array<string> = [];

    if(this.onlyMyTasks){
      //add my teamIds to list
      let currentUserId = this.claimsService.currentUserId();
      let userTeamIds = this.teams.filter(i => i.userIds != null && i.userIds.includes(currentUserId)).map(i => i.uid)
      teamIds.push(...userTeamIds);
    }

    if(this.selectedUserId){
      //userIds.push(this.selectedUserId)

      //add user teamIds to list
      let userTeamIds = this.teams.filter(i => i.userIds != null && i.userIds.includes(this.selectedUserId)).map(i => i.uid)
      teamIds.push(...userTeamIds);
    }

    if(this.selectedTeamId) {
      teamIds.push(this.selectedTeamId);

      //add team userIds to user list
      // let team = this.teams.find(i => i.uid == this.selectedTeamId);
      // userIds.push(...team?.userIds);
    }

    let calendarFilter = {
      harvestIds: this.harvests.map(i => i.uid),
      groupIds: this.groups.map(i => i.uid),
      selectedUserId: this.selectedUserId,
      selectedTeamId: this.selectedTeamId,
      completeStatus: this.completeStatus,
      onlyMyTasks: this.onlyMyTasks,
      phases: this.phases,
      tags: this.tags,
      //userIds: userIds,
      teamIds: teamIds,
      zoneIds: this.zoneIds,
      savedFilterSetId: savedFilterSetId
    }
    this.dialogRef.close(calendarFilter);
  }
}
