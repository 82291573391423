<h2 mat-dialog-title>Harvest Documents</h2>
<mat-dialog-content class="dialog-content">

    <div class="row" style="padding-bottom: 5px">
        <div class="col-12">
            <button mat-raised-button style="float: right;" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="addDocument()" title="Add Doc">
                <mat-icon aria-hidden="false" aria-label="Add Doc">add</mat-icon>
                <span>Add Document</span>
              </button>
              <button mat-menu-item (click)="download()" title="Download Doc" [disabled]="selectedRows.length == 0">
                <mat-icon aria-hidden="false" aria-label="Download Doc">download</mat-icon>
                <span *ngIf="selectedRows.length <= 1">Download Document</span>
                <span *ngIf="selectedRows.length > 1">Download Documents</span>
              </button>
              <button mat-menu-item (click)="delete()" title="Delete Doc" [disabled]="selectedRows.length == 0">
                <mat-icon aria-hidden="false" aria-label="Delete Doc">delete</mat-icon>
                <span *ngIf="selectedRows.length <= 1">Delete Document</span>
                <span *ngIf="selectedRows.length > 1">Delete Documents</span>
              </button>
            </mat-menu>
        </div>
    </div>

    <ag-grid-angular
        style="width: 100%; min-height:400px; height: calc(100vh - 400px);" 
        class="ag-theme-balham"
        [rowData]="documents"
        [gridOptions]="gridOptions"
        (selectionChanged)="onSelectionChanged()">
    </ag-grid-angular>

    <!-- <table>
        <tr>
            <th>Document Name</th>
            <th>Date Uploaded</th>
            <th>Download</th>
        </tr>
        <tr *ngFor="let doc of documents">
            <td>{{doc.name}}</td>
            <td>{{doc.dateUploaded | date:'MM/dd/yyyy'}}</td>
            <td>Button</td>
        </tr>
    </table> -->
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
