import { Component, OnInit, SimpleChanges, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import * as models from '../../../models';
import * as services from '../../../services';
import { Subject, Observable, Subscription } from "rxjs";
import { ChartOptions } from 'chart.js';
import * as Chart from 'chart.js/auto';
import { ChartDaterangeFilterComponent } from 'app/components/chart-filters/chart-daterange-filter/chart-daterange-filter.component';

@Component({
  selector: 'app-users-actual-and-estimated-time-chart',
  templateUrl: './users-actual-and-estimated-time-chart.component.html',
  styleUrls: ['./users-actual-and-estimated-time-chart.component.scss']
})
export class UsersActualAndEstimatedTimeChartComponent implements OnInit {

  @ViewChild('chartCanvas', { static: false }) chartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild(ChartDaterangeFilterComponent) chartDaterangeFilterComponent: ChartDaterangeFilterComponent;

  @Input() removeEmpty: boolean = false;
  @Input() filteredEvents: models.ChartEventInput[];
  @Output() chartViewChange = new EventEmitter<any>();

  chartTitle: string = 'Time Taken';
  chartSubTitle: string = 'The amount of time Events take to complete vs. the estimated time by User';

  private verticalBarChart;

  users: models.User[];
  dateRange: models.DateRanger;
  selectedInterval: string;

  totalEventsCount: (number | null)[];
  totalActualTimeTaken: (number | null)[];
  totalEstimatedTimeTaken: (number | null)[];
  usersLabels: string[];

  private _events = new Subject<models.ChartEventInput[]>();
  getEvents(): Observable<any> {
    return this._events.asObservable();
  }

  set events(val: models.ChartEventInput[]) {
    if (val) {
      this._events.next(val);
    }
  }

  eventsSub: Subscription;
  constructor(
    private helperService: services.HelperService
    , private userService: services.UserService
  ) { }

  changeChartView() {
    this.chartViewChange.emit({ expand: true, chart: 'time_taken' });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.events = this.filteredEvents;
  }

  ngAfterViewInit() {
    if (this.eventsSub) {
      this.prepareChartEvents(this.events, this.dateRange);
    }
    this.eventsSub = this.getEvents().subscribe(data => {
      this.prepareChartEvents(data, this.dateRange);
    })
  }

  async prepareChartEvents(events: models.ChartEventInput[], eventsDateRange: models.DateRanger) {
    const startDate = new Date(eventsDateRange.datesInRange[0]).getTime();
    const endDate = new Date(eventsDateRange.datesInRange[eventsDateRange.datesInRange.length - 1]).getTime();
    this.users = await this.userService.getUsersForCompanyWithCaching(this.helperService.currentCompanyId, true, true);
    let actualTimeTotalForAUser = {};
    let estimatedTimeTotalForAUser = {};
    let totalEventsWithTimeForAUser = {};
    for (const event of events) {
      const eventDate = new Date(event.date).getTime();
      // Check if the event date is within the selected date range - last month by default;
      if (eventDate >= startDate && eventDate <= endDate) {
        // find events in date range which has the estimated time assigned
        for (const user of this.users) {
          if (event.completed) {
            //find user who completed the event
            let discussions = event.eventObj.discussions.slice().reverse();
            let userIdWhoCompletedThisEvent = discussions.find(item => item.discussionType === 'completed_status' && item.content === "true")?.userId;
            // check if the event is completed by the current user of loop
            if (user.id === userIdWhoCompletedThisEvent) {
              // check if this event has predicted time to complete and actual time spent available
              if (event.eventObj.estimatedTime && event.eventObj.timeSpent) {
                // compute actual time taken and estimated time
                actualTimeTotalForAUser[user.id] = (actualTimeTotalForAUser[user.id] || 0) + Number(event.eventObj.timeSpent);
                estimatedTimeTotalForAUser[user.id] = (estimatedTimeTotalForAUser[user.id] || 0) + Number(event.eventObj.estimatedTime);
                totalEventsWithTimeForAUser[user.id] = (totalEventsWithTimeForAUser[user.id] || 0) + 1;
              } else { // stats remain same 
                actualTimeTotalForAUser[user.id] = (actualTimeTotalForAUser[user.id] || 0);
                estimatedTimeTotalForAUser[user.id] = (estimatedTimeTotalForAUser[user.id] || 0);
                totalEventsWithTimeForAUser[user.id] = (totalEventsWithTimeForAUser[user.id] || 0);
              }
            } else { // stats remain same
              actualTimeTotalForAUser[user.id] = (actualTimeTotalForAUser[user.id] || 0);
              estimatedTimeTotalForAUser[user.id] = (estimatedTimeTotalForAUser[user.id] || 0);
              totalEventsWithTimeForAUser[user.id] = (totalEventsWithTimeForAUser[user.id] || 0);
            }
          } else { // stats remain same
            actualTimeTotalForAUser[user.id] = (actualTimeTotalForAUser[user.id] || 0);
            estimatedTimeTotalForAUser[user.id] = (estimatedTimeTotalForAUser[user.id] || 0);
            totalEventsWithTimeForAUser[user.id] = (totalEventsWithTimeForAUser[user.id] || 0);
          }
        }
        
      }
    }
    // console.log(actualTimeTotalForAUser, estimatedTimeTotalForAUser);
    const totalActualTimeMap = this.helperService.computeUsersToEventsMap(actualTimeTotalForAUser, this.users, this.removeEmpty);
    const totalEstimatedTimeMap = this.helperService.computeUsersToEventsMap(estimatedTimeTotalForAUser, this.users, this.removeEmpty);
    const totalEventsWithTimeForAUserMap = this.helperService.computeUsersToEventsMap(totalEventsWithTimeForAUser, this.users, this.removeEmpty);

    this.usersLabels = Array.from(totalActualTimeMap.keys());
    this.totalActualTimeTaken = Array.from(totalActualTimeMap.values());
    this.totalEstimatedTimeTaken = Array.from(totalEstimatedTimeMap.values());
    this.totalEventsCount = Array.from(totalEventsWithTimeForAUserMap.values());
    this.makeChart();
  }

  makeChart(): void {
    // destroy chart if already there
    if (this.verticalBarChart) {
      this.verticalBarChart.destroy();
    }

    const chartDatasets = [
      {
        label: 'Actual Time Taken',
        data: this.totalActualTimeTaken.map((count, index) => ({ x: this.usersLabels[index], y: count })), // all events here
        backgroundColor: '#43A047',
        borderColor: '#43A047',
        borderWidth: 1,
      },
      {
        label: 'Estimated Time',
        data: this.totalEstimatedTimeTaken.map((count, index) => ({ x: this.usersLabels[index], y: count })), // all events here
        backgroundColor: '#D3D3D3',
        borderColor: '#D3D3D3',
        borderWidth: 1,

      }
    ]

    // chart config options,
    const chartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 0, // Adjust as needed
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltip) => {
              const tooltipItem = tooltip;
              const datasetLabel = tooltipItem.dataset.label || '';
              const dataValue = tooltipItem.formattedValue;
              const totalValue = this.totalEventsCount[tooltipItem.dataIndex];
              if (datasetLabel === 'Estimated Time') {
                return `Total Estimated Time: ${dataValue} - Total Tasks - ${totalValue}`;
              } else {
                return `Total Actual Time Taken: ${dataValue} - Total Tasks - ${totalValue}`;
              }
            }
          }
        },
        legend: {
          position: 'bottom'
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 10,
            }
          }
        },
        y: {
          stacked: false,
        }
      }
    };

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.verticalBarChart = new Chart.Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.usersLabels, // past days 1 to 30 by default
        datasets: chartDatasets,
      },
      options: chartOptions
    });
  }

  handleChartDateRangeChange(chartDateRange: models.DateRanger) {
    this.dateRange = chartDateRange;
    this.selectedInterval = this.helperService.getDefaultInterval(chartDateRange.rangeType);
    this.prepareChartEvents(this.filteredEvents, this.dateRange);
  }

  handleChartIntervalChange(chartInterval: string) {
    this.selectedInterval = chartInterval;
    this.prepareChartEvents(this.filteredEvents, this.dateRange);
  }

  resetFilters() {
    this.chartDaterangeFilterComponent.setDefaultDateRange();
  }
}
