
<mat-form-field>
    <mat-label>Select Sensors</mat-label>
    <mat-select [(ngModel)]="sensorIds" multiple #sensorSelect="ngModel">
        <mat-select-trigger>
            {{title}}
        </mat-select-trigger>
        <mat-option [value]="s.id" *ngFor="let s of sensors">{{s.name}}</mat-option>
    </mat-select>
    <mat-spinner matSuffix *ngIf="loading" diameter=25 strokeWidth=4></mat-spinner>
</mat-form-field>

<div class="row" *ngIf="displayList">
    <div class="col-12">
        <h5>Selected Sensors:</h5>
        <ul class="list">
            <li *ngFor="let sensor of selectedSensors">
                {{sensor.name}}
            </li>
        </ul>
    </div>
</div>