import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { SessionStorageService } from 'angular-web-storage';

import * as models from '../models';
import { HelperService } from './helper.service';
import { ClaimsService } from './claims.service';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {

    constructor(
        private storage: AngularFireStorage,
        private sessionStorage: SessionStorageService,
        private helperService: HelperService,
        private claimsService: ClaimsService
    ) {
    }

    private replaceCompanyString(path: string): string {
        let companyId = this.helperService.currentCompanyId;
        return path.replace('{companyId}', companyId);
    }

    private replaceUserString(path: string): string {
        let userId = this.claimsService.currentUserId();
        return path.replace('{userId}', userId);
    }

    upload(path: string, data: any, imageFor: string = 'company') : AngularFireUploadTask {
        if (imageFor === 'company') {
            path = this.replaceCompanyString(path);
        } else if (imageFor === 'user') {
            path = this.replaceUserString(path);
        }
        let response = this.storage.upload(path, data);
        return response;
    }

    get(path: string, imageFor: string = 'company') : Observable<any> {
        if (imageFor === 'company') {
            path = this.replaceCompanyString(path);
        } else if (imageFor === 'user') {
            path = this.replaceUserString(path);
        }
        return this.storage.ref(path).getDownloadURL();
    }

    delete(path: string, imageFor: string = 'company') {
        if (imageFor === 'company') {
            path = this.replaceCompanyString(path);
        } else if (imageFor === 'user') {
            path = this.replaceUserString(path);
        }
        return this.storage.ref(path).delete();
    }
}