import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';

import * as models from '../../models';
import * as services from 'app/services';
import { ZoneSetupComponent } from 'app/components/zones/zone-setup/zone-setup.component';

@Component({
  selector: 'app-zone-assign',
  templateUrl: './zone-assign.component.html',
  styleUrls: ['./zone-assign.component.scss']
})
export class ZoneAssignComponent implements OnInit {

  @ViewChild(ZoneSetupComponent) zoneSetupComponent: ZoneSetupComponent;

  phase: models.HarvestPhase;

  constructor(
    private dialogRef: MatDialogRef<ZoneAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //dialogRef.disableClose = true;
    this.phase = data.phase;
  }

  ngOnInit(): void {
  }

  savephaseZone() {
    let zones: Map<string, models.HarvestPhaseStrainZone[]> = this.zoneSetupComponent.getSelectedZones();
    let keys = Object.keys(zones);

    //set strains that are passed back from dialog
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let strainZones: models.HarvestPhaseStrainZone[] = zones[key];

      let strain: models.HarvestPhaseStrain = this.phase.strains.find(i => i.strainId == key);
      if(strain != null){
        strain.zones = strainZones;
      }
    }

    //remove zones from strains that have zero zones
    this.phase.strains.filter(i => !keys.includes(i.strainId)).forEach(strain => {
      strain.zones = [];
    })

    this.dialogRef.close(this.phase);
  }

}
