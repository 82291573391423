
export interface CompanyNotification {
    uid?: string;
    name?: string;
    enabled?: boolean;
    message?: string;
    frequency?: string;
    dayOfWeek?: number;
    dayOfMonth?: string;
    hour?: number;
    ampm?: string;
    role?: string;
}
