import { Timestamp } from "@angular/fire/firestore";
import { User } from "./user";

export interface UserLink {
    id?: string;
    url?: string;
    linkId?: string;
    expiration?: Timestamp;
    companyId?: string;

    user?: User;
    companyName?: string;
}