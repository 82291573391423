import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-sensor-zone-timeline-dialog',
  templateUrl: './sensor-zone-timeline-dialog.component.html',
  styleUrls: ['./sensor-zone-timeline-dialog.component.scss']
})
export class SensorZoneTimelineDialogComponent implements OnInit {

  history: Array<models.SensorZoneHistory> = [];
  listDisplay: Array<SensorZoneHistoryViewModel> = []

  constructor(
    private dialogRef: MatDialogRef<SensorZoneTimelineDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private zoneService: services.ZoneService
  ) { 
    if(data != null){
      this.history = data.history;
    }
  }

  ngOnInit(): void {
    this.getZones();
  }

  async getZones() {
    let zones = await this.zoneService.getAll()

    this.history.forEach(item => {
      let zone = this.zoneService.getZone(zones, item.zoneId);
      if(zone == null){
        let vm: SensorZoneHistoryViewModel = {
          zoneId: item.zoneId,
          dateMovedToZone: item.dateMovedToZone,
          zoneHierarchy: 'No Zone'
        }
        this.listDisplay.push(vm)
        return;
      }

      let zoneHierarchyString: string = zone.name;

      let parents = this.zoneService.getListOfParentNames(zones, item.zoneId);

      for (let index = parents.length-1; index >= 0; index--) {
        const element = parents[index];
        
        zoneHierarchyString = `${element} -> ${zoneHierarchyString}`
      }

      let vm: SensorZoneHistoryViewModel = {
        zoneId: item.zoneId,
        dateMovedToZone: item.dateMovedToZone,
        zoneHierarchy: zoneHierarchyString
      }
      this.listDisplay.push(vm)
    });

  }

  submit() {
    this.dialogRef.close(this.history);
  }

  deleteClick(vm: SensorZoneHistoryViewModel) {
    this.history = this.history.filter(i => i.zoneId != vm.zoneId);
    this.listDisplay = this.listDisplay.filter(i => i.zoneId != vm.zoneId);
  }
}

interface SensorZoneHistoryViewModel extends models.SensorZoneHistory {
  zoneHierarchy: string;
}