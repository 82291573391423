import { Component, OnDestroy, OnInit } from '@angular/core';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';

import { Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';

@Component({
  selector: 'app-company-settings-notifications',
  templateUrl: './company-settings-notifications.component.html',
  styleUrls: ['./company-settings-notifications.component.scss']
})
export class CompanySettingsNotificationsComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  companySub: Subscription;

  saving: boolean = false;

  systemSettings: models.SystemSettings = {};
  company: models.Company = {};
  initialCompany: models.Company = {};

  roles: Array<models.Role> = [];

  customSubject: string;
  customMessage: string;

  get canSeeBlastNotifications() {
    if(!this.systemSettings.notificationsSendPush){
      return false;
    }

    return this.claimsService.userRole?.permissions?.includes(models.Permissions.notificationBlast);
  }

  constructor(
    private claimsService: services.ClaimsService
    , private helperService: services.HelperService
    , private roleService: services.RoleService
    , private systemSettingsService: services.SystemSettingsService
    , private companyService: services.CompanyService
    , private notifierService: services.NotifierService
    , private notificationService: services.NotificationService
  ) {
    super()
   }

  canDeactivate():boolean{
    return !this.dataUpdated();
  }

  dataUpdatedBool: boolean = false;
  dataUpdated(): boolean {
    let x = JSON.stringify(this.initialCompany);
    let y = JSON.stringify(this.company);

    this.dataUpdatedBool = x !== y;
    return this.dataUpdatedBool;
  }

  ngOnInit(): void {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.load(companyId);
    }
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load(data);
    })
  }

  ngOnDestroy() {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  load(companyId: string) {
    this.getSystemSettings();
    this.get();
    this.getRoles();
  }

  async getRoles() {
    let roles: Array<models.Role> = await this.roleService.getRoles();
    //let currentUserRole = await this.claimsService.getRoleAwait()
    // roles = roles.filter(r => {
    //   return r.precedence >= currentUserRole.precedence;
    // })

    // this.roles = roles.sort((a, b) => {
    //   return a.precedence - b.precedence;
    // });

    this.roles = this.helperService.sortArrayByStringField(roles, 'name');
  }

  async getSystemSettings() {
    this.systemSettings = await this.systemSettingsService.get();
  }

  async get() {
    let companyId = this.helperService.currentCompanyId;
    let company = await this.companyService.getById(companyId, true);
    if(company.leaflogix == null){
      company.leaflogix = {adminUserIds: []};
    }
    this.company = company;
    //this.eventTooltipDisplay = company.eventsTooltips ? company.eventsTooltips : false;
    this.initialCompany = this.helperService.deepCopy(company);
  }

  updateTime(timeDisplay: viewmodels.TimeDisplayViewModel){
    if(timeDisplay.time > 12){
      timeDisplay.time -= 12;
      timeDisplay.ampm = 'pm';
    } else if(timeDisplay.time === 12){
      timeDisplay.ampm = 'pm';
    } else if (timeDisplay.time === 0) {
      timeDisplay.time = 12;
      timeDisplay.ampm = 'am';
    } else {
      timeDisplay.ampm = 'am';
    }
  }

  async save() {
    let dutchieChanged: boolean = false;
    if(this.initialCompany.leaflogix?.active != this.company.leaflogix?.active || this.initialCompany.leaflogix?.apiKey != this.company.leaflogix?.apiKey){
      dutchieChanged = true;
    }
    this.saving = true;
    let companyToSave = this.helperService.deepCopy(this.company)
    await this.companyService.save(companyToSave);
    this.helperService.currentCompany = companyToSave;
    this.get();
    this.saving = false;

    this.notifierService.success('Successfully saved Company notifications');

    // if(this.company.leaflogix?.active && dutchieChanged){
    //   this.notifierService.confirm('Initial Sync', 'Would you like to run an initial sync from Dutchie?  This will update all Strains and Rooms.  Anything currently saved will be overwritten.',
    //   () => {this.syncLeaflogix()},
    //   () => {})
    // }
  }

  async blastNotification() {
    let response: any = await this.notificationService.blastNotification(this.customSubject, this.customMessage);

    if(response.data.success){
      alert(response.data.message);
    } else {
      alert('Unexpected error.  Contact administrator for assistance.')
    }
  }

  addNoti() {
    if(this.company.notifications == null){
      this.company.notifications = [];
    }

    let newNotification: models.CompanyNotification = {
      name: 'Notification' + (this.company.notifications.length+1),
      enabled: true,
      hour: 12,
      ampm: 'pm',
      frequency: 'daily',
      role: this.roles[this.roles.length-1].id.toString()
    }
    this.company.notifications.push(newNotification);
  }

  deleteNotification(n: models.CompanyNotification){
    let index = this.company.notifications.indexOf(n);
    if (index > -1) {
      this.company.notifications.splice(index, 1);
    }
  }

}
