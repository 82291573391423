import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as models from '../../models';
import * as services from '../../services';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  userSaveClick: boolean = false;

  user: models.User = {};

  constructor(
    private dialogRef: MatDialogRef<UserEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: services.UserService,
    private snackBar: MatSnackBar
  ) { 
    dialogRef.disableClose = true;
    this.user = data;
  }

  ngOnInit(): void {
  }

  async saveUser() {
    this.userSaveClick = true;
    let response = await this.userService.updateUser(this.user);
    if(response.data.success){
      this.dialogRef.close();
    } else {
      this.snackBar.open(response.data.message.errorInfo.message);
    }

    this.userSaveClick = false;
  }

}
