<h2 mat-dialog-title>Upgrade to PlanaCan Premium!</h2>
<mat-dialog-content *ngIf="attemptedAction == 'upgrade'">
    <div class="row">
        <!-- Single Special Area -->
        <div class="col-12 col-md-4" *ngIf="attemptedAction == 'harvestAdd' || attemptedAction == 'upgrade'">
            <div class="single-special text-center wow fadeInUp" data-wow-delay="0.2s">
                <div class="single-icon">
                    <i class="material-icons">list</i>
                </div>
                <h4>Harvests</h4>
                <p style="padding-bottom: 15px;">
                    <b>Why upgrade?</b><br><br>
                    Create multiple Harvests with unique identifiers. For a commercial  operation this is a must.
                </p>
            </div>
        </div>
        <!-- Single Special Area -->
        <div class="col-12 col-md-4" *ngIf="attemptedAction == 'templateAdd' || attemptedAction == 'upgrade'">
            <div class="single-special text-center wow fadeInUp" data-wow-delay="0.4s">
                <div class="single-icon">
                    <i class="material-icons">file_copy</i>
                </div>
                <h4>Templates</h4>
                <p style="padding-bottom: 15px;">
                    <b>Why upgrade?</b><br><br>
                    Multiple Templates allow you to automate different processes in your grow. Use them for specific strain schedules, or any other step-by-step procedures.
                </p>
            </div>
        </div>
        <!-- Single Special Area -->
        <div class="col-12 col-md-4" *ngIf="attemptedAction == 'userAdd' || attemptedAction == 'upgrade'">
            <div class="single-special text-center wow fadeInUp" data-wow-delay="0.6s">
                <div class="single-icon">
                    <i class="material-icons">person_outline</i>
                </div>
                <h4>Users</h4>
                <p style="padding-bottom: 15px;">
                    <b>Why upgrade?</b><br><br>
                    Add more Users to connect your grow. Assign different Users to tasks, and customize permissions for each employee from owner to trimmer.
                </p>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content>
    <div class="row">
        <!-- Single Special Area -->
        <div class="col-12 col-md-12" *ngIf="attemptedAction == 'harvestAdd'">
            <div class="single-special text-center wow fadeInUp" data-wow-delay="0.2s">
                <div class="single-icon">
                    <i class="material-icons">list</i>
                </div>
                <h4>Harvests</h4>
                <p style="padding-bottom: 15px;">
                    You can only create {{appStorage.freeHarvests}} Harvests in the free version of this software.<br><br>
                    <b>Why upgrade?</b><br><br>
                    Create multiple Harvests with unique identifiers. For a commercial  operation this is a must.
                </p>
            </div>
        </div>
        <!-- Single Special Area -->
        <div class="col-12 col-md-12" *ngIf="attemptedAction == 'templateAdd'">
            <div class="single-special text-center wow fadeInUp" data-wow-delay="0.4s">
                <div class="single-icon">
                    <i class="material-icons">file_copy</i>
                </div>
                <h4>Templates</h4>
                <p style="padding-bottom: 15px;">
                    You can only create {{appStorage.freeTemplates}} Templates in the free version of this software.<br><br>
                    <b>Why upgrade?</b><br><br>
                    Multiple Templates allow you to automate different processes in your grow. Use them for specific strain schedules, or any other step-by-step procedures.
                </p>
            </div>
        </div>
        <!-- Single Special Area -->
        <div class="col-12 col-md-12" *ngIf="attemptedAction == 'userAdd'">
            <div class="single-special text-center wow fadeInUp" data-wow-delay="0.6s">
                <div class="single-icon">
                    <i class="material-icons">person_outline</i>
                </div>
                <h4>Users</h4>
                <p style="padding-bottom: 15px;">
                    You can only create {{appStorage.freeUsers}} Users in the free version of this software.<br><br>
                    <b>Why upgrade?</b><br><br>
                    Add more Users to connect your grow. Assign different Users to tasks, and customize permissions for each employee from owner to trimmer.
                </p>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <app-upgrade-btn [trialDays]="7" (successfulUpgrade)="successfulUpgrade()"></app-upgrade-btn>
</mat-dialog-actions>
