import { Component, Input, OnInit } from '@angular/core';

import * as models from 'app/models';

@Component({
  selector: 'app-zone-identifiers-detail',
  templateUrl: './zone-identifiers-detail.component.html',
  styleUrls: ['./zone-identifiers-detail.component.scss']
})
export class ZoneIdentifiersDetailComponent implements OnInit {

  @Input() zones: Array<models.Zone> = [];
  //lastInList: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
