import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../../environments/environment';

import * as services from '../../services';
import * as squareSDK from '@square/web-sdk';
//import { loadSquare } from '@square/web-sdk/dist/load';

//import { loadSquare } from 'square-web-sdk';

//import Stripe from 'stripe';
//const stripe = new Stripe(environment.stripe, {apiVersion: '2020-08-27'});

//import {loadStripe} from '@stripe/stripe-js';


//declare var SqPaymentForm: any;

@Component({
  selector: 'app-upgrade-btn',
  templateUrl: './upgrade-btn.component.html',
  styleUrls: ['./upgrade-btn.component.scss']
})
export class UpgradeBtnComponent implements OnInit, AfterViewInit {
  
  @ViewChild('NgxAsidePanelRight') NgxAsidePanelRight;

  //paymentForm: any;
  card: squareSDK.Card;

  loadingCardReader: boolean = true;
  upgrading: boolean = false;
  @Input() trialDays: number;

  @Output() successfulUpgrade: EventEmitter<any> = new EventEmitter();

  cardHolderName: string;
  paymentProviderCustomerId: string;

  get startDate() : Date {
    let date = new Date();
    date.setDate(date.getDate() + this.trialDays);
    return date;
  }

  constructor(
    private snackBar: MatSnackBar,
    // private stripeService: services.StripeService,
    private claimsService: services.ClaimsService,
    private userService: services.UserService,
    private companyService: services.CompanyService,
    private helperService: services.HelperService,
    // private router: Router,
    private dsls: services.DynamicScriptLoaderService,
    private squareService: services.SquareService,
    private notifierService: services.NotifierService
  ) { }

  // async upgrade() {
  //   this.upgrading = true;
  //   const stripe = await loadStripe(environment.stripe.publicKey);

  //   let inObj: any = {
  //     priceId: environment.stripe.priceId,
  //     trialDays: this.trialDays,
  //   }

  //   let userId = this.claimsService.currentUserId();
  //   let user = await this.userService.getUserById(userId);
  //   let company = await this.companyService.getCurrent();
  //   inObj.customerId = company.stripeCustomerId;
  //   inObj.email = user.emailAddress;

  //   this.stripeService.createStripeSession(inObj).then(data => {

  //     stripe
  //       .redirectToCheckout({
  //         sessionId: data.data.id
  //       })
  //       .then(result => {
  //         if (result.error) {
  //           this.snackBar.open(result.error.message);
  //         }
  //       });
  //   })
  // }

  upgrade() {
    this.NgxAsidePanelRight.show()
    //this.initPayment();
    this.initCard();
  }
  
  onCancel() {
    this.NgxAsidePanelRight.hide()
  }

  onSave() {
    alert('Save')
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  async initializeCard(payments: squareSDK.Payments): Promise<squareSDK.Card> {
    const card = await payments.card();
    await card.attach('#card-container'); 
    return card; 
  }
  
  async initCard() {
    // let src: string = 'https://sandbox.web.squarecdn.com/v1/square.js';
    // if(environment.production){
    //   src = 'https://web.squarecdn.com/v1/square.js';
    // }
    // await loadSquare(src);

    // if (!window.Square) {
    //   throw new Error('Square.js failed to load properly');
    // }
    const payments = await squareSDK.payments(environment.square.appId, environment.square.locationId);
    
    try {
      this.card = await this.initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
    }

    this.loadingCardReader = false;
  }


  // This function tokenizes a payment method. 
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async tokenize(paymentMethod: squareSDK.Card) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }

  async handlePaymentMethodSubmission(event) {
    event.preventDefault();
 
    try {
      // disable the submit button as we await tokenization and make a
      // payment request.
      this.upgrading = true;
      const token = await this.tokenize(this.card);
      const paymentResults = await this.upgradeAccount(token);
      this.notifierService.success('SUCCESS')
 
      console.debug('Payment Success', paymentResults);
    } catch (e) {
      this.upgrading = false;
      this.notifierService.error('There was an error entering the Card information');
      console.error(e.message);
    }
  }

  async upgradeAccount(nonce: string) {
    let userId = this.claimsService.currentUserId();
    let user = await this.userService.getUserById(userId);
    let company = await this.companyService.getCurrent();

    let arr = user.displayName.split(' ');
    let firstName = arr[0];
    arr.shift();
    let lastName = arr.join();

    let month = '' + (this.startDate.getMonth() + 1);
    let day = '' + this.startDate.getDate();
    let year = this.startDate.getFullYear();
    let startDate = [year, month, day].join('-');

    let upgadeResponse = await this.squareService.upgradeAccount(firstName, lastName, company.name, company.uid, 
      user.emailAddress, nonce, this.cardHolderName, startDate, this.paymentProviderCustomerId, null);

    if(upgadeResponse.data.success){
      this.helperService.company_freeVersion = false;
      this.NgxAsidePanelRight.hide();
      this.successfulUpgrade.emit();
    } else {
      this.snackBar.open(upgadeResponse.data.message);
    }

    this.upgrading = false;
  }




  // async initPayment() {
  //   await this.dsls.loadScript(environment.square.script);

  //   this.paymentForm = new SqPaymentForm({ 
  //     applicationId: environment.square.appId,
  //     autoBuild: false,
  //     card: {
  //       elementId: 'sq-card',
  //     },
  //     // SqPaymentForm callback functions
  //     callbacks: {
  //       /*
  //       * callback function: cardNonceResponseReceived
  //       * Triggered when: SqPaymentForm completes a card nonce request
  //       */
  //       cardNonceResponseReceived: (errors, nonce, cardData) => {
  //         if (errors) {
  //             // Log errors from nonce generation to the browser developer console.
  //             console.error('Encountered errors:');
  //             errors.forEach(function (error) {
  //                 console.error('  ' + error.message);
  //             });
  //             //alert('Encountered errors, check browser developer console for more details');
  //             this.snackBar.open('There was an error entering the Card information')
  //             this.upgrading = false;
  //             return;
  //         }
          
  //         this.upgradeAccount(nonce);
  //       }
  //     }
  //   });
  //   this.paymentForm.build();
  // }

  // onGetCardNonce(event) {
  //   this.upgrading = true;

  //   // Don't submit the form until SqPaymentForm returns with a nonce
  //   event.preventDefault();
  //   // Request a nonce from the SqPaymentForm object
  //   this.paymentForm.requestCardNonce();
  // }
}

