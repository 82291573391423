import { DutchieUnmatchedBatchSelectDialogComponent } from 'app/dialogs/dutchie-unmatched-batch-select-dialog/dutchie-unmatched-batch-select-dialog.component';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from 'moment';

import * as services from 'app/services';
import * as models from 'app/models';
import * as leaflogix from 'app/models/leaflogix/index';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';
import { ComponentCanDeactivate } from '../../../services/guards/component-can-deactivate';

import { MatDialog } from '@angular/material/dialog';

import {
  TemplateAddComponent,
  RestrictionNotificationComponent,
  EventAddComponent,
  HarvestDocumentsDialogComponent,
  HarvestEditDialogComponent,
  EventAddFromLibraryDialogComponent,
} from '../../../dialogs';
import { Title } from '@angular/platform-browser';
import { AppStorage } from '../../../services';
import { EventListComponent } from 'app/components/event/event-list/event-list.component';
import { P } from '@angular/cdk/keycodes';
import { TimelineHelper } from 'app/components/timeline/timeline-helper';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-harvest-detail',
  templateUrl: './harvest-detail.component.html',
  styleUrls: ['./harvest-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HarvestDetailComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild(EventListComponent) public eventListComponent: EventListComponent;

  //variables
  pageLoaded: boolean = false;
  collectionType: string = 'harvests';
  harvestId: string;
  harvest: models.Harvest;
  initialHarvest: models.Harvest;
  events: Array<models.Event>;
  timelineData: Array<any> = [];

  //subscriptions
  company: models.Company;
  companySub: Subscription;
  //harvestSub: Subscription;

  startDate: Timestamp;
  harvestDate: Timestamp;
  endDate: Timestamp;
  cloneDaysDif: number;
  harvestDaysDif: number;
  endDateDaysDif: number;
  templateUsedForHarvest: models.Template;
  color: string;

  dutchieAdmin: boolean = false;

  eventListChange(events: Array<models.Event>){
    this.events = events;
    if(events == null){
      return;
    }
    
    //startDate and endDate
    if(this.events == null || this.events.length == 0){
      return null;
    }

    if(this.harvest.startDate == null || this.harvest.endDate == null){
      let sorted: models.Event[] = this.helperService.sortArrayByNumberField(this.events, 'startDateTime');
      this.startDate = sorted[0].startDateTime;
      this.harvestDate = sorted[sorted.length-1].startDateTime;
      this.endDate = sorted[sorted.length-1].startDateTime;
    } else {
      this.startDate = this.harvest.startDate;
      this.harvestDate = this.harvest.harvestDate ?? this.harvest.endDate;
      this.endDate = this.harvest.endDate;
    }
    
    //cloneDateDif
    let now = moment();
    let cloneDate = moment(this.startDate.seconds*1000);
    this.cloneDaysDif = cloneDate.diff(now, 'days');
    
    //harvestDateDif
    let endDate = moment(this.endDate.seconds*1000);
    this.endDateDaysDif = endDate.diff(now, 'days');
    
    //harvestDateDif
    let harvestDate = moment(this.harvestDate.seconds*1000);
    this.harvestDaysDif = harvestDate.diff(now, 'days');

    //compile data for timeline
    this.timelineData = this.timelineHelper.createTimelineDataFromEventList(this.events);

    this.pageLoaded = true;
  }

  //permissions
  get canEditHarvest() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.editHarvest);
  }

  //getters
  get saveBtnEnabled() {
    return this.harvestDirty || this.eventListComponent?.hasPendingChanges;
  }

  get harvestDirty() {
    let x = JSON.stringify(this.harvest);
    let y = JSON.stringify(this.initialHarvest);
    return x != y;
  }
  
  get cloneDaysDifAbs() {
    return Math.abs(this.cloneDaysDif);
  }
  
  get harvestDaysDifAbs() {
    return Math.abs(this.harvestDaysDif);
  }
  
  get endDaysDifAbs() {
    return Math.abs(this.endDateDaysDif);
  }

  get numberOfEvents() {
    return this.events != null ? this.events.length : 0;
  }
  get numberOfCompletedEvents() {
    let completedEvents = 0;
    if(this.events == null) return 0;
    this.events.forEach(event => {
      if(event.completed){
        completedEvents++;
      }
    });
    return completedEvents;
  }
  get percentOfEventsCompleted() {
    return (this.numberOfCompletedEvents / this.numberOfEvents) * 100;
  }
  get percentOfEventsCompletedString() {
    return this.percentOfEventsCompleted + "%";
  }

  get canCompleteEvent(): boolean { 
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.completeEvent);
  }

  get canAddEvent(): boolean { 
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent);
  }

  //strains
  allStrains: Array<models.Strain> = []

  getZoneIds(strain: models.HarvestPhaseStrain) {
    if(strain.zones == null){
      return [];
    }
    return strain.zones.map(i => i.zoneId);
  }

  setCanSeeDutchie() {
    let userId = this.claimsService.currentUserId();

    this.dutchieAdmin = this.helperService.currentCompany.leaflogix?.active && this.helperService.currentCompany.leaflogix?.adminUserIds?.includes(userId);
  }

  constructor(
    private router: Router
    , public dialog: MatDialog
    , private activatedRoute: ActivatedRoute
    , private harvestService: services.HarvestService
    , private helperService: services.HelperService
    , private claimsService: services.ClaimsService
    , private titleService: Title
    , private templateService: services.TemplateService
    , private appStorage: AppStorage
    , private zoneService: services.ZoneService
    , private eventService: services.EventService
    , private notifierService: services.NotifierService
    , private timelineHelper: TimelineHelper
    , private leafLogixService: services.LeaflogixService
  ) { 
    super();
  }

  ngOnInit() {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.findHarvestId();
    }
    //set up subscription to listen to company id changes
    this.company = this.helperService.currentCompany;
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.company = data;
      this.findHarvestId();

      let userId = this.claimsService.currentUserId();
      this.dutchieAdmin = data.leaflogix?.active && data.leaflogix?.adminUserIds?.includes(userId);
    })

    this.setCanSeeDutchie();
  }

  ngOnDestroy() {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
    // if(this.harvestSub != null){
    //   this.harvestSub.unsubscribe();
    // }
  }

  canDeactivate() : boolean {
    return !this.harvestDirty && !this.eventListComponent?.hasPendingChanges;
  }

  findHarvestId() {
    this.activatedRoute.params.subscribe( params => {
      this.harvestId = params.harvestId;
      this.getHarvest();
    });
  }

  async getHarvest() {
    // if (this.harvestSub != null) {
    //   this.harvestSub.unsubscribe();
    // }

    let harvestResponse = await this.harvestService.get(this.harvestId).get().toPromise();

    if(harvestResponse.exists) {
      this.harvest = harvestResponse.data();
      this.color = this.harvest.color;
      this.templateService.get(this.harvest.templateId)
      .then(template => {
        this.templateUsedForHarvest = template;
      });
      if(this.harvest.strainIds == null){
        this.harvest.strainIds = [];
      }
      if(this.harvest.phases == null){
        this.harvest.phases = [];
      }
      this.harvest.phases.forEach(phase => {
        if(phase.strains == null){
          phase.strains = []
        }
      });
      if(this.harvest.postHarvestData == null){
        this.harvest.postHarvestData = {};
      }
      this.titleService.setTitle(`${this.harvest.name} - PlanaCan`)
      this.harvest.uid = harvestResponse.id;
      this.initialHarvest = this.helperService.deepCopy(this.harvest);
    } else {
      this.router.navigateByUrl('/console/harvests')
    }

    // this.harvestSub = this.harvestService.get(this.harvestId).snapshotChanges().subscribe(harvestResponse => {
    //   if(harvestResponse.payload.exists){
    //     this.harvest = harvestResponse.payload.data();
    //     this.color = this.harvest.color;
    //     this.templateService.get(this.harvest.templateId)
    //     .then(template => {
    //       this.templateUsedForHarvest = template;
    //     });
    //     if(this.harvest.strainIds == null){
    //       this.harvest.strainIds = [];
    //     }
    //     if(this.harvest.phases == null){
    //       this.harvest.phases = [];
    //     }
    //     this.harvest.phases.forEach(phase => {
    //       if(phase.strains == null){
    //         phase.strains = []
    //       }
    //     });
    //     if(this.harvest.postHarvestData == null){
    //       this.harvest.postHarvestData = {};
    //     }
    //     this.titleService.setTitle(`${this.harvest.name} - PlanaCan`)
    //     this.harvest.uid = harvestResponse.payload.id;
    //     this.initialHarvest = this.helperService.deepCopy(this.harvest);
    //   } else {
    //     this.router.navigateByUrl('/console/harvests')
    //   }
    // });
  }

  navigateToTemplateDetails() {
    this.router.navigate(['console', 'template', this.templateUsedForHarvest.uid])
  }

  backToHarvestList() {
    this.router.navigate(['console', 'harvests']);
  }

  async backfillTemplate() {
    let numberOfTemplates = await this.templateService.getNumberOfTemplates();
    if(this.helperService.company_freeVersion && numberOfTemplates >= this.appStorage.freeTemplates) {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'templateAdd'
      });
      return;
    }

    let dialogRef = this.dialog.open(TemplateAddComponent, {
      panelClass: 'med-width-dialog',
      data: {isCopy: false, harvestIdToTemplate: this.harvestId}
    });
  }

  saveClick() {
    if(this.harvestDirty) {
      this.saveHarvestChanges();
    }
    if(this.eventListComponent.hasPendingChanges){
      this.eventListComponent.saveChanges();
    }
  }

  undoClick() {
    if(this.harvestDirty) {
      this.undoHarvestChanges();
    }
    if(this.eventListComponent.hasPendingChanges){
      this.eventListComponent.undoChanges();
    }
  }

  async saveHarvestChanges() {
    await this.harvestService.update(this.harvest)
    this.notifierService.success('Successfully saved Harvest ' + this.harvest.name);
    this.getHarvest();
  }

  undoHarvestChanges() {
    this.getHarvest();
  }

  saveHarvestName(event) {
    event.target.blur()
    this.saveHarvestChanges()
  }


  addEvent() {
    let data: any = { 
      harvestId: this.harvestId,
      saveInDialog: this.helperService.currentCompany?.eventsSplit || false
     };
    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: data,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.processEventAddResponse(result);
    });
  }

  addEventFromLibrary() {
    let inObj: any = {
      harvestId: this.harvestId
    }
    let dialogRef = this.dialog.open(EventAddFromLibraryDialogComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe((result: viewmodels.EventAddResponse) => {
      this.processEventAddResponse(result);
    })
  }

  async processEventAddResponse(result: viewmodels.EventAddResponse) {
    if(result && result.save){
      //perform save
      let assignToGroup: boolean = result.assignToGroup;
      let harvest: models.Harvest = result.harvest;
      let group: models.Group = result.group;

      let events: Array<models.Event> = result.events;
      let dbEvents: Array<models.Event> = [];

      events.forEach(event => {
        let dbEvent: models.Event = event;

        dbEvent.color = assignToGroup ? group.color : harvest.color;

        if(assignToGroup){
          delete dbEvent.harvestName;
          dbEvent.groupName = group.name;
        } else {
          delete dbEvent.groupName;
          dbEvent.harvestName = harvest.name;
        }

        dbEvents.push(dbEvent);
      });

      await this.eventService.add(assignToGroup, assignToGroup ? group.uid : harvest.uid, dbEvents);
    }
  }

  harvestDocuments() {
    let dialogRef = this.dialog.open(HarvestDocumentsDialogComponent, {
      data: { 
        harvestId: this.harvestId
      }
    });
  }

  editHarvest() {
    if(!this.canEditHarvest) {
      return;
    }

    let dialogRef = this.dialog.open(HarvestEditDialogComponent, {
      data: { 
        type: 'harvest',
        id: this.harvestId,
        color: this.color,
        harvest: this.harvest
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.color = result.color;
        this.harvest.color = this.color;
        this.harvest.name = result.name;
        this.initialHarvest.color = this.color;
      }
    })
  }

  unlinking: boolean = false;
  unlinkClick() {
      this.notifierService.confirm('Unlink Batch to Harvest', 'Are you sure you want to unlink this Batch from this Harvest?',
      () => {this.unlink()},
      () => {})
  }

  async unlink() {
      this.unlinking = true;
      await this.harvestService.unlinkDutchieBatch(this.harvestId);
      this.harvest.leaflogixId = null;
      this.harvest.leaflogixName = null;
      this.initialHarvest.leaflogixId = null;
      this.initialHarvest.leaflogixName = null;
      //this.initialHarvest = this.helperService.deepCopy(this.harvest);
      this.unlinking = false;
      this.notifierService.success('Successfully unlinked Dutchie Batch from the PlanaCan Harvest')
  }

  linking: boolean = false;
  async linkToDutchieBatch() {
    this.linking = true;

    //check harvest template
    let currentTemplate = await this.templateService.get(this.harvest.templateId);

    if (
        currentTemplate == null ||
        currentTemplate.phases.filter((i) => i.compliancePhase == null)
            .length > 0
    ) {
        this.notifierService.errorWithBtn(
            "You do not have a Dutchie compliance stage associated with each phase in the Template used to create this Harvest. Need help?",
            "Click here",
            () => {
            this.navigateToTemplate(currentTemplate?.uid);
            },
            false
        );
        this.linking = false;
        return;
    }

    //open dutchie link select dialog
    let dialogRef = this.dialog.open(DutchieUnmatchedBatchSelectDialogComponent);

    dialogRef.afterClosed().subscribe(async result => {
        if (result?.submitted) {
            let batch: leaflogix.Batch = result.data;

            let harvestPartial: models.Harvest = {
                leaflogixName: batch.batchName.toString(),
                leaflogixId: batch.batchId.toString()
            }
            await this.harvestService.partialUpdate(harvestPartial, this.harvestId);

            //get current model
            this.harvest.leaflogixId = batch.batchId.toString();
            this.harvest.leaflogixName = batch.batchName.toString();
            this.initialHarvest.leaflogixId = batch.batchId.toString();
            this.initialHarvest.leaflogixName = batch.batchName.toString();

            this.appStorage.unlinkedDutchieBatches = this.appStorage.unlinkedDutchieBatches - 1;

            this.notifierService.success(
            `You've linked PlanaCan Harvest ${this.harvest.name} with Dutchie Batch ${batch.batchName}. All plant counts, plant attrition, and room movements will now be displayed and updated in PlanaCan when it is done syncing.`
            );

            //this.sync();
        }
        this.linking = false;
    })
  }

  // async sync() {
  //   this.notifierService.success('Kicking off process to sync Harvests');
  //   let response = await this.leafLogixService.syncLeaflogixCompany();
  //   let syncResponse: models.Result<boolean> = response.data;

  //   if (syncResponse.success) {
  //     this.notifierService.success('Successfully synced all Harvests that are linked to LeafLogix Batches');
  //   } else {
  //     this.notifierService.error('There was an error syncing with Dutchie')
  //   }
  // }

  navigateToTemplate(templateId: string) {
      let extras: NavigationExtras = {
        queryParams: {
          'tourstep': 'stages'
        }
      }
      this.router.navigate(['console', 'template', templateId], extras)
  }
}
