import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrls: ['./date-picker-dialog.component.scss']
})
export class DatePickerDialogComponent implements OnInit {

  public title: string = 'DatePicker';
  minDate: Date = null;

  public date: Date;
  public time: string

  constructor(
    private dialogRef: MatDialogRef<DatePickerDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data != null){
      this.title = data.title;
      this.minDate = data.minDate;
    }
    this.date = new Date();
    let hours: string = this.date.getHours().toString();
    if(this.date.getHours() < 10){
      hours = '0' + hours;
    }
    let minutes: string = this.date.getMinutes().toString();
    if(this.date.getMinutes() < 10){
      minutes = '0' + minutes;
    }
    this.time = `${hours}:${minutes}`;
  }

  ngOnInit(): void {
  }

  submit() {
    let split = this.time.split(':');
    let hour = +split[0];
    let min = +split[1];
    //let date = new Date(event.startDateTime.seconds * 1000);
    this.date.setHours(hour);
    this.date.setMinutes(min);
    this.dialogRef.close(this.date);
  }

}
