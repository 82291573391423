<h2 mat-dialog-title>{{header}}</h2>
<mat-dialog-content>

  <form id="harvestSelectDialog" class="harvest-select-form" (ngSubmit)="submit()">

    <div class="row">
      <div class="col-sm-12">
        <h4 mat-dialog-title>Harvests</h4>

        <app-harvest-select-multiple 
            [displayList]="true" 
            [displayListSide]="true" 
            [harvests]="harvests" 
            [(selectedHarvests)]="selectedHarvests">
        </app-harvest-select-multiple>

      </div>
      <!-- <div class="col-sm-6">
          <ul>
              <li *ngFor="let h of selectedHarvests">{{h.name}}</li>
          </ul>
      </div> -->
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{negativeBtn}}</button>
  <button mat-button type="submit" form="harvestSelectDialog" [disabled]="selectedHarvests.length == 0">
    {{affirmativeBtn}}
  </button>
</mat-dialog-actions>
