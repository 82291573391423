import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-url-upload-dialog',
  templateUrl: './url-upload-dialog.component.html',
  styleUrls: ['./url-upload-dialog.component.scss']
})
export class UrlUploadDialogComponent implements OnInit {

  url: string;

  constructor(
    private dialogRef: MatDialogRef<UrlUploadDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(this.url);
  }

}
