
<div class="row">
    <div class="col-12 right">
        <button id="configs_savBtn" mat-raised-button class="pad-btn" [disabled]="!dirty" title="Save Configs" (click)="saveSettings()">
            <mat-icon aria-hidden="false" aria-label="Save Template">save</mat-icon>
        </button>
        <button mat-raised-button class="pad-btn" [disabled]="!dirty" title="Discard Changes" (click)="getSettings()">
            <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <mat-slide-toggle [(ngModel)]="settings.notificationsSendPush">Notifications Send Phone Push</mat-slide-toggle>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <mat-slide-toggle [(ngModel)]="settings.notificationsSendEmail">Notifications Send Email</mat-slide-toggle>
    </div>
</div>