import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-company-select-multiple-dialog',
  templateUrl: './company-select-multiple-dialog.component.html',
  styleUrls: ['./company-select-multiple-dialog.component.scss']
})
export class CompanySelectMultipleDialogComponent implements OnInit {

  title: string = 'Select Companies'

  companies: models.Company[] = [];
  selectedCompanies = new FormControl('');
  saveClicked: boolean = false;
  initialSelectAll: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CompanySelectMultipleDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data) {
      this.companies = data.companies;
      this.initialSelectAll = data.initialSelectAll;
      this.title = data.title;
    }
  }

  ngOnInit(): void {
    if(this.initialSelectAll){
      this.selectedCompanies.setValue(this.companies);
    }
  }

  companyChange(newValue: models.Company[]) {
    this.selectedCompanies.setValue(newValue);
  }

  selectAll() {
    this.selectedCompanies.setValue(this.companies);
  }

  close() {
    this.dialogRef.close({ confirm: false })
  }

  async yesClick() {
    this.dialogRef.close({ confirm: true, selectedCompanies: this.selectedCompanies.value })
  }

}
