import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';

import * as models from 'app/models';
import * as services from 'app/services';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-team-select-multiple',
  templateUrl: './team-select-multiple.component.html',
  styleUrls: ['./team-select-multiple.component.scss']
})
export class TeamSelectMultipleComponent implements OnInit, OnDestroy {

  @Input() disabled = false;
  @Input() displayTeamList: boolean = false;

  protected teams: models.Team[] = [];
  protected users: models.User[] = [];
  public teamCtrl: FormControl = new FormControl();
  public teamFilterCtrl: FormControl = new FormControl();
  public filteredTeams: ReplaySubject<models.Team[]> = new ReplaySubject<models.Team[]>(1);
  protected _onDestroy = new Subject<void>();

  protected filterTeams() {
    if (!this.teams) {
      return;
    }
    // get the search keyword
    let search = this.teamFilterCtrl.value;
    if (!search) {
      this.filteredTeams.next(this.teams.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the teams
    let filteredTeams = this.teams.filter(team => {
      return team.name != null && team.name.toLowerCase().indexOf(search) > -1
    })
    this.filteredTeams.next(filteredTeams);
  }

  //selectedTeamsValue: Array<models.Team>;
  @Input()
  get selectedTeams() {
    return this.teams.filter(i => this.teamIds.includes(i.uid));
    //return this.selectedTeamsValue;
  }
  @Output() selectedTeamsChange = new EventEmitter();
  set selectedTeams(val) {
    if(val == null){
      val = [];
    }
    this.teamIds = val.map(i => i.uid);
    //this.selectedTeamsValue = val;
    //this.setForm();
    this.selectedTeamsChange.emit(val);
  }

  teamIDsValue: Array<string>;
  @Input()
  get teamIds() {
    return this.teamIDsValue;
  }
  @Output() teamIdsChange = new EventEmitter();
  set teamIds(val) {
    if(val == null){
      val = [];
    }
    this.teamIDsValue = val;
    this.setForm();
    this.teamIdsChange.emit(this.teamIDsValue);
    this.selectedTeamsChange.emit(this.selectedTeams);
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  companySub: Subscription;

  get title(): string {
    if(this.selectedTeams == null || this.teams == null){
      return '';
    }
    if(this.selectedTeams.length == this.teams.length){
      return 'ALL';
    }
    if(this.selectedTeams.length >= 1){
      let title = this.selectedTeams[0].name;
      if(this.selectedTeams.length >= 2){
        title += ` (+${this.selectedTeams.length - 1} ${this.selectedTeams?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }

  // getTeam(id: string): string {
  //   return this.teams.find(i => i.uid == id).name;
  // }

  constructor(
    private teamService: services.TeamService
    , private helperService: services.HelperService
    , private userService: services.UserService
  ) { }

  ngOnInit(): void {
    let companyId = this.helperService.currentCompanyId;

    //if we have it then load
    if(companyId != null){
      this.getUsers(companyId).then(usersFetchSuccess => {
        this.getTeams();
      });
    }

    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.getUsers(data).then(usersFetchSuccess => {
        this.getTeams();
      });
      
    })

    // listen for search field value changes
    this.teamFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTeams();
      });
  }

  ngOnDestroy(): void {
    this.companySub.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async getUsers(companyId: string) {
    let userResponse = await this.userService.getUsersForCompanyWithCaching(companyId, true, true);
    this.users = userResponse;
  }

  async getTeams() {
    (await this.teamService.getAll()).snapshotChanges().subscribe(snapshot => {
      let teams = [];
      let data = snapshot
      data.forEach((element) => {
        let team: models.Team = element.payload.doc.data();
        team.uid = element.payload.doc.id;

        // find team members from all company users,
        const teamMembers = this.users.filter((companyUser) => {
          return team.userIds.some(teamUser => teamUser === companyUser.id)
        })

        // join all user's displayNames into a strings
        let usersTooltipContent = teamMembers.map(teamMember => teamMember.displayName).join("\n");

        // make new key in a team for tooltip
        team['allTeamUsersTooltip'] = usersTooltipContent;
        teams.push(team);
      });
      this.teams = this.helperService.sortArrayByStringField(teams, 'name');
      this.filteredTeams.next(this.teams.slice());
      this.setForm();
    })
  }

  setForm() {
    this.teamCtrl.setValue(this.teams.filter(i => this.teamIds.includes(i.uid)));
  }

  async setTeams() {
    this.teamIds = this.teamCtrl.value.map(i => i.uid);
  }
}
