import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, NgZone, OnDestroy } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import { NavbarComponent } from '../../components/layout/navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Subscription, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import PerfectScrollbar from 'perfect-scrollbar';

import { Auth, Unsubscribe, User, authState, onAuthStateChanged } from '@angular/fire/auth';

import { SessionStorageService } from 'angular-web-storage';

import * as models from '../../models';
import * as services from '../../services';
import { Title } from '@angular/platform-browser';

import { DeviceDetectorService } from 'ngx-device-detector';
import * as introJs from 'intro.js/intro.js';
import { BlockNotificationComponent, EmbededVideoDialogComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  user: User;

  companySubCancelledSub: Subscription;

  doneText:string = 'OK';
  progressIndicatorLocation: string = 'hidden';

  introJS = introJs();

  get showReleaseNotification(): boolean {
    let releaseDate: Date = new Date(this.appStorage.releaseDate.getTime());
    releaseDate.setDate(releaseDate.getDate() + 7) 
    return new Date() <= releaseDate;
  }

  constructor(
      public location: Location
      , private router: Router
      , private route: ActivatedRoute
      , private titleService: Title
      , private helperService: services.HelperService
      , private roleService: services.RoleService
      , private claimsService: services.ClaimsService
      , private companyService: services.CompanyService
      , private storage: SessionStorageService
      , private zone: NgZone
      , private userService: services.UserService
      , private tourService: services.TourService
      , private harvestService: services.HarvestService
      , private deviceDetector: DeviceDetectorService
      , private calendarService: services.CalendarService
      , private dialog: MatDialog
      , private auth: Auth
      , public appStorage: services.AppStorage
    ) {}

  async ngOnInit() {

    this.titleService.setTitle('Console - PlanaCan');

    this.redirectIfNoCompanies();

    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
        // if we are on windows OS we activate the perfectScrollbar function

        document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
    } else {
        document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

    this.location.subscribe((ev:PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event:any) => {
        if (event instanceof NavigationStart) {
            if (event.url != this.lastPoppedUrl)
                this.yScrollStack.push(window.scrollY);
        } else if (event instanceof NavigationEnd) {
            if (event.url == this.lastPoppedUrl) {
                this.lastPoppedUrl = undefined;
                window.scrollTo(0, this.yScrollStack.pop());
            } else
                window.scrollTo(0, 0);
        }
    });
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
    });
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        let ps = new PerfectScrollbar(elemMainPanel);
        ps = new PerfectScrollbar(elemSidebar);
    }

    //await this.getRoles();
    this.monitorAuthStatus();
    //this.findLocationToGoTo();
    //this.findIfCancelled();
  }
  ngAfterViewInit() {
      this.isLoggedIn();
      this.runOnRouteChange();
  }
  isMaps(path){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      titlee = titlee.slice( 1 );
      if(path == titlee){
          return false;
      }
      else {
          return true;
      }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
      let bool = false;
      if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
          bool = true;
      }
      return bool;
  }

  async isLoggedIn() {
    let user = await authState(this.auth).toPromise();
    if(user == null){
        this.router.navigate(['login']);
    }
  }

  ngOnDestroy(): void {
    if(this.companySubCancelledSub != null){
      this.companySubCancelledSub.unsubscribe();
    }
  }

  async redirectIfNoCompanies() {
    //check company ids
    let companyResponse = await this.companyService.getAllCompaniesForUser();
    if(!companyResponse.success || companyResponse.value.length == 0){
      this.router.navigate(['no-companies']);
    }
  }

  monitorAuthStatus(): void {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        this.user = user;
        this.claimsService.setUserClaims();
        this.checkUser();

        //this.findLocationToSendTo();
      } else {
        this.claimsService.clearClaims();
        //this.router.navigateByUrl('/login', {skipLocationChange: true});
        var title = this.location.prepareExternalUrl(this.location.path());
        window.location.href = '/';
      }
    });
  }

  // async findLocationToSendTo() {
  //   firebase.auth().currentUser.getIdTokenResult().then(async (idTokenResult) => {
  //     if(idTokenResult.claims.companyId){
  //       let companyIds = this.helperService.extractCompanyIds(idTokenResult.claims.companyId);
  //       this.storage.set('companyIds', companyIds || []);

  //       if(companyIds.length === 1){
  //         this.storage.set('companyId', companyIds[0]);
  //       }
  //     }

  //     await this.getCompany(this.storage.get('companyId'));

  //     var title = this.location.prepareExternalUrl(this.location.path());
  //     if(title.charAt(0) === '#'){
  //       title = title.slice( 2 );
  //     }
  //     title = title.split('?')[0]

  //     let companyIdFromStorage = this.storage.get('companyId');
  //     if(title != '/company-select' && companyIdFromStorage === null){
  //       this.zone.run(() => this.router.navigateByUrl('/company-select'));
  //       //window.location.href = '/company-select';
  //     } else {
  //       this.redirectIfNotAlready(title, ['console', 'calendar']);
  //     }

  //     // if (idTokenResult.claims.admin) {
  //     //   //this.router.navigate(['company-select']);

  //     // } else if (idTokenResult.claims.owner) {
  //     //   this.redirectIfNotAlready(title, '/console/calendar');
  //     // } else if (idTokenResult.claims.manager) {
  //     //   this.redirectIfNotAlready(title, '/console/calendar');
  //     // } else if (idTokenResult.claims.standardUser){
  //     //   this.redirectIfNotAlready(title, '/console/calendar');
  //     // } else{
  //     //   //show error
  //     //   this.redirectIfNotAlready(title, '/no-permissions');
  //     // }
  //   });
  // }

  // redirectIfNotAlready(title: string, pathParts: string[]) {
  //   if(title == '/console'){
  //     this.zone.run(() => this.router.navigate(pathParts, { preserveQueryParams: true }));

  //     //window.location.href = path;
  //   }
  // }

  // findLocationToGoTo() {
  //   var title = this.location.prepareExternalUrl(this.location.path());
  //   if(title.charAt(0) === '#'){
  //     title = title.slice( 2 );
  //   }
  //   title = title.split('?')[0]

  //   if(title == '/console'){
  //     this.zone.run(() => this.router.navigate(['console', 'calendar'], { preserveQueryParams: true }));

  //     //window.location.href = path;
  //   }
  // }

  async getCompany(companyId: string){
    if(companyId == null)
        return;
    let company: models.Company = await this.companyService.getById(companyId);
    this.storage.set('companyName', company.name);
  }

  async checkUser() {
    if(this.user == null){
      return;
    }

    let user: models.User = await this.userService.getUserById(this.user.uid);

    if(user.mustCompleteTour && !this.deviceDetector.isMobile()){
      let tourstep: string = this.route.snapshot.queryParamMap.get('tourstep');

      if(tourstep == null){
        this.tourService.addStepToQueryString('start');
      }
    }

    // if(user.createDemoHarvest){ //moving this to backend
    //   let companyId = this.helperService.currentCompanyId;
    //   if(companyId == null){

    //     this.currentCompanySub = this.helperService.getCurrentCompanyId().subscribe(id => {
    //       this.createDemoHarvest(id, user);
    //     })
    //   } else {
    //     this.createDemoHarvest(companyId, user);
    //   }
    // };
  }

  // currentCompanySub: Subscription;
  // async createDemoHarvest(companyId: string, user: models.User) {
  //   if(this.currentCompanySub != null){
  //     this.currentCompanySub.unsubscribe();
  //   }

  //   let response = await this.harvestService.createDemoHarvest(companyId)

  //   if(response){
  //     user.createDemoHarvest = false;
  //     this.userService.update(user);

  //     this.calendarService.fireNext();
  //   };
  // }

  // findIfCancelled() {
  //   let block = this.helperService.company_subscriptionCancelled;
  //   this.companySubCancelledSub = this.helperService.getcompany_subscriptionCancelled().subscribe(data => {
  //     this.displayBlock(data);
  //   })
  //   this.displayBlock(block);
  // }

  // displayBlock(cancelled: boolean) {
  //   if(!cancelled){
  //     return;
  //   }

  //   let dialogRef = this.dialog.open(BlockNotificationComponent, {
  //     panelClass: 'med-width-dialog'
  //   });
  // }

  bannerClick() {
    if(this.appStorage.releaseVideoUrl == null) {
      window.open('https://planacan.io/release-notes/', '_blank');
      return;
    }

    //open dialog
    this.dialog.open(EmbededVideoDialogComponent, {
      data: {title: `Release ${this.appStorage.releaseVersion}`, url: this.appStorage.releaseVideoUrl}
    });
  }
}
