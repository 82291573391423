<div class="row">
    <div class="col-6">
        <div class="row" style="padding-bottom: 10px;">
            <div class="col-12">
                <button class="gridbtn" style="float: right;" mat-raised-button [disabled]="dirty" (click)="addTeam()" title="Add Team">
                  <mat-icon aria-hidden="false" aria-label="Add Team">add</mat-icon>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ag-grid-angular 
                  style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
                  class="ag-theme-balham"
                  [gridOptions]="gridOptions"
                  (selectionChanged)="onSelectionChanged()"
                  [suppressRowClickSelection]="dirty">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="selectedTeam != null">
        <div class="row"> 
          <div class="col-sm-12 right">
            <button class="gridbtn" mat-raised-button [disabled]="!dirty || saving" (click)="save()" title="Save Team">
              <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon aria-hidden="false" aria-label="Save" *ngIf="!saving">save</mat-icon>
            </button>
            <button class="gridbtn" mat-raised-button *ngIf="!adding" [disabled]="!dirty" (click)="undo()" title="Undo">
              <mat-icon aria-hidden="false" aria-label="Undo">undo</mat-icon>
            </button>
            <button class="gridbtn" mat-raised-button [disabled]="deleting" (click)="deleteClick()" title="Delete Team">
              <mat-spinner *ngIf="deleting" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon aria-hidden="false" aria-label="Delete" *ngIf="!deleting">delete</mat-icon>
            </button>
          </div>
        </div>
        <div class="row"> 
          <div class="col-sm-12">
            <mat-form-field>
              <input matInput required name=name [(ngModel)]="selectedTeam.name" placeholder="Team Name">
            </mat-form-field>
          </div>
        </div>
        <div class="row"> 
          <div class="col-sm-12">
            <app-user-select-multiple [disabled]="readOnly" [displayUserList]="true" [(userIds)]="selectedTeam.userIds"></app-user-select-multiple>
          </div>
        </div>
    </div>
</div>