<button mat-raised-button class="gridbtn" *ngIf="canSeeMassUserAssign || canSeeHarvestGroupDelete"
    [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="canSeeMassUserAssign" (click)="usersMassAssign()">
        <mat-icon>group_add</mat-icon>
        <span>Mass assign Users</span>
    </button>
    <app-harvest-shift-btn [dropDownMenu]="true"></app-harvest-shift-btn>
    <button mat-menu-item *ngIf="canSeeHarvestGroupDelete" (click)="harvestGroupDelete()">
        <mat-icon>delete</mat-icon>
        <span>Delete Harvests and Task Groups</span>
    </button>
    <app-leaflogix-harvest-sync-btn (addedHarvests)="addedHarvestsFunc($event)"></app-leaflogix-harvest-sync-btn>
    <app-leaflogix-sync-btn></app-leaflogix-sync-btn>
</mat-menu>