<ng-container *ngIf="selectedCompany != null">
    <mat-form-field style="padding: 0px 10px;" *ngIf="changeCompany">
        <mat-select [(ngModel)]="selectedCompany" (selectionChange)="selectionChange($event.value)">
            <mat-option *ngFor="let company of companies" [value]="company">
                {{ company.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <span *ngIf="!changeCompany">{{selectedCompany.name}}</span>
    <span style="font-size: 12px" *ngIf="!changeCompany && companies.length > 1">  
        (<a (click)="change()" href="javascript:void(0);">change</a>)
    </span>
    
</ng-container>