
<h2 mat-dialog-title>Ask us a question</h2>
<mat-dialog-content>
  <form id="help" class="fp-form" (ngSubmit)="save()">

    <mat-form-field class="example-full-width">
        <mat-label>Ask a question</mat-label>
        <textarea matInput rows="5" maxlength="500" placeholder="Ex. I am looking for and can't find..." [(ngModel)]="question" [ngModelOptions]="{standalone: true}"></textarea>
        <mat-hint align="end">{{question?.length || 0}}/500</mat-hint>
    </mat-form-field>
  
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="working">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="help" [disabled]="working">
    <mat-spinner *ngIf="working" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!working">Ask</span>
  </button>
</mat-dialog-actions>