<h2 mat-dialog-title>Repeat</h2>
<mat-dialog-content>
  <form id="repeat" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-4">
        <mat-label>Repeat Every</mat-label>
      </div>
      <div class="col-3">
        <mat-form-field>
          <!-- <mat-label>Search...</mat-label> -->
          <input matInput type="number" [(ngModel)]="number" name="number">
          <!-- <mat-icon matSuffix>tag</mat-icon> -->
        </mat-form-field>
        <!-- <mat-form-field>
          <mat-label>Interval</mat-label>
          <mat-select [(value)]="number">
            <mat-option *ngFor="let i of numbers" [value]="i">{{i}}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="col-5">
        <mat-form-field>
          <!-- <mat-label>Timespan</mat-label> -->
          <mat-select [(value)]="interval" (selectionChange)="intervalChange($event.value)"><!--  -->
            <mat-option value="daily">Day(s)</mat-option>
            <mat-option value="weekly">Week(s)</mat-option>
            <mat-option value="monthly">Month(s)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="padding-bottom: 10px" *ngIf="interval == 'weekly'">
      <div class="col-12" style="text-align: center;">
        <mat-button-toggle-group name="fontStyle" disableRipple="true" class="daysOfWeek" [(ngModel)]="daysOfWeek" multiple>
          <mat-button-toggle value="sunday">Su</mat-button-toggle>
          <mat-button-toggle value="monday">M</mat-button-toggle>
          <mat-button-toggle value="tuesday">T</mat-button-toggle>
          <mat-button-toggle value="wednesday">W</mat-button-toggle>
          <mat-button-toggle value="thursday">Th</mat-button-toggle>
          <mat-button-toggle value="friday">F</mat-button-toggle>
          <mat-button-toggle value="saturday">Sa</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-form-field>
      <input matInput [matDatepicker]="endDatePicker" placeholder="Choose an end date" name="endDate" [formControl]="endDate" [min]="minDate" [max]="maxDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="repeat">OK</button>
</mat-dialog-actions>
