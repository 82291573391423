import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from '../../../models';
import * as services from '../../../services';

@Component({
  selector: 'app-strain-select',
  templateUrl: './strain-select.component.html',
  styleUrls: ['./strain-select.component.scss']
})
export class StrainSelectComponent implements OnInit {

  @Input() disabled = false;
  @Input() strains: Array<models.Strain>;

  strainValue: Array<models.Strain>;
  @Input()
  get strain() {
    return this.strainValue;
  }
  @Output() strainChange = new EventEmitter();
  set strain(val) {
    if(val == null){
      val = [];
    }
    this.strainValue = val;
    this.strainChange.emit(this.strainValue);
  }

  constructor(
    private companyService: services.CompanyService
  ) { }

  ngOnInit(): void {
    if(this.strains == null){
      this.companyService.getCurrent().then(data => {
        this.strains = data.strains;
      })
    }
  }
}
