import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as models from '../../../models';
import * as services from '../../../services';
import { Subscription, Observable, Subject } from 'rxjs';
import { ChartOptions, ChartType } from 'chart.js';
import * as Chart from 'chart.js/auto';
import { ChartDaterangeFilterComponent } from 'app/components/chart-filters/chart-daterange-filter/chart-daterange-filter.component';

@Component({
  selector: 'app-event-volume-combo-chart',
  templateUrl: './event-volume-combo-chart.component.html',
  styleUrls: ['./event-volume-combo-chart.component.scss']
})
export class EventVolumeComboChartComponent implements OnInit {

  @ViewChild('chartCanvas', { static: false }) chartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild(ChartDaterangeFilterComponent) chartDaterangeFilterComponent: ChartDaterangeFilterComponent;

  @Input() filteredEvents: models.ChartEventInput[];
  @Output() chartViewChange = new EventEmitter<any>();

  private eventVolumeComboChart;
  eventsSub: Subscription;

  chartTitle: string = 'Labor Load';
  chartSubTitle: string = 'The amount of Events scheduled vs. the historical average'
  dateRange: models.DateRanger;
  selectedInterval: string;

  dateLabels: Array<string>;
  averages: Array<Number>;
  eventsInRangeMap: Map<string, number>;
  eventsInRange: (number | null)[];
  dayLabels: Array<string>;

  private _events = new Subject<models.ChartEventInput[]>();
  getEvents(): Observable<any> {
    return this._events.asObservable();
  }

  set events(val: models.ChartEventInput[]) {
    if (val) {
      this._events.next(val);
    }
  }

  constructor(
    private helperService: services.HelperService
  ) { }

  changeChartView() {
    this.chartViewChange.emit({ expand: true,  chart: 'labor_load' });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.eventsSub) {
      this.prepareChart(this.events, this.dateRange);
    }
    this.eventsSub = this.getEvents().subscribe(data => {
      this.prepareChart(data, this.dateRange);
    })
  }

  ngOnChanges(): void {
    this.events = this.filteredEvents;
  }

  async prepareChart(events: models.ChartEventInput[], eventsDateRange: models.DateRanger) {      
    const eventsMap = this.prepareMapForBarEvents(events, eventsDateRange);
    
    let interval: string;
    interval = this.selectedInterval;

    switch(interval) {
      case 'Day':
        // set data for Bar chart
        this.eventsInRangeMap = this.helperService.aggregateDataByDay(eventsMap);
        this.dateLabels = Array.from(this.eventsInRangeMap.keys());
        this.eventsInRange = Array.from(this.eventsInRangeMap.values());
        
        // set data for line chart as per filters - day interval in this case
        this.prepareMapForLineEvents(events, eventsDateRange, interval);
        break;
      case 'Week':
        this.eventsInRangeMap = this.helperService.aggregateDataByWeek(eventsMap);
        this.dateLabels = Array.from(this.eventsInRangeMap.keys());
        this.eventsInRange = Array.from(this.eventsInRangeMap.values());
        
        // set data for line chart as per filters - day interval in this case
        this.prepareMapForLineEvents(events, eventsDateRange, interval);
        break;
      case 'Month':
        this.eventsInRangeMap = this.helperService.aggregateDataByMonth(eventsMap);
        this.dateLabels = Array.from(this.eventsInRangeMap.keys());
        this.eventsInRange = Array.from(this.eventsInRangeMap.values());
        
        // set data for line chart as per filters - day interval in this case
        this.prepareMapForLineEvents(events, eventsDateRange, interval);
        break
      case 'Quarter':
        this.eventsInRangeMap = this.helperService.aggregateDataByQuarter(eventsMap);
        this.dateLabels = Array.from(this.eventsInRangeMap.keys());
        this.eventsInRange = Array.from(this.eventsInRangeMap.values());
        
        // set data for line chart as per filters - day interval in this case
        this.prepareMapForLineEvents(events, eventsDateRange, interval);
        break;
      case 'Year':
        this.eventsInRangeMap = this.helperService.aggregateDataByYear(eventsMap);
        this.dateLabels = Array.from(this.eventsInRangeMap.keys());
        this.eventsInRange = Array.from(this.eventsInRangeMap.values());
        
        // set data for line chart as per filters - day interval in this case
        this.prepareMapForLineEvents(events, eventsDateRange, interval);
        break;
    }
    this.dateLabels = this.helperService.formatDateForCharts(this.dateLabels, interval);
    this.makeChart();
  }

  // for actual number of events in selected date range
  prepareMapForBarEvents(events: models.ChartEventInput[], eventsDateRange: models.DateRanger) {
    const startDate = new Date(eventsDateRange.datesInRange[0]);
    const endDate = new Date(eventsDateRange.datesInRange[eventsDateRange.datesInRange.length - 1]);
    endDate.setDate(endDate.getDate() + 1); //add one day to end date
    let _barEvents = []; // store events in a date range - for bars
    for (const event of events) { 
      let eventDate = new Date(event.date);
      
      if (eventDate.getTime() >= startDate.getTime() && eventDate.getTime() <= endDate.getTime()) { // check which event falls in dateRange - week by default
        _barEvents.push(event);
      }
    }
    // compute map for events on dates in default or selected date range
    let datesToEventsMap = this.helperService.computeDateWiseEventsCount(_barEvents, eventsDateRange?.datesInRange);
    return datesToEventsMap;
  }

  // for average number of events in selected date range
  async prepareMapForLineEvents(events: models.ChartEventInput[], eventsDateRange: models.DateRanger, interval: string) {
    if (eventsDateRange.rangeType === 'week' && interval === 'Day') {
      
      // computing map based on days of week - Saturday, Sunday, Friday etc., over all days of all events
      let eventGroupByDaysOverWeeks = this.helperService.groupEventsByDaysOfWeek(events);
      let numberOfWeeks = this.helperService.getTotalNumberOfWeeks(events);
      let dayWiseEventsAverageMap = this.helperService.computeDayWiseEventsAverageMap(eventGroupByDaysOverWeeks, eventsDateRange?.datesInRange, numberOfWeeks);
      this.averages = Array.from(dayWiseEventsAverageMap.values());
    } else if (eventsDateRange.rangeType === 'month' && interval === 'Day') {
      
      // computing map based on dates, irrespective of months and years, over all dates of all events
      let eventGroupByDatesOverMonths = this.helperService.groupEventsByDatesForMonths(events);
      let numberOfMonths = this.helperService.getTotalNumberOfMonths(events);
      let dateWiseEventsAverageMap = this.helperService.computeDateWiseEventsAverageMap(eventGroupByDatesOverMonths, eventsDateRange.datesInRange, numberOfMonths, 'monthly');
      this.averages = Array.from(dateWiseEventsAverageMap.values());
    } else if (eventsDateRange.rangeType === 'month' && interval === 'Week') {

      // computing map based on week number of a month, over same week number of all months, of all months of all events
      let eventGroupByWeekOfAMonth = this.helperService.groupEventsByWeekOfMonth(events);
      let numberOfWeeks = this.helperService.getTotalNumberOfWeeks(events);
      let weekWiseEventsAverageMap = this.helperService.computeWeekWiseEventsAverageMapForMonths(eventGroupByWeekOfAMonth, eventsDateRange.datesInRange, numberOfWeeks);
      this.averages = Array.from(weekWiseEventsAverageMap.values())
    } else if (eventsDateRange.rangeType === 'year' && interval === 'Day') {

      // computing map based on each date of each month, irrespective of years, over all dates of all months, of all events
      let eventGroupByDatesOverYears = this.helperService.groupEventsByDatesForYears(events);
      let numberOfYears = this.helperService.getTotalNumberOfYears(events);
      let dateWiseEventsAverageMap = this.helperService.computeDateWiseEventsAverageMap(eventGroupByDatesOverYears, eventsDateRange.datesInRange, numberOfYears, 'yearly');
      this.averages = Array.from(dateWiseEventsAverageMap.values());
    } else if (eventsDateRange.rangeType === 'year' && interval === 'Week') {

      // computing map based on each week of all events
      let eventGroupByWeeksOverYears = this.helperService.groupEventsByWeeksOverYears(events);
      let numberOfWeeks = this.helperService.getTotalNumberOfWeeks(events);
      let weekWiseEventsAverageMap = this.helperService.computeWeeksWiseEventsAverageMap(eventGroupByWeeksOverYears, eventsDateRange.datesInRange, numberOfWeeks, 'yearly');
      this.averages = Array.from(weekWiseEventsAverageMap.values())
    } else if (eventsDateRange.rangeType === 'year' && interval === 'Month') {

      // computing map based on each month, irrespective of years, over all months in all events
      let eventGroupByMonthsOverYears = this.helperService.groupEventsByMonthsOverYears(events);
      let numberOfMonths = this.helperService.getTotalNumberOfMonths(events);
      let monthWiseEventsAverageMap = this.helperService.computeMonthWiseEventsAverageMap(eventGroupByMonthsOverYears, eventsDateRange.datesInRange, numberOfMonths);
      this.averages = Array.from(monthWiseEventsAverageMap.values());
    } else if (eventsDateRange.rangeType === 'year' && interval === 'Quarter') {

      // computing map based on each quarter, over all quarters in all events
      let eventGroupByQuartersOverYears = this.helperService.groupEventsByQuartersOverYears(events);
      let numberOfQuarters = this.helperService.getTotalNumberOfQuarters(events);
      let quarterWiseEventsAverageMap = this.helperService.computeQuartersWiseEventsAverageMap(eventGroupByQuartersOverYears, eventsDateRange.datesInRange, numberOfQuarters);
      this.averages = Array.from(quarterWiseEventsAverageMap.values());
    } else if (eventsDateRange.rangeType === 'custom' && interval === 'Day') {
      
      let eventGroupByDatesOverMonths = this.helperService.groupEventsByDatesForMonths(events);
      let numberOfMonths = this.helperService.getTotalNumberOfMonths(events);
      let dateWiseEventsAverageMap = this.helperService.computeDateWiseEventsAverageMap(eventGroupByDatesOverMonths, eventsDateRange.datesInRange, numberOfMonths, 'monthly');
      this.averages = Array.from(dateWiseEventsAverageMap.values());
    } else if (eventsDateRange.rangeType === 'custom' && interval === 'Week') {
      
      let eventGroupByWeekOfAMonth = this.helperService.groupEventsByWeekOfMonth(events);
      let numberOfWeeks = this.helperService.getTotalNumberOfWeeks(events);
      let weekWiseEventsAverageMap = this.helperService.computeWeekWiseEventsAverageMapForMonths(eventGroupByWeekOfAMonth, eventsDateRange.datesInRange, numberOfWeeks);
      this.averages = Array.from(weekWiseEventsAverageMap.values())
    } else if (eventsDateRange.rangeType === 'custom' && interval === 'Month') {
      
      let eventGroupByMonthsOverYears = this.helperService.groupEventsByMonthsOverYears(events);
      let numberOfMonths = this.helperService.getTotalNumberOfMonths(events);
      let monthWiseEventsAverageMap = this.helperService.computeMonthWiseEventsAverageMap(eventGroupByMonthsOverYears, eventsDateRange.datesInRange, numberOfMonths);
      this.averages = Array.from(monthWiseEventsAverageMap.values());
    } else {
      this.averages = [];
    }
  }

  makeChart() {
    // destroy chart if already there
    if (this.eventVolumeComboChart) {
      this.eventVolumeComboChart.destroy();
    }

    const chartDatasets = [
      {
        label: 'Actual Events',
        data: this.eventsInRange.map((count, index) => ({ x: this.dateLabels[index], y: count })), // all events here
        backgroundColor: '#43A047',
        borderColor: '#43A047',
        borderWidth: 1,
        order: 1,
      },
      {
        label: 'Average Events',
        data: this.averages.map((count, index) => ({ x: this.dateLabels[index], y: count })),
        backgroundColor: 'black',
        borderColor: 'black',
        borderWidth: 1,
        pointBorderWidth: 0.5,
        type: "line" as ChartType,
        order: 0
      }
    ]

    // chart config options,
    const chartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 0, // Adjust as needed
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltip) => {
              const tooltipItem = tooltip;
              const datasetLabel = tooltipItem.dataset.label || '';
              const dataValue = tooltipItem.formattedValue;
              // const totalValue = this.totalEvents[tooltipItem.dataIndex];
              return `${datasetLabel}: ${dataValue}`;
            }
          }
        },
        legend: {
          position: 'bottom'
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 10,
            }
          }
        },
        y: {
          stacked: true,
        }
      }
    };

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.eventVolumeComboChart = new Chart.Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.dateLabels, // past days 1 to 30 by default
        datasets: chartDatasets,
      },
      options: chartOptions
    });
  }

  handleChartDateRangeChange(chartDateRange: models.DateRanger) {
    this.dateRange = chartDateRange;
    this.selectedInterval = this.helperService.getDefaultInterval(chartDateRange.rangeType);
    this.prepareChart(this.filteredEvents, this.dateRange);
  }

  handleChartIntervalChange(chartInterval: string) {
    this.selectedInterval = chartInterval;
    this.prepareChart(this.filteredEvents, this.dateRange);
  }

  resetFilters() {
    this.chartDaterangeFilterComponent.setDefaultDateRange();
  }
}
