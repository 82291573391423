import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

import * as models from '../../models';

@Component({
    selector: "process-type-cell",
    template: `{{text}}`,
    styles: []
})
export class ProcessTypeCellComponent implements ICellRendererAngularComp {
    private params: any;

    text: string;

    constructor() { }

    agInit(params: any): void {
        if (params.value == null) {
            return;
        }
        this.params = params;

        switch (this.params.value) {
            case models.ProcessType.TemplateToHarvestSync:
                this.text = 'Template to Harvest Sync'
                break;
            case models.ProcessType.MigrateCompany:
                this.text = 'Migrate Company'
                break;
            default:
                console.log(`ProcessTypeCellComponent needs updating for ${this.params.value}`);
        }
    }

    refresh(params: any): boolean {
        return false;
    }
}