// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  stripe: {
    publicKey: 'pk_test_51HmWuEEMfzkokyyJ9PAT50tT14y5LaHH611bennDKm0JzZj2JDM74aOi7CSQEuYhkey8MBQaL7Oj5xtq4fDDRfxj00cC1SGKN4',
    priceId: 'price_1HsXImEMfzkokyyJIn2LEmtT'
  },

  square: {
    script: 'square-sandbox',
    appId: 'sandbox-sq0idb-h2-I7kzjRLv5eHtXjh-Kgw',
    locationId: 'LFH6BFCTP0X90'
  },


  //PlanaCan-Test
  firebase: {
    apiKey: "AIzaSyDCw27U0ugsQEytnDtT-Fmcw-BGPcVgIY8",
    authDomain: "planacan-test.firebaseapp.com",
    databaseURL: "https://planacan-test.firebaseio.com",
    projectId: "planacan-test",
    storageBucket: "planacan-test.appspot.com",
    messagingSenderId: "194575082797",
    appId: "1:194575082797:web:7811f59c8247b8262f9fa4"
  }
  
  //PlanaCan - prod
  // firebase: {
  //   apiKey: "AIzaSyAciMl2OJq3rU4DvFcNSlrzYf7UGG0Hzpk",
  //   authDomain: "planacan.firebaseapp.com",
  //   databaseURL: "https://planacan.firebaseio.com",
  //   projectId: "planacan",
  //   storageBucket: "planacan.appspot.com",
  //   messagingSenderId: "562906032845",
  //   appId: "1:562906032845:web:4c0e44dff3bd3755"
  // }
};
