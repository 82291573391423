import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components';

import * as models from 'app/models';

@Component({
  selector: 'app-other-filter-options-btn',
  templateUrl: './other-filter-options-btn.component.html',
  styleUrls: ['./other-filter-options-btn.component.scss']
})
export class OtherFilterOptionsBtnComponent implements OnInit {
  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';

  completeStatusOptions: Array<any> = [
    {
      value: models.CompleteStatus.incomplete,
      display: 'Incomplete'
    },
    {
      value: models.CompleteStatus.complete,
      display: 'Complete'
    },
    {
      value: models.CompleteStatus.both,
      display: 'Both'
    }
  ]

  completeStatusValue: string = 'both';
  @Input()
  get completeStatus() {
    return this.completeStatusValue;
  }
  @Output() completeStatusChange = new EventEmitter();
  set completeStatus(val) {
    if(val == null){
      val = 'both';
    }
    this.completeStatusValue = val;
    this.completeStatusChange.emit(this.completeStatusValue);
  }
  initialCompleteStatus: string = 'both';

  onlyMyTasksValue: boolean = false;
  @Input()
  get onlyMyTasks() {
    return this.onlyMyTasksValue;
  }
  @Output() onlyMyTasksChange = new EventEmitter();
  set onlyMyTasks(val) {
    if(val == null){
      val = false;
    }
    this.onlyMyTasksValue = val;
    this.onlyMyTasksChange.emit(this.onlyMyTasksValue);
  }
  initialOnlyMyTasks: boolean = false;

  @Output() onClosed = new EventEmitter();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  openedEvent(event: any) {
    this.initialCompleteStatus = this.completeStatus;
    this.initialOnlyMyTasks = this.onlyMyTasks;
  }

  closedEvent(event: any) {
    if(this.initialCompleteStatus != this.completeStatus || this.initialOnlyMyTasks != this.onlyMyTasks){
      this.onClosed.emit(event);
    }
  }
}
