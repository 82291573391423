import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as models from 'app/models';
import * as services from 'app/services';
import * as leaflogix from 'app/models/leaflogix/index';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LeaflogixHarvestMatchDialogComponent } from 'app/dialogs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-leaflogix-harvest-sync-btn',
  templateUrl: './leaflogix-harvest-sync-btn.component.html',
  styleUrls: ['./leaflogix-harvest-sync-btn.component.scss']
})
export class LeaflogixHarvestSyncBtnComponent implements OnInit, OnDestroy {

  @Output() addedHarvests: EventEmitter<any> = new EventEmitter();

  disabled: boolean = false;
  companyIdSub: Subscription;
  getOverride: boolean = false;

  processingValue: boolean;
  @Input()
  get processing() {
    return this.processingValue;
  }
  @Output() processingChange = new EventEmitter();
  set processing(val) {
    this.processingValue = val;
    this.processingChange.emit(this.processingValue);
  }

  constructor(
    private helperService: services.HelperService,
    private claimsService: services.ClaimsService,
    private leafLogixService: services.LeaflogixService,
    private dialog: MatDialog,
     private appStorage: services.AppStorage,
     private notifierService: services.NotifierService,
     private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.companyIdSub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load();
    });

    this.route.queryParams.subscribe(params => {
      this.getOverride = params.dutchie;
      this.load();
    });
  }

  ngOnDestroy(): void {
    this.companyIdSub.unsubscribe();
  }

  load() {
    this.getLeaflogix();

    let currentCheckTime = this.appStorage.leaflogixCheckTime;
    if(!this.getOverride && currentCheckTime != null){
      var nextCheckTime = new Date(currentCheckTime.getTime());
      nextCheckTime.setDate(nextCheckTime.getDate() + 1);
      if(new Date() < nextCheckTime){
        return;
      }
    }
    if (this.canSeeLeaflogix) {
      this.checkHarvests();
    }
  }

  canSeeLeaflogix: boolean = false;
  getLeaflogix() {
    let company = this.helperService.currentCompany;
    let userId = this.claimsService.currentUserId();
    this.canSeeLeaflogix = company?.leaflogix?.active && company.leaflogix?.adminUserIds?.includes(userId);
  }

  checkingBatches: boolean = false;
  async checkHarvests() {
    if(this.checkingBatches){
      return;
    }
    this.checkingBatches = true;
    this.processing = true;
    let response = await this.leafLogixService.getUnlinkedBatches();
    let batchResponse: models.Result<leaflogix.Batch[]> = response.data;

    if (batchResponse.success) {
      if (batchResponse.value.length > 0) {
        console.log(batchResponse);
        //popup dialog with the batches passed in
        let dialogRef = this.dialog.open(LeaflogixHarvestMatchDialogComponent, {data: batchResponse.value});

        dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.addedHarvests.emit(result);
          }
        })
      } else {
        this.notifierService.success('There are no Batches in Dutchie to match in PlanaCan')
      }
      this.appStorage.unlinkedDutchieBatches = batchResponse.value.length;
    }
    this.appStorage.leaflogixCheckTime = new Date();
    this.checkingBatches = false;
    this.processing = false;
  }
}
