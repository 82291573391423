import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";

import * as services from "app/services";
import * as leaflogix from "app/models/leaflogix/index";
import * as models from "app/models";
import {
  HarvestAddComponent,
  HarvestSelectSingleDialogComponent,
} from "app/dialogs";

@Component({
  selector: "batch-link-cell",
  template: `
    <div *ngIf="linked" style="width: 40px; height: 40px; text-align: center; padding-top: 8px;">
        <div style="display: flex; align-items: center;">
            <mat-icon>check</mat-icon>
            <a style="padding-left: 5px; cursor: pointer" (click)="openInNewWindow()">{{harvestName}} <mat-icon style="color: grey; font-size: 14px; height: 14px; width: 14px;">exit_to_app</mat-icon></a>
            <button mat-icon-button style="height: 20px;" (click)="undoClick()" [disabled]="undoing">
              <mat-icon *ngIf="!undoing" style="font-size: 20px; height: 20px; width: 20px; margin-top: -20px;">undo</mat-icon>
              <mat-spinner *ngIf="undoing" diameter=15 strokeWidth=3></mat-spinner>
            </button>
        </div>
    </div>
    <button mat-icon-button *ngIf="!linked" [matMenuTriggerFor]="menu">
      <mat-icon>link</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="matchCurrent()">
        <mat-icon>sync_alt</mat-icon>
        <span>Match with current PlanaCan Harvest</span>
      </button>
      <button mat-menu-item (click)="addHarvest()">
        <mat-icon>add</mat-icon>
        <span>Create Harvest</span>
      </button>
    </mat-menu>
  `,
  styles: [],
})
export class BatchLinkCellComponent implements ICellRendererAngularComp {
  params: any;

  batchId: string;
  linked: boolean = false;
  harvestAdded: string = null;
  linkedHarvest: string = null;
  navigateToTemplateFunc: any;
  harvestAddedFunc: any;

  harvestId: string;
  harvestName: string;

  constructor(
    private dialog: MatDialog,
    private harvestService: services.HarvestService,
    private notifierService: services.NotifierService,
    private templateService: services.TemplateService,
    private router: Router,
    private appStorage: services.AppStorage
  ) {}

  agInit(params: any): void {
    if (!params.value) {
      return;
    }

    this.params = params;

    this.batchId = this.params.value;
    this.linked = this.params.linked;
    this.navigateToTemplateFunc = this.params.navigateToTemplateFunc;
    this.harvestAddedFunc = this.params.harvestAddedFunc;
  }

  refresh(params: any): boolean {
    return false;
  }

  matchCurrent() {
    let batch: leaflogix.Batch = this.params.data;

    let inModel = {
      header: "Match PlanaCan Harvest to Dutchie?",
      negativeBtn: "Cancel",
      affirmativeBtn: "Match",
      //harvest: harvests
    };
    let dialogRef = this.dialog.open(HarvestSelectSingleDialogComponent, {
      data: inModel,
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        let pcHarvest: models.Harvest = data;

        //   let currentTemplate = this.templates.find(i => i.uid == pcHarvest.templateId);
        //   if(currentTemplate == null){
        //     currentTemplate = await this.templateService.get(pcHarvest.templateId);
        //   }
        let currentTemplate = await this.templateService.get(
          pcHarvest.templateId
        );

        if (
          currentTemplate == null ||
          currentTemplate.phases.filter((i) => i.compliancePhase == null)
            .length > 0
        ) {
          this.notifierService.errorWithBtn(
            "You do not have a Dutchie compliance stage associated with each phase in the Template used to create this Harvest. Need help?",
            "Click here",
            () => {
              this.navigateToTemplateFunc(currentTemplate?.uid);
            },
            false
          );
          return;
        }

        this.harvestId = pcHarvest.uid;
        this.harvestName = pcHarvest.name;
        this.linked = true;
        this.linkedHarvest = pcHarvest.uid;

        pcHarvest.leaflogixName = batch.batchName.toString();
        pcHarvest.leaflogixId = batch.batchId.toString();
        await this.harvestService.update(pcHarvest);
        batch.linkedExisting = true;

        this.appStorage.unlinkedDutchieBatches = this.appStorage.unlinkedDutchieBatches - 1;

        this.notifierService.success(
          `You've synced PlanaCan Harvest ${pcHarvest.name} with Dutchie Batch ${batch.batchName}. All plant counts, plant attrition, and room movements will now be displayed and updated in PlanaCan.`
        );
      }
    });
  }

  addHarvest() {
    let batch: leaflogix.Batch = this.params.data;

    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: {
        addGroup: false,
        name: batch.batchName,
        leaflogixId: batch.batchId.toString(),
        leaflogixName: batch.batchName,
        startDate: batch.plantedOn
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.added) {
        batch.addedNew = true;

        this.harvestId = result.id[0];
        this.harvestName = result.harvestNames[0];
        this.linked = true;
        this.harvestAdded = this.harvestId;
        this.appStorage.unlinkedDutchieBatches = this.appStorage.unlinkedDutchieBatches - 1;

        this.harvestAddedFunc(result);
        this.harvestService
          .get(result)
          .get()
          .subscribe((data) => {
            let harvest: models.Harvest = data.data();
            this.notifierService.success(
              `You've synced PlanaCan Harvest ${harvest.name} with Dutchie Batch ${batch.batchName}. All plant counts, plant attrition, and room movements will now be displayed and updated in PlanaCan.`
            );
          });
      } else if (result.templateId) {
        this.navigateToTemplateFunc(result.templateId);
      }
    });
  }

  openInNewWindow() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/console/harvest/${this.harvestId}`])
    );
  
    window.open(url, '_blank');
  }

  undoClick() {
    this.notifierService.confirm('Undo', 'Are you sure you want to unlink this Harvest?',
    () => {this.undo()},
    () => {})
  }

  undoing: boolean = false;
  async undo() {
    this.undoing = true;
    if(this.linkedHarvest) { //need to update the harvest
      await this.harvestService.partialUpdate({leaflogixName: null, leaflogixId: null}, this.linkedHarvest);

      this.linked = false;
      this.linkedHarvest = null;
      this.params.data.linkedExisting = false;
    }

    if(this.harvestAdded) { //need to delete the harvest
      await this.harvestService.delete(this.harvestAdded)

      this.linked = false;
      this.harvestAdded = null;
      this.params.data.addedNew = false;
    }

    this.appStorage.unlinkedDutchieBatches = this.appStorage.unlinkedDutchieBatches + 1;
    this.notifierService.success('Successfully undid the linke between Batch and Harvest')
    this.undoing = false;
  }
}
