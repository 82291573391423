import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TourStep, Orientation, GuidedTour, GuidedTourService } from 'ngx-guided-tour';
import { UserService } from './user.service';
import { ClaimsService } from './claims.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppStorage } from './app.storage.service';

@Injectable()
export class TourService {

  constructor(
    private router: Router
    , private route: ActivatedRoute
    , private guidedTourService: GuidedTourService
    , private userService: UserService
    , private claimsService: ClaimsService
    , private deviceDetector: DeviceDetectorService
    , private appStorage: AppStorage) {
  }

  listenForQueryString(input: Array<string>) {
    return this.route.queryParams.subscribe(params => {
      let tourstep: string = params['tourstep'];

      //don't start tour if they are on mobile
      if(input.includes(tourstep) && !this.deviceDetector.isMobile()){
        this.startTour(tourstep);
      }
    });
  }

  addStepToQueryString(step: string) {
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          tourstep: step
        },
        queryParamsHandling: 'merge'
      });
  }


  makeSureElementExists(selector: string, returnFunction: any) {
    var checkExist = setInterval(() => {
      if ($(selector).length) {
         console.log(selector + " Exists!");
         clearInterval(checkExist);
         returnFunction();
      }
    }, 200); // check every 200ms
  }

  startTour(step: string){
    switch(step) {
        case 'start':
            this.makeSureElementExists("#calendar-component", () => { this.start() })
          break;
        case 'event':
            this.makeSureElementExists("#eventUpdate", () => { this.event() })
          break;
        case 'calendar2':
            this.makeSureElementExists("#calendar-filters", () => { this.calendar2() })
            break;
        case 'templates':
            this.makeSureElementExists("#templates", () => { this.templates() })
            break;
        case 'calendar3':
            this.makeSureElementExists("#calendar-harvestAdd", () => { this.calendar3() })
            break;
        case 'stages':
            this.makeSureElementExists("#template-phase-compliance-stage", () => { this.templateComplianceStage() })
            break;
        default:
          // code block
      }
  }

  start() {
    let firstStepContent: string = `
                        <p>We’re excited to help you optimize and streamline your grow scheduling.</p>
                        <p>To help you get the most out of this software, we’ve created this short walkthrough to give you an overview of how the software works.</p>
                        <p>In this tutorial we will:</p>
                        <ul>
                            <li>Explore an already created demo grow</li>
                            <li>Show you the main features of the interactive Calendar</li>
                            <li>Explain the power of Templates</li>
                            <li>Assign a task to your first user</li>
                        </ul>
                        <p>Ready? Click OK to get started!</p>`;

    let intro: TourStep = {
      //selector: '#corp_logo',
      title: 'Welcome to PlanaCan!',
      content: firstStepContent,
      orientation: Orientation.BottomRight
    }
    let calendar: TourStep = {
      selector: '#calendar-component',
      title: 'Calendar',
      content: `<p>This is a sample <b>Harvest</b> we’ve already created to show you how a <b>Harvest</b> schedule looks and works.</p>`,
      //content: `<p>Your Calendar is where you view all of the information about day-to-day operations in your grow. Here you can create and view additional tasks for each harvest, manage employees, and schedule groups.</p><p>This is a sample harvest we’ve already created to show you how a schedule looks and works.</p>`,
      orientation: Orientation.Left,
      scrollAdjustment: -500
    }
    let event: TourStep = {
      selector: '.fc-daygrid-event',
      title: 'Events',
      content: '<p>You can click on <b>Events</b> in the harvest to see more information about them.</p>',
      orientation: Orientation.TopLeft,
      closeAction: async () => {
        let firstElement: any = document.getElementsByClassName('fc-daygrid-event')[0];
        firstElement.click();
        this.addStepToQueryString('event');
      }
    }

    // let eventpopup: TourStep = {
    //   selector: '.mat-dialog-container',//cdk-overlay-pane
    //   title: 'Events',
    //   content: `<p>Events and their information are the building blocks of your grow.</p>
    //             <p><b>Attach files, assign users, and mark events as completed from this view.</b></p>
    //             <p>All information on the event is recorded in the History log.</p>`,
    //   orientation: Orientation.Left,
    //   closeAction: async() => {
    //     document.getElementById("eventUpdate-Cancel").click();
    //     this.addStepToQueryString('calendar2');
    //     this.startTour('calendar2')
    //   }
    // }

    let tour: GuidedTour = {
      tourId: 'Start',
      useOrb: false,
      steps: [
        intro, calendar, event//, eventpopup
      ],
      preventBackdropFromAdvancing: true,
      skipCallback: async () => {
        await this.userService.completeTour(this.claimsService.currentUserId())
        this.addStepToQueryString(null);
        this.appStorage.skippedTour = true;
      },
    }
    
    this.guidedTourService.startTour(tour);

  }

  event() {

    let eventpopup: TourStep = {
      selector: '.cdk-overlay-pane',//cdk-overlay-pane
      title: 'Events',
      content: `<p><b>Events</b> and their information are the building blocks of your grow.</p>
                <p><b>Attach files, assign users, and mark events as completed from this view.</b></p>
                <p>All of the <b>Event</b> information is recorded in the History log to the right.</p>`,
      orientation: Orientation.Left,
      closeAction: async() => {
        document.getElementById("eventUpdate-Cancel").click();
        this.addStepToQueryString('calendar2');
        this.startTour('calendar2')
      }
    }

    let tour: GuidedTour = {
        tourId: 'Event',
        useOrb: false,
        steps: [
            eventpopup
        ],
        preventBackdropFromAdvancing: true,
        skipCallback: async () => {
          await this.userService.completeTour(this.claimsService.currentUserId())
          this.addStepToQueryString(null);
          this.appStorage.skippedTour = true;
        }
      }
      
      this.guidedTourService.startTour(tour);
  }

  calendar2() {
    let filterDropdowns: TourStep = {
      selector: '#calendar-filters',
      title: 'Harvests and Group Dropdowns',
      content: `<p>The <b>Select Harvest(s)</b> drop down allows you to view different harvests. View all your <b>Harvests</b> at once, or filter only what you want to see.</p>
                <p>The <b>Select Task Group</b> drop down works much the same. <b>Groups</b> are tasks or events that are not tied to an individual harvest.</p>
                <p>Use <b>Groups</b> for weekly manager meetings, or a rotating team of trimmers that operate separately from the <b>Harvest</b> schedule.</p>`,
      orientation: Orientation.BottomRight
    }

    let calendar: TourStep = {
        title: 'Calendar',
        content: `<p>Take a moment to explore the <b>Calendar</b>.</p>
                  <p>Try adding an event to an existing <b>Harvest</b> (do this by clicking on a blank space in the calendar), assign yourself to an event, etc. </p>
                  <p>You can even drag and drop events to different days!</p>
                  <p>When you’re ready, click on the <b>Templates</b> page to the left to continue the tutorial.</p>`,
        //orientation: Orientation.BottomRight
    }

    let tour: GuidedTour = {
        tourId: 'Calendar2',
        useOrb: false,
        steps: [
            filterDropdowns, calendar
        ],
        preventBackdropFromAdvancing: true,
        skipCallback: async () => {
          await this.userService.completeTour(this.claimsService.currentUserId())
          this.addStepToQueryString(null);
          this.appStorage.skippedTour = true;
        },
        completeCallback: () => {
            this.addStepToQueryString('templates');
        }
      }
      
      this.guidedTourService.startTour(tour);
  }

  templates() {
    let templates: TourStep = {
      selector: '#templates',
      title: 'Templates',
      content: `<p>Welcome back. This is the <b>Templates</b> page.</p>
                <p><b>This is where you create and store your specific, step-by-step, harvest schedule that is unique to your grow.</b></p>
                <p>You can store multiple <b>Templates</b> for different strains, processes, or series of tasks.</p>
                <p><b>Templates are for anything that has a repeatable schedule in your grow.</b></p>`,
      orientation: Orientation.Left,
      scrollAdjustment: -500
    }

    let phases: TourStep = {
        selector: '.mat-tab-label-container',
        title: 'Phases',
        content: `<p>Phases help you break up your <b>Templates</b> into different sections.</p>
                  <p>Each section contains a portion of the overall tasks needed to complete the <b>Harvest</b>.</p>
                  <p><b>You can choose how to label your phases. Create as many as you want, or use one phase for your entire schedule.</b></p>
                  <p>For example, break up phases by room, or by a significant change in nutrients and process.</p>`,
        orientation: Orientation.Bottom
    }

    let task: TourStep = {
        selector: '#ng-star-inserted',
        title: 'Events: Day, Task Description, Precedence, and Time',
        content: `<p>Remember our sample grow from the <b>Calendar</b> page? These are the values that will populate into the <b>Calendar</b>.</p>`,
        orientation: Orientation.Top
    }

    let templatesOverview: TourStep = {
        title: 'Templates',
        content: `<p>Take a moment to play around with <b>Templates</b>.</p>
                  <p>Add an extra task or event to an existing <b>Templates</b>, or create one from scratch.</p>
                  <p>Go back to the <b>Calendar</b> page when you’re ready. It’s time to create your first <b>Harvest</b>!</p>`
    }

    let tour: GuidedTour = {
        tourId: 'Templates',
        useOrb: false,
        steps: [
            templates, phases, task, templatesOverview
        ],
        preventBackdropFromAdvancing: true,
        skipCallback: async () => {
            await this.userService.completeTour(this.claimsService.currentUserId())
            this.addStepToQueryString(null);
            this.appStorage.skippedTour = true;
        },
        completeCallback: () => {
            this.addStepToQueryString('calendar3');
        }
    }
      
    this.guidedTourService.startTour(tour);

  }

  calendar3() {
    let harvestAdd: TourStep = {
      selector: '#calendar-harvestAdd',
      title: 'Add Harvest',
      content: `<p>This is where you’ll create your first <b>Harvest</b>.</p>
                <p><b>Harvests</b> use the grow schedule created on the <b>Templates</b> page.</p>`,
      orientation: Orientation.Bottom,
      closeAction: () => {
        let harvestAddBtn: any = document.getElementById('calendar-harvestAdd');
        harvestAddBtn.click();
      }
    }

    let harvestAddPopup: TourStep = {
      selector: '.cdk-overlay-pane',
      title: 'Add Harvest',
      content: `<p>Choose a <b>Template</b> to use, name your <b>Harvest</b>, and choose a day to start it on. You can even color code it.</p>
                <p>P.S. This is also where you can add a <b>Group</b> as well.</p>`,
      orientation: Orientation.Left,
      action: () => {
        //fill out necessary things in popup
        //this is handled in the popup
      },
      closeAction: async() => {
          //hit save on harvest add
        let harvestSaveBtn: any = document.getElementById('harvestAdd-save');
        harvestSaveBtn.click();
      }
    }

    let calendar: TourStep = {
      selector: '#calendar-component',
      title: 'Calendar',
      content: `<p>After it is done loading, you can see the new <b>Harvest</b> on the <b>Calendar</b>.</p>`,
      orientation: Orientation.Left,
      scrollAdjustment: -500
    }

    let complete: TourStep = {
        title: 'Complete',
        content: `<p>Awesome. We've covered the basics of PlanaCan. In this tutorial we:</p>
                    <ul>
                        <li>Explored the Calendar view - where you’ll spend much of your time viewing Harvest schedules and assigning tasks.</li>
                        <li>Toured the Templates page - where you’ll store your unique grow schedules.</li>
                        <li>Created a Harvest - using the demo Template we populated a new Harvest onto the Calendar page.</li>
                    </ul>
                  <p>Please email us with any questions! <b>We are available 24/7 at <a href = "mailto: support@planacan.io">support@planacan.io</a></b>. </p>`
    }

    let tour: GuidedTour = {
        tourId: 'Calendar3',
        useOrb: false,
        steps: [
            harvestAdd, harvestAddPopup, calendar, complete
        ],
        preventBackdropFromAdvancing: true,
        skipCallback: async () => {
            await this.userService.completeTour(this.claimsService.currentUserId())
            this.addStepToQueryString(null);
            this.appStorage.skippedTour = true;
        },
        completeCallback: async () => {
            await this.userService.completeTour(this.claimsService.currentUserId())
            this.addStepToQueryString(null);
            this.appStorage.skippedTour = false;
        }
    }
      
    this.guidedTourService.startTour(tour);

  }

  templateComplianceStage() {
    let templates: TourStep = {
      selector: '#template-phase-compliance-stage',
      title: 'Compliance Stage',
      content: `<p>For each Phase in this Template, and any others you wish to use to create your batch schedules, please select the Dutchie compliance stage for each PlanaCan phase.</p>
                <p>If a compliance stage is not chosen for each Phase, the API will not successfully sync your Dutchie batches with the PlanaCan Harvest.</p>`,
      orientation: Orientation.Left
    }

    let tour: GuidedTour = {
        tourId: 'Templates',
        useOrb: false,
        steps: [
            templates
        ],
        preventBackdropFromAdvancing: true,
        // skipCallback: async () => {
        //   this.addStepToQueryString(null);
        // },
        completeCallback: () => {
          this.addStepToQueryString(null);
        }
    }
      
    this.guidedTourService.startTour(tour);

  }

}
