
export class StrainExport {
  strains?: string;

  constructor(model: StrainExport){
    this.strains = model.strains;
  }

  public validate?() : boolean {
    if (typeof this.strains !== 'string' || this.strains == null) {
      return false;
    }

    return true;
  }
}