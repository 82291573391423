
<mat-horizontal-stepper [linear]="true" #stepper style="min-width: 50vw;">
    <mat-step label="Overview">
        <mat-dialog-content style="text-align: center;">
            <p><b>Welcome to Zones setup</b></p>
            <p>This page is where you'll create and store each Zone in your cultivation. </p>
            <p>What is a Zone? <b>A Zone represents any specific physical space in your cultivation.</b> A zone could be as big as a room, or as small as a table (or smaller if you choose). </p>
            <p>Once a Zone is created, <b>Zones can be assigned to each Harvest</b> to track the physical space each Harvest is in as it progresses through the cultivation.</p>
            <p><b>You can create multiple levels of organization for each Zone</b> (or keep it simple with one Zone level). This means you can create a room, and also a table in each of those rooms.</p>
            <p>Additionally, <b>you can assign a numerical value for each Zone</b>. This number represents the total number of <b>placements</b> for the Zone. (i.e. how many plants a room can hold total).</p>
            <p>If you create multiple levels of Zones for each Zone type, <b>the lowest level of the Zone will contain the placement value.</b></p>
            <p>Click Next below to see examples of a single level Zone, and a multi level zone.</p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close *ngIf="inPopup">Close</button>
            <button mat-button matStepperNext>Next</button>
        </mat-dialog-actions>
    </mat-step>
    <mat-step label="Single Zone Group setup">
        <mat-dialog-content style="text-align: center;">
            <p>Start with a common Zone Group: Room.</p>
            <p>Each Group is completely customizable, so choose terminology that you and your team can understand!</p>
            <img class="center padding" src="assets/img/single-zone-1.png">
            <p>Once created, you can label each individual room in your grow, and note the total number placements in that room (i.e. total number of plants).</p>
            <img class="center padding" src="assets/img/single-zone-2.png">
            <p>A finished example of a Zone setup with one level of organization:</p>
            <img class="center padding" src="assets/img/single-zone-3.png">
            <p>Now you’ve labeled each room in your grow, and have a total number of placements for each of them. .</p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close *ngIf="inPopup">Close</button>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
        </mat-dialog-actions>
    </mat-step>
    <mat-step label="Multi level Zones">
        <mat-dialog-content style="text-align: center;">
            <p>If you want to get more specific about where a placement (plant) is in a Zone, you can add additional Zone groups to nest underneath the parent Zone group.</p>
            <p>Create a new Zone Group (see last slide). For this example, we’ll choose Table:</p>
            <img class="center padding" src="assets/img/multi-zone-1.png">
            <p>Once saved, you can then choose which specific parent Zone (in this case Flower 1) you’d like to nest the new Zone under, and label it:</p>
            <img class="center padding" src="assets/img/multi-zone-2.png">
            <p>After you save the new Zone, your general Zone screen might look like this:</p>
            <img class="center padding" src="assets/img/multi-zone-3.png">
            <p>You can see, there’s now two tables in the room Flower 1. Each table has 450 placements, which now adds up to the total placement amount for the entire room.</p>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button mat-dialog-close *ngIf="inPopup">Done</button>
        </mat-dialog-actions>
    </mat-step>
</mat-horizontal-stepper>