<h1 mat-dialog-title>Review All Harvests</h1>
<mat-dialog-content style="width: 65vw; height: 65vh;">
        <ag-grid-angular
            style="width: 100%; height: 100%;"
            [gridOptions]="gridOptions"
            [rowData]="harvestFormDataArray"
            [columnDefs]="columnDefs"
            (firstDataRendered)="onFirstDataRendered()"
            class="ag-theme-balham"
        >
        </ag-grid-angular>    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="goBack()">Go Back</button>
    <button mat-button *ngIf="!harvestSaveError" (click)="saveAllHarvests()">
        <mat-spinner *ngIf="saveClick" diameter=25 strokeWidth=4></mat-spinner>
        <span *ngIf="!saveClick">{{ harvestFormDataArray.length > 1 ? 'Save All' : 'Save' }}</span>
    </button>
    <button mat-button *ngIf="harvestSaveError" (click)="goBackAndModify()">
        <mat-spinner *ngIf="saveClick" diameter=25 strokeWidth=4></mat-spinner>
        <span *ngIf="!saveClick">Modify Unsaved Harvests</span>
    </button>
</mat-dialog-actions>