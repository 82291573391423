
<ng-container *ngIf="harvest != null">
  <mat-card class="sticky-header">
    <div class="row">
      <div class="col-sm-3" style="text-align: left">
        <button class="gridbtn" mat-raised-button (click)="backToHarvestList()" title="Back to List"><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div class="col-sm-6" style="text-align: center">
        <h3>
          <span 
            class="color-icon" 
            [ngStyle]="{'background-color': color ? color : '#0066FF', 'cursor': canEditHarvest ? 'pointer' : 'default'}"
            (click)="editHarvest()">
          </span>
          <span
            [ngStyle]="{'cursor': canEditHarvest ? 'pointer' : 'default'}"
            (click)="editHarvest()">
            {{harvest.name}}
          </span>
        </h3>
        <mat-hint *ngIf="harvest.leaflogixName != null">
          <b>Dutchie Batch:</b> {{harvest.leaflogixName}}
          <button mat-icon-button class="medium-icon-button" *ngIf="dutchieAdmin" [disabled]="unlinking" (click)="unlinkClick()">
              <mat-spinner *ngIf="unlinking" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon *ngIf="!unlinking">close</mat-icon>
          </button>
        </mat-hint>
        <a 
          *ngIf="dutchieAdmin && harvest.leaflogixName == null" 
          class="link-to-template" 
          (click)="linkToDutchieBatch()">
            <span *ngIf="!linking">Link to Dutchie Batch</span>
            <mat-spinner style="margin: auto;" *ngIf="linking" diameter=20 strokeWidth=3></mat-spinner>
        </a>
        <br/>
        <a class="link-to-template" (click)="navigateToTemplateDetails()"><b>Template:</b> {{ templateUsedForHarvest?.name }}</a>
      </div>
      <!-- <div class="col-sm-4" style="text-align: right"> -->
        <!--shift harvest button-->
        <!-- <app-harvest-shift-btn [harvest]="harvest"></app-harvest-shift-btn> -->
    
        <!--delete harvest button-->
        <!-- <app-harvest-delete-btn [harvest]="harvest" (doneDeleting)="backToHarvestList()"></app-harvest-delete-btn> -->
    
        <!--edit harvest button-->
        <!-- <app-harvest-edit-btn [harvest]="harvest"></app-harvest-edit-btn> -->
    
        <!-- <button data-step="2" data-intro="Use this to add and Event." data-position='left' class="gridbtn" mat-raised-button (click)="addEvent()" title="Add Event"><mat-icon>add</mat-icon></button> -->
    
        <!-- <button class="gridbtn" mat-raised-button (click)="backfillTemplate()" title="Backfill Template"><mat-icon>bookmark_add</mat-icon></button> -->
      <!-- </div> -->
      <div class="col-sm-3 right">
        <!-- <button *ngIf="canEditHarvest" mat-raised-button class="pad-btn" (click)="editClick()">
          <mat-icon>edit</mat-icon>
        </button> -->
        <ng-container>
          <app-harvest-save-btn *ngIf="canEditHarvest || canCompleteEvent" [disabled]="!saveBtnEnabled" (click)="saveClick()"></app-harvest-save-btn>
          <app-harvest-undo-btn *ngIf="canEditHarvest || canCompleteEvent" [disabled]="!saveBtnEnabled" (click)="undoClick()"></app-harvest-undo-btn>
        </ng-container>
        <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <app-harvest-shift-btn *ngIf="canEditHarvest" [disabled]="hasPendingChanges" [dropDownMenu]=true [harvest]="harvest"></app-harvest-shift-btn>
          <button *ngIf="canEditHarvest" mat-menu-item [disabled]="hasPendingChanges" (click)="backfillTemplate()" title="Backfill Template">
            <mat-icon aria-hidden="false" aria-label="Backfill Template">bookmark_add</mat-icon>
            <span>Backfill Template</span>
          </button>
          <app-harvest-delete-btn *ngIf="canEditHarvest" [harvest]="harvest" (doneDeleting)="backToHarvestList()"></app-harvest-delete-btn>
          <button mat-menu-item (click)="harvestDocuments()" title="Harvest Documents">
            <mat-icon aria-hidden="false" aria-label="Harvest Documents">folder_open</mat-icon>
            <span>Harvest Documents</span>
          </button>
          <mat-divider *ngIf="canEditHarvest"></mat-divider>
          <button mat-menu-item *ngIf="eventListComponent != null && canAddEvent" data-step="2" data-into="Use this to add an Event." data-position='left' [matMenuTriggerFor]="addeventmenu" [disabled]="eventListComponent.hasPendingChanges" title="Add Event">
            <mat-icon aria-hidden="false" aria-label="Add Event">add</mat-icon>
            <span>Add Event</span>
          </button>
          <app-apply-workflow-btn [showApplyToGroupButton]="false" [harvestId]="harvest.uid"></app-apply-workflow-btn>
        </mat-menu>

        <mat-menu #addeventmenu="matMenu">
            <button mat-menu-item (click)="addEvent(null)">
                <mat-icon>add</mat-icon>
                <span>Event</span>
            </button>
            <button mat-menu-item (click)="addEventFromLibrary()">
                <mat-icon>add_task</mat-icon>
                <span>Add From Library</span>
            </button>
        </mat-menu>
      </div>
    </div>
  </mat-card>
  
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">access_time</i>
                </div>
                <p class="card-category">Clone Date</p>
                <h3 class="card-title" *ngIf="pageLoaded">
                    {{ startDate?.seconds * 1000 | date:'MM/dd/yyyy' }}
                    <!-- <small>GB</small> -->
                </h3>
            </div>
            <div class="card-footer">
                <div class="stats" *ngIf="pageLoaded">
                  <i class="material-icons">access_time</i> 
                    <span *ngIf="cloneDaysDif < 0">Cloned {{cloneDaysDifAbs}} days ago</span>
                    <span *ngIf="cloneDaysDif == 0">Cloned Today</span>
                    <span *ngIf="cloneDaysDif > 0">{{cloneDaysDif}} days until clone</span>
                    <!-- <span>{{startDate?.seconds * 1000 | timeAgo}} until clone</span> -->
                  <!-- <i class="material-icons">access_time</i> updated {{numberOfUsers.lastUpdate | timeAgo}} -->
                    <!-- <i class="material-icons text-danger">warning</i>
                    <a href="#pablo">Get More Space...</a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">timeline</i>
                </div>
                <p class="card-category">Events Completed</p>
                <h3 class="card-title">{{numberOfCompletedEvents}} / {{numberOfEvents}}</h3>
            </div>
            <div class="card-footer">
                <!-- <div class="stats">
                </div> -->
                <div class="progress" style="width: 100%">
                  <div class="progress-bar" role="progressbar" [style.width]="percentOfEventsCompletedString" style="background: linear-gradient(60deg, #66bb6a, #43a047);" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentOfEventsCompleted | number:'1.0-0'}}%</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">content_cut</i>
                </div>
                <p class="card-category">Harvest Date</p>
                <h3 class="card-title" *ngIf="pageLoaded">
                  {{ harvestDate?.seconds * 1000 | date:'MM/dd/yyyy' }}
                </h3>
                <!-- <h3 class="card-title">{{recentRedirects.data}}</h3> -->
            </div>
            <div class="card-footer">
                <div class="stats" *ngIf="pageLoaded">
                  <i class="material-icons">access_time</i> 
                  <span *ngIf="harvestDaysDif < 0">Harvested {{harvestDaysDifAbs}} days ago</span>
                  <span *ngIf="harvestDaysDif == 0">Harvested Today</span>
                  <span *ngIf="harvestDaysDif > 0">{{harvestDaysDif}} days until harvest</span>
                  <!-- <i class="material-icons">access_time</i> updated {{recentRedirects.lastUpdate | timeAgo}} -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-danger card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">done</i>
                </div>
                <p class="card-category">End Date</p>
                <h3 class="card-title" *ngIf="pageLoaded">
                  {{ endDate?.seconds * 1000 | date:'MM/dd/yyyy' }}
                </h3>
                <!-- <h3 class="card-title">{{recentRedirects.data}}</h3> -->
            </div>
            <div class="card-footer">
                <div class="stats" *ngIf="pageLoaded">
                  <i class="material-icons">access_time</i> 
                  <span *ngIf="endDateDaysDif < 0">Ended {{endDaysDifAbs}} days ago</span>
                  <span *ngIf="endDateDaysDif == 0">Harvested Today</span>
                  <span *ngIf="endDateDaysDif > 0">{{endDateDaysDif}} days until end</span>
                  <!-- <i class="material-icons">access_time</i> updated {{recentRedirects.lastUpdate | timeAgo}} -->
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <app-timeline [dataList]="timelineData"></app-timeline>
  
  <div class="row" *ngIf="this.hasPendingChanges">
    <div class="col-sm-12" style="min-height: 40px">
      <b>There are pending changes. Click save to confirm changes.</b>
    </div>
    <!-- <app-harvest-save-btn *ngIf="this.hasPendingChanges" (click)="selectedNodes()"></app-harvest-save-btn> -->
  </div>

  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Events">
      <app-event-list [collectionType]="collectionType" [collectionId]="harvestId" [collectionName]="harvest?.name" [company]="company" (eventListChange)="eventListChange($event)"></app-event-list>    
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Detail</span>
      </ng-template>
      <div class="row" [hidden]="!canEditHarvest">
        <div class="col-4">
          <h3 [hidden]="allStrains.length == 0 || harvest.phases == null || harvest.phases.length == 0">Strains</h3>
          <app-strain-select-multiple-for-harvest 
            [hidden]="allStrains.length == 0 || harvest.phases == null || harvest.phases.length == 0"
            [(allStrains)]="allStrains"
            [(strainIds)]="harvest.strainIds"
            [(harvestPhases)]="harvest.phases">
          </app-strain-select-multiple-for-harvest>
        </div>
        <div class="col-8">
    
        </div>
      </div>
      <app-phase-accordion [(harvest)]="harvest" [allStrains]="allStrains" [canEditHarvest]="canEditHarvest"></app-phase-accordion>
    </mat-tab>
  </mat-tab-group>
</ng-container>
