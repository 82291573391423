import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

import * as services from '../../../services';
import * as models from '../../../models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent extends ComponentCanDeactivate implements OnInit {

  public gridOptions: GridOptions;
  private gridApi;
  
  saving: boolean = false;
  adding: boolean = false;
  deleting: boolean = false;

  private createColumnDefs() {
    return [
      {
          headerName: "Name",
          field: "name", sortable: true, resizable: true
      },
    ];
  }

  selectedTeam: models.Team;
  initialTeam: models.Team;

  get dirty(): boolean {
    if(this.selectedTeam == null){
      return false;
    }
    
    let finish = JSON.stringify(this.selectedTeam);
    let start = JSON.stringify(this.initialTeam);
    let updated = finish !== start;

    return updated;
  }

  canDeactivate(): boolean {
    return !this.dirty;
  }

  constructor(
    private teamService: services.TeamService
    , private helperService: services.HelperService
    , private snackBar: MatSnackBar
    , private deviceService: DeviceDetectorService
  ) { 
    super(); // call to default constructor added implicitly  
    this.gridOptions = <GridOptions>{
        context: {
            componentParent: this
        },
        //rowData: [],
        columnDefs: this.createColumnDefs(),
        rowHeight: 35,
        onGridReady: () => {
          if(!this.deviceService.isMobile()){
            this.gridOptions.api.sizeColumnsToFit();
          }
          this.gridApi = this.gridOptions.api;
        },
        frameworkComponents: {
        },
        rowSelection: 'single',
        //selectionChanged: this.onSelectionChanged
    };
  }

  ngOnInit(): void {
    this.getTeams();
  }

  async getTeams() {
    this.adding = false;
    this.teamService.getAll().snapshotChanges().subscribe(snapshot => {
      let teams = [];
      snapshot.forEach(element => {
        let team: models.Team = element.payload.doc.data();
        team.uid = element.payload.doc.id;
        teams.push(team);
      });

      this.gridOptions.api.setRowData(this.helperService.sortArrayByStringField(teams, 'name'));
    })
  }

  onSelectionChanged() {
    if(this.adding){
      return;
    }
    var selectedRows = this.gridApi.getSelectedRows();
    this.selectedTeam = selectedRows[0];
    this.initialTeam = this.helperService.deepCopy(this.selectedTeam);
  }

  addTeam() {
    this.adding = true;
    let team: models.Team = { };
    this.initialTeam = this.helperService.deepCopy(team);
    team.name = 'New Team';
    this.gridApi.deselectAll();
    this.selectedTeam = team;
  }

  async save() {
    this.saving = true;
    if(this.adding){
      await this.teamService.add(this.selectedTeam);
    } else {
      await this.teamService.update(this.selectedTeam)
    }
    this.gridOptions.api.setRowData([]);
    this.selectedTeam = null;
    this.getTeams();
    this.snackBar.open(`Successfully ${this.adding ? 'added' : 'updated'} Team`);
    this.saving = false;
  }

  undo() {
    this.gridOptions.api.setRowData([]);
    this.selectedTeam = null;
    this.getTeams();
  }

  deleteClick() {
    let r = confirm('Are you sure you want to delete this Team?  This will permanently delete it and all of its data.')
    if(r){
      this.deleteTeam();
    }
  }

  async deleteTeam() {
    if(this.adding){
      this.gridOptions.api.setRowData([]);
      this.selectedTeam = null;
      this.getTeams();
      return;
    }

    this.deleting = true;
    await this.teamService.delete(this.selectedTeam.uid);
    this.gridOptions.api.setRowData([]);
    this.selectedTeam = null;
    this.getTeams();
    this.snackBar.open('Successfully deleted Team');
    this.deleting = false;
  }

}
