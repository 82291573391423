
<h2 mat-dialog-title>
    <span style="display: inline-block;">{{ header }}</span>
    <button style="float: right;" mat-icon-button (click)="add()"><mat-icon>add</mat-icon></button>
</h2>
<mat-dialog-content>
    <form id="note-list" (submit)="save()" (keyup.enter)="save()" (keydown)="keyDownFunction($event)">
      <div class="row" *ngFor="let n of notes; let i = index">
          <div class="col-12">
              <mat-form-field style="display: inline-block;">
                <input [id]="'note_'+i" matInput placeholder="{{ placeholder }}" type="text" [ngModel]="notes[i]" (change)="onValueUpdate($event, i)" [ngModelOptions]="{standalone: true}">
                <button type="button" matSuffix mat-icon-button (click)="remove(i)"><mat-icon>delete</mat-icon></button>
              </mat-form-field>
          </div>
      </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  
  <button mat-button type="submit" form="note-list">
    <span>Save</span>
  </button>
</mat-dialog-actions>
