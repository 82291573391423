import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage'

import * as models from 'app/models';
import * as services from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanySelectComponent implements OnInit {

  loading: boolean = true;
  companies: Array<models.Company> = [];

  constructor(
    private snackBar: MatSnackBar,
    private claimsService: services.ClaimsService,
    private companyService: services.CompanyService,
    private sessionStorage: SessionStorageService,
    private roleService: services.RoleService
    , private zone: NgZone
    , private router: Router
    , private titleService: Title
    , private helperService: services.HelperService
    , private auth: Auth
  ) { }

  async ngOnInit() {
    this.titleService.setTitle('Company Select - PlanaCan')
    //await this.getRoles();
    this.monitorAuthStatus();

    // if(userRole.precedence !== 0) {
    //   window.location.href = '/no-permissions';
    //   return;
    // }

    // this.getCompanies();
  }

  monitorAuthStatus() {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        this.getCompanies();
      } else {
        this.claimsService.clearClaims();
        window.location.href = '/';
      }
    });
  }

  async getCompanies() {
    let companyResponse = await this.companyService.getAllCompaniesForUser();

    if(!companyResponse.success){
      this.snackBar.open(companyResponse.message);
      this.loading = false;
      return;
    }

    this.companies = companyResponse.value;

    if(this.companies.length !== 0){
      this.companies[0].selected = true;
    }

    this.loading = false;
  }

  companyClick(company: models.Company) {
    this.companies.forEach(company => {
      company.selected = false;
    });

    company.selected = true;
  }

  okClick(){
    let selectedCompany: models.Company = this.companies.find(i => i.selected === true);

    //get company id in storage
    this.helperService.currentCompanyId = selectedCompany.uid;;

    //redirect
    this.zone.run(() => this.router.navigateByUrl('/console/calendar'));
    //window.location.href = '/console/calendar';
    
  }
}
