import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { HelperService } from './helper.service';
import { AppStorage, ClaimsService } from '.';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class ProcessService {

    companyRef: AngularFirestoreCollection;

    constructor(
        private firestore: AngularFirestore
        , private helperService: HelperService
        , private claimsService: ClaimsService
        , private functions: Functions
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    getByUser(){
        const companyId = this.helperService.currentCompanyId;
        const userId = this.claimsService.currentUserId();

        const processRef = this.companyRef.doc(companyId).collection('processes');
        return processRef.ref.where('userId', '==', userId)
    }

    async revertBackup(processId: string) {
        
        const inObj = {
            companyId: this.helperService.currentCompanyId,
            processId: processId,
        }

        let method = httpsCallable(this.functions, 'revertBackup')
        let response = await method(inObj);

        return response;
    }
}
