import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppStorage } from 'app/services';
import { HarvestGroupDeleteComponent, UsersAssignComponent } from 'app/dialogs';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-calendar-more-menu-btn',
  templateUrl: './calendar-more-menu-btn.component.html',
  styleUrls: ['./calendar-more-menu-btn.component.scss']
})
export class CalendarMoreMenuBtnComponent implements OnInit {

  @Output() addedHarvests = new EventEmitter();
  @Output() deletedHarvestsAndGroups = new EventEmitter();
  
  get canSeeMassUserAssign(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.assignUsers)) {
      return true;
    } else {
      return false;
    }
  }

  get canSeeHarvestGroupDelete(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteHarvest) || 
        this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteGroup)) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private claimsService: services.ClaimsService
    , private helperService: services.HelperService
    , private harvestService: services.HarvestService
    , private appStorage: AppStorage
    , private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  usersMassAssign() {
    this.dialog.open(UsersAssignComponent);
  }

  harvestGroupDelete() {
    let dialogRef = this.dialog.open(HarvestGroupDeleteComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletedHarvestsAndGroups.emit(result);
      }
    })
  }

  addedHarvestsFunc(dialogResult: any) {
    this.addedHarvests.emit(dialogResult);
  }
}
