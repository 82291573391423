import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    Component,
    ElementRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'editor-cell',
    template: `
    <div class="cont">
        <div style="padding-bottom: 5px">
            <div style="color: lightgrey; text-align: right">
                <mat-icon style="vertical-align: middle;">keyboard_return</mat-icon> <span>to close</span>
            </div>
        </div>
        <mat-checkbox [(ngModel)]="anyTime">Anytime</mat-checkbox>
        <mat-form-field *ngIf="!anyTime">
            <input matInput type="time" [(ngModel)]="startTime"  placeholder="Start Time">
        </mat-form-field>
    </div>
    `,
    styles: [
        `
      .cont{
        padding:20px;
      }
      `
    ],
})
export class TimeEditorCell implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    anyTime: boolean = false;
    startTime: string;

    isPopup(): boolean {
        return true;
    }

    ngAfterViewInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.anyTime = this.params.data.anyTime;
        this.startTime = this.params.data.startTime;
    }

    getValue(): any {
        this.params.data.anyTime = this.anyTime;
        this.params.data.startTime = this.startTime;

        return this.anyTime;
        // return {
        //     anyTime: this.anyTime,
        //     startTime: this.startTime
        // };
    }
}
