
export interface CompanyViewModel {
    uid?: string;
    name?: string;

    // lastActiveUser?: string;
    // lastActiveDate?: Date;

    // harvests?: number;
    // templates?: number;
    // users?: number;
    created?: any;

    freeVersion?: boolean;
    paymentProviderCustomerId?: string;
    enableSensors?: boolean;
    eventsSplit?: boolean;
}