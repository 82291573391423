import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage'
import { DeviceDetectorService } from 'ngx-device-detector';

import { ColDef, CsvExportParams, GridOptions } from "ag-grid-community";
import {
  MatCheckboxComponent,
  MatInputComponent,
  MatRadioComponent,
  MatSelectComponent,
  MatIconComponent,
  RoleComponent,
  UpdateRoleBtnComponent,
  UsernameAndImageComponent
} from "../../cell-components";

import * as models from '../../../models';
import * as services from '../../../services'
import * as cells from 'app/components/cell-components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { InviteToOtherCompanyDialogComponent, RestrictionNotificationComponent, UserAddComponent, UserEditComponent } from 'app/dialogs';
import { AppStorage } from '../../../services';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {
  public gridOptions: GridOptions;

  companySub: Subscription;
  role: models.Role;

  disableUsersClick: boolean = false;
  enableUsersClick: boolean = false;
  deleteUsersClick: boolean = false;
  sendResetPasswordLinkClick: boolean = false;

  companies: models.Company[] = [];

  get canSeeInviteToOtherCompanies() {
    return this.companies.length > 0;
  }

  get canAddUsers() {
    return this.role?.permissions?.includes(models.Permissions.addUsers);
  }

  get canEditUsers() {
    return this.role?.permissions?.includes(models.Permissions.editUsers);
  }

  get canDeleteUsers() {
    return this.role?.permissions?.includes(models.Permissions.deleteUsers);
  }

  get canEnableDisableUsers() {
    return this.role?.permissions?.includes(models.Permissions.enableDisableUsers);
  }

  users: Array<models.User> = [];
  invitedUsers: Array<models.User> = [];

  selectedRows: Array<UserListItem> = [];
  listUsers: UserListItem[] = null;

  public columnDefs: ColDef[] = [

    {
      headerName: "Display Name",
      field: "user.displayName", sortable: true, resizable: true,
      cellRendererFramework: cells.UsernameAndImageComponent
    },
    {
      headerName: "Email Address",
      field: "user.emailAddress", sortable: true, resizable: true
    },
    // {
    //     headerName: "Phone",
    //     field: "phoneNumber", sortable: true, resizable: true
    // },
    {
      headerName: "Enabled",
      field: "user.disabled",
      cellRenderer: "iconRenderer",
      sortable: true, resizable: true
    },
    {
      headerName: "Role",
      field: "user.roleId",
      cellRendererFramework: cells.RoleComponent,
      sortable: false, resizable: true
    },
    {
      headerName: "Update Role",
      field: "user.emailAddress",
      cellRendererFramework: cells.UpdateRoleBtnComponent,
      sortable: false, resizable: true
    }

  ]

  constructor(
    private userService: services.UserService,
    private authService: services.AuthService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
    , private deviceService: DeviceDetectorService
    , private helperService: services.HelperService
    , private appStorage: AppStorage
    , private notifierService: services.NotifierService
    , private companyService: services.CompanyService
    , private claimsService: services.ClaimsService
  ) {
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      rowData: this.listUsers,
      //columnDefs: this.createColumnDefs(),
      rowHeight: 35,
      onGridReady: () => {
        if (!this.deviceService.isMobile()) {
          this.gridOptions.api.sizeColumnsToFit();
        }
        //get companyId
        let companyId = this.helperService.currentCompanyId;
        //if we have it then load
        if (companyId != null) {
          this.getFromServer();
        }
      },
      frameworkComponents: {
        checkboxRenderer: MatCheckboxComponent,
        inputRenderer: MatInputComponent,
        radioEditor: MatRadioComponent,
        selectEditor: MatSelectComponent,
        iconRenderer: MatIconComponent,
        roleComponent: RoleComponent,
        updateRoleBtn: UpdateRoleBtnComponent,
        usernameAndImageComponent: UsernameAndImageComponent
      },
      rowSelection: 'multiple',
      enableCellTextSelection: true
    };
  }

  async ngOnInit() {
    this.role = await this.claimsService.getRoleAwait();
    this.getCompanies();
    this.updateColumns();
  }

  ngAfterViewInit(): void {
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.getFromServer();
    })
  }

  ngOnDestroy() {
    if (this.companySub != null) {
      this.companySub.unsubscribe();
    }
  }

  updateColumns() {
    //let colDefs = this.createColumnDefs();
    if (!this.role.permissions.includes(models.Permissions.editUserRoles)) {
      this.columnDefs = this.columnDefs.filter(i => i.headerName != 'Update Role');
    }

    this.gridOptions.columnDefs = this.columnDefs;
  }

  async getCompanies() {
    let companyId = this.helperService.currentCompanyId;
    let companyResponse = await this.companyService.getAllCompaniesForUser();
    if (companyResponse.success) {
      this.companies = companyResponse.value.filter(i => i.uid != companyId);
    }
  }

  objectsAreSame(x, y): boolean {
    let objectsAreSame: boolean = true;
    for (var propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }

  onSelectionChanged() {
    this.selectedRows = this.gridOptions.api.getSelectedRows();
  }

  editUser() {
    let firstSelectedRow: UserListItem = this.gridOptions.api.getSelectedRows()[0];
    if (firstSelectedRow.invited) {
      this.notifierService.error('Cannot edit an invited user.  User must accept invitation.');
      return;
    }

    let dialogRef = this.dialog.open(UserEditComponent, {
      data: firstSelectedRow.user
    });

    dialogRef.afterClosed().subscribe(async result => {
      // if(result && result.submitted){
      //   this.event.assignedUserIds = result.data;
      // }
      this.getFromServer();
    });
  }

  disableSelectedRows() {
    let userListItems: UserListItem[] = this.gridOptions.api.getSelectedRows();
    let users = userListItems.filter(i => !i.invited).map(i => i.user);
    if (users.length == 0) {
      this.notifierService.error('Users selected are invited.  Cannot disable invited users.');
      return;
    }

    this.disableUsersClick = true;

    this.authService.disableUsers(users).then(response => {
      if (response.data.success) {
        this.notifierService.success(response.data.message)
        this.getFromServer();
      } else {
        this.snackBar.open("Error! " + response.data.message, null, {
          duration: 3000,
          verticalPosition: 'top'
        });
      }

      this.disableUsersClick = false;
    }).catch(error => {
      this.snackBar.open("Error!", null, {
        duration: 3000,
        verticalPosition: 'top'
      });

      this.disableUsersClick = false;
    })
  }

  enableSelectedRows() {
    let userListItems: UserListItem[] = this.gridOptions.api.getSelectedRows();
    let users = userListItems.filter(i => !i.invited).map(i => i.user);
    if (users.length == 0) {
      this.notifierService.error('Users selected are invited.  Cannot enable invited users.');
      return;
    }

    this.enableUsersClick = true;

    this.authService.enableUsers(users).then(response => {
      if (response.data.success) {
        this.notifierService.success(response.data.message)
        this.getFromServer();
      } else {
        this.snackBar.open("Error! " + response.data.message, null, {
          duration: 3000,
          verticalPosition: 'top'
        });
      }

      this.enableUsersClick = false;
    }).catch(error => {
      this.snackBar.open("Error!", null, {
        duration: 3000,
        verticalPosition: 'top'
      });

      this.enableUsersClick = false;
    })
  }

  deleteSelectedRowsClick() {
    this.notifierService.confirm('Delete Users', 'Are you sure you want to delete the selected Users?',
      () => { this.deleteSelectedRows() },
      () => { })
  }

  deleteSelectedRows() {
    let userListItems: Array<UserListItem> = this.gridOptions.api.getSelectedRows();
    let users = userListItems.filter(i => !i.invited).map(i => i.user);
    let userIds = users.map(i => i.id);

    if (users.length == 0) {
      this.notifierService.error('Users selected are invited.  Cannot delete invited users.');
      return;
    }

    this.deleteUsersClick = true;
    let companyId = this.helperService.currentCompanyId;

    let request = {
      userIds: userIds,
      companyId: companyId
    }

    this.authService.deleteUsers(request).then(response => {
      if (response.data.success) {
        this.notifierService.success(response.data.message)
        this.getFromServer();
      } else {
        this.snackBar.open("Error! " + response.data.message, null, {
          duration: 3000,
          verticalPosition: 'top'
        });
      }

      this.deleteUsersClick = false;
    }).catch(error => {
      this.snackBar.open("Error!", null, {
        duration: 3000,
        verticalPosition: 'top'
      });

      this.deleteUsersClick = false;
    })
  }

  inviteToOtherCompany() {
    let firstSelectedRow: UserListItem = this.gridOptions.api.getSelectedRows()[0];
    if (firstSelectedRow.invited) {
      this.notifierService.error('Cannot edit an invited user.  User must accept invitation.');
      return;
    }

    let dialogRef = this.dialog.open(InviteToOtherCompanyDialogComponent, {
      data: {
        user: firstSelectedRow.user,
        companyId: this.helperService.currentCompanyId,
        companies: this.companies
      }
    });

    // dialogRef.afterClosed().subscribe(async result => {
    //   this.getFromServer();
    // });
  }

  sendResetPasswordLink() {
    this.sendResetPasswordLinkClick = true;

    let userListItems: Array<UserListItem> = this.gridOptions.api.getSelectedRows();
    let users = userListItems.map(i => i.user);

    users.forEach(user => {
      this.authService.sendPasswordResetLink(user.emailAddress).then(data => {
        this.notifierService.success('Emails have been sent to users selected')
        console.log('Email Sent');

        this.sendResetPasswordLinkClick = false;
      }).catch(error => {
        this.snackBar.open(error, null, {
          duration: 3000,
          verticalPosition: 'top'
        });

        this.sendResetPasswordLinkClick = false;
      })
    });
  }

  async getFromServer() {
    //set grid to show that it is loading
    this.listUsers = null;

    //find the company and go get the users for that company
    let companyId = this.helperService.currentCompanyId;

    let call1 = this.userService.getUsersFoCompany(companyId);
    let call2 = this.userService.getInvitedUsers(companyId);

    this.gridOptions.api.showLoadingOverlay();
    Promise.all([call1, call2]).then((values) => {
      let userResponse = values[0];
      let invitedUsers = values[1];
      let listUsers: UserListItem[] = [];

      if (!userResponse.data.success) {
        this.notifierService.error(userResponse.data.message);
      } else {
        //put all users returned from the server into the list
        let users: models.User[] = []
        userResponse.data.value.forEach(element => {
          let user: models.User = element;
          user.id = element.id;
          users.push(user);
        });

        this.users = users;
        users.forEach(user => {
          let listUser: UserListItem = {
            user: user,
            invited: false
          }
          listUsers.push(listUser);
        });
      }

      this.invitedUsers = invitedUsers;
      invitedUsers.forEach(user => {
        if (listUsers.find(i => i.user.id == user.id) != null) {
          return;
        }
        let listUser: UserListItem = {
          user: user,
          invited: true
        }
        listUsers.push(listUser);
      });

      this.listUsers = listUsers;
    });
  }

  addUser() {
    if (this.helperService.company_freeVersion && this.users.length >= this.appStorage.freeUsers) {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'userAdd'
      });

      return;
    }

    let dialogRef = this.dialog.open(UserAddComponent, {
      panelClass: 'med-width-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      this.getFromServer();
      console.log(JSON.stringify(result));
    })
  }

  export() {
    let params: CsvExportParams = {
      fileName: `UserList_Export`,
      columnKeys: ['user.displayName', 'user.emailAddress', 'user.roleId']
    };
    params.processCellCallback = (params) => {
      if (params.value instanceof Timestamp) {
        return this.helperService.timestampToDateString(params.value);
      } else {
        return params.value;
      }
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }
}

export interface UserListItem {
  user: models.User;
  invited: boolean;
}