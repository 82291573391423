<h2 id="template_add_title" mat-dialog-title>Select Attachments</h2>
<mat-dialog-content style="min-width: 50vw">
    <div class="row">
        <div class="col-12">
            <button style="float: right" mat-button (click)="upload()">Upload</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ag-grid-angular 
                style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="gridList" >
            </ag-grid-angular>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="ok()">OK</button>
</mat-dialog-actions>
