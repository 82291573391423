import { Timestamp } from "@angular/fire/firestore";

export interface TaskGroupTableViewModel {
    id?: string;
    name?: string;
    color?: string;
    startDate?: Timestamp;
    endDate?: Timestamp;
    completedTasks?: number;
    totalTasks?: number;
}