import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewModels from 'app/viewmodels';

@Component({
  selector: 'app-zone-select',
  templateUrl: './zone-select.component.html',
  styleUrls: ['./zone-select.component.scss']
})
export class ZoneSelectComponent implements OnInit {

  @Input() disabled: boolean = false;

  zoneTitles: Array<string> = [];
  allZones: Array<models.Zone> = [];
  zoneSelections: Array<viewModels.ZoneSelection> = [];
  
  // @Input()
  // get zone() {
  //   return this.zones.find(i => i.id == this.zoneId);
  // }
  // @Output() zoneChange = new EventEmitter();
  // set zone(val) {
  //   if(val == null){
  //     return;
  //   }
  //   this.zoneId = val.id;
  //   this.zoneChange.emit(val);
  // }

  zoneIDValue: string;
  @Input()
  get zoneId() {
    return this.zoneIDValue;
  }
  @Output() zoneIdChange = new EventEmitter();
  set zoneId(val) {
    this.zoneIDValue = val;
    this.zoneIdChange.emit(this.zoneIDValue);
    //this.zoneChange.emit(this.zone);
  }

  constructor(
    private zoneService: services.ZoneService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getZones();
  }

  async getZones() {
    let dbZone = await this.zoneService.getDbZone();

    this.zoneTitles = this.helperService.deepCopy(dbZone.zoneTitles);
    this.allZones = this.helperService.deepCopy(dbZone.list);
      
    // //this.zones = this.helperService.sortArrayByStringField(zones, 'name');
    // this.zones = this.compileZones([], zones, 0);
    // this.filteredZones = this.zones.slice();

    // if(this.zones.length != 0 && this.zoneId == null) {
    //   //this.zoneChange(this.zones[0]);
    //   this.zoneId = this.zones[0].id;
    // }

    //this.zoneChange.emit(this.zone);

    this.setZoneSelections();
  }

  setZoneSelections() {
    let zones = this.allZones;

    let zoneSelection = this.getDefaultZoneSelection(zones);
    this.zoneSelections.push(zoneSelection);

    //compile parent ids
    //let parentIds = this.findInList(this.allZones, this.zone.id);
    let parentIds = this.zoneService.getListOfParents(this.allZones, this.zoneId);
    let ids = [...parentIds, this.zoneId]
    for (let i = 0; i < ids.length; i++) {
      const parentId = ids[i];
      
      if(this.zoneSelections[i] != null) {
        let selectedZone = this.zoneSelections[i].zones.find(i => i.id == parentId);
        if(selectedZone != null){
          this.zoneSelections[i].selectedZone = selectedZone;
          if(selectedZone.zones == null || selectedZone.zones.length == 0){
            continue;
          }
          let newZoneSelection = this.getDefaultZoneSelection(selectedZone.zones);

          // let nextParentIds = ids[i+1];
          // if(nextParentIds != null){
          //   let nextSelected = newZoneSelection.zones.find(i => i.id == nextParentIds);
          //   newZoneSelection.selectedZone = nextSelected;
          // }
          this.zoneSelections.push(newZoneSelection);
        }
      } else {
        let previousZone = this.zoneSelections[i-1].selectedZone;
        if(previousZone.zones == null || previousZone.zones.length == 0){
          continue;
        }
        let newZoneSelection = this.getDefaultZoneSelection(previousZone.zones);

        // let nextParentIds = ids[i+1];
        // if(nextParentIds != null){
        //   let nextSelected = newZoneSelection.zones.find(i => i.id == nextParentIds);
        //   newZoneSelection.selectedZone = nextSelected;
        // }
        this.zoneSelections.push(newZoneSelection);
      }
    }
  }

  async change(zoneSelection: viewModels.ZoneSelection, index: number, event: any) {
    //let zone = zoneSelection.zones.find(i => i.id == event.value);
    let zone = event.value

    //if(zone == null || zone.id == null){
    //  this.zoneSelections.length = index+1;
    //}

    if(index+1 < this.zoneSelections.length){
      this.zoneSelections = this.zoneSelections.splice(0, index+1);
    }

    
    //await this.helperService.wait(1000);
    this.addZoneSelection(zone);
    
    // await this.helperService.wait(10);
    // zoneSelection.selectedZone = null;
    // await this.helperService.wait(10);
    // zoneSelection.selectedZone = zone;

    
    // this.displayToggle = false;
    // await this.helperService.wait(50);
    // this.displayToggle = true;

    let zoneSelections = this.zoneSelections
      .filter(i => i.selectedZone?.id != null);

    this.zoneId = zoneSelections[zoneSelections.length-1]?.selectedZone?.id;
  }

  addZoneSelection(zone: models.Zone){
    let zones = zone.zones;

    if(zones == null || zones.length == 0){
      return;
    }

    let zoneSelection = this.getDefaultZoneSelection(zones);

    this.zoneSelections.push(zoneSelection);
  }

  getDefaultZoneSelection(zones: Array<models.Zone>) {
    let nullZone = zones.find(i => i.id == null);
    if(nullZone == null){
      nullZone = {
        id: null,
        name: '<Not Selected>'
      }
      zones.unshift(nullZone)
    }

    let newZoneSelection: viewModels.ZoneSelection = {
      zones: zones,
      selectedZone: nullZone
    }

    return newZoneSelection;
  }

  // compileZones(flatZones: Array<ZoneSelectViewModel>, zones: Array<models.Zone>, indent: number) : Array<models.Zone> {

  //   zones.forEach(zone => {
  //     let listVM: ZoneSelectViewModel = {
  //       id: zone.id,
  //       name: zone.name,
  //       indent: indent
  //     }
  //     flatZones.push(listVM);
  //     if(zone.zones != null && zone.zones.length > 0){
  //       this.compileZones(flatZones, zone.zones, indent+1);
  //     }
  //   });

  //   return flatZones;
  // }
}

interface ZoneSelectViewModel {
  id?: string;
  name?: string;
  indent?: number;
}