import { Component, OnInit } from '@angular/core';

import * as models from '../../models';
import * as services from '../../services';

@Component({
  selector: 'app-system-configs',
  templateUrl: './system-configs.component.html',
  styleUrls: ['./system-configs.component.scss']
})
export class SystemConfigsComponent implements OnInit {

  settings: models.SystemSettings = {};
  initialSettings: models.SystemSettings = {};

  get dirty() : boolean {
    let x = JSON.stringify(this.settings);
    let y = JSON.stringify(this.initialSettings);
    return x !== y;
  }

  constructor(
    private systemSettingsService: services.SystemSettingsService
    , private notifierService: services.NotifierService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getSettings();
  }

  async getSettings() {
    let settings = await this.systemSettingsService.get();
    if(settings == null){
      this.settings = {
        notificationsSendEmail: false,
        notificationsSendPush: true
      }
    } else {
      this.settings = settings;
    }
    this.initialSettings = this.helperService.deepCopy(this.settings);
  }

  async saveSettings() {
    await this.systemSettingsService.save(this.settings);
    this.notifierService.success('Successfully saved System Settings')
    this.getSettings();
  }

}
