import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-embeded-video-dialog',
  templateUrl: './embeded-video-dialog.component.html',
  styleUrls: ['./embeded-video-dialog.component.scss']
})
export class EmbededVideoDialogComponent implements OnInit {

  title: string;
  url: string;

  constructor(
    private dialogRef: MatDialogRef<EmbededVideoDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data) {
      this.title = data.title;
      this.url = data.url;
    }
  }

  ngOnInit(): void {
  }

}
