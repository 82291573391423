import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
var linkifyHtml = require('linkifyjs/html');

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewModels from 'app/viewmodels';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit, OnChanges {

  @Input() discussions: Array<models.Discussion>;
  @Output() deleteMessage = new EventEmitter();
  //@Output() sendMessage = new EventEmitter();

  @Input() disabled: boolean = false;

  @Input() assignToGroup: boolean = false;
  @Input() parentId: string;
  @Input() eventId: string;

  discussionViewModels: Array<viewModels.DiscussionViewModel> = [];
  users: Array<models.User> = [];

  loading: boolean = true;

  newMessage: string = '';

  notesValue: string[] = [];
  @Input()
  get notes() {
    return this.notesValue;
  }
  @Output() notesChange = new EventEmitter();
  set notes(val) {
    this.notesValue = val;
    this.notesChange.emit(this.notesValue);
  }

  sendingValue: boolean = false;
  @Input()
  get sending() {
    return this.sendingValue;
  }
  @Output() sendingChange = new EventEmitter();
  set sending(val) {
    this.sendingValue = val;
    this.sendingChange.emit(this.sendingValue);
  }

  constructor(
    private helperService: services.HelperService
    , private userService: services.UserService
    , private notifierService: services.NotifierService
    , private claimsService: services.ClaimsService
    , private eventService: services.EventService
  ) { }

  async ngOnInit(): Promise<void> {
    if(this.discussions == null) {
      this.discussions = [];
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.discussions){
      await this.getUsers();
      this.organizeMessages();
    }
  }

  /**
   * get users for the company from the database
   */
  async getUsers() {
    if(this.users.length > 0){
      return;
    }
    let companyId = this.helperService.currentCompanyId;
    let userResponse = await this.userService.getUsersFoCompany(companyId, false)

    if(!userResponse.data.success){
      this.notifierService.error('Error getting Users');
      return;
    }

    userResponse.data.value.forEach(element => {
      let user: models.User = element;
      user.id = element.id;
      this.users.push(user);
    });
  }

  organizeMessages() {
    this.discussionViewModels = [];
    let discussions = this.discussions.filter(i => i.discussionType == models.DiscussionType.Note || i.discussionType == models.DiscussionType.InitialNote);

    let currentUserId = this.claimsService.currentUserId();

    discussions.forEach(dis => {

      let disVM: viewModels.DiscussionViewModel = {
        timestamp: dis.timestamp
      };

      if(dis.timestamp != null){
        disVM.formattedDate = this.helperService.timestampToDateTimeString(dis.timestamp)
      }

      let user = this.users.find(i => i.id == dis.userId);

      disVM.userName = user != null ? user.displayName : 'Unknown';
      disVM.currentUser = currentUserId == dis.userId;

      switch(dis.discussionType){
        case models.DiscussionType.InitialNote : {
          disVM.icon = 'note';
          let linkifyString = linkifyHtml(dis.content, {
            defaultProtocol: 'https'
          });
          disVM.userName = 'Template Admin';
          disVM.description = `${linkifyString}`;
          disVM.timestamp = null;
          break;
        }
        case models.DiscussionType.Note : {
          disVM.icon = 'note';
          let linkifyString = linkifyHtml(dis.content, {
            defaultProtocol: 'https'
          });
          disVM.description = `${linkifyString}`;
          break;
        }
        default : {
          return;
        }
      }

      this.discussionViewModels.push(disVM);
    });

    this.loading = false;
  }

  submit(pushToParentComp: boolean = true) {
    if(this.newMessage == '') {
      return;
    }

    let user = this.users.find(i => i.id == this.claimsService.currentUserId());
    let userName = user != null ? user.displayName : 'Unknown';
    let linkifyString = linkifyHtml(this.newMessage, {
      defaultProtocol: 'https'
    });
    
    let n: viewModels.DiscussionViewModel = {
      timestamp: Timestamp.now(),
      formattedDate: this.helperService.timestampToDateTimeString(Timestamp.now()),
      icon: 'note',
      userName: userName,
      description: `${linkifyString}`
    }
    this.discussionViewModels.push(n);

    //this will push the note up to the parent component to save there
    if(pushToParentComp) {
      this.notes.push(this.newMessage);
    } else { //if we are saving here, then just put the discussion item in the list in preparation to save the event
      let newDis: models.Discussion = {
        discussionType: models.DiscussionType.Note,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: this.newMessage,
      }
  
      this.discussions.push(newDis);
    }

    this.newMessage = '';
  }

  async send() {
    if(this.newMessage == '') {
      this.notifierService.error('Must submit a message to send')
      return;
    }
    this.sending = true;
    this.submit(false);
    //this.sendMessage.emit(true);

    await this.eventService.saveDiscussions(this.assignToGroup, this.parentId, this.eventId, this.discussions);

    this.sending = false;
  }

}
