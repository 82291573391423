import { Component, OnInit } from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import { NotifierService } from 'app/services';

@Component({
  selector: 'app-documentation-redirect-dialog',
  templateUrl: './documentation-redirect-dialog.component.html',
  styleUrls: ['./documentation-redirect-dialog.component.scss']
})
export class DocumentationRedirectDialogComponent implements OnInit {

  pass: string = 'FAQ22.10.01';

  constructor(
    private clipboard: Clipboard
    , private notifierService: NotifierService
  ) { }

  ngOnInit(): void {
  }

  copy() {
    this.clipboard.copy(this.pass)
    this.notifierService.success('Password copied to clipboard.');
  }

  go() {
    this.clipboard.copy(this.pass)
    window.open(
        'https://planacan.io/documentation/',
        '_blank'
    );
  }
}
