import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    Component,
    ElementRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'estimated-time-editor-cell',
    template: `
    <div class="cont">
        <div style="padding-bottom: 5px">
            <div style="color: lightgrey; text-align: right">
                <mat-icon style="vertical-align: middle;">keyboard_return</mat-icon> <span>to close</span>
            </div>
        </div>
        <mat-checkbox [(ngModel)]="required">Time Spent Required</mat-checkbox>
        <mat-form-field>
            <input matInput type="number" [(ngModel)]="estimatedTime"  placeholder="Estimated Time (Min)">
        </mat-form-field>
    </div>
    `,
    styles: [
        `
      .cont{
        padding:20px;
      }
      `
    ],
})
export class EstimatedTimeEditorCell implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    required: boolean = false;
    estimatedTime: string;

    isPopup(): boolean {
        return true;
    }

    ngAfterViewInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.required = this.params.data.timeSpentRequired;
        this.estimatedTime = this.params.data.estimatedTime;
    }

    getValue(): any {
        if(this.required != null){
            this.params.data.timeSpentRequired = this.required;
        }
        if(this.estimatedTime != null){
            this.params.data.estimatedTime = this.estimatedTime;
        }

        return this.estimatedTime;
    }
}
