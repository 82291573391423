import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ROUTES, SBROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

import * as services from '../../../services';
import * as models from 'app/models';
import { Role, User } from 'app/models';
import { MatDialog } from '@angular/material/dialog';
import { DocumentationRedirectDialogComponent, HelpComponent } from 'app/dialogs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    user: User;
    userRole: Role;
    //companyIds: Array<string> = [];

    get canSeeSystemManagement() {
        return this.userRole?.permissions?.includes(models.Permissions.adminOnly);
    }

    get showReleaseNotification(): boolean {
        let releaseDate: Date = new Date(this.appStorage.releaseDate.getTime());
        releaseDate.setDate(releaseDate.getDate() + 7) 
        return new Date() <= releaseDate;
    }
    version: string;

    constructor(
        location: Location
        , private element: ElementRef
        , private router: Router
        , private helperService: services.HelperService
        , private authService: services.AuthService
        , private claimsService: services.ClaimsService
        , private userService: services.UserService
        , private appStorage: services.AppStorage
        , private tourService: services.TourService
        , private dialog: MatDialog
        , private snackBar: MatSnackBar) {
        this.location = location;
        this.sidebarVisible = false;
    }

    async ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });

        this.userRole = await this.claimsService.getRoleAwait();

        // fetch whole user
        let userId = this.claimsService.currentUserId();
        this.user = await this.userService.getUserById(userId);
        
        console.log(JSON.stringify(this.userRole));

        //this.companyIds = await this.helperService.getCompanyIds();
        // this.startTourVisible = this.appStorage.skippedTour;
        // this.appStorage.getSkippedTour().subscribe(data => {
        //     this.startTourVisible = data;
        // });
        this.version = this.appStorage.releaseVersion;
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        SBROUTES.filter(listTitle => listTitle).forEach(element => {
            this.listTitles.push(element);
        });

        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(2);
        }
        if (titlee.includes('?')) {
            titlee = titlee.split('?')[0]
        }
        //titlee = titlee.split('/').pop();

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return '';
    }

    get canSelectOtherCompany() {
        // if((this.userRole != null && this.userRole.precedence === 0) || this.companyIds.length > 1){
        //     return true;
        // } else {
        //     return false;
        // }
        return false;
    }

    get canSeeCompanyManagement() {
      return this.userRole?.permissions?.includes(models.Permissions.viewCompanySettings);
    }

    async signOut() {
        await this.authService.signOut();
    }

    askQuestion() {
        let dialogRef = this.dialog.open(HelpComponent, {
            width: '600px',
        });

        dialogRef.afterClosed().subscribe(result => {
            //reload list
            if (result) {
                this.snackBar.open('We have recieved your question and someone from our team will be getting back to you as soon as we can.');
            }
            console.log(JSON.stringify(result));
        })

    }

    docs() {
        //open dialog
        this.dialog.open(DocumentationRedirectDialogComponent);
    }
}
