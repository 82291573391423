import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AppStorage, ClaimsService, HelperService } from 'app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dutchie-btn',
  templateUrl: './dutchie-btn.component.html',
  styleUrls: ['./dutchie-btn.component.scss']
})
export class DutchieBtnComponent implements OnInit, OnDestroy {

  @Output() addedHarvests: EventEmitter<any> = new EventEmitter();

  canSeeDutchie: boolean = false;
  dutchieProcessing: boolean = false;

  companySub: Subscription;

  get unlinkedDutchieBadge() {
    if(this.appStorage.unlinkedDutchieBatches == null || this.appStorage.unlinkedDutchieBatches == 0) {
      return null;
    }

    if(this.appStorage.unlinkedDutchieBatches >= 10){
      return '9+';
    }

    return this.appStorage.unlinkedDutchieBatches;
  }

  constructor(
    private appStorage: AppStorage
    , private helperService: HelperService
    , private claimsService: ClaimsService
  ) { }

  ngOnInit(): void {
    let userId = this.claimsService.currentUserId();

    this.canSeeDutchie = this.helperService.currentCompany?.leaflogix?.active && this.helperService.currentCompany.leaflogix?.adminUserIds?.includes(userId);

    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.canSeeDutchie = data.leaflogix?.active && data.leaflogix?.adminUserIds?.includes(userId);
    })
  }

  ngOnDestroy(): void {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }
}
