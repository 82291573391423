import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import * as models from '../models';
import { HelperService } from './helper.service';

@Injectable()
export class TeamService {

    companyRef: AngularFirestoreCollection;

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
    ) {
        console.log('Hello TeamService Provider');

        this.companyRef = this.firestore.collection('companies');
    }

    getAll(): AngularFirestoreCollection {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = this.companyRef.doc(companyId).collection('teams');

        return harvestResponse;
    }

    async getAllModels(): Promise<models.Team[]> {
        let companyId: string = this.helperService.currentCompanyId;

        let response = await this.companyRef.doc(companyId).collection('teams').get().toPromise();

        let teams: models.Team[] = [];
        response.forEach(data => {
            let team: models.Team = data.data();
            team.uid = data.id;
            teams.push(team);
          })

        return teams;
    }

    get(teamId: string): AngularFirestoreDocument {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = this.companyRef.doc(companyId).collection('teams')
                                .doc(teamId);

        return harvestResponse;
    }

    add(team: models.Team) {
        let companyId = this.helperService.currentCompanyId;
        return this.firestore.collection('companies').doc(companyId)
                .collection('teams').add(team);
    }

    update(team: models.Team) {
        let uid = team.uid;
        delete team.uid;

        let companyId = this.helperService.currentCompanyId;
        return this.firestore.collection('companies').doc(companyId)
                .collection('teams').doc(uid).set(team);
    }

    async delete(teamId: string){
        let companyId = this.helperService.currentCompanyId;

        await this.firestore.collection('companies').doc(companyId)
                .collection('teams').doc(teamId).delete();
    }
}