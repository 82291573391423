import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as models from '../../../models';
import * as services from '../../../services';

@Component({
  selector: 'app-harvest-select-multiple',
  templateUrl: './harvest-select-multiple.component.html',
  styleUrls: ['./harvest-select-multiple.component.scss']
})
export class HarvestSelectMultipleComponent implements OnInit {

  @Input() displayList: boolean = false;
  @Input() displayListSide: boolean = false;
  @Input() selectByDefault: string = models.SelectDefault.None;
  @Input() initialSelectedIds: string[];
  //@Output() changed = new EventEmitter();

  @Input() harvests: Array<models.Harvest> = [];
  loadingHarvests: boolean = true;

  //protected harvests: models.Harvest[] = [];
  public harvestCtrl: FormControl = new FormControl();
  public harvestFilterCtrl: FormControl = new FormControl();
  public filteredHarvests: ReplaySubject<models.Harvest[]> = new ReplaySubject<models.Harvest[]>(1);
  protected _onDestroy = new Subject<void>();

  protected filterHarvests() {
    if (!this.harvests) {
      return;
    }
    // get the search keyword
    let search = this.harvestFilterCtrl.value;
    if (!search) {
      this.filteredHarvests.next(this.harvests.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the harvests
    let filteredHarvests = this.harvests.filter(harvest => {
      return (harvest.name != null && harvest.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredHarvests.next(filteredHarvests);
  }


  //selectedHarvestsValue: Array<models.Harvest>;
  @Input()
  get selectedHarvests() {
    return this.harvests.filter(i => this.harvestIds.includes(i.uid));
    //return this.selectedHarvestsValue;
  }
  @Output() selectedHarvestsChange = new EventEmitter();
  set selectedHarvests(val) {
    if(val == null){
      val = [];
    }
    this.harvestIds = val.map(i => i.uid);
    //this.selectedHarvestsValue = val;
    //this.setForm();
    this.selectedHarvestsChange.emit(val);
  }

  harvestIDsValue: Array<string>;
  @Input()
  get harvestIds() {
    return this.harvestIDsValue;
  }
  @Output() harvestIdsChange = new EventEmitter();
  set harvestIds(val) {
    if(val == null){
      val = [];
    }
    this.harvestIDsValue = val;
    this.setForm();
    this.harvestIdsChange.emit(this.harvestIDsValue);
    this.selectedHarvestsChange.emit(this.selectedHarvests);
  }

  // selectedHarvestsValue: Array<models.Harvest>;
  
  // @Input()
  // get selectedHarvests() {
  //   return this.selectedHarvestsValue;
  // }
  // @Output() selectedHarvestsChange = new EventEmitter();
  // set selectedHarvests(val) {
  //   if(val == null){
  //     val = [];
  //   }
  //   this.selectedHarvestsValue = val;
  //   //this.setForm();
  //   this.selectedHarvestsChange.emit(this.selectedHarvestsValue);
  // }

  get title(): string {
    if(this.selectedHarvests == null || this.harvests == null){
      return '';
    }
    if(this.selectedHarvests.length == this.harvests.length){
      return 'ALL';
    }
    if(this.selectedHarvests.length >= 1){
      let title = this.selectedHarvests[0].name;
      if(this.selectedHarvests.length >= 2){
        title += ` (+${this.selectedHarvests.length - 1} ${this.selectedHarvests?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }

  get bootstrapClass(): string {
    if(this.displayList && this.displayListSide){
      return 'col-6';
    }
    return 'col-12';
  }

  constructor(
    private harvestService: services.HarvestService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    if(this.harvests.length == 0){
      this.getHarvests();
    } else {
      this.filteredHarvests.next(this.harvests.slice());
      this.loadingHarvests = false;
    }

    // listen for search field value changes
    this.harvestFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHarvests();
      });
  }

  harvestChange(newValue: models.Harvest[]) {
    this.selectedHarvests = newValue;
  }

  selectAllHarvests(select: NgModel, values) {
    select.update.emit(values); 
    this.selectAll();
  }

  selectAll() {
    this.harvestChange(this.harvests);
  }

  selectFiltered() {
    let sub = this.filteredHarvests.subscribe(data => {
      this.harvestChange(data);
      sub.unsubscribe();
    })
  }

  deselectAllHarvests(select: NgModel) {
    select.update.emit([]); 
    this.harvestChange([]);
  }

  async getHarvests() {
    this.loadingHarvests = true;
    (await this.harvestService.getAll()).snapshotChanges().subscribe(snapshot => {
      let harvests = [];
      let data = snapshot
      data.forEach(element => {
        let harvest: models.Harvest = element.payload.doc.data();
        harvest.uid = element.payload.doc.id;
        harvests.push(harvest);
      });

      this.harvests = this.helperService.sortArrayByStringField(harvests, 'name');
      this.initialSelect();
      this.filteredHarvests.next(this.harvests.slice());
      this.setForm();
      this.loadingHarvests = false;
    })
  }

  setForm() {
    this.harvestCtrl.setValue(this.harvests.filter(i => this.harvestIds.includes(i.uid)));
  }

  async setHarvests() {
    this.harvestIds = this.harvestCtrl.value.map(i => i.uid);
  }

  initialSelect() {
    // if(this.selectedHarvests != null){
    //   return;
    // }
    if(this.initialSelectedIds != null){
      let selectedHarvests = this.harvests.filter(i => this.initialSelectedIds.includes(i.uid));
      this.harvestChange(selectedHarvests);
    } else {
      switch(this.selectByDefault) { 
        case models.SelectDefault.NotCompleted: { 
          let notCompletedHarvests = this.harvests.filter(i => i.completed == false);
          this.harvestChange(notCompletedHarvests);
          //statements; 
          break; 
        } 
        case models.SelectDefault.All: { 
          this.harvestChange(this.harvests);
          break; 
        } 
        case models.SelectDefault.None:
        default: { 
          break; 
        } 
     } 
    }
  }

}
