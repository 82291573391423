import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components';
import { MatDialog } from '@angular/material/dialog';
import { FilterSetSaveDialogComponent } from 'app/dialogs';

import * as models from 'app/models';
import * as services from 'app/services';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-filter-set-select-btn',
  templateUrl: './filter-set-select-btn.component.html',
  styleUrls: ['./filter-set-select-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterSetSelectBtnComponent implements OnInit {
  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';

  groupIdsValue: Array<string>;
  @Input() get groupIds() {
    return this.groupIdsValue;
  }
  @Output() groupIdsChange = new EventEmitter();
  set groupIds(val) {
    if(val == null){
      val = [];
    }
    this.groupIdsValue = val;
    this.groupIdsChange.emit(this.groupIdsValue);
  }

  userIdsValue: Array<string>;
  @Input() get userIds() {
    return this.userIdsValue;
  }
  @Output() userIdsChange = new EventEmitter();
  set userIds(val) {
    if(val == null){
      val = [];
    }
    this.userIdsValue = val;
    this.userIdsChange.emit(this.userIdsValue);
  }

  teamIdsValue: Array<string>;
  @Input() get teamIds() {
    return this.teamIdsValue;
  }
  @Output() teamIdsChange = new EventEmitter();
  set teamIds(val) {
    if(val == null){
      val = [];
    }
    this.teamIdsValue = val;
    this.teamIdsChange.emit(this.teamIdsValue);
  }

  phaseIdsValue: Array<string>;
  @Input() get phaseIds() {
    return this.phaseIdsValue;
  }
  @Output() phaseIdsChange = new EventEmitter();
  set phaseIds(val) {
    if(val == null){
      val = [];
    }
    this.phaseIdsValue = val;
    this.phaseIdsChange.emit(this.phaseIdsValue);
  }

  tagsValue: Array<string>;
  @Input() get tags() {
    return this.tagsValue;
  }
  @Output() tagsChange = new EventEmitter();
  set tags(val) {
    if(val == null){
      val = [];
    }
    this.tagsValue = val;
    this.tagsChange.emit(this.tagsValue);
  }

  zoneIdsValue: Array<string>;
  @Input() get zoneIds() {
    return this.zoneIdsValue;
  }
  @Output() zoneIdsChange = new EventEmitter();
  set zoneIds(val) {
    if(val == null){
      val = [];
    }
    this.zoneIdsValue = val;
    this.zoneIdsChange.emit(this.zoneIdsValue);
  }

  completeStatusValue: string;
  @Input() get completeStatus() {
    return this.completeStatusValue;
  }
  @Output() completeStatusChange = new EventEmitter();
  set completeStatus(val) {
    this.completeStatusValue = val;
    this.completeStatusChange.emit(this.completeStatusValue);
  }

  onlyMyTasksValue: boolean;
  @Input() get onlyMyTasks() {
    return this.onlyMyTasksValue;
  }
  @Output() onlyMyTasksChange = new EventEmitter();
  set onlyMyTasks(val) {
    this.onlyMyTasksValue = val;
    this.onlyMyTasksChange.emit(this.onlyMyTasksValue);
  }
  
  @Output() onClosed = new EventEmitter();

  filterSets: models.FilterSet[] = [];
  selectedFilterSets: models.FilterSet[];
  selectedFilterSet: models.FilterSet;
  initialSelectedFilterSet: models.FilterSet;

  get canManipulateFilterSets(): boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.manipulateFilterSets)) {
      return true;
    } else {
      return false;
    }
  }

  truncate(name: string): string {
    if(name.length < 25){
      return name;
    }

    return name.substring(0, 25) + '...';
  }

  constructor(
    private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private filterSetService: services.FilterSetService
    , private claimsService: services.ClaimsService
  ) { }

  ngOnInit(): void {
  }

  popoverOpened(event: any) {
    this.getFilterSets();
    this.initialSelectedFilterSet = this.selectedFilterSet;
  }

  popoverClosed(event: any) {
    //this.selected = this.list.filter(i => i.selected).map(i => i.id);
    if(this.initialSelectedFilterSet?.id != this.selectedFilterSet?.id){
      this.onClosed.emit(event);
    }
  }

  async getFilterSets() {
    if(this.filterSets.length > 0){
      return;
    }
    this.filterSets = await this.filterSetService.getAll();
    // this.filterSets.unshift({
    //   id: null,
    //   name: 'No Filter Set'
    // });
    // if(this.selectedFilterSets == null || this.selectedFilterSets.length == 0) {
    //   this.selectedFilterSets = [this.filterSets[0]];
    // }
  }

  filterSetChange(event){
    this.selectedFilterSet = event[0];

    if(this.selectedFilterSet){
      this.groupIds = this.selectedFilterSet.groupIds;
      this.userIds = this.selectedFilterSet.userIds;
      this.teamIds = this.selectedFilterSet.teamIds;
      this.completeStatus = this.selectedFilterSet.completeStatus;
      this.onlyMyTasks = this.selectedFilterSet.onlyMyTasks;
      this.phaseIds = this.selectedFilterSet.phases;
      this.tags = this.selectedFilterSet.tags;
      this.zoneIds = this.selectedFilterSet.zoneIds;
    } else {
      this.userIds = [];
      this.teamIds = [];
      this.completeStatus = 'both';
      this.onlyMyTasks = false;
      this.phaseIds = null;
      this.tags = null;
      this.zoneIds = null;
    }
    
    this.popover.close();
  }

  addFilterSet() {
    let newFilterSet: models.FilterSet = {
      name: '',
      // harvestIds: this.harvestIds,
      groupIds: this.groupIds,
      userIds: this.userIds,
      teamIds: this.teamIds,
      completeStatus: this.completeStatus,
      onlyMyTasks: this.onlyMyTasks,
      phases: this.phaseIds,
      tags: this.tags,
      zoneIds: this.zoneIds
    }
    this.dialog.open(FilterSetSaveDialogComponent, {data: newFilterSet}).afterClosed().subscribe(async (id: string) => {
      if(id){
        this.filterSets = [];
        await this.getFilterSets();
        this.selectedFilterSet = this.filterSets.find(i => i.id == id);
        this.selectedFilterSets = [this.selectedFilterSet];
      }
    })
  }

  removeFilterSet() {
    let selectedFilterSet: models.FilterSet = this.selectedFilterSet;

    if(selectedFilterSet == null){
      this.notifierService.error('Must select a filter set to remove');
      return;
    }

    this.notifierService.confirm('Delete View', 'Are you sure you want to delete this View?',
    async () => {
      await this.filterSetService.delete(selectedFilterSet.id)
      this.filterSets = this.filterSets.filter(i => i.id != selectedFilterSet.id)
      // this.userIds = [];
      // this.teamIds = [];
      // this.completeStatus = 'both';
      // this.onlyMyTasks = false;
      // this.phaseIds = null;
      // this.tags = null;
      // this.zoneIds = null;
      // this.popoverClosed(true)
      this.default();
    }, () => {})
  }

  default() {
    this.selectedFilterSets = [];
    this.filterSetChange(this.selectedFilterSets);
  }

  clearSelected() {
    this.selectedFilterSets = [];
    this.selectedFilterSet = null;
  }
}
