import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  @Input() saveAsGenericGroup: boolean = false;

  colorValue: string;
  @Input()
  get color() {
    return this.colorValue;
  }
  @Output() colorChange = new EventEmitter();
  set color(val) {
    this.colorValue = val;
    this.colorChange.emit(this.colorValue);
  }

  colors: Array<any> = [
    {class: 'red', value: '#C62424'},
    {class: 'green', value: '#009933'},
    {class: 'army-green', value: '#6f7302'},
    {class: 'lite-blue', value: '#0066ff'},
    {class: 'blue', value: '#0000cc'},
    {class: 'mustard', value: '#B9B91C'},
    {class: 'tuqouis', value: '#168D8D'},
    {class: 'lite-purple', value: '#8114ee'},
    {class: 'purple', value: '#51168D'},
    {class: 'orange', value: '#C77A2D'},
  ]

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
