<h2 id="template_add_title" mat-dialog-title><span *ngIf="!isCopy">Add</span><span *ngIf="isCopy">Copy</span> Role</h2>
<mat-dialog-content>
    <form id="addRole" class="template-form" (ngSubmit)="saveClick()">

        <mat-form-field class="user-full-width">
            <input id="role_add_name" matInput placeholder="New Role Name" type="text" [disabled]="displayCompanySelect"
                required name="name" [(ngModel)]="name">
        </mat-form-field>

        <ng-container *ngIf="displayCompanySelect">
            <p>Migrate new Role to your other Companies</p>

            <mat-form-field>
                <mat-select placeholder="Select Companies" [formControl]="selectedCompanies" multiple>
                    <mat-option disabled="disabled" class="filter-option">
                        <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll()">
                            Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="companyChange([])">
                            Deselect All
                        </button>
                    </mat-option>
                    <mat-select-trigger>
                        <ng-container *ngIf="selectedCompanies.value?.[0] == null">
                            None Selected
                        </ng-container>
                        <ng-container *ngIf="selectedCompanies.value?.[0] != null">
                            {{selectedCompanies.value?.[0].name || ''}}
                            <span *ngIf="(selectedCompanies.value?.length || 0) > 1" class="example-additional-selection">
                                (+{{(selectedCompanies.value?.length || 0) - 1}} {{selectedCompanies.value?.length === 2 ? 'other' :
                                'others'}})
                            </span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="!displayCompanySelect" mat-button mat-dialog-close [disabled]="saveClicked">
        Cancel
    </button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button *ngIf="!displayCompanySelect" id="template_add_save" mat-button type="submit" form="addTemplate" [disabled]="saveClicked"
        (click)="saveClick()">
        <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
        <span *ngIf="!saveClicked">Save</span>
    </button>

    <button *ngIf="displayCompanySelect" mat-button [disabled]="migrateClicked" (click)="close()">
        No
    </button>
    <button *ngIf="displayCompanySelect" mat-button [disabled]="migrateClicked" (click)="migrateClick()">
        <mat-spinner *ngIf="migrateClicked" diameter=25 strokeWidth=4></mat-spinner>
        <span *ngIf="!migrateClicked">Yes</span>
    </button>
</mat-dialog-actions>
