<h2 mat-dialog-title class="dialog-title">
    We like to keep our Intelectual Property private
</h2>
<mat-dialog-content class="m-content">
    <p style="text-align: center">To view our Documentation and walkthroughs, copy the password below to your clipboard, and enter it after redirecting to the Documentation page by clicking the "Let's Go" button below.</p>

    <p style="text-align: center">Once the password is entered, you'll have full access</p>

    <mat-form-field>
        <input matInput name="pass" type="text" [(ngModel)]="pass" placeholder="Password" disabled>
        <button type="button" matSuffix mat-icon-button (click)="copy()"><mat-icon>content_copy</mat-icon></button>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="go()">Let's Go!</button>
</mat-dialog-actions>