
<div class="row">
    <div [class]="bootstrapClass">
        <mat-form-field>
            <mat-label>Select Harvest(s)</mat-label>
            <mat-select [formControl]="harvestCtrl" multiple (closed)="setHarvests()">
                <mat-option>
                    <ngx-mat-select-search [formControl]="harvestFilterCtrl"
                                            placeholderLabel="Find harvest..."
                                            noEntriesFoundLabel="no matching harvest found"></ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="disabled" class="filter-option">
                    <button mat-raised-button class="mat-primary fill text-sm" (click)="selectFiltered()">
                        Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="harvestChange([])">
                        Deselect All
                    </button>
                </mat-option>
                <mat-select-trigger>
                    {{harvestCtrl.value && harvestCtrl.value.length >= 1 ? harvestCtrl.value[0].name : 'None Selected'}}
                    <span *ngIf="harvestCtrl.value?.length > 1" class="example-additional-selection">
                    (+{{harvestCtrl.value.length - 1}} {{harvestCtrl.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let harvest of filteredHarvests | async" [value]="harvest">
                    {{harvest.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div [class]="bootstrapClass">
        <div class="row" *ngIf="displayList">
            <div class="col-12">
                <h5>Selected Harvests:</h5>
                <ul class="list">
                    <li *ngFor="let harvest of selectedHarvests">
                        {{harvest.name}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


  
<!-- <div class="row">
    <div [class]="bootstrapClass">
        <mat-form-field>
            <mat-label>Select Harvest(s)</mat-label>
            <mat-select [(ngModel)]="selectedHarvests" multiple #harvestSelect="ngModel">
                <mat-select-trigger>
                    {{title}}
                </mat-select-trigger>
                <mat-option disabled="disabled" class="filter-option">
                    <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllHarvests(harvestSelect, harvests)">
                        Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="deselectAllHarvests(harvestSelect)">
                        Deselect All
                    </button>
                </mat-option>
                <mat-option [value]="h" *ngFor="let h of harvests">{{h.name}}</mat-option>
            </mat-select>
            <mat-spinner matSuffix *ngIf="loadingHarvests" diameter=25 strokeWidth=4></mat-spinner>
        </mat-form-field>
    </div>
    <div [class]="bootstrapClass">
        <div class="row" *ngIf="displayList">
            <div class="col-12">
                <h5>Selected Harvests:</h5>
                <ul class="list">
                    <li *ngFor="let harvest of selectedHarvests">
                        {{harvest.name}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->
