<!-- <app-banner></app-banner> -->
<div class="banner banner-click" *ngIf="showReleaseNotification" (click)="bannerClick()"> <!--[class.banner-click]="appStorage.releaseVideoUrl != null" -->
    <div class="banner-content">
        Notifications are live! You can now tag users and teams in an Event to send them a message - head over to the release notes to read more!
        <!-- New Release {{appStorage.releaseVersion}} is live! <span>Click here for more</span> *ngIf="appStorage.releaseVideoUrl != null" -->
    </div>
</div>
<div class="wrapper" [class.move-down]="showReleaseNotification">
    <div class="sidebar" [class.move-down]="showReleaseNotification" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>  <!--style="background-image: url(./assets/img/sidebar-4.jpg)"-->
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>
    </div>
</div>
<ngx-guided-tour [backText]="''" [nextText]="'OK'" [doneText]="doneText" [progressIndicatorLocation]="progressIndicatorLocation"></ngx-guided-tour>
<!-- [skipText]="'Skip'" -->
<app-upgrade-fab></app-upgrade-fab>
<app-stopwatch-popup></app-stopwatch-popup>
