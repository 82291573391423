<h2 id="template_add_title" mat-dialog-title><span *ngIf="!isCopy">Add</span><span *ngIf="isCopy">Copy</span> Template</h2>
<mat-dialog-content>
  <form id="addTemplate" class="template-form" (ngSubmit)="saveClick()">

    <mat-form-field class="user-full-width" *ngIf="isCopy">
      <input matInput placeholder="Template to Copy" type="text" required name="copy_name" disabled [(ngModel)]="templateToCopy.name">
    </mat-form-field>
  
    <mat-form-field class="user-full-width">
      <input id="template_add_name" matInput placeholder="New Template Name" type="text" required name="name" [(ngModel)]="name">
    </mat-form-field>

    <mat-form-field class="user-full-width" *ngIf="!isCopy">
      <mat-label>Select A Template Type</mat-label>
      <mat-select [(ngModel)]="selectedTemplateType" name="templateType" required>
        <mat-option *ngFor="let type of templateTypes" [value]="type.id">{{type.name | titlecase}}</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClicked">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button id="template_add_save" mat-button type="submit" form="addTemplate" [disabled]="saveClicked">
    <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClicked">Save</span>
  </button>
</mat-dialog-actions>
