
<a class="nav-link" [matMenuTriggerFor]="navbarAlerts">
    <i [matBadge]="unreadAlerts.length" [matBadgeHidden]="unreadAlerts.length == 0" class="material-icons hide-text">notifications</i>
</a>
<mat-menu #navbarAlerts="matMenu">
    <div style="min-width: 400px;">
        <div class="alert-container" [class.unread] = "alert.read == false" *ngFor="let alert of alerts">
            <button class="alert-btn" mat-menu-item (click)="alertClick(alert)">
                <!-- <mat-icon>notifications</mat-icon> -->
                <span style="padding-right: 20px">
                    <b>{{alert.subject}}</b><br/>
                    {{alert.message}}<br/>
                    <span style="font-size: 12px;">{{alert.dateAdded.seconds * 1000 | date: 'MMM d, y, h:mm a'}}</span>
                </span>
            </button>
            <button class="alert-delete-btn" mat-icon-button (click)="deleteAlert($event, alert)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="alerts.length > 0">
            <mat-divider></mat-divider>
            <button style="width: 50% !important; float: left;" mat-menu-item (click)="markAllAsRead()">
                <mat-icon>mark_email_read</mat-icon>
                <span>Mark all as Read</span>
            </button>
            <button style="width: 50% !important;" mat-menu-item (click)="deleteAll()">
                <mat-icon>delete</mat-icon>
                <span>Delete All</span>
            </button>
        </ng-container>
        <ng-container *ngIf="alerts.length == 0">
            <div class="no-alerts">Good news - you don't have any new messages!</div>
        </ng-container>
    </div>
</mat-menu>

<!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarAlerts">
    <a *ngFor="let alert of alerts" class="dropdown-item" (click)="alertClick(alert)">{{alert.message}}</a>
</div> -->