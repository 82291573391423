

export interface Token {
    token: string;
    incomplete: boolean;
    assigned: boolean;
    timeframe: string;
    description: string;
    example: string;
    message: string;
}

export class Tokens {
    tokens: Array<Token> = [
        {
            token: '{incompleteUserTasks}',
            incomplete: true,
            assigned: true,
            timeframe: 'allTime',
            description: 'All INCOMPLETE tasks assigned to the user on or before the date of notification',
            example: 'You have 7 tasks to complete today',
            message: 'You have {incompleteUserTasks} tasks that are assigned to you that need to be completed by today'
        },
        {
            token: '{incompleteUserTasksToday}',
            incomplete: true,
            assigned: true,
            timeframe: 'daily',
            description: 'All INCOMPLETE tasks assigned to the user on the DATE of notification',
            example: 'You have 2 tasks to complete today',
            message: 'You have {incompleteUserTasksToday} tasks to complete today'
        },
        {
            token: '{incompleteUserTasksThisWeek}',
            incomplete: true,
            assigned: true,
            timeframe: 'weekly',
            description: 'All INCOMPLETE tasks assigned to the user during the WEEK of notification',
            example: 'You have 10 tasks to complete this week',
            message: 'You have {incompleteUserTasksThisWeek} tasks to complete this week'
        },
        {
            token: '{incompleteUserTasksThisMonth}',
            incomplete: true,
            assigned: true,
            timeframe: 'monthly',
            description: 'All INCOMPLETE tasks assigned to the user during the MONTH of notification',
            example: 'You have 30 tasks that have to be completed this month',
            message: 'You have {incompleteUserTasksThisMonth} tasks that have to be completed this month'
        },
        {
            token: '{allUserTasks}',
            incomplete: false,
            assigned: true,
            timeframe: 'allTime',
            description: 'All tasks assigned to the user on or before the date of notification',
            example: 'You have 70 tasks that are assigned to you',
            message: 'You have {allUserTasks} tasks that are assigned to you'
        },
        {
            token: '{allUserTasksToday}',
            incomplete: false,
            assigned: true,
            timeframe: 'daily',
            description: 'All tasks assigned to the user on the DATE of notification',
            example: 'You have 7 tasks assigned to you today',
            message: 'You have {allUserTasksToday} tasks assigned to you today'
        },
        {
            token: '{allUserTasksThisWeek}',
            incomplete: false,
            assigned: true,
            timeframe: 'weekly',
            description: 'All tasks assigned to the user during the WEEK of notification',
            example: 'You have 70 tasks assigned to you this week',
            message: 'You have {allUserTasksThisWeek} tasks assigned to you this week'
        },
        {
            token: '{allUserTasksThisMonth}',
            incomplete: false,
            assigned: true,
            timeframe: 'monthly',
            description: 'All tasks assigned to the user during the MONTH of notification',
            example: 'You have 150 tasks assigned to you this month',
            message: 'You have {allUserTasksThisMonth} tasks assigned to you this month'
        },
        {
            token: '{incompleteTasks}',
            incomplete: true,
            assigned: false,
            timeframe: 'allTime',
            description: 'All INCOMPLETE tasks on or before the date of notification',
            example: 'There are 250 tasks on or before today yet to be completed',
            message: 'There are {incompleteTasks} tasks on or before today yet to be completed'
        },
        {
            token: '{incompleteTasksToday}',
            incomplete: true,
            assigned: false,
            timeframe: 'daily',
            description: 'All INCOMPLETE tasks on the DATE of notification',
            example: 'There are 5 incomplete tasks today',
            message: 'There are {incompleteTasksToday} incomplete tasks today'
        },
        {
            token: '{incompleteTasksThisWeek}',
            incomplete: true,
            assigned: false,
            timeframe: 'weekly',
            description: 'All INCOMPLETE tasks during the WEEK of notification',
            example: 'There are 15 incomplete tasks this week',
            message: 'There are {incompleteTasksThisWeek} incomplete tasks this week'
        },
        {
            token: '{incompleteTasksThisMonth}',
            incomplete: true,
            assigned: false,
            timeframe: 'monthly',
            description: 'All INCOMPLETE tasks during the MONTH of notification',
            example: 'There are 40 incomplete tasks this month',
            message: 'There are {incompleteTasksThisMonth} incomplete tasks this month'
        },
        {
            token: '{tasks}',
            incomplete: false,
            assigned: false,
            timeframe: 'allTime',
            description: 'All tasks on or before the date of notification',
            example: 'There are 5000 tasks on or before today',
            message: 'There are {tasks} tasks on or before today'
        },
        {
            token: '{tasksToday}',
            incomplete: false,
            assigned: false,
            timeframe: 'daily',
            description: 'All tasks on the DATE of notification',
            example: 'There are 20 total tasks today for all users',
            message: 'There are {tasksToday} total tasks today for all users'
        },
        {
            token: '{tasksThisWeek}',
            incomplete: false,
            assigned: false,
            timeframe: 'weekly',
            description: 'All tasks during the WEEK of notification',
            example: 'There are 140 total tasks this week for all users',
            message: 'There are {tasksThisWeek} total tasks this week for all users'
        },
        {
            token: '{tasksThisMonth}',
            incomplete: false,
            assigned: false,
            timeframe: 'monthly',
            description: 'All tasks during the MONTH of notification',
            example: 'There are 500 total tasks this month for all users',
            message: 'There are {tasksThisMonth} total tasks this month for all users'
        }
    ];
}