import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import { HelperService } from './helper.service';

@Injectable()
export class ZoneService {

    cache: { [key: string]: Array<models.Zone> } = {};
    dbZoneCache: { [key: string]: models.DbZone } = {};

    companyRef: AngularFirestoreCollection;
    collectionId: string = 'grow-settings';
    itemId: string = 'zones';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    async getZonesObject(): Promise<models.DbZone> {
        let companyId = this.helperService.currentCompanyId;
        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbZone: models.DbZone = await (await response.get().toPromise()).data();

        return dbZone;
    }

    async getDbZone(): Promise<models.DbZone> {
        let companyId = this.helperService.currentCompanyId;

        //get from memory cache
        if(this.dbZoneCache[companyId] != null){
            return this.dbZoneCache[companyId];
        }

        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbZone: models.DbZone = await (await response.get().toPromise()).data();

        //save to memory cache
        if(dbZone != null){
            this.dbZoneCache[companyId] = dbZone;
        }

        return dbZone;
    }

    async getAll(): Promise<Array<models.Zone>> {
        let companyId = this.helperService.currentCompanyId;

        //get from memory cache
        if(this.cache[companyId] != null){
            return this.cache[companyId];
        }

        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbZone: models.DbZone = await (await response.get().toPromise()).data();

        //save to memory cache
        let list = dbZone?.list || [];
        if(list.length > 0){
            this.cache[companyId] = list;
        }

        return list;
    }

    update(zoneObject: models.DbZone) {
        let companyId = this.helperService.currentCompanyId;

        //clear cache
        this.cache[companyId] = null;

        return this.firestore.collection('companies').doc(companyId)
            .collection(this.collectionId).doc(this.itemId).set(zoneObject);
    }

    //non-db calls
    getListOfParents(zones: Array<models.Zone>, id: string) {
        let parentIds: Array<string> = [];
        let parent: models.Zone = this.getParent({ zones: zones }, id);

        if (parent != null && parent.id != null) {
            id = parent.id;
            parentIds.unshift(id);
        }

        while (parent != null) {
            parent = this.getParent({ zones: zones }, id);
            if (parent != null) {
                id = parent.id;
                if (id != null) {
                    parentIds.unshift(id);
                }
            }
        }

        return parentIds;
    }

    getListOfParentNames(zones: Array<models.Zone>, id: string) {
        let parentNames: Array<string> = [];
        let parent: models.Zone = this.getParent({ zones: zones }, id);

        if (parent != null && parent.name != null) {
            id = parent.id;
            parentNames.unshift(parent.name);
        }

        while (parent != null) {
            parent = this.getParent({ zones: zones }, id);
            if (parent != null) {
                id = parent.id;
                if (id != null) {
                    parentNames.unshift(parent.name);
                }
            }
        }

        return parentNames;
    }

    getParent(zone: models.Zone, id: string): models.Zone {
      var result;
      (zone.zones || []).some(o => result = o.id === id ? zone : this.getParent(o, id));
      return result;
    }

    getZone(zones: Array<models.Zone>, id: string): models.Zone {
        let zone;

        zone = zones.find(i => i.id == id);

        if(zone != null){
            return zone;
        }

        for (let i = 0; i < zones.length; i++) {
            const z = zones[i];
            zone = this.getZone(z.zones, id);
            if(zone != null){
                return zone;
            }
        }
    }

    getListOfChildIds(zones: Array< models.Zone>, id: string): Array<string> {
        let retVal: Array<string> = [];
        let zone = this.getZone(zones, id);
        if(zone != null && zone.zones != null){
            retVal.push(... this.returnListOfChildIds(zone.zones));
        }
        return retVal;
    }

    returnListOfChildIds(zones: Array<models.Zone>) {
        let retVal: Array<string> = [];

        zones.forEach(zone => {
            retVal.push(zone.id);
            if(zone.zones != null){
                let childIds = this.returnListOfChildIds(zone.zones);
                retVal.push(... childIds);
            }
        });

        return retVal;
    }

    getZoneHeirachryString(zones: Array<models.Zone>, zoneId: string) {
      let zone = this.getZone(zones, zoneId);
      let parents = this.getListOfParentNames(zones, zoneId);

      let zoneHierarchyString: string = zone.name;

      for (let index = parents.length-1; index >= 0; index--) {
        const element = parents[index];
        
        zoneHierarchyString = `${element} -> ${zoneHierarchyString}`
      }

      return zoneHierarchyString;
    }

    getAllTopLevel(zones: Array<models.Zone>, zoneIds: string[]): models.Zone[] {
        if(zones == null){
            return [];
        }
        let allIds = []
        zoneIds.forEach(zoneId => {
            allIds.push(zoneId);
            let parentIds = this.getListOfParents(zones, zoneId)
            allIds.push(...parentIds);
        });

        return zones.filter(i => allIds.includes(i.id));
    }
}
