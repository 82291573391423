import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';

@Component({
  selector: 'app-strain-select-multiple-for-harvest',
  templateUrl: './strain-select-multiple-for-harvest.component.html',
  styleUrls: ['./strain-select-multiple-for-harvest.component.scss']
})
export class StrainSelectMultipleForHarvestComponent implements OnInit {

  @Input() hidden: boolean = false;

  allStrainsValue: Array<models.Strain> = [];
  @Input()
  get allStrains() {
    return this.allStrainsValue;
  }
  @Output() allStrainsChange = new EventEmitter();
  set allStrains(val) {
    if(val == null){
      val = [];
    }
    this.allStrainsValue = val;
    this.allStrainsChange.emit(this.allStrainsValue);
  }
  
  strainIdsValue: Array<string> = [];
  @Input()
  get strainIds(): Array<string> {
    return this.strainIdsValue;
  }
  @Output() strainIdsChange = new EventEmitter();
  set strainIds(value) {
    this.strainIdsValue = value;
    this.setHarvestStrains(this.strainIdsValue);
    this.strainIdsChange.emit(this.strainIdsValue);
  }
  
  harvestPhasesValue: Array<models.HarvestPhase> = [];
  @Input()
  get harvestPhases(): Array<models.HarvestPhase> {
    return this.harvestPhasesValue;
  }
  @Output() harvestPhasesChange = new EventEmitter();
  set harvestPhases(value) {
    this.harvestPhasesValue = value;
    this.harvestPhasesChange.emit(this.harvestPhasesValue);
  }

  constructor() { }

  ngOnInit(): void {
  }

  //harvestStrains: Array<models.HarvestPhaseStrain> = [];
  setHarvestStrains(strainIds: Array<string>) {
    if(this.hidden){
      return;
    }

    this.harvestPhases.forEach(harvestPhase => {
      if(harvestPhase.strains == null){
        harvestPhase.strains = [];
      }
      
      strainIds.forEach(strainId => {
        let foundStrain = harvestPhase.strains.find(i => i.strainId == strainId);
        if(foundStrain == null){
          let strain = this.allStrains.find(i => i.id == strainId);
          let newStrain: models.HarvestPhaseStrain = {
            strainId: strain.id,
            strainName: strain.name,
            count: 0
          };
          harvestPhase.strains.push(newStrain);
        }
      });

      //let strainIds = strains.map(i => i.id);
      harvestPhase.strains = harvestPhase.strains.filter(i => strainIds.includes(i.strainId))
    })
  }

}
