<h2 mat-dialog-title>Invite User to Another Company</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Select a Company</mat-label>
        <mat-select [(ngModel)]="selectedCompanyId">
            <mat-option [value]="c.uid" *ngFor="let c of companies">{{c.name}}</mat-option>
        </mat-select>
        <mat-spinner *ngIf="gettingCompanies" matSuffix diameter=25 strokeWidth=4></mat-spinner>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="submitted">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button form="imageUpload" [disabled]="submitted" (click)="invite()">
    <mat-spinner *ngIf="submitted" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!submitted">Invite</span>
  </button>
</mat-dialog-actions>
