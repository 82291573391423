import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as services from '../../services';
import * as models from '../../models';

import { HarvestEditComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-harvest-edit-btn',
  templateUrl: './harvest-edit-btn.component.html',
  styleUrls: ['./harvest-edit-btn.component.scss']
})
export class HarvestEditBtnComponent implements OnInit {

  @Input() harvest: models.Harvest;
  @Output() done: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private claimsService: services.ClaimsService
    , private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  /**
   * if the user has the ability to see the edit harvest functionality
   */
  canSeeHarvestEdit() : boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.editHarvest)) {
      if(this.harvest != null && this.harvest.uid != ''){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  editHarvest() {
    let dialogRef = this.dialog.open(HarvestEditComponent, {
      panelClass: 'med-width-dialog',
      data: this.harvest
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.done.emit();
      }
      console.log(JSON.stringify(result));
    })
  }

}
