import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as models from '../../models';
import * as services from '../../services';
import { HttpsCallableResult } from '@angular/fire/functions';

@Component({
  selector: 'app-template-add',
  templateUrl: './template-add.component.html',
  styleUrls: ['./template-add.component.scss']
})
export class TemplateAddComponent implements OnInit {

  isCopy: boolean = false;
  templateToCopy: models.Template;
  harvestIdToTemplate: string;
  name: string;
  saveClicked: boolean = false;
  selectedTemplateType: models.TemplateType;
  templateTypes = new models.TemplateTypeList().list;

  constructor(
    private dialogRef: MatDialogRef<TemplateAddComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private snackBar: MatSnackBar
    , private templateService: services.TemplateService
    , private notifierService: services.NotifierService
  ) { 
    dialogRef.disableClose = true;

    this.isCopy = data.isCopy;
    this.templateToCopy = data.templateToCopy;
    this.harvestIdToTemplate = data.harvestIdToTemplate;
    this.selectedTemplateType = data.selectedTemplate;
    if(this.selectedTemplateType == null){
      this.selectedTemplateType = models.TemplateType.Primary;
    }
  }

  ngOnInit() {
  }

  saveClick() {

    if(this.name == null || this.name === ''){
      this.snackBar.open('Must enter in name for the new Template.')
      return;
    }
    if(this.selectedTemplateType === undefined) {
      this.notifierService.error("Must Select A Template Type.");
      return;
    }

    this.saveClicked = true;

    if(!this.isCopy){
      if(this.harvestIdToTemplate == null) {
        this.saveTemplate();
      } else {
        this.backfillTemplateFromHarvest();
      }
    }else{
      this.copyTemplate();
    }
  }

  saveTemplate() {
    let template: models.Template = {
      name: this.name,
      phases: [],
      type: this.selectedTemplateType
    }
    this.templateService.save(template).then(data => {
      this.snackBar.open('Successfully saved Template');

      this.dialogRef.close(data);
      
    }).catch(error => {
      this.snackBar.open('There was an error that occured');
      console.log(JSON.stringify(error))
      this.saveClicked = false;
    });
  }

  async backfillTemplateFromHarvest() {
    let backfillResponse: HttpsCallableResult<models.Result<any>> = await this.templateService.createFromHarvest(this.harvestIdToTemplate, this.name);

    this.snackBar.open(backfillResponse.data.message, null, {
      duration: 3000,
      verticalPosition: 'top'
    });

    if(backfillResponse.data.success){
      this.dialogRef.close(true);
    }

    this.saveClicked = false;
  }

  async copyTemplate() {
    let copyResponse: HttpsCallableResult<models.Result<any>> = await this.templateService.copy(this.templateToCopy.uid, this.name);

    this.snackBar.open(copyResponse.data.message, null, {
      duration: 3000,
      verticalPosition: 'top'
    });

    if(copyResponse.data.success){
      this.dialogRef.close(copyResponse.data.value);
    }

    this.saveClicked = false;
  }
}
