<div class="container">
    <h2 mat-dialog-title class="text-center">Your subscription seems to have been locked</h2>
    <mat-dialog-content>
        <div class="row">
            <div class="col-12 text-center">
                Hello, you’ve stopped payment for PlanaCan Premium.<br><br>
                Don’t worry! We still have all your data backed up, but we need a current method of payment to get you back up and running.
            </div>
        </div>
        <div class="row" *ngIf="companies.length > 1">
            <div class="col-12">
                <mat-form-field style="padding: 0px 10px;">
                    <mat-label>Switch to another Company</mat-label>
                    <mat-select [(ngModel)]="selectedCompany" (selectionChange)="companySelectionChange($event.value)">
                        <mat-option *ngFor="let company of companies" [value]="company">
                            {{ company.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="row" *ngIf="canManage">
            <div class="col-12">
                Click here to manage your subscription within your Stripe Portal:
            </div>
        </div> -->
        <div class="row" *ngIf="!canManage">
            <div class="col-12">
                Contact your Subscription manager to resolve the issue.
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="canManage">
        <!-- <ng-container *ngIf="displayManage">
            <app-stripe-portal-btn [customerId]="paymentProviderCustomerId"></app-stripe-portal-btn>
        </ng-container> -->
        <!-- <ng-container *ngIf="displayUpgrade">
            <app-upgrade-btn [trialDays]="0"></app-upgrade-btn>
        </ng-container> -->

        <button mat-raised-button (click)="resume()" color="success">
            Manage Billing
        </button>
    </mat-dialog-actions>
</div>