import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as services from 'app/services';
import * as models from 'app/models';
import { Timestamp } from '@angular/fire/firestore';
var fileDownload = require('js-file-download');

@Component({
  selector: 'app-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.scss']
})
export class DocumentUploadDialogComponent implements OnInit {

  saveClicked: boolean = false;
  fileToUpload: File | null = null;

  harvestId?: string;
  document: models.Document;

  constructor(
    private dialogRef: MatDialogRef<DocumentUploadDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private notifierService: services.NotifierService
    , private fileService: services.FileService
    , private harvestDocumentService: services.HarvestDocumentService
  ) {
    this.document = data?.document;
    this.harvestId = data?.harvestId;
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  async upload() {
    if (this.fileToUpload == null) {
      this.notifierService.error('Must first select a file to upload')
      return;
    }

    this.saveClicked = true;

    if (this.document == null) {
      this.document = {
        name: this.fileToUpload.name,
        fullPath: '',
        file: this.fileToUpload,
        dateUploaded: Timestamp.now()
      }
    } else {
      this.document.name = this.fileToUpload.name;
      this.document.file = this.fileToUpload
    }

    let response = await this.harvestDocumentService.save(this.harvestId, this.document);

    this.dialogRef.close(response)
    this.saveClicked = false;
  }

  download() {
    this.fileService.get(this.document.fullPath).subscribe(async url => {
      let blob = await fetch(url).then(r => r.blob());
      fileDownload(blob, this.document.name);
    });
  }
}
