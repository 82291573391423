<h2 id="sensor_add_title" mat-dialog-title>
  <span style="display: inline-block">Description</span>
</h2>
<mat-dialog-content style="min-width: 30vw;">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput [(ngModel)]="description"></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button (click)="save()">Ok</button>
</mat-dialog-actions>
