import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-team-select-multi-btn',
  templateUrl: './team-select-multi-btn.component.html',
  styleUrls: ['./team-select-multi-btn.component.scss']
})
export class TeamSelectMultiBtnComponent implements OnInit {

  teams: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);

    this.selectedTeams = this.teams.filter(i => this.selected.includes(i.id));
  }
  @Output() onClosed = new EventEmitter();

  selectedTeamsValue: Array<viewmodels.MultiSelect>;
  @Input()
  get selectedTeams() {
    return this.selectedTeamsValue;
  }
  @Output() selectedTeamsChange = new EventEmitter();
  set selectedTeams(val) {
    if (val == null) {
      val = [];
    }
    this.selectedTeamsValue = val;
    this.selectedTeamsChange.emit(this.selectedTeamsValue);
  }

  constructor(
    private teamService: services.TeamService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.getTeams();
  }

  async getTeams() {
    let teams = await this.teamService.getAllModels()
    let list = teams.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i.uid,
        displayValue: i.name,
        selected: false // this.selected.includes(i.uid)
      }
      return h;
    })

    this.teams = this.helperService.sortArrayByStringField(list, 'displayValue');
    //this.selected = this.zones.map(i => i.id);
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
