import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as models from '../../../models';
import * as services from '../../../services';

@Component({
  selector: 'app-group-select-multiple',
  templateUrl: './group-select-multiple.component.html',
  styleUrls: ['./group-select-multiple.component.scss']
})
export class GroupSelectMultipleComponent implements OnInit {

  @Input() displayList: boolean = false;
  @Input() selectByDefault: string = models.SelectDefault.All;
  @Input() initialSelectedIds: string[];

  public groupCtrl: FormControl = new FormControl();
  public groupFilterCtrl: FormControl = new FormControl();
  public filteredGroups: ReplaySubject<models.Group[]> = new ReplaySubject<models.Group[]>(1);
  protected _onDestroy = new Subject<void>();

  groups: Array<models.Group> = [];
  //selectedGroups: Array<models.Group> = [];
  loadingGroups: boolean = true;

  @Input()
  get selectedGroups() {
    return this.groups.filter(i => this.groupIds.includes(i.uid));;
  }

  @Output() selectedGroupsChange = new EventEmitter();
  set selectedGroups(val) {
    if (val == null) {
      val = [];
    }
    this.groupIds = val.map(i => i.uid);
    //this.setForm();
    this.selectedGroupsChange.emit(val);
  }

  get title(): string {
    if (this.selectedGroups == null || this.groups == null) {
      return '';
    }
    if (this.selectedGroups.length == this.groups.length) {
      return 'ALL';
    }
    if (this.selectedGroups.length >= 1) {
      let title = this.selectedGroups[0].name;
      if (this.selectedGroups.length >= 2) {
        title += ` (+${this.selectedGroups.length - 1} ${this.selectedGroups?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }

  groupIDsValue: Array<string>;
  @Input()
  get groupIds() {
    return this.groupIDsValue;
  }
  @Output() groupIdsChange = new EventEmitter();
  set groupIds(val) {
    if(val == null){
      val = [];
    }
    this.groupIDsValue = val;
    this.setForm();
    this.groupIdsChange.emit(this.groupIDsValue);
    this.selectedGroupsChange.emit(this.selectedGroups);
  }

  protected filterGroups() {
    if (!this.groups) {
      return;
    }
    // get the search keyword
    let search = this.groupFilterCtrl.value;
    if (!search) {
      this.filteredGroups.next(this.groups.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Groups
    let filteredGroups = this.groups.filter(group => {
      return (group.name != null && group.name.toLowerCase().indexOf(search) > -1)
    })
    this.filteredGroups.next(filteredGroups);
  }

  constructor(
    private groupService: services.GroupService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    if (this.groups.length === 0) {
      this.getGroups();
    } else {
      this.filteredGroups.next(this.groups.slice());
      this.loadingGroups = false;
    }
    // listen for search field value changes
    this.groupFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGroups();
      });
  }

  async setGroups() {
    this.groupIds = this.groupCtrl.value.map(i => i.uid);
  }

  selectFiltered() {
    let sub = this.filteredGroups.subscribe(data => {
      this.groupChange(data);
    })

    if (sub !== null) {
      sub.unsubscribe();
    }
  }

  groupChange(newValue) {
    this.selectedGroups = newValue;
  }

  // selectAllGroups(select: NgModel, values) {
  //   select.update.emit(values);
  //   this.selectAll();
  // }

  selectAll() {
    this.groupChange(this.groups);
  }

  // deselectAllGroups(select: NgModel) {
  //   select.update.emit([]);
  //   this.groupChange([]);
  // }

  async getGroups() {
    this.loadingGroups = true;
    (await this.groupService.getAll()).snapshotChanges().subscribe(snapshot => {
      let groups = [];
      let data = snapshot
      data.forEach(element => {
        let group: models.Group = element.payload.doc.data();
        group.uid = element.payload.doc.id;
        groups.push(group);
      });

      this.groups = this.helperService.sortArrayByStringField(groups, 'name');
      this.initialSelect();
      this.filteredGroups.next(this.groups.slice());
      this.setForm();
      this.loadingGroups = false;
    })
  }

  initialSelect() {
    if (this.initialSelectedIds != null) {
      let selected = this.groups.filter(i => this.initialSelectedIds.includes(i.uid));
      this.groupChange(selected);
    } else {
      switch (this.selectByDefault) {
        case models.SelectDefault.All: {
          this.groupChange(this.groups);
          break;
        }
        case models.SelectDefault.NotCompleted:
        case models.SelectDefault.None:
        default: {
          break;
        }
      }
    }
  }

  setForm() {
    this.groupCtrl.setValue(this.groups.filter(i => this.groupIds.includes(i.uid)));
  }
}
