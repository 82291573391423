import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as services from '../../services';
import * as models from '../../models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-harvest-delete-btn',
  templateUrl: './harvest-delete-btn.component.html',
  styleUrls: ['./harvest-delete-btn.component.scss']
})
export class HarvestDeleteBtnComponent implements OnInit {

  @Input() harvest: models.Harvest;
  @Output() doneDeleting: EventEmitter<any> = new EventEmitter();

  deleteHarvestClick: boolean = false;

  constructor(
    private claimsService: services.ClaimsService
    , private harvestService: services.HarvestService
    , private snackBar: MatSnackBar
    ) { }

  ngOnInit() {
  }

  canSeeHarvestDelete() : boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteHarvest)) {
      if(this.harvest != null && this.harvest.uid != ''){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    
  }

  deleteHarvest() {
    console.log(JSON.stringify(this.harvest))
    let c = confirm(`Are you sure you want to delete this Harvest (${this.harvest.name})?`);
    if(!c){
      return;
    }

    this.deleteHarvestClick = true;

    this.harvestService.delete(this.harvest.uid).then(response => {
      
      this.snackBar.open(response.data.message, null, {
        duration: 3000,
        verticalPosition: 'top'
      });

      if(response.data.success){
        this.doneDeleting.emit();
      }

      this.deleteHarvestClick = false;
    }).catch(error => {
      this.snackBar.open(error.error, null, {
        duration: 3000,
        verticalPosition: 'top'
      });
      console.log(JSON.stringify(error))

      this.deleteHarvestClick = false;
    });
  }
}
