import { Timestamp } from "@angular/fire/firestore";

export interface GetEventsRequest {

    //dates
    start?: Timestamp;
    end?: Timestamp;

    //filters
    harvestIds: Array<string>
    groupIds: Array<string>
    phases: Array<string>
    teamIds: Array<string>
    userIds: Array<string>
    tags: Array<string>
    completeStatus: string;
    zoneIds: string[];
}