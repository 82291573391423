import { Timestamp } from "firebase/firestore";

export interface MultiSelect {
    id?: string;
    displayValue?: string;
    selected?: boolean;
    completed?: boolean;
    startDate?: Timestamp,
    endDate?: Timestamp,
    harvestDate?: Timestamp,
    formattedStartDate?: string,
    formattedHarvestDate?: string,
    formattedEndDate?: string,
    currentDay?: string;
    harvestAtEnd?: boolean;
    color?: string;
}
