import { Component, OnInit } from '@angular/core';
import { Timestamp, onSnapshot } from 'firebase/firestore';

import * as models from 'app/models';
import * as services from 'app/services';
import { ExtraOptions, NavigationBehaviorOptions, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-alerts-icon',
  templateUrl: './alerts-icon.component.html',
  styleUrls: ['./alerts-icon.component.scss']
})
export class AlertsIconComponent implements OnInit {

  alerts: models.Alert[] = [];

  get unreadAlerts() {
    return this.alerts.filter(i => i.read == false);
  } 

  constructor(
    private alertService: services.AlertService
    , private claimsService: services.ClaimsService
    , private router: Router
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.listenForAlerts();
  }

  listenForAlerts() {
    onSnapshot(this.alertService.getForCurrentUser(false), (snapshot) => {

      let alerts: models.Alert[] = [];
      snapshot.docs.forEach(doc => {
          let alert: models.Alert = doc.data();
          alert.id = doc.id;
          alerts.push(alert);
      })

      this.alerts = alerts;
    })
  }

  async alertClick(alert: models.Alert) {
    //mark as read
    if(!alert.read) {
      alert.read = true;
      alert.readDate = Timestamp.now();
      await this.alertService.update(alert);
    }

    //update company to companyId
    if(alert.companyId != null && this.helperService.currentCompanyId != alert.companyId) {
      this.helperService.currentCompanyId = alert.companyId;
    }

    //redirect
    switch (alert.redirectType) {
      case models.AlertRedirectType.dutchie: //redirect to dutchie
        let calendarUrl: string[] = ['/console', 'calendar']
        let calendarUrlExtras: NavigationExtras = {
          queryParams: {
            dutchie: true
          }
        };
        await this.router.navigate(calendarUrl, calendarUrlExtras)
        break;
    
      default:
        let url: string[] = [
          '/console', 
          alert.harvestId != null ? 'harvest' : 'task-groups', 
          alert.harvestId != null ? alert.harvestId : alert.groupId];
        let extras: NavigationExtras = {
          queryParams: {
            evt: alert.eventId
          }
        };
        await this.router.navigate(url, extras)
        break;
    }
  }

  async markAllAsRead() {
    let alertsToUpdate: models.Alert[] = [];
    this.alerts.forEach(alert => {
      if(alert.read == false){
        alert.read = true;
        alert.readDate = Timestamp.now();
        alertsToUpdate.push(alert);
      }
    });
    await this.alertService.updateList(alertsToUpdate);
  }

  async deleteAlert(event: MouseEvent, alert: models.Alert) {
    event.stopPropagation();
    alert.deleted = true;
    await this.alertService.update(alert);
  }

  async deleteAll() {
    this.alerts.forEach(alert => {
      alert.deleted = true;
    });
    await this.alertService.updateList(this.alerts);
  }
}
