
export interface DbPostHarvest {
    list?: Array<PostHarvest>;
}

export interface PostHarvest {
    id?: string;
    name?: string;
    systemValue?: string;
    dataType?: string;
}
