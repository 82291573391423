import { Batch } from './../../models/leaflogix/batch';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import * as leaflogix from 'app/models/leaflogix/index';
import * as services from 'app/services';

@Component({
  selector: 'app-dutchie-unmatched-batch-select-dialog',
  templateUrl: './dutchie-unmatched-batch-select-dialog.component.html',
  styleUrls: ['./dutchie-unmatched-batch-select-dialog.component.scss']
})
export class DutchieUnmatchedBatchSelectDialogComponent implements OnInit {
  batches: Array<leaflogix.Batch> = [];
  selectedBatch: leaflogix.Batch;

  loading: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<DutchieUnmatchedBatchSelectDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private leafLogixService: services.LeaflogixService
  ) { 
  }

  ngOnInit(): void {
    this.getUnmatchedBatches();
  }

  submit() {
    let dialogResult = {
      submitted: true,
      data: this.selectedBatch
    }
    this.dialogRef.close(dialogResult)
  }

  async getUnmatchedBatches() {
    let response = await this.leafLogixService.getUnlinkedBatches();
    let batchResponse: models.Result<leaflogix.Batch[]> = response.data;

    if(batchResponse.success) {
      this.batches = batchResponse.value;
    }
    this.loading = false;
  }
}
