
<div class="row">
  <div class="col-sm-12 right">
    <button class="gridbtn" mat-raised-button (click)="openCreateCompanyDialog()" title="Create New Company">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <ag-grid-angular 
      style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
      class="ag-theme-balham"
      [gridOptions]="gridOptions"
      (selectionChanged)="onSelectionChanged()">
    </ag-grid-angular>
  </div>
  <div class="col-6" *ngIf="selectedCompany != null">

    <div class="row"> 
      <div class="col-sm-12 right">
        <button class="gridbtn" mat-raised-button [disabled]="!dirty || saving" (click)="saveCompany()" title="Save Company">
          <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
          <mat-icon aria-hidden="false" aria-label="Save" *ngIf="!saving">save</mat-icon>
        </button>
        <button class="gridbtn" mat-raised-button [disabled]="deleting" (click)="deleteClick()" title="Delete Company">
          <mat-spinner *ngIf="deleting" diameter=25 strokeWidth=4></mat-spinner>
          <mat-icon aria-hidden="false" aria-label="Delete" *ngIf="!deleting">delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-checkbox [disabled]="saving" [(ngModel)]="selectedCompany.freeVersion">Free Version</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-checkbox [disabled]="saving" [(ngModel)]="selectedCompany.enableSensors">Sensors Enabled</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- <mat-checkbox [disabled]="saving" [(ngModel)]="selectedCompany.eventsSplit">Events Split</mat-checkbox> -->
            <mat-checkbox [disabled]="true" [(ngModel)]="selectedCompany.eventsSplit">Events Split</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field>
              <input matInput disabled required name=company [(ngModel)]="selectedCompany.paymentProviderCustomerId" placeholder="Payment Provider Id">
            </mat-form-field>
        </div>
    </div>

    <mat-card>
      <div *ngIf="!loadingUser && lastActive != null">Last Active: {{lastActiveUser}} - {{lastActive| date:'MM/dd/yyyy'}}</div>
      <div *ngIf="!loadingUser && lastActive == null">Last Active: INACTIVE</div>
      <div *ngIf="loadingUser">Last Active: <mat-spinner style="display: inline-block;" diameter=15 strokeWidth=3></mat-spinner></div>
      <div>Harvests: 
        <span *ngIf="!loadingharvests">{{harvests}}</span>
        <mat-spinner *ngIf="loadingharvests" style="display: inline-block;" diameter=15 strokeWidth=3></mat-spinner>
      </div>
      <div>Templates: 
        <span *ngIf="!loadingTemplates">{{templates}}</span>
        <mat-spinner *ngIf="loadingTemplates" style="display: inline-block;" diameter=15 strokeWidth=3></mat-spinner>
      </div>
      <div>Users: 
        <span *ngIf="!loadingUsers">{{users}}</span>
        <mat-spinner *ngIf="loadingUsers" style="display: inline-block;" diameter=15 strokeWidth=3></mat-spinner>
      </div>
    </mat-card>

  </div>
</div>


<!-- <div class="row">
    <div class="col-sm-4">
        <mat-form-field>
            <mat-label>Companies</mat-label>
            <mat-select [disabled]="dirty || saving" [(value)]="selectedCompany" (selectionChange)="companyChange($event.value)">
              <mat-option [value]="c" *ngFor="let c of companies">{{c.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-card>
            <div *ngIf="lastActive != null">Last Active: {{lastActiveUser}} - {{lastActive| date:'MM/dd/yyyy'}}</div>
            <div *ngIf="lastActive == null">Last Active: INACTIVE</div>
            <div>Harvests: {{harvests}}</div>
            <div>Templates: {{templates}}</div>
            <div>Users: {{users}}</div>
        </mat-card>
    </div>
    <div class="col-sm-8" *ngIf="selectedCompany != null">
        <div class="row"> 
          <div class="col-sm-12 right">
            <button class="gridbtn" mat-raised-button [disabled]="!dirty || saving" (click)="saveCompany()" title="Save Company">
              <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon aria-hidden="false" aria-label="Save" *ngIf="!saving">save</mat-icon>
            </button>
            <button class="gridbtn" mat-raised-button [disabled]="deleting" (click)="deleteClick()" title="Delete Company">
              <mat-spinner *ngIf="deleting" diameter=25 strokeWidth=4></mat-spinner>
              <mat-icon aria-hidden="false" aria-label="Delete" *ngIf="!deleting">delete</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-checkbox [disabled]="saving" [(ngModel)]="selectedCompany.freeVersion">Free Version</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                  <input matInput disabled required name=company [(ngModel)]="selectedCompany.paymentProviderCustomerId" placeholder="Payment Provider Id">
                </mat-form-field>
            </div>
        </div>
    </div>
</div> -->