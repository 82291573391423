<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <!-- <li>
                    <a href="https://planacan.io/">
                        Welcome
                    </a>
                </li> -->
                <!-- <li>
                    <a href="https://creative-tim.com/about-us">
                        About Us
                    </a>
                </li> -->
                <li>
                    <a [routerLink]="['/terms']" target="_blank">
                        Terms and Conditions
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/privacy-policy']" target="_blank">
                        Privacy Policy
                    </a>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}}
            <!-- <a href="https://www.creative-tim.com" target="_blank">Creative Tim</a> --> Sko Development LLC.
        </div>
    </div>
</footer>
