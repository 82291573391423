<div class="row" style="padding-bottom: 15px">
    <div class="col-sm-12" style="text-align: right">
        <!-- <app-stripe-portal-btn [customerId]="company.stripeCustomerId"></app-stripe-portal-btn> -->
        <button mat-raised-button class="pad-btn" [disabled]="!dataUpdated()" title="Save Company" (click)="save()">
            <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
            <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Template">save</mat-icon>
        </button>
        <button mat-raised-button class="pad-btn" [disabled]="!dataUpdated()" title="Discard Changes" (click)="get()">
            <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
        </button>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <h3 style="float: left;">Notifications</h3>
        <div style="float: right;">
            <button mat-raised-button class="pad-btn" title="Add Notification" (click)="addNoti()">
                <mat-icon aria-hidden="false" aria-label="Add Notification">notification_add</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="row" *ngIf="company.notifications == null || company.notifications.length == 0">
    <div class="col-12">
        <div style="width: 100%; height: 100px; text-align: center;">
            No Notifications configured
        </div>
    </div>
</div>

<div class="row" style="padding-bottom: 20px;" *ngIf="company.notifications != null">
    <div class="col-12">
        <mat-accordion class="example-headers-align" multi>
            <ng-container *ngFor="let n of company.notifications">
                <app-notification-panel [notification]="n" [roles]="roles"
                    (deleteNotification)="deleteNotification($event)"></app-notification-panel>
            </ng-container>
        </mat-accordion>
    </div>
</div>

<div class="row" *ngIf="canSeeBlastNotifications">
    <div class="col-2">
        <mat-form-field class="full-width">
            <input type="text" #input matInput [(ngModel)]="customSubject" placeholder="Custom Subject">
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field class="full-width">
            <input type="text" #input matInput [(ngModel)]="customMessage" placeholder="Custom Message">
        </mat-form-field>
    </div>
    <div class="col-4" style="text-align: right">
        <button mat-raised-button class="pad-btn" title="Blast Notification" (click)="blastNotification()">
            Blast Notification
        </button>
    </div>
</div>