import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import * as models from 'app/models';

@Component({
    selector: "checkbox-label-cell",
    template: `
        <div style="display: inline">
            <mat-checkbox *ngIf="checkBoxVisible" [ngModel]="checked" (ngModelChange)="onChange($event)">{{label}}</mat-checkbox>
        </div>
        <div style="display: inline">
            <mat-form-field *ngIf="displayStrains == true && checked == true && strains.length > 1">
                <mat-label>Select Strain(s)</mat-label>
                <mat-select [(ngModel)]="selectedStrains" (selectionChange)="strainsChange($event.value)" multiple #strainSelect="ngModel">
                    <mat-select-trigger>
                        {{title}}
                    </mat-select-trigger>
                    <mat-option disabled="disabled" class="filter-option">
                        <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAllGroups(strainSelect, strains)">
                            Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAllGroups(strainSelect)">
                            Deselect All
                        </button>
                    </mat-option>
                    <mat-option [value]="strain" *ngFor="let strain of strains">{{strain.strainName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    `,
    styles: [
        `
            ::ng-deep
            .mat-checkbox-layout {
                /* horizontally align the checkbox */
                width: 100%;
                display: inline-block !important;
                text-align: center;
                margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

                /* vertically align the checkbox when not using the ag-material theme - should be the same as the 
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
            }

            .container {
                width: 190px;
                height: 48px;
            }

            .container:focus {
                outline: none;
            }
        `
    ]
})
export class CheckboxLabelComponent implements ICellRendererAngularComp {
    private params: any;

    checked: boolean = false;
    label: string = '';
    checkBoxVisible: boolean = false;
    id: string;
    displayStrains: boolean;
    strains: Array<models.HarvestPhaseStrain> = [];

    selectedStrains: Array<models.HarvestPhaseStrain> = [];

    get title(): string {
      if(this.selectedStrains == null || this.strains == null){
        return '';
      }
      if(this.selectedStrains.length == this.strains.length){
        return 'ALL';
      }
      if(this.selectedStrains.length >= 1){
        let title = this.selectedStrains[0].strainName;
        if(this.selectedStrains.length >= 2){
          title += ` (+${this.selectedStrains.length - 1} ${this.selectedStrains?.length === 2 ? 'other' : 'others'})`
        }
        return title;
      }
    }

    agInit(params: any): void {
        this.params = params;
        if(this.params.value != null){
            this.label = this.params.value?.name;
            this.id = this.params.value?.id;
            this.checked = this.params.value?.checked;
            this.checkBoxVisible = true;
        }
        this.displayStrains = this.params.displayStrains;
        this.strains = this.params.strains;
        //this.checked = this.params.value === "On";
    }

    // demonstrates how you can do "inline" editing of a cell
    onChange(checked: boolean) {
        this.checked = checked;
        this.params.value.checked = checked;
        
        //this.params.data
        this.params.check.call(this.params.that, this.id, this.checked);
    }

    strainsChange(value: Array<models.HarvestPhaseStrain>){
        this.selectedStrains = value;
        this.params.value.selectedStrains = value;
    }

    refresh(params: any): boolean {
        return false;
    }
}