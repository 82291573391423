import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColDef, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { LinkCellComponent, ZoneHierarchyComponent } from 'app/components/cell-components';
import { SensorEditComponent, SensorUploadComponent } from 'app/dialogs';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewModels from 'app/viewmodels';
import { ExportToCsv, Options } from 'export-to-csv';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sensor-setup',
  templateUrl: './sensor-setup.component.html',
  styleUrls: ['./sensor-setup.component.scss']
})
export class SensorSetupComponent implements OnInit, AfterViewInit {
  public gridOptions: GridOptions;
  
  sensors: Array<models.Sensor>;
  
  csvExporter: ExportToCsv;

  columnDefs: Array<ColDef> = [
    {
        headerName: "Sensor Name",
        field: "name",
        filter: "agTextColumnFilter",
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: (params) => {
          params.func = this.editSensor.bind(this);
          return params;
        },
    },
    {
        headerName: "Sensor Number",
        field: "sensorNumber",
        filter: "agTextColumnFilter"
    },
    {
        headerName: "Type",
        field: "type",
        filter: "agTextColumnFilter",
    },
    {
        headerName: "Zone",
        field: "zoneId",
        cellRendererFramework: ZoneHierarchyComponent,
    },
  ];

  constructor(
    private sensorService: services.SensorService
    , private dialog: MatDialog
    , private deviceService: DeviceDetectorService
    , private helperService: services.HelperService
  ) { 
    this.gridOptions = <GridOptions>{
      context: {
          componentParent: this
      },
      //rowData: this.rowData,
      columnDefs: this.columnDefs,
      rowHeight: 35,
      onGridReady: () => {
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      frameworkComponents: {
      },
      rowSelection: 'none',
      enableCellTextSelection: true,
      defaultColDef: {
        sortable: true,
      },
      onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
        this.editSensor(event.data);
      }
    };
  }

  ngOnInit(): void {
    this.getSensors();
  }

  ngAfterViewInit(): void {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.getSensors();
    }
  }

  async getSensors() {
    //set grid to show that it is loading
    //this.gridOptions.api.showLoadingOverlay();

    //find the company and go get the users for that company
    let sensors = await this.sensorService.getAll();
    this.sensors = this.helperService.sortArrayByStringField(sensors, 'name');
  }

  addSensor() {
    let inObj = {
      isAdd: true,
      sensor: {},
      allSensors: this.sensors,
      options: [...new Set(this.sensors.map(i => i.type))] 
    }
    let dialogRef = this.dialog.open(SensorEditComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSensors();
      }
    })
  }

  editSensor(sensor: models.Sensor) {
    let inObj = {
      isAdd: false,
      sensor: sensor,
      allSensors: this.sensors,
      options: [...new Set(this.sensors.map(i => i.type))] 
    }
    let dialogRef = this.dialog.open(SensorEditComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      //if (result) {
        this.getSensors();
      //}
    })
  }

  export() {
    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: 'Sensors Export',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Sensors Export',
      //headers: ['Phase', 'Day in Phase', 'Task Description', 'High Priority', "Anytime", "Start Time", "Cloud Attachments", "Notes"]
    };
  
    this.csvExporter = new ExportToCsv(options);

    this.csvExporter.generateCsv(this.sensors);
  }

  import() {
    let dialogRef = this.dialog.open(SensorUploadComponent);

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.getSensors();
      }
      console.log(JSON.stringify(result));
    })
  }
}
