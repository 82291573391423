<h3>History</h3>

<div class="container" *ngIf="loading">
    <mat-spinner style="margin: auto; vertical-align: baseline;" diameter=25 strokeWidth=4></mat-spinner>
</div>

<div *ngIf="!loading && discussionViewModels.length != 0">
    <ul class="history">
        <li [class]="d.icon" *ngFor="let d of discussionViewModels">
            <b>{{d.userName}} </b> 
            <span [innerHtml]="d.description"></span>
            <span *ngIf="d.timestamp != null" [matTooltip]="d.formattedDate"> {{d?.timestamp?.seconds * 1000 | timeAgo}}</span></li>
    </ul>
</div>

<div class="container" *ngIf="!loading && discussionViewModels.length == 0">
    There is no history to display for this task
</div>
