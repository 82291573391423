import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpsCallableResult } from 'firebase/functions';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-harvest-edit-dialog',
  templateUrl: './harvest-edit-dialog.component.html',
  styleUrls: ['./harvest-edit-dialog.component.scss']
})
export class HarvestEditDialogComponent implements OnInit {

  saving: boolean = false;

  type: string = 'harvest';
  id: string;
  name: string;
  harvest: models.Harvest;
  color: string;

  get saveAsGenericGroup() {
    return this.type != 'harvest';
  }

  constructor(
    private dialogRef: MatDialogRef<HarvestEditDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private harvestService: services.HarvestService
    , private notifierService: services.NotifierService
  ) {
    if(data) {
      this.id = data.id;
      this.harvest = data.harvest;
      this.color = data.color;
      this.name = this.harvest.name;
    }
  }

  ngOnInit(): void {
  }

  async save() {
    this.saving = true;

    let requests: any[] = [];

    let updateResponse: HttpsCallableResult<models.Result<any>> = await this.harvestService.updateColor(this.id, null, this.color, this.name);

    if(updateResponse.data.success) {
      this.notifierService.success('Successfully updated Harvest');
    } else {
      this.notifierService.brokenRules(updateResponse.data.brokenRules);
    }

    requests.push(this.harvestService.updateColor(this.id, null, this.color, this.name));

    // if(this.harvest.name != this.name) {
    //   this.harvest.name = this.name;

    //   requests.push(this.harvestService.update(this.harvest));
    // }

    // let responses = await Promise.all(requests);

    // let colorResponse: HttpsCallableResult<models.Result<any>> = responses[0];

    // if(colorResponse.data.success) {
    //   this.notifierService.success('Successfully updated Color');
    // } else {
    //   this.notifierService.brokenRules(colorResponse.data.brokenRules);
    // }
    
    // if(responses.length > 1) {
    //   this.notifierService.success('Successfully updated Harvest');
    // }

    this.dialogRef.close({
      color: this.color,
      name: this.name
    })
    this.saving = false;
  }
}
