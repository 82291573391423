export interface ViewModel {
    id?: string;
    name?: string;
}

export interface TemplateListViewModel {
    id?: string;
    name?: string;
    phases?: number;
    events?: number;
    harvestsCreated?: number;
    duration?: number;
}