import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as services from '../../services';
import * as models from '../../models';

import { HarvestShiftComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-harvest-shift-btn',
  templateUrl: './harvest-shift-btn.component.html',
  styleUrls: ['./harvest-shift-btn.component.scss']
})
export class HarvestShiftBtnComponent implements OnInit {

  @Input() harvest: models.Harvest;
  @Input() disabled: boolean;
  @Input() dropDownMenu: boolean = false;
  @Output() done: EventEmitter<any> = new EventEmitter();

  constructor(
    private claimsService: services.ClaimsService
    , private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  /**
   * if the user has the ability to see the edit harvest functionality
   */
  canSee() : boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.shiftHarvest)) {
      return true;
    } else {
      return false;
    }
  }

  click() {
    let inData: any = { }
    if(this.harvest != null){
      inData.harvestId = this.harvest.uid,
      inData.name = this.harvest.name
    }
    let dialogRef = this.dialog.open(HarvestShiftComponent, {
      panelClass: 'med-width-dialog',
      data: inData
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if(result){
        this.done.emit();
      }
      console.log(JSON.stringify(result));
    })
  }

}
