import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent implements OnInit {

  loading: boolean = true;
  saving: boolean = false;
  errorCode: string;

  linkId: string;
  link: models.UserLink;

  constructor(
    private route: ActivatedRoute
    , private router: Router
    , private userService: services.UserService
    , private notifierService: services.NotifierService
  ) { 
    this.route.queryParams.subscribe(params => {
        this.linkId = params['l'];
        this.verifyLink();
    });
  }

  ngOnInit(): void {
  }

  async verifyLink() {
    if(this.linkId == null){
      this.router.navigate(['login']);
      return;
    }

    let linkResponse = await this.userService.getUserLink(this.linkId);
    let link: models.Result<models.UserLink> = linkResponse.data;

    if(!link.success) {
      this.errorCode = link.message;
      this.loading = false;
      return;
    }

    this.link = link.value;
    this.loading = false;
  }

  async submit() {
    this.saving = true;
    //update user companies

    let request: viewmodels.AcceptInvitationLinkRequest = {
      userId: this.link.user.id,
      linkId: this.link.id
    }
    let response = await this.userService.acceptInvitationLink(request);
    let link: models.Result<models.UserLink> = response.data;
    if(!link.success) {
      this.notifierService.error(link.message);
      this.saving = false;
      return;
    }

    await this.userService.expireUserLink(this.link.user.id, this.link.id);

    window.location.href = '/console';

    this.saving = false;
  }
}
