import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-recurring-events-dialog',
  templateUrl: './edit-recurring-events-dialog.component.html',
  styleUrls: ['./edit-recurring-events-dialog.component.scss']
})
export class EditRecurringEventsDialogComponent implements OnInit {

  type: string = 'Edit';
  choice: string = 'one';

  constructor(
    private dialogRef: MatDialogRef<EditRecurringEventsDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(data?.type) {
      this.type = data?.type;
    }
  }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(this.choice);
  }
}
