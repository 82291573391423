<mat-card class="col-sm-12 mt-4 chart-container">
    <mat-card-header class="chart-title">
        <mat-card-title>
            {{ chartTitle }}
        </mat-card-title>
        <mat-card-subtitle>
            {{ chartSubTitle }}
        </mat-card-subtitle>
    </mat-card-header>
    <span class="material-symbols-outlined expand-icon" 	
        (click)="changeChartView()">
        expand_content
    </span>
    <mat-card-content class="mb-0">
        <div style="height: 400px;">
            <canvas #chartCanvas></canvas>
        </div>
    </mat-card-content>
    <mat-card-actions class="card-action-btn">
            <app-chart-daterange-filter 
                (selectedChartDateRangeChange)="handleChartDateRangeChange($event)"
                [filterForEventVolumeChart]="true"
                [showYearly]="false"
                [showAllTime]="false"
                [showCustom]="false"
            ></app-chart-daterange-filter>
            <app-chart-interval-filter 
                [dateRange]="dateRange" 
                (selectedChartIntervalChange)="handleChartIntervalChange($event)"
            ></app-chart-interval-filter>
    </mat-card-actions>
</mat-card>

