
<mat-form-field>
    <mat-label>Select Task Group(s)</mat-label>
    <mat-select [formControl]="groupCtrl" multiple (closed)="setGroups()">
        <mat-select-trigger>
            {{title}}
        </mat-select-trigger>
        <mat-option>
            <ngx-mat-select-search [formControl]="groupFilterCtrl"
                                    placeholderLabel="Find Group..."
                                    noEntriesFoundLabel="no matching group found"></ngx-mat-select-search>
        </mat-option>
        <mat-option disabled="disabled" class="filter-option">
            <button mat-raised-button class="mat-primary fill text-sm" (click)="selectFiltered()">
                Select All
            </button>
            <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="groupChange([])">
                Deselect All
            </button>
        </mat-option>
        <mat-option [value]="h" *ngFor="let h of filteredGroups | async">{{h.name}}</mat-option>
    </mat-select>
    <mat-spinner matSuffix *ngIf="loadingGroups" diameter=25 strokeWidth=4></mat-spinner>
</mat-form-field>

<div class="row" *ngIf="displayList">
    <div class="col-12">
        <h5>Selected Groups:</h5>
        <ul class="list">
            <li *ngFor="let group of selectedGroups">
                {{group.name}}
            </li>
        </ul>
    </div>
</div>