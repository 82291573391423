import { Component, OnDestroy, OnInit } from '@angular/core';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-settings-warehouse-layout',
  templateUrl: './company-settings-warehouse-layout.component.html',
  styleUrls: ['./company-settings-warehouse-layout.component.scss']
})
export class CompanySettingsWarehouseLayoutComponent implements OnInit, OnDestroy {

  companySub: Subscription;

  get width() {
    let cal = this.columns / this.rows * 50;

    return cal + '%';
  }
  get height() {
    return '75vh';
  }

  rows: number = 1;
  columns: number = 1;
  gridTemplateColumns: string;
  availableBoxes: Array<any>;
  zones: Array<models.Zone> = []

  constructor(
    private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.load(companyId);
    }
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load(data);
    })
  }

  ngOnDestroy(): void {
    this.companySub.unsubscribe();
  }

  setgridTemplateColumns() {
    let ret: string = '';
    for (let i = 0; i < this.columns; i++) {
      ret += '1fr '
    }
    this.gridTemplateColumns = ret;
  }

  setavailableBoxes() {
    let ret: Array<any> = [];
    let number = this.rows * this.columns;
    for (let i = 0; i < number; i++) {
      ret.push({name: i});
    }
    this.availableBoxes = ret;
  }

  addCol(){
    this.columns++;
  }

  addRow() {
    this.rows++;
  }

  load(companyId: string) {
    //TODO: get Zones
    this.zones.push({name: 'Test', rows: 1, cols: 1, zones: []})
  }

}
