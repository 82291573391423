<h2 id="template_add_title" mat-dialog-title>Attachments</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div class="flex-container">
                <div class="flex-item-1">
                    <input class="file-upload" type="file" id="file" (change)="handleFileInput($event.target.files)">
                </div>
                <div class="flex-item-2" *ngIf="attachment != null">
                    <button style="margin-left: 5px;" mat-raised-button (click)="download()" title="Download Example">
                        <mat-icon aria-hidden="false" aria-label="Download Example">download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="fileToUpload != null">
        <div class="col-12">
            {{fileToUpload.name}}
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClicked">Cancel</button>
  <button id="template_upload_save" mat-button form="uploadTemplate" [disabled]="saveClicked" (click)="upload()">
    <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClicked">Upload</span>
  </button>
</mat-dialog-actions>
