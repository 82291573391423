import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as models from '../../models';
import * as services from '../../services';
import * as viewmodels from 'app/viewmodels';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  userSaveClick: boolean = false;
  roles: Array<models.Role> = [];

  user: models.User = {};

  constructor(
    private dialogRef: MatDialogRef<UserAddComponent>
    , private authService: services.AuthService
    , private userService: services.UserService
    , private snackBar: MatSnackBar
    , private storage: SessionStorageService
    , private helperService: services.HelperService
    , private roleService: services.RoleService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getRoles();
  }

  async getRoles() {
    let roles: Array<models.Role> = await this.roleService.getRoles();
    this.roles = this.helperService.sortArrayByStringField(roles, 'name');
  }

  saveUser(): void {

    if(!this.helperService.emailIsValid(this.user.emailAddress)){
      this.snackBar.open('Email is not valid.');
      return;
    }

    if(this.user.displayName == null || this.user.displayName === ''){
      this.snackBar.open('Must add a Name for your user.')
      return;
    }

    if(this.user.roleId == null || this.user.roleId === ''){
      this.snackBar.open('Must select a Role.')
      return;
    }

    this.userSaveClick = true;

    this.user.companyIds = [this.helperService.currentCompanyId];
    
    let request: viewmodels.AddUserRequest = {
      domain: this.helperService.getCurrentDomain(),
      user: this.user
    }
    this.userService.addUser(request).then(data => {

      if(data.data.success){
        this.snackBar.open(data.data.message);

        // if(data.data.email){
        //   this.authService.sendPasswordResetLink(data.data.email).then(data => {
        //     this.dialogRef.close();
  
        //     this.userSaveClick = false;
        //   });
        // } else {
          this.dialogRef.close();
          this.userSaveClick = false;
        //}

      }else{
        this.snackBar.open(data.data.message, null, {
          duration: 3000,
          verticalPosition: 'top'
        });

        this.userSaveClick = false;
      }
      
    }).catch(error => {
      this.snackBar.open(error.message);
      console.log(JSON.stringify(error))

      this.userSaveClick = false;
    });
  }
}
