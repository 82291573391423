import {Phase} from './phase';
import { Timestamp } from 'rxjs';

export interface Template {
    uid?: string;
    name?: string;
    //dateAdded?: firebase.firestore.Timestamp;

    phases?: Array<Phase>;
    type?: string;
}