import { Injectable } from '@angular/core';
import * as firebase from 'firebase/compat/app';

import * as models from '../models';
import * as viewmodels from '../viewmodels';

import { AngularFirestore, DocumentData, QuerySnapshot } from '@angular/fire/compat/firestore';
import { HelperService } from './helper.service';
import { LocalStorageService } from 'angular-web-storage';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';

@Injectable()
export class UserService {

  constructor(
    private functions: Functions,
    private firestore: AngularFirestore,
    private helperService: HelperService,
    private localStorage: LocalStorageService,
    private storage: AngularFireStorage,
  ) {
  }

  async getUsersForCompanyWithCaching(companyId: string, onlyEnabledUsers: boolean = false, useCaching: boolean = true): Promise<models.User[]> {
    let users: Array<models.User> = [];

    if(useCaching){ //if we are using caching, go try to get it from localstorage.  if it is there, return.  if it is not, go get from server
      let cachedUsers = this.localStorage.get(`userService_exp_${companyId}_${onlyEnabledUsers}`);

      if(cachedUsers != null){
        return cachedUsers;
      }
    }

    //go get from server
    let userResponse = await this.getUsersFoCompany(companyId, onlyEnabledUsers);
    if(userResponse.data.success){
      userResponse.data.value.forEach(element => {
        let user: models.User = element;
        user.id = element.id;
        users.push(user);
      });
    }

    //set cache always
    this.localStorage.set(`userService_exp_${companyId}_${onlyEnabledUsers}`, users, 12, 'h') //expire after 12 hours

    return users;
  }

  async getUsersFoCompany(companyId: string, onlyEnabledUsers: boolean = false): Promise<HttpsCallableResult<models.Result<Array<models.User>>>> {
    var allUsersMethod = httpsCallable<any, models.Result<Array<models.User>>>(this.functions, 'listAllUsersForCompany');
    var users = await allUsersMethod({companyId: companyId, onlyEnabledUsers: onlyEnabledUsers});

    return users;
  }

  async getInvitedUsers(companyId: string, onlyEnabledUsers: boolean = false): Promise<models.User[]> {
    let query = this.firestore.collection('users').ref.where("invitedCompanyIds", "array-contains", companyId);

    //if we do not want disabled users -> include it in the query to not grab them
    if(onlyEnabledUsers){
      query = query.where("disabled", "==", false);
    }

    let users: Array<models.User> = [];
    let userRefs = await query.get();

    for (let i = 0; i < userRefs.docs.length; i++) {
      const userRef = userRefs.docs[i];
      let user: models.User = userRef.data();
      user.id = userRef.id;
      users.push(user);
    }

    return users;
  }

  async getAllUsers(): Promise<QuerySnapshot<DocumentData>> {
    return this.firestore.collection('users').get().toPromise();
  }

  async addUser(user: viewmodels.AddUserRequest): Promise<any> {
    var addUsersMethod = httpsCallable(this.functions, 'addUser');
    var addUserResponse = await addUsersMethod(user);
    
    this.helperService.removeAllCacheStartsWith("userService_")

    return addUserResponse;
  }


  async updateUserCompanies(inObj: any): Promise<any> {
    var updateUserCompaniesMethod = httpsCallable(this.functions, 'updateUserCompanies');
    var updateUserCompaniesResponse = await updateUserCompaniesMethod(inObj);
    
    this.helperService.removeAllCacheStartsWith("userService_")

    return updateUserCompaniesResponse;
  }

  async getUserById(userId: string): Promise<models.User> {
    let userDocData = await this.firestore.collection('users').doc(userId).get().toPromise();
    let user: models.User = userDocData.data();
    user.id = userDocData.id;

    return user;
  }

  async getUserByIdList(userIds: Array<string>): Promise<Array<models.User>> {
    let response: Array<models.User> = [];

    for (let i = 0; i < userIds.length; i++) {
      const userId = userIds[i];

      let userDocData = await this.firestore.collection('users').doc(userId).get().toPromise();
      let user: models.User = userDocData.data();
      user.id = userDocData.id;

      response.push(user);
    }
    
    return response;
  }

  async completeTour(userId: string) {
    await this.firestore.collection('users').doc(userId).update({mustCompleteTour: false})
  }

  /* using the micro backend service to update things in the Authorization service */
  async updateUser(user: models.User): Promise<any> {
    var updateUserMethod = httpsCallable(this.functions, 'updateUser');
    var updateUserResponse = await updateUserMethod(user);
    
    this.helperService.removeAllCacheStartsWith("userService_")

    return updateUserResponse;
  }

  /** Updating things that are not in the authorization service */
  async update(user: models.User){
    let savableUser: models.User = this.helperService.deepCopy(user);
    let id: string = savableUser.id;
    delete savableUser.id;

    await this.firestore.collection('users').doc(id).update(savableUser)
    
    this.helperService.removeAllCacheStartsWith("userService_")
  }

  // updateUserToAdmin(): Observable<any> {
  //   // let func = httpsCallable(this.functions, 'myFunc');
  //   // func({text: 'huch'}).then(result => console.log('...and returned!'));

  //   const callable = this.fns.httpsCallable('addSystemAdmin');
  //   return callable({ email: 'mikey.rast@gmail.com' });


  //   // var allUsersMethod = httpsCallable(this.functions, 'addSystemAdmin');
  //   // var users = allUsersMethod({email: 'mikey.rast@gmail.com'});

  //   // return users;
  // }

  async verifyUserLink(linkId: string, password: string) {
    let inObj = {
      linkId: linkId,
      password: password
    }
    var method = httpsCallable(this.functions, 'verifyUserLink');
    var response = await method(inObj);

    return response;
  }

  async expireUserLink(userId: string, linkId: string) {
    let linkRef = this.firestore.collection('users').doc(userId).collection('links').doc(linkId);

    await linkRef.update({expiration: Timestamp.now()})
  }

  async requestReplacementLink(linkId: string) {
    let domain = document.location.origin;
    let inObj = {
      linkId: linkId,
      domain: domain
    }
    var method = httpsCallable(this.functions, 'requestReplacementLink');
    var response = await method(inObj);

    return response;
  }

  async getUserLink(linkId: string) {
    let inObj = {
      linkId: linkId
    }
    var method = httpsCallable(this.functions, 'getUserLink');
    var response = await method(inObj);

    return response;
  }

  async acceptInvitationLink(request: viewmodels.AcceptInvitationLinkRequest){
    var method = httpsCallable(this.functions, 'acceptInvitationLink');
    var response = await method(request);

    return response;
  }

  async getUserImageUrl(userId: string) {
    try {
      let path = `userImage/${userId}`;
      let userImageUrl = await this.storage.ref(path).getDownloadURL().toPromise();
      return userImageUrl;
    } catch(ex) {
      return 'assets/img/blank-profile-picture.png';
    }
  }
}
