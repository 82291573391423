<h2 mat-dialog-title>Zone
  <aside class="right">
    <button mat-icon-button>
      <mat-icon style="margin-right: 8px" (click)="copyClick()">content_copy</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon style="margin-right: 8px" (click)="deleteClick()">delete</mat-icon>
    </button>
  </aside>
</h2>
<mat-dialog-content style="min-width: 50vw;">
  <form id="saveZone" class="zone-form" (ngSubmit)="saveZone()">
    <div class="row">
      <div class="col-4" *ngFor="let zs of zoneSelections; let i = index">
        <mat-form-field>
          <mat-label>{{zoneTitles[i]}}</mat-label>
          <mat-select [(ngModel)]="zs.selectedZone" (selectionChange)="change(zs, i, $event)" [ngModelOptions]="{standalone: true}" [disabled]="disabled(i)">
            <mat-option [disabled]="z.id == zone.id" [value]="z" *ngFor="let z of zs.zones">{{z.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="col-4" *ngFor="let z of zoneSelections; let i = index">
        <mat-form-field>
          <mat-label>{{z.title}}</mat-label>
          <mat-select [(ngModel)]="z.selectedZone" (selectionChange)="change(z, i, $event)" [disabled]="disabled(i)">
            <mat-option [value]="zone" *ngFor="let zone of z.zones">{{zone.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <div class="row">
      <div class="col-8"> <!-- [class]="nameClass" -->
        <mat-form-field>
          <input matInput placeholder="Zone Name" type="text" name="name" [(ngModel)]="zone.name">
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <input matInput placeholder="Placements" [disabled]="!canEditPlacements" type="number" name="placement" [(ngModel)]="zone.placements">
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="sensors != null && sensors.length > 0">
      <div class="col-12">
        Sensors:
        <ul>
          <li *ngFor="let s of selectedSensors">{{s.name}}</li>
        </ul>
        <!-- <app-sensors-select-multiple [(sensorIds)]="zone.sensorIds"></app-sensors-select-multiple> -->
      </div>
    </div>
  
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button type="submit" form="saveZone">Ok</button>
</mat-dialog-actions>
