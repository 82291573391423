import { Component, OnDestroy, OnInit } from '@angular/core';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';

import { Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { CompanySelectDialogComponent, RoleAddDialogComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CompanySelectMultipleDialogComponent } from 'app/dialogs/company-select-multiple-dialog/company-select-multiple-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-settings-security',
  templateUrl: './company-settings-security.component.html',
  styleUrls: ['./company-settings-security.component.scss']
})
export class CompanySettingsSecurityComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  companySub: Subscription;

  loading: boolean = true;
  saving: boolean = false;
  deleting: boolean = false;

  // systemSettings: models.SystemSettings = {};
  // company: models.Company = {};
  // initialCompany: models.Company = {};

  roles: models.Role[] = [];
  selectedRoleId: string;
  get selectedRole(): models.Role {
    return this.roles.find(i => i.id == this.selectedRoleId);
  };
  initialRole: models.Role
  permissionList: models.Permission[] = [];

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  treeControl: FlatTreeControl<ItemFlatNode>;
  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;
  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  userCompanies: models.Company[] = [];
  get hasMultipleCompanies() {
    return this.userCompanies.length > 1;
  };

  constructor(
    private helperService: services.HelperService
    , private roleService: services.RoleService
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private companyService: services.CompanyService
    , private route: ActivatedRoute
  ) {
    super()

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  getLevel = (node: ItemFlatNode) => node.level;
  isExpandable = (node: ItemFlatNode) => node.expandable;
  getChildren = (node: ItemNode): ItemNode[] => node.children;
  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.compileSelected();

    if(this.checklistSelection.isSelected(node)){
      this.treeControl.expand(node);
    } else {
      this.treeControl.collapse(node);
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.compileSelected();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  compileSelected() {
    let selectedPermissions = this.checklistSelection.selected
      .filter(i => i.level != 0)
      .map(p => this.permissionList.find(i => i.name == p.item));
    
    if(this.selectedRole != null) {
      let orderedPermissions: models.Permission[] = this.helperService.sortArrayByStringField(selectedPermissions, 'id');
      this.selectedRole.permissions = orderedPermissions.map(i => i.id);
    }
  }

  canDeactivate():boolean{
    return !this.dirty();
  }

  dirtyBool: boolean = false;
  dirty(): boolean {
    let x = JSON.stringify(this.initialRole);
    let y = JSON.stringify(this.selectedRole);

    this.dirtyBool = x !== y;
    return this.dirtyBool;
  }

  roleChange() {
    this.helperService.queryStringMergeNoReload({id: this.selectedRoleId}, true);
  }

  idChange() {
    this.initialRole = this.helperService.deepCopy(this.selectedRole);
    //this.dataSource.data = this.buildFileTree();
    this.setSelectedNodes();
  }

  ngOnInit(): void {
    this.dataSource.data = this.buildFileTree();

    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.load();
    }
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load();
    })

    this.route.queryParams.subscribe(params => {
      console.log(params);

      this.selectedRoleId = params.id;
      if(this.selectedRoleId != null) {
        this.idChange();
      }
    });

    this.getUserCompanies();
  }

  ngOnDestroy(): void {
    if(this.companySub != null) {
      this.companySub.unsubscribe();
    }
  }

  async getUserCompanies() {
    let companyResponse = await this.companyService.getAllCompaniesForUser();

    if(companyResponse.success) {
      this.userCompanies = companyResponse.value || [];
    }
  }

  load() {
    this.getRoles();
  }

  async getRoles() {
    this.loading = true;
    let roles = await this.roleService.getRoles();
    roles.forEach(role => {
      role.permissions = role.permissions.sort();
    });
    this.roles = roles;

    if(this.selectedRoleId != null && this.roles.find(i => i.id == this.selectedRoleId) == null) {
      this.selectedRoleId = null
    }
    if(this.selectedRoleId == null && this.roles.length > 0){
      this.selectedRoleId = this.roles[0].id;
    }
    this.roleChange();
    this.idChange();
    this.loading = false;
    //this.dataSource.data = this.buildFileTree();
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `ItemNode`.
   */
  buildFileTree(): ItemNode[] {
    let returnList: ItemNode[] = [];
    this.permissionList = new models.PermissionList().list.filter(i => i.grouping != models.PermissionGroups.hideFromUser);
    let permissionGroups = Array.from(new Set(this.permissionList.map(i => i.grouping))).sort();

    permissionGroups.forEach(permissionGroup => {
      const node: ItemNode = {
        item: permissionGroup,
        children: []
      };

      let groupPermissions = this.helperService.sortArrayByStringField(this.permissionList.filter(i => i.grouping == permissionGroup), 'name');

      groupPermissions.forEach(permission => {
        const permissionNode: ItemNode = {
          item: permission.name,
          id: permission.id,
          checked: this.selectedRole?.permissions?.includes(permission.id)
        };
        
        node.children.push(permissionNode);
      });

      returnList.push(node);
    });

    return returnList;
  }

  setSelectedNodes() {
    this.checklistSelection.deselect(...this.treeControl.dataNodes)
    let flatNodes = this.treeControl.dataNodes;
    let defaultSelected = [];
    flatNodes?.forEach(flatNode => {
      let foundPermission = this.permissionList.find(i => i.name == flatNode.item);
      if(foundPermission != null && this.selectedRole?.permissions?.includes(foundPermission.id)){
        defaultSelected.push(flatNode);
      }
    });
    this.checklistSelection.select(...defaultSelected)
    this.compileSelected();
  }

  saveClick() {
    this.saving = true;

    if(this.selectedRole.powerUser && this.selectedRole.powerUser != this.initialRole.powerUser) {
      this.notifierService.confirm(
        `Update ${this.selectedRole.name} to Power User`, 
        'Are you sure you want to update this role to Power User?  Any users that have this role assigned will have full access to the application.', 
        () => { this.save() }, () => { this.saving = false })
    } else {
      this.save();
    }
  }

  async save() {
    let savable = this.helperService.deepCopy(this.selectedRole);

    let companyId = this.helperService.currentCompanyId;

    let saveResult = await this.roleService.save(savable, companyId)

    if(saveResult.success){
      this.notifierService.success('Successfully saved Role');
      this.roleChange();
      this.idChange();

      //check other companies
      await this.checkOtherCompanies();
    } else {
      this.notifierService.brokenRules(saveResult.brokenRules)
      console.log(JSON.stringify(saveResult.message))
    }
    
    this.saving = false;
  }

  add() {
    let dialogRef = this.dialog.open(RoleAddDialogComponent, {data: {isCopy: false}});

    dialogRef.afterClosed().subscribe((role: models.Role) => {
      if(role) {
        this.roles.push(role);
        this.selectedRoleId = role.id;
        this.roleChange();
        this.idChange();
      }
    })
  }

  copy() {
    let dialogRef = this.dialog.open(RoleAddDialogComponent, {
      data: {
        isCopy: true,
        copyRole: this.selectedRole
      }
    });

    dialogRef.afterClosed().subscribe((role: models.Role) => {
      if(role) {
        this.roles.push(role);
        this.selectedRoleId = role.id;
        this.roleChange();
        this.idChange();
      }
    })
  }

  async delete() {
    this.deleting = true;
    
    let deleteResult = await this.roleService.delete(this.selectedRoleId)

    if(deleteResult.success){
      this.notifierService.success('Successfully deleted Role');
      this.selectedRoleId = null;
      this.getRoles();
    } else {
      this.notifierService.brokenRules(deleteResult.brokenRules)
      console.log(JSON.stringify(deleteResult))
    }
    
    this.deleting = false;
  }

  powerUserChecked(ob: MatCheckboxChange) {
    if(ob.checked) {
      this.selectedRole.permissions = this.permissionList.map(i => i.id);
      this.setSelectedNodes();
    }
  }

  async checkOtherCompanies() {
    let companyResponse = await this.roleService.getRoleCompaniesByUser(this.selectedRoleId);

    if(companyResponse.success) {
      // let otherCompanies = companyResponse.value.filter(i => i.uid != this.helperService.currentCompanyId);

      // let otherCompaniesWithRole: models.Company[] = [];
      // for (let i = 0; i < otherCompanies.length; i++) {
      //   const company = otherCompanies[i];
      //   let foundRole = await this.roleService.getByIdAndCompany(this.selectedRoleId, company.uid);
      //   if(foundRole != null) {
      //     otherCompaniesWithRole.push(company);
      //   }
      // }
      let otherCompaniesWithRole = companyResponse.value.filter(i => i.uid != this.helperService.currentCompanyId);

      if(otherCompaniesWithRole.length > 0) {
        this.openOtherCompaniesSelectDialog(otherCompaniesWithRole);
      }
    }
  }

  openOtherCompaniesSelectDialog(companies: models.Company[]) {
    let dialogRef = this.dialog.open(CompanySelectMultipleDialogComponent, {data: 
      {
        companies: companies,
        title: 'Migrate updated Role change(s) to your other Companies',
        initialSelectAll: true
      }
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if(response && response.confirm) {
        this.migrateRolesToCompanies(response.selectedCompanies)
      }
    })
  }

  async migrateRolesToCompanies(selectedCompanies: models.Company[]) {
    for (let i = 0; i < selectedCompanies.length; i++) {
      const selectedCompany = selectedCompanies[i];
    
      let savableRole = this.helperService.deepCopy(this.selectedRole);
      let roleAddResponse = await this.roleService.save(savableRole, selectedCompany.uid);
    }

    this.notifierService.success('Successfully updated other companies Roles')
  }

  migrateClick() {
    let dialogRef = this.dialog.open(CompanySelectDialogComponent, {data: 
      {
        companies: this.userCompanies.filter(i => i.uid != this.helperService.currentCompanyId),
        title: 'Migrate Roles from other Company'
      }
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if(response && response.confirm) {
        let selectedCompany: models.Company = response.selectedCompany;
        this.migrateRolesFromOtherCompany(selectedCompany.uid)
      }
    })
  }

  async migrateRolesFromOtherCompany(companyId: string) {
    this.loading = true;
    let roles = await this.roleService.get(companyId);

    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
    
      let savableRole = this.helperService.deepCopy(role);
      let roleAddResponse = await this.roleService.save(savableRole, this.helperService.currentCompanyId);
    }

    this.loading = false;
    this.getRoles();
    this.notifierService.success('Successfully migrated all Roles')
  }

}


/**
 * Node for to-do item
 */
export interface ItemNode {
  children?: ItemNode[];
  item?: string;
  id?: string;
  checked?: boolean;
}

/** Flat to-do item node with expandable and level information */
export class ItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  checked?: boolean;
}
