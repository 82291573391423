import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention'

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-mention-textbox',
  templateUrl: './mention-textbox.component.html',
  styleUrls: ['./mention-textbox.component.scss']
})
export class MentionTextboxComponent implements OnInit {

  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent

  selectable: Array<SelectableMention> = [];

  contentValue: string;
  @Input()
  get content() {
    return this.contentValue;
  }
  @Output() contentChange = new EventEmitter();
  set content(val) {
    this.contentValue = val;
    this.contentChange.emit(this.contentValue);
  }

  @Input() placeholder: string
  @Input() hintInput: string = '';

  modules: any;

  get hint () {
    return this.hintInput + "Type '@' to tag a colleague or team"
  }
  
  constructor(
    private userService: services.UserService
    , private teamService: services.TeamService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.setMention();
    this.getUsers();
    this.getTeams();
  }

  async getUsers() {
    let companyId: string = this.helperService.currentCompanyId;
    let foundUsers: models.User[] = await this.userService.getUsersForCompanyWithCaching(companyId, true, true)
    
    let users: Array<SelectableMention> = []
    foundUsers.forEach(user => {
      let obj: SelectableMention = {
        id: 'user_' + user.id,
        value: user.displayName
      }
      users.push(obj);
    });
    this.selectable.push(...users);
  }

  async getTeams() {
    let foundTeams: models.Team[] = await this.teamService.getAllModels()
    
    let teams: Array<SelectableMention> = []
    foundTeams.forEach(team => {
      let obj: SelectableMention = {
        id: 'team_' + team.uid,
        value: team.name
      }
      teams.push(obj);
    });
    this.selectable.push(...teams);
  }

  setMention() {
    this.modules = {
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        showDenotationChar: false,
        defaultMenuOrientation: 'bottom',
        mentionDenotationChars: ["@"],
        //linkTarget: '_self',
        onSelect: (item, insertItem) => {
          const editor = this.editor.quillEditor
          insertItem(item)
          // necessary because quill-mention triggers changes as 'api' instead of 'user'
          editor.insertText(editor.getLength() - 1, '', 'user')
        },
        source: (searchTerm, renderList, mentionChar) => {
          let values: any[];

          if (mentionChar === "@") {
            values = this.selectable;
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm)
          } else {
            searchTerm = searchTerm.toLowerCase();
            let data = values.filter(i => i.value.toLowerCase().includes(searchTerm));
            renderList(data, searchTerm)
          }
        },
        // renderItem: (item, searchTerm) => {
        //   return item.lastName + ', ' + item.firstName;
        // }
      },
      toolbar: false
    };
  }

}

interface SelectableMention {
  id?: string;
  value?: string;
}