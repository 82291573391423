<h2 mat-dialog-title>Select Users</h2>
<mat-dialog-content>
    <form id="selectUser" (ngSubmit)="submit()">
      <mat-form-field>
          <mat-label>Select a User</mat-label>
          <mat-select [(value)]="selectedUserId">
          <mat-option [value]="u.id" *ngFor="let u of users">{{u.displayName}}</mat-option>
          </mat-select>
      </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="selectUser">OK</button>
</mat-dialog-actions>