import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

import * as models from '../../models';

@Component({
    selector: "progress-bar-cell",
    template: `
    <div *ngIf="displayProgress" class="progress" style="width: 100%; margin-top: 5px;">
        <div class="progress-bar" role="progressbar" [style.width]="percentOfEventsCompletedString" style="background: linear-gradient(60deg, #66bb6a, #43a047);" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentOfEventsCompleted | number:'1.0-0'}}%</div>
    </div>
    <mat-icon style="color: #43a047; font-size: 24px; margin-top: 0px;" *ngIf="!displayProgress && completed">check</mat-icon>
    `,
    styles: [`
        :host { 
            width: 100% 
        }

        ::ng-deep
        .mat-checkbox-layout {
            /* horizontally align the checkbox */
            width: 100%;
            display: inline-block !important;
            text-align: center;
            margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

            /* vertically align the checkbox when not using the ag-material theme - should be the same as the 
            rowHeight - cell padding
            (you can of course alter the cell padding via CSS instead if you'd prefer)
            line-height: 42px;
            */
        }
    `]
})
export class ProgressBarCellComponent implements ICellRendererAngularComp {
    private params: any;

    completed: boolean;

    displayProgress: boolean = false;
    completedTasks: number;
    totalTasks: number;

    get percentOfEventsCompleted() {
      return (this.completedTasks / this.totalTasks) * 100;
    }
    get percentOfEventsCompletedString() {
      return this.percentOfEventsCompleted + "%";
    }

    constructor() { }

    agInit(params: any): void {
        if (params.value == null) {
            return;
        }
        this.params = params;
        this.completed = this.params.data.completed;

        if(this.params.data.completedTasks && this.params.data.totalTasks){
            this.displayProgress = true;
            this.completedTasks = this.params.data.completedTasks;
            this.totalTasks = this.params.data.totalTasks;
        }

    }

    refresh(params: any): boolean {
        return false;
    }
}