<h2 id="template_add_title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-select placeholder="Select Companies" [formControl]="selectedCompanies" multiple>
                    <mat-option disabled="disabled" class="filter-option">
                        <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll()">
                            Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="companyChange([])">
                            Deselect All
                        </button>
                    </mat-option>
                    <mat-select-trigger>
                        <ng-container *ngIf="selectedCompanies.value?.[0] == null">
                            None Selected
                        </ng-container>
                        <ng-container *ngIf="selectedCompanies.value?.[0] != null">
                            {{selectedCompanies.value?.[0].name || ''}}
                            <span *ngIf="(selectedCompanies.value?.length || 0) > 1" class="example-additional-selection">
                                (+{{(selectedCompanies.value?.length || 0) - 1}} {{selectedCompanies.value?.length === 2 ? 'other' :
                                'others'}})
                            </span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">
        No
    </button>
    <button mat-button (click)="yesClick()">
        Yes
    </button>
</mat-dialog-actions>