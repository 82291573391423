<h2 mat-dialog-title class="dialog-title">
    Select Unmatched Dutchie Batch
</h2>
<mat-dialog-content>
    <form id="selectedBatch" (ngSubmit)="submit()">
      <mat-form-field>
          <mat-label>Select a Batch</mat-label>
          <mat-select [(value)]="selectedBatch">
            <mat-option [value]="b" *ngFor="let b of batches">{{b.batchName}}</mat-option>
          </mat-select>
          <mat-spinner matSuffix *ngIf="loading" diameter=25 strokeWidth=4></mat-spinner>
      </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" form="selectedBatch" [disabled]="selectedBatch == null">Select</button>
</mat-dialog-actions>
