<div class="row" *ngIf="edit == true">
    <div class="col-12">
        <h3 style="float: left;">Zones</h3>
        <!-- <div style="float: right;">
            <button mat-raised-button class="pad-btn" title="Add Strain" (click)="addStrain()">
                <mat-icon aria-hidden="false" aria-label="Add Strain">add</mat-icon>
            </button>
        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <div class="row" *ngIf="edit == true">
            <div class="col-12" style="padding-bottom: 10px;">
                <div style="float: right;">
                    <button *ngIf="canSeeLeaflogix" mat-raised-button class="pad-btn" [disabled]="refreshingZones" title="Refresh Zones" (click)="refreshZonesClick()">
                        <i class="fa fa-spinner fa-spin" *ngIf="refreshingZones"></i>
                        <span *ngIf="!refreshingZones">Sync with Dutchie</span>
                    </button>
                    <button class="gridbtn" mat-raised-button (click)="setGroups()" title="Add Zone">
                        Set Zone Groups
                    </button>
                    <button class="gridbtn" mat-raised-button *ngIf="zoneObject?.zoneTitles?.length > 0" (click)="addZone()" title="Add Zone">
                        <mat-icon aria-hidden="false" aria-label="Add Zone">add</mat-icon>
                    </button>
                    <button class="gridbtn" mat-raised-button [disabled]="!dirty" (click)="save()" title="Save Zone">
                        <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
                        <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Zone">save</mat-icon>
                    </button>
                    <button class="gridbtn" mat-raised-button [disabled]="!dirty" (click)="get()" title="Undo">
                        <mat-icon aria-hidden="false" aria-label="Undo">undo</mat-icon>
                    </button>
                    <button mat-button (click)="info()">
                        <mat-icon>info</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row sticky-header" style="padding-bottom: 10px;" *ngIf="edit == false">
            <div class="col-sm-3" *ngFor="let s of strains">
                {{s.strainName}}: {{getPlacementCountForStrain(s)}}/{{s.count}}
            </div>
        </div>
        <div class="row" style="padding-bottom: 10px;" *ngIf="edit == true && zoneObject?.zoneTitles?.length == 0">
            <div class="col-12">
                <app-zone-explanation></app-zone-explanation>
            </div>
        </div>
        <div class="row" *ngIf="zoneObject?.zoneTitles?.length > 0">
            <div class="col-12">
                <table class="fixed_header">
                    <tr class="table_header">
                        <th *ngFor="let col of columns">{{col.headerName}}</th>
                    </tr>
                    <tr class="table_body" *ngFor="let row of zoneDisplays" [style.border-top]="borderTopStyle(row)">
                        <ng-container *ngIf="edit == true">
                            <td *ngFor="let col of columns" [class.editable-cell]="class(row[col.field])" (click)="zoneClick(row[col.field])">
                                <span *ngIf="row[col.field] != null">{{row[col.field].name}} <span style="font-size: 14px; color: #43a047">({{row[col.field].placements}})</span></span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="edit == false">
                            <td *ngFor="let col of columns; let i = index">
                                <ng-container *ngIf="row[col.field] != null">
                                    <mat-checkbox style="margin-right: 10px;" [ngModel]="row[col.field].checked" (ngModelChange)="checkChange(row[col.field], i, $event)">{{row[col.field].name}} <span style="font-size: 14px;">({{row[col.field].placements}})</span></mat-checkbox>
                                    
                                    <div *ngIf="row[col.field].checked" style="display: inline-block;">
                                        <div style="display: flex;" *ngFor="let strain of row[col.field].strains">
                                            <mat-form-field style="flex: 40%;">
                                                <mat-select [(ngModel)]="strain.id">
                                                    <mat-option *ngFor="let s of strains" [value]="s.strainId">
                                                        {{ s.strainName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field style="flex: 20%; max-width: 75px">
                                                <input matInput type="number" [(ngModel)]="strain.count" (ngModelChange)="countValueChange(row[col.field].strains, strain, $event, row[col.field].placements)">
                                            </mat-form-field>
                                            <button type="button" mat-icon-button style="flex: 20%;" *ngIf="row[col.field].strains.length < strains.length" (click)="add(row[col.field].strains, row[col.field].placements)" title="Add Strain">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                            <button type="button" mat-icon-button style="flex: 20%;" *ngIf="row[col.field].strains.length > 1" (click)="remove(row[col.field].strains, strain)" title="Remove Strain">
                                                <mat-icon>remove</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </table>
                <!-- <ag-grid-angular 
                    style="width: 100%; min-height:400px; height: calc(100vh - 300px);" 
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="zoneDisplays"
                    [columnDefs]="columns"
                    (selectionChanged)="onSelectionChanged()">
                </ag-grid-angular> -->
            </div>
        </div>
    </div>
    <!-- <div class="col-sm-6">
        <div class="row">
            <div class="col-12" style="padding-bottom: 10px;">
                <div style="float: right;">
                    <button class="gridbtn" mat-raised-button *ngIf="zone != null" (click)="save()" title="Save Zone">
                        <mat-icon aria-hidden="false" aria-label="Save Zone">save</mat-icon>
                    </button>
                    <button class="gridbtn" mat-raised-button *ngIf="zone != null" (click)="getAll()" title="Undo">
                        <mat-icon aria-hidden="false" aria-label="Undo">undo</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="zone != null">
            <div class='col-12'>
                <mat-form-field>
                    <input matInput class="zone-name" [(ngModel)]="zone.name" placeholder="Name">
                </mat-form-field>
            </div>
        </div>
    </div> -->
</div>
