<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
          <a id="page_title" class="navbar-brand" href="#">{{getTitle()}}</a>
        </div>
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <form class="navbar-form">
                <div class="input-group no-border">
                    <!-- <input type="text" value="" class="form-control" placeholder="Search..."> -->
                    <!-- <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        Take Tour
                        <div class="ripple-container"></div>
                    </button> -->
                    <button class="upgrade-fab" mat-fab color="primary" (click)="docs()">
                        <span class="extended-fab-button__text">Documentation</span>
                    </button>
                    <button class="upgrade-fab" mat-fab color="warn" (click)="askQuestion()">
                        <!-- <mat-icon>upgrade</mat-icon>  *ngIf="visible" (click)="upgrade()"-->
                        <span class="extended-fab-button__text">Questions?</span>
                    </button>
                </div>
            </form>
            <ul class="navbar-nav" style="padding-left: 10px;">
                <!-- <li class="nav-item" *ngIf="displayDashboard">
                    <a class="nav-link" [routerLink]="['/console/dashboard']">
                        <i class="material-icons">dashboard</i>
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li> -->
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#">Mike John responded to your email</a>
                        <a class="dropdown-item" href="#">You have 5 new tasks</a>
                        <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                        <a class="dropdown-item" href="#">Another Notification</a>
                        <a class="dropdown-item" href="#">Another One</a>
                    </div>
                </li> -->
                <li class="nav-item">
                    <a class="userprofile" routerLink="/console/profile">{{ user?.displayName }}</a>
                </li>
                <li class="nav-item dropdown">
                    <app-alerts-icon></app-alerts-icon>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" style="padding: 10px 5px;" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i matBadge="0" [matBadgeHidden]="!showReleaseNotification" class="material-icons hide-text">settings</i>
                        <p>
                            <span class="d-lg-none d-md-block">Settings</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <!-- <a class="dropdown-item" href="#">Mike John responded to your email</a> -->
                        <a *ngIf="canSelectOtherCompany" class="dropdown-item" routerLink="/company-select">Select other Company</a>
                        <a *ngIf="canSeeCompanyManagement" class="dropdown-item" routerLink="/console/company-settings">Edit Company Settings</a>
                        <a class="dropdown-item" routerLink="/console/processes">Processes</a>
                        <!-- <a class="dropdown-item" (click)="signOut()">Sign Out</a> -->
                        <a class="dropdown-item" routerLink="/console/profile">Profile</a>
                        <a class="dropdown-item" href="https://planacan.io/release-notes/" target="_blank">Release Notes <span class="new-flag" *ngIf="showReleaseNotification">{{version}}</span></a>
                        <a class="dropdown-item" *ngIf="canSeeSystemManagement" routerLink="/console/system-management">System Management</a>
                        <!-- <div>
                            Signed in as Michael Rast (mikey.rast@gmail.com)
                        </div> -->
                    </div>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#pablo">
                        <i class="material-icons">person</i>
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>

<!--

<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="#">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="#pablo" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="#">Mike John responded to your email</a></li>
                        <li><a href="#">You have 5 new tasks</a></li>
                        <li><a href="#">You're now friend with Andrew</a></li>
                        <li><a href="#">Another Notification</a></li>
                        <li><a href="#">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#pablo" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav> -->
