import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';

@Injectable()
export class AgGridPinningService {

  constructor(
    private helperService: HelperService
  ) { }

  async setPinnedData(gridApi: any, columns: Array<string>, totalColumns: Array<string>, moneyColumns: Array<string>, numberColumns: Array<string>) {
    let row = {};

    await this.helperService.wait(10);

    let rowData = [];
    gridApi.forEachNodeAfterFilter(node => {
      rowData.push(node.data);
    });
    for (let i = 0; i < columns.length; i++) {
      const element = columns[i];

      if(totalColumns.includes(element)){
        row[element] = `Total: ${rowData.length}`;
      } else if (moneyColumns.includes(element)) {
        var total = 0;
        for (let j = 0; j < rowData.length; j++) {
          total += rowData[j][element];
        }
        let totalFormatted = total.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        row[element] = `${totalFormatted}`;
      } else if (numberColumns.includes(element)) {
        var total = 0;
        for (let j = 0; j < rowData.length; j++) {
          total += +rowData[j][element] || 0;
        }
        row[element] = `${(+(total).toFixed(2)).toLocaleString()}`;
      } else {
        row[element] = undefined;
      }
    }

    gridApi.setPinnedBottomRowData([row])
  }

  pinnedRowStyle(params) {
    if(params.value == undefined){
      return params.value;
    }

    return `<b>${params.value}</b>`
  }
}
