<h2 mat-dialog-title class="dialog-title">
    Apply Workflow
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content>
    <div class="row" *ngIf="showGroupTab">
        <div class="col-12">
          <div class="toggleForm">
            <ng-template matStepLabel>Harvest/Task Group</ng-template>
            <mat-button-toggle-group class="center" [(ngModel)]="applyToGroup">
              <mat-button-toggle [value]="false">Harvest</mat-button-toggle>
              <mat-button-toggle [value]="true">Task Group</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
    </div>

    <ng-container>
        <form class="apply-workflow-form" id="applyWorkflowForm" (ngSubmit)="applyWorkflow()">
            <ng-container *ngIf="!applyToGroup">
                <app-harvest-select 
                    [includeCompleted]="false" 
                    [(harvestId)]="harvestId" 
                    [(harvest)]="harvest"
                    [disabled]="!showGroupTab">
                </app-harvest-select>
            </ng-container>
    
            <ng-container *ngIf="applyToGroup && showGroupTab">
                <app-group-select 
                    [(groupId)]="groupId" 
                    [(group)]="group">
                </app-group-select>
            </ng-container>

            <ng-container>
                <app-workflow-select 
                    [(workflowId)]="workflowId" 
                    [(workflow)]="workflow"
                    (workflowIdChange)="onWorkflowIdChange()"
                >
                </app-workflow-select>
            </ng-container>

            <mat-form-field *ngIf="workflowId && workflowId !== 'null'">
                <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" [formControl]="startDate"
                  disabled="true" class="workflow-start-date">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
            </mat-form-field>
        </form>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close [disabled]="applyingWorkflow">Cancel</button>
    <button mat-button type="submit" form="applyWorkflowForm" [disabled]="applyingWorkflow">
      <mat-spinner *ngIf="applyingWorkflow" diameter=25 strokeWidth=4></mat-spinner>
      <span *ngIf="!applyingWorkflow">Save</span>
    </button>
</mat-dialog-actions>