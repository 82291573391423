import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-zone-explanation',
  templateUrl: './zone-explanation.component.html',
  styleUrls: ['./zone-explanation.component.scss']
})
export class ZoneExplanationComponent implements OnInit {

  @Input() inPopup: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
