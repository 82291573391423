import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventAddComponent } from '../event-add/event-add.component';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-event-add-from-library-dialog',
  templateUrl: './event-add-from-library-dialog.component.html',
  styleUrls: ['./event-add-from-library-dialog.component.scss']
})
export class EventAddFromLibraryDialogComponent implements OnInit {

  saving: boolean = false;

  // needToPick: boolean = true;
  // needToPickDate: boolean = false;
  // selectedHarvestId: string;
  // selectedTaskGroupId: string;
  date: Date;

  assignToGroup: boolean = false;
  selectedTemplate: models.TemplatedTask;

  phase: string;

  constructor(
    private dialogRef: MatDialogRef<EventAddFromLibraryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inData: any,
    private notifierService: services.NotifierService,
    private dialog: MatDialog
  ) { 
    if(inData) {
      // this.selectedHarvestId = data.selectedHarvestId;
      // this.selectedTaskGroupId = data.selectedTaskGroupId;
      // this.needToPick = this.selectedHarvestId == null || this.selectedTaskGroupId == null;
      this.date = inData.date;
      //this.needToPickDate = this.date == null;
    }
  }

  ngOnInit(): void {
  }

  continue() {
    if(this.selectedTemplate == null) {
      this.notifierService.error('Must select a Templated Task in order to continue');
      return;
    }

    let inObj: any = {
      date: this.date,
      harvestId: this.inData.harvestId,
      groupId: this.inData.groupId,
      templatedTask: this.selectedTemplate,
      addFromGroupDetail: this.inData.groupId != null,
    }
    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: inObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(result);
      }
    })
  }

}
