import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "harvest-name-cell",
    template: `
        <div style="display: flex">
            <span class="circle" [ngStyle]="{'background-color': color ? color : '#0066FF', 'border-radius': borderRadius}"></span>
            <span>{{value}}</span>
        </div>
    `,
    styles: [
        `
        .circle {
            display: inline-block;
            margin-top: 5px;
            margin-right: 7px;
            width: 15px;
            height: 15px;
            margin-top: 5px;
            margin-right: 7px;
        }
        `
    ]
})
export class NameColorCellComponent implements ICellRendererAngularComp {
    private params: any;

    value: string;
    color: string;
    isHarvest: boolean = false;

    get borderRadius() {
        if(this.isHarvest) {
            return '50%'
        }else {
            return '0%'
        }
    }

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        this.color = this.params.color;
        this.isHarvest = this.params.isHarvest;
    }

    refresh(params: any): boolean {
        return false;
    }
}
