import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import * as viewModels from 'app/viewmodels';
import { HelperService } from './helper.service';

@Injectable()
export class SensorService {

    companyRef: AngularFirestoreCollection;
    collectionId: string = 'grow-settings';
    itemId: string = 'sensor';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    async getAll(): Promise<Array<models.Sensor>> {
        let companyId = this.helperService.currentCompanyId;
        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbSensor: models.DbSensor = await (await response.get().toPromise()).data();

        return dbSensor?.list || [];
    }

    update(sensors: Array<models.Sensor>) {

        sensors.forEach(sensor => {
            if(sensor.id == null){
                sensor.id = this.firestore.createId()
            }
        });

        sensors = this.helperService.removeEmptyFromList(sensors);

        let dbSensor: models.DbSensor = {
            list: sensors
        }

        let companyId = this.helperService.currentCompanyId;
        return this.firestore.collection('companies').doc(companyId)
                .collection(this.collectionId).doc(this.itemId).set(dbSensor);
    }

    async import(importRows: Array<viewModels.SensorExport>): Promise<void> {
        let sensors = await this.getAll();

        importRows.forEach(importRow => {
            if(sensors.find(i => i.sensorNumber == importRow.sensorNumber) == null){
                sensors.push(Object.assign({}, importRow));
            }
        });

        await this.update(sensors);
    }
}
