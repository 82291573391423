<app-header [perminantBackground]="true" [displayLogin]="false"></app-header>


<section class="clearfix" style="padding-top: 50px;">

    <div class="loader" *ngIf="loading">
        <!-- <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg> -->
        <img src="assets/img/Loading_Logo.gif" class="loadingImg" />
    </div>

    <ng-container *ngIf="!loading">
        <div class="container h-100" *ngIf="!loading && link == null && errorCode != 'link_expired' && errorCode != 'resent_link'">
            <h2 style="text-align: center; color: green">Link Invalid</h2>
            <br />
            <h4 style="text-align: center; color: green">
                This link is invalid.  Proceed to the <a href="/login">Login</a> screen to access the application or to reset your password.
            </h4>
        </div>
        <div class="container h-100" *ngIf="errorCode == 'link_expired'">
            <h2 style="text-align: center; color: green">Link Expired</h2>
            <br />
            <h4 style="text-align: center; color: green">
                This link has been expired for security reasons.
            </h4>
        </div>
        <div class="container h-100" *ngIf="!loading && link != null">
            <h1 style="text-align: center; color: green">Would you like to join {{link?.companyName}}?</h1>
            <br />
            <h3 style="text-align: center; color: green">You have been invited to join {{link?.companyName}}. Please click join below, and your account will be granted access.</h3>
            <br />
            <div class="center">
                <button type="button" mat-raised-button (click)="submit()">
                    <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
                    <span style="font-weight: bold;" *ngIf="!saving">Join</span>
                </button>
            </div>
            <br />
            <h3 style="text-align: center; color: green">If you don’t know why you’re receiving this message, please email us at info@planacan.io.</h3>
            <br />
        </div>
    </ng-container>

</section>

<app-footer></app-footer>