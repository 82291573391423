import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgModel, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as models from '../../models';
import * as services from '../../services';
import * as viewModels from 'app/viewmodels';

import { SessionStorageService } from 'angular-web-storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { Timestamp } from '@angular/fire/firestore';
import { HttpsCallableResult } from 'firebase/functions';

@Component({
  selector: 'app-users-assign',
  templateUrl: './users-assign.component.html',
  styleUrls: ['./users-assign.component.scss']
})
export class UsersAssignComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  assigningGroup: FormGroup;
  selectUsersFormGroup: FormGroup;
  selectHarvestsFormGroup: FormGroup;
  selectTasksFormGroup: FormGroup;
  confirmFormGroup: FormGroup;

  assignChange(value) {
    this.stepper.next();
  }

  async taskTypeChange(value) {
    if(value == 'tags'){
      let events: Array<models.Event> = [];

      let harvestIds = this.harvests.map(i => i.uid);
      if(harvestIds.length > 0){
        let harvestEvents = await this.eventService.getByHarvestIdList(harvestIds);
        events.push(...harvestEvents);
      }
      
      let groupIds = this.groups.map(i => i.uid);
      if(groupIds.length > 0){
        let groupEvents = await this.eventService.getByGroupIdList(groupIds);
        events.push(...groupEvents);
      }
      
      let list = [];
      events.forEach(event => {
        if(event.tags != null){
          list.push(...event.tags);
        }
      });

      let tags = [...new Set(list)].sort();
      
      this.allTags = tags.map(i => {
        let vm: viewModels.ViewModel = {
          id: i,
          name: i
        }
        return vm;
      })
    }
  }
  
  assigning: boolean = false;

  users: Array<models.User> = [];
  teams: Array<models.Team> = [];
  harvests: Array<models.Harvest> = [];
  groups: Array<models.Group> = [];
  phases: Array<string> = [];

  startDate: FormControl = new FormControl();
  endDate: FormControl = new FormControl();

  allTags: Array<viewModels.ViewModel> = [];
  tags: Array<string> = [];

  descriptionItems: Array<descriptionItem> = [];

  get displayUsers(): boolean { return this.descriptionItems.filter(i => i.user != null).length > 0; }
  get displayTeams(): boolean { return this.descriptionItems.filter(i => i.team != null).length > 0; }
  get displayHarvests(): boolean { return this.descriptionItems.filter(i => i.harvest != null).length > 0; }
  get displayGroups(): boolean { return this.descriptionItems.filter(i => i.group != null).length > 0; }
  get displayPhases(): boolean { return this.descriptionItems.filter(i => i.phase != null).length > 0; }
  get displayDates(): boolean { return this.descriptionItems.filter(i => i.date != null).length > 0; }
  get displayTags(): boolean { return this.descriptionItems.filter(i => i.tag != null).length > 0; }

  // get totalUserIds () {
  //   let userIds = this.users.map(i => i.id);
  //   userIds = userIds.concat(this.teams.map(i => i.userIds).reduce((a, b) => a.concat(b), [])) 
  //   userIds =  [...new Set(userIds)];
  //   return userIds;
  // }

  //assign: boolean = true; //whether to assign or remove users from tasks

  // get allUsers() {
  //   let teamUsers = this.teams.filter(i => i.users != null).map(i => i.users).reduce((a, b) => a.concat(b), [])
  //   let names = this.users.map(i => i.displayName);
  //   names = names.concat(teamUsers.map(i => i.displayName));
  //   let uniqueNames = [...new Set(names)];
  //   return uniqueNames;
  // }

  get userNames() {
    return this.helperService.concatToSentence(this.users.map(i => i.displayName));
  }

  get teamNames() {
    return this.helperService.concatToSentence(this.teams.map(i => i.name));
  }

  get harvestNames() {
    let names = this.harvests.map(i => i.name);
    return this.helperService.concatToSentence(names);
  }

  get groupNames() {
    let names = this.groups.map(i => i.name);
    return this.helperService.concatToSentence(names);
  }

  get phaseNames() {
    return this.helperService.concatToSentence(this.phases);
  }

  get tagSentence() {
    return this.helperService.concatToSentence(this.tags);
  }

  get description () {
    let ret = '';
    if(this.assigningGroup.get('assigning').value){
      ret += 'Assigning ';
    } else {
      ret += 'Unassigning ';
    }

    if(this.users.length > 0){
      ret += `${this.users.length} ${this.users.length == 1 ? 'User' : 'Users'} `;
    }

    if(this.users.length > 0 && this.teams.length > 0){
      ret += 'and ';
    }

    if(this.teams.length > 0){
      ret += `${this.teams.length} ${this.teams.length == 1 ? 'Team' : 'Teams'} `;
    }
    
    ret += 'to ';
    if(this.harvests.length == 0 && this.groups.length == 0){
      return '';
    } else if(this.harvests.length > 0 && this.groups.length == 0){
      ret += `${this.harvests.length} ${this.harvests.length == 1 ? 'Harvest' : 'Harvests'} `;
    } else if (this.harvests.length == 0 && this.groups.length > 0) {
      ret += `${this.groups.length} ${this.groups.length == 1 ? 'Group' : 'Groups'} `;
    } else {
      ret += `${this.harvests.length} ${this.harvests.length == 1 ? 'Harvest' : 'Harvests'} and ${this.groups.length == 1 ? 'Group' : 'Groups'} `;
    }

    let taskType = this.selectTasksFormGroup.get('taskType').value
    if(taskType == null){
      return '';
    } 
    
    if(taskType == 'phase'){
      ret += `in ${this.phases.length} ${this.phases.length == 1 ? 'Phase' : 'Phases'}`;
    } else if (taskType == 'dateRange'){
      let start = moment(this.startDate.value).format('l');
      let end = moment(this.endDate.value).format('l');
      ret += `between ${start} and ${end}`;
    }

    return ret;
  }

  constructor(
    private dialogRef: MatDialogRef<UsersAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private harvestService: services.HarvestService
    , private groupService: services.GroupService
    , private userService: services.UserService
    , private eventService: services.EventService
    , private storage: SessionStorageService
    , private snackbar: MatSnackBar
    , private helperService: services.HelperService
    , private _formBuilder: FormBuilder
    , private notifierService: services.NotifierService
  ) { }

  ngOnInit(): void {
    this.assigningGroup = this._formBuilder.group({
      assigning: [null, Validators.required]
    });
    this.selectUsersFormGroup = this._formBuilder.group({
      //selectedUsers: [[], Validators.required]
    });
    this.selectHarvestsFormGroup = this._formBuilder.group({
      //selectedUsers: [[], Validators.required]
    });
    this.selectTasksFormGroup = this._formBuilder.group({
      taskType: [null, Validators.required]
    });
    this.confirmFormGroup = this._formBuilder.group({});

    //this.getGroups();
    //this.getUsers();
  }

  // toggleChange(newVal) {
  //   if(newVal && newVal.checked){
  //     this.assign = true;
  //   } else {
  //     this.assign = false;
  //   }
  // }

  async stepChange(event){
    if(event.selectedIndex == 4){
      this.teams = await this.setUsers(this.teams);
      this.setDescriptionItems();
    }
  }

  async setUsers(teams: Array<models.Team>){
    let userIds = this.teams.map(i => i.userIds).reduce((a, b) => a.concat(b), [])
    let uniqueUserIds = [...new Set(userIds)];
    let users = await this.userService.getUserByIdList(uniqueUserIds);
    
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];

      if(team.userIds == null){
        continue;
      }

      team.users = users.filter(i => team.userIds.includes(i.id));
    }

    return teams;
  }

  setDescriptionItems() {
    let descriptionItems: Array<descriptionItem> = [];

    let first: descriptionItem = {
      assign: this.assigningGroup.get('assigning').value == 'true' ? 'Assigning' : 'Unassigning',
    }
    descriptionItems.push(first);

    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i];
      let item = descriptionItems[i];
      let needToAdd = false;
      if(item == null){
        item = {};
        needToAdd = true
      }
      item.user = user.displayName;
      if(needToAdd){
        descriptionItems.push(item);
      }
    }

    for (let i = 0; i < this.teams.length; i++) {
      const team = this.teams[i];
      let item = descriptionItems[i];
      let needToAdd = false;
      if(item == null){
        item = {};
        needToAdd = true
      }
      item.team = team.name;
      if(needToAdd){
        descriptionItems.push(item);
      }
    }

    for (let i = 0; i < this.harvests.length; i++) {
      const harvest = this.harvests[i];
      let item = descriptionItems[i];
      let needToAdd = false;
      if(item == null){
        item = {};
        needToAdd = true
      }
      item.harvest = harvest.name;
      if(needToAdd){
        descriptionItems.push(item);
      }
    }

    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];
      let item = descriptionItems[i];
      let needToAdd = false;
      if(item == null){
        item = {};
        needToAdd = true
      }
      item.group = group.name;
      if(needToAdd){
        descriptionItems.push(item);
      }
    }


    let taskType = this.selectTasksFormGroup.get('taskType').value
    if(taskType == null){
      return;
    } 
    
    if(taskType == 'phase'){
      for (let i = 0; i < this.phases.length; i++) {
        const phase = this.phases[i];
        let item = descriptionItems[i];
        let needToAdd = false;
        if(item == null){
          item = {};
          needToAdd = true
        }
        item.phase = phase;
        if(needToAdd){
          descriptionItems.push(item);
        }
      }
    } else if (taskType == 'dateRange'){
      let start = moment(this.startDate.value).format('l');
      let end = moment(this.endDate.value).format('l');
      descriptionItems[0].date = `between ${start} and ${end}`;
    } else if (taskType == 'tags'){
      for (let i = 0; i < this.tags.length; i++) {
        const tag = this.tags[i];
        let item = descriptionItems[i];
        let needToAdd = false;
        if(item == null){
          item = {};
          needToAdd = true
        }
        item.tag = tag;
        if(needToAdd){
          descriptionItems.push(item);
        }
      }
    }

    this.descriptionItems = descriptionItems;

    this.descriptionItems.filter(i => i.user != null).length > 0
  }

  async process() {
    //validation
    if(this.phases.length > 10) {
      this.notifierService.error('Cannot mass assign to more than 10 phases at once');
      return;
    }
    if(this.tags.length > 10) {
      this.notifierService.error('Cannot mass assign to more than 10 tags at once');
      return;
    }

    this.assigning = true;
    
    let harvestIds = this.harvests.map(a => a.uid);
    let groupIds = this.groups.map(a => a.uid);
    //let userIds = this.selectedUsers.map(a => a.id);

    let assigning = this.assigningGroup.get('assigning').value == 'true';

    //let phases: Array<string>;
    let startDate: Timestamp = this.startDate.value != null ? Timestamp.fromDate(new Date(this.startDate.value)) : null;
    let endDate: Timestamp = this.endDate.value != null ? Timestamp.fromDate(new Date(this.endDate.value)) : null;

    // let taskType = this.selectTasksFormGroup.get('taskType').value;
    // if(taskType == 'phase'){
    //   phases = this.phases;
    // } else if (taskType == 'dateRange'){
      
    // }

    try {

      let result: HttpsCallableResult<models.Result<any>> = await this.eventService.assignUsersToEvents(harvestIds, groupIds, this.users.map(i => i.id), this.teams.map(i => i.uid), assigning, startDate, endDate, this.phases, this.tags, this.selectTasksFormGroup.get('taskType').value);

      if(result.data.success){
        this.snackbar.open(result.data.message);
        this.dialogRef.close();
      } else {
        this.snackbar.open(result.data.message);
      }
    } catch(ex){
      this.snackbar.open('There was an error that occurred processing your request.');
    }

    this.assigning = false;
  }

}

export interface descriptionItem {
  assign?: string;
  user?: string;
  team?: string;
  harvest?: string;
  group?: string;
  phase?: string;
  date?: string;
  tag?: string;
}