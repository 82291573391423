<app-header [perminantBackground]="true" [displayLogin]="true" [displayGoToConsole]="false"></app-header>

<section class="clearfix" style="padding-top: 50px">
  <div class="container h-100">
    <h2 style="text-align: center; color: green">No Access to PlanaCan</h2>
    <br />
    <h4 style="text-align: center; color: green">
      You do not have access to PlanaCan. Either contact your administrator to
      request access or email us at
      <a href="mailto:sales@planacan.io">sales@planacan.io</a> to set up a
      subscription.
    </h4>
  </div>
</section>

<app-footer></app-footer>


<!-- <div class="wrapper">
  <app-navbar></app-navbar>
  <div class="main-content">
    <div class="container-fluid">
      <div class="no-permissions-container">
        You do not have access to PlanaCan. Either contact your administrator to
        request access or email us at
        <a href="mailto:sales@planacan.io">sales@planacan.io</a> to set up a
        subscription.
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div> -->
