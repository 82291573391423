import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "high-priority-cell",
    template: `
        <mat-icon aria-hidden="false" style="vertical-align: middle" *ngIf="highPriority" aria-label="Example home icon">error_outline</mat-icon>

    `,
    styles: []
})
export class HighPriortyIconComponent implements ICellRendererAngularComp {
    private params: any;

    highPriority: boolean = false;

    agInit(params: any): void {
        this.params = params;
        this.highPriority = this.params.value;
    }

    refresh(params: any): boolean {
        return false;
    }
}
