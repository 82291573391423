import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as services from '../../services'
import * as models from '../../models';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit {

  creatingCompany: boolean = false;
  timezones: any;
  companyName: string;
  timezone: string;
  adminName: string;
  adminEmail: string;
  freeVersion: boolean = false;
  addUserToCompany: boolean = false;
  eventsSplit: boolean = false;

  constructor(public dialog: MatDialog,
  private dialogRef: MatDialogRef<CreateCompanyComponent>,
  private helperService: services.HelperService,
  private companyService: services.CompanyService,
  private authService: services.AuthService,
  private notifierService: services.NotifierService) { }

  ngOnInit(): void {
    this.timezones = this.helperService.getTimeZones();
  }

  valid(): boolean {
    if(this.adminEmail == null){
      this.notifierService.error('Must enter in email address');
      return false;
    }

    if(!this.helperService.emailIsValid(this.adminEmail)){
      this.notifierService.error('Must enter a valid email address');
      return false;
    }

    if(this.adminName == '' || this.adminName == null){
      this.notifierService.error('Must enter an admin name');
      return false;
    }

    return true;
  }

  createCompany() {
    if(this.addUserToCompany) {
      if(this.valid() && this.companyInfoCompleted()) {
        let dialogResult: models.CompanyCreate = {
          companyName: this.companyName,
          timezone: this.timezone,
          addUserToCompany: this.addUserToCompany,
          adminName: this.adminName,
          adminEmail: this.adminEmail,
          freeVersion: this.freeVersion,
          domain: this.helperService.getCurrentDomain(),
          eventsSplit: false
        };
        console.log(dialogResult);
        this.createNewCompany(dialogResult);
      };
      return;
    }
    else{
      if(this.companyInfoCompleted()){
        let dialogResult: models.CompanyCreate = {
          companyName: this.companyName,
          timezone: this.timezone,
          addUserToCompany: this.addUserToCompany,
          adminName: '',
          adminEmail: '',
          freeVersion: this.freeVersion,
          domain: this.helperService.getCurrentDomain(),
          eventsSplit: false
        }
        console.log(dialogResult)
        this.createNewCompany(dialogResult);
      };
    };
  }

  companyInfoCompleted() {
    if(this.companyName == '' || this.companyName == null) {
      this.notifierService.error('Must enter a company name')
      return false;
    }
    else if(this.timezone == '' || this.timezone == null) {
      this.notifierService.error('Please select a timezone');
      return false;
    }
    else {
      return true;
    };
  };


  async createNewCompany(dialogResponse: models.CompanyCreate) {

    this.creatingCompany = true;

    this.companyService.createDemoCompany(dialogResponse).then(data => {
      let result: models.Result<any> = data;

      if(result.success){
        this.notifierService.success(result.message)
        this.dialogRef.close();
      } else {
        this.notifierService.error(result.message)
      }
      this.creatingCompany = false;

    }).catch(error => {
      this.notifierService.error(error.error)
      console.log(JSON.stringify(error))
      this.creatingCompany = false;
    })
  };
}
