import { Role } from "./role";

export interface User {
    id?: string;

    displayName?: string;
    emailAddress?: string;
    phoneNumber?: string;
    disabled?: boolean;
    customClaims?: Array<string>;
    mustCompleteTour?: boolean;
    createDemoHarvest?: boolean;

    companyIds?: Array<string>;
    invitedCompanyIds?: Array<string>;

    roleId?: string;
    role?: Role;
}
