import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

import * as models from '../../models';
import * as services from 'app/services';

@Component({
    selector: "team-cell",
    template: `
        <span *ngIf="!bold">{{value}}</span>
        <b *ngIf="bold">{{value}}</b>
    `,
    styles: [
    ]
})
export class TeamCellComponent implements ICellRendererAngularComp {
    private params: any;

    value: string;
    bold: boolean = false;

    constructor(
        private helperService: services.HelperService
    ) { }

    agInit(params: any): void {
        if (!params.value) {
            this.value = 'No Teams'
            return;
        }

        this.params = params;
        let teams: models.Team[] = params.teams;
        let assignedTeamIds: string[] = params.value;

        if (assignedTeamIds.length == 0) {
            this.value = 'No Teams'
            return;
        }

        let selectedTeams = teams.filter(i => assignedTeamIds.includes(i.uid));

        this.value = this.helperService.concatToSentence(selectedTeams.map(i => i.name));
        this.bold = true;
    }

    refresh(params: any): boolean {
        return false;
    }
}