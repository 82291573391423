import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zone-explanation-dialog',
  templateUrl: './zone-explanation-dialog.component.html',
  styleUrls: ['./zone-explanation-dialog.component.scss']
})
export class ZoneExplanationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
