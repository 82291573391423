<div mat-dialog-title>
    <h3>Save Filter Set</h3>
</div>

<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <input matInput [(ngModel)]="filterSet.name" placeholder="Filter Set Name">
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <mat-checkbox [(ngModel)]="includeHarvests">
              Include Harvests
            </mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-checkbox [(ngModel)]="includeGroups">
              Include Task Groups
            </mat-checkbox>
        </div>
    </div> -->
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" [disabled]="saving">
        <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
        <span *ngIf="!saving">Save</span>
    </button>
</mat-dialog-actions>