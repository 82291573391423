<button mat-menu-item
        title="Shift Harvest"
        [disabled]="this.disabled"
        (click)="click()"
        *ngIf="canSee() && dropDownMenu">
        <mat-icon aria-hidden="false" aria-label="Shift Harvest">compare_arrows</mat-icon>
    <span>Shift Harvest</span>
</button>
<button class="gridbtn" mat-raised-button *ngIf="canSee() && !dropDownMenu" (click)="click()" title="Shift Harvest">
    <mat-icon aria-hidden="false" aria-label="Shift Harvest">compare_arrows</mat-icon>
</button>