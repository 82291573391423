import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

@Component({
    selector: "moment-cell",
    template: `{{ display }}`,
    styles: [
    ]
})
export class MomentComponent implements ICellRendererAngularComp {
    private params: any;

    display: string;

    constructor(){}

    agInit(params: any): void {
        if(params.value == null){
            return;
        }
        this.params = params;

        this.display = `${this.params.value.format('l')} ${this.params.value.format('LT')}`;
    }

    refresh(params: any): boolean {
        return false;
    }
}