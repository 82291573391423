import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "unit-cell",
    template: `
    <span *ngIf="anyTime">Anytime</span>
    <span style="font-weight: bold" *ngIf="!anyTime">{{startTime}}</span>`,
    styles: []
})
export class AnytimeCellComponent implements ICellRendererAngularComp {
    params: any;

    anyTime: boolean;
    startTime: string;

    agInit(params: any): void {
        this.params = params;
        this.anyTime = this.params.value;
        this.startTime = this.extractTime(this.params.data.startTime);
    }

    extractTime(time: string){
        if(time == null){
            return 'N/A';
        }
        let l = time.split(":");
        if(l.length != 2) {
            return 'N/A';
        }
        let ampm: string = 'am';
        let hour = +l[0];
        let min = l[1];
        if(hour == 0) {
            hour = 12;
            ampm = 'am';
        } else if(hour == 12) {
            ampm = 'pm';
        } else if(hour > 12){
            hour = hour - 12;
            ampm = 'pm';
        }

        return `${hour}:${min} ${ampm}`;
    }

    refresh(params: any): boolean {
        return false;
    }
}
