import { Component, OnInit, Inject } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as models from '../../models';
import * as services from '../../services';

@Component({
  selector: 'app-user-select-single',
  templateUrl: './user-select-single.component.html',
  styleUrls: ['./user-select-single.component.scss']
})
export class UserSelectSingleComponent implements OnInit {
  users: Array<models.User> = [];
  selectedUserId: string;

  constructor(
    private dialogRef: MatDialogRef<UserSelectSingleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: SessionStorageService,
    private snackBar: MatSnackBar,
    private userService: services.UserService,
    private helperService: services.HelperService
  ) {
    if(data !== ''){
      this.selectedUserId = data;
    }
  }

  ngOnInit() {
    this.getUsers();
  }

  submit() {
    let dialogResult = {
      submitted: true,
      data: this.selectedUserId
    }
    this.dialogRef.close(dialogResult)
  }

  async getUsers() {
    let companyId = this.helperService.currentCompanyId;
    let userResponse = await this.userService.getUsersFoCompany(companyId, true)

    if(!userResponse.data.success){
      this.snackBar.open('Error getting Users', null, {duration: 3000, verticalPosition: 'top'});
      return;
    }

    let nullUser: models.User = {
      id: undefined,
      displayName: 'No User'
    }
    this.users.push(nullUser);

    userResponse.data.value.forEach(element => {
      let user: models.User = element;
      user.id = element.id;
      this.users.push(user);
    });
  }
}
