
<mat-radio-group
  aria-label="Select an option"
  [class.group]="saveAsGenericGroup"
  [(ngModel)]="color"
>
  <mat-radio-button
    *ngFor="let c of colors"
    [className]="c.class"
    style="width: unset"
    [value]="c.value"
  ></mat-radio-button>
</mat-radio-group>

  <!-- name="color={{ i }}" -->