import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as services from '../../services';

@Component({
  selector: 'app-manage-billing-btn',
  templateUrl: './manage-billing-btn.component.html',
  styleUrls: ['./manage-billing-btn.component.scss']
})
export class ManageBillingBtnComponent implements OnInit {

  @Input() customerId: string;
  loading: boolean = false;

  constructor(
    //private squareService: services.SquareService
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  click() {
    this.router.navigate(['console', 'billing'])
    //this.loading = true;
    // this.stripeService.retrieveCustomerPortalSession(this.customerId).then(data => {
    //   window.location.href = data.data.url;
    //   this.loading = false;
    // })
  }
}
