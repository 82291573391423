import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as services from 'app/services';
import * as models from 'app/models';

@Component({
  selector: 'app-task-group-delete-btn',
  templateUrl: './task-group-delete-btn.component.html',
  styleUrls: ['./task-group-delete-btn.component.scss']
})
export class TaskGroupDeleteBtnComponent implements OnInit {

  @Input() group: models.Group;
  @Output() doneDeleting: EventEmitter<any> = new EventEmitter();

  deleteClick: boolean = false;

  constructor(
    private claimsService: services.ClaimsService
    , private groupService: services.GroupService
    , private snackBar: MatSnackBar
    ) { }

  ngOnInit() {
  }

  canSeeDelete() : boolean {
    if (this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteGroup)) {
      if(this.group != null && this.group.uid != ''){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    
  }

  delete() {
    console.log(JSON.stringify(this.group))
    let c = confirm(`Are you sure you want to delete this Task Group (${this.group.name})?`);
    if(!c){
      return;
    }

    this.deleteClick = true;

    this.groupService.deleteList([this.group.uid]).then(r => {

      let response: models.Result<any> = r.data;;
      
      this.snackBar.open(response.message, null, {
        duration: 3000,
        verticalPosition: 'top'
      });

      if(response.success){
        this.doneDeleting.emit();
      }

      this.deleteClick = false;
    }).catch(error => {
      this.snackBar.open(error.error, null, {
        duration: 3000,
        verticalPosition: 'top'
      });
      console.log(JSON.stringify(error))

      this.deleteClick = false;
    });
  }
}
