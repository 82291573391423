import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import * as models from '../models';
import * as viewModels from 'app/viewmodels';
import { HelperService } from './helper.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class StrainService {

    companyRef: AngularFirestoreCollection;
    collectionId: string = 'grow-settings';
    itemId: string = 'strains';

    constructor(
        private firestore: AngularFirestore,
        private helperService: HelperService,
    ) {
        this.companyRef = this.firestore.collection('companies');
    }

    async getAll(): Promise<Array<models.Strain>> {
        let companyId = this.helperService.currentCompanyId;
        let response = this.companyRef.doc(companyId).collection(this.collectionId).doc(this.itemId);
        let dbStrain: models.DbStrain = await (await response.get().toPromise()).data();

        return dbStrain?.list || [];
    }

    // get(teamId: string): AngularFirestoreDocument {
    //     let companyId = this.helperService.currentCompanyId;
    //     let harvestResponse = this.companyRef.doc(companyId).collection(this.collectionId)
    //                             .doc(teamId);

    //     return harvestResponse;
    // }

    // add(strain: models.Strain) {
    //     let companyId = this.helperService.currentCompanyId;
    //     return this.firestore.collection('companies').doc(companyId)
    //             .collection(this.collectionId).add(strain);
    //}

    update(strains: Array<models.Strain>) {

        let dbStrain: models.DbStrain = {
            list: strains
        }

        let companyId = this.helperService.currentCompanyId;
        return this.firestore.collection('companies').doc(companyId)
                .collection(this.collectionId).doc(this.itemId).set(dbStrain);
    }

    // async delete(strainId: string){
    //     let companyId = this.helperService.currentCompanyId;

    //     await this.firestore.collection('companies').doc(companyId)
    //             .collection(this.collectionId).doc(strainId).delete();
    // }

    async import(importRows: Array<viewModels.StrainExport>): Promise<void> {
        let strains = await this.getAll();

        importRows.forEach(importRow => {
            if(strains.find(i => i.name == importRow.strains) == null){
                let strain: models.Strain = {};
                strain.id = uuidv4();
                strain.name = importRow.strains;
                strains.push(strain);
            }
        });

        await this.update(strains);
    }
}
