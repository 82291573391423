
<div class="row">
  <div class="col-8">
    <h2 mat-dialog-title><span *ngIf="!isAdd">Edit</span><span *ngIf="isAdd">Add</span> Sensor</h2>
  </div>
  <div class="col-4">
    <button style="float:right;" type="button" mat-raised-button (click)="timeline()">
      <mat-icon>manage_history</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content style="min-width: 50vw">
  <form id="editSensor" class="template-form" (ngSubmit)="saveClick()">

    <mat-form-field>
      <input matInput placeholder="Name" type="text" required name="sensor_number" [(ngModel)]="sensor.name">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Sensor Number" type="text" required name="sensor_name"
        [(ngModel)]="sensor.sensorNumber">
    </mat-form-field>

    <app-autocomplete [(value)]="sensor.type" [options]="options" [label]="'Sensor Type'"></app-autocomplete>

    <app-zone-select [(zoneId)]="sensor.zoneId">
    </app-zone-select>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-icon-button color="warn">
    <mat-icon style="margin-right: 8px" (click)="deleteClick()">delete</mat-icon>
  </button>
  <button mat-button mat-dialog-close [disabled]="saveClicked">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="editSensor" [disabled]="saveClicked">
    <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClicked">Save</span>
  </button>
</mat-dialog-actions>