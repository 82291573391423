import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService, StopwatchHelperService } from 'app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stopwatch-popup',
  templateUrl: './stopwatch-popup.component.html',
  styleUrls: ['./stopwatch-popup.component.scss']
})
export class StopwatchPopupComponent implements OnInit, OnDestroy {

  open: boolean = true;
  counter: number;
  timerRef;
  running: boolean = false;
  startText = 'Start';
  timerDisplay: any = {};
  sub: Subscription;
  clearSub: Subscription;

  constructor(
    public stopwatchHelperService: StopwatchHelperService
    , private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.init();
    this.sub = this.stopwatchHelperService.sub.subscribe(data => {
      this.init();
    })
    this.clearSub = this.stopwatchHelperService.clearSub.subscribe(data => {
      this.clearTimer();
    })
  }

  init() {
    let start = this.stopwatchHelperService.start;

    if(start != null){
      this.counter = Date.now() - start;
      this.timerDisplay = this.getDisplayTimer(this.counter/1000);
      this.stopwatchHelperService.display = true;
      //this.stopwatchHelperService.startStopwatch(this.stopwatchHelperService.eventName, this.counter);
      this.startTimer();
    }
  }

  height: number = 230;
  click() {
		if(this.open === false) {
			$('.footerSlideContent').animate({ height: this.height.toString() + 'px' });
			$('.footerSlideContent').css('border', 'grey solid 1px');
			$(this).css('backgroundPosition', 'bottom left');
			this.open = true;
		} else {
      this.height = $('.footerSlideContent').height();
			$('.footerSlideContent').animate({ height: '0px' });
			$('.footerSlideContent').css('border', '0px');
			$(this).css('backgroundPosition', 'top left');
			this.open = false;
		}
  }

  startTimer() {
    this.running = !this.running;
    if (this.running) {
      this.startText = 'Stop';
      const startTime = Date.now() - (this.counter || 0);
      this.stopwatchHelperService.start = startTime;
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        this.timerDisplay = this.getDisplayTimer(this.counter/1000);
      });
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }

  getDisplayTimer(time: number) {
    const hours = '0' + Math.floor(time / 3600);
    const minutes = '0' + Math.floor(time % 3600 / 60);
    const seconds = '0' + Math.floor(time % 3600 % 60);

    return {
      hours: { digit1: hours.slice(-2, -1), digit2: hours.slice(-1) },
      minutes: { digit1: minutes.slice(-2, -1), digit2: minutes.slice(-1) },
      seconds: { digit1: seconds.slice(-2, -1), digit2: seconds.slice(-1) },
    };
  }

  clearTimer() {
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    this.stopwatchHelperService.start = null;
    clearInterval(this.timerRef);
    this.stopwatchHelperService.display = false;
  }

  ngOnDestroy() {
    clearInterval(this.timerRef);
    this.sub.unsubscribe();
    this.clearSub.unsubscribe();
  }

  submit() {
    this.stopwatchHelperService.submitSub.next(
      {
        timeSpent: Math.round(this.counter/60000),
        eventName: this.stopwatchHelperService.eventName
      }
    )
  }
}
