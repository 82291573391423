import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { SessionStorageService } from 'angular-web-storage';

import * as models from '../models';
import * as enums from '../models/enumerations';
import { Observable, Subscription, Subject } from 'rxjs';
import { RoleService } from './roles.service';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';

@Injectable()
export class ClaimsService {
    retrievedClaims: boolean = false;

    // admin: boolean = false;
    // manager: boolean = false;
    // standardUser: boolean = false;

    userRole: models.Role = {};

    constructor(
        private storage: SessionStorageService
        , private roleService: RoleService
        , private auth: Auth
    ){}

    async getRoleAwait(): Promise<models.Role> {
        if(this.retrievedClaims){
            return this.userRole;
        }

        this.setUserClaims();
        while(!this.retrievedClaims){
            await new Promise(r => setTimeout(r, 250) );
        }

        return this.userRole;
    }

    // async getAdminAwait(): Promise<boolean> {
    //     if(this.retrievedClaims){
    //         return this.admin;
    //     }

    //     while(!this.retrievedClaims){
    //         await new Promise(r => setTimeout(r, 250) );
    //     }

    //     return this.admin;
    // }

    // async getManagerAwait(): Promise<boolean> {
    //     if(this.retrievedClaims){
    //         return this.manager;
    //     }

    //     while(!this.retrievedClaims){
    //         await new Promise(r => setTimeout(r, 250) );
    //     }

    //     return this.manager;
    // }

    // async getStandardUserAwait(): Promise<boolean> {
    //     if(this.retrievedClaims){
    //         return this.standardUser;
    //     }

    //     while(!this.retrievedClaims){
    //         await new Promise(r => setTimeout(r, 250) );
    //     }

    //     return this.standardUser;
    // }

    setUserClaims() {
        onAuthStateChanged(this.auth, user => {
            if (user) {
              
                this.auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
                    let roles: Array<models.Role> = await this.roleService.getRoles();

                    let claims = Object.keys(idTokenResult.claims);
                    claims.forEach(claim => {
                        if(claim == 'admin'){ //admin override
                            let adminRole: models.Role = {
                                id: 'admin',
                                name: 'System Admin',
                                permissions: (new models.PermissionList()).list.map(i => i.id)
                            }
                            this.userRole = adminRole;
                        }

                        let role: models.Role = roles.find(i => i.id === claim);
                        if(role != null){
                            this.userRole = role;
                            return;
                        }
                    });

                    this.retrievedClaims = true;
                });
            } else {
                this.userRole = null;
                this.retrievedClaims = false;
            }
        });
    }

    currentUserId(): string {
        let uid: string;
        try {
            uid = this.auth.currentUser.uid;
        }catch(ex){
            uid = '';
        }
        return uid;
    }
    
    clearClaims() {
        // this.admin = false;
        // this.manager = false;
        // this.standardUser = false;

        this.userRole = null;
        this.storage.clear()
    }
}