import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ChartOptions } from 'chart.js';
import * as Chart from 'chart.js/auto';
import * as models from "../../../models";
import * as services from "../../../services";
import { Subject, Observable, Subscription } from "rxjs";
import { ChartDaterangeFilterComponent } from 'app/components/chart-filters/chart-daterange-filter/chart-daterange-filter.component';

@Component({
  selector: 'app-events-completed-stacked-chart',
  templateUrl: './events-completed-stacked-chart.component.html',
  styleUrls: ['./events-completed-stacked-chart.component.scss']
})
export class EventsCompletedStackedChartComponent implements OnInit {

  @ViewChild('chartCanvas', { static: false }) chartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild(ChartDaterangeFilterComponent) chartDaterangeFilterComponent: ChartDaterangeFilterComponent;
  
  
  @Input() filteredEvents: models.ChartEventInput[];
  @Output() chartViewChange = new EventEmitter<any>();
  
  private stackedBarChart;

  dates: Array<string>;
  totalEvents: (number | null)[];
  completedEvents: (number | null)[];
  overdueEvents: (number | null)[];

  totalEventsMap: Map<string, number>;
  completedEventsMap: Map<string, number>;
  overdueEventsMap: Map<string, number>;

  dateRange: models.DateRanger;
  selectedInterval: string;

  eventsSub: Subscription;

  intervalSelected: string;

  range: models.DateRanger;

  chartTitle: string = 'Events';
  chartSubTitle: string = 'Events completed and overdue for your cultivation'

  private _events = new Subject<models.ChartEventInput[]>();
  getEvents(): Observable<any> {
    return this._events.asObservable();
  }

  set events(val: models.ChartEventInput[]) {
    if (val) {
      this._events.next(val);
    }
  }

  constructor(
    private helperService: services.HelperService
  ) { }

  changeChartView() {
    this.chartViewChange.emit({ expand: true, chart: 'events' });
  }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.events = this.filteredEvents;
  }

  ngAfterViewInit() {
    if (this.eventsSub) {
      this.prepareChartEvents(this.events, this.dateRange);
    }
    this.eventsSub = this.getEvents().subscribe(data => {
      this.prepareChartEvents(data, this.dateRange);
    })
  }

  // categorise Events for Completed and Overdue - based on Date Range selected - last month by default
  prepareChartEvents(events: models.ChartEventInput[], eventsDateRange: models.DateRanger) {
    // last week, last month, last year, all time or custom range
    let interval: string;
    interval = this.selectedInterval;
    let [_totalEventsMap, _completedEventsMap, _overdueEventsMap] = this.prepareEventsMapForDatesRange(events, eventsDateRange);
    if (!interval) { // prepare Events Map for default range last month;
      this.dates = Array.from(_totalEventsMap.keys());
      this.dates = this.helperService.formatDateForCharts(this.dates, 'Day');
      this.totalEvents = Array.from(_totalEventsMap.values());
      this.completedEvents = Array.from(_completedEventsMap.values());
      this.overdueEvents = Array.from(_overdueEventsMap.values());
    } else {
      switch(interval) {
        case 'Day':
          this.totalEventsMap = this.helperService.aggregateDataByDay(_totalEventsMap);
          this.completedEventsMap = this.helperService.aggregateDataByDay(_completedEventsMap);
          this.overdueEventsMap = this.helperService.aggregateDataByDay(_overdueEventsMap);

          this.dates = Array.from(this.totalEventsMap.keys());
          this.totalEvents = Array.from(this.totalEventsMap.values());
          this.completedEvents = Array.from(this.completedEventsMap.values());
          this.overdueEvents = Array.from(this.overdueEventsMap.values());
          break;
        case 'Week':
          this.totalEventsMap = this.helperService.aggregateDataByWeek(_totalEventsMap);
          this.completedEventsMap = this.helperService.aggregateDataByWeek(_completedEventsMap);
          this.overdueEventsMap = this.helperService.aggregateDataByWeek(_overdueEventsMap);

          //prepare labels(Dates) and data sets from events and count maps
          this.dates = Array.from(this.totalEventsMap.keys());
          this.totalEvents = Array.from(this.totalEventsMap.values());
          this.completedEvents = Array.from(this.completedEventsMap.values());
          this.overdueEvents = Array.from(this.overdueEventsMap.values());
          break;
        case 'Month':
          this.totalEventsMap = this.helperService.aggregateDataByMonth(_totalEventsMap);
          this.completedEventsMap = this.helperService.aggregateDataByMonth(_completedEventsMap);
          this.overdueEventsMap = this.helperService.aggregateDataByMonth(_overdueEventsMap);

          //prepare labels(Dates) and data sets from events and count maps
          this.dates = Array.from(this.totalEventsMap.keys());
          this.totalEvents = Array.from(this.totalEventsMap.values());
          this.completedEvents = Array.from(this.completedEventsMap.values());
          this.overdueEvents = Array.from(this.overdueEventsMap.values());
          break
        case 'Quarter':
          this.totalEventsMap = this.helperService.aggregateDataByQuarter(_totalEventsMap);
          this.completedEventsMap = this.helperService.aggregateDataByQuarter(_completedEventsMap);
          this.overdueEventsMap = this.helperService.aggregateDataByQuarter(_overdueEventsMap);

          //prepare labels(Dates) and data sets from events and count maps
          this.dates = Array.from(this.totalEventsMap.keys());
          this.totalEvents = Array.from(this.totalEventsMap.values());
          this.completedEvents = Array.from(this.completedEventsMap.values());
          this.overdueEvents = Array.from(this.overdueEventsMap.values());
          break;
        case 'Year':
          this.totalEventsMap = this.helperService.aggregateDataByYear(_totalEventsMap);
          this.completedEventsMap = this.helperService.aggregateDataByYear(_completedEventsMap);
          this.overdueEventsMap = this.helperService.aggregateDataByYear(_overdueEventsMap);

          //prepare labels(Dates) and data sets from events and count maps
          this.dates = Array.from(this.totalEventsMap.keys());
          this.totalEvents = Array.from(this.totalEventsMap.values());
          this.completedEvents = Array.from(this.completedEventsMap.values());
          this.overdueEvents = Array.from(this.overdueEventsMap.values());
          break;
      }
      this.dates = this.helperService.formatDateForCharts(this.dates, interval);
    }

    this.makeChart();
  }

  prepareEventsMapForDatesRange(events: models.ChartEventInput[], eventsDateRange: models.DateRanger): any { // last month
    const totalEventsInRangeMap = this.helperService.computeDateWiseEventsCount(events, eventsDateRange?.datesInRange);
    // filter events for completed events
    const _completedEvents = events.filter(event => event.completed)
    const completedEventsInRangeMap = this.helperService.computeDateWiseEventsCount(_completedEvents, eventsDateRange?.datesInRange);

    // filter events for overdue events
    const _overdueEvents = events.filter(event => event.date < new Date() && !event.completed);
    const overdueEventsInRangeMap = this.helperService.computeDateWiseEventsCount(_overdueEvents, eventsDateRange?.datesInRange);

    return [totalEventsInRangeMap, completedEventsInRangeMap, overdueEventsInRangeMap];
  }

  makeChart(): void {
    // destroy chart if already there
    if (this.stackedBarChart) {
      this.stackedBarChart.destroy();
    }

    const chartDatasets = [
      {
        label: 'Completed Events',
        data: this.completedEvents.map((count, index) => ({ x: this.dates[index], y: count })), // all events here
        backgroundColor: '#43A047',
        borderColor: '#43A047',
        borderWidth: 1,
      },
      {
        label: 'Overdue Events',
        data: this.overdueEvents.map((count, index) => ({ x: this.dates[index], y: count })), // all events here
        backgroundColor: '#f44336',
        borderColor: '#f44336',
        borderWidth: 1,

      }
    ]

    // chart config options,
    const chartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 0, // Adjust as needed
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltip) => {
              const tooltipItem = tooltip;
              const datasetLabel = tooltipItem.dataset.label || '';
              const dataValue = tooltipItem.formattedValue;
              const totalValue = this.totalEvents[tooltipItem.dataIndex];
              return `${datasetLabel}: ${dataValue} - (Total Events: ${totalValue})`;
            }
          }
        },
        legend: {
          position: 'bottom'
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 10,
            }
          }
        },
        y: {
          stacked: true,
        }
      }
    };

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.stackedBarChart = new Chart.Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.dates, // past days 1 to 30 by default
        datasets: chartDatasets,
      },
      options: chartOptions
    });
  }

  handleChartDateRangeChange(chartDateRange: models.DateRanger) {
    this.dateRange = chartDateRange;
    this.selectedInterval = this.helperService.getDefaultInterval(chartDateRange.rangeType);
    this.prepareChartEvents(this.filteredEvents, this.dateRange);
  }

  handleChartIntervalChange(chartInterval: string) {
    this.selectedInterval = chartInterval;
    this.prepareChartEvents(this.filteredEvents, this.dateRange);
  }

  resetFilters() {
    this.chartDaterangeFilterComponent.setDefaultDateRange();
  }

  ngOnDestroy(): void {
    if(this.stackedBarChart) {
      this.stackedBarChart.destroy();
    }

    if (this.eventsSub) {
      this.eventsSub.unsubscribe();
    }
  }
}
