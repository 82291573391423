import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { FileService } from '../../services/file.service';
import { UserService } from "app/services";

@Component({
    selector: "username-image-cell",
    template: `
        <div style="display: flex; align-items: center; gap: 10px;">
            <img *ngIf="loadedImg" [src]="userImageUrl" height="25" width="25" style="object-fit: cover; margin: auto; display: inline-block; border-radius: 50%" /> 
            <i *ngIf="!loadedImg" class="fa fa-spinner fa-spin"></i>
            <span>{{displayName}}</span> 
            <i *ngIf="invitedUser">(Invitation Sent)</i>
        </div>`,
    styles: [
    ]
})
export class UsernameAndImageComponent implements ICellRendererAngularComp {
    private params: any;

    displayName: string;
    loadedImg: boolean = false;
    userImageUrl: string;
    invitedUser: boolean = false;

    constructor(
        private userService: UserService
    ) {}

    agInit(params: any): void {
        this.params = params;
        this.displayName = params.value;
        this.invitedUser = params.data.invited
        this.loadUserImageData(params.data.user.id);
    }

    async loadUserImageData(userId: string) {
        this.userImageUrl = await this.userService.getUserImageUrl(userId);
        this.loadedImg = true;
    }

    refresh(params: any): boolean {
        return false;
    }
}