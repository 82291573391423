<mat-form-field>
    <mat-label>Apply a Workflow</mat-label>
    <mat-select [(value)]="workflowId" name="selectedWorkflow-select">
      <mat-select-filter 
        *ngIf="filteredWorkflows.length !== 0"
        [array]="workflows" 
        [displayMember]="'name'" 
        (filteredReturn)="filteredWorkflows = $event"
      >
      </mat-select-filter>
      <mat-option 
       class="full-option-text"  
       value="null"
       *ngIf="filteredWorkflows.length === 0; else displayFilteredWorkflows"
      >
        You dont't have any Secondary Templates. 
        Please go to Templates to create one.
      </mat-option>
      <ng-template #displayFilteredWorkflows>
        <mat-option class="full-option-text" [value]="item.id" *ngFor="let item of filteredWorkflows">
          {{item.name}}
        </mat-option>
      </ng-template>
    </mat-select>
</mat-form-field>