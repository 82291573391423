import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { GridHeaderService, HelperService } from 'app/services';

export interface ICustomHeaderParams {
    menuIcon: string;
}

@Component({
    selector: 'app-checkbox-header',
    template: `
    <mat-checkbox
        class="cheackbox-header"
        [(ngModel)]="gridHeaderService.anyChecked" 
        [indeterminate]="notAllChecked" 
        (change)="checkedChanged()">
    </mat-checkbox>
  `,
    styles: [
        `
        .cheackbox-header {
            padding-top: 8px
        }
        ::ng-deep .mat-checkbox-indeterminate .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
            background-color: #828282 !important;
        }
    `,
    ],
})
export class CheckboxHeaderComponent implements IHeaderAngularComp {
    public params!: IHeaderParams & ICustomHeaderParams;

    // get notAllRowsTooltip(): string {
    //     if (this.gridHeaderService.notAllChecked) {
    //         return `Because of paging not all rows can be selected.  Selected ${this.getTotalSelected()}/${this.gridHeaderService.totalRows} rows.`;
    //     }
    //     return '';
    // }

    //checkAll: boolean = false;

    @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;

    constructor(
        private dialog: MatDialog
        , private helperService: HelperService
        , public gridHeaderService: GridHeaderService
    ) { }

    agInit(params: IHeaderParams & ICustomHeaderParams): void {
        this.params = params;
    }

    async checkedChanged() {
        //this.params.api.forEachNode(node => node.setSelected(this.gridHeaderService.anyChecked));
        this.gridHeaderService.events.forEach(i => i.event.completed = this.gridHeaderService.anyChecked);
        await this.helperService.wait(50);
        this.params.api.refreshCells({force: true});
    }
    
    get notAllChecked() {
        if (!this.gridHeaderService.anyChecked) {
            return false;
        }

        let totalSelected = this.gridHeaderService.events.filter(i => i.event.completed).length;

        let notAllChecked = totalSelected < this.gridHeaderService.totalRows;
        this.gridHeaderService.notAllChecked = notAllChecked;
        return notAllChecked;
    }

    getTotalSelected() {
        let totalSelected = 0;
        this.params.api.forEachNode(node => {
            if (node.isSelected()) {
                totalSelected++;
            }
        });
        return totalSelected;
    }

    // changePagedValue() {
    //     let data = {
    //         header: 'Number of rows per page',
    //         placeholder: 'Rows per page',
    //         value: 100,
    //     }
    //     let dialogRef = this.dialog.open(NumberDialogComponent, { data: data });

    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.gridHeaderService.changeTotalPerPage(result);
    //         }
    //     });
    // }

    refresh(params: IHeaderParams) {
        return false;
    }
}
