<div id="form-container">
    <div id="sq-card"></div>
    <!-- <div id="sq-card-number"></div>
    <div class="third" id="sq-expiration-date"></div>
    <div class="third" id="sq-cvv"></div>
    <div class="third" id="sq-postal-code"></div> -->
    <button id="sq-creditcard" class="button-credit-card" (click)="onGetCardNonce($event)">Pay $1.00</button>
</div>

<!-- <div id="sq-card-number"></div>
<div id="sq-cvv" class="sq-input-small"></div>
<div id="sq-expiration-date" class="sq-input-small"></div>
<div id="sq-postal-code" class="sq-input-small"></div> -->