<app-loading-panel [show]="company == null"></app-loading-panel>

<ng-container *ngIf="company != null">
    <div class="row" style="padding-bottom: 15px">
        <div class="col-sm-12" style="text-align: right">
            <!-- <app-stripe-portal-btn [customerId]="company.stripeCustomerId"></app-stripe-portal-btn> -->
            <button mat-raised-button class="pad-btn" [disabled]="!dataUpdated()" title="Save Company" (click)="save()">
                <i class="fa fa-spinner fa-spin" *ngIf="saving"></i>
                <mat-icon *ngIf="!saving" aria-hidden="false" aria-label="Save Template">save</mat-icon>
            </button>
            <button mat-raised-button class="pad-btn" [disabled]="!dataUpdated()" title="Discard Changes" (click)="get()">
                <mat-icon aria-hidden="false" aria-label="Discard Changes">undo</mat-icon>
            </button>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-6">
            <mat-slide-toggle name="leaflogixactive" [(ngModel)]="company.leaflogix.active">Use Dutchie</mat-slide-toggle>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-6">
            <mat-form-field>
                <input autocomplete="off" matInput placeholder="ApiKey" [type]="apiKeyHide ? 'password' : 'text'" [(ngModel)]="company.leaflogix.apiKey">
                <mat-icon matSuffix (click)="apiKeyHide = !apiKeyHide">{{apiKeyHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <app-user-select-multiple [placeholder]="'Dutchie Admins'" [displayUserList]="false" [(userIds)]="company.leaflogix.adminUserIds"></app-user-select-multiple>
        </div>
    </div>
</ng-container>