import { EventEmitter, Injectable } from '@angular/core';
import { HelperService } from '../helper.service';

@Injectable()
export class TemplateStepListHelperService {
  
  public sub = new EventEmitter<any>();

  constructor(
  ) { }

}
