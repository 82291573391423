export interface FilterSet {

    id?: string;
    name?: string;

    // this.harvestSelectMultipleComponent.selectAll();
    // this.groupSelectMultipleComponent.selectAll();
    userIds?: string[];
    teamIds?: string[];
    completeStatus?: string;
    onlyMyTasks?: boolean;
    phases?: string[];
    tags?: string[];
    zoneIds?: string[]

    // harvestIds?: string[];
    groupIds?: string[];
}