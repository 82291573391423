import { Component, OnInit } from '@angular/core';
import { DynamicScriptLoaderService } from 'app/services';
declare var SqPaymentForm: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  paymentForm: any;
  
  constructor(
    private dsls: DynamicScriptLoaderService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.dsls.loadScript('square-sandbox');

    this.paymentForm = new SqPaymentForm({ 
      applicationId: "sandbox-sq0idb-h2-I7kzjRLv5eHtXjh-Kgw",
      //inputClass: 'sq-input',
      autoBuild: false,
      // Customize the CSS for SqPaymentForm iframe elements
      // inputStyles: [{
      //     fontSize: '16px',
      //     lineHeight: '24px',
      //     padding: '16px',
      //     placeholderColor: '#a0a0a0',
      //     backgroundColor: 'transparent',
      // }],
      card: {
        elementId: 'sq-card',
      },
      // // Initialize the credit card placeholders
      // cardNumber: {
      //     elementId: 'sq-card-number',
      //     placeholder: 'Card Number'
      // },
      // cvv: {
      //     elementId: 'sq-cvv',
      //     placeholder: 'CVV'
      // },
      // expirationDate: {
      //     elementId: 'sq-expiration-date',
      //     placeholder: 'MM/YY'
      // },
      // postalCode: {
      //     elementId: 'sq-postal-code',
      //     placeholder: 'Postal'
      // },
      // SqPaymentForm callback functions
      callbacks: {
        /*
        * callback function: cardNonceResponseReceived
        * Triggered when: SqPaymentForm completes a card nonce request
        */
        cardNonceResponseReceived: function (errors, nonce, cardData) {
          if (errors) {
              // Log errors from nonce generation to the browser developer console.
              console.error('Encountered errors:');
              errors.forEach(function (error) {
                  console.error('  ' + error.message);
              });
              alert('Encountered errors, check browser developer console for more details');
               return;
          }
          //TODO: Replace alert with code in step 2.1
          alert(`The generated nonce is:\n${nonce}`);
        }
      }
    });
    this.paymentForm.build();
  }

  onGetCardNonce(event) {

    // Don't submit the form until SqPaymentForm returns with a nonce
    event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    this.paymentForm.requestCardNonce();
  }
}
