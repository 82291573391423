<h2 id="template_add_title" mat-dialog-title><span *ngIf="!isAdd">Edit</span><span *ngIf="isAdd">Add</span> Post Harvest Data Point</h2>
<mat-dialog-content>
  <form id="editPostHarvest" class="template-form" (ngSubmit)="saveClick()">

    <mat-form-field>
      <input matInput placeholder="Data Point Name" type="text" required name="postHarvest_name" [(ngModel)]="postHarvest.name">
    </mat-form-field>

    <mat-form-field>
        <mat-select [(ngModel)]="postHarvest.dataType" name="postHarvest_type">
            <mat-option *ngFor="let dataType of dataTypes" [value]="dataType">
                {{ dataType }}
            </mat-option>
        </mat-select>
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="saveClicked">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button id="template_add_save" mat-button type="submit" form="editPostHarvest" [disabled]="saveClicked">
    <mat-spinner *ngIf="saveClicked" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!saveClicked">Save</span>
  </button>
</mat-dialog-actions>
