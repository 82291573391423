import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Moment } from 'moment';

import * as models from '../../models';

@Component({
    selector: "warning-cell",
    template: `
        <span [class]="class">{{value}}</span>
    `,
    styles: [
        `
        .warning {
            background-color: red;
        }
        `
    ]
})
export class WarningComponent implements ICellRendererAngularComp {
    private params: any;

    value: string;
    empty: boolean = false;

    get class() {
        if(this.empty){
            return 'warning';
        }

        return '';
    }

    constructor() { }

    agInit(params: any): void {
        if (!params.value) {
            return;
        }

        this.params = params;

        this.value = this.params.value;
        this.empty = this.value == null || this.value == '';
    }

    refresh(params: any): boolean {
        return false;
    }
}