import { Injectable } from '@angular/core';
import { NgxIzitoastService } from 'ngx-izitoast';
import { NgxIzitoastModel } from 'ngx-izitoast/src/model/ngx-izitoast.model';
import * as alertify from "alertifyjs";
import { BrokenRule } from 'app/models/brokenRule';

@Injectable()
export class NotifierService {

  constructor(
    public iziToast: NgxIzitoastService
  ) {}

  error(message: string, duration: number | boolean = 5000) {
    let model: NgxIzitoastModel = {
      message: message,
      position: 'topCenter',
      timeout: duration
    }
    this.iziToast.error(model);
  }

  brokenRules(brokenRules: Array<BrokenRule>, duration: number | boolean = 5000) {
    let message: string = '';
    let usableBrokenRules = brokenRules?.filter(i => i.displayToClient) || [];
    if(usableBrokenRules.length == 0) {
      message = 'Unexpected error occurred';
    } else {
      brokenRules.filter(i => i.displayToClient).forEach(brokenRule => {
        message += brokenRule.message + ' ';
      });
    }
    let model: NgxIzitoastModel = {
      message: message,
      position: 'topCenter',
      timeout: duration
    }
    this.iziToast.error(model);
  }

  errorWithBtn(message: string, btnTxt: string, btnClick: any, duration: number | boolean = 5000) {
    let model: NgxIzitoastModel = {
      message: message,
      position: 'topCenter',
      buttons: [
        ['<button><b>' + btnTxt + '</b></button>', (instance, toast) => {
          btnClick();
          instance.hide(toast);
        }, true],
      ],
      timeout: duration
    }
    this.iziToast.error(model);
  }

  success(message: string) {
    let model: NgxIzitoastModel = {
      message: message,
      position: 'bottomRight'
    }
    this.iziToast.success(model);
  }

  successWithBtn(message: string, btnTxt: string, btnClick: any) {
    let model: NgxIzitoastModel = {
      message: message,
      position: 'bottomRight',
      buttons: [
        ['<button><b>' + btnTxt + '</b></button>', (instance, toast) => {
          btnClick();
        }, true],
      ]
    
    }
    this.iziToast.success(model);
  }

  public confirm(title: string, message: string, yesCallbackFunction: any, noCallbackFunction: any, yesBtnTxt: string = 'OK', noBtnTxt: string = 'Cancel'){
    alertify.confirm(title, message,
      yesCallbackFunction,
      noCallbackFunction).set('labels', { ok: yesBtnTxt, cancel: noBtnTxt });
  }
}
