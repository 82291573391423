<h2 mat-dialog-title class="text-capitalize">Upload {{imageFor}} Image</h2>
<mat-dialog-content>

    <form id="imageUpload" class="harvest-form" (ngSubmit)="submit()">
        <img [src]="imgURL" height="100" width="100" style="object-fit: cover; margin: auto; display: block;" *ngIf="imgURL">
        <span style="color:red;" *ngIf="message">{{message}}</span>
        <!-- <input #file type="file" style="padding-top: 20px;" accept='image/*' (change)="preview(file.files)" /> -->
        <input #file type="file" id="selectedFile" style="display: none;" accept='image/*' (change)="preview(file.files)" />
        <button type="button" style="margin:10px auto; display:block;" mat-button-raised (click)="browse()">Browse...</button>

        <p>Square images will look best.  Image must be less than 100KB.</p>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="formSubmitted">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="imageUpload" [disabled]="formSubmitted">
    <mat-spinner *ngIf="formSubmitted" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!formSubmitted" [disabled]="imagePath == null">Upload</span>
  </button>
</mat-dialog-actions>
