<ng-container>
  <mat-card class="sticky-header" style="margin-bottom: 20px">
    <!--  -->
    <div class="row">
      <div class="col-sm-4" style="text-align: left">
        <button class="gridbtn" mat-raised-button title="Back to List" (click)="returnToList()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div class="col-sm-4" style="text-align: center" *ngIf="pageLoaded">
        <mat-form-field class="full-width" *ngIf="canEdit">
          <input matInput style="text-align: center; font-size: 26px; font-weight: bold;" [(ngModel)]="taskGroup.name"
            (keyup.enter)="saveName($event)">
        </mat-form-field>
        <h3 *ngIf="!canEdit">
          {{taskGroup.name}}
        </h3>
      </div>
      <div class="col-sm-4 right">
        <ng-container>
          <app-harvest-save-btn *ngIf="canEdit || canCompleteEvent" [disabled]="!saveBtnEnabled" (click)="saveChanges()"></app-harvest-save-btn>
          <app-harvest-undo-btn *ngIf="canEdit || canCompleteEvent" [disabled]="!saveBtnEnabled" (click)="undoChanges()"></app-harvest-undo-btn>
        </ng-container>
        <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu" *ngIf="canEdit || canAddEvent">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <app-task-group-delete-btn *ngIf="canEdit" [group]="taskGroup" (doneDeleting)="backToGroupList()">
          </app-task-group-delete-btn>
          <mat-divider *ngIf="canEdit"></mat-divider>
          <!-- <button mat-menu-item (click)="defaultColumns()" title="Default Columns">
            <mat-icon aria-hidden="false" aria-label="Default Columns">settings_backup_restore</mat-icon>
            <span>Default Columns</span>
          </button> -->
          <button mat-menu-item *ngIf="eventListComponent != null && canAddEvent" [matMenuTriggerFor]="addeventmenu"
            [disabled]="eventListComponent.hasPendingChanges" title="Add Event">
            <mat-icon aria-hidden="false" aria-label="Add Event">add</mat-icon>
            <span>Add Event</span>
          </button>
        </mat-menu>

        <mat-menu #addeventmenu="matMenu">
            <button mat-menu-item (click)="addEvent()">
                <mat-icon>add</mat-icon>
                <span>Event</span>
            </button>
            <button mat-menu-item (click)="addEventFromLibrary()">
                <mat-icon>add_task</mat-icon>
                <span>Add From Library</span>
            </button>
        </mat-menu>
      </div>
    </div>
  </mat-card>

  <app-event-list *ngIf="pageLoaded" [collectionType]="collectionType" [collectionId]="taskGroupId"
    [collectionName]="taskGroup.name" [company]="company" (eventListChange)="eventListChange($event)">
  </app-event-list>
</ng-container>