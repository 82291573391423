import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-templated-task-select',
  templateUrl: './templated-task-select.component.html',
  styleUrls: ['./templated-task-select.component.scss']
})
export class TemplatedTaskSelectComponent implements OnInit {

  templatedTasks: models.TemplatedTask[] = [];

  taskValue: models.TemplatedTask;
  @Input()
  get selectedTask() {
    return this.taskValue;
  }
  @Output() selectedTaskChange = new EventEmitter();
  set selectedTask(val: models.TemplatedTask) {
    this.taskValue = val;
    this.selectedTaskChange.emit(this.taskValue);
  }
  
  constructor(
    private templatedTaskService: services.TemplatedTasksService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    let response = await this.templatedTaskService.getAll();
    this.templatedTasks = this.helperService.sortArrayByStringField(response, 'name');
  }

}
