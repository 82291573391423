<h2 mat-dialog-title>Edit User</h2>
<mat-dialog-content>
  <form id="saveUser" class="user-form" (ngSubmit)="saveUser()">
    <mat-form-field class="user-full-width">
      <input matInput placeholder="Display Name" name="displayName" required [(ngModel)]="user.displayName">
    </mat-form-field>
  
    <mat-form-field class="user-full-width">
      <input matInput placeholder="Email" type="email" name="email" required [(ngModel)]="user.emailAddress">
    </mat-form-field>
  
    <!-- <mat-form-field class="user-full-width">
      <input matInput placeholder="Phone" type="tel" name="phone" [(ngModel)]="user.phoneNumber"
      required pattern="^[6-9]\d{9}$" maxlength="10">
    </mat-form-field> -->
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="userSaveClick">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="saveUser" [disabled]="userSaveClick">
    <mat-spinner *ngIf="userSaveClick" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!userSaveClick">Save</span>
  </button>
</mat-dialog-actions>
