
<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    Add Number of Tasks to Notification
</h2>
<mat-dialog-content>
    
    <div class="row">
        <div class="col-6">
            <mat-form-field>
              <mat-label>Task Time Frame</mat-label>
              <mat-select [(ngModel)]="timeframe">
                <mat-option [(value)]="t.key" *ngFor="let t of timeframes">{{t.display}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-checkbox [(ngModel)]="assigned">Only Tasks Assigned to User</mat-checkbox>
            <mat-checkbox [(ngModel)]="incomplete">Exclude Complete Tasks</mat-checkbox>
        </div>
    </div>
    
    <div class="row" *ngIf="token != null">
        <div class="col-12">
            <p>Description: {{token.description}}</p>
            <p>Example: {{token.example}}</p>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  
  <button mat-button type="submit" [disabled]="token == null" (click)="submit()">
    <span>Add Token to Message</span>
  </button>
</mat-dialog-actions>
