
export interface Permission {
    grouping?: string;
    id?: string;
    name?: string;
}

export enum PermissionGroups {
  harvest = 'Harvest',
  group = 'Task Group',
  event = 'Event',
  template = 'Template',
  calendar = 'Calendar',
  growSettings = 'Grow Settings',
  user = 'User',
  company = 'Company',
  notifications = 'Notifications',
  analytics = 'Analytics',
  hideFromUser = 'hideFromUser',
}

export enum Permissions {
  addHarvest = 'addHarvest', //manager
  editHarvest = 'editHarvest', //manager
  shiftHarvest = 'shiftHarvest', //manager
  deleteHarvest = 'deleteHarvest', //manager
  addGroup = 'addGroup', //manager
  editGroup = 'editGroup', //manager
  deleteGroup = 'deleteGroup', //manager
  addEvent = 'addEvent', //manager
  editEvent = 'editEvent', //manager
  completeEvent = 'completeEvent', //standardUser
  applyWorkflow = 'applyWorkflow', //manager
  viewTemplate = 'viewTemplate', //manager
  addTemplate = 'addTemplate', //manager
  deleteTemplate = 'deleteTemplate', //manager
  assignUsers = 'assignUsers', //manager
  qualityTracker = 'qualityTracker', //manager
  deleteEvent = 'deleteEvent', //manager
  viewGrowSettings = 'viewGrowSettings', //owner
  manipulateFilterSets = 'manipulateFilterSets', //owner
  viewUsers = 'viewUsers', //manager
  editUsers = 'editUsers', //manager
  addUsers = 'addUsers', //manager
  deleteUsers = 'deleteUsers', //manager
  editUserRoles = 'editUserRoles', //owner
  enableDisableUsers = 'enableDisableUsers', //owner
  viewCompanySettings = 'viewCompanySettings', //owner
  billingManagement = 'billingManagement', //owner
  notificationEdit = 'notificationEdit', //owner
  notificationBlast = 'notificationBlast', //owner
  viewAnalytics = 'viewAnalytics', //manager
  adminOnly = 'adminOnly', //admin
}

export class PermissionList {
  list: Permission[] = [
    {
      grouping: PermissionGroups.harvest,
      id: Permissions.addHarvest,
      name: 'Add Harvest'
    },
    {
      grouping: PermissionGroups.harvest,
      id: Permissions.editHarvest,
      name: 'Edit Harvest'
    },
    {
      grouping: PermissionGroups.harvest,
      id: Permissions.shiftHarvest,
      name: 'Shift Harvest'
    },
    {
      grouping: PermissionGroups.harvest,
      id: Permissions.deleteHarvest,
      name: 'Delete Harvest'
    },
    {
      grouping: PermissionGroups.group,
      id: Permissions.addGroup,
      name: 'Add Group'
    },
    {
      grouping: PermissionGroups.group,
      id: Permissions.editGroup,
      name: 'Edit Group'
    },
    {
      grouping: PermissionGroups.group,
      id: Permissions.deleteGroup,
      name: 'Delete Group'
    },
    {
      grouping: PermissionGroups.event,
      id: Permissions.addEvent,
      name: 'Add Event'
    },
    {
      grouping: PermissionGroups.event,
      id: Permissions.editEvent,
      name: 'Edit Event'
    },
    {
      grouping: PermissionGroups.event,
      id: Permissions.completeEvent,
      name: 'Complete Event'
    },
    {
      grouping: PermissionGroups.event,
      id: Permissions.assignUsers,
      name: 'Assign Users'
    },
    {
      grouping: PermissionGroups.event,
      id: Permissions.qualityTracker,
      name: 'Quality Tracker'
    },
    {
      grouping: PermissionGroups.event,
      id: Permissions.deleteEvent,
      name: 'Delete Event'
    },
    {
      grouping: PermissionGroups.template,
      id: Permissions.applyWorkflow,
      name: 'Apply Workflow'
    },
    {
      grouping: PermissionGroups.template,
      id: Permissions.viewTemplate,
      name: 'View Template'
    },
    {
      grouping: PermissionGroups.template,
      id: Permissions.addTemplate,
      name: 'Add Template'
    },
    {
      grouping: PermissionGroups.template,
      id: Permissions.deleteTemplate,
      name: 'Delete Template'
    },
    {
      grouping: PermissionGroups.growSettings,
      id: Permissions.viewGrowSettings,
      name: 'View Grow Settings'
    },
    {
      grouping: PermissionGroups.calendar,
      id: Permissions.manipulateFilterSets,
      name: 'Change Filter Sets'
    },
    {
      grouping: PermissionGroups.user,
      id: Permissions.viewUsers,
      name: 'View Users'
    },
    {
      grouping: PermissionGroups.user,
      id: Permissions.editUsers,
      name: 'Edit Users'
    },
    {
      grouping: PermissionGroups.user,
      id: Permissions.addUsers,
      name: 'Add Users'
    },
    {
      grouping: PermissionGroups.user,
      id: Permissions.deleteUsers,
      name: 'Delete Users'
    },
    {
      grouping: PermissionGroups.user,
      id: Permissions.editUserRoles,
      name: 'Edit User Roles'
    },
    {
      grouping: PermissionGroups.user,
      id: Permissions.enableDisableUsers,
      name: 'Enable/Disable Users'
    },
    {
      grouping: PermissionGroups.company,
      id: Permissions.viewCompanySettings,
      name: 'View Company Settings'
    },
    {
      grouping: PermissionGroups.company,
      id: Permissions.billingManagement,
      name: 'Billing Management'
    },
    {
      grouping: PermissionGroups.notifications,
      id: Permissions.notificationEdit,
      name: 'Edit Notifications'
    },
    {
      grouping: PermissionGroups.notifications,
      id: Permissions.notificationBlast,
      name: 'Blast Notification'
    },
    {
      grouping: PermissionGroups.analytics,
      id: Permissions.viewAnalytics,
      name: 'View Analytics'
    },

    {
      grouping: PermissionGroups.hideFromUser,
      id: Permissions.adminOnly,
      name: 'Admin Only'
    },
  ];
}
