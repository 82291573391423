import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as models from 'app/models';
import * as services from 'app/services';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply-workflow',
  templateUrl: './apply-workflow.component.html',
  styleUrls: ['./apply-workflow.component.scss']
})
export class ApplyWorkflowComponent implements OnInit {
  applyingWorkflow: boolean = false;
  applyToGroup: boolean = false;
  showGroupTab: boolean;

  workflow: models.Template;
  workflowId: string;

  harvest: models.Harvest;
  harvestId: string;

  group: models.Harvest;
  groupId: string;

  startDate: FormControl = new FormControl(new Date());

  constructor(
    private dialogRef: MatDialogRef<ApplyWorkflowComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public dialog: MatDialog
    , private helperService: services.HelperService
    , private router: Router
    , private templateService: services.TemplateService
    , private notifierService: services.NotifierService
  ) { 
    if(data.showGroupTab){
      this.showGroupTab = data.showGroupTab;
    }

    if (data.harvestId) {
      this.harvestId = data.harvestId;
    }
  }
  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.harvestId = null;
  }

  applyWorkflow() {
    this.applyingWorkflow = true;
    let requestObject: models.ApplyWorkflowRequest;
    if (this.applyToGroup) { // apply template to group
      requestObject = {
        companyId: this.helperService.currentCompanyId,
        groupId: this.groupId,
        templateId: this.workflowId,
        startDate: this.helperService.dateStringToTimestamp(this.startDate.value),
        applyToGroup: true,
      }
    } else { // apply template to harvest
      requestObject = {
        companyId: this.helperService.currentCompanyId,
        harvestId: this.harvestId,
        templateId: this.workflowId,
        startDate: this.helperService.dateStringToTimestamp(this.startDate.value),
        applyToGroup: false,
      }
    }

    this.templateService.applyWorkflow(requestObject)
    .then((data: any) => {
      this.applyingWorkflow = false;
      this.dialogRef.close({
        added: data.success,
        message: data.message
      });
    })
    .catch((error: any) => {
      this.applyingWorkflow = false;
      this.notifierService.error(error.message)
    })
  }

  onWorkflowIdChange() {
    if(this.workflowId === 'null') {
      this.router.navigate(["/console" , "templates"])
      this.dialogRef.close({
        message: 'No Secondary Template Available. Please Create One in Templates.',
        requestObject: null
      })
    }
  }
}
