import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridOptions } from 'ag-grid-community';
import { CreateCompanyComponent } from 'app/dialogs/create-company/create-company.component';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as models from '../../../models';
import * as services from '../../../services';
import * as viewModels from '../../../viewmodels';
import { MatCheckboxComponent, MatInputComponent, MomentComponent } from '../../cell-components';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {

  public gridOptions: GridOptions;
  private gridApi;
  
  saving: boolean = false;
  deleting: boolean = false;

  private createColumnDefs() {
    return [
      {
          headerName: "Name",
          field: "name", sortable: true, resizable: true
      },
      // {
      //     headerName: "Harvests",
      //     field: "harvests", sortable: true, resizable: true
      // },
      // {
      //     headerName: "Templates",
      //     field: "templates",
      //     sortable: true, resizable: true
      // },
      // {
      //     headerName: "Users",
      //     field: "users",
      //     sortable: true, resizable: true
      // },
      {
          headerName: "Created",
          field: "created",
          sortable: true, resizable: true,
          cellRenderer: "momentRenderer"
      },
      // {
      //     headerName: "Last Active Date",
      //     field: "lastActiveDate",
      //     sortable: true, resizable: true
      // },
      // {
      //     headerName: "Free Version",
      //     field: "freeVersion",
      //     sortable: true, resizable: true
      // },
      // {
      //     headerName: "Payment Provider ID",
      //     field: "paymentProviderCustomerId",
      //     sortable: true, resizable: true
      // }
    ];
  }

  // companies: Array<models.Company> = [];
  selectedCompany: viewModels.CompanyViewModel;
  initialCompany: viewModels.CompanyViewModel;

  get dirty(): boolean {
    if(this.selectedCompany == null){
      return false;
    }
    
    // let finish = JSON.stringify(this.selectedCompany);
    // let start = JSON.stringify(this.initialCompany);
    // let updated = finish !== start;

    return this.selectedCompany.freeVersion != this.initialCompany.freeVersion ||
    this.selectedCompany.enableSensors != this.initialCompany.enableSensors ||
    this.selectedCompany.eventsSplit != this.initialCompany.eventsSplit;
  }

  lastActiveUser: string;
  lastActive: string;
  harvests: number;
  templates: number;
  users: number;

  loadingUser: boolean = false;
  loadingharvests: boolean = false;
  loadingTemplates: boolean = false;
  loadingUsers: boolean = false;

  constructor(
    private companyService: services.CompanyService
    , private harvestService: services.HarvestService
    , private templateService: services.TemplateService
    , private userService: services.UserService
    , private helperService: services.HelperService
    , private snackBar: MatSnackBar
    , private deviceService: DeviceDetectorService
    , private dialog: MatDialog
    , private migrationService: services.MigrationService
    , private notifierService: services.NotifierService
  ) { 
    this.gridOptions = <GridOptions>{
        context: {
            componentParent: this
        },
        //rowData: [],
        columnDefs: this.createColumnDefs(),
        onGridReady: () => {
          if(!this.deviceService.isMobile()){
            this.gridOptions.api.sizeColumnsToFit();
          }
          this.gridApi = this.gridOptions.api;
          this.getCompanies();
        },
        frameworkComponents: {
            checkboxRenderer: MatCheckboxComponent,
            inputRenderer: MatInputComponent,
            momentRenderer: MomentComponent
        },
        rowSelection: 'single',
        //selectionChanged: this.onSelectionChanged
    };
  }

  ngOnInit(): void {
  }

  async getCompanies() {
    if(this.gridOptions?.api){
      this.gridOptions.api.showLoadingOverlay();
    }
    let companyResponse = await this.companyService.getCompaniesWithDetails();

    if(companyResponse.success){
      companyResponse.value.forEach(company => {
        let date: any = company.created
        company.created = moment(date._seconds*1000);
      });
      let companies: Array<any> = this.helperService.sortByDateField('created', companyResponse.value);
      this.gridOptions.api.setRowData(companies.reverse());
    }
  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.selectedCompany = selectedRows[0];
    this.initialCompany = this.helperService.deepCopy(this.selectedCompany);
    this.getCompanyNumbers();
  }

  // getCompanies() {
  //   this.companies = [];
  //   this.companyService.get().get().subscribe((companyDocList) => {
  //     companyDocList.docs.forEach(companyDoc => {
  //       let company: models.Company = companyDoc.data();
  //       company.uid = companyDoc.id;
  //       this.companies.push(company);
  //     });
  //     if(this.companies.length != 0){
  //       this.companyChange(this.companies[0])
  //     }
  //   })
  // }

  async saveCompany() {
    this.saving = true;

    let savableCompany: models.Company = {
      freeVersion: this.selectedCompany.freeVersion,
      enableSensors: this.selectedCompany.enableSensors,
      eventsSplit: this.selectedCompany.eventsSplit
    }

    if(this.initialCompany.eventsSplit != savableCompany.eventsSplit && savableCompany.eventsSplit == true){
      this.migrateCompany();
    }

    await this.companyService.savePartial(this.selectedCompany.uid, savableCompany);
    this.initialCompany = this.helperService.deepCopy(this.selectedCompany)
    this.getCompanies();
    this.saving = false;
  }

  async migrateCompany() {
    this.notifierService.success(`Attempting to migrate company.  Go to processes screen to view.`);
    let migrationResult = await this.migrationService.migrateCompany(this.selectedCompany.uid);

    if(!migrationResult.success){
      this.notifierService.error('Could not migrate Company to split events');
      return;
    }
  }

  deleteClick() {
    let r = confirm('Are you sure you want to delete this Company?  This will permanently delete it and all of its data.')
    if(r){
      this.deleteCompany();
    }
  }

  async deleteCompany() {
    this.deleting = true;
    let response = await this.companyService.delete(this.selectedCompany.uid);
    if(response.success) {
      this.gridOptions.api.setRowData([]);
      this.selectedCompany = null;
      this.getCompanies();
      this.snackBar.open(response.message);
    } else {
      this.snackBar.open(response.message);
    }
    this.deleting = false;
  }

  async getCompanyNumbers() {
    this.getCompanyLastActive();
    this.getCompanyHarvests();
    this.getCompanyTemplates();
    this.getCompanyUsers();
  }

  async getCompanyLastActive() {
    this.loadingUser = true;
    let companyActive = await this.companyService.getLastActive(this.selectedCompany.uid);

    if(companyActive.success){
      this.lastActiveUser = companyActive.value.name; 
      this.lastActive = companyActive.value.lastActive; 
    }
    this.loadingUser = false;
  }

  async getCompanyHarvests() {
    this.loadingharvests = true;
    this.harvests = (await (await this.harvestService.getAllForCompany(this.selectedCompany.uid)).get().toPromise()).size;
    this.loadingharvests = false;
  }

  async getCompanyTemplates() {
    this.loadingTemplates = true;
    this.templates = await this.templateService.getNumberOfTemplatesForCompany(this.selectedCompany.uid);
    this.loadingTemplates = false;
  }

  async getCompanyUsers() {
    this.loadingUsers = true;
    let userResponse = await this.userService.getUsersFoCompany(this.selectedCompany.uid);
    this.users = userResponse.data.value.length;
    this.loadingUsers = false;
  }
  
  openCreateCompanyDialog() {
    let dialogRef = this.dialog.open(CreateCompanyComponent, {
      panelClass: 'full-width-dialog',
      autoFocus: true,
      disableClose: false
    })
  };
}
