import { DateRanger } from "app/models";

export interface FilterResponse {
    harvestIds: string[];
    groupIds: string[];
    zoneIds?: string[];
    phases: string[];
    teamIds: string[];
    userIds: string[];
    tags?: string[];
    completeStatus?: string;
    onlyMyTasks?: boolean;
}