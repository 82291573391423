import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { SessionStorageService } from 'angular-web-storage';

import * as enums from '../../models/enumerations';
import * as models from '../../models';
import * as services from '../../services';

@Component({
    selector: "icon-cell",
    template: `
        <mat-chip-list style="vertical-align: middle">
            <mat-chip *ngFor="let tc of trueClaims" style="vertical-align: middle">
                <a *ngIf="rolesRedirect" [routerLink]="['/console/company-settings/security']" [queryParams]="{id: params.value}">
                    {{tc}}
                </a>
                <span *ngIf="!rolesRedirect">{{tc}}</span>
            </mat-chip>
        </mat-chip-list>
    `,
    styles: [
    ]
})
export class RoleComponent implements ICellRendererAngularComp {
    private params: any;

    trueClaims: Array<string> = [];
    currentUserRole: models.Role;

    get rolesRedirect() {
        return this.currentUserRole?.permissions?.includes(models.Permissions.editUserRoles);
    }

    constructor(
        private roleService: services.RoleService
        , private claimsService: services.ClaimsService
    ){}

    async agInit(params: any): Promise<void> {
        if(params.value == null){
            return;
        }
        this.params = params;

        //let roles: Array<models.Role> = this.storage.get('roles');
        let roleId: string = this.params.value;
        let role: models.Role = await this.roleService.getById(roleId);

        this.trueClaims.push(role.name);

        this.currentUserRole = this.claimsService.userRole
    }

    refresh(params: any): boolean {
        return false;
    }
}