import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import * as services from 'app/services';
import * as models from 'app/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Stripe } from 'stripe';
import { MatDialog } from '@angular/material/dialog';
import { BlockNotificationComponent } from 'app/dialogs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Subscription as SquareSubscription } from 'square'

@Component({
  selector: 'app-company-view-and-dropdown',
  templateUrl: './company-view-and-dropdown.component.html',
  styleUrls: ['./company-view-and-dropdown.component.scss']
})
export class CompanyViewAndDropdownComponent implements OnInit, OnDestroy {

  routeSub: any;
  changeCompany: boolean = false;

  companySub: Subscription;

  companies: Array<models.Company>;
  selectedCompany: models.Company;

  constructor(
    private storage: SessionStorageService
    , private companyService: services.CompanyService
    , private snackBar: MatSnackBar
    , private helperService: services.HelperService
    //, private stripeService: services.StripeService
    , private squareService: services.SquareService
    , private dialog: MatDialog
    , private router: Router
  ) { }

  ngOnInit(): void {
    this.getCompanies();
    this.watchRoute();
    this.watchCompanySub();
  }

  watchRoute() {
    this.routeSub = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.checkSquareSubscription();
      }
    });
  }

  watchCompanySub() {
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(companyId => {
      if(this.selectedCompany?.uid != companyId) {
        this.setSelectedCompany();
      }
    })
  }

  ngOnDestroy(): void {
    if(this.routeSub != null) {
      this.routeSub.unsubscribe();
    }
    if(this.companySub != null) {
      this.companySub.unsubscribe();
    }
  }

  async getCompanies() {
    let companyResponse = await this.companyService.getAllCompaniesForUser();

    if(!companyResponse.success){
      this.snackBar.open(companyResponse.message);
      //this.loading = false;
      return;
    }

    this.companies = companyResponse.value;

    if(this.companies.length !== 0){
      this.setSelectedCompany();
    }
  }

  setSelectedCompany(){
    let companyId: string = this.helperService.currentCompanyId;

    if(companyId != null && this.companies.find(i => i.uid == companyId) != null){
      this.selectedCompany = this.companies.find(i => i.uid == companyId);
    } else {
      this.selectedCompany = this.companies[0]
    }

    this.helperService.company_freeVersion = this.selectedCompany.freeVersion || false;
    //this.helperService.company_subscriptionCancelled = this.selectedCompany.stripeSubscriptionCancelled || false;
    if(this.helperService.currentCompanyId != this.selectedCompany.uid){
      this.helperService.currentCompanyId = this.selectedCompany.uid;
    }
    this.helperService.currentCompany = this.selectedCompany;

    //could probably check square here to see if the subscription is valid, if not, change it back to free version
    this.checkSquareSubscription();
  }

  change() {
    this.changeCompany = true;
  }

  selectionChange(value) {
    this.helperService.company_freeVersion = this.selectedCompany.freeVersion || false;
    this.helperService.currentCompanyId = this.selectedCompany.uid;
    this.helperService.currentCompany = this.selectedCompany;
    this.changeCompany = false;

    this.checkSquareSubscription();
  }

  checkSquareSubscription() {
    if(this.selectedCompany == null || this.selectedCompany.freeVersion || this.selectedCompany.paymentProviderCustomerId == null){ //|| this.selectedCompany.stripeSubscriptionCancelled
      return;
    }

    if(this.router.url.includes('billing')){
      return;
    }

    this.squareService.getSubscriptonsBySquareCustomerId(this.selectedCompany.paymentProviderCustomerId).then(data => {
      let result: models.Result<Array<SquareSubscription>> = data.data;

      if(result.success){
        if(result.value == null || result.value.length == 0 || result.value.find(i => i.status == models.SquareSubscriptionStatus.Active || i.status == models.SquareSubscriptionStatus.Pending) == null) {

          let dialogRef = this.dialog.open(BlockNotificationComponent, {
            data: {subscriptions: result.value}
          });

        }
      }
    })

    // this.stripeService.getSubscriptionsByStripeCustomerId(this.selectedCompany.stripeCustomerId).then(data => {
    //   let result: models.Result<Array<Stripe.Subscription>> = data.data;

    //   if(result.success){
    //     if(result.value == null || result.value.length == 0 || result.value.find(i => i.status == 'active' || i.status == 'trialing') == null) {

    //       let dialogRef = this.dialog.open(BlockNotificationComponent, {
    //         data: {subscriptions: result.value}
    //       });

    //     }
    //   }
    // })
  }
}
