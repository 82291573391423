import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { GridOptions, CellDoubleClickedEvent, RowNode, ColDef, SortChangedEvent } from "ag-grid-community";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';

import * as services from '../../../services';
import * as viewmodels from '../../../viewmodels';
import * as models from '../../../models';
import * as cells from 'app/components/cell-components';

import { ProgressBarCellComponent, TimeStampComponent, PhaseDateRangeCellComponent, WarningComponent } from '../../../components/cell-components';

//dialogs
import { MatDialog } from '@angular/material/dialog';
import {
  HarvestAddComponent, HarvestGroupDeleteComponent, RestrictionNotificationComponent
} from '../../../dialogs';
import { Subscription } from 'rxjs';
import { AppStorage } from '../../../services';
// import { TimelineComponent } from 'app/components/timeline/timeline.component';
import { TimelineHelper } from 'app/components/timeline/timeline-helper';
import { TemplateSelectMultiBtnComponent } from 'app/buttons/template-select-multi-btn/template-select-multi-btn.component';
import { LeaflogixSyncBtnComponent } from 'app/buttons/leaflogix-sync-btn/leaflogix-sync-btn.component';
import { Timestamp, getDocs } from '@angular/fire/firestore';
import { HarvestSelectMultiBtnComponent } from 'app/buttons/harvest-select-multi-btn/harvest-select-multi-btn.component';
import { ZoneSelectMultiBtnComponent } from 'app/buttons/zone-select-multi-btn/zone-select-multi-btn.component';

@Component({
  selector: 'app-harvest-list',
  templateUrl: './harvest-list.component.html',
  styleUrls: ['./harvest-list.component.scss']
})
export class HarvestListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('harvestList') harvestList: ElementRef;
  @ViewChild(TemplateSelectMultiBtnComponent) templateSelectMultiBtnComponent: TemplateSelectMultiBtnComponent;
  @ViewChild(HarvestSelectMultiBtnComponent) harvestSelectMultiBtnComponent: HarvestSelectMultiBtnComponent;
  @ViewChild(ZoneSelectMultiBtnComponent) zoneSelectMultiBtnComponent: ZoneSelectMultiBtnComponent;
  @ViewChild(LeaflogixSyncBtnComponent) leaflogixSyncBtnComponent: LeaflogixSyncBtnComponent;

  public gridOptions: GridOptions;
  rowData: Array<any>;

  companyId: string;
  company: models.Company;
  companySub: Subscription;
  harvestsSub: Subscription;

  templateIds: Array<string> = [];

  postHarvestDataPoints: models.PostHarvest[];

  timelineData: Array<any>;
  timelineDateFormat: string = "MMM";
  timelineColors: Array<string>;
  harvests: Array<viewmodels.HarvestTableViewModel> = [];
  maxPhases: number = 0;
  phases: Array<viewmodels.HarvestTableViewPhaseModel> = [];
  harvestIds: string[] = [];
  zoneIds: string[] = [];
  filteredHarvestPhasesByZone: Array<viewmodels.HarvestPhases> = [];
  harvestListSortDirection: string = 'desc';

  get numberOfHarvests(): number {
    return this.gridOptions.api.getDisplayedRowCount()
  }

  get canAdd(): boolean {
    if (this.helperService.company_freeVersion && this.numberOfHarvests >= this.appStorage.freeHarvests) {
      return false;
    }

    return true;
  }

  cellClassRules = {
    "cell-warning": params => params.value == null || params.value == '',
    "": params => params.value != null && params.value != ''
  };

  private allPossibleColumns: Array<any> = [
    {
      headerName: "Name",
      field: "name", 
      sortable: true, resizable: true,
      cellRendererFramework: cells.NameColorCellComponent,
      cellRendererParams: (params) => {
        params.color = params.data.color;
        params.isHarvest = true;
        return params;
      },
      pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    {
      headerName: "Dutchie Name",
      field: "leaflogixName",
      sortable: true, resizable: true,
      //cellClassRules: this.cellClassRules,
      cellRendererFramework: cells.DutchieLinkComponent,
      // cellRendererParams: (params) => {
      //   params.navigateToTemplateFunc = this.navigateToTemplate.bind(this);
      //   return params;
      // },
    },
    {
      headerName: "Clone Date",
      field: "startDate",
      cellRendererFramework: cells.TimeStampComponent,
      cellRendererParams: (params) => {
        params.showDayOfWeek = true;
        return params;
      },
      sortable: true, resizable: true,
      filter: 'agDateColumnFilter',
    },
    {
      headerName: 'Phases',
      resizable: true,
      children: [
        {
          headerName: "",
          field: "3",
          columnGroupShow: "closed"
        },
      ],
    },
    // {
    //   headerName: "Phase 1 Start",
    //   valueGetter: "data.phases[0].startDate",
    //   cellRenderer: "timestampRenderer",
    //   sortable: true, resizable: true,
    //   filter: 'agDateColumnFilter',
    // },
    // {
    //   headerName: "Phase 1 End",
    //   valueGetter: "data.phases[0].endDate",
    //   cellRenderer: "timestampRenderer",
    //   sortable: true, resizable: true,
    //   filter: 'agDateColumnFilter',
    // },
    {
      headerName: "Harvest Date",
      field: "harvestDate",
      cellRendererFramework: cells.TimeStampComponent,
      cellRendererParams: (params) => {
        params.showDayOfWeek = true;
        return params;
      },
      sortable: true, resizable: true,
      filter: 'agDateColumnFilter',
    },
    {
      headerName: "End Date",
      field: "endDate",
      cellRendererFramework: cells.TimeStampComponent,
      cellRendererParams: (params) => {
        params.showDayOfWeek = true;
        return params;
      },
      sortable: true, resizable: true,
      filter: 'agDateColumnFilter',
    },
    {
      headerName: "Completed",
      field: "percentComplete",
      cellRendererFramework: ProgressBarCellComponent,
      sortable: true, resizable: true,
      pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    },
    {
      headerName: "Strains",
      field: "strains",
      sortable: true, resizable: true,
    },
    {
      headerName: "Plant Count",
      field: "plantCount",
      cellRendererFramework: cells.PlantCountCellComponent,
      sortable: true, resizable: true,
    },
    {
      headerName: "Zone",
      field: "zone",
      sortable: true, resizable: true,
    },
    //,
    // {
    //     headerName: "Total Clones",
    //     field: "total_clone",
    //     sortable: true, resizable: true,
    //     pinnedRowCellRenderer: this.gridPinningService.pinnedRowStyle
    // }
  ];

  // views: Array<string> = [
  //   'Pre-Harvest',
  //   'In Progress',
  //   'Post-Harvest'
  // ]
  // view: string;
  // columnGroups: Array<any> = [
  //   {
  //     name: 'Detail',
  //     columns: ['startDate', 'endDate', 'completed']
  //   },
  //   {
  //     name: 'Clone Data',
  //     columns: ['*_clone']
  //   },
  //   {
  //     name: 'Post Harvest Data',
  //     columns: ['*_post']
  //   }
  // ];
  // selectedColumnGroups: Array<any> = [];

  setUpColumns() {
    // let cols = ['name'];
    // cols.push(...this.selectedColumnGroups.flatMap(i => i.columns));

    // let more = cols.filter(i => i.startsWith('*'));
    // cols = cols.filter(i => !i.startsWith('*'));

    // more.forEach(element => {
    //   let suffix = element.replace('*', '');
    //   cols.push(...this.allPossibleColumns.filter(i => i.field.endsWith(suffix)).map(i => i.field))
    // });

    // this.appStorage.harvestListColGroups = this.selectedColumnGroups.map(i => i.name);

    //let currentCols = this.allPossibleColumns.filter(i => cols.includes(i.field));
    let currentCols = this.allPossibleColumns;

    if(this.harvests.filter(i => i.leaflogixName != null).length == 0){
      currentCols = currentCols.filter(i => i.field != 'leaflogixName');
    }

    let phaseColumnsToAdd = []
    this.phases.forEach(phase => {
      let phaseColumn = {
        headerName: phase.name,
        field: phase.name,
        //cellRendererFramework: PhaseDateRangeCellComponent,
        cellRendererFramework: cells.TimeStampComponent,
        cellRendererParams: (params) => {
          params.showDayOfWeek = true;
          return params;
        },
        sortable: true, resizable: true,
        filter: 'agDateColumnFilter',
        columnGroupShow: 'open',
        suppressMovable: true
      }
      phaseColumnsToAdd.push(phaseColumn)
    });

    // for (let i = 0; i < this.maxPhases; i++) {
    //   let phaseColumn = {
    //     headerName: data.phases[i].name,
    //     valueGetter: `data.phases[${i}]`,
    //     cellRendererFramework: PhaseDateRangeCellComponent,
    //     sortable: false, resizable: true,
    //     filter: 'agDateColumnFilter',
    //     columnGroupShow: 'open',
    //   }

    //   // {
    //   //   headerName: "Phase 1 start",
    //   //   valueGetter: "data.phases[0].startDate",
    //   //   cellRenderer: "timestampRenderer",
    //   //   sortable: true, resizable: true,
    //   //   filter: 'agDateColumnFilter',
    //   //   columnGroupShow: 'open',
    //   //   suppressMovable: true,
    //   // },
    //   // {
    //   //   headerName: "data.phases[0].name End",
    //   //   valueGetter: "data.phases[0].endDate",
    //   //   cellRenderer: "timestampRenderer",
    //   //   sortable: true, resizable: true,
    //   //   filter: 'agDateColumnFilter',
    //   //   columnGroupShow: 'open',
    //   //   suppressMovable: true,
    //   // },

    //   phaseColumnsToAdd.push(phaseColumn)
    // }

    let phaseCol = currentCols.find(i => i.headerName == 'Phases')
    phaseCol.children.length = 1;
    phaseCol.children = [...phaseCol.children, ...phaseColumnsToAdd];
    
    // let currentColsWithPhases = [currentCols[0], ...phaseColumnsToAdd, ...currentCols.slice(1)]

    // console.log(currentColsWithPhases);
    
    if (this.gridOptions != null && this.gridOptions.api != null) {
      this.gridOptions.api.setColumnDefs(currentCols);
      this.gridOptions.columnApi.autoSizeAllColumns();
    }

    this.resizeCols();
  }

  async resizeCols() {
    if (!this.deviceService.isMobile() && this.gridOptions != null) {
      await this.helperService.wait(10);
      const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
      this.gridOptions.columnApi.autoSizeColumns(colIds);
      await this.helperService.wait(10);

      let columns = this.gridOptions.columnApi.getAllDisplayedColumns()
      let widthOfCols = 0;
      columns.forEach(col => {
        widthOfCols += col.getActualWidth();
      });
      if (this.harvestList.nativeElement.clientWidth < widthOfCols) {
        const colIds = this.gridOptions.columnApi.getAllColumns().map(c => c.getColId())
        this.gridOptions.columnApi.autoSizeColumns(colIds)
      } else {
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  }

  constructor(
    private deviceService: DeviceDetectorService
    , private claimsService: services.ClaimsService
    , private harvestService: services.HarvestService
    , private eventService: services.EventService
    , private helperService: services.HelperService
    , private router: Router
    , private route: ActivatedRoute
    , private storage: LocalStorageService
    , private dialog: MatDialog
    , public appStorage: AppStorage
    , private strainService: services.StrainService
    , private postHarvestService: services.PostHarvestService
    , private gridPinningService: services.AgGridPinningService
    , private timelineHelper: TimelineHelper
    , private zoneService: services.ZoneService
  ) {
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this
      },
      rowData: this.rowData,
      rowHeight: 35,
      //columnDefs: this.createColumnDefs(),
      onGridReady: () => {
        if (!this.deviceService.isMobile()) {
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      frameworkComponents: {
        timestampRenderer: TimeStampComponent,
      },
      rowSelection: 'multiple',
      //onCellDoubleClicked: this.selectedCell
      onSortChanged: (event: SortChangedEvent) => {
        let sortModel = this.gridOptions.api.getSortModel();
        let startDateSort = sortModel.find(i => i.colId == 'startDate');
        if(startDateSort) {
          this.appStorage.harvestListSortDesc = startDateSort.sort == 'desc';
        }
      }
    };

    this.harvestListSortDirection = this.appStorage.harvestListSortDesc ? 'desc' : 'asc';
  }

  async ngOnInit() {
  }

  ngAfterViewInit(): void {
    //get companyId
    this.companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if (this.companyId != null) {
      //this.getStrains();
      //this.getHarvests();
      setTimeout(() => {
        this.templateSelectMultiBtnComponent.getAll();
      }, 50)
    }
    //set up subscription to listen to company id changes
    this.company = this.helperService.currentCompany;
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.company = this.helperService.currentCompany;
      this.templateSelectMultiBtnComponent.firstLoad = true;
      this.templateSelectMultiBtnComponent.getAll();
    })
    this.setUpColumns();
  }

  ngOnDestroy() {
    if (this.companySub != null) {
      this.companySub.unsubscribe();
    }
    if (this.harvestsSub != null) {
      this.harvestsSub.unsubscribe();
    }
  }

  setUpTutorial() {
    //this.storage.remove('completed-harvest-list-demo');
    let completedDemo = this.storage.get('completed-harvest-list-demo');

    if (completedDemo === true) {
      return;
    }
  }

  // async getStrains() {
  //   let strains = await this.strainService.getAll();
  //   strains.forEach(strain => {
  //     let col: ColDef = {
  //       headerName: strain.name + ' Clones',
  //       field: strain.id + '_clone'
  //     }
  //     this.allPossibleColumns.push(col);
  //   });
  //   this.setUpColumns();
  // }

  // async toggleShowAllHarvests(event) {
  //   await this.getHarvests(event.checked)
  //   this.gridOptions.api.refreshCells();
  // }

  zones: models.Zone[];
  async getZones() {
    let dbZone = await this.zoneService.getDbZone();
    if(dbZone == null){
      return;
    }
    this.zones = dbZone.list;
  }

  async getHarvests() {
    if (this.harvestsSub != null) {
      this.harvestsSub.unsubscribe();
    }
    await this.getZones();

    if (this.zoneIds != null && this.zoneIds.length > 0) {
      this.filteredHarvestPhasesByZone = await this.harvestService.getHarvestsAndPhasesByZoneIds(this.zoneIds);
    }

    this.harvestsSub = (await this.harvestService.getAll(false, !this.appStorage.showFinishedHarvests)).snapshotChanges().subscribe(async snapshot => {
      //remove all data
      if (this.gridOptions.api) {
        this.gridOptions.api.setRowData([]);
      }

      this.phases = [];
      
      //let harvestDataList = await (await this.harvestService.getAll()).get().toPromise();
      let listModels: Array<viewmodels.HarvestTableViewModel> = [];
      for await (const content of snapshot.map(async harvestData => {
        let harvest: models.Harvest = harvestData.payload.doc.data();
        // if (!includeInactive && harvest.endDate < Timestamp.now()) {
        //   return
        // }
        let harvestModel: viewmodels.HarvestTableViewModel = {
          harvest: harvest,
          harvestId: harvestData.payload.doc.id,
          name: harvest.name,
          completed: harvest.completed,
          templateId: harvest.templateId,
          // startDate: Timestamp.fromDate(this.helperService.addYearsToToday(5)),
          // endDate: Timestamp.fromDate(this.helperService.addYearsToToday(-5))
          startDate: harvest.startDate,
          harvestDate: harvest.harvestDate ?? harvest.endDate,
          endDate: harvest.endDate,
          completedTasks: harvest.completedTasks,
          totalTasks: harvest.totalTasks,
          percentComplete: harvest.completed ? 100 : (harvest.completedTasks / harvest.totalTasks) * 100,
          color: harvest.color,
          leaflogixName: harvest.leaflogixName,
          strains: this.getStrainsFromHarvest(harvest),
          plantCount: this.getPlantCountFromHarvest(harvest),
          zone: this.getZoneForHarvest(harvest)
        }

        if(this.company.eventsSplit) {//if we are configured to split events
          harvest.phases?.forEach(phase => {
            harvestModel[phase.phaseName] = phase.start;
  
            if(this.phases.find(i => i.name == phase.phaseName) == null){
              let phaseVM: viewmodels.HarvestTableViewPhaseModel = {
                name: phase.phaseName,
                startDate: phase.start,
                endDate: phase.end
              }
              this.phases.push(phaseVM);
            }
          });

        } else {
          let eventDataList = await getDocs(this.eventService.get(harvestData.payload.doc.id));
  
          let events: models.Event[]
          eventDataList.forEach(eventData => {
              let dbEvent: models.DbEvent = eventData.data()
              events = dbEvent.list
          })
  
          let phases = this.buildPhaseDataArray(events);
          phases.forEach(phase => {
            harvestModel[phase.name] = phase.startDate;
  
            if(this.phases.find(i => i.name == phase.name) == null){
              this.phases.push(phase);
            }
          });
        }

        // check and include harvest if current harvestModel is in the harvestIds selected AND in selected templateIds
        // excluding harvest either if not in selected harvestIds or in selected templateIds
        if (this.harvestIds.includes(harvestModel.harvestId) && this.templateIds.includes(harvestModel.templateId)) {
          listModels.push(harvestModel);
        }
        // listModels.push(harvestModel);
        // //set clone counts
        // if(harvest.phases != null && harvest.phases[0] != null && harvest.phases[0].strains != null){
        //   harvest.phases[0].strains.forEach(strain => {
        //     harvestModel[strain.strainId + '_clone'] = strain.count;
        //   });

        //   harvestModel['total_clone'] = this.helperService.sum(harvest.phases[0].strains.map(i => i.count));
        // }

        // //get post harvest data
        // if(harvest.postHarvestData != null){
        //   let keys = Object.keys(harvest.postHarvestData);
        //   keys.forEach(key => {
        //     harvestModel[key + '_post'] = harvest.postHarvestData[key];
        //   });
        // }

        //set start and end date
        // if (harvestModel.startDate == null || harvestModel.endDate == null) {
        //   // let eventDataList = await this.eventService.get(harvestData.payload.doc.id).get().toPromise();
        //   console.log('going through herherhere')
          
        //   harvestModel.startDate = Timestamp.fromDate(this.helperService.addYearsToToday(5));
        //   harvestModel.endDate = Timestamp.fromDate(this.helperService.addYearsToToday(-5));
        //   console.log(events);
        //   events.forEach(event => {
        //     if (event.startDateTime < harvestModel.startDate) {
        //       harvestModel.startDate = event.startDateTime
        //     }
        //     if (event.startDateTime > harvestModel.endDate) {
        //       harvestModel.endDate = event.startDateTime;
        //     }
        //   })
          
          
          
        //   eventDataList.forEach(eventData => {
        //     let dbEvent: models.DbEvent = eventData.data();

        //     harvestModel.startDate = Timestamp.fromDate(this.helperService.addYearsToToday(5));
        //     harvestModel.endDate = Timestamp.fromDate(this.helperService.addYearsToToday(-5));

        //     dbEvent.list.forEach(event => {
        //       if (event.startDateTime < harvestModel.startDate) {
        //         harvestModel.startDate = event.startDateTime
        //       }
        //       if (event.startDateTime > harvestModel.endDate) {
        //         harvestModel.endDate = event.startDateTime;
        //       }
        //     });
        //   });
        // }

        //put all users returned from the server into the list
      }));

      // check if harvests in listModels are from the zones selected
      if (this.filteredHarvestPhasesByZone.length > 0) {
        let harvestsInZone = this.filteredHarvestPhasesByZone.map(_harvest => _harvest.harvestId);
        listModels = listModels.filter(_harvest => harvestsInZone.includes(_harvest.harvestId));
      }

      this.harvests = listModels;
      this.gridOptions.api.setRowData(listModels);
      this.rowData = listModels;
      let data = this.timelineHelper.createTimelineDataFromHarvestViewModelList(listModels, false)
      this.timelineData = data.dataList
      this.timelineColors = data.colors
      this.gridOptions.api.hideOverlay();

      this.gridOptions.columnApi.getColumn('startDate').setSort(this.harvestListSortDirection)
      this.setUpColumns();
    })
      //this.setTotalRow();

      // snapshot.forEach(async harvestData => {
      //   let harvest: models.Harvest = harvestData.payload.doc.data();
      //   let harvestModel: viewmodels.HarvestTableViewModel = {
      //     harvestId: harvestData.payload.doc.id,
      //     name: harvest.name,
      //     completed: harvest.completed,
      //     templateId: harvest.templateId,
      //     // startDate: Timestamp.fromDate(this.helperService.addYearsToToday(5)),
      //     // endDate: Timestamp.fromDate(this.helperService.addYearsToToday(-5))
      //     startDate: harvest.startDate,
      //     harvestDate: harvest.harvestDate ?? harvest.endDate,
      //     endDate: harvest.endDate,
      //     completedTasks: harvest.completedTasks,
      //     totalTasks: harvest.totalTasks,
      //     percentComplete: harvest.completed ? 100 : (harvest.completedTasks / harvest.totalTasks) * 100,
      //     color: harvest.color,
      //   }

      //   let eventDataList = await this.eventService.get(harvestData.payload.doc.id).get().toPromise();
      //   console.log('harvest id', harvestData.payload.doc.id);
      //   let events: models.Event[]
      //   eventDataList.forEach(eventData => {
      //       let dbEvent: models.DbEvent = eventData.data()
      //       events = dbEvent.list
      //       // console.log(events);
      //   })

      //   harvestModel.phases = this.buildPhaseDataArray(events);

      //   // //set clone counts
      //   // if(harvest.phases != null && harvest.phases[0] != null && harvest.phases[0].strains != null){
      //   //   harvest.phases[0].strains.forEach(strain => {
      //   //     harvestModel[strain.strainId + '_clone'] = strain.count;
      //   //   });

      //   //   harvestModel['total_clone'] = this.helperService.sum(harvest.phases[0].strains.map(i => i.count));
      //   // }

      //   // //get post harvest data
      //   // if(harvest.postHarvestData != null){
      //   //   let keys = Object.keys(harvest.postHarvestData);
      //   //   keys.forEach(key => {
      //   //     harvestModel[key + '_post'] = harvest.postHarvestData[key];
      //   //   });
      //   // }

      //   //set start and end date
      //   if (harvestModel.startDate == null || harvestModel.endDate == null) {
      //     // let eventDataList = await this.eventService.get(harvestData.payload.doc.id).get().toPromise();
      //     console.log('going through herherhere')
          
      //     // harvestModel.startDate = Timestamp.fromDate(this.helperService.addYearsToToday(5));
      //     // harvestModel.endDate = Timestamp.fromDate(this.helperService.addYearsToToday(-5));
      //     // console.log(events);
      //     // events.forEach(event => {
      //     //   if (event.startDateTime < harvestModel.startDate) {
      //     //     harvestModel.startDate = event.startDateTime
      //     //   }
      //     //   if (event.startDateTime > harvestModel.endDate) {
      //     //     harvestModel.endDate = event.startDateTime;
      //     //   }
      //     // })
          
          
          
      //     // eventDataList.forEach(eventData => {
      //     //   let dbEvent: models.DbEvent = eventData.data();

      //     //   harvestModel.startDate = Timestamp.fromDate(this.helperService.addYearsToToday(5));
      //     //   harvestModel.endDate = Timestamp.fromDate(this.helperService.addYearsToToday(-5));

      //     //   dbEvent.list.forEach(event => {
      //     //     if (event.startDateTime < harvestModel.startDate) {
      //     //       harvestModel.startDate = event.startDateTime
      //     //     }
      //     //     if (event.startDateTime > harvestModel.endDate) {
      //     //       harvestModel.endDate = event.startDateTime;
      //     //     }
      //     //   });
      //     // });
      //   }

      //   //put all users returned from the server into the list
      //   listModels.push(harvestModel);
      // });

      // console.log('logging here', listModels);

      // this.gridOptions.api.setRowData(listModels);
      // let data = this.timelineHelper.createTimelineDataFromHarvestViewModelList(listModels)
      // this.timelineData = data.dataList
      // this.timelineColors = data.colors
      // this.gridOptions.api.hideOverlay();

      // this.gridOptions.columnApi.getColumn('startDate').setSort("desc")
      // this.setUpColumns();
      // //this.setTotalRow();
  }

  getStrainsFromHarvest(h: models.Harvest): string {
    // Use flatMap to both map and flatten the array in one operation
    let strains = h.phases?.flatMap(phase => 
      phase.strains?.filter(strain => strain.strainName != null).map(strain => strain.strainName)
    ) || []; // Fallback to empty array if h.phases is undefined
    
    let uniqueStrains = new Set(strains.filter(i => i != null)); // Create a Set to ensure uniqueness
    
    return [...uniqueStrains].join(', '); // Convert the Set back to an array and join into a string
  }

  getPlantCountFromHarvest(h: models.Harvest): number {
    let noneNullStrains = h.phases?.filter(i => i.strains != null) || [];
    if(noneNullStrains.length == 0) {
      return null;
    }

    for (let i = noneNullStrains.length-1; i >= 0; i--) {
      const phaseStrains = noneNullStrains[i];
      let counts = phaseStrains.strains.map(i => i.count);
      if(counts.length > 0) {
        var sum = counts.reduce((p, c) => {return p+c});
        if(sum > 0) {
          return sum;
        }
      }
    }
    return 0;
  }

  getZoneForHarvest(h: models.Harvest) {
    let noneNullStrains = (h.phases?.filter(i => i.strains != null) || []).filter(i => i.strains.length > 0);
    if(noneNullStrains.length == 0) {
      return null;
    }
    let lastPhaseStrains = noneNullStrains[noneNullStrains.length-1];
    let zoneIds = new Set(lastPhaseStrains.strains.filter(i => i.zones != null).flatMap(i => i.zones).map(i => i.zoneId).filter(i => i != null))
    let zones = this.zoneService.getAllTopLevel(this.zones, [...zoneIds]);
    return zones.map(i => i.name).join(', ')
  }

  // setTotalRow() {
  //   let numberCols = [];
  //   this.allPossibleColumns.forEach(col => {
  //     if(col.field.endsWith('_clone')){
  //       numberCols.push(col.field);
  //     }
  //   });
  //   // this.postHarvestDataPoints.filter(i => i.dataType == models.DataTypes.number).forEach(numberDataPoints => {
  //   //   numberCols.push(`${numberDataPoints.systemValue}_post`);
  //   // });
  //   this.gridPinningService.setPinnedData(this.gridOptions.api, this.allPossibleColumns.map(i => i.field), ['name'], [], numberCols);
  // }
  
  buildPhaseDataArray(events: models.Event[]) {
    const phases = [...new Set(events?.map(event => event.phase))]
    if (phases.length > this.maxPhases) {
      this.maxPhases = phases.length;
    }

    const phaseDataArray: viewmodels.HarvestTableViewPhaseModel[] = [];

    phases.forEach(phase => {
      const filteredEvents = events.filter(event => event.phase == phase)
      let startAndEndDate = this.timelineHelper.getStartAndEndDateForPhase(filteredEvents)
      let phaseData: viewmodels.HarvestTableViewPhaseModel = {
        name: phase,
        startDate: Timestamp.fromDate(startAndEndDate[0]),
        endDate: Timestamp.fromDate(startAndEndDate[1]),
      }

      phaseDataArray.push(phaseData);
    })

    return phaseDataArray
  }

  selectedCell(event: CellDoubleClickedEvent) {
    let harvestId: string = event.data.harvestId;

    // this.router.navigate([], {
    //   relativeTo: this.route,

    // })
    // const newUrl = this.router.createUrlTree([
    //   merge([{'a': 123}], this.route.snapshot.params)
    // ], {relativeTo: this.route});

    this.router.navigate(['console', 'harvest', harvestId]);
  }

  get canSeeHarvestAdd(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addHarvest);
  }

  addedHarvests(dialogResult: any) {
    // if(dialogResult.harvestIds && dialogResult.harvestIds.length > 0) {
    //   this.getHarvests();
    // }

    // if(dialogResult.linked && this.leaflogixSyncBtnComponent != null){
    //   this.leaflogixSyncBtnComponent.sync();
    // }
  }

  addHarvest() {
    if (!this.canAdd) {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'harvestAdd'
      });
      return;
    }

    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: false }
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.reloadHarvests();
    })
  }

  get canSeeHarvestGroupDelete(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteHarvest) ||
           this.claimsService.userRole?.permissions?.includes(models.Permissions.deleteGroup);
  }

  harvestGroupDelete() {
    let dialogRef = this.dialog.open(HarvestGroupDeleteComponent, {
      panelClass: 'full-width-dialog',
      data: { hideGroups: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadHarvests();
    })
  }

  exportAsCSV() {
    let params: any = {};
    params.processCellCallback = (params) => {
      if (params.value instanceof Timestamp) {
        return this.helperService.timestampToDateString(params.value);
      } else {
        return params.value;
      }
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }

  getRowData() {
    var rowData = [];
    if (this.gridOptions.api == null) {
      return rowData;
    }

    this.gridOptions.api.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  async reset() {
    this.zoneIds = [];
    this.zoneSelectMultiBtnComponent.selected = [];
    this.harvestSelectMultiBtnComponent.selected = [];
    await this.harvestSelectMultiBtnComponent.getHarvests();
    this.templateSelectMultiBtnComponent.selected = [];
    await this.templateSelectMultiBtnComponent.getAll();
    this.filteredHarvestPhasesByZone = [];
    this.getHarvests();
  }

  async reloadHarvests() {
    //await this.helperService.wait(1500);
    this.harvestSelectMultiBtnComponent.selected = [];
    this.harvestSelectMultiBtnComponent.getHarvests();
  }
}
