import { Component, OnInit, Inject } from '@angular/core';
import * as models from '../../models';
import * as services from '../../services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-template-workflow-delete',
  templateUrl: './template-workflow-delete.component.html',
  styleUrls: ['./template-workflow-delete.component.scss']
})
export class TemplateWorkflowDeleteComponent implements OnInit {

  deleting: boolean = false;

  primaryTemplates: Array<models.Template> = [];
  secondaryTemplates: Array<models.Template> = [];

  constructor(
    private dialogRef: MatDialogRef<TemplateWorkflowDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notiferService: services.NotifierService,
    private templateService: services.TemplateService
  ) { }

  ngOnInit(): void {
  }

  deleteClick() {
    
    if(this.primaryTemplates.length === 0 && this.secondaryTemplates.length === 0){
      this.notiferService.error('Must select at least one Template or Workflow to continue.');
      return;
    }

    let confirmationText = `Are you sure you want to delete`;
    if(this.primaryTemplates.length > 0){
      confirmationText += ` ${this.primaryTemplates.length} Template(s)`;
    }
    if(this.primaryTemplates.length > 0 && this.secondaryTemplates.length > 0) {
      confirmationText += ` and`;
    }
    if(this.primaryTemplates.length > 0){
      confirmationText += ` ${this.secondaryTemplates.length} Workflow(s)`;
    }
    confirmationText += `?  This will delete them permanently.`;

    this.notiferService.confirm('Delete?', confirmationText, 
      () => {this.delete([...this.primaryTemplates, ...this.secondaryTemplates])},
      () => {});
  }

  delete(templatesToDelete: models.Template[]) {
    this.deleting = true;

    let methods: Promise<any>[] = [];
    
    if(templatesToDelete.length != 0){
      templatesToDelete.forEach(template => {
        let templateDeletePromise = this.templateService.delete(template);
        methods.push(templateDeletePromise);
      })
    }

    Promise
      .all(methods)
      .then(() => {
        this.notiferService.success(templatesToDelete.length > 1 ? `Templates` : `Template` + ` Removed.`);
        this.deleting = false;
        this.dialogRef.close({
          deleted: true,
        });
      })
      .catch(error => {
          this.notiferService.error('An Error as occured.');
          console.log(JSON.stringify(error))
          this.deleting = false;
      });
  }

}
