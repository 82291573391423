import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import { ForgotPasswordComponent } from '../../dialogs/forgot-password/forgot-password.component';

import * as services from '../../services';

import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';
import { onAuthStateChanged, Auth } from '@angular/fire/auth';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  userName: string;
  password: string;

  constructor(
      private authService: services.AuthService
      , public dialog: MatDialog
      , private titleService: Title
      , private router: Router
      , private zone: NgZone
      , private auth: Auth
      , private notifierService: services.NotifierService
    ) { }

  ngOnInit() {
    this.titleService.setTitle('Login - PlanaCan')
    this.initiate();
    this.checkAuthStatus();
  }
  
  checkAuthStatus() {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        //window.location.href = '/console';
        this.zone.run(() => this.router.navigateByUrl('/console'));
        //this.router.navigate(['console']);
      } 
    });
  }

  async loginClick() {
    //need to try to log into firebase

    try {
      await this.authService.signIn(this.userName, this.password);
      
      this.notifierService.success('Signed in Successfully.');

      let afterLogin = sessionStorage.getItem('afterLogin');
      if(afterLogin){
        window.location.href = afterLogin;
        sessionStorage.removeItem('afterLogin')
      } else {
        window.location.href = '/console';
      }

      //this.router.navigate(['console']);
    }
    catch(ex){
      this.notifierService.error('Could not sign in, please check your authentification preference.');
    }
  }

  initiate(): void {
    
    /*==================================================================
    [ Focus input ]*/
    $('.input100').each(function(){
      $(this).on('blur', function(){
          if($(this).val().trim() != "") {
              $(this).addClass('has-val');
          }
          else {
              $(this).removeClass('has-val');
          }
      })    
    })


    /*==================================================================
    [ Validate ]*/
    var input = $('.validate-input .input100');

    $('.validate-form').on('submit',function(){
        var check = true;

        for(var i=0; i<input.length; i++) {
            if(validate(input[i]) == false){
                showValidate(input[i]);
                check=false;
            }
        }

        return check;
    });


    $('.validate-form .input100').each(function(){
        $(this).focus(function(){
          hideValidate(this);
        });
    });

    function validate (input) {
        if($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
            if($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
                return false;
            }
        }
        else {
            if($(input).val().trim() == ''){
                return false;
            }
        }
    }

    function showValidate(input) {
        var thisAlert = $(input).parent();

        $(thisAlert).addClass('alert-validate');
    }

    function hideValidate(input) {
        var thisAlert = $(input).parent();

        $(thisAlert).removeClass('alert-validate');
    }
    
    /*==================================================================
    [ Show pass ]*/
    var showPass = 0;
    $('.btn-show-pass').on('click', function(){
        if(showPass == 0) {
            $(this).next('input').attr('type','text');
            $(this).find('i').removeClass('zmdi-eye');
            $(this).find('i').addClass('zmdi-eye-off');
            showPass = 1;
        }
        else {
            $(this).next('input').attr('type','password');
            $(this).find('i').addClass('zmdi-eye');
            $(this).find('i').removeClass('zmdi-eye-off');
            showPass = 0;
        }
        
    });
  }

  forgotPasswordClick(){
    
    let dialogRef = this.dialog.open(ForgotPasswordComponent, {
      panelClass: 'med-width-dialog'
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   //reload list
    //   this.getFromServer();
    //   console.log(JSON.stringify(result));
    // })
  }

  async googleClick() {
    try {
      await this.authService.signInViaGoogle();

      this.notifierService.success('Signed in Successfully.');

      let afterLogin = sessionStorage.getItem('afterLogin');
      if(afterLogin){
        window.location.href = afterLogin;
        sessionStorage.removeItem('afterLogin')
      } else {
        window.location.href = '/console';
      }

      //this.router.navigate(['console']);
    }
    catch(ex){
      if(ex.code == 'auth/popup-closed-by-user') {
        return;
      }
      let temp: FirebaseError = ex;
      if(temp.message.includes('Firebase: HTTP Cloud Function returned an error: ')) {
        let errorMessage = temp.message.replace('Firebase: HTTP Cloud Function returned an error: ', '');
        errorMessage = errorMessage.replace(' (auth/internal-error).', '');
        let error = this.tryParse(errorMessage);
        this.notifierService.error(error.error.message);
      } else {
        this.notifierService.error('Could not sign in, please check your authentification preference.');
      }
    }
  }

  async microsoftClick () {
    try {
      await this.authService.signInViaMicrosoft();
      
      this.notifierService.success('Signed in Successfully.');

      let afterLogin = sessionStorage.getItem('afterLogin');
      if(afterLogin){
        window.location.href = afterLogin;
        sessionStorage.removeItem('afterLogin')
      } else {
        window.location.href = '/console';
      }

      //this.router.navigate(['console']);
    }
    catch(ex){
      if(ex.code == 'auth/popup-closed-by-user') {
        return;
      }
      let temp: FirebaseError = ex;
      if(temp.message.includes('Firebase: HTTP Cloud Function returned an error: ')) {
        let errorMessage = temp.message.replace('Firebase: HTTP Cloud Function returned an error: ', '');
        errorMessage = errorMessage.replace(' (auth/internal-error).', '');
        let error = this.tryParse(errorMessage);
        this.notifierService.error(error.error.message);
      } else {
        this.notifierService.error('Could not sign in, please check your authentification preference.');
      }
    }
  }

  tryParse (jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};
}
