<h2 mat-dialog-title>Zones assigned to phase - {{phase.phaseName}}</h2>
<mat-dialog-content style="min-width: 50vw;">
  <form id="savephaseZone" class="zone-form" (ngSubmit)="savephaseZone()">
    <app-zone-setup [edit]="false" [(strains)]="phase.strains"></app-zone-setup>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button type="submit" form="savephaseZone">Ok</button>
</mat-dialog-actions>
