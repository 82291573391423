<h2 mat-dialog-title>Select Teams/Users</h2>
<mat-dialog-content>
  <!-- <div class="container" *ngIf="!retrievedUsers">
    <mat-spinner style="margin: auto" diameter=25 strokeWidth=4></mat-spinner>
  </div>

  <button mat-raised-button class="right" (click)="assignToAll()" *ngIf="retrievedUsers">
    Assign to All
  </button>
  <form id="selectUsers" [formGroup]="userIdsForm" (ngSubmit)="submit()" *ngIf="retrievedUsers">
    <div formArrayName="users" *ngFor="let user of userIdsForm.get('users')['controls']; let i = index" >
        <mat-checkbox class="example-margin" [formControlName]="i">
        {{users[i].displayName}}
        </mat-checkbox>
    </div>
  </form> -->

  <div class="row">
    <div class="col-sm-6">
      <app-team-select-multiple [displayTeamList]="true" [(teamIds)]="assignedTeamIds"></app-team-select-multiple>
    </div>
    <div class="col-sm-6">
      <app-user-select-multiple [displayUserList]="true" [(userIds)]="assignedUserIds"></app-user-select-multiple>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button (click)="submit()">OK</button>
</mat-dialog-actions>
