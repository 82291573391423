import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class LeaflogixService {

  constructor(
    private helperService: HelperService
    , private functions: Functions
  ) {}
  
  async syncLeaflogixCompany() {
    let companyId: string = this.helperService.currentCompanyId;
    
    var method = httpsCallable(this.functions, 'syncLeaflogixCompany');
    var response = await method({companyId: companyId});

    return response;
  }
  
  async getUnlinkedBatches() {
    let companyId: string = this.helperService.currentCompanyId;
    
    var method = httpsCallable(this.functions, 'getUnlinkedLeaflogixBatches');
    var response = await method({companyId: companyId});

    return response;
  }
  
  async getUnlinkedHarvests() {
    let companyId: string = this.helperService.currentCompanyId;
    
    var method = httpsCallable(this.functions, 'getUnlinkedLeaflogixHarvests');
    var response = await method({companyId: companyId});

    return response;
  }
  
  async integrateStrains() {
    let companyId: string = this.helperService.currentCompanyId;
    
    var method = httpsCallable(this.functions, 'integrateStrains');
    var response = await method({companyId: companyId});

    return response;
  }
  
  async integrateZones() {
    let companyId: string = this.helperService.currentCompanyId;
    
    var method = httpsCallable(this.functions, 'integrateZones');
    var response = await method({companyId: companyId});

    return response;
  }
}
