import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: "unit-cell",
    template: `<span *ngIf="params.value != null">{{params.value}}{{unit}}</span>`,
    styles: []
})
export class UnitCellComponent implements ICellRendererAngularComp {
    params: any;

    value: any;
    unit: string;

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;
        this.unit = this.params.unit;
    }

    refresh(params: any): boolean {
        return false;
    }
}
