<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-chip-list [disabled]="disabled" #chipList aria-label="Chips">
      <mat-chip
        *ngFor="let chip of chips"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(chip)">
        {{chip}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="New..."
        #chipInput
        [formControl]="chipCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
        {{chip}}
      </mat-option>
    </mat-autocomplete>
</mat-form-field>