<h2 mat-dialog-title>
  <span style="display: inline-block">Create Step from Library</span>
</h2>
<mat-dialog-content style="min-width: 30vw">
    <div class="row">
        <div class="col-12">
            <app-templated-task-select
                [(selectedTask)]="selectedTemplate">
            </app-templated-task-select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="type">
                <mat-radio-button value="single">Single</mat-radio-button>
                <mat-radio-button value="list">List</mat-radio-button>
                <mat-radio-button value="range">Range</mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="type == 'single'">
                <mat-form-field>
                    <input matInput type="number" [(ngModel)]="single" placeholder="Day">
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="type == 'list'">
                <mat-chip-list #chipList aria-label="Days">
                    <mat-chip *ngFor="let item of list" [selectable]="true" [removable]="true"
                        (removed)="remove(item)">
                        {{item}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input type="number" placeholder="New day..." [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </ng-container>
            <ng-container *ngIf="type == 'range'">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="rangeStart" placeholder="Start">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <input matInput type="number" [(ngModel)]="rangeEnd" placeholder="End">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="save()">Save</button>
</mat-dialog-actions>
