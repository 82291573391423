<h2 mat-dialog-title class="dialog-title">
    Change Color
</h2>
<mat-dialog-content>
    <!-- Color the Harvest or Group will be -->
    <app-color-picker [saveAsGenericGroup]="saveAsGenericGroup" [(color)]="color"></app-color-picker>
    
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close [disabled]="saving">Cancel</button>
    <button mat-button type="submit" [disabled]="saving" (click)="save()">
      <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
      <span *ngIf="!saving">Save</span>
    </button>
</mat-dialog-actions>