import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventListComponent } from 'app/components/event/event-list/event-list.component';
import { GroupService, ClaimsService, NotifierService, EventService } from 'app/services';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ComponentCanDeactivate } from '../../../services/guards/component-can-deactivate';
import * as models from '../../../models';
import { HelperService } from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { MatDialog } from '@angular/material/dialog';
import { EventAddComponent, EventAddFromLibraryDialogComponent } from 'app/dialogs';

const returnUrl: string = '/console/task-groups';

@Component({
  selector: 'app-task-group-detail',
  templateUrl: './task-group-detail.component.html',
  styleUrls: ['./task-group-detail.component.scss']
})
export class TaskGroupDetailComponent implements OnInit, OnDestroy {

  @ViewChild(EventListComponent) eventListComponent: EventListComponent;

  company: models.Company;
  pageLoaded: boolean = false;
  collectionType: string = "groups";
  taskGroup: models.Group;
  initialTaskGroup: models.Group;
  taskGroupId: string;

  taskGroupSub: Subscription;

  get saveBtnEnabled() {
    return this.hasPendingChanges || this.eventListComponent?.hasPendingChanges;
  }

  get hasPendingChanges() {
    let x = JSON.stringify(this.taskGroup);
    let y = JSON.stringify(this.initialTaskGroup);
    return x != y;
  }

  get canEdit() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.editGroup);
  }

  get canCompleteEvent(): boolean { 
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.completeEvent);
  }

  get canAddEvent(): boolean { 
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private groupService: GroupService,
    private helperService: HelperService,
    private router: Router,
    private titleService: Title,
    private notifierService: NotifierService,
    private dialog: MatDialog,
    private eventService: EventService
  ) {
  }

  ngOnInit(): void {
    this.company = this.helperService.currentCompany;
    this.getTaskGroup()
  }

  ngOnDestroy() {
    if(!this.taskGroupSub) {
      this.taskGroupSub.unsubscribe();
    };
  }

  canDeactivate() : boolean {
    return !this.hasPendingChanges && !this.eventListComponent.hasPendingChanges;
  }

  async getTaskGroup() {
    this.activatedRoute.params.subscribe(params => {
      this.taskGroupId = params.id
    })
    this.taskGroupSub = await this.groupService.get(this.taskGroupId).snapshotChanges().subscribe(taskGroupResponse => {
      if(taskGroupResponse.payload.exists) {
        this.taskGroup = taskGroupResponse.payload.data();
        this.taskGroup.uid = taskGroupResponse.payload.id;
        this.initialTaskGroup = this.helperService.deepCopy(this.taskGroup);
        this.titleService.setTitle(`${this.taskGroup.name} - Planacan`)
        this.pageLoaded = true
      } else {
        this.router.navigateByUrl(returnUrl)
      }
    })
  };

  eventListChange(events: Array<models.Event>){
    //this.events = events;
    if(events == null){
      return;
    }
    this.pageLoaded = true;
  }

  returnToList() {
    this.router.navigateByUrl(returnUrl)
  }

  async saveChanges() {
    if(this.hasPendingChanges) {
      this.saveGroupChanges();
    }
    if(this.eventListComponent.hasPendingChanges){
      this.eventListComponent.saveChanges();
    }
  }

  undoChanges() {
    if(this.hasPendingChanges) {
      this.getTaskGroup()
    }
    if(this.eventListComponent.hasPendingChanges){
      this.eventListComponent.undoChanges();
    }
  }

  async saveGroupChanges() {
    await this.groupService.update(this.taskGroup)
    this.notifierService.success(`Successfully saved Task Group '${this.taskGroup.name}'`);
  }

  // saveName(event) {
  //   event.target.blur()
  //   this.saveChanges()
  // }

  // add() {
  //   let dialogRef = this.dialog.open(HarvestAddComponent, {
  //     data: { addGroup: true }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     //reload list
  //     if (result) {
  //       this.lo
  //     }
  //     console.log(JSON.stringify(result));
  //   })
  // }

  backToGroupList() {
    this.router.navigate(['console', 'task-groups']);
  }

  addEvent() {
    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: { 
        groupId: this.taskGroupId,
        addFromGroupDetail: true,
      },
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.processEventAddResponse(result);
    });
  }

  addEventFromLibrary() {
    let inObj: any = {
      groupId: this.taskGroupId,
    }
    let dialogRef = this.dialog.open(EventAddFromLibraryDialogComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe((result: viewmodels.EventAddResponse) => {
      this.processEventAddResponse(result);
    })
  }

  async processEventAddResponse(result: viewmodels.EventAddResponse) {
    if(result && result.save){
      //perform save
      let assignToGroup: boolean = result.assignToGroup;
      let harvest: models.Harvest = result.harvest;
      let group: models.Group = result.group;

      let events: Array<models.Event> = result.events;
      let dbEvents: Array<models.Event> = [];

      events.forEach(event => {
        let dbEvent: models.Event = event;

        dbEvent.color = assignToGroup ? group.color : harvest.color;

        if(assignToGroup){
          delete dbEvent.harvestName;
          dbEvent.groupName = group.name;
        } else {
          delete dbEvent.groupName;
          dbEvent.harvestName = harvest.name;
        }

        dbEvents.push(dbEvent);
      });

      await this.eventService.add(assignToGroup, assignToGroup ? group.uid : harvest.uid, dbEvents);
    }
  }
}
