import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as services from '../../services';
import * as models from '../../models';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit {

  @Input() notification: models.CompanyNotification;
  @Input() roles: Array<models.Role> = [];

  @Output() deleteNotification = new EventEmitter<any>();

  private _selectedRoles: Array<models.Role>;
  get selectedRoles(): Array<models.Role> {
    if(this._selectedRoles == null){
      if(this.notification.role != null){
        let roleIds = this.notification.role.toString().split(',');
        this._selectedRoles = this.roles.filter(i => i.id != null && roleIds.includes(i.id.toString()))
      } else {
        this._selectedRoles = [];
      }
      
    }
    return this._selectedRoles;
  }
  set selectedRoles(value: Array<models.Role>) {
    this.notification.role = value.map(i => i.id).join(',');
    this._selectedRoles = value;
  }

  frequencies: Array<any> = [
    { key: 'daily', display: 'Daily' },
    { key: 'weekly', display: 'Weekly' },
    { key: 'monthly', display: 'Monthly' }
  ]
  //frequency: string = 'daily';

  frequencyChange(e: any, n: models.CompanyNotification) {
    if(e.value == 'weekly' && n.dayOfWeek == null){
      n.dayOfWeek = 1
    }
    if(e.value == 'monthly' && n.dayOfMonth == null){
      n.dayOfMonth = 'first'
    }
  }

  daysOfWeek: Array<any> = [
    { key: 0, display: 'Sunday' },
    { key: 1, display: 'Monday' },
    { key: 2, display: 'Tuesday' },
    { key: 3, display: 'Wednesday' },
    { key: 4, display: 'Thursday' },
    { key: 5, display: 'Friday' },
    { key: 6, display: 'Saturday' },
  ]
  //dayOfWeek: string = 'monday'

  daysOfMonth: Array<any> = [
    { key: 'first', display: 'First' },
    { key: 'middle', display: '15th' },
    { key: 'last', display: 'Last' },
  ]
  //dayOfMonth: string = 'first'

  hours: Array<any> = [
    { key: 1, display: '1' },
    { key: 2, display: '2' },
    { key: 3, display: '3' },
    { key: 4, display: '4' },
    { key: 5, display: '5' },
    { key: 6, display: '6' },
    { key: 7, display: '7' },
    { key: 8, display: '8' },
    { key: 9, display: '9' },
    { key: 10, display: '10' },
    { key: 11, display: '11' },
    { key: 12, display: '12' },
  ]

  getTitle(n: models.CompanyNotification){
    let frequency = this.helperService.capitalizeFirstLetter(n.frequency);
    let frequencyAdder = '';
    if(n.frequency == 'weekly'){
      frequencyAdder = ' (' + this.helperService.capitalizeFirstLetter(this.daysOfWeek[n.dayOfWeek].display) + ")";
    } else if (n.frequency == 'monthly'){
      frequencyAdder = ' (' + this.helperService.capitalizeFirstLetter(n.dayOfMonth) + ")";
    }
    let notificationRoles = n.role != null ? n.role.split(',') : [];
    let roles = [];
    let foundRoles = this.roles.filter(i => i.id != null && notificationRoles.includes(i.id.toString()));
    foundRoles.forEach(foundRole => {
      let roleName = this.helperService.capitalizeFirstLetter(foundRole.name);
      roles.push(roleName);
    });

    let title = `${frequency}${frequencyAdder} - ${n.hour}${n.ampm} - ${roles.join(', ')}`;
    return title;
  }

  exampleMessage: string = '';
  assigned: boolean = false;
  incomplete: boolean = false;

  tokens = new models.Tokens().tokens;

  get token() : models.Token {
    let token = this.tokens.find(i => 
      i.assigned == this.assigned && 
      i.incomplete == this.incomplete &&
      i.timeframe == this.notification.frequency)
    
    this.notification.message = token.message;

    return token;
  }

  constructor(
    private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    let found = this.tokens.find(i => i.message == this.notification.message);
    if(found != null){
      this.assigned = found.assigned;
      this.incomplete = found.incomplete;
    }
  }

  delete() {
    this.deleteNotification.next(this.notification);
  }

}
