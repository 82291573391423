import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { canActivate, loggedIn, redirectLoggedInTo } from '@angular/fire/auth-guard';
//const redirectUnauthorizedToLogin: AuthPipeGenerator = (next: ActivatedRouteSnapshot) => map((user: User) => !!user && next.params.userId === user.uid);
//const redirectUnauthorizedToLogin = redirectUnauthorizedTo(['login']);

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { CompanySelectComponent } from './pages/company-select/company-select.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { FormInsertComponent } from './pages/form-insert/form-insert.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NoCompaniesComponent } from './pages/no-companies/no-companies.component';

//import { MarketingLayoutComponent } from './layouts/marketing-layout/marketing-layout.component';

//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
// const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
//   return redirectUnauthorizedTo([`/login?redirectTo=${state.url}`]);
// };
const redirectUnauthorizedToLogin = (route: ActivatedRouteSnapshot) => {
  return pipe(
    loggedIn,
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        let p = window.location.pathname + window.location.search;
        console.log('Saving afterLogin path', p);
        sessionStorage.setItem('afterLogin', p);
      }
    }),
    map(loggedIn => loggedIn || ['/login'])
  );
};
const redirectLoggedInToConsole = () => redirectLoggedInTo(['console']);

const routes: Routes =[
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // }, 
  { path: 'login', component: LoginComponent,
    ...canActivate(redirectLoggedInToConsole), data: { authGuardPipe: redirectLoggedInToConsole } 
  },
  { path: 'no-permissions', component: NoPermissionsComponent },
  { path: 'no-companies', component: NoCompaniesComponent },
  { 
    path: 'company-select', 
    component: CompanySelectComponent,
    ...canActivate(redirectLoggedInToConsole)
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'form-insert', component: FormInsertComponent },
  { path: 'terms', component: TermsAndConditionsComponent },
  { 
    path: 'upgrade', 
    component: PaymentComponent,
    ...canActivate(redirectLoggedInToConsole),
    data: { authGuardPipe: redirectUnauthorizedToLogin } 
  },
  { path: 'signup', component: SignupComponent },
  { 
    path: 'confirm', 
    component: ConfirmComponent,
    ...canActivate(redirectLoggedInToConsole),
    data: { authGuardPipe: redirectUnauthorizedToLogin }  
  },
  {
    path: 'console',
    component: AdminLayoutComponent,
    ...canActivate(redirectLoggedInToConsole),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    //...canActivate(redirectUnauthorizedToLogin),
    children: [
        {
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
  }]},
  // {
  //   path: 'marketing',
  //   component: MarketingLayoutComponent,
  //   children: [
  //       {
  //     path: '',
  //     loadChildren: () => import('./layouts/marketing-layout/marketing-layout.module').then(m => m.MarketingLayoutModule)
  // }]},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) //, {onSameUrlNavigation: 'reload'}
 //, {onSameUrlNavigation: 'reload'}
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
