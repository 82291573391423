import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  @Input() label = 'Number'
  @Input() options: string[];

  sub: Subscription;

  myControl = new FormControl();
  //options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  val: string;
  @Input()
  get value() {
    return this.val;
  }
  @Output() valueChange = new EventEmitter();
  set value(val) {
    this.val = val;
    this.valueChange.emit(this.val);
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    this.sub = this.myControl.valueChanges.subscribe(value => {
      this.value = value; //set ngModel
    })

    this.myControl.setValue(this.value)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
