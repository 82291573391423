import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';

@Component({
  selector: 'app-filter-set-save-dialog',
  templateUrl: './filter-set-save-dialog.component.html',
  styleUrls: ['./filter-set-save-dialog.component.scss']
})
export class FilterSetSaveDialogComponent implements OnInit {

  filterSet: models.FilterSet;
  saving: boolean = false;

  includeHarvests: boolean = false;
  includeGroups: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<FilterSetSaveDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private filterSetService: services.FilterSetService
  ) { 
    this.filterSet = data
  }

  ngOnInit(): void {
  }

  async save() {
    // if(!this.includeHarvests){
    //   delete this.filterSet.harvestIds
    // }
    // if(!this.includeGroups){
    //   delete this.filterSet.groupIds
    // }

    this.saving = true;
    let id = await this.filterSetService.save(this.filterSet)
    this.dialogRef.close(id);
    this.saving = false;
  }
}