<!-- {{chip.name}}<ng-container *ngIf="!lastInList && (chip.children == null || chip.children.length == 0)">, </ng-container>
<ng-container *ngIf="lastInList || (chip.children != null && chip.children.length > 0)"> -> </ng-container>

<ng-container *ngFor="let c of chip.children; let i = index">
    <app-zone-identifiers-detail [chip]="c" [lastInList]="chip.children.length == i+1"></app-zone-identifiers-detail>
</ng-container> -->

<ul>
    <li *ngFor="let zone of zones">
        {{zone.name}} <span *ngIf="zone.placements != null" style="font-size: 14px; color: #43a047">({{zone.placements}})</span>
        <ng-container *ngIf="zone.zones != null && zone.zones.length > 0">
            <app-zone-identifiers-detail [zones]="zone.zones"></app-zone-identifiers-detail>
        </ng-container>
    </li>
</ul>
