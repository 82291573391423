import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { SessionStorageService } from 'angular-web-storage';

import * as enums from '../../models/enumerations';
import * as models from '../../models';
import * as services from '../../services';

@Component({
    selector: "zone-hierarchy-cell",
    template: `
        {{hierarchy}}
    `,
    styles: [
    ]
})
export class ZoneHierarchyComponent implements ICellRendererAngularComp {
    private params: any;

    hierarchy: string = '';

    constructor(
        private zoneService: services.ZoneService
    ) { }

    async agInit(params: any): Promise<void> {
        if (params.value == null) {
            return;
        }
        this.params = params;

        if(this.params.value == null){
            return;
        }

        let zones = await this.zoneService.getAll();
        let parentNames = this.zoneService.getListOfParentNames(zones, this.params.value);
        let zone = this.zoneService.getZone(zones, this.params.value);

        this.hierarchy = zone.name;

        for (let index = parentNames.length-1; index >= 0; index--) {
            const element = parentNames[index];
            
            this.hierarchy = `${element} -> ${this.hierarchy}`
        }
    }

    refresh(params: any): boolean {
        return false;
    }
}