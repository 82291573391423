import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import * as services from 'app/services'
import * as models from 'app/models'
import { Timestamp, getDocs } from '@angular/fire/firestore';

@Component({
  selector: 'app-phase-select',
  templateUrl: './phase-select.component.html',
  styleUrls: ['./phase-select.component.scss']
})
export class PhaseSelectComponent implements OnInit, OnChanges {

  phases: Array<string> = [];
  events: Array<models.Event> = [];

  @Input() harvestId: string;
  @Input() disabled: boolean = false;
  @Input() date: Date = null;

  phaseValue: string;
  @Input()
  get phase() {
    return this.phaseValue;
  }
  @Output() phaseChange = new EventEmitter();
  set phase(val) {
    this.phaseValue = val;
    this.phaseChange.emit(this.phaseValue);
  }

  constructor(
    private eventService: services.EventService
    , private harvestService: services.HarvestService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    // if(this.harvestId != null){
    //   this.getPhases();
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.harvestId != null){
      this.phase = null;
      this.getPhases();
    }
    if(changes.date != null){
      this.phase = null;
      this.setSelectedPhase();
    }
  }

  async getPhases() {
    if(this.helperService.currentCompany.eventsSplit){
      this.getPhasesFromHarvest();
    } else {
      this.getPhasesFromEvents();
    }
  }

  async getPhasesFromHarvest() {
    let harvestResponse = await this.harvestService.get(this.harvestId).get().toPromise();

    let harvest: models.Harvest = harvestResponse.data();
    harvest.uid = harvestResponse.id;

    this.phases = harvest.phases.map(i => i.phaseName);
  }

  async getPhasesFromEvents() {
    let eventResponse = await getDocs(this.eventService.get(this.harvestId));

    eventResponse.forEach(eventData => {
      let dbEvent: models.DbEvent = eventData.data();
      dbEvent.uid = eventData.id;
      this.events = dbEvent.list;
      
      let phases = this.events.map(i => { return i.phase});
      this.phases = Array.from(new Set(phases));
    })

    this.setSelectedPhase();
  }

  setSelectedPhase() {
    if(this.phases.length == 0 || this.phase != null){
      return;
    }

    if(this.phase == null || !this.phases.includes(this.phase)){
      if(this.date != null){
        let nextEvent = this.events.find(i => i.startDateTime.seconds >= Timestamp.fromDate(this.date).seconds);

        if(nextEvent != null){
          this.phase = nextEvent.phase;
        } else {
          //find last phase in list
          this.phase = this.events[this.events.length - 1].phase;
        }
      } else {
        this.phase = this.phases[0];
      }
    }
  }
}
