import { Component, Input, OnInit } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
var fileDownload = require('js-file-download');

@Component({
  selector: 'app-attachment-btn',
  templateUrl: './attachment-btn.component.html',
  styleUrls: ['./attachment-btn.component.scss']
})
export class AttachmentBtnComponent implements OnInit {

  @Input() id: string;
  attachment: models.Attachment;

  get name(): string {
    let fileName: string = this.attachment?.fileName;
    if(fileName == null){
      return '';
    }
    if(fileName.includes('.')){
      const lastIndex = fileName.lastIndexOf('.');
      const before = fileName.slice(0, lastIndex);
      return before + '.';
    }
    return fileName;
  }

  get extension(): string {
    let fileName: string = this.attachment?.fileName;
    return fileName.split('.').pop()
  }

  loading: boolean = false;

  constructor(
    private attachmentService: services.AttachmentService
    , private fileService: services.FileService
  ) { }

  ngOnInit(): void {
    this.getAttachment();
  }

  async getAttachment() {
    this.attachment = await this.attachmentService.get(this.id);
  }

  async download(d: string) {
    this.loading = true;
    this.fileService.get(this.attachment.fullPath).subscribe(async url => {
      //window.open(url, '_blank').focus();
      let blob = await fetch(url).then(r => r.blob());
      fileDownload(blob, this.attachment.fileName);
      this.loading = false;
    });
  }
}
