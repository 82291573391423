import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-tag-select-multi-btn',
  templateUrl: './tag-select-multi-btn.component.html',
  styleUrls: ['./tag-select-multi-btn.component.scss']
})
export class TagSelectMultiBtnComponent implements OnInit, OnChanges {

  @Input() events: models.Event[] = [];

  tags: viewmodels.MultiSelect[] = [];
  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);
  }
  @Output() onClosed = new EventEmitter();

  constructor(
    private teamService: services.TeamService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.events) {
      let events: models.Event[] = this.events;
      if(events != null){
        let allTags = [...new Set(events.map(i => i.tags).flat())];
        let tags = allTags.filter(i => i != null && i != '');
        let list = tags.map(i => {
          let h: viewmodels.MultiSelect = {
            id: i,
            displayValue: i,
            selected: this.selected.includes(i)
          }
          return h;
        })
        this.tags = this.helperService.sortArrayByStringField(list, 'displayValue');
      }
    }
  }

  // async getTeams() {
  //   let teams = await this.teamService.getAllModels()
  //   let list = teams.map(i => {
  //     let h: viewmodels.MultiSelect = {
  //       id: i.uid,
  //       displayValue: i.name,
  //       selected: false // this.selected.includes(i.uid)
  //     }
  //     return h;
  //   })

  //   this.teams = this.helperService.sortArrayByStringField(list, 'displayValue');
  //   //this.selected = this.zones.map(i => i.id);
  // }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
