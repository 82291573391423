import { Component, OnInit, Inject } from '@angular/core';

import * as models from '../../models';
import * as services from '../../services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, NgModel } from '@angular/forms';
import { HttpsCallableResult } from '@angular/fire/functions';

@Component({
  selector: 'app-harvest-shift',
  templateUrl: './harvest-shift.component.html',
  styleUrls: ['./harvest-shift.component.scss']
})
export class HarvestShiftComponent implements OnInit {

  harvests: Array<models.Harvest> = [];
  selectedHarvests: Array<models.Harvest> = [];
  needToPickHarvest: boolean = false;

  formSubmitted: boolean = false;

  startDate:FormControl = new FormControl(new Date());
  partialShift: boolean = true;
  numberOfDays: number = 1;

  harvestChange(newVal) {
    if(newVal){
      this.selectedHarvests = newVal;
    }
  }

  constructor(
    private dialogRef: MatDialogRef<HarvestShiftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private harvestService: services.HarvestService,
    private snackBar: MatSnackBar
  ) { 
    if(data.harvestId == null){
      this.needToPickHarvest = true;
    } else {
      let inHarvest: models.Harvest = {
        uid: data.harvestId,
        name: data.name
      }
      this.selectedHarvests.push(inHarvest)
    }
  }

  async ngOnInit() {
    if(this.needToPickHarvest){
      await this.getHarvests();
    }
  }

  async getHarvests() {
    let harvestResponse = await (await this.harvestService.getAll()).get().toPromise();
    harvestResponse.forEach(harvestData => {
      let harvest: models.Harvest = harvestData.data();
      harvest.uid = harvestData.id;
      this.harvests.push(harvest);
    })

    // if(this.harvests.length != 0){
    //   this.harvestChange(this.harvests[0]);
    // }
  }

  selectAllHarvests(select: NgModel, values) {
    select.update.emit(values); 
    this.harvestChange(this.harvests);
  }

  deselectAllHarvests(select: NgModel) {
    select.update.emit([]); 
    this.harvestChange([]);
  }

  async submit() {
    if(this.selectedHarvests.length == 0){
      this.snackBar.open('No Harvest(s) selected to Shift')
      return;
    }

    this.formSubmitted = true;
    let startDate: Date = new Date(this.startDate.value);

    let callsToMake: Array<Promise<HttpsCallableResult<models.Result<any>>>> = [];

    this.selectedHarvests.forEach(selectedHarvest => {
      callsToMake.push(this.harvestService.shift(selectedHarvest.uid, this.numberOfDays, startDate, this.partialShift));
    });

    Promise.all(callsToMake).then(responses => {

      if(responses.find(i => i.data.success == false) != null){
        this.snackBar.open('There was an error with one or more of the Harvest shifts.');
        this.formSubmitted = false;
        return;
      }

      let message: string = '';
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i];

        message += response.data.message + "\n";
      }

      this.snackBar.open(message, null, {duration: 3000*responses.length});

      this.dialogRef.close(true);

    }).catch(error => {
      this.snackBar.open('There was an error with one or more of the Harvest shifts.');
      console.log(JSON.stringify(error))
      this.formSubmitted = false;
    })

  }

}
