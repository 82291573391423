import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';

@Component({
  selector: 'app-harvest-select-single-dialog',
  templateUrl: './harvest-select-single-dialog.component.html',
  styleUrls: ['./harvest-select-single-dialog.component.scss']
})
export class HarvestSelectSingleDialogComponent implements OnInit {

  header: string = 'Select Harvest';
  negativeBtn: string = 'Cancel';
  affirmativeBtn: string = 'OK';
  selectedHarvest: models.Harvest;

  constructor(
    private dialogRef: MatDialogRef<HarvestSelectSingleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.header = data.header;
    this.negativeBtn = data.negativeBtn;
    this.affirmativeBtn = data.affirmativeBtn;
    this.selectedHarvest = data.harvest;
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(this.selectedHarvest);
  }

}
