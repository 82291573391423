import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import * as services from 'app/services';
import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import * as enums from 'app/models/enumerations';
import { SessionStorageService } from 'angular-web-storage';
import * as copy from 'copy-to-clipboard';

import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImageUploadComponent, NotificationTokenComponent } from 'app/dialogs';
import { FileService } from 'app/services';
import { Subscription } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-company-settings-details',
  templateUrl: './company-settings-details.component.html',
  styleUrls: ['./company-settings-details.component.scss'],
})
export class CompanySettingsDetailsComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  //detectChangesInterval: any;

  companySub: Subscription;

  company: models.Company = null;
  initialCompany: models.Company = {};

  saving: boolean = false;
  timezones: any;

  companyImageUrl: string;
  customCompanyImage: boolean = false;

  roles: Array<models.Role> = [];

  constructor(
    private companyService: services.CompanyService
    , private helperService: services.HelperService
    , private dialog: MatDialog
    , private fileService: FileService
    , private notifierService: services.NotifierService
  ) { 
    super(); // call to default constructor added implicitly  
    //ref.detach();
    //this.detectChangesInterval = setInterval(() => { this.ref.detectChanges(); }, 250);
  }

  canDeactivate():boolean{
    return !this.dataUpdated();
  }

  async ngOnInit() {
    this.timezones = this.helperService.getTimeZones();

    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.load(companyId);
    }
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.load(data);
    })
  }

  load(companyId: string) {
    this.getCompanyImage(companyId);
    this.get();
  }

  ngOnDestroy() {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  async get() {
    let companyId = this.helperService.currentCompanyId;
    let company = await this.companyService.getById(companyId, true);
    if(company.leaflogix == null){
      company.leaflogix = {adminUserIds: []};
    }
    this.company = company;
    this.initialCompany = this.helperService.deepCopy(company);
  }

  async getCompanyImage(companyId: string) {
    //get company image from storage if it is there
    this.fileService.get(`companyImage/${companyId}`).subscribe(url => {
      this.customCompanyImage = true;
      this.companyImageUrl = url;
    }, error => {
      this.companyImageUrl = 'assets/img/Logo1.png'
    });
  }

  async save() {
    let dutchieChanged: boolean = false;
    if(this.initialCompany.leaflogix?.active != this.company.leaflogix?.active || this.initialCompany.leaflogix?.apiKey != this.company.leaflogix?.apiKey){
      dutchieChanged = true;
    }
    this.saving = true;
    let companyToSave = this.helperService.deepCopy(this.company)
    await this.companyService.save(companyToSave);
    this.helperService.currentCompany = companyToSave;
    this.get();
    this.saving = false;

    this.notifierService.success('Successfully saved Company');

    // if(this.company.leaflogix?.active && dutchieChanged){
    //   this.notifierService.confirm('Initial Sync', 'Would you like to run an initial sync from Dutchie?  This will update all Strains and Rooms.  Anything currently saved will be overwritten.',
    //   () => {this.syncLeaflogix()},
    //   () => {})
    // }
  }

  dataUpdatedBool: boolean = false;
  dataUpdated(): boolean {
    let x = JSON.stringify(this.initialCompany);
    let y = JSON.stringify(this.company);

    this.dataUpdatedBool = x !== y;
    return this.dataUpdatedBool;
  }

  // async processNotifications() {
  //   let response: any = await this.notificationService.processNotifications();

  //   if(response.data.success){
  //     alert(response.data.message);
  //   } else {
  //     alert('Unexpected error.  Contact administrator for assistance.')
  //   }
  // }

  // async processManagementNotifications() {
  //   let response: any = await this.notificationService.processManagementNotifications();

  //   if(response.data.success){
  //     alert(response.data.message);
  //   } else {
  //     alert('Unexpected error.  Contact administrator for assistance.')
  //   }
  // }
  
  // async processOwnerNotifications() {
  //   let response: any = await this.notificationService.processOwnerNotifications();

  //   if(response.data.success){
  //     alert(response.data.message);
  //   } else {
  //     alert('Unexpected error.  Contact administrator for assistance.')
  //   }
  // }

  imageClick() {

    let dialogRef = this.dialog.open(ImageUploadComponent, {
      panelClass: 'med-width-dialog',
      data: {imageFor: 'company'}
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload image
      //this.getCompanyImage();

      if(result){
        location.reload();
      }
    })
  }

  minusClick() {
    let c = confirm('Are you sure you want to remove the custom image and go back to the stock image?')

    if(c){
      this.fileService.delete(`companyImage/${this.helperService.currentCompanyId}`).subscribe(
        data => {
          location.reload();
      })
    }
  }
}
