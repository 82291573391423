
<div [hidden]="hidden">
    <mat-form-field>
        <mat-label>Select Strain(s)</mat-label>
        <mat-select [(ngModel)]="strainIds" (selectionChange)="change($event.value)" multiple #select="ngModel">
            <mat-select-trigger>
                {{title}}
            </mat-select-trigger>
            <mat-option disabled="disabled" class="filter-option">
                <button mat-raised-button class="mat-primary fill text-sm" (click)="selectAll(select, strains)">
                    Select All
                </button>
                <button mat-raised-button class="mat-accent fill text-sm" (click)="deselectAll(select)">
                    Deselect All
                </button>
            </mat-option>
            <mat-option [value]="s.id" *ngFor="let s of strains">{{s.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
