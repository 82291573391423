<h2 mat-dialog-title>
  {{!hideHarvests ? 'Harvest' : ''}}
  {{!hideHarvests && !hideGroups ? '/' : ''}}
  {{!hideGroups ? 'Group' : ''}} Delete</h2>
<mat-dialog-content>

  <form id="harvestGroupDelete" class="user-form" (ngSubmit)="deleteClick()">

    <div class="row">
      <div [class]="harvestCol" *ngIf="!hideHarvests">
        <h4 mat-dialog-title>Select Harvests</h4>

        <!-- <app-harvest-select-multiple [displayList]="true" [(selectedHarvests)]="harvests"></app-harvest-select-multiple> -->

        <app-harvest-select-multi-btn 
          class="filter-btn" 
          [showUpcomingHarvests]="false"
          [appStorageNeeded]="false" 
          [startWithNoneSelected]="true"
          [(selectedHarvests)]="harvests" >
        </app-harvest-select-multi-btn>

        <div class="row" style="padding-top:15px">
            <div class="col-12">
                <h5>Selected Harvests:</h5>
                <ul class="list">
                    <li *ngFor="let harvest of harvests">
                        {{harvest.displayValue}}
                    </li>
                </ul>
            </div>
        </div>
      </div>
      <div [class]="groupCol" *ngIf="!hideGroups">
        <h4 mat-dialog-title>Select Task Groups</h4>

        <!-- <app-group-select-multiple [displayList]="true" [(selectedGroups)]="groups" [selectByDefault]="'none'"></app-group-select-multiple>
         -->
        <app-task-group-select-multi-btn 
          class="filter-btn" 
          [showUpcomingHarvests]="true"
          [appStorageNeeded]="false" 
          [startWithNoneSelected]="true"
          [(selectedGroups)]="groups" >
        </app-task-group-select-multi-btn>

        <div class="row" style="padding-top:15px">
            <div class="col-12">
                <h5>Selected Task Groups:</h5>
                <ul class="list">
                    <li *ngFor="let group of groups">
                        {{group.displayValue}}
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </div>

    <!-- <h4 mat-dialog-title>Harvests</h4>

    <div class="container" *ngIf="!retrievedHarvests">
      <mat-spinner style="margin: auto" diameter=25 strokeWidth=4></mat-spinner>
    </div>

    <div formArrayName="harvests" class="form-check-inline" *ngFor="let harvest of harvestGroupForm.get('harvests')['controls']; let i = index" >
      <mat-checkbox class="example-margin" [formControlName]="i">
      {{harvests[i].name}}
      </mat-checkbox>
    </div>

    <h4 mat-dialog-title>Groups</h4>

    <div class="container" *ngIf="!retrievedGroups">
      <mat-spinner style="margin: auto" diameter=25 strokeWidth=4></mat-spinner>
    </div>

    <div formArrayName="groups" class="form-check-inline" *ngFor="let group of harvestGroupForm.get('groups')['controls']; let i = index" >
      <mat-checkbox class="example-margin" [formControlName]="i">
      {{groups[i].name}}
      </mat-checkbox>
    </div> -->

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [disabled]="deleting">Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button type="submit" form="harvestGroupDelete" [disabled]="deleting">
    <mat-spinner *ngIf="deleting" diameter=25 strokeWidth=4></mat-spinner>
    <span *ngIf="!deleting">Delete</span>
  </button>
</mat-dialog-actions>
