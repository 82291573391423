<div class="row no-gutters">
    <div class="col-sm-4 px-1">
        <div class="card card-stats">
            <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">trending_up</i>
                </div>
                <p class="card-category">Top Performers</p>
                <span class="card-title">
                    <div *ngFor="let user of top3Performers; let i = index" class="text-capitalize text-truncate">
                        <span class="performer-span" [title]="performanceLabelName(user, i) + performanceLabelValue(user, i)">
                            <span class="performer-span-name">{{performanceLabelName(user, i)}}</span>
                            <span class="performer-span-value">{{performanceLabelValue(user, i)}}</span>
                        </span>
                    </div>
                    <mat-icon [ngxTippy]="topPerformersMore" tippyName="top_performers_more" class="tooltip-more">
                        add
                    </mat-icon>
    
                    <ng-template #topPerformersMore let-name let-data="data">
                        <div class="tippy-container">
                            <b *ngFor="let user of allPerformers; let i = index">{{performanceLabelName(user, i)}}{{performanceLabelValue(user, i)}}<br/></b>
                        </div>
                    </ng-template>
                </span>
            </div>

            <div class="card-footer">
                <div class="stats">
                  <i class="material-icons card-footer-icon">trending_up</i>
                  <span>Events Completed over last 30 days</span>
                </div>
                <mat-icon [ngxTippy]="topPerformersHelp" tippyName="top_performers_help" class="tooltip-help">
                    help_outline
                </mat-icon>

                <ng-template #topPerformersHelp let-name let-data="data">
                    <div class="tippy-container">
                        <p class="header"><b>Top Performers tracks two types of data: </b></p>
                        <p>1. Number of Events completed by Users over the last 30 days.</p>
                        <p>2. The % change in the number of Events completed over the last 30 days compared to the monthly average of the last 90 days previous.</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    
    <div class="col-sm-4 px-1">
        <div class="card card-stats">
            <div class="card-header card-header-danger card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">trending_down</i>
                </div>
                <p class="card-category">Needs Help</p>
                <span class="card-title">
                    <div *ngFor="let user of bottom3Performers; let i = index" class="text-capitalize text-truncate">
                        <span class="performer-span" [title]="performanceLabelName(user, i) + performanceLabelValue(user, i)">
                            <span class="performer-span-name">{{performanceLabelName(user, i)}}</span>
                            <span class="performer-span-value">{{performanceLabelValue(user, i)}}</span>
                        </span>
                    </div>
                    <mat-icon [ngxTippy]="needsHelpMore" tippyName="needs_help_more" class="tooltip-more">
                        add
                    </mat-icon>
    
                    <ng-template #needsHelpMore let-name let-data="data">
                        <div class="tippy-container">
                            <b *ngFor="let user of allPerformersDesc; let i = index">{{performanceLabelName(user, i)}}{{performanceLabelValue(user, i)}}<br/></b>
                        </div>
                    </ng-template>
                </span>
            </div>

            <div class="card-footer">
                <div class="stats">
                  <i class="material-icons card-footer-icon">trending_down</i>
                  <span>Events Completed over last 30 days</span>
                </div>
                <mat-icon [ngxTippy]="needsHelpHelp" tippyName="needs_help" class="tooltip-help">
                    help_outline
                </mat-icon>

                <ng-template #needsHelpHelp let-name let-data="data">
                    <div class="tippy-container">
                        <p class="header"><b>Needs Help tracks two types of data: </b></p>
                        <p>1. Number of Events completed by Users over the last 30 days.</p>
                        <p>2. The % change in the number of Events completed over the last 30 days compared to the monthly average of the last 90 days previous.</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-sm-4 px-1">
        <div class="card card-stats">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">scale</i>
                </div>
                <p class="card-category">Labor Load - All Events</p>
                <span class="card-title">
                    <div style="height: 60px; top: 15px; position: relative;">
                        <canvas #chartCanvas></canvas>
                    </div>
                </span>
            </div>

            <div class="card-footer">
                <div class="stats">
                  <i class="material-icons card-footer-icon">scale</i>
                  <span>Events over next 7 days vs. Average</span>
                </div>
                <mat-icon [ngxTippy]="eventsAverageHelp" tippyName="events_average" class="tooltip-help">
                    help_outline
                </mat-icon>

                <ng-template #eventsAverageHelp let-name let-data="data">
                    <div class="tippy-container">
                        <p class="header"><b>Labor Load tracks two pieces of data: </b></p>
                        <p>1. Then Number of Events scheduled over next 7 days.</p>
                        <p>2. The % change in the number of Events scheduled over the next 7 days compared to the average of Events over the previous 30 days.</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- <div class="col-sm-3 px-1">
        <div class="card card-stats">
            <p class="card-category mx-2 text-center">Labor Load - Users & Teams</p>
        </div>
    </div> -->
    
</div>
