export interface Filter {

    harvestIds: string[];
    groupIds: string[];
    zoneIds: string[];
    phases: string[];
    teamIds: string[];
    userIds: string[];
    tags: string[];
    completeStatus: string
    onlyMyTasks: boolean
}

export interface CompanyFilter {
    companyId: string;
    companyFilters: Filter;
}