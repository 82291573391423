import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SessionStorageService } from 'angular-web-storage';

import * as services from '../../services'
import * as models from '../../models'
import { HttpsCallableResult } from '@angular/fire/functions';

@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit {

  email: string;
  selectedRole: string;
  //roles: Array<enums.Claim> = [enums.Claim.admin, enums.Claim.manager, enums.Claim.standardUser];
  roles: Array<models.Role> = [];

  saveClick: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<UpdateRoleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private snackBar: MatSnackBar,
    private authService: services.AuthService,
    private claimsService: services.ClaimsService,
    private storage: SessionStorageService,
    private roleService: services.RoleService,
    private helperService: services.HelperService
  ) { 
    dialogRef.disableClose = true;
    this.email = data.email;

    if(data?.roleId != null){
      this.selectedRole = data.roleId;
    }
  }

  ngOnInit() {
    this.getRoles();
  }

  async getRoles() {
    let roles: Array<models.Role> = await this.roleService.getRoles();
    //let currentUserRole = await this.claimsService.getRoleAwait()

    // if(currentUserRole.precedence !== 0){
    //   roles = roles.filter(r => currentUserRole.precedence == 1 ?  r.precedence >= currentUserRole.precedence : r.precedence > currentUserRole.precedence)
    // }

    this.roles = this.helperService.sortArrayByStringField(roles, 'name');
  }

  save() {
    this.saveClick = true;

    this.authService.setUserRole(this.selectedRole, this.email).then((data: HttpsCallableResult<models.Result<any>>) => {

      this.snackBar.open(data.data.message, null, {
        duration: 3000,
        verticalPosition: 'top'
      });

      if(data.data.success){
        this.dialogRef.close(true);
      }

      this.saveClick = false;
    }).catch(error => {
      this.snackBar.open(error.error, null, {
        duration: 3000,
        verticalPosition: 'top'
      });
      console.log(JSON.stringify(error));

      this.saveClick = false;
    })
  }

  close(){
    this.dialogRef.close(false);
  }
}
