import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import  * as models from '../../../models';
import { ChartOptions } from 'chart.js';
import * as Chart from 'chart.js/auto';
import { Subscription, Subject, Observable } from 'rxjs';
import * as services from '../../../services';
import { MatDialog } from '@angular/material/dialog';
import { CreateTeamsAlertDialogComponent } from 'app/dialogs';
import { ChartDaterangeFilterComponent } from 'app/components/chart-filters/chart-daterange-filter/chart-daterange-filter.component';

@Component({
  selector: 'app-users-teams-performance-chart',
  templateUrl: './users-teams-performance-chart.component.html',
  styleUrls: ['./users-teams-performance-chart.component.scss']
})
export class UsersTeamsPerformanceChartComponent implements OnInit {
  @ViewChild('chartCanvas', { static: false }) chartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild(ChartDaterangeFilterComponent) chartDaterangeFilterComponent: ChartDaterangeFilterComponent;

  @Input() removeEmpty: boolean = false;
  @Input() filteredEvents: models.ChartEventInput[];
  @Output() chartViewChange = new EventEmitter<any>();

  private performanceBarChart;
  eventsSub: Subscription;

  private _events = new Subject<models.ChartEventInput[]>();
  getEvents(): Observable<any> {
    return this._events.asObservable();
  }

  set events(val: models.ChartEventInput[]) {
    if (val) {
      this._events.next(val);
    }
  }

  dateRange: models.DateRanger;
  chartTitle: string = 'Users and Teams';
  chartSubTitle: string = 'Events assigned vs. completed by Teams and Users';
  selectedChartView: string = 'Users';

  users: models.User[];
  teams: models.Team[];

  usersLabels: string[];
  teamsLabels: string[];

  totalEvents: (number | null)[];
  completedEvents: (number | null)[];
  overdueEvents: (number | null)[];
  assignedToUserButCompletedByElseEvents: (number | null)[] = [];
  assignedToTeamButCompletedByElseEvents: (number | null)[] = [];

  constructor(
    private helperService: services.HelperService
    , private userService: services.UserService
    , private teamService: services.TeamService
    , private teamsAlertDialog: MatDialog
  ) { }

  changeChartView() {
    this.chartViewChange.emit({ expand: true, chart: 'users_teams' });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.eventsSub) {
      this.prepareChartEvents(this.events, this.dateRange);
    }
    this.eventsSub = this.getEvents().subscribe(data => {
      this.prepareChartEvents(data, this.dateRange);
    })
  }

  ngOnChanges(): void {
    this.events = this.filteredEvents;
  }

  async prepareChartEvents(events: models.ChartEventInput[], eventsDateRange: models.DateRanger) {
    this.usersLabels = [];
    this.totalEvents = [];
    this.completedEvents = [];
    this.overdueEvents = [];
    this.teamsLabels = [];
    this.assignedToUserButCompletedByElseEvents = [];
    this.assignedToTeamButCompletedByElseEvents = [];

    this.users = await this.userService.getUsersForCompanyWithCaching(this.helperService.currentCompanyId, true, true);
    this.teams = await this.teamService.getAllModels()

    switch (this.selectedChartView) {
      case 'Users': // filter events by Users
        let [totalAssignedEventsUsersMap, completedEventsUsersMap, overdueEventsUsersMap, assignedButCompletedByElseMap] = this.prepareEventsMapForUsers(events, eventsDateRange);
        this.usersLabels = Array.from(totalAssignedEventsUsersMap.keys());
        this.totalEvents = Array.from(totalAssignedEventsUsersMap.values());
        this.completedEvents = Array.from(completedEventsUsersMap.values());
        this.overdueEvents = Array.from(overdueEventsUsersMap.values());
        this.assignedToUserButCompletedByElseEvents = Array.from(assignedButCompletedByElseMap.values());
        this.makePerformanceChart(true, false);
        break;
      case 'Teams': // filter events by Teams
        if (this.teams.length > 0) {
          let [totalAssignedEventsTeamsMap, completedEventsTeamsMap, overdueEventsTeamsMap, assignedButCompletedByElseMap] = this.prepareEventsMapForTeams(events, eventsDateRange);
          this.teamsLabels = Array.from(totalAssignedEventsTeamsMap.keys());
          this.totalEvents = Array.from(totalAssignedEventsTeamsMap.values());
          this.completedEvents = Array.from(completedEventsTeamsMap.values());
          this.overdueEvents = Array.from(overdueEventsTeamsMap.values());
          this.assignedToTeamButCompletedByElseEvents = Array.from(assignedButCompletedByElseMap.values());
          this.makePerformanceChart(false, true);
        } else {
          let dialogRef = this.teamsAlertDialog.open(CreateTeamsAlertDialogComponent, {
            disableClose: true,
          })
          this.performanceBarChart.data = [];
          this.performanceBarChart.update();
        }
        break;
    }
  }

  prepareEventsMapForUsers(events: models.ChartEventInput[], eventsDateRange: models.DateRanger): any { // last month
    const startDate = new Date(eventsDateRange.datesInRange[0]).getTime();
    const endDate = new Date(eventsDateRange.datesInRange[eventsDateRange.datesInRange.length - 1]).getTime();

    const totalEventCounts = {};    
    const completedEventsCounts = {};
    const overdueEventsCounts = {};
    const assignedEventToUserButCompletedByOtherUser = {};
    // Iterate through filtered events to calculate counts for each user
    for (const event of events) {
      // Convert event date to a timestamp for comparison
      const eventDate = new Date(event.date).getTime();
      // Check if the event date is within the selected date range - last month by default;
      if (eventDate >= startDate && eventDate <= endDate) {
        // iterating over each user to find total assigned, completed and overdue events counts
        for (const user of this.users) {
          let userTeams = this.teams.filter(i => i.userIds.includes(user.id)).map(i => i.uid);
          const userTeamsSet = new Set(userTeams);
        
          if ((event.assignedUserIds && event.assignedUserIds.includes(user.id)) || (event.assignedTeamIds && event.assignedTeamIds.some(element => userTeamsSet.has(element)))) { // check if current event is assigned to the user or it is assigned to a team the user is a part of
            
            totalEventCounts[user.id] = (totalEventCounts[user.id] || 0) + 1;
            
            if (event.completed) { // increase count in completedEventsCounts for the user
           
              // check if event is completed by the same user or any other user
              let discussions = event.eventObj.discussions.slice().reverse();
              let userIdWhoCompletedThisEvent = discussions.find(item => item.discussionType === 'completed_status' && item.content === "true")?.userId;

              if (user.id === userIdWhoCompletedThisEvent) { // current user and one who completed the event are same
                completedEventsCounts[user.id] = (completedEventsCounts[user.id] || 0) + 1;
                overdueEventsCounts[user.id] = (overdueEventsCounts[user.id] || 0); // overdueEvents count remains same or 0
                assignedEventToUserButCompletedByOtherUser[user.id] = (assignedEventToUserButCompletedByOtherUser[user.id] || 0);
              } else {
                completedEventsCounts[userIdWhoCompletedThisEvent] = (completedEventsCounts[userIdWhoCompletedThisEvent] || 0) + 1;
                overdueEventsCounts[userIdWhoCompletedThisEvent] = (overdueEventsCounts[userIdWhoCompletedThisEvent] || 0);
                assignedEventToUserButCompletedByOtherUser[user.id] = (assignedEventToUserButCompletedByOtherUser[user.id] || 0) + 1;
              }
            } else if (event.date < new Date() && !event.completed) { // increase overdue events counts for the user
              completedEventsCounts[user.id] = (completedEventsCounts[user.id] || 0); // completed events count remain same or 0
              overdueEventsCounts[user.id] = (overdueEventsCounts[user.id] || 0) + 1;
              assignedEventToUserButCompletedByOtherUser[user.id] = (assignedEventToUserButCompletedByOtherUser[user.id] || 0);
            } else { // both remains same or 0
              completedEventsCounts[user.id] = (completedEventsCounts[user.id] || 0);
              overdueEventsCounts[user.id] = (overdueEventsCounts[user.id] || 0); 
              assignedEventToUserButCompletedByOtherUser[user.id] = (assignedEventToUserButCompletedByOtherUser[user.id] || 0);
            }
          }
        }
      } else { // event date doesnt fall in the selected or default date range;
        continue;
      }
    }
    // Create datasets (Map) for all assigned, completed and overdue events for every user
    const totalAssignedEventsMap = this.helperService.computeUsersToEventsMap(totalEventCounts, this.users, this.removeEmpty);
    const completedEventsMap = this.helperService.computeUsersToEventsMap(completedEventsCounts, this.users, this.removeEmpty);
    const overdueEventsMap = this.helperService.computeUsersToEventsMap(overdueEventsCounts, this.users, this.removeEmpty);
    const assignedButCompletedByElseMap = this.helperService.computeUsersToEventsMap(assignedEventToUserButCompletedByOtherUser, this.users, this.removeEmpty);
    return [totalAssignedEventsMap, completedEventsMap, overdueEventsMap, assignedButCompletedByElseMap];
  }

  prepareEventsMapForTeams(events: models.ChartEventInput[], eventsDateRange: models.DateRanger): any {
    const startDate = new Date(eventsDateRange.datesInRange[0]).getTime();
    const endDate = new Date(eventsDateRange.datesInRange[eventsDateRange.datesInRange.length - 1]).getTime();

    const totalEventCounts = {};    
    const completedEventsCounts = {};
    const overdueEventsCounts = {};
    const assignedToTeamButCompletedByOther = {};
    // Iterate through filtered events to calculate counts for each user
    for (const event of events) {
      // Convert event date to a timestamp for comparison
      const eventDate = new Date(event.date).getTime();
      // Check if the event date is within the selected date range - last month by default;
      if (eventDate >= startDate && eventDate <= endDate) {
        // iterating over each user to find total assigned, completed and overdue events counts
        for (const team of this.teams) {
          if (event.assignedTeamIds && event.assignedTeamIds.includes(team.uid)) { // check if current event is assigned to the user
            
            // let userIdWhoCompletedThisEvent = event.eventObj.discussions.find(item => item.discussionType === 'completed_status' && item.content === "true").userId;
            totalEventCounts[team.uid] = (totalEventCounts[team.uid] || 0) + 1;
            if (event.completed) { // increase count in completedEventsCounts for the user

              let discussions = event.eventObj.discussions.slice().reverse();
              let userIdWhoCompletedThisEvent = discussions.find(item => item.discussionType === 'completed_status' && item.content === "true").userId;

              // check if this user belongs to the current team or any other
              if (team.userIds.includes(userIdWhoCompletedThisEvent)) {
                completedEventsCounts[team.uid] = (completedEventsCounts[team.uid] || 0) + 1;
                overdueEventsCounts[team.uid] = (overdueEventsCounts[team.uid] || 0); // overdueEvents count remains same or 0
                assignedToTeamButCompletedByOther[team.uid] = (assignedToTeamButCompletedByOther[team.uid] || 0);
              } else {
                completedEventsCounts[userIdWhoCompletedThisEvent] = (completedEventsCounts[userIdWhoCompletedThisEvent] || 0);
                overdueEventsCounts[userIdWhoCompletedThisEvent] = (overdueEventsCounts[userIdWhoCompletedThisEvent] || 0); // overdueEvents count remains same or 0
                assignedToTeamButCompletedByOther[team.uid] = (assignedToTeamButCompletedByOther[team.uid] || 0) + 1;
              }
            } else if (event.date < new Date() && !event.completed) { // increate overdue events counts for the user
              completedEventsCounts[team.uid] = (completedEventsCounts[team.uid] || 0); // completed events count remain same or 0
              overdueEventsCounts[team.uid] = (overdueEventsCounts[team.uid] || 0) + 1;
              assignedToTeamButCompletedByOther[team.uid] = (assignedToTeamButCompletedByOther[team.uid] || 0);
            } else { // both remains same or 0
              completedEventsCounts[team.uid] = (completedEventsCounts[team.uid] || 0);
              overdueEventsCounts[team.uid] = (overdueEventsCounts[team.uid] || 0); 
              assignedToTeamButCompletedByOther[team.uid] = (assignedToTeamButCompletedByOther[team.uid] || 0);
            }
          }
        }
      } else { // event date doesnt fall in the selected or default date range;
        continue;
      }
    }
  
    // Create datasets (Map) for all assigned, completed and overdue events for every user
    const totalAssignedEventsMap = this.helperService.computeTeamsToEventsMap(totalEventCounts, this.teams);
    const completedEventsMap = this.helperService.computeTeamsToEventsMap(completedEventsCounts, this.teams);
    const overdueEventsMap = this.helperService.computeTeamsToEventsMap(overdueEventsCounts, this.teams);
    const assignedButCompletedByElseMap = this.helperService.computeTeamsToEventsMap(assignedToTeamButCompletedByOther, this.teams);
    return [totalAssignedEventsMap, completedEventsMap, overdueEventsMap, assignedButCompletedByElseMap];
  }

  makePerformanceChart(forUsers: boolean = true, forTeams: boolean = false): void {
    // destroy chart if already there
    if (this.performanceBarChart) {
      this.performanceBarChart.destroy();
    }

    //compute completed vs. assigned events ratio
    // Initialize variables to store the highest ratio and its index
    let highestRatio = -Infinity;
    let highestRatioIndex = -1;

    // Calculate the highest ratio of completed events to total assigned events
    for (let i = 0; i < this.completedEvents.length; i++) {
      const _ratio = this.helperService.calculateRatio(this.completedEvents[i], this.totalEvents[i]);
      if (_ratio > highestRatio) {
        highestRatio = _ratio;
        highestRatioIndex = i;
      }
    }

    // Create an array to store the borderColor values for each bar
    const borderColorArray = this.completedEvents.map((completed, index) => {
      return {
        borderColor: (index === highestRatioIndex && highestRatio !== null) ? '#43A047' : 'transparent',
        borderDash: (index === highestRatioIndex && highestRatio !== null) ? [3, 3] : [] // Dotted border [dash, gap]
      };
    });



    // datasets
    const usersChartDatasets = [
      {
        label: 'Completed Events',
        data: this.completedEvents.map((count, index) => ({ x: this.usersLabels[index], y: count })),
        backgroundColor: '#43A047',
        borderColor: borderColorArray.map(item => item.borderColor),
        borderDash: borderColorArray.map(item => item.borderDash),
        borderWidth: {
          top: 0,
          left: 4,
          right: 4
        },
        minBarLength: 4,
      },
      {
        label: 'Event Assigned but Completed by Other User',
        data: this.assignedToUserButCompletedByElseEvents.map((count, index) => ({ x: this.usersLabels[index], y: count })),
        backgroundColor: 	'#D3D3D3',
        borderColor: borderColorArray.map(item => item.borderColor),
        borderDash: borderColorArray.map(item => item.borderDash),
        borderWidth: {
          bottom: 0,
          top: 0,
          left: 4,
          right: 4
        },
        minBarLength: 4,
      },
      {
        label: 'Overdue Events',
        data: this.overdueEvents.map((count, index) => ({ x: this.usersLabels[index], y: count })),
        backgroundColor: '#f44336',
        borderColor: borderColorArray.map(item => item.borderColor),
        borderDash: borderColorArray.map(item => item.borderDash),
        borderWidth: {
          bottom: 0,
          top: 4,
          left: 4,
          right: 4
        },
        minBarLength: 4,
      },
    ]

    const teamsChartDatasets = [
      {
        label: 'Completed Events',
        data: this.completedEvents.map((count, index) => ({x: this.teamsLabels[index], y: count})),
        backgroundColor: '#43A047',
        borderColor: borderColorArray.map(item => item.borderColor),
        borderDash: borderColorArray.map(item => item.borderDash),
        borderWidth: {
          top: 0,
          left: 4,
          right: 4
        },
        minBarLength: 4,
      },
      {
        label: 'Event Assigned but Completed by Other User',
        data: this.assignedToTeamButCompletedByElseEvents.map((count, index) => ({ x: this.teamsLabels[index], y: count })),
        backgroundColor: 	'#D3D3D3',
        borderColor: borderColorArray.map(item => item.borderColor),
        borderDash: borderColorArray.map(item => item.borderDash),
        borderWidth: {
          bottom: 0,
          top: 0,
          left: 4,
          right: 4
        },
        minBarLength: 4,
      },
      {
        label: 'Overdue Events',
        data: this.overdueEvents.map((count, index) => ({x: this.teamsLabels[index], y: count})),
        backgroundColor: '#f44336',
        borderColor: borderColorArray.map(item => item.borderColor),
        borderDash: borderColorArray.map(item => item.borderDash),
        borderWidth: {
          bottom: 0,
          top: 4,
          left: 4,
          right: 4
        },
        minBarLength: 4,
      },
    ]

    // chart config options,
    const chartOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: 0, // Adjust as needed
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltip) => {
              const tooltipItem = tooltip;
              const datasetLabel = tooltipItem.dataset.label || '';
              const dataValue = tooltipItem.formattedValue;
              const totalValue = this.totalEvents[tooltipItem.dataIndex] || 0;
              if (datasetLabel === 'Assigned & Completed By Else Events') {
                return `${datasetLabel}: ${dataValue}`;  
              }
              return `${datasetLabel}: ${dataValue} - (Assigned Events: ${totalValue})`;
            },
            labelColor: (tooltipItem) => {
              let bgColor = tooltipItem.dataset.backgroundColor;
              return {
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: bgColor,
              }
            }
          },
        },
        legend: {
          position: "bottom",
          align: 'center',
          maxWidth: 1
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 10,
            }
          }
        },
        y: {
          stacked: true,
        }
      },
    };

    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.performanceBarChart = new Chart.Chart(ctx, {
      type: 'bar',
      data: {
        labels: forTeams ? this.teamsLabels : this.usersLabels, 
        datasets: forUsers ? usersChartDatasets : teamsChartDatasets,
      },
      options: chartOptions
    } as any);
  }

  switchChartView(view: string) {
    this.selectedChartView = view;
    this.prepareChartEvents(this.filteredEvents, this.dateRange);
  }

  handleChartDateRangeChange(chartDateRange: models.DateRanger) {
    this.dateRange = chartDateRange;
    this.prepareChartEvents(this.filteredEvents, this.dateRange);
  }

  resetFilters() {
    this.chartDaterangeFilterComponent.setDefaultDateRange();
  }

  ngOnDestroy(): void {
    if (this.eventsSub) {
      this.eventsSub.unsubscribe();
    }

    if(this.performanceBarChart) {
      this.performanceBarChart.destroy();
    }
  }
}
