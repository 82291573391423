import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import * as viewmodels from "app/viewmodels";

@Component({
    selector: "plant-count-cell",
    template: `
        {{value}}<span *ngIf="attrition != null" matTooltip="Attrition"> ({{attrition}})</span>
    `,
    styles: [
        `
        `
    ]
})
export class PlantCountCellComponent implements ICellRendererAngularComp {
    private params: any;

    value: number;
    attrition?: number = null;

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;

        this.setAttrition();
    }

    refresh(params: any): boolean {
        return false;
    }

    setAttrition() {
        let harvestVM: viewmodels.HarvestTableViewModel = this.params.data;

        let noneNullStrains = harvestVM.harvest.phases?.filter(i => i.strains != null) || [];
        if(noneNullStrains.length == 0) {
            this.attrition = null;
            return;
        }

        let firstPlantCount = noneNullStrains.map(i => i.strains.map(i => i.count)).flat().filter(i => i != null)[0];
    
        if(firstPlantCount == null) {
            this.attrition = null;
            return;
        }

        this.attrition = firstPlantCount - this.value;
    }
}
