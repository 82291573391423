
<button mat-button (click)="upgrade()">
    Upgrade!
</button>

<ngx-aside #NgxAsidePanelRight
           
    (cancel)="onCancel()"
    (submit)="handlePaymentMethodSubmission($event)"

    [title]="'Upgrade'"
    [cancelButtonTitle]="'Cancel'"
    [submitButtonTitle]="'Submit Payment'"

    [closeOnEscape]="false"
    [showOverlay]="true"

    [showDefaultFooter]="false"
    [showDefaultHeader]="false">

    <header style="text-align: left; padding: 10px">
        <h2 style="color: green; font-weight: bold; display: inline; padding-right:20px">PlanaCan Premium</h2>
        <!-- <span style="color: grey;">1-9 registered users</span> -->
    </header>
    <mat-divider></mat-divider>

    <h3>Get started with a {{trialDays}} Day free trial with unlimited access.  Pay <b>$139.99</b> per month after.</h3>

    <p>Your card will not be charged until {{startDate | date:'MM/dd/yyyy'}}</p>

    <p>You can cancel your subscription at any time</p>

    <!-- <div>
        <mat-form-field>
            <input matInput class="cardholderName" [(ngModel)]="cardHolderName" placeholder="Cardholder Name">
        </mat-form-field>
    </div> -->

    <!-- <div id="form-container">
        <div id="sq-card"></div>
    </div> -->

    <div class="card-loader" *ngIf="loadingCardReader">
        <div class="card-loader-spinner">
            <mat-spinner diameter=25 strokeWidth=4></mat-spinner>
        </div>
    </div>
    <div id="card-container"></div>
    <!-- <form id="payment-form">
        <button id="card-button" type="button">Pay $1.00</button>
    </form>
    <div id="payment-status-container"></div> -->

    <p>Connect with us at <a href="mailto:sales@planacan.io">sales@planacan.io</a> to find out more about pricing contracts for enterprise level accounts, optional yearly rates, and dedicated support packages.</p>

    <p><b>Watch your grow thrive!</b></p>

    <footer class="footer" style="display: block;">
        <button mat-raised-button class="cancelBtn" (click)="NgxAsidePanelRight.hide()">
            Close
        </button>
        <button id="sq-creditcard" mat-raised-button color="accent" align="end" class="submitBtn" (click)="handlePaymentMethodSubmission($event)" [disabled]="upgrading">
            <mat-spinner *ngIf="upgrading" diameter=25 strokeWidth=4></mat-spinner>
            <span *ngIf="!upgrading">Upgrade</span>
        </button>
    </footer>
</ngx-aside>