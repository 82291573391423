import { Timestamp } from '@angular/fire/firestore';
import { Discussion } from './discussion';
import { User } from './user';
import { Subtask } from './subtask';

export interface Event {
    uid?: string;
    id?: string;
    companyId?: string;
    parentId?: string;
    repeatedEventId?: string;
    harvestName?: string;
    groupName?: string;
    phase?: string;
    description?: string;
    dateAdded?: Timestamp;
    completed?: boolean;
    startDateTime?: Timestamp;
    color?: string;
    assignedUserIds?: Array<string>;
    assignedTeamIds?: Array<string>;
    precedence?: number;
    highPriority?: boolean;
    //notes?: string;
    anyTime?: boolean;
    timeSpent?: number;
    efficacy?: number;
    estimatedTime?: string;
    timeSpentRequired?: boolean;

    discussions?: Array<Discussion>;
    cloudAttachments?: Array<string>;
    attachments?: Array<string>;
    tags?: Array<string>;
    subtasks?: Array<Subtask>;
    
    filters?: Array<string>;

    isInGroup?: boolean;
}

export interface DbEvent {
    uid?: string;
    list?: Array<Event>;
    id?: string;
}

export interface ChartEventInput {
    id: string,
    date: Date,
    eventObj: Event,
    type: string,
    parentId: string,
    completed: boolean,
    assignedUserIds?: Array<string>;
    assignedTeamIds?: Array<string>;
}