import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as services from '../../services';
import * as models from '../../models';
//import Stripe from 'stripe';
import { Customer, Subscription } from 'square'
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-block-notification',
  templateUrl: './block-notification.component.html',
  styleUrls: ['./block-notification.component.scss']
})
export class BlockNotificationComponent implements OnInit {

  paymentProviderCustomerId: string;
  subscriptions: Array<Subscription> = [];

  sqCustomer: Customer;

  companies: Array<models.Company> = [];
  selectedCompany: models.Company;

  get displayUpgrade() : boolean {
    return this.subscriptions.length === 0;
  }

  get displayManage() : boolean {
    return this.subscriptions.length !== 0;
  }

  constructor(
    public dialog: MatDialog
    , private dialogRef: MatDialogRef<BlockNotificationComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private helperService: services.HelperService
    , private claimsService: services.ClaimsService
    , private companyService: services.CompanyService
    , private snackBar: MatSnackBar
    , private squareService: services.SquareService
    , private userService: services.UserService
    , private router: Router
  ) { 
    dialogRef.disableClose = true;

    this.subscriptions = data.subscriptions || [];
  }

  /**
   * if the user has the ability to managesubscription
   */
  canManage() : boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.billingManagement);
  }

  ngOnInit() {
    if(this.helperService.currentCompanyId != null){
      this.setSquareCustomerId()
    } else {
      let sub = this.helperService.getCurrentCompanyId().subscribe(data => {
        this.setSquareCustomerId()
        sub.unsubscribe();
      })
    }

    this.getCompanies();
  }

  async setSquareCustomerId() {
    let company = await this.companyService.getCurrent();
    this.paymentProviderCustomerId = company.paymentProviderCustomerId;
    this.getSquareCustomer(this.paymentProviderCustomerId)
  }

  companySelectionChange(value) {
    this.helperService.company_freeVersion = this.selectedCompany.freeVersion || false;
    this.helperService.currentCompanyId = this.selectedCompany.uid;
    location.reload();
  }

  async getCompanies() {
    let companyResponse = await this.companyService.getAllCompaniesForUser();

    if(!companyResponse.success){
      this.snackBar.open(companyResponse.message);
      //this.loading = false;
      return;
    }

    this.companies = companyResponse.value;
  }
  
  async getSquareCustomer(paymentProviderCustomerId: string) {
    //this.loading.push(true);
    let result = await this.squareService.getCustomer(paymentProviderCustomerId);
    if(result.data.success){
      this.sqCustomer = result.data.value;
    }
    //this.loading.pop();
  }

  async resume() {
    this.dialogRef.close();
    this.router.navigate(['/console', 'billing']);
    return;
    ////this.loading.push(true);

    // let userId = this.claimsService.currentUserId();
    // let user = await this.userService.getUserById(userId);
    // let company = await this.companyService.getCurrent();

    // let arr = user.displayName.split(' ');
    // let firstName = arr[0];
    // arr.shift();
    // let lastName = arr.join();

    // let cardId;
    // if(this.sqCustomer.cards.length > 0){
    //   cardId = this.sqCustomer.cards[0];
    // }
    
    // let response = await this.squareService.upgradeAccount(firstName, lastName, company.name, company.uid, 
    //   user.emailAddress, null, null, null, company.paymentProviderCustomerId, cardId);
    // if(response.data.success){
    //   alert('New Subscription')
    // }
    // //this.loading.pop();
  }
}
