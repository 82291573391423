
<div class="row" *ngIf="displayFor === 'templates'">
    <div class="col-12">
        <mat-form-field>
            <mat-label>Select Template(s)</mat-label>
            <mat-select [formControl]="primaryTemplateCtrl" multiple (closed)="setPrimaryTemplates()">
                <mat-option>
                    <ngx-mat-select-search [formControl]="primaryTemplateFilterCtrl"
                                            placeholderLabel="Find Template..."
                                            noEntriesFoundLabel="no matching template found"></ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="disabled" class="filter-option">
                    <button mat-raised-button class="mat-primary fill text-sm" (click)="selectFilteredPrimaryTemplate()">
                        Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="templateChange([], 'primary')">
                        Deselect All
                    </button>
                </mat-option>
                <mat-select-trigger>
                    {{primaryTemplateCtrl.value && primaryTemplateCtrl.value.length >= 1 ? primaryTemplateCtrl.value[0].name : 'None Selected'}}
                    <span *ngIf="primaryTemplateCtrl.value?.length > 1" class="example-additional-selection">
                    (+{{primaryTemplateCtrl.value.length - 1}} {{primaryTemplateCtrl.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let template of filteredPrimaryTemplates | async" [value]="template">
                    {{template.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <h5>Selected Templates:</h5>
                <ul class="list">
                    <li *ngFor="let template of selectedPrimaryTemplates">
                        {{template.name}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="displayFor === 'workflows'">
    <div class="col-12">
        <mat-form-field>
            <mat-label>Select Workflow(s)</mat-label>
            <mat-select [formControl]="secondaryTemplateCtrl" multiple (closed)="setSecondaryTemplates()">
                <mat-option>
                    <ngx-mat-select-search [formControl]="secondaryTemplateFilterCtrl"
                                            placeholderLabel="Find Workflow..."
                                            noEntriesFoundLabel="no matching workflow found"></ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="disabled" class="filter-option">
                    <button mat-raised-button class="mat-primary fill text-sm" (click)="selectFilteredSecondaryTemplate()">
                        Select All
                    </button>
                    <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="templateChange([], 'secondary')">
                        Deselect All
                    </button>
                </mat-option>
                <mat-select-trigger>
                    {{secondaryTemplateCtrl.value && secondaryTemplateCtrl.value.length >= 1 ? secondaryTemplateCtrl.value[0].name : 'None Selected'}}
                    <span *ngIf="secondaryTemplateCtrl.value?.length > 1" class="example-additional-selection">
                    (+{{secondaryTemplateCtrl.value.length - 1}} {{secondaryTemplateCtrl.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let workflow of filteredSecondaryTemplates | async" [value]="workflow">
                    {{workflow.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <h5>Selected Workflows:</h5>
                <ul class="list">
                    <li *ngFor="let workflow of selectedSecondaryTemplates">
                        {{workflow.name}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>