import { Component, OnInit, ElementRef, ViewChild, HostListener, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import * as models from '../../../models';
import * as services from '../../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-interval-filter',
  templateUrl: './chart-interval-filter.component.html',
  styleUrls: ['./chart-interval-filter.component.scss']
})
export class ChartIntervalFilterComponent implements OnInit {

  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (
      !this.dropdownButton?.nativeElement.contains(event.target) &&
      !this.dropdownMenu?.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false;
    }
  }

  chartInterval: string;

  @Input() dateRange: models.DateRanger;

  @Output() selectedChartIntervalChange = new EventEmitter();
  set selectedChartInterval(val: string) {
    if(val && val != null){
      this.chartInterval = val;  
      this.selectedChartIntervalChange.emit(this.chartInterval);
    }
  }

  companySub: Subscription;
  dateIntervalOptions: string[];

  constructor(
    private helperService: services.HelperService
  ) { }

  isDropdownOpen: boolean = false;

  selectedOption: string;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngOnInit(): void {
    let companyId = this.helperService.currentCompanyId;
    if (companyId) {
      this.selectedOption = 'Day';
    }
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.selectedOption = 'Day';
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRange && changes.dateRange.currentValue !== changes.dateRange.previousValue) {
      setTimeout(() => {
        // this.selectedChartInterval = changes.dateRange.currentValue.defaultViewSetTo;
        this.makeChartIntervals(changes.dateRange.currentValue.rangeType);
        this.selectedOption = this.helperService.getDefaultInterval(changes.dateRange.currentValue.rangeType);
      }, 0)
    }
  }

  makeChartIntervals(rangeType: string): void {
    switch(rangeType) {
      case 'last_week':
        this.dateIntervalOptions = ['Day', 'Week'];
        break;
      case 'week':
        this.dateIntervalOptions = ['Day'];
        break;
      case 'last_month':
        this.dateIntervalOptions = ['Day', 'Week', 'Month'];
        break;
      case 'month':
        this.dateIntervalOptions = ['Day', 'Week'];
        break;
      case 'last_year':
        this.dateIntervalOptions = ['Day', 'Week', 'Month', 'Quarter', 'Year']; 
        break;
      case 'year':
        this.dateIntervalOptions = ['Day', 'Week', 'Month', 'Quarter']; 
        break;
      case 'all_time':
        this.dateIntervalOptions = ['Day', 'Week', 'Month', 'Quarter', 'Year'];
        break;
      case 'custom': 
        this.dateIntervalOptions = ['Day', 'Week', 'Month'];
        break;
    }
  }

  optionChange(option: any) {
    this.selectedOption = option.value;
    this.selectedChartInterval = option.value;
    this.isDropdownOpen = false;
  }

  ngOnDestroy() {
    if(this.companySub) {
      this.companySub.unsubscribe();
    }
  }
}
