import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

import * as models from '../../../models';
import * as services from '../../../services';

@Component({
  selector: 'app-strain-select-multiple',
  templateUrl: './strain-select-multiple.component.html',
  styleUrls: ['./strain-select-multiple.component.scss']
})
export class StrainSelectMultipleComponent implements OnInit {

  loading: boolean = true;
  @Input() hidden: boolean = false;

  strainsValue: Array<models.Strain>;
  @Input()
  get strains() {
    return this.strainsValue;
  }
  @Output() strainsChange = new EventEmitter();
  set strains(val) {
    if(val == null){
      val = [];
    }
    this.strainsValue = val;
    //this.setForm();
    this.strainsChange.emit(this.strainsValue);
  }
  
  strainIdsValue: Array<string>;
  @Input()
  get strainIds() {
    return this.strainIdsValue;
  }
  @Output() strainIdsChange = new EventEmitter();
  set strainIds(val) {
    if(val == null){
      val = [];
    }
    this.strainIdsValue = val;
    //this.setForm();
    this.strainIdsChange.emit(this.strainIdsValue);
  }

  get title(): string {
    if(this.strainIds == null || this.strains == null){
      return '';
    }
    if(this.strainIds.length == this.strains.length){
      return 'ALL';
    }
    if(this.strainIds.length >= 1){
      let strain = this.strains.find(i => i.id == this.strainIds[0]);
      let title = strain?.name || '';
      if(this.strainIds.length >= 2){
        title += ` (+${this.strainIds.length - 1} ${this.strainIds?.length === 2 ? 'other' : 'others'})`
      }
      return title;
    }
  }

  constructor(
    private strainService: services.StrainService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.get();
  }

  change(newValue) {
    this.strainIds = newValue;
  }

  selectAll(select: NgModel, values) {
    select.update.emit(values); 
    this.change(this.strains.map(i => i.id));
  }

  deselectAll(select: NgModel) {
    select.update.emit([]); 
    this.change([]);
  }

  async get() {
    this.loading = true;
    let response = await this.strainService.getAll();
    this.strains = response;
    
    // //set selected
    // if(this.strainIds.length > 0){
    //   let strains = this.strains.filter(i => this.strainIds.includes(i.id));
    //   this.selected = strains;
    // }
  }
}
