
<div class="row" style="padding-bottom: 15px">
  <div class="col-sm-6">
    <mat-form-field>
        <mat-label>Filter Users by Company</mat-label>
        <mat-select [disabled]="userUpdated() || savingUser" [(value)]="selectedCompany" (selectionChange)="companyChange($event.value)">
          <mat-option>All Companies</mat-option>
          <mat-option [value]="c" *ngFor="let c of companies">{{c.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select [disabled]="userUpdated() || savingUser" [formControl]="userCtrl" placeholder="Find user..." #singleSelect (selectionChange)="userChange($event.value)">
        <mat-option>
          <ngx-mat-select-search [formControl]="userFilterCtrl"
                                  placeholderLabel="Find user..."
                                  noEntriesFoundLabel="no matching user found"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{user.displayName}} ({{user.emailAddress}})
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div class="col-sm-6">
    <div class="row"> 
      <div class="col-sm-12 right">
        <button class="gridbtn" mat-raised-button [disabled]="userUpdated() == false || savingUser" (click)="saveUser()" title="Save User">
          <mat-spinner *ngIf="savingUser" diameter=25 strokeWidth=4></mat-spinner>
          <mat-icon aria-hidden="false" aria-label="Save User" *ngIf="!savingUser">save</mat-icon>
        </button>
      </div>
    </div>

    <h3>User Info</h3>
    <div class="row" *ngIf="selectedUser != null">
      <div class="col-12">
        <mat-checkbox class="example-margin" [disabled]="savingUser" [(ngModel)]="selectedUser.mustCompleteTour">User must complete Tour</mat-checkbox>
      </div>
    </div>
    <div class="row" *ngIf="selectedUser != null">
      <div class="col-12">
        <mat-checkbox class="example-margin" [disabled]="savingUser" [(ngModel)]="selectedUser.createDemoHarvest">Create Demo Harvest when user logs in</mat-checkbox>
      </div>
    </div>

    <div class="row"> 
      <div class="col-sm-12 right">
        <button class="gridbtn" mat-raised-button [disabled]="companiesUpdated() == false || savingUser" (click)="saveUserCompanies()" title="Save User">
          <mat-spinner *ngIf="savingUser" diameter=25 strokeWidth=4></mat-spinner>
          <mat-icon aria-hidden="false" aria-label="Save User" *ngIf="!savingUser">save</mat-icon>
        </button>
      </div>
    </div>

    <h3>Companies</h3>
    <form id="selectCompanies" [formGroup]="companySelectForm" (ngSubmit)="submit()" *ngIf="retrievedCompanies && selectedUser != null">
      <div formArrayName="companies" *ngFor="let company of companySelectForm.get('companies')['controls']; let i = index" >
          <mat-checkbox class="example-margin" [disabled]="savingUser" [formControlName]="i">
          {{companies[i].name}}
          </mat-checkbox>
      </div>
    </form>
  </div>
</div>
